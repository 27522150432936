import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useLocation } from "react-router-dom";
import { Button, Form, FormGroup, Label, FormText, Row, Col, Alert, Table } from "reactstrap";
import { API_BASE_URL } from "../../../../_services/constant";
import { Card, TextField } from "@material-ui/core";
import { post } from "axios";

import {
  postMethod,
  validateMobile,
  singleParam,
  editUserGrid,
} from "../../../../_services/_postMethod";
import Loader from "../../../modules/loader";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AddTearms = (props) => {
  let queryParams = useQuery();
  const id = queryParams.get("id") == "" ? null : queryParams.get("id");
  const { register, handleSubmit, errors } = useForm();
  const [load, setLoad] = useState(false);
  const [loadInser, setLoadInsert] = useState(false);
  const [loadDelete, setLoadDelete] = useState(false);  
  const [loadDeleteMsg, setLoadDeleteMsg] = useState("Deleted");
  const [termValidation, setTermValidation] = useState(false);
  const [voucherlist, setVoucherlist] = useState([]);
  const [termsandconditionsdetail, setTermsandconditionsdetail] = useState([]);
  const [termParam, settermParam] = useState();

  const [param, setParam] = useState({
    tran_id: "0",
    voucher_id: "",
    terms_and_conditions_detail: [],
  });
  const [success, setSuccess] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);

  useEffect(() => {
    if (id !== null) {
      setLoad(true);
      var temp = window.atob(id);
      postMethod("api/transactions/term/preview", { tran_id: temp }).then((data) => {
        setParam({
          ...param,
          tran_id:temp,    
          voucher_id: data.data[0][0].voucher_id
        });
        var Temtext = [];
        if(data.data[1].length > 0)
        {
          for (let index = 0; index < data.data[1].length; index++) {
            const element = data.data[1][index];
            Temtext.push(element.text);
            
          }
        }
        setTermsandconditionsdetail(Temtext);
        setLoad(false);
      });
    }
    postMethod("api/customers/customer/voucherlist", {}).then((data) => {
     setVoucherlist(data.data);
    });

  }, []);

  // check object is null or undefined
  const checkObject = (obj) => {
    return obj === undefined ? {} : Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const AddTearms = () => {
    if (termParam !== "") {
      termsandconditionsdetail.push(termParam);
      settermParam("");
    }
  };
  const removeProduct = (i) => {
    let tempArry = [...termsandconditionsdetail];
    tempArry.splice(i, 1);
    setTermsandconditionsdetail(tempArry);
  };

  const _Save = () => {  
    let temparam = {
      tran_id:param.tran_id,
      voucher_id: param.voucher_id,
      terms_and_conditions_detail: termsandconditionsdetail
    };
    if(termsandconditionsdetail.length == 0)
    {
      setTermValidation(true);
    }
    else
    {
        setLoadInsert(true);
    postMethod("api/transactions/term/insert", temparam).then((data) => {
      if (data.data[0].valid) {
        setSuccess(true);
        setTimeout(() => {
         props.history.push("/branch/voucher-terms");
        }, 2000);
      }
      else{
          setLoadInsert(false);
      }
    });
    }

  };
  const handleDelete = () => {
    var proceed = window.confirm("Do you Really Wish to Delete this Entry..?!");
    var temp = window.atob(id);
    if (proceed) {
        setLoadDelete(true);
      postMethod("api/transactions/term/delete", { tran_id: temp }).then((data) => {     
        if (data.data[0].valid) {
          setSuccessDelete(true);
          setTimeout(() => {
            props.history.push("/branch/voucher-terms");
          }, 2000);
        }
        else
        {
          setSuccessDelete(true);
              setTimeout(() => {
               setSuccessDelete(false);
            }, 4000);
             setLoadDeleteMsg(data.error)
           setLoadDelete(false);
        }
      });
    }
  };

  return (
    <>
      {load ? <Loader /> : null}
      <Form onSubmit={handleSubmit(_Save)}>
        <input
          type="hidden"
          name="product_id"
          value={id == null ? "0" : param.product_id}
          ref={register}
        />
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Add Voucher tearm and condition</h3>
            </div>
          </div>
          <div className="card-body">
            <Row>
             
              
              <Col sm={3}>
                <FormGroup>
                  <Label>Voucher</Label>
                  <select
                  required
                    className="form-control h-auto py-2 px-6"
                    name="branch_id"
                    value={param.voucher_id}
                    onChange={(e) => setParam({ ...param, voucher_id: e.target.value })}
                  >
                    <option value="">-select-</option>
                    {voucherlist.length > 0 ?
                      voucherlist.map((obj, i) => {
                          return (
                            <option key={i} value={obj.voucher_id}>
                              {obj.voucher_name}
                            </option>
                          );
                        }) : null}
                  </select>
                </FormGroup>
              </Col>
           
            </Row>

            <Row>
              <Col sm={12}>
                <Table bordered>
                  <thead>
                    <tr>
                    <th>Srno.</th>
                      <th>Tearms and Conditions {termValidation ? <label className="text-danger">*Required</label> : null}</th>
                      <th>action</th>
                    </tr>
                    <tr>     
                    <th></th>               
                      <th>
                        <input
                          type="text"
                          className="form-control h-auto py-2 px-6"
                          name="barcode"
                          onChange={(e) =>
                            settermParam(e.target.value)
                          }
                          value={termParam}
                        />
                      </th>                  
                      
                      <th>
                        <button type="button" className="btn-success" onClick={() => AddTearms()}>
                          Add
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {termsandconditionsdetail.length > 0
                      ? termsandconditionsdetail.map((obj, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                {i + 1})
                              </td>
                              <td>
                                {obj}
                              </td>
                              <td>
                                <button type="button" onClick={() => removeProduct(i)}>
                                  x
                                </button>
                              </td>
                            </tr>
                          );
                        }) : null }
                  </tbody>
                </Table>
              </Col>
            </Row>
            {success ? <Alert color="success">Saved Successfully</Alert> : null}
            {successDelete ? <Alert color="danger">{loadDeleteMsg}</Alert> : null}
          </div>
          <div className="card-footer text-right">
            <a href="/branch/voucher-terms" className="btn btn-secondary mr-2">
              Cancel
            </a>
           {id && (
              loadDelete ? <button type="button" className="btn btn-danger mr-2">
                Deleting..
              </button> : 
               <button type="button" onClick={handleDelete} className="btn btn-danger mr-2">
                Delete
              </button>
            )}
          
            {loadInser ? 
             <button type="button" className="btn btn-primary ">
              Saving..
            </button> :  <button type="submit" className="btn btn-primary ">
              Save
            </button>}
          </div>
        </div>
      </Form>
    </>
  );
};

export default AddTearms;
