import React  from "react";
 
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
 

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={props => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.child} />
      )}
    />
  );
}

export default RouteWithSubRoutes;
