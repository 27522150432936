import React, { useEffect, useState } from "react";
import background from "../../../_assets/media/login_back.jpg";
import Logo from "../../../_assets/media/favicon.png";
import { useForm } from "react-hook-form";
import { Alert } from "reactstrap";
import { postMethod } from "../../../_services/_postMethod";

const Welcome = (props) => {
  const [otp, setOtp] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const [isvalid, setValid] = useState(false);
  const [load, setLoad] = useState(false);
  useEffect(() => {
    localStorage.setItem("type", "branch");
   }, []);
  return (
    <>
      <div className="exhibitionbg">
      <div className="container" >
              <div className="text-center">
                <img src={Logo} />
              </div>

              <div className="login-form" style={{ marginTop: "25px" }}>
                <div className="main-div">
                  <form>
                    <div className="exgroup exsubs">
                      <select
                        className="form-control"
                        onChange={(e) => {
                          if (e.target.value != "") {
                            localStorage.setItem("type", e.target.value);
                            setValid(false);
                          } else {
                            setValid(true);
                          }
                        }}
                      >
                        {/* <option value="" selected={true}>
                          ---Select Type---
                        </option> */}
                        <option value="admin">Admin Login</option>
                        <option value="company">Company Login</option>
                        <option value="branch" selected={true}>Branch Login</option>
                        <option value="exhibition">Exhibition Login</option>
                      </select>
                      <span className="highlight"></span>
                      <p style={{ color: "red",fontSize:13 }} className="exbar">
                        {isvalid && "* Please Select a Login type"}
                      </p>
                    </div>

                    <div className="">
                      <button
                        type="button"
                        className="btn btn-theme w-100"
                        onClick={() => {
                          if (!isvalid) {
                            props.history.push("/login");
                          }
                        }}
                      >Confirm</button>
                    </div>
                  </form>
                </div>

                <div className="clr"></div>
              </div>
            </div>
        
        <div className="clr"></div>
        <div className="custom-footer">
          <div className="container mcntr">
            <div className="row">
              <div className="col-sm-10">© 2020 QuickTagg All Rights Reserved</div>
              <div className="col-sm-2">
                <div className="text-center center-block">
                  <a href="https://www.facebook.com/bootsnipp">
                    <i id="social-fb" className="fa fa-facebook fa-2x social"></i>
                  </a>
                  <a href="https://plus.google.com/+Bootsnipp-page">
                    <i id="social-gp" className="fa fa-instagram fa-2x social"></i>
                  </a>
                  <a href="mailto:bootsnipp@gmail.com">
                    <i id="social-em" className="fa fa-linkedin fa-2x social"></i>
                  </a>
                  <a href="https://twitter.com/bootsnipp">
                    <i id="social-tw" className="fa fa-twitter fa-2x social"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Welcome;
