import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../../../_services/constant";
import OwlCarousel from "react-owl-carousel2";
import { postMethod } from "../../../../_services/_postMethod";

export default function CustomerVoucherCom(props) {
    const [voucherList, setVoucherList] = useState([]);
    const options = {
      responsive:{
        0:{
          items:2,
          margin:10
        },
        600:{
          items:2,
          margin:10
        },
        1000:{
          items:4,
          margin:40
        }
      },
        nav: true, 
        dots: true,
        autoplay: true,
        stagePadding: 10,
        loop: true,
      };
    useEffect(() => {  
        postMethod("api/session/voucherlist", { }).then((data) => {
          setVoucherList(data.data);
        });
      }, []);

    
  return (
    <>
       {voucherList.length && (
            <>        
            <OwlCarousel options={options}>
              {voucherList.map((prop, i) => (          
                  <img onClick={()=>{
                   // console.log(prop.voucher_id);
                    var tran = prop.voucher_id;
                   
                    if(prop.voucher_type == "birthday")
                    {
                     window.location =`/customers/birthday?id=${tran}`
                    }
                    else if(prop.voucher_type == "anniversary"){
                     window.location =`/customers/anniversary?id=${tran}`
                    }
                    else if(prop.voucher_type == "instagram"){
                     window.location =`/customers/instagram?id=${tran}`
                   }
                   else if(prop.voucher_type == "referral"){
                     window.location =`/customers/referral?id=${tran}` 
                   }
                   else if(prop.voucher_type == "uploadDesign"){
                    window.location =`/customers/upload-design?id=${tran}` 
                  }
                   else{
                     window.location =`/customers/special-voucher?id=${tran}`
                   }
                  }}
                    src={API_BASE_URL + "CustomerVoucher/" + prop.image_path}
                    className="img-fluid"
                    alt=""
                  />
               
              ))}
            </OwlCarousel>       
            </>
          )}
      <div className="clr"></div>
    </>
  );
}
