import Category from "../app/screens/branch/product_category/category";
import AddCategory from "../app/screens/branch/product_category/addcategory";
import Subcategory from "../app/screens/branch/product_subcategory/subcategory";
import AddSubcategory from "../app/screens/branch/product_subcategory/addsubcategory";
import Product from "../app/screens/branch/products/product";
import AddProduct from "../app/screens/branch/products/addproduct";
import CustomerCategory from "../app/screens/branch/customer_category/customer_category";
import AddCustomerCategory from "../app/screens/branch/customer_category/addcustomer_category";
import Customer from "../app/screens/branch/customers/customer";
import AddCustomer from "../app/screens/branch/customers/addcustomer";
import AddSession from "../app/screens/branch/customer_session/addcustomer_session";
import Session from "../app/screens/branch/customer_session/customer_session";
import Stockin from "../app/screens/branch/stock_in/stockin";
import AddStockin from "../app/screens/branch/stock_in/addstockin";
import Stocktransfer from "../app/screens/branch/stock_transfer/stocktransfer";
import AddStocktransfer from "../app/screens/branch/stock_transfer/addstocktransfer";
import Stockacceptance from "../app/screens/branch/stock_acceptance/stockacceptance";
import AddStockacceptance from "../app/screens/branch/stock_acceptance/addstockacceptance";
import BranchDashboard from "../app/screens/branch/dashboard/dashboard";
import Voucher from "../app/screens/branch/customer_voucher/voucher";
import AddVoucher from "../app/screens/branch/customer_voucher/addvoucher";
import SMSTemplete from "../app/screens/branch/sms_templete_setting/smstemplete";
import TabToScan from "../app/screens/branch/tab_to_scan/tabtoscan";
import AddCustomerReview from "../app/screens/branch/customer_review/addcustomerreview";
import CustomerReview from "../app/screens/branch/customer_review/customerreview";
import Terms from "../app/screens/branch/terms/terms";
import AddTerms from "../app/screens/branch/terms/addterms";
import Customeritemincart from "../app/screens/branch/customer_item_in_cart/customeritemincart";
import Tab_to_scan_banner from "../app/screens/branch/tab_to_scan_banner/tab_to_scan_banner";
import GenralSession from "../app/screens/branch/general_session/general_session";
import AddGeneralSession from "../app/screens/branch/general_session/addgeneral_session";
import Exhibition from "../app/screens/branch/exhibition/exhibition";
import Staff from "../app/screens/branch/staff/staff";
import AddStaff from "../app/screens/branch/staff/addstaff";
import AddArea from "../app/screens/branch/area/addarea";
import Area from "../app/screens/branch/area/area";
import Trials from "../app/screens/branch/trials/trials";
import TrialSession from "../app/screens/branch/trial_session/trial_session";
import AddTrialSession from "../app/screens/branch/trial_session/addtrial_session";
import WhatsAppTemplate from "../app/screens/branch/whatsapp_template_setting/whatsapptemplate";
import ExhibitionSession from "../app/screens/branch/exhibition_session/exhibition_session";
import AddExhibitionSession from "../app/screens/branch/exhibition_session/addexhibition_session";
import SessionImage from "../app/screens/branch/image_upload/session_image";

import CustomerSales from "../app/screens/branch/customer_sales/customer_sales";
import AddCustomerSales from "../app/screens/branch/customer_sales/addcustomer_sales";

export const BranchRoutes = [
  {
    path: "/branch/dashboard",
    name: "Dashboard",
    icon: "flaticon-web",
    child: false,
    component: BranchDashboard,
  },

  // {
  //   path: "/branch/product/cart_item",
  //   name: "Customer Items in cart",
  //   icon: "flaticon-web",
  //   child: false,
  //   component: Customeritemincart,
  // },
  {
    path: "/branch/product/add",
    name: "Products",
    child: true,
    icon: "flaticon-technology-1",
    component: AddProduct,
  },
  {
    path: "/branch/product/category",
    name: "Product Categories",
    icon: "flaticon-web",
    child: false,
    component: Category,
  },
  {
    path: "/branch/product/category/add",
    name: "Product Categories",
    icon: "flaticon-technology-1",
    child: true,
    component: AddCategory,
  },
  {
    path: "/branch/product/subcategory",
    name: "Product SubCategories",
    icon: "flaticon-web",
    child: false,
    component: Subcategory,
  },
  {
    path: "/branch/product/subcategory/add",
    name: "Product SubCategories",
    icon: "flaticon-technology-1",
    child: true,
    component: AddSubcategory,
  },

  {
    path: "/branch/product",
    name: "Products",
    icon: "flaticon-web",
    child: false,
    component: Product,
  },

  {
    path: "/branch/voucher",
    name: "Voucher",
    icon: "flaticon-web",
    child: false,
    component: Voucher,
  },
  {
    path: "/branch/voucher/add",
    name: "Voucher",
    icon: "flaticon-technology-1",
    child: true,
    component: AddVoucher,
  },
  {
    path: "/branch/voucher-terms",
    name: "Voucher Terms",
    icon: "flaticon-web",
    child: false,
    component: Terms,
  },
  {
    path: "/branch/voucher-terms/add",
    name: "Voucher Terms",
    icon: "flaticon-technology-1",
    child: true,
    component: AddTerms,
  },
  {
    path: "/branch/customer/add",
    name: "Customer",
    icon: "flaticon-technology-1",
    child: true,
    component: AddCustomer,
  },

  {
    path: "/branch/customer",
    name: "Customer",
    icon: "flaticon-web",
    child: false,
    component: Customer,
  },
  {
    path: "/branch/customer/session",
    name: "Customer Session",
    icon: "flaticon-web",
    child: false,
    component: Session,
  },
  {
    path: "/branch/customer/general-session",
    name: "General Session",
    icon: "flaticon-web",
    child: false,
    component: GenralSession,
  },
  {
    path: "/branch/customer/general-session/add",
    name: "General Session",
    icon: "flaticon-technology-1",
    child: true,
    component: AddGeneralSession,
  },
  {
    path: "/branch/customer/session/add",
    name: "Customer Session",
    icon: "flaticon-technology-1",
    child: true,
    component: AddSession,
  },
  {
    path: "/branch/customer/trial-session",
    name: "Trial Session",
    icon: "flaticon-web",
    child: false,
    component: TrialSession,
  },
  {
    path: "/branch/customer/trial-session/add",
    name: "Trial Session",
    icon: "flaticon-technology-1",
    child: true,
    component: AddTrialSession,
  },
  {
    path: "/branch/customer/exhibition-session",
    name: "Exhibition Session",
    icon: "flaticon-web",
    child: false,
    component: ExhibitionSession,
  },
  {
    path: "/branch/customer/exhibition-session/add",
    name: "Exhibition Session",
    icon: "flaticon-technology-1",
    child: true,
    component: AddExhibitionSession,
  },
  {
    path: "/branch/stock-transfer",
    name: "Stock Transfer",
    icon: "flaticon-web",
    child: false,
    component: Stocktransfer,
  },
  {
    path: "/branch/stock-transfer/add",
    name: "Stock Transfer",
    icon: "flaticon-technology-1",
    child: true,
    component: AddStocktransfer,
  },
  {
    path: "/branch/stock-acceptance",
    name: "Stock Acceptance",
    icon: "flaticon-web",
    child: false,
    component: Stockacceptance,
  },
  {
    path: "/branch/stock-acceptance/add",
    name: "Stock Acceptance",
    icon: "flaticon-technology-1",
    child: true,
    component: AddStockacceptance,
  },
  {
    path: "/branch/stock-sale",
    name: "Stock Sale",
    icon: "flaticon-web",
    child: false,
    component: CustomerSales,
  },
  {
    path: "/branch/stock-sale/add",
    name: "Stock Sale",
    icon: "flaticon-technology-1",
    child: true,
    component: AddCustomerSales,
  },

  {
    path: "/branch/smstemplete",
    name: "Sms Templete Setting",
    icon: "flaticon-web",
    child: false,
    component: SMSTemplete,
  },
  {
    path: "/branch/WhatsAppTemplate",
    name: "Whatsapp Templete Setting",
    icon: "flaticon-web",
    child: false,
    component: WhatsAppTemplate,
  },
  {
    path: "/branch/image_upload",
    name: "Seesion Images",
    icon: "flaticon-web",
    child: false,
    component: SessionImage,
  },
  {
    path: "/tabtoscan",
    name: "Tab To Scan",
    icon: "flaticon-web",
    child: false,
    component: TabToScan,
  },
  {
    path: "/branch/customer-review",
    name: "Customer Review",
    icon: "flaticon-web",
    child: false,
    component: CustomerReview,
  },
  {
    path: "/branch/customer-review/add",
    name: "Customer Review",
    icon: "flaticon-technology-1",
    child: true,
    component: AddCustomerReview,
  },

  {
    path: "/branch/customer/category",
    name: "Customer Categories",
    icon: "flaticon-web",
    child: false,
    component: CustomerCategory,
  },
  {
    path: "/branch/customer/category/add",
    name: "Customer Categories",
    icon: "flaticon-technology-1",
    child: true,
    component: AddCustomerCategory,
  },
  {
    path: "/branch/staff",
    name: "Staff",
    icon: "flaticon-web",
    child: false,
    component: Staff,
  },
  {
    path: "/branch/staff/add",
    name: "Staff",
    icon: "flaticon-technology-1",
    child: true,
    component: AddStaff,
  },
  {
    path: "/branch/area",
    name: "Area",
    icon: "flaticon-web",
    child: false,
    component: Area,
  },
  {
    path: "/branch/area/add",
    name: "Area",
    icon: "flaticon-technology-1",
    child: true,
    component: AddArea,
  },
  {
    path: "/branch/tab-to-scan-banner",
    name: "Tab to scan banner",
    icon: "flaticon-web",
    child: false,
    component: Tab_to_scan_banner,
  },
];
