
import React, { useEffect, useState, Fragment, useRef } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import "react-awesome-slider/dist/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Slide from "@material-ui/core/Slide";
import { Carousel, CarouselItem, Label } from "reactstrap";

import {
  Button,
  Divider,
} from "@material-ui/core";
import {
  Row,
  Col,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  ListGroupItem,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  Form,
  FormGroup,
  FormText,
  FormFeedback,
} from "reactstrap";

import { postMethod } from "../../../../../_services/_postMethod";
import "react-tabs/style/react-tabs.css";

import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function CustomRedeem(props) {

  const [staffList, setStaffList] = React.useState([]);
  const [referenceList, setReferenceList] = React.useState([]);
  const [categoryList, setCategoryList] = React.useState([]);
  const [recentCustomerList, setRecentCustomerList] = React.useState([]);
  const [notificationList, setNotificationList] = React.useState([]);
  const [lastCheckinList, setLastCheckinList] = React.useState([]);
    const [voucherCount, setVoucherCount] = useState(0);
    const {mobno} = props;

      //Table Config
  const config = {
    page_size: 5,
    // show_pagination: false,
    show_filter: false,
    //show_info: false,
    show_length_menu: false,
  };

  const config_point = {
    page_size: 10,
    show_filter: false,
    show_length_menu: false,
  };

  //Table Config
  const config_AREPoint = {
    page_size: 10,
    length_menu: [10, 5, 20, 50],
  };

    const [customerHistory, setCustomerHistory] = React.useState({
        showRedeemList: false,
        showRedeemPoint: false,
        showVoucherPoint: false,
      });
      const [isPleaseWait, setIsPleaseWait] = React.useState(false);
      const [CustomerVoucherList, setCustomerVoucherList] = React.useState([]);


      const handleCustomerVoucherList = (customer_id) => {
        let temparam = {
          customer_id: customer_id,
        };
        postMethod("api/customervisit/getCustomerVoucherList", temparam).then(
          (data) => {
            setCustomerVoucherList(data.data);
            setVoucherCount(data.data[0].voucher_count);
          }
        );
      };

      const handleRedeem = (customer_id, voucher_id, voucher_name, details) => {
        var msg = voucher_name + " " + details + " confirm redeem ?";
        var r = window.confirm(msg);
        if (r == true) {
          let temparam = {
            tran_id: 0,
            customer_id: customer_id,
    
            voucher_id: voucher_id,
          };
          postMethod("api/customervisit/insertVoucherRedeem", temparam).then(
            (data) => {
              if (data.data[0].valid) {
                setCustomerHistory({
                  ...customerHistory,
                  showRedeemList: false,
                });
                alert("Voucher redeem succesfully");
                handleCustomerVoucherList(customer_id);
              }
            }
          );
        }
      };
      const voucher_columns = [
        {
          key: "voucher_name",
          text: "Voucher",
          align: "left",
          sortable: true,
        },
        {
          key: "details",
          text: "Details",
          sortable: true,
          align: "left",
        },
        {
          key: "amount",
          text: "Offer",
          sortable: true,
          align: "left",
        },
        {
          key: "start_date",
          text: "Issue Date",
          align: "left",
          sortable: true,
        },
        {
          key: "redeem_end_date",
          text: "Expiry Date",
          align: "left",
          sortable: true,
          cell: (record) => {
            return (
              <Fragment>
                <span>
                  {/* {record.IsvoucherExpire === "true" ? (
                      <label className="text-success">{record.end_date}</label>
                    ) : (
                      <label className="text-danger">{record.end_date}</label>
                    )} */}
    
                  {record.IsvoucherExpire === "true" ? (
                    <label className="text-success">
                      {moment(record.redeem_end_date).format("DD/MM/YYYY")}
                    </label>
                  ) : (
                    <label className="text-danger">
                      {moment(record.redeem_end_date).format("DD/MM/YYYY")}
                    </label>
                  )}
                </span>
              </Fragment>
            );
          },
        },
        {
          key: "action",
          text: "Action",
          className: "action",
          width: 100,
          align: "left",
          sortable: false,
          cell: (record) => {
            return (
              <Fragment>
                {record.IsvoucherExpire === "true" ? (
                  <label className="text-success">
                    <button
                      className="btn btn-success"
                      color="primary"
                      onClick={() =>
                        handleRedeem(
                          record.customer_id,
                          record.voucher_id,
                          record.voucher_name,
                          record.details
                        )
                      }
                      style={{ marginRight: "5px" }}
                    >
                      Redeem
                    </button>
                  </label>
                ) : null}
              </Fragment>
            );
          },
        },
      ];
      // -------------Active Point--------------------------------------
      const [pointActiveModal, SetPointActiveModal] = useState(false);
    
      const toggleActivePoint = () => {
        SetPointActiveModal(!pointActiveModal);
      };
    
      const [CustomerPointList, setCustomerPointList] = React.useState([]);
      const ActivePoint_Columns = [
        {
          key: "mobile",
          text: "Mobile",
          align: "left",
          sortable: true,
        },
        {
          key: "type",
          text: "Type",
          align: "left",
          sortable: true,
        },
        {
          key: "remark",
          text: "Remark",
          align: "left",
          sortable: true,
        },
        {
          key: "points",
          text: "Points",
          sortable: true,
          align: "left",
        },
        {
          key: "date_time",
          text: "Date",
          sortable: true,
          align: "left",
          cell: (record) => {
            return (
              <Fragment>
                {record.date_time != "" && record.date_time != null ? (
                  <>
                    <p style={{ padding: 0, margin: 0, color: "green" }}>
                      {
                        moment(
                          record.date_time.split("T")[0] +
                            "T" +
                            record.date_time.split("T")[1].split(".")[0]
                        )
                          .format("DD/MM/yyyy HH:mm")
                          .toString()
                          .split(" ")[0]
                      }
                    </p>
                  </>
                ) : null}{" "}
              </Fragment>
            );
          },
        },
        {
          key: "expire_date",
          text: "Expired Date",
          sortable: true,
          align: "left",
          cell: (record) => {
            return (
              <Fragment>
                {record.expire_date != "" && record.expire_date != null ? (
                  <>
                    <p style={{ padding: 0, margin: 0, color: "red" }}>
                      {
                        moment(
                          record.expire_date.split("T")[0] +
                            "T" +
                            record.expire_date.split("T")[1].split(".")[0]
                        )
                          .format("DD/MM/yyyy HH:mm")
                          .toString()
                          .split(" ")[0]
                      }
                    </p>
                  </>
                ) : null}{" "}
              </Fragment>
            );
          },
        },
      ];
      // ---------------------------
    
      // -------------Redeem Point--------------------------------------
    
      const [pointRedeemModal, SetPointRedeemModal] = useState(false);
    
      const toggleRedeemPoint = () => {
        SetPointRedeemModal(!pointRedeemModal);
      };
    
      const [CustomerRedeemPoint, SetCustomerRedeemPoint] = React.useState([]);
      const RedeemPoint_Columns = [
        {
          key: "mobile",
          text: "Mobile",
          align: "left",
          sortable: true,
        },
        {
          key: "type",
          text: "Type",
          align: "left",
          sortable: true,
        },
        {
          key: "remark",
          text: "Remark",
          align: "left",
          sortable: true,
        },
        {
          key: "staff_name",
          text: "Staff Name",
          align: "left",
          sortable: true,
        },
        {
          key: "points",
          text: "Points",
          sortable: true,
          align: "left",
        },
        {
          key: "date_time",
          text: "Date",
          sortable: true,
          align: "left",
          cell: (record) => {
            return (
              <Fragment>
                {record.date_time != "" && record.date_time != null ? (
                  <>
                    <p style={{ padding: 0, margin: 0, color: "green" }}>
                      {
                        moment(
                          record.date_time.split("T")[0] +
                            "T" +
                            record.date_time.split("T")[1].split(".")[0]
                        )
                          .format("DD/MM/yyyy HH:mm")
                          .toString()
                          .split(" ")[0]
                      }
                    </p>
    
                    {/* moment(record.changedatetime.split("T")[0]+'T'+(record.changedatetime.split('T')[1]).split('.')[0]).format("DD/MM/yyyy HH:mm")  */}
                  </>
                ) : null}{" "}
              </Fragment>
            );
          },
        },
      ];
      // ---------------------------
    
      // -------------Expire Point--------------------------------------
      const [pointExpireModal, SetPointExpireModal] = useState(false);
    
      const toggleExpirePoint = () => {
        SetPointExpireModal(!pointExpireModal);
      };
    
      const [CustomerExpirePoint, SetCustomerExpirePoint] = React.useState([]);
      const ExpirePoint_Columns = [
        {
          key: "mobile",
          text: "Mobile",
          align: "left",
          sortable: true,
        },
        {
          key: "type",
          text: "Type",
          align: "left",
          sortable: true,
        },
        {
          key: "remark",
          text: "Remark",
          align: "left",
          sortable: true,
        },
        {
          key: "staff_name",
          text: "Staff Name",
          align: "left",
          sortable: true,
        },
        {
          key: "points",
          text: "Points",
          sortable: true,
          align: "left",
        },
        {
          key: "date_time",
          text: "Date",
          sortable: true,
          align: "left",
          cell: (record) => {
            return (
              <Fragment>
                {record.date_time != "" && record.date_time != null ? (
                  <>
                    <p style={{ padding: 0, margin: 0, color: "green" }}>
                      {
                        moment(
                          record.date_time.split("T")[0] +
                            "T" +
                            record.date_time.split("T")[1].split(".")[0]
                        )
                          .format("DD/MM/yyyy HH:mm")
                          .toString()
                          .split(" ")[0]
                      }
                    </p>
                  </>
                ) : null}{" "}
              </Fragment>
            );
          },
        },
        {
          key: "expire_date",
          text: "Expired Date",
          sortable: true,
          align: "left",
          cell: (record) => {
            return (
              <Fragment>
                {record.expire_date != "" && record.expire_date != null ? (
                  <>
                    <p style={{ padding: 0, margin: 0, color: "red" }}>
                      {
                        moment(
                          record.expire_date.split("T")[0] +
                            "T" +
                            record.expire_date.split("T")[1].split(".")[0]
                        )
                          .format("DD/MM/yyyy")
                          .toString()
                          .split(" ")[0]
                      }
                    </p>
                  </>
                ) : null}{" "}
              </Fragment>
            );
          },
        },
      ];
      // ---------------------------
    
      const [customerDetails, setCustomerDetails] = React.useState({
        customer_id: "",
        full_name: "",
        mobile: "",
        type: "",
        gender: "",
        dob: "",
        doa: "",
        category_name: "",
        staff_id: "",
        profession: "",
        address: "",
        email: "",
        total_visit: "",
        date: "",
      });
    
      // -----------------------------------------------point
      const [redeemPointData, setRedeemPointData] = useState({
        customer_id: "",
        mobile: "",
        full_name: "",
        redeemPoint: "",
        remark: "",
        staff_name: "",
      });
    
      const [extraPointData, setExtraPointData] = useState({
        customer_id: "",
        mobile: "",
        full_name: "",
        extra_point: 0,
        remark: "",
        staff_name: "",
      });

      const handleCustomerPointList = (customer_id) => {
        let temparam = {
          customer_id: customer_id,
        };
        postMethod("api/customervisit/getCustomerPointList", temparam).then(
          (data) => {
            setCustomerPointList(data.data);
            setTotalPoint(data.data[0].total_points);
          }
        );
        postMethod("api/customervisit/getCustomerRedeemPointList", temparam).then(
          (data) => {
            SetCustomerRedeemPoint(data.data);
          }
        );
        postMethod("api/customervisit/getCustomerExpirePointList", temparam).then(
          (data) => {
            SetCustomerExpirePoint(data.data);
          }
        );
      };
    
      // ------------------------------------------------------point
      const getCustomerDetails = () => {
        let temparam = {
          mobile: mobno,
        };
        postMethod("api/customervisit/getCustomerDetails", temparam).then(
          (dataD) => {
            var data = dataD.data;
            // alert(JSON.stringify(data));
            setCustomerDetails({
              customer_id: data.customer_id,
              full_name: data.full_name,
              mobile: data.mobile,
              total_visit: data.visit_count,
              last_visit: data.last_visit,
              gender: data.gender,
              dob: data.dob == null ? "N/A" : data.dob,
              doa: data.doa == null ? "N/A" : data.doa,
              ref_name: data.ref_name,
              ref_mobile: data.ref_mobile,
              category_name: data.category_name,
              area_name: data.area_name,
              staff_name: data.staff_name,
              profession: data.profession,
              address: data.address,
              branch_name: data.branch_name,
            });
    
            setRedeemPointData({
              ...redeemPointData,
              customer_id: data.customer_id,
              full_name: data.full_name,
              mobile: data.mobile,
            });
            setExtraPointData({
              ...extraPointData,
              customer_id: data.customer_id,
              full_name: data.full_name,
              mobile: data.mobile,
            });


            handleCustomerVoucherList(data.customer_id);
            handleCustomerPointList(data.customer_id);
          }
        );
      };
    
      //   -----------------
    
      const [totalPoint, setTotalPoint] = useState(0);
      const [text, setText] = useState("");
      const [maxLength, setMaxLength] = useState(200);
      const handleChange = (event) => {
        const newText = event.target.value;
    
        if (newText.length <= maxLength) {
          setText(newText);
          setRedeemPointData((prevState) => ({
            ...prevState,
            remark: newText,
          }));
        }
      };
    
      const [redeemPoint, setRedeemPoint] = useState("");
    
      function handlerPoint(e) {
        const redtemp = e.target.value;
        if (Number(redtemp) <= Number(totalPoint)) {
          setRedeemPoint(redtemp);
          setRedeemPointData((prevState) => ({
            ...prevState,
            redeemPoint: redtemp,
          }));
        }
      }
    
      const [redeemStaff, setRedeemStaff] = useState("");
      function handlerStaff(e) {
        const sttemp = e.target.value;
        setRedeemStaff(sttemp);
        setRedeemPointData((prevState) => ({
          ...prevState,
          staff_name: sttemp,
        }));
      }
    
      //   ---------------Redeem insert Api---------------------
    
      // ------------------------------------------------------
    
      function handlerRedeem() {
        // alert(JSON.stringify(redeemPointData));
        if (redeemPoint > totalPoint || redeemPoint == null) {
          alert("Invalid Points");
          setRedeemPoint();
          setCustomerHistory({
            ...customerHistory,
            showRedeemPoint: false,
            showRedeemList: false,
            showVoucherPoint: false,
          });
        } else {
          postMethod("api/customervisit/insertPointRedeem02", redeemPointData).then(
            (data) => {
              if (data.data[0].valid) {
                setCustomerHistory({
                  ...customerHistory,
                  showRedeemPoint: false,
                  showRedeemList: false,
                  showVoucherPoint: false,
                });
                setText("");
                setRedeemPoint("");
                setRedeemStaff("");
                alert("Points redeem succesfully");
              }
            }
          );
        }
      }
      //   ============EXTRA POINT=======================
      const [extraPointeModal, SetExtraPointeModal] = useState(false);
      const [extraPoint, setExtraPoint] = useState();
      const toggleExtraPoint = () => {
        SetExtraPointeModal(!extraPointeModal);
      };
      function handlerExtraPoint(e) {
        const redtemp = e.target.value;
    
        setExtraPoint(redtemp);
        setExtraPointData((prevState) => ({
          ...prevState,
          extra_point: redtemp,
        }));
      }
      const [extraPointStaff, setExtraPointStaff] = useState("");
      function handlerExtraPointStaff(e) {
        const sttemp = e.target.value;
        setExtraPointStaff(sttemp);
        setExtraPointData((prevState) => ({
          ...prevState,
          staff_name: sttemp,
        }));
      }
    
      const handleExtraChange = (event) => {
        const newText = event.target.value;
    
        if (newText.length <= maxLength) {
          setText(newText);
          setExtraPointData((prevState) => ({
            ...prevState,
            remark: newText,
          }));
        }
      };
    
      function addExtraPointHandler() {
        postMethod("api/customervisit/insert/extraPoint", extraPointData).then(
          (data) => {
            if (data.data[0].valid) {
              setCustomerHistory({
                ...customerHistory,
                showRedeemPoint: false,
                showRedeemList: false,
                showVoucherPoint: false,
              });
              setExtraPointStaff("");
              setExtraPoint();
              setText("");
    
              alert("Add Extra Points succesfully");
              toggleExtraPoint();
            }
          }
        );
      }
    

     
      const getReferenceList = () => {
        postMethod("api/customervisit/ReferenceList").then((res) => {
          setReferenceList(res.data);
        });
      };
      const getStaffList = () => {
        postMethod("api/customervisit/StaffList").then((res) => {
          setStaffList(res.data);
          //   alert("staff :: " + JSON.stringify(res.data));
        });
      };
      const getCategoryList = () => {
        postMethod("api/customervisit/CategoryList").then((res) => {
          setCategoryList(res.data);
        });
      };
      const getRecentCustomerList = () => {
        postMethod("api/customervisit/getRecentvisiters").then((res) => {
          setRecentCustomerList(res.data);
        });
      };
      const getNotificationList = () => {
        postMethod("api/customervisit/getNotification").then((res) => {
          setNotificationList(res.data);
        });
      };
    
      const getLastCheckinCustomerList = () => {
        let temparam = {
          branch_id: localStorage.getItem("jwl_branch_id"),
        };
        postMethod("api/customervisit/LastCheckinCustomerList", temparam).then(
          (res) => {
            if (res.data) setLastCheckinList(res.data);
          }
        );
      };
    
      useEffect(() => {
        getReferenceList();
        getNotificationList();
        getStaffList();
        getCategoryList();
        getLastCheckinCustomerList();
        getRecentCustomerList();
        getCustomerDetails();
      }, []);
      //   ==============================================
    
      return (
        <>
        <Button
          className="btn btn-info"
        onClick={() => {
            setCustomerHistory({
                ...customerHistory,
                showVoucherPoint: true,
              });
        }}>
          REDEEM
        </Button>

        
          {/* --------------------------- */}    
          <Modal
            isOpen={customerHistory.showVoucherPoint}
            zIndex="999999"
            size="lg"
            backdrop="static"
            centered="true"
          >
            <ModalHeader>Customer Redeem Point System</ModalHeader>
    
            <ModalBody>
              <Row>
                <Card className="shadow ml-4" style={{ width: "58rem" }}>
                  <CardHeader className="d-flex align-items-center justify-content-between font-weight-bold ">
                    <Button
                      className="btn btn-info font-weight-bold"
                      color="primary"
                      onClick={() => {
                        setCustomerHistory({
                          ...customerHistory,
                          showRedeemList: true,
                          showRedeemPoint: false,
                        });
                      }}
                    >
                      Vouchers : {voucherCount}
                    </Button>
                    <Button
                      className="btn btn-info font-weight-bold"
                      color="primary"
                      onClick={() => {
                        setCustomerHistory({
                          ...customerHistory,
                          showRedeemPoint: true,
                          showRedeemList: false,
                        });
                      }}
                    >
                      Points : {totalPoint}
                    </Button>
                  </CardHeader>
                </Card>
              </Row>
    
              <Row>
                <Card
                  className="my-2 ml-4"
                  color="secondary"
                  inverse
                  style={{
                    width: "58rem",
                  }}
                >
                  <CardHeader>
                    <i>PROFILE</i>
                  </CardHeader>
                  <CardBody>
                    <CardText>
                      <div className="dialog-border">
                        <div className="container-fluid customerDetails">
                          <div className="row">
                            <div className="col-md-4">
                              <p className="text-light mb-3">{"Name"}</p>
                              <h4 className="text-light">
                                {customerDetails.full_name || "N/A"}
                              </h4>
                            </div>
                            <div className="col-md-4">
                              <p className="text-light  mb-3">{"Date Of Birth"}</p>
                              <h4 className="text-light ">
                                {customerDetails.dob || "N/A"}
                              </h4>
                            </div>
                            <div className="col-md-4">
                              <p className="text-light mb-3">
                                {"Date Of Anniversary"}
                              </p>
                              <h4 className="text-light">
                                {customerDetails.doa || "N/A"}
                              </h4>
                            </div>
                          </div>
    
                          <Divider className="my-3" />
    
                          <div className="row">
                            <div className="col-md-4">
                              <p className="text-light mb-3">{"Category Name"}</p>
                              <h4 className="text-light">
                                {customerDetails.category_name || "N/A"}
                              </h4>
                            </div>
                            <div className="col-md-4">
                              <p className="text-light mb-3">{"Total Visit"}</p>
                              <h4 className="text-light">
                                {customerDetails.total_visit || "N/A"}
                              </h4>
                            </div>
                            <div className="col-md-4">
                              <p className="text-light mb-3">{"Last Visit"}</p>
                              <h4 className="text-light">
                                {moment(customerDetails.date).format(
                                  "DD/MM/YYYY HH:mm"
                                ) || "N/A"}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardText>
                  </CardBody>
                </Card>
              </Row>
            </ModalBody>
    
            <ModalFooter>
              <Button
                className="btn btn-primary m-2"
                color="secondary"
                onClick={() => toggleExtraPoint()}
              >
                Extra
              </Button>
              <Button
                className="btn btn-danger"
                onClick={() => {
                  setCustomerHistory({
                    ...customerHistory,
                    showVoucherPoint: false,
                  });
                }}
                color="secondary"
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>
    
          {/* Voucher */}
    
          <Modal
            isOpen={customerHistory.showRedeemList}
            zIndex="9999999"
            size="lg"
            backdrop="static"
            centered="true"
          >
            <div className="dialog-border">
              {/* <div>
                <Button class="btn btn-success m-1">Active Voucher</Button>
                <Button class="btn btn-secondary m-1">Redeem Voucher</Button>
                <Button class="btn btn-danger m-1">Expire Voucher</Button>
              </div> */}
              <ModalBody
                className="table-responsive"
                style={{ maxHeight: "550px" }}
              >
                <Row>
                  <Card className="shadow ml-4" style={{ width: "58rem" }}>
                    <CardHeader className="d-flex align-items-center justify-content-between font-weight-bold">
                      <Col>
                        <i>{customerDetails.full_name}</i>
                      </Col>
                      <Col>
                        <i>{customerDetails.mobile}</i>
                      </Col>
                      <Col style={{ backgroundColor: "Orange", color: "white" }}>
                        <Button
                          style={{ backgroundColor: "Orange", color: "white" }}
                        >
                          Total Point:{totalPoint}
                        </Button>
                      </Col>
                    </CardHeader>
                  </Card>
                </Row>
    
                <ReactDatatable
                  config={config}
                  records={CustomerVoucherList}
                  columns={voucher_columns}
                />
              </ModalBody>
    
              <ModalFooter>
                <Button
                  className="btn btn-primary m-2"
                  onClick={() => {
                    setCustomerHistory({
                      ...customerHistory,
                      showRedeemList: false,
                      showRedeemPoint: false,
                    });
                  }}
                >
                  Back
                </Button>
                <Button
                  className="btn btn-danger"
                  onClick={() => {
                    setCustomerHistory({
                      ...customerHistory,
                      showRedeemList: false,
                      showRedeemPoint: false,
                      showVoucherPoint: false,
                    });
                  }}
                >
                  Close
                </Button>
              </ModalFooter>
            </div>
          </Modal>
    
          {/* Points */}
    
          <Modal
            isOpen={customerHistory.showRedeemPoint}
            zIndex="9999999"
            size="lg"
            backdrop="static"
            centered="true"
          >
            <div className="dialog-border">
              <div>
                <Button class="btn btn-success m-1" onClick={toggleActivePoint}>
                  Active Points
                </Button>
                <Button class="btn btn-secondary m-1" onClick={toggleRedeemPoint}>
                  Redeem Points
                </Button>
                <Button class="btn btn-danger m-1" onClick={toggleExpirePoint}>
                  Expire Points
                </Button>
              </div>
              <ModalBody>
                <Row>
                  <Card className="shadow ml-4" style={{ width: "58rem" }}>
                    <CardHeader className="d-flex align-items-center justify-content-between font-weight-bold">
                      <Col>
                        <i>{customerDetails.full_name || ""}</i>
                      </Col>
                      <Col>
                        <i>{customerDetails.mobile}</i>
                      </Col>
                      <Col>
                        <Button
                          style={{ backgroundColor: "orange", color: "white" }}
                        >
                          TotalPoint: {totalPoint}
                        </Button>
                      </Col>
                    </CardHeader>
                  </Card>
                </Row>
                <Row>
                  <Col>
                    <Form>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label for="point1">Redeem Point</Label>
                            <Input
                              type="number"
                              name="point1"
                              id="point1"
                              required
                              value={redeemPoint}
                              placeholder="Redeem Point"
                              onChange={handlerPoint}
                              valid={
                                redeemPoint !== "" &&
                                Number(redeemPoint) <= totalPoint
                              }
                              invalid={
                                redeemPoint !== "" &&
                                Number(redeemPoint) > totalPoint
                              }
                            />
                            <FormFeedback valid tooltip>
                              Sweet! The value is within the allowed range.
                            </FormFeedback>
                            <FormFeedback invalid tooltip>
                              Oh opps! The value exceeds the allowed Points.
                            </FormFeedback>
                            <FormText>
                              Enter a value that is smaller or equal to {totalPoint}
                              .
                            </FormText>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label for="staff_id">Staff Name</Label>
                            <Input
                              id="saff_id"
                              name="saff_id"
                              type="select"
                              value={redeemStaff}
                              onChange={handlerStaff}
                            >
                              <option value="">Select Staff Name</option>
                              {staffList.map((i) => (
                                <option key={i.id} value={i.name}>
                                  {i.name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="ml-1">
                        <FormGroup>
                          <Label>Remark</Label>
                          <Input
                            value={text}
                            onChange={handleChange}
                            required={true}
                            placeholder="Type here..."
                            rows={5}
                            cols={100}
                            type="textarea"
                          />
    
                          <h5 style={{ color: "blue" }}>
                            {maxLength - text.length}/{maxLength}
                          </h5>
                        </FormGroup>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </ModalBody>
    
              <ModalFooter>
              <Button className="btn btn-dark" onClick={() => handlerRedeem()}>
                  Submit
                </Button>
                <Button
                  className="btn btn-primary m-2"
                  onClick={() => {
                    setCustomerHistory({
                      ...customerHistory,
                      showRedeemList: false,
                      showRedeemPoint: false,
                    });
                  }}
                  color="secondary"
                >
                  Back
                </Button>
                <Button
                  className="btn btn-danger"
                  onClick={() => {
                    setCustomerHistory({
                      ...customerHistory,
                      showRedeemList: false,
                      showRedeemPoint: false,
                      showVoucherPoint: false,
                    });
                  }}
                  color="secondary"
                >
                  Close
                </Button>
               
              </ModalFooter>
            </div>
          </Modal>
    
          {/* -----------------------ActivePointModal-------------------------- */}
    
          <Modal
            isOpen={pointActiveModal}
            zIndex="9999999"
            size="lg"
            backdrop="static"
            centered="true"
          >
            <ModalHeader>
              <Row style={{ marginBottom: "10px" }}>
                <Col sm="6">Active Customer Point</Col>
    
                <Col sm="6">
                  <i
                    className="fa fa-close pull-right pointerShow"
                    onClick={toggleActivePoint}
                  ></i>
                </Col>
              </Row>
            </ModalHeader>
    
            <ModalBody style={{ maxHeight: "400px", overflowY: "auto" }}>
              <ReactDatatable
                config={config_AREPoint}
                records={CustomerPointList}
                columns={ActivePoint_Columns}
              />
            </ModalBody>
          </Modal>
    
          {/* ------------------------------------ */}
    
          {/* -----------------------RedeemPointModal-------------------------- */}
    
          <Modal
            isOpen={pointRedeemModal}
            zIndex="9999999"
            size="lg"
            backdrop="static"
            centered="true"
          >
            <ModalHeader>
              <Row style={{ marginBottom: "10px" }}>
                <Col sm="6">Redeem Customer Point</Col>
    
                <Col sm="6">
                  <i
                    className="fa fa-close pull-right pointerShow"
                    onClick={toggleRedeemPoint}
                  ></i>
                </Col>
              </Row>
            </ModalHeader>
    
            <ModalBody style={{ maxHeight: "400px", overflowY: "auto" }}>
              <ReactDatatable
                config={config_AREPoint}
                records={CustomerRedeemPoint}
                columns={RedeemPoint_Columns}
              />
            </ModalBody>
          </Modal>
    
          {/* ------------------------------------ */}
    
          {/* -----------------------ExpirePointModal-------------------------- */}
    
          <Modal
            isOpen={pointExpireModal}
            zIndex="9999999"
            size="lg"
            backdrop="static"
            centered="true"
          >
            <ModalHeader>
              <Row style={{ marginBottom: "10px" }}>
                <Col sm="6">Customer Expired Point</Col>
    
                <Col sm="6">
                  <i
                    className="fa fa-close pull-right pointerShow"
                    onClick={toggleExpirePoint}
                  ></i>
                </Col>
              </Row>
            </ModalHeader>
    
            <ModalBody style={{ maxHeight: "400px", overflowY: "auto" }}>
              <ReactDatatable
                config={config_AREPoint}
                records={CustomerExpirePoint}
                columns={ExpirePoint_Columns}
              />
            </ModalBody>
          </Modal>
    
          {/* ------------------------------------ */}
    
          <Modal
            isOpen={extraPointeModal}
            zIndex="9999999"
            size="lg"
            backdrop="static"
            centered="true"
          >
            <div className="dialog-border">
              <ModalHeader>
                <Row style={{ marginBottom: "10px" }}>
                  <Col sm="6">Extra Customer Point</Col>
    
                  <Col sm="6">
                    <i
                      className="fa fa-close pull-right pointerShow"
                      onClick={() => {
                        toggleExtraPoint();
                      }}
                    ></i>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Card className="shadow ml-4" style={{ width: "58rem" }}>
                    <CardHeader className="d-flex align-items-center justify-content-between font-weight-bold">
                      <Col>
                        <i>{customerDetails.full_name || ""}</i>
                      </Col>
                      <Col>
                        <i>{customerDetails.mobile}</i>
                      </Col>
                      <Col>
                        <Button
                          style={{ backgroundColor: "orange", color: "white" }}
                        >
                          TotalPoint: {totalPoint}
                        </Button>
                      </Col>
                    </CardHeader>
                  </Card>
                </Row>
                <Row>
                  <Col>
                    <Form>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label fo="point1">Extra Points</Label>
    
                            <Input
                              type="number"
                              name="point1"
                              id="point1"
                              required
                              value={extraPoint}
                              placeholder="Extra Point"
                              onChange={handlerExtraPoint}
                            />
                          </FormGroup>
                        </Col>
    
                        <Col>
                          <FormGroup>
                            <Label for="staff_id">Staff Name</Label>
                            <Input
                              id="saff_id"
                              name="saff_id"
                              type="select"
                              value={extraPointStaff}
                              onChange={handlerExtraPointStaff}
                            >
                              <option value="">Select Staff Name</option>
                              {staffList.map((i) => (
                                <option key={i.id} value={i.name}>
                                  {i.name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="ml-1">
                        <FormGroup>
                          <Label>Remark</Label>
                          <Input
                            value={text}
                            onChange={handleExtraChange}
                            required={true}
                            placeholder="Type here..."
                            rows={5}
                            cols={100}
                            type="textarea"
                          />
    
                          <h5 style={{ color: "blue" }}>
                            {maxLength - text.length}/{maxLength}
                          </h5>
                        </FormGroup>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </ModalBody>
    
              <ModalFooter>
              <Button
                  className="btn btn-dark"
                  onClick={() => addExtraPointHandler()}
                >
                  Add Point
                </Button>
                <Button
                  className="btn btn-primary m-2"
                  color="secondary"
                  onClick={() => {
                    toggleExtraPoint();
                  }}
                >
                  Back
                </Button>
                <Button
                  className="btn btn-danger"
                  onClick={() => {
                    setCustomerHistory({
                      ...customerHistory,
                      showRedeemList: false,
                      showRedeemPoint: false,
                      showVoucherPoint: false,
                    });
                    toggleExtraPoint();
                  }}
                  color="secondary"
                >
                  Close
                </Button>
               
              </ModalFooter>
            </div>
          </Modal>
        </>
      );
    }
    