import React, { Fragment, useEffect, useState } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { postMethod } from "../../../../_services/_postMethod";
import Loader from "../../../modules/loader";

const Stocktransfer = (props) => {
  const [grid, setGrid] = React.useState([]);
  const [load, setLoad] = React.useState(false);
  const editRecord = (val) => {
    var temp = window.btoa(val);
    props.history.push("/branch/stock-transfer/add?id=" + temp);
  };

  const [gridParam, setGridParam] = useState({});
  const columns = [
      {
      key: "entry_no",
      text: "Entry No",
      align: "left",
      sortable: true,
    },  
    {
      key: "entry_date",
      text: "Date",
      align: "left",
      sortable: true,
    },    
    {
      key: "to_branch",
      text: "To Branch",
      align: "left",
      sortable: true,
    },
    {
      key: "tranfer_product",
      text: "Transfer qty",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
           <span>{record.tranfer_product}({record.transfer_qty})</span>
          </Fragment>
        );
      },
    },
      
    {
      key: "accept_qty",
      text: "Accept product",
      align: "left",
      sortable: true, cell: (record) => {
        return (
          <Fragment> 
           <span>{record.accept_product}({record.accept_qty})</span>
          </Fragment>
        );
      },
    },
    {
      key: "status",
      text: "Status",
      align: "left",
      sortable: true,
    },
    {
      key: "remarks",
      text: "Remarks",
      align: "left",
      sortable: true,
    },

    {
      key: "action",
      text: "Action",
      className: "action text-nowrap",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="btn btn-link btn-sm"
              onClick={() => editRecord(record.tran_id)}
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-edit"></i>
            </button>
            {record.status =="Pending" ?
             <button
             className="btn btn-link btn-sm"
             onClick={() => {
               cancelRecord(record)}}
             style={{ marginRight: "5px" }}
           >
             <i className="fa fa-times"></i>
           </button>:""
          }
           
          </Fragment>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
  };

  const cancelRecord = (record) =>{
    console.log(record);
    if (window.confirm("are you sure want to cancel?")) {
      postMethod("api/transactions/stockcancel/cancel", {
        tran_id: record.tran_id,
        cancel_from_branch_id:record.branch_id,
        cancel_to_branch_id : record.to_branch_id
      }).then((data) => {  
      window.location.reload();
        setLoad(false);
      });
     }   
  }

  useEffect(() => {
    setLoad(true);
    postMethod("api/transactions/stockTransfer/browse", gridParam).then((data) => {
      setGrid(data.data);
      setLoad(false);
    });
  }, []);

  return (
    <>
      {load ? <Loader /> : null}
      <div className="card card-custom gutter-b">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">Stock Transfer</h3>
          </div>
          <div className="card-toolbar">
            <a
              href="/branch/stock-transfer/add"
              className="btn btn-sm btn-outline-theme font-weight-bold py-2 px-3"
            >
              <i className="flaticon2-user"></i> Stock Transfer
            </a>
          </div>
        </div>
        <div className="card-body">
          <ReactDatatable config={config} records={grid} columns={columns} />
        </div>
      </div>
    </>
  );
};

export default Stocktransfer;
