import { CircularProgress } from "@material-ui/core";
import React from "react";
import logo from "../../logo.svg";

const styles = {
  loader: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    background: "#fff",
    width: "100%",
    height: "100%",
    zIndex: 9999,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loaderImg: {
    display: "block",
  },
};

const Loader = (props) => {
  return (
    <div className="data_loader" style={styles.loader}>
      <h1>Loading..</h1>
      <CircularProgress color="secondary" />
    </div>
  );
};

export default Loader;
