import React from 'react';
import './loader.css';

const FullScreenLoader = ({state=false}) => {

  return (
    (state) &&
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(255, 255, 255, 0.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999999999999,
      }}
    >
      <div
        style={{
          width: '100px',
          height: '100px',
          border: '5px solid #333',
          borderTop: '5px solid transparent',
          borderRadius: '50%',
          animation: 'spin 1s linear infinite',
        }}
      ></div>
    </div>
  );
};

export default FullScreenLoader;
