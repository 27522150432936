import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../../../_services/constant";
import { postMethod } from "../../../../_services/_postMethod";
import videocall from "../../../../_assets/media/videocall.jpg";
import checked_icon from "../../../../_assets/media/checked.png";

const CustomerHeader = (props) => {
  const { count } = props;
  const [open, setOpen] = useState(false);
  const mobile_no = localStorage.getItem("cust_mobile");
  const vcall = localStorage.getItem("jwl_customer_vcall");
  const [whatsappNo, setWhatsappNo] = useState("");
  const [countInnernal, setCountInnernal] = useState(0);
  const [bookedStatus, setBookedStatus] = useState(false);
  const [seeDesign, setSeeDesign] = useState(false);
  useEffect(() => {
    setCountInnernal(count);
  }, [count]);

  const [customerName, setCustomerName] = useState("");

  const [checkWhatsapp, setWhatsappCheck] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const handleWhatsappCheck = (event) => {
    setWhatsappCheck(event.target.checked);
  };

  useEffect(() => {
    postMethod("api/session/wishlist", {}).then((data) => {
      setCountInnernal(data.data.length);
    });
  }, []);

  const bookVideoCall = () => {
    var param = {
      tran_id: "0",
      name: customerName,
      visit_type: "video call",
      mobile: checkWhatsapp ? localStorage.getItem("cust_mobile") : whatsappNo,
    };
    postMethod("api/session/Insert_appointment", param).then((data) => {
      if (data.data[0].status == "true") {
        setBookedStatus(true);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    });
  };

  return (
    <React.Fragment>
      <Dialog
        maxWidth="xs"
        fullWidth
        className={seeDesign ? "video_call" : "see_design"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="p-0">
          {seeDesign ? (
            bookedStatus ? (
              <div className="bookedStatus text-center my-5">
                <img src={checked_icon} className="mb-3" />
                <h3>Booked Successfully</h3>
                <p>Our staff executive will reach you soon</p>
              </div>
            ) : (
              <React.Fragment>
                <img src={videocall} className="w-100" />
                <div className="p-4">
                  <h3 className="mb-3">Try before you buy</h3>
                  <div className="form-group mb-2 mt-3">
                    <label className="font-weight-bold">Customer Name</label>
                    <input
                      className="form-control"
                      type="text"
                      value={customerName}
                      onChange={(e) => setCustomerName(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-2">
                    <label className="font-weight-bold">Mobile No.</label>
                    <input
                      className="form-control"
                      type="text"
                      disabled
                      value={mobile_no}
                    />
                  </div>
                  <div className="form-check mb-2">
                    
                      <input
                        type="checkbox"
                        name="whatsapp"
                        className="form-check-input"
                        checked={checkWhatsapp}
                        onChange={handleWhatsappCheck}
                        
                      />
                      <label style={{paddingLeft:5}} className="form-check-label" for="whatsapp">
                      It is your whatsapp no.?
                    </label>
                  </div>
                  {!checkWhatsapp ? (
                    <div className="form-group">
                      <label className="font-weight-bold">Whatsapp No.</label>
                      <input
                        className="form-control"
                        type="number"
                        value={whatsappNo}
                        onChange={(e) => setWhatsappNo(e.target.value)}
                      />
                    </div>
                  ) : null}
                  <button
                    onClick={bookVideoCall}
                    className="btn btn-new-theme btn-primary w-100 py-3 mt-2"
                  >
                    <i className="fas fa-video"></i>{" "}
                    <span className="font-weight-bold">Book a video call</span>
                  </button>
                  <p style={{ fontSize: 13 }} className="mt-2 text-center">
                    Schedule a Video Call to see this Design Live .{" "}
                  </p>
                </div>
              </React.Fragment>
            )
          ) : (
            <div className="seeDesign h-100 d-flex align-items-center p-3 text-center">
              <div className="position-relative text-white">
                <h2>Your Favourite Designs at Your Doorstep!</h2>
                <button
                  className="btn btn-see-design font-weight-bold py-3 px-4 mt-3"
                  onClick={() => setSeeDesign(true)}
                >
                  See Designs
                </button>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
      <header className="main_header_area">
        <div className="container">
          <div className="row toppad2 mtoppad2">
            <div className="col-6 col-sm-3 col-lg-3 logo_session">
              <img
                onClick={() => (window.location.pathname = "exhibition-view/home")}
                src={`${API_BASE_URL}BranchLogo/${localStorage.getItem(
                  "jwl_logo"
                )}`}
                className="img-fluid mlogo"
              />
            </div>
            <div className="col-6 col-sm-9 col-lg-9 rightnav">
              <ul>
                <li className="d-block d-md-none">
                  <a href="tel:+9898989898">
                    <i className="fa fa-phone protxt" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={() => {
                      window.location.pathname = "exhibition-view/profile";
                    }}
                  >
                    <i className="fa fa-user protxt" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={() => {
                      window.location.pathname = "exhibition-view/wish-list";
                    }}
                  >
                    <span className="bdgicon">
                      <i
                        className={
                          countInnernal > 0
                            ? "fa fa-heart text-danger"
                            : "fa fa-heart-o"
                        }
                        style={{ color: "#007bff" }}
                        aria-hidden="true"
                      ></i>
                      <span className="bdg">{countInnernal}</span>
                    </span>
                  </a>
                </li>
                {vcall ?    <li>
                  <button
                    className="btn btn-new-theme btn-primary"
                    onClick={() => setOpen(true)}
                  >
                    <i className="fas fa-video"></i> Book a video call
                  </button>
                </li> : null}
             
              </ul>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default CustomerHeader;
