import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Label, Input, Alert } from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { postMethod } from "../../../../_services/_postMethod";
import Loader from "../../../modules/loader";
import { API_BASE_URL } from "../../../../_services/constant";
import { post } from "axios";
import { useParams, useLocation } from "react-router-dom";
import {Form} from "reactstrap";


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginBottom: 15,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const WhatsAppTemplate = (props) => {
  let queryParams = useQuery();
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const [load, setLoad] = useState(false);
  const [loadInser, setLoadInsert] = useState(false);
  const companyName = localStorage.getItem("jwl_brand_name");
  const [paramSMS, setParamSMS] = useState({
    welcome_1: "",
    welcome_2: "",
    welcome_template_HTML:
      "welcome to <div class='text-red ml-1 mr-1'>Variable Part</div> prestigious <div class='text-red ml-1 mr-1'>Variable Part</div>, " +
      companyName.toUpperCase() +
      "! We honoured and hope you will have a great experience.",
    ref_1: "",
    ref_template_HTML:
      "thanks for referring <div class='text-red ml-1 mr-1'>Variable Part</div> to <div class='text-red ml-1 mr-1'>Variable Part</div>. We are grateful for your love and support. Team " +
      companyName.toUpperCase() +
      ".",
    thank_you: "",
    thank_you_template_HTML:
      "thank you for visiting " +
      companyName.toUpperCase() +
      ". Hope you had a great experience. Kindly contact us <div class='text-red ml-1 mr-1'>Variable Part</div> for any assistance.",
    rate_us: "",
    rate_us_template_HTML:
      "Please rate us at <div class='text-red ml-1 mr-1'>Variable Part</div>. Team " +
      companyName.toUpperCase() +
      ". Thank you.",
    try: "",
    try_template_HTML:
      "just click http://j-qt.in/<div class='text-red ml-1 mr-1'>$$URL$$</div> to check our <div class='text-red ml-1 mr-1'>Variable Part</div>. Team " +
      companyName.toUpperCase() +
      ". Thank you.",
    customised_catalogue_HTML:
      "just click <div class='text-red ml-1 mr-1'>Variable Part</div> to check <div class='text-red ml-1 mr-1'>Variable Part</div>. Team " +
      companyName.toUpperCase() +
      ". Thank you",
    video_call_booked_1: "",
    video_call_booked_template_HTML:
      "congratulation your video call session booked. Date <div class='text-red ml-1 mr-1'>Variable Part</div> and Time <div class='text-red ml-1 mr-1'>Variable Part</div>. Team " +
      companyName.toUpperCase() +
      ".",
    sales_message: "",
    sales_message_temp:
      "Dear <div class='text-red ml-1 mr-1'>$$MemberName$$</div>, thank you for <div class='text-red ml-1 mr-1'>$$Variable$$</div>. Hope to serve you again. Thank you. Team <div class='text-red ml-1 mr-1'>$$BrandName$$</div>",
    video_call_new: "",
    video_call_new_temp: "",
    missed_call: "",
    missed_call_temp: "",
    redeem: "",
    redeem_temp: "",
    referrel: "",
    referrel_temp: "",
    stock_transfer_a: "",
    stock_transfer_a_temp: "",
    stock_transfer_b: "",
    stock_transfer_b_temp: "",
    stock_accept_a: "",
    stock_accept_a_temp: "",
    stock_accept_b: "",
    stock_accept_b_temp: "",
    upload_voucher: "",
    upload_voucher_temp: "",
    referral_thirt_party: "",
    referral_thirt_party_temp: "",
  });

  const [param, setParam] = useState({
    name: "name",
    otp:
      companyName.toUpperCase() +
      " XXXX is your one-time password (OTP) for login. Please enter the OTP to proceed. Team " +
      companyName.toUpperCase() +
      ".",
    welcome: "",
    welcome_temp:
      "welcome to  prestigious , MALIRAM JEWELLERS! We honoured and hope you will have a great experience.",
    birthday: "",
    birthday_temp:
      companyName.toUpperCase() +
      " wish you a wonderful BIRTHDAY! May this day be filled with manyhappy hours and your life with many birthdays.",
    anniversary: "",
    anniversary_temp:
      companyName.toUpperCase() +
      " wishing you a HAPPY ANNIVERSARY! Wishing you all the happiness and love in the world. Congratulation.",
    reference: "",
    reference_temp:
      "thanks for referring " +
      companyName.toUpperCase() +
      " to $$MemberName$$. We are grateful for your love and support. Team " +
      companyName.toUpperCase() +
      ".",
    upload_design: "",
    upload_design_temp:
      "thank you for sharing designs. We appreciate and will try to get back to you with the closet we have. Team $$BrandName$$.",
    thank_you: "",
    thank_you_temp: "",
    rate_us: "",
    rate_us_temp:
      "Please rate us at  http://j-qt.in/$$URL$$. Team " +
      companyName.toUpperCase() +
      ". Thank you.",
    common: "",
    common_temp: "",
    customised_catalogue: "",
    customised_catalogue_temp: "",
    video_call_request: "",
    video_call_request_temp:
      "welcome to " +
      companyName.toUpperCase() +
      " live support. Our staff executive will reach you soon.Thank you.",
    video_call_booked: "",
    video_call_booked_temp:
      "congratulation your video call session booked. Date {#var#} and Time {#var#}. Team " +
      companyName.toUpperCase() +
      ".",
    sales_message: false,
    sales_message_temp:
      "Dear $$MemberName$$, thank you for $$value$$. Hope to serve you again. Thank you. Team $$BrandName$$.",
    video_call_new: false,
    video_call_new_temp:
      "Dear $$MemberName$$, your video call appointment has been booked on $$Date$$ at $$Time$$. Please contact if you need any assistance. Team $$BrandName$$.",
    missed_call: false,
    missed_call_temp:
      "Dear $$MemberName$$, your appointment has been booked on $$Date$$ at $$Time$$. Please contact if you need any assistance. Team $$BrandName$$.",
    redeem: false,
    redeem_temp:
      "Dear $$MemberName$$, your voucher has been redeemed successfully. Thank you. Team $$BrandName$$.",
    referrel: false,
    referrel_temp:
      "Dear $$MemberName$$, $$Name$$ refer you $$ThirdPartyName$$. Provide us a chance to serve you with the best. Thank you. Team $$BrandName$$.",
    stock_transfer_a: false,
    stock_transfer_a_temp:
      "Dear $$MemberName$$, you transferred $$Quantity$$ products to $$ToBranch$$. Please check. Thank you. Team $$BrandName$$.",
    stock_transfer_b: false,
    stock_transfer_b_temp:
      "Dear $$MemberName$$, $$ToBranch$$ accepted $$Quantity$$ from our store. Please check. Thank you. Team $$BrandName$$.",
    stock_accept_a: false,
    stock_accept_a_temp:
      "Dear $$MemberName$$, $$FromBranch$$ transfer you $$Quantity$$ products. Please check. Thank you. Team $$BrandName$$.",
    stock_accept_b: false,
    stock_accept_b_temp:
      "Dear $$MemberName$$, our store accepted $$Quantity$$ products from $$FromBranch$$. Please check. Thank you. Team $$BrandName$$.",
    upload_voucher: false,
    upload_voucher_temp:
      "Dear $$MemberName$$, thanks for sharing designs. We appreciate and offer $$Var1$$ gift $$Var2$$. Validity ($$ValidityDate$$). Team $$BrandName$$.",
    referral_thirt_party: false,
    referral_thirt_party_temp:
      "Dear $$MemberName$$, thanks for sharing designs. We appreciate and offer $$Var1$$ gift $$Var2$$. Validity ($$ValidityDate$$). Team $$BrandName$$.",
  });
  const [success, setSuccess] = useState(false);
  const companyLogo =
    API_BASE_URL + "BranchLogo/" + localStorage.getItem("jwl_company_logo");


  useEffect(() => {
    setLoad(true);
    postMethod("api/sms/smssettings/whatsapp_Preview", {}).then((dataD) => {
      var data = dataD?.data[0];
      if (data) {
        setParam({
          ...param,
          name: data?.name,
          otp:
            companyName.toUpperCase() +
            " XXXX is your one-time password (OTP) for login. Please enter the OTP to proceed. Team " +
            companyName.toUpperCase() +
            ".",
          welcome: data?.welcome,
          welcome_temp: data?.welcome_temp,
          birthday: data?.birthday,
          birthday_temp:
            companyName.toUpperCase() +
            " wish you a wonderful BIRTHDAY! May this day be filled with many happy hours and your life with many birthdays.",
          anniversary: data?.anniversary,
          anniversary_temp:
            companyName.toUpperCase() +
            " wishing you a HAPPY ANNIVERSARY! Wishing you all the happiness and love in the world. Congratulation.",
          reference: data?.reference,
          reference_temp: data?.reference_temp,
          upload_design: data?.upload_design,
          upload_design_temp: data?.upload_design_temp,
          thank_you: data?.thank_you,
          thank_you_temp: data?.thank_you_temp,
          rate_us: data?.rate_us,
          rate_us_temp:
            "Please rate us at <div class='text-red ml-1 mr-1'>Variable Part</div>. Team " +
            companyName.toUpperCase() +
            ". Thank you.",
          common: data?.common,
          common_temp: data?.common_temp,
          customised_catalogue: data?.customised_catalogue,
          customised_catalogue_temp: data?.customised_catalogue_temp,
          video_call_request: data?.video_call_request,
          video_call_request_temp: data?.video_call_request_temp,
          video_call_booked: data?.video_call_booked,
          video_call_booked_temp: data?.video_call_booked_temp,
          sales_message: data?.sales ? data?.sales : false,
          sales_message_temp: data?.sales_temp
            ? data?.sales_temp
            : "Dear $$MemberName$$, thank you for $$value$$. Hope to serve you again. Thank you. Team $$BrandName$$.",
          video_call_new: data?.video_call_new ? data?.video_call_new : false,
          video_call_new_temp: data?.video_call_new_temp
            ? data?.video_call_new_temp
            : "Dear $$MemberName$$, your video call appointment has been booked on $$Date$$ at $$Time$$. Please contact if you need any assistance. Team $$BrandName$$.",
          missed_call: data?.appointment_misscall_booked
            ? data?.appointment_misscall_booked
            : false,
          missed_call_temp: data?.appointment_misscall_booked_temp
            ? data?.appointment_misscall_booked_temp
            : "Dear $$MemberName$$, your appointment has been booked on $$Date$$ at $$Time$$. Please contact if you need any assistance. Team $$BrandName$$.",
          redeem: data?.redeem ? data?.redeem : false,
          redeem_temp: data?.redeem_temp
            ? data?.redeem_temp
            : "Dear $$MemberName$$, your voucher has been redeemed successfully. Thank you. Team $$BrandName$$.",
          referrel: data?.referral ? data?.referral : false,
          referrel_temp: data?.referral_temp
            ? data?.referral_temp
            : "Dear $$MemberName$$, $$Name$$ refer you $$ThirdPartyName$$. Provide us a chance to serve you with the best. Thank you. Team $$BrandName$$.",
          stock_transfer_a: data?.stock_transfer ? data?.stock_transfer : false,
          stock_transfer_a_temp: data?.stock_transfer_temp
            ? data?.stock_transfer_temp
            : "Dear $$MemberName$$, you transferred $$Quantity$$ products to $$ToBranch$$. Please check. Thank you. Team $$BrandName$$.",
          stock_transfer_b: data?.stock1_transfer
            ? data?.stock1_transfer
            : false,
          stock_transfer_b_temp: data?.stock1_transfer_temp
            ? data?.stock1_transfer_temp
            : "Dear $$MemberName$$, $$ToBranch$$ accepted $$Quantity$$ from our store. Please check. Thank you. Team $$BrandName$$.",
          stock_accept_a: data?.stock_acceptance
            ? data?.stock_acceptance
            : false,
          stock_accept_a_temp: data?.stock_acceptance_temp
            ? data?.stock_acceptance_temp
            : "Dear $$MemberName$$, $$FromBranch$$ transfer you $$Quantity$$ products. Please check. Thank you. Team $$BrandName$$.",
          stock_accept_b: data?.stock1_acceptance
            ? data?.stock1_acceptance
            : false,
          stock_accept_b_temp: data?.stock1_acceptance_temp
            ? data?.stock1_acceptance_temp
            : "Dear $$MemberName$$, our store accepted $$Quantity$$ products from $$FromBranch$$. Please check. Thank you. Team $$BrandName$$.",
          upload_voucher: data?.upload_voucher ? data?.upload_voucher : false,
          upload_voucher_temp: data?.upload_voucher_temp
            ? data?.upload_voucher_temp
            : "Dear $$MemberName$$, thanks for sharing designs. We appreciate and offer $$Var1$$ gift $$Var2$$. Validity ($$ValidityDate$$). Team $$BrandName$$.",
          referral_thirt_party: data?.referral_thirt_party
            ? data?.referral_thirt_party
            : false,
          referral_thirt_party_temp: data?.referral_thirt_party_temp
            ? data?.referral_thirt_party_temp
            : "Dear $$MemberName$$, thanks for sharing designs. We appreciate and offer $$Var1$$ gift $$Var2$$. Validity ($$ValidityDate$$). Team $$BrandName$$.",
        });
        setParamSMS({
          ...paramSMS,
          welcome_template_HTML: data.welcome_temp,
          ref_template_HTML: data.reference_temp,
          thank_you_template_HTML: data.thank_you_temp,
        });
        setWhatsappParam({
          ...WhatsappParam,
          wht_welcome_images: data?.wht_welcome_images
            ? API_BASE_URL + "Images/" + data?.wht_welcome_images
            : companyLogo,
          wht_welcome_text: data?.wht_welcome_text,
          wht_Birthday_images: data?.wht_Birthday_images
            ? API_BASE_URL + "Images/" + data?.wht_Birthday_images
            : companyLogo,
          wht_Birthday_text: data?.wht_Birthday_text,
          wht_AddSales_images: data?.wht_AddSales_images
            ? API_BASE_URL + "Images/" + data?.wht_AddSales_images
            : companyLogo,
          wht_AddSales_text: data?.wht_AddSales_text,
          wht_Anniversary_images: data?.wht_Anniversary_images
            ? API_BASE_URL + "Images/" + data?.wht_Anniversary_images
            : companyLogo,
          wht_Anniversary_text: data?.wht_Anniversary_text,
          wht_CustomizedCatalogue_images: data?.wht_CustomizedCatalogue_images
            ? API_BASE_URL + "Images/" + data?.wht_CustomizedCatalogue_images
            : companyLogo,
          wht_CustomizedCatalogue_text: data?.wht_CustomizedCatalogue_text,
          wht_General_images: data?.wht_General_images
            ? API_BASE_URL + "Images/" + data?.wht_General_images
            : companyLogo,
          wht_General_text: data?.wht_General_text,
          wht_MissedCall_images: data?.wht_MissedCall_images
            ? API_BASE_URL + "Images/" + data?.wht_MissedCall_images
            : companyLogo,
          wht_MissedCall_text: data?.wht_MissedCall_text,
          wht_Otp_images: data?.wht_Otp_images
            ? API_BASE_URL + "Images/" + data?.wht_Otp_images
            : companyLogo,
          wht_Otp_text: data?.wht_Otp_text,
          wht_RateUs_images: data?.wht_RateUs_images
            ? API_BASE_URL + "Images/" + data?.wht_RateUs_images
            : companyLogo,
          wht_RateUs_text: data?.wht_RateUs_text,
          wht_Redeem_images: data?.wht_Redeem_images
            ? API_BASE_URL + "Images/" + data?.wht_Redeem_images
            : companyLogo,
          wht_Redeem_text: data?.wht_Redeem_text,
          wht_ReferralThirdParty_images: data?.wht_ReferralThirdParty_images
            ? API_BASE_URL + "Images/" + data?.wht_ReferralThirdParty_images
            : companyLogo,
          wht_ReferralThirdParty_text: data?.wht_ReferralThirdParty_text,
          wht_Referrence_images: data?.wht_Referrence_images
            ? API_BASE_URL + "Images/" + data?.wht_Referrence_images
            : companyLogo,
          wht_Referrence_text: data?.wht_Referrence_text,
          wht_StockAcpA_images: data?.wht_StockAcpA_images
            ? API_BASE_URL + "Images/" + data?.wht_StockAcpA_images
            : companyLogo,
          wht_StockAcpA_text: data?.wht_StockAcpA_text,
          wht_StockAcpB_images: data?.wht_StockAcpB_images
            ? API_BASE_URL + "Images/" + data?.wht_StockAcpB_images
            : companyLogo,
          wht_StockAcpB_text: data?.wht_StockAcpB_text,
          wht_StockTrxA_images: data?.wht_StockTrxA_images
            ? API_BASE_URL + "Images/" + data?.wht_StockTrxA_images
            : companyLogo,
          wht_StockTrxA_text: data?.wht_StockTrxA_text,
          wht_StockTrxB_images: data?.wht_StockTrxB_images
            ? API_BASE_URL + "Images/" + data?.wht_StockTrxB_images
            : companyLogo,
          wht_StockTrxB_text: data?.wht_StockTrxB_text,
          wht_ThankYou_images: data?.wht_ThankYou_images
            ? API_BASE_URL + "Images/" + data?.wht_ThankYou_images
            : companyLogo,
          wht_ThankYou_text: data?.wht_ThankYou_text,
          wht_UploadDesign_images: data?.wht_UploadDesign_images
            ? API_BASE_URL + "Images/" + data?.wht_UploadDesign_images
            : companyLogo,
          wht_UploadDesign_text: data?.wht_UploadDesign_text,
          wht_UploadVoucher_images: data?.wht_UploadVoucher_images
            ? API_BASE_URL + "Images/" + data?.wht_UploadVoucher_images
            : companyLogo,
          wht_UploadVoucher_text: data?.wht_UploadVoucher_text,
          wht_VideoCallBooked_images: data?.wht_VideoCallBooked_images
            ? API_BASE_URL + "Images/" + data?.wht_VideoCallBooked_images
            : companyLogo,
          wht_VideoCallBooked_text: data?.wht_VideoCallBooked_text,
          wht_VideoCallRequest_images: data?.wht_VideoCallRequest_images
            ? API_BASE_URL + "Images/" + data?.wht_VideoCallRequest_images
            : companyLogo,
          wht_VideoCallRequest_text: data?.wht_VideoCallRequest_text,
          wht_VideoCall_images: data?.wht_VideoCall_images
            ? API_BASE_URL + "Images/" + data?.wht_VideoCall_images
            : companyLogo,
          wht_VideoCall_text: data?.wht_VideoCall_text,
        });
      }

      setLoad(false);
    });
  }, []);







  const _Save = () => {
    let temparam = {
      name: param.name,
      otp_temp: param.otp,
      welcome: param.welcome,
      welcome_temp: param.welcome_temp,
      birthday: param.birthday,
      birthday_temp: param.birthday_temp,
      anniversary: param.anniversary,
      anniversary_temp: param.anniversary_temp,
      reference: param.reference,
      reference_temp: param.reference_temp,
      upload_design: param.upload_design,
      upload_design_temp: param.upload_design_temp,
      thank_you: param.thank_you,
      thank_you_temp: param.thank_you_temp,
      rate_us: param.rate_us,
      rate_us_temp:
        "Please rate us at http://j-qt.in/f?t=$$URL$$. Team " +
        companyName.toUpperCase() +
        ". Thank you.",
      common: param.common,
      common_temp: param.common_temp,
      customised_catalogue: param.customised_catalogue,
      customised_catalogue_temp: param.customised_catalogue_temp,
      video_call_request: param.video_call_request,
      video_call_request_temp: param.video_call_request_temp,
      video_call_booked: param.video_call_booked,
      video_call_booked_temp: param.video_call_booked_temp,
      
      sales_message:param.sales_message,
      sales_message_temp:param.sales_message_temp,
      video_call_new:param.video_call_new,
      video_call_new_temp:param.video_call_new_temp,
      missed_call:param.missed_call,
      missed_call_temp:param.missed_call_temp,
      redeem:param.redeem,
      redeem_temp:param.redeem_temp,
      referrel:param.referrel,
      referrel_temp:param.referrel_temp,
      stock_transfer_a:param.stock_transfer_a,
      stock_transfer_a_temp:param.stock_transfer_a_temp,
      stock_transfer_b:param.stock_transfer_b,
      stock_transfer_b_temp:param.stock_transfer_b_temp,
      stock_accept_a:param.stock_accept_a,
      stock_accept_a_temp:param.stock_accept_a_temp,
      stock_accept_b:param.stock_accept_b,
      stock_accept_b_temp:param.stock_accept_b_temp,
      upload_voucher:param.upload_voucher,
      upload_voucher_temp:param.upload_voucher_temp,
      referral_thirt_party:param.referral_thirt_party,
      referral_thirt_party_temp:param.referral_thirt_party_temp,
      wht_welcome_text:WhatsappParam.wht_welcome_text,
      wht_Birthday_text:WhatsappParam.wht_Birthday_text,
      wht_Anniversary_text:WhatsappParam.wht_Anniversary_text,
      wht_Referrence_text:WhatsappParam.wht_Referrence_text,
      wht_ThankYou_text:WhatsappParam.wht_ThankYou_text,
      wht_UploadDesign_text:WhatsappParam.wht_UploadDesign_text,
      wht_RateUs_text:WhatsappParam.wht_RateUs_text,
      wht_General_text:WhatsappParam.wht_General_text,
      wht_AddSales_text:WhatsappParam.wht_AddSales_text,
      wht_ReferralThirdParty_text:WhatsappParam.wht_ReferralThirdParty_text,
      wht_MissedCall_text:WhatsappParam.wht_MissedCall_text,
      wht_VideoCall_text:WhatsappParam.wht_VideoCall_text,
      wht_Redeem_text:WhatsappParam.wht_Redeem_text,
      wht_StockTrxA_text:WhatsappParam.wht_StockTrxA_text,
      wht_StockTrxB_text:WhatsappParam.wht_StockTrxB_text,
      wht_StockAcpA_text:WhatsappParam.wht_StockAcpA_text,
      wht_StockAcpB_text:WhatsappParam.wht_StockAcpB_text,
      wht_UploadVoucher_text:WhatsappParam.wht_UploadVoucher_text,
      wht_CustomizedCatalogue_text:WhatsappParam.wht_CustomizedCatalogue_text,
      wht_VideoCallRequest_text:WhatsappParam.wht_VideoCallRequest_text,
      wht_VideoCallBooked_text:WhatsappParam.wht_VideoCallBooked_text,
      
    };
    setLoadInsert(true);
    postMethod("api/sms/whatsapp/insert", temparam).then((data) => {
      if (data.data[0].valid) {
        setSuccess(true);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setLoadInsert(false);
      }
    });
  };













  //set Welcome template and HTML
  const welcomeFun = () => {
    var template =
      "welcome to " +
      paramSMS.welcome_1 +
      " prestigious " +
      paramSMS.welcome_2 +
      ", " +
      companyName.toUpperCase() +
      "! We honoured and hope you will have a great experience.";
    var templateHTML =
      "welcome to " +
      (paramSMS.welcome_1 !== ""
        ? "<div class='text-red ml-1 mr-1'>" + paramSMS.welcome_1 + "</div>"
        : "<div class='text-red ml-1 mr-1'>Variable Part</div>") +
      " prestigious " +
      (paramSMS.welcome_2 !== ""
        ? "<div class='text-red ml-1 mr-1'>" + paramSMS.welcome_2 + "</div>"
        : "<div class='text-red ml-1 mr-1'>Variable Part</div>") +
      ", " +
      companyName.toUpperCase() +
      "! We honoured and hope you will have a great experience.";
    setParamSMS({ ...paramSMS, welcome_template_HTML: templateHTML });
    setParam({ ...param, welcome_temp: template });
  };

  //set referring template and HTML
  const refFun = () => {
    var template =
      "thanks for referring " +
      paramSMS.ref_1 +
      " to $$MemberName$$. We are grateful for your love and support. Team " +
      companyName.toUpperCase() +
      ".";
    var templateHTML =
      "thanks for referring " +
      (paramSMS.ref_1 !== ""
        ? "<div class='text-red ml-1 mr-1'>" + paramSMS.ref_1 + "</div>"
        : "<div class='text-red ml-1 mr-1'>Variable Part</div>") +
      " to <div class='text-red ml-1 mr-1'>$$MemberName$$</div>. We are grateful for your love and support. Team " +
      companyName.toUpperCase() +
      ".";
    setParamSMS({ ...paramSMS, ref_template_HTML: templateHTML });
    setParam({ ...param, reference_temp: template });
  };

  //set referring template and HTML
  const thankYouFun = () => {
    var template =
      "thank you for visiting " +
      companyName.toUpperCase() +
      ". Hope you had a great experience. Kindly contact us " +
      paramSMS.thank_you +
      " for any assistance.";
    var templateHTML =
      "thank you for visiting " +
      companyName.toUpperCase() +
      ". Hope you had a great experience. Kindly contact us " +
      (paramSMS.thank_you !== ""
        ? "<div class='text-red ml-1 mr-1'>" + paramSMS.thank_you + "</div>"
        : "<div class='text-red ml-1 mr-1'>Variable Part</div>") +
      " for any assistance.";
    setParamSMS({ ...paramSMS, thank_you_template_HTML: templateHTML });
    setParam({ ...param, thank_you_temp: template });
  };

  //set common catalogue template and HTML
  const setMessage = (val) => {
    console.log(val);
    setParam({
      ...param,
      sales_message_temp:
        "Dear $$MemberName$$, thank you for " +
        val +
        ".Hope to serve you again. Thank you. Team $$BrandName$$.",
    });
  };

  const setThirdParty = (val) => {
    setParam({
      ...param,
      referral_thirt_party_temp:
        "Dear $$Name$$, $$MemberName$$ refer you " +
        val +
        ". Provide us a chance to serve you with the best. Thank you. Team $$BrandName$$.",
    });
  };

  const commonFun = () => {
    var template =
      "just click http://j-qt.in/$$URL$$ to check our " +
      paramSMS.try +
      ". Team " +
      companyName.toUpperCase() +
      ". Thank you.";
    var templateHTML =
      "just click http://j-qt.in/<div class='text-red ml-1 mr-1'>$$URL$$</div> to check our " +
      (paramSMS.try !== ""
        ? "<div class='text-red ml-1 mr-1'>" + paramSMS.try + "</div>"
        : "<div class='text-red ml-1 mr-1'>Variable Part</div>") +
      ". Team " +
      companyName.toUpperCase() +
      ". Thank you.";
    setParamSMS({ ...paramSMS, try_template_HTML: templateHTML });
    setParam({ ...param, common_temp: template });
  };

  const [WhatsappParam, setWhatsappParam] = useState({
    textdata: "",
    product_images: [],
    template_name: "",
    wht_welcome_text: "",
    wht_welcome_images: companyLogo,
    wht_Birthday_text: "",
    wht_Birthday_images: companyLogo,
    wht_Anniversary_text: "",
    wht_Anniversary_images: companyLogo,
    wht_Referrence_text: "",
    wht_Referrence_images: companyLogo,
    wht_ThankYou_text: "",
    wht_UploadDesign_text: "",
    wht_UploadDesign_images: companyLogo,
    wht_RateUs_text: "",
    wht_General_text: "",
    wht_AddSales_text: "",
    wht_ReferralThirdParty_text: "",
    wht_MissedCall_text: "",
    wht_VideoCall_text: "",
    wht_Redeem_text: "",
    wht_StockTrxA_text: "",
    wht_StockTrxB_text: "",
    wht_StockAcpA_text: "",
    wht_StockAcpB_text: "",
    wht_UploadVoucher_text: "",
    wht_CustomizedCatalogue_text: "",
    wht_VideoCallRequest_text: "",
    wht_VideoCallBooked_text: "",
    wht_AddSales_images: companyLogo,
    wht_CustomizedCatalogue_images: companyLogo,
    wht_General_images: companyLogo,
    wht_MissedCall_images: companyLogo,
    wht_Otp_images: companyLogo,
    wht_RateUs_images: companyLogo,
    wht_Redeem_images: companyLogo,
    wht_ReferralThirdParty_images: companyLogo,
    wht_StockAcpA_images: companyLogo,
    wht_StockAcpB_images: companyLogo,
    wht_StockTrxA_images: companyLogo,
    wht_StockTrxB_images: companyLogo,
    wht_ThankYou_images: companyLogo,
    wht_UploadVoucher_images: companyLogo,
    wht_VideoCallBooked_images: companyLogo,
    wht_VideoCallRequest_images: companyLogo,
    wht_VideoCall_images: companyLogo,
  });
  function createGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
  const uploadImageFunc = (formData) => {
    const url = API_BASE_URL + `api/masters/product/uploadImage`;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "auth-token": localStorage.getItem("jwl_token"),
      },
    };
    try {
      post(url, formData, config).then(
        (response) => {},
        (error) => {
          setSuccess(true);
        }
      );
    } catch (error) {
      console.log("error".error);
    }
  };
  function handleInsert(file_id, template_textdata) {
    let file = document.getElementById(file_id);
    let files = file?.files;
    let formData = new FormData();
    WhatsappParam.template_name = file_id;
    WhatsappParam.textdata = template_textdata;
    console.log("files", files);
    if (files?.length > 0) {
      for (var j = 0; j < files?.length; j++) {
        let file_name = "image-" + createGuid();
        WhatsappParam?.product_images.push(
          file_name +
            "" +
            files[j]?.name.substring(files[j]?.name.lastIndexOf("."))
        );
        formData.append("files", files[j], file_name);
      }
    }
    uploadImageFunc(formData);
    try {
      postMethod("api/sms/whatsapp/insert_files", WhatsappParam).then(
        (data) => {
          if (data.data) {
            alert("Template Save Succesfully");
            window.location.reload();
          } else {
            alert("Something Went Wrong");
          }
        }
      );
    } catch (error) {
      console.log("error", error);
    }
    alert("Saved");
  }

  function setPreviewImage(e, name) {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setWhatsappParam({ ...WhatsappParam, [name]: reader.result });
    };
    reader.readAsDataURL(file);
  }


  
  return (
    <>
      {load ? <Loader /> : null}
      <Form onSubmit={handleSubmit(_Save)}> 
      
      <input type="hidden" name="branch_id" value={param.branch_id} />
      <div className="card card-custom gutter-b">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">Add WhatsApp Templete</h3>
          </div>
        </div>
        <div className="card-body">
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                Customer Head
              </Typography>
              <Typography variant="h6" className="mt-4">
                <span>Dear Customer Name :</span>{" "}
                <input
                  type="checkbox"
                  checked={param.name === "name" ? true : false}
                  name="customerName"
                  onChange={(e) => setParam({ ...param, name: "name" })}
                />
                <span className="ml-4">|| Dear Customer :</span>{" "}
                <input
                  type="checkbox"
                  checked={param.name === "Customer" ? true : false}
                  name="customer"
                  onChange={(e) => setParam({ ...param, name: "Customer" })}
                />
                <span className="ml-4">|| Dear Member :</span>{" "}
                <input
                  type="checkbox"
                  checked={param.name === "Member" ? true : false}
                  name="member"
                  onChange={(e) => setParam({ ...param, name: "Member" })}
                />
              </Typography>
            </CardContent>
          </Card>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                OTP
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                className="d-flex flex-wrap align-items-center"
              >
                {"Dear Member, XXXX is your one-time password (OTP) for login. Please enter the OTP to proceed." +
                  companyName.toUpperCase() +
                  "."}
              </Typography>
            </CardContent>
          </Card>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                Welcome
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                className="d-flex flex-wrap align-items-center"
              >
                <span>Dear Member, welcome to</span>{" "}
                <input
                  type="text"
                  maxLength="30"
                  value={paramSMS.welcome_1}
                  className="form-control h-auto py-2 px-6 sms-textbox"
                  onChange={(e) =>
                    setParamSMS({ ...paramSMS, welcome_1: e.target.value })
                  }
                  onBlur={(e) => {
                    welcomeFun();
                  }}
                  ref={register}
                />{" "}
                prestigious{" "}
                <input
                  type="text"
                  style={{ width: "24%" }}
                  maxLength="30"
                  value={paramSMS.welcome_2}
                  className="form-control h-auto py-2 px-6 sms-textbox"
                  onChange={(e) =>
                    setParamSMS({ ...paramSMS, welcome_2: e.target.value })
                  }
                  onBlur={(e) => {
                    welcomeFun();
                  }}
                  ref={register}
                />
                , {companyName.toUpperCase()}!
              </Typography>
              <Typography variant="h6" component="h2" className="mb-0">
                We honoured and hope you will have a great experience.
              </Typography>
              <Typography variant="h5" component="h2" className="mt-4">
                Template Preview
              </Typography>
              <Typography variant="h5" component="h2" gutterBottom>
                <h5 className="d-flex" style={{ fontSize: "11px" }}>
                  Dear Member,{" "}
                  <p
                    className="d-flex"
                    dangerouslySetInnerHTML={{
                      __html: paramSMS.welcome_template_HTML,
                    }}
                  />
                </h5>
                {}
              </Typography>
              <Typography variant="h6" className="mt-4">
                <span>Active :</span>{" "}
                <input
                  type="checkbox"
                  checked={param.welcome}
                  name="exhibition"
                  onChange={(e) =>
                    setParam({ ...param, welcome: !param.welcome })
                  }
                />
              </Typography>
              <h6>Whatsapp Message</h6>
              <Input
                type="textarea"
                name="textarea"
                value={WhatsappParam.wht_welcome_text}
                onChange={(e) =>
                  setWhatsappParam({
                    ...WhatsappParam,
                    wht_welcome_text: e.target.value,
                  })
                }
              ></Input>
              <br></br>
              <Label>
                Upload File<br></br>
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  {WhatsappParam?.wht_welcome_images ? (
                    <img
                      style={{ width: "100px", height: "100px" }}
                      src={WhatsappParam?.wht_welcome_images}
                      alt="Image"
                    />
                  ) : null}
                </div>
                <Input
                  className="mt-2"
                  id="wht_welcome_"
                  type="file"
                  name="Choose Image"
                  onChange={(e) => setPreviewImage(e, "wht_welcome_images")}
                  ref={register}
                ></Input>
              </Label>
              <button
                type="button"
                onClick={() =>
                  handleInsert("wht_welcome_", WhatsappParam.wht_welcome_text)
                }
                className="btn btn-primary "
              >
                Save
              </button>
            </CardContent>
          </Card>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                Birthday
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                className="d-flex flex-wrap align-items-center"
              >
                <span>Dear Member, </span> {companyName.toUpperCase()} wish you
                a wonderful BIRTHDAY! May this day be filled with many happy
                hours and your life with many birthdays.
              </Typography>
              <Typography variant="h6" className="mt-4">
                <span>Active :</span>{" "}
                <input
                  type="checkbox"
                  checked={param.birthday}
                  name="birthday"
                  onChange={(e) =>
                    setParam({ ...param, birthday: !param.birthday })
                  }
                />
              </Typography>
              <h6>Whatsapp Message</h6>
              <Input
                type="textarea"
                name="textarea"
                value={WhatsappParam.wht_Birthday_text}
                onChange={(e) =>
                  setWhatsappParam({
                    ...WhatsappParam,
                    wht_Birthday_text: e.target.value,
                  })
                }
              ></Input>
              <br></br>
              <Label>
                Upload File<br></br>
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  {WhatsappParam?.wht_Birthday_images ? (
                    <img
                      style={{ width: "100px", height: "100px" }}
                      src={WhatsappParam?.wht_Birthday_images}
                      alt="Image"
                    />
                  ) : null}
                </div>
                <Input
                  className="mt-2"
                  id="wht_Birthday_"
                  type="file"
                  name="Choose Image"
                  onChange={(e) => setPreviewImage(e, "wht_Birthday_images")}
                  ref={register}
                ></Input>
              </Label>
              <button
                type="button"
                onClick={() =>
                  handleInsert("wht_Birthday_", WhatsappParam.wht_Birthday_text)
                }
                className="btn btn-primary"
              >
                Save
              </button>
            </CardContent>
          </Card>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                Anniversary
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                className="d-flex flex-wrap align-items-center"
              >
                <span>Dear Member, </span> {companyName.toUpperCase()} wishing
                you a HAPPY ANNIVERSARY! Wishing you all the happiness and love
                in the world. Congratulation.
              </Typography>
              <Typography variant="h6" className="mt-4">
                <span>Active :</span>{" "}
                <input
                  type="checkbox"
                  checked={param.anniversary}
                  name="birthday"
                  onChange={(e) =>
                    setParam({ ...param, anniversary: !param.anniversary })
                  }
                />
              </Typography>
              <h6>Whatsapp Message</h6>
              <Input
                type="textarea"
                name="textarea"
                value={WhatsappParam.wht_Anniversary_text}
                onChange={(e) =>
                  setWhatsappParam({
                    ...WhatsappParam,
                    wht_Anniversary_text: e.target.value,
                  })
                }
              ></Input>
              <br></br>
              <Label>
                Upload File<br></br>
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  {WhatsappParam?.wht_Anniversary_images ? (
                    <img
                      style={{ width: "100px", height: "100px" }}
                      src={WhatsappParam?.wht_Anniversary_images}
                      alt="Image"
                    />
                  ) : null}
                </div>
                <Input
                  className="mt-2"
                  id="wht_Anniversary_"
                  type="file"
                  name="Choose Image"
                  onChange={(e) => setPreviewImage(e, "wht_Anniversary_images")}
                  ref={register}
                ></Input>
              </Label>
              <button
                type="button"
                onClick={() =>
                  handleInsert(
                    "wht_Anniversary_",
                    WhatsappParam.wht_Anniversary_text
                  )
                }
                className="btn btn-primary"
              >
                Save
              </button>
            </CardContent>
          </Card>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                REFERRENCE
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                className="d-flex flex-wrap align-items-center"
              >
                <span>Dear Member, thanks for referring </span>
                <input
                  type="text"
                  maxLength="30"
                  value={paramSMS.ref_1}
                  className="form-control h-auto py-2 px-6 sms-textbox"
                  onChange={(e) =>
                    setParamSMS({ ...paramSMS, ref_1: e.target.value })
                  }
                  onBlur={(e) => {
                    refFun();
                  }}
                  ref={register}
                />{" "}
                to <div class="text-red ml-1 mr-1">$$MemberName$$</div>. We are
                grateful for your
              </Typography>
              <Typography variant="h6" component="h2" className="mb-0">
                love and support. Team {companyName.toUpperCase()}.{" "}
              </Typography>
              <Typography variant="h5" component="h2" className="mt-4">
                Template Preview
              </Typography>
              <Typography variant="h5" component="h2" gutterBottom>
                <h5 className="d-flex" style={{ fontSize: "11px" }}>
                  Dear Member,{" "}
                  <p
                    className="d-flex"
                    dangerouslySetInnerHTML={{
                      __html: paramSMS.ref_template_HTML,
                    }}
                  />
                </h5>
                {}
              </Typography>
              <Typography variant="h6" className="mt-4">
                <span>Active :</span>{" "}
                <input
                  type="checkbox"
                  checked={param.reference}
                  name="reference"
                  onChange={(e) =>
                    setParam({ ...param, reference: !param.reference })
                  }
                />
              </Typography>

              <h6>Whatsapp Message</h6>
              <Input
                type="textarea"
                name="textarea"
                value={WhatsappParam.wht_Referrence_text}
                onChange={(e) =>
                  setWhatsappParam({
                    ...WhatsappParam,
                    wht_Referrence_text: e.target.value,
                  })
                }
              ></Input>
              <br></br>

              <Label>
                Upload File<br></br>
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  {WhatsappParam?.wht_Referrence_images ? (
                    <img
                      style={{ width: "100px", height: "100px" }}
                      src={WhatsappParam?.wht_Referrence_images}
                      alt="Image"
                    />
                  ) : null}
                </div>
                <Input
                  className="mt-2"
                  id="wht_Referrence_"
                  type="file"
                  name="Choose Image"
                  onChange={(e) => setPreviewImage(e, "wht_Referrence_images")}
                  ref={register}
                ></Input>
              </Label>
              <button
                type="button"
                onClick={() =>
                  handleInsert(
                    "wht_Referrence_",
                    WhatsappParam.wht_Referrence_text
                  )
                }
                className="btn btn-primary "
              >
                Save
              </button>
            </CardContent>
          </Card>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                UPLOAD DESIGN
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                className="d-flex flex-wrap align-items-center"
              >
                <span>Dear Member, </span> thank you for sharing designs. We
                appreciate and will try to get back to you with the closet we
                have. Team $$BrandName$$.
              </Typography>
              <Typography variant="h6" className="mt-4">
                <span>Active :</span>{" "}
                <input
                  type="checkbox"
                  checked={param.upload_design}
                  name="upload_design"
                  onChange={(e) =>
                    setParam({
                      ...param,
                      upload_design: !param.upload_design,
                    })
                  }
                />
              </Typography>
              <h6>Whatsapp Message</h6>
              <Input
                type="textarea"
                name="textarea"
                value={WhatsappParam.wht_UploadDesign_text}
                onChange={(e) =>
                  setWhatsappParam({
                    ...WhatsappParam,
                    wht_UploadDesign_text: e.target.value,
                  })
                }
              ></Input>
              <br></br>
              <Label>
                Upload File<br></br>
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  {WhatsappParam?.wht_UploadDesign_images ? (
                    <img
                      style={{ width: "100px", height: "100px" }}
                      src={WhatsappParam?.wht_UploadDesign_images}
                      alt="Image"
                    />
                  ) : null}
                </div>
                <Input
                  className="mt-2"
                  id="wht_UploadDesign_"
                  type="file"
                  name="Choose Image"
                  onChange={(e) =>
                    setPreviewImage(e, "wht_UploadDesign_images")
                  }
                  ref={register}
                ></Input>
              </Label>
              <button
                type="button"
                onClick={() =>
                  handleInsert(
                    "wht_UploadDesign_",
                    WhatsappParam.wht_UploadDesign_text
                  )
                }
                className="btn btn-primary "
              >
                Save
              </button>
            </CardContent>
          </Card>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                THNAK YOU
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                className="d-flex flex-wrap align-items-center"
              >
                <span>
                  Dear Member, thank you for visiting{" "}
                  {companyName.toUpperCase()}. Hope you had a great experience.
                  Kindly contact us{" "}
                </span>
                <input
                  type="text"
                  maxLength="30"
                  value={paramSMS.thank_you}
                  className="form-control h-auto py-2 px-6 sms-textbox"
                  onChange={(e) =>
                    setParamSMS({ ...paramSMS, thank_you: e.target.value })
                  }
                  onBlur={(e) => {
                    thankYouFun();
                  }}
                  ref={register}
                />
                . We are grateful for your for any assistance.
              </Typography>
              <Typography variant="h5" component="h2" className="mt-4">
                Template Preview
              </Typography>
              <Typography variant="h5" component="h2" gutterBottom>
                <h5 className="d-flex" style={{ fontSize: "11px" }}>
                  Dear Member,{" "}
                  <p
                    className="d-flex"
                    dangerouslySetInnerHTML={{
                      __html: paramSMS.thank_you_template_HTML,
                    }}
                  />
                </h5>
                {}
              </Typography>
              <Typography variant="h6" className="mt-4">
                <span>Active :</span>{" "}
                <input
                  type="checkbox"
                  checked={param.thank_you}
                  name="thank_you"
                  onChange={(e) =>
                    setParam({ ...param, thank_you: !param.thank_you })
                  }
                />
              </Typography>
              <h6>Whatsapp Message</h6>
              <Input
                type="textarea"
                name="textarea"
                value={WhatsappParam.wht_ThankYou_text}
                onChange={(e) =>
                  setWhatsappParam({
                    ...WhatsappParam,
                    wht_ThankYou_text: e.target.value,
                  })
                }
              ></Input>
              <br></br>
              <Label>
                Upload File<br></br>
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  {WhatsappParam?.wht_ThankYou_images ? (
                    <img
                      style={{ width: "100px", height: "100px" }}
                      src={WhatsappParam?.wht_ThankYou_images}
                      alt="Image"
                    />
                  ) : null}
                </div>
                <Input
                  className="mt-2"
                  id="wht_ThankYou_"
                  type="file"
                  name="Choose Image"
                  onChange={(e) => setPreviewImage(e, "wht_ThankYou_images")}
                  ref={register}
                ></Input>
              </Label>
              <button
                type="button"
                onClick={() =>
                  handleInsert("wht_ThankYou_", WhatsappParam.wht_ThankYou_text)
                }
                className="btn btn-primary "
              >
                Save
              </button>
            </CardContent>
          </Card>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                RATE US
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                className="d-flex flex-wrap align-items-center"
              >
                <span>Dear Member, Please rate us at</span>
                <div class="text-red ml-1 mr-1">http://j-qt.in/$$URL$$</div>.
                Team {companyName.toUpperCase()}. Thank you.
              </Typography>
              <Typography variant="h6" className="mt-4">
                <span>Active :</span>{" "}
                <input
                  type="checkbox"
                  checked={param.rate_us}
                  name="rate_us"
                  onChange={(e) =>
                    setParam({ ...param, rate_us: !param.rate_us })
                  }
                />
              </Typography>
              <h6>Whatsapp Message</h6>
              <Input
                type="textarea"
                name="textarea"
                value={WhatsappParam.wht_RateUs_text}
                onChange={(e) =>
                  setWhatsappParam({
                    ...WhatsappParam,
                    wht_RateUs_text: e.target.value,
                  })
                }
              ></Input>
              <br></br>
              <Label>
                Upload File<br></br>
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  {WhatsappParam?.wht_RateUs_images ? (
                    <img
                      style={{ width: "100px", height: "100px" }}
                      src={WhatsappParam?.wht_RateUs_images}
                      alt="Image"
                    />
                  ) : null}
                </div>
                <Input
                  className="mt-2"
                  id="wht_RateUs_"
                  type="file"
                  name="Choose Image"
                  onChange={(e) => setPreviewImage(e, "wht_RateUs_images")}
                  ref={register}
                ></Input>
              </Label>
              <button
                type="button"
                onClick={() =>
                  handleInsert("wht_RateUs_", WhatsappParam.wht_RateUs_text)
                }
                className="btn btn-primary "
              >
                Save
              </button>
            </CardContent>
          </Card>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                GENERAL, TRY AT HOME, BUSINESS CATALOGUE
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                className="d-flex flex-wrap align-items-center"
              >
                <span>
                  Dear Member, just click http://j-qt.in/$$URL$$ to check our
                </span>{" "}
                <input
                  type="text"
                  maxLength="30"
                  value={paramSMS.try}
                  className="form-control h-auto py-2 px-6 sms-textbox"
                  onChange={(e) =>
                    setParamSMS({ ...paramSMS, try: e.target.value })
                  }
                  onBlur={(e) => {
                    commonFun();
                  }}
                  ref={register}
                />
                . Team {companyName.toUpperCase()}. Thank you.
              </Typography>
              <Typography variant="h5" component="h2" className="mt-4">
                Template Preview
              </Typography>
              <Typography variant="h5" component="h2" gutterBottom>
                <h5 className="d-flex" style={{ fontSize: "11px" }}>
                  Dear Member,{" "}
                  <p
                    className="d-flex"
                    dangerouslySetInnerHTML={{
                      __html: paramSMS.try_template_HTML,
                    }}
                  />
                </h5>
                {}
              </Typography>
              <Typography variant="h6" className="mt-4">
                <span>Active :</span>{" "}
                <input
                  type="checkbox"
                  checked={param.common}
                  name="common"
                  onChange={(e) =>
                    setParam({ ...param, common: !param.common })
                  }
                />
              </Typography>
              <h6>Whatsapp Message</h6>
              <Input
                type="textarea"
                name="textarea"
                value={WhatsappParam.wht_General_text}
                onChange={(e) =>
                  setWhatsappParam({
                    ...WhatsappParam,
                    wht_General_text: e.target.value,
                  })
                }
              ></Input>
              <br></br>
              <Label>
                Upload File<br></br>
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  {WhatsappParam?.wht_General_images ? (
                    <img
                      style={{ width: "100px", height: "100px" }}
                      src={WhatsappParam?.wht_General_images}
                      alt="Image"
                    />
                  ) : null}
                </div>
                <Input
                  className="mt-2"
                  id="wht_General_"
                  type="file"
                  name="Choose Image"
                  onChange={(e) => setPreviewImage(e, "wht_General_images")}
                  ref={register}
                ></Input>
              </Label>
              <button
                type="button"
                onClick={() =>
                  handleInsert("wht_General_", WhatsappParam.wht_General_text)
                }
                className="btn btn-primary "
              >
                Save
              </button>
            </CardContent>
          </Card>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                Add Sales
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                className="d-flex flex-wrap align-items-center"
              >
                <span>Dear Member, thank you for</span>{" "}
                <input
                  type="text"
                  maxLength="30"
                  value={paramSMS.sales_message}
                  name="sales_message"
                  className="form-control h-auto py-2 px-6 sms-textbox"
                  onChange={(e) => {
                    setParamSMS({
                      ...paramSMS,
                      sales_message: e.target.value,
                    });
                    setMessage(e.target.value);
                  }}
                  ref={register}
                />
                . Hope to serve you again. Thank you. Team{" "}
                {companyName.toUpperCase()}.
              </Typography>
              <Typography variant="h5" component="h2" className="mt-4">
                Template Preview
              </Typography>
              <Typography variant="h5" component="h2" gutterBottom>
                <h5 className="d-flex" style={{ fontSize: "11px" }}>
                  <p
                    className="d-flex"
                    dangerouslySetInnerHTML={{
                      __html:
                        paramSMS.sales_message === ""
                          ? param.sales_message_temp
                          : "Dear <div class='text-red ml-1 mr-1'>$$MemberName$$</div>, thank you for <div class='text-red ml-1 mr-1'>" +
                            paramSMS.sales_message +
                            "</div>. Hope to serve you again. Thank you. Team <div class='text-red ml-1 mr-1'>$$BrandName$$</div>.",
                    }}
                  />
                </h5>
              </Typography>
              <Typography variant="h6" className="mt-4">
                <span>Active :</span>{" "}
                <input
                  type="checkbox"
                  checked={param.sales_message}
                  name="sales_message"
                  onChange={(e) =>
                    setParam({
                      ...param,
                      sales_message: !param.sales_message,
                    })
                  }
                />
              </Typography>
              <h6>Whatsapp Message</h6>
              <Input
                type="textarea"
                name="textarea"
                value={WhatsappParam.wht_AddSales_text}
                onChange={(e) =>
                  setWhatsappParam({
                    ...WhatsappParam,
                    wht_AddSales_text: e.target.value,
                  })
                }
              ></Input>
              <br></br>
              <Label>
                Upload File<br></br>
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  {WhatsappParam?.wht_AddSales_images ? (
                    <img
                      style={{ width: "100px", height: "100px" }}
                      src={WhatsappParam?.wht_AddSales_images}
                      alt="Image"
                    />
                  ) : null}
                </div>
                <Input
                  className="mt-2"
                  id="wht_AddSales_"
                  type="file"
                  name="Choose Image"
                  onChange={(e) => setPreviewImage(e, "wht_AddSales_images")}
                  ref={register}
                ></Input>
              </Label>
              <button
                type="button"
                onClick={() =>
                  handleInsert("wht_AddSales_", WhatsappParam.wht_AddSales_text)
                }
                className="btn btn-primary "
              >
                Save
              </button>
            </CardContent>
          </Card>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                REFERRAL THIRD PARTY
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                className="d-flex flex-wrap align-items-center"
              >
                <span>
                  Dear <span class="text-red ml-1 mr-1">$$Name$$</span>,{" "}
                  <span class="text-red ml-1 mr-1">$$MemberName$$</span> refer
                  you
                </span>{" "}
                <input
                  type="text"
                  maxLength="30"
                  value={paramSMS.referral_thirt_party}
                  name="referral_thirt_party"
                  className="form-control h-auto py-2 px-6 sms-textbox"
                  onChange={(e) => {
                    setParamSMS({
                      ...paramSMS,
                      referral_thirt_party: e.target.value,
                    });
                    setThirdParty(e.target.value);
                  }}
                  ref={register}
                />
                . Provide us a chance to serve you with the best. Thank you.
                Team <div class="text-red ml-1 mr-1">$$BrandName$$</div>.
              </Typography>
              <Typography variant="h5" component="h2" className="mt-4">
                Template Preview
              </Typography>
              <Typography variant="h5" component="h2" gutterBottom>
                <h5 className="d-flex" style={{ fontSize: "11px" }}>
                  <p
                    className="d-flex"
                    dangerouslySetInnerHTML={{
                      __html:
                        paramSMS.referral_thirt_party === ""
                          ? param.referral_thirt_party_temp
                          : "Dear <span class='text-red ml-1 mr-1'>$$Name$$</span>, <span class='text-red ml-1 mr-1'>$$MemberName$$</span> refer you <span class='text-red ml-1 mr-1'>" +
                            paramSMS.referral_thirt_party +
                            "</span>. Provide us a chance to serve you with the best. Thank you. Team <div class='text-red ml-1 mr-1'>$$BrandName$$</div>.",
                    }}
                  />
                </h5>
                {}
              </Typography>
              <Typography variant="h6" className="mt-4">
                <span>Active :</span>{" "}
                <input
                  type="checkbox"
                  checked={param.referral_thirt_party}
                  name="referral_thirt_party"
                  onChange={(e) =>
                    setParam({
                      ...param,
                      referral_thirt_party: !param.referral_thirt_party,
                    })
                  }
                />
              </Typography>
              <h6>Whatsapp Message</h6>
              <Input
                type="textarea"
                name="textarea"
                value={WhatsappParam.wht_ReferralThirdParty_text}
                onChange={(e) =>
                  setWhatsappParam({
                    ...WhatsappParam,
                    wht_ReferralThirdParty_text: e.target.value,
                  })
                }
              ></Input>
              <br></br>
              <Label>
                Upload File<br></br>
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  {WhatsappParam?.wht_ReferralThirdParty_images ? (
                    <img
                      style={{ width: "100px", height: "100px" }}
                      src={WhatsappParam?.wht_ReferralThirdParty_images}
                      alt="Image"
                    />
                  ) : null}
                </div>
                <Input
                  className="mt-2"
                  id="wht_ReferralThirdParty_"
                  type="file"
                  name="Choose Image"
                  onChange={(e) =>
                    setPreviewImage(e, "wht_ReferralThirdParty_images")
                  }
                  ref={register}
                ></Input>
              </Label>
              <button
                type="button"
                onClick={() =>
                  handleInsert(
                    "wht_ReferralThirdParty_",
                    WhatsappParam.wht_ReferralThirdParty_text
                  )
                }
                className="btn btn-primary "
              >
                Save
              </button>
            </CardContent>
          </Card>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                MISSED CALL APPOINTMENT BOOKED
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                className="d-flex flex-wrap align-items-center"
              ></Typography>
              <Typography variant="h5" component="h2" className="mt-4">
                Template Preview
              </Typography>
              <Typography variant="h5" component="h2" gutterBottom>
                <h5 className="d-flex" style={{ fontSize: "11px" }}>
                  <p
                    className="d-flex"
                    dangerouslySetInnerHTML={{
                      __html:
                        "Dear <div class='text-red ml-1 mr-1'>$$MemberName$$</div>, your appointment has been booked on <div class='text-red ml-1 mr-1'>$$Date$$</div> at <div class='text-red ml-1 mr-1'>$$Time$$</div>. Please contact if you need any assistance. Team <div class='text-red ml-1 mr-1'>$$BrandName$$.</div>",
                    }}
                  />
                </h5>
                {}
              </Typography>
              <Typography variant="h6" className="mt-4">
                <span>Active :</span>{" "}
                <input
                  type="checkbox"
                  checked={param.missed_call}
                  name="sales_message"
                  onChange={(e) =>
                    setParam({ ...param, missed_call: !param.missed_call })
                  }
                />
              </Typography>
              <h6>Whatsapp Message</h6>
              <Input
                type="textarea"
                name="textarea"
                value={WhatsappParam.wht_MissedCall_text}
                onChange={(e) =>
                  setWhatsappParam({
                    ...WhatsappParam,
                    wht_MissedCall_text: e.target.value,
                  })
                }
              ></Input>
              <br></br>
              <Label>
                Upload File<br></br>
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  {WhatsappParam?.wht_MissedCall_images ? (
                    <img
                      style={{ width: "100px", height: "100px" }}
                      src={WhatsappParam?.wht_MissedCall_images}
                      alt="Image"
                    />
                  ) : null}
                </div>
                <Input
                  className="mt-2"
                  id="wht_MissedCall_"
                  type="file"
                  name="Choose Image"
                  onChange={(e) => setPreviewImage(e, "wht_MissedCall_images")}
                  ref={register}
                ></Input>
              </Label>
              <button
                type="button"
                onClick={() =>
                  handleInsert(
                    "wht_MissedCall_",
                    WhatsappParam.wht_MissedCall_text
                  )
                }
                className="btn btn-primary "
              >
                Save
              </button>
            </CardContent>
          </Card>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                VIDEO CALL BOOKED
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                className="d-flex flex-wrap align-items-center"
              ></Typography>
              <Typography variant="h5" component="h2" className="mt-4">
                Template Preview
              </Typography>
              <Typography variant="h5" component="h2" gutterBottom>
                <h5 className="d-flex" style={{ fontSize: "11px" }}>
                  {/* Dear Member,{" "} */}
                  <p
                    className="d-flex"
                    dangerouslySetInnerHTML={{
                      __html:
                        "Dear <div class='text-red ml-1 mr-1'>$$MemberName$$</div>, your video call appointment has been booked on <div class='text-red ml-1 mr-1'>$$Date$$</div> at <div class='text-red ml-1 mr-1'>$$Time$$</div>. Please contact if you need any assistance. Team <div class='text-red ml-1 mr-1'>$$BrandName$$.</div>",
                    }}
                  />
                </h5>
                {}
              </Typography>
              <Typography variant="h6" className="mt-4">
                <span>Active :</span>{" "}
                <input
                  type="checkbox"
                  checked={param.video_call_new}
                  name="video_call_new"
                  onChange={(e) =>
                    setParam({
                      ...param,
                      video_call_new: !param.video_call_new,
                    })
                  }
                />
              </Typography>
              <h6>Whatsapp Message</h6>
              <Input
                type="textarea"
                name="textarea"
                value={WhatsappParam.wht_VideoCall_text}
                onChange={(e) =>
                  setWhatsappParam({
                    ...WhatsappParam,
                    wht_VideoCall_text: e.target.value,
                  })
                }
              ></Input>
              <br></br>
              <Label>
                Upload File<br></br>
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  {WhatsappParam?.wht_VideoCall_images ? (
                    <img
                      style={{ width: "100px", height: "100px" }}
                      src={WhatsappParam?.wht_VideoCall_images}
                      alt="Image"
                    />
                  ) : null}
                </div>
                <Input
                  className="mt-2"
                  id="wht_VideoCall_"
                  type="file"
                  name="Choose Image"
                  onChange={(e) => setPreviewImage(e, "wht_VideoCall_images")}
                  ref={register}
                ></Input>
              </Label>
              <button
                type="button"
                onClick={() =>
                  handleInsert(
                    "wht_VideoCall_",
                    WhatsappParam.wht_VideoCall_text
                  )
                }
                className="btn btn-primary "
              >
                Save
              </button>
            </CardContent>
          </Card>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                REDEEM
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                className="d-flex flex-wrap align-items-center"
              ></Typography>
              <Typography variant="h5" component="h2" className="mt-4">
                Template Preview
              </Typography>
              <Typography variant="h5" component="h2" gutterBottom>
                <h5 className="d-flex" style={{ fontSize: "11px" }}>
                  <p
                    className="d-flex"
                    dangerouslySetInnerHTML={{
                      __html:
                        "Dear <div class='text-red ml-1 mr-1'>$$MemberName$$</div>, your voucher has been redeemed successfully. Please contact if you need any assistance. Team <div class='text-red ml-1 mr-1'>$$BrandName$$.</div>",
                    }}
                  />
                </h5>
                {}
              </Typography>
              <Typography variant="h6" className="mt-4">
                <span>Active :</span>{" "}
                <input
                  type="checkbox"
                  checked={param.redeem}
                  name="redeem"
                  onChange={(e) =>
                    setParam({ ...param, redeem: !param.redeem })
                  }
                />
              </Typography>
              <h6>Whatsapp Message</h6>
              <Input
                type="textarea"
                name="textarea"
                value={WhatsappParam.wht_Redeem_text}
                onChange={(e) =>
                  setWhatsappParam({
                    ...WhatsappParam,
                    wht_Redeem_text: e.target.value,
                  })
                }
              ></Input>
              <br></br>
              <Label>
                Upload File<br></br>
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  {WhatsappParam?.wht_Redeem_images ? (
                    <img
                      style={{ width: "100px", height: "100px" }}
                      src={WhatsappParam?.wht_Redeem_images}
                      alt="Image"
                    />
                  ) : null}
                </div>
                <Input
                  className="mt-2"
                  id="wht_Redeem_"
                  type="file"
                  name="Choose Image"
                  onChange={(e) => setPreviewImage(e, "wht_Redeem_images")}
                  ref={register}
                ></Input>
              </Label>
              <button
                type="button"
                onClick={() =>
                  handleInsert("wht_Redeem_", WhatsappParam.wht_Redeem_text)
                }
                className="btn btn-primary "
              >
                Save
              </button>
            </CardContent>
          </Card>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                STOCK TRANSFER
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                className="d-flex flex-wrap align-items-center"
              ></Typography>
              <Typography variant="h5" component="h2" className="mt-4">
                Template Preview
              </Typography>
              <Typography variant="h5" component="h2" gutterBottom>
                <h5 className="d-flex" style={{ fontSize: "11px" }}>
                  <p
                    className="d-flex"
                    dangerouslySetInnerHTML={{
                      __html:
                        "A) Dear <div class='text-red ml-1 mr-1'>$$MemberName$$</div>, you transferred $$Quantity$$ products to $$ToBranch$$. Please check. Thank you. Team <div class='text-red ml-1 mr-1'>$$BrandName$$.</div>",
                    }}
                  />
                </h5>
                {}
              </Typography>
              <Typography variant="h6" className="mt-4">
                <span>Active :</span>{" "}
                <input
                  type="checkbox"
                  checked={param.stock_transfer_a}
                  name="stock_transfer_a"
                  onChange={(e) =>
                    setParam({
                      ...param,
                      stock_transfer_a: !param.stock_transfer_a,
                    })
                  }
                />
              </Typography>
              <h6>Whatsapp Message</h6>
              <Input
                type="textarea"
                name="textarea"
                value={WhatsappParam.wht_StockTrxA_text}
                onChange={(e) =>
                  setWhatsappParam({
                    ...WhatsappParam,
                    wht_StockTrxA_text: e.target.value,
                  })
                }
              ></Input>
              <br></br>
              <Label>
                Upload File<br></br>
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  {WhatsappParam?.wht_StockTrxA_images ? (
                    <img
                      style={{ width: "100px", height: "100px" }}
                      src={WhatsappParam?.wht_StockTrxA_images}
                      alt="Image"
                    />
                  ) : null}
                </div>
                <Input
                  className="mt-2"
                  id="wht_StockTrxA_"
                  type="file"
                  name="Choose Image"
                  onChange={(e) => setPreviewImage(e, "wht_StockTrxA_images")}
                  ref={register}
                ></Input>
              </Label>
              <button
                type="button"
                onClick={() =>
                  handleInsert(
                    "wht_StockTrxA_",
                    WhatsappParam.wht_StockTrxA_text
                  )
                }
                className="btn btn-primary "
              >
                Save
              </button>
              <Typography variant="h5" component="h2" className="mt-4">
                Template Preview
              </Typography>
              <Typography variant="h5" component="h2" gutterBottom>
                <h5 className="d-flex" style={{ fontSize: "11px" }}>
                  {/* Dear Member,{" "} */}
                  <p
                    className="d-flex"
                    dangerouslySetInnerHTML={{
                      __html:
                        "B) Dear <div class='text-red ml-1 mr-1'>$$MemberName$$</div>, <div class='text-red ml-1 mr-1'>$$ToBranch$$</div> accepted <div class='text-red ml-1 mr-1'>$$Quantity$$</div> from our store. Please check. Thank you. Team <div class='text-red ml-1 mr-1'>$$BrandName$$.</div>",
                    }}
                  />
                </h5>
                {}
              </Typography>
              <Typography variant="h6" className="mt-4">
                <span>Active :</span>{" "}
                <input
                  type="checkbox"
                  checked={param.stock_transfer_b}
                  name="stock_transfer_b"
                  onChange={(e) =>
                    setParam({
                      ...param,
                      stock_transfer_b: !param.stock_transfer_b,
                    })
                  }
                />
              </Typography>
              <h6>Whatsapp Message</h6>
              <Input
                type="textarea"
                name="textarea"
                value={WhatsappParam.wht_StockTrxB_text}
                onChange={(e) =>
                  setWhatsappParam({
                    ...WhatsappParam,
                    wht_StockTrxB_text: e.target.value,
                  })
                }
              ></Input>
              <br></br>
              <Label>
                Upload File<br></br>
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  {WhatsappParam?.wht_StockTrxB_images ? (
                    <img
                      style={{ width: "100px", height: "100px" }}
                      src={WhatsappParam?.wht_StockTrxB_images}
                      alt="Image"
                    />
                  ) : null}
                </div>
                <Input
                  className="mt-2"
                  id="wht_StockTrxB_"
                  type="file"
                  name="Choose Image"
                  onChange={(e) => setPreviewImage(e, "wht_StockTrxB_images")}
                  ref={register}
                ></Input>
              </Label>
              <button
                type="button"
                onClick={() =>
                  handleInsert(
                    "wht_StockTrxB_",
                    WhatsappParam.wht_StockTrxB_text
                  )
                }
                className="btn btn-primary "
              >
                Save
              </button>
            </CardContent>
          </Card>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                STOCK ACCEPTANCE
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                className="d-flex flex-wrap align-items-center"
              ></Typography>
              <Typography variant="h5" component="h2" className="mt-4">
                Template Preview
              </Typography>
              <Typography variant="h5" component="h2" gutterBottom>
                <h5 className="d-flex" style={{ fontSize: "11px" }}>
                  <p
                    className="d-flex"
                    dangerouslySetInnerHTML={{
                      __html:
                        "A) Dear <div class='text-red ml-1 mr-1'>$$MemberName$$</div>, <div class='text-red ml-1 mr-1'>$$FromBranch$$</div> transfer you <div class='text-red ml-1 mr-1'>$$Quantity$$</div> products. Please check. Thank you. Team <div class='text-red ml-1 mr-1'>$$BrandName$$.</div>",
                    }}
                  />
                </h5>
                {}
              </Typography>
              <Typography variant="h6" className="mt-4">
                <span>Active :</span>{" "}
                <input
                  type="checkbox"
                  checked={param.stock_accept_a}
                  name="stock_accept_a"
                  onChange={(e) =>
                    setParam({
                      ...param,
                      stock_accept_a: !param.stock_accept_a,
                    })
                  }
                />
              </Typography>
              <h6>Whatsapp Message</h6>
              <Input
                type="textarea"
                name="textarea"
                value={WhatsappParam.wht_StockAcpA_text}
                onChange={(e) =>
                  setWhatsappParam({
                    ...WhatsappParam,
                    wht_StockAcpA_text: e.target.value,
                  })
                }
              ></Input>
              <br></br>
              <Label>
                Upload File<br></br>
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  {WhatsappParam?.wht_StockAcpA_images ? (
                    <img
                      style={{ width: "100px", height: "100px" }}
                      src={WhatsappParam?.wht_StockAcpA_images}
                      alt="Image"
                    />
                  ) : null}
                </div>
                <Input
                  className="mt-2"
                  id="wht_StockAcpA_"
                  type="file"
                  name="Choose Image"
                  onChange={(e) => setPreviewImage(e, "wht_StockAcpA_images")}
                  ref={register}
                ></Input>
              </Label>
              <button
                type="button"
                onClick={() =>
                  handleInsert(
                    "wht_StockAcpA_",
                    WhatsappParam.wht_StockAcpA_text
                  )
                }
                className="btn btn-primary "
              >
                Save
              </button>
              <Typography variant="h5" component="h2" className="mt-4">
                Template Preview
              </Typography>
              <Typography variant="h5" component="h2" gutterBottom>
                <h5 className="d-flex" style={{ fontSize: "11px" }}>
                  <p
                    className="d-flex"
                    dangerouslySetInnerHTML={{
                      __html:
                        "B) Dear <div class='text-red ml-1 mr-1'>$$MemberName$$</div>, our store accepted <div class='text-red ml-1 mr-1'>$$Quantity$$</div> products from <div class='text-red ml-1 mr-1'>$$FromBranch$$</div>. Please check. Thank you. Team <div class='text-red ml-1 mr-1'>$$BrandName$$.</div>",
                    }}
                  />
                </h5>
                {}
              </Typography>
              <Typography variant="h6" className="mt-4">
                <span>Active :</span>{" "}
                <input
                  type="checkbox"
                  checked={param.stock_accept_b}
                  name="stock_accept_b"
                  onChange={(e) =>
                    setParam({
                      ...param,
                      stock_accept_b: !param.stock_accept_b,
                    })
                  }
                />
              </Typography>
              <h6>Whatsapp Message</h6>
              <Input
                type="textarea"
                name="textarea"
                value={WhatsappParam.wht_StockAcpB_text}
                onChange={(e) =>
                  setWhatsappParam({
                    ...WhatsappParam,
                    wht_StockAcpB_text: e.target.value,
                  })
                }
              ></Input>
              <br></br>
              <Label>
                Upload File<br></br>
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  {WhatsappParam?.wht_StockAcpB_images ? (
                    <img
                      style={{ width: "100px", height: "100px" }}
                      src={WhatsappParam?.wht_StockAcpB_images}
                      alt="Image"
                    />
                  ) : null}
                </div>
                <Input
                  className="mt-2"
                  id="wht_StockAcpB_"
                  type="file"
                  name="Choose Image"
                  onChange={(e) => setPreviewImage(e, "wht_StockAcpB_images")}
                  ref={register}
                ></Input>
              </Label>
              <button
                type="button"
                onClick={() =>
                  handleInsert(
                    "wht_StockAcpB_",
                    WhatsappParam.wht_StockAcpB_text
                  )
                }
                className="btn btn-primary "
              >
                Save
              </button>
            </CardContent>
          </Card>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                Upload voucher
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                className="d-flex flex-wrap align-items-center"
              ></Typography>
              <Typography variant="h5" component="h2" className="mt-4">
                Template Preview
              </Typography>
              <Typography variant="h5" component="h2" gutterBottom>
                <h5 className="d-flex" style={{ fontSize: "11px" }}>
                  <p
                    className="d-flex"
                    dangerouslySetInnerHTML={{
                      __html:
                        "Dear <div class='text-red ml-1 mr-1'>$$MemberName$$</div>,  thanks for sharing designs. We appreciate and offer <div class='text-red ml-1 mr-1'>$$Var1$$</div> gift <div class='text-red ml-1 mr-1'>$$Var2$$</div>. Validity (<div class='text-red ml-1 mr-1'>$$ValidityDate$$</div>). Team <div class='text-red ml-1 mr-1'>$$BrandName$$.</div>",
                    }}
                  />
                </h5>
                {}
              </Typography>
              <Typography variant="h6" className="mt-4">
                <span>Active :</span>{" "}
                <input
                  type="checkbox"
                  checked={param.upload_voucher}
                  name="upload_voucher"
                  onChange={(e) =>
                    setParam({
                      ...param,
                      upload_voucher: !param.upload_voucher,
                    })
                  }
                />
              </Typography>
              <h6>Whatsapp Message</h6>
              <Input
                type="textarea"
                name="textarea"
                value={WhatsappParam.wht_UploadVoucher_text}
                onChange={(e) =>
                  setWhatsappParam({
                    ...WhatsappParam,
                    wht_UploadVoucher_text: e.target.value,
                  })
                }
              ></Input>
              <br></br>
              <Label>
                Upload File<br></br>
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  {WhatsappParam?.wht_UploadVoucher_images ? (
                    <img
                      style={{ width: "100px", height: "100px" }}
                      src={WhatsappParam?.wht_UploadVoucher_images}
                      alt="Image"
                    />
                  ) : null}
                </div>
                <Input
                  className="mt-2"
                  id="wht_UploadVoucher_"
                  type="file"
                  name="Choose Image"
                  onChange={(e) =>
                    setPreviewImage(e, "wht_UploadVoucher_images")
                  }
                  ref={register}
                ></Input>
              </Label>
              <button
                type="button"
                onClick={() =>
                  handleInsert(
                    "wht_UploadVoucher_",
                    WhatsappParam.wht_UploadVoucher_text
                  )
                }
                className="btn btn-primary "
              >
                Save
              </button>
            </CardContent>
          </Card>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                CUSTOMISED CATALOGUE
              </Typography>
              <Typography variant="h5" component="h2" gutterBottom>
                <h5 className="d-flex" style={{ fontSize: "11px" }}>
                  Dear Member,{" "}
                  <p
                    className="d-flex"
                    dangerouslySetInnerHTML={{
                      __html: paramSMS.customised_catalogue_HTML,
                    }}
                  />
                </h5>
              </Typography>
              <Typography variant="h6" className="mt-4">
                <span>Active :</span>{" "}
                <input
                  type="checkbox"
                  checked={param.customised_catalogue}
                  name="customised_catalogue"
                  onChange={(e) =>
                    setParam({
                      ...param,
                      customised_catalogue: !param.customised_catalogue,
                    })
                  }
                />
              </Typography>
              <h6>Whatsapp Message</h6>
              <Input
                type="textarea"
                name="textarea"
                value={WhatsappParam.wht_CustomizedCatalogue_text}
                onChange={(e) =>
                  setWhatsappParam({
                    ...WhatsappParam,
                    wht_CustomizedCatalogue_text: e.target.value,
                  })
                }
              ></Input>
              <br></br>
              <Label>
                Upload File<br></br>
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  {WhatsappParam?.wht_CustomizedCatalogue_images ? (
                    <img
                      style={{ width: "100px", height: "100px" }}
                      src={WhatsappParam?.wht_CustomizedCatalogue_images}
                      alt="Image"
                    />
                  ) : null}
                </div>
                <Input
                  className="mt-2"
                  id="wht_CustomizedCatalogue_"
                  type="file"
                  name="Choose Image"
                  onChange={(e) =>
                    setPreviewImage(e, "wht_CustomizedCatalogue_images")
                  }
                  ref={register}
                ></Input>
              </Label>
              <button
                type="button"
                onClick={() =>
                  handleInsert(
                    "wht_CustomizedCatalogue_",
                    WhatsappParam.wht_CustomizedCatalogue_text
                  )
                }
                className="btn btn-primary "
              >
                Save
              </button>
            </CardContent>
          </Card>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                VIDEO CALL REQUEST
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                className="d-flex flex-wrap align-items-center"
              >
                <span>Dear Member, </span> welcome to{" "}
                {companyName.toUpperCase()} live support. Our staff executive
                will reach you soon.
              </Typography>
              <Typography variant="h6" className="mt-4">
                <span>Active :</span>{" "}
                <input
                  type="checkbox"
                  checked={param.video_call_request}
                  name="video_call_request"
                  onChange={(e) =>
                    setParam({
                      ...param,
                      video_call_request: !param.video_call_request,
                    })
                  }
                />
              </Typography>
              <h6>Whatsapp Message</h6>
              <Input
                type="textarea"
                name="textarea"
                value={WhatsappParam.wht_VideoCallRequest_text}
                onChange={(e) =>
                  setWhatsappParam({
                    ...WhatsappParam,
                    wht_VideoCallRequest_text: e.target.value,
                  })
                }
              ></Input>
              <br></br>
              <Label>
                Upload File<br></br>
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  {WhatsappParam?.wht_VideoCallRequest_images ? (
                    <img
                      style={{ width: "100px", height: "100px" }}
                      src={WhatsappParam?.wht_VideoCallRequest_images}
                      alt="Image"
                    />
                  ) : null}
                </div>
                <Input
                  className="mt-2"
                  id="wht_VideoCallRequest_"
                  type="file"
                  name="Choose Image"
                  onChange={(e) =>
                    setPreviewImage(e, "wht_VideoCallRequest_images")
                  }
                  ref={register}
                ></Input>
              </Label>
              <button
                type="button"
                onClick={() =>
                  handleInsert(
                    "wht_VideoCallRequest_",
                    WhatsappParam.wht_VideoCallRequest_text
                  )
                }
                className="btn btn-primary "
              >
                Save
              </button>
            </CardContent>
          </Card>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                VIDEO CALL BOOKED
              </Typography>
              <Typography variant="h5" component="h2" gutterBottom>
                <h5 className="d-flex" style={{ fontSize: "11px" }}>
                  Dear Member,{" "}
                  <p
                    className="d-flex"
                    dangerouslySetInnerHTML={{
                      __html: paramSMS.try_template_HTML,
                    }}
                  />
                </h5>
              </Typography>
              <Typography variant="h6" className="mt-4">
                <span>Active :</span>{" "}
                <input
                  type="checkbox"
                  checked={param.video_call_booked}
                  name="video_call_booked"
                  onChange={(e) =>
                    setParam({
                      ...param,
                      video_call_booked: !param.video_call_booked,
                    })
                  }
                />
              </Typography>
              <h6>Whatsapp Message</h6>
              <Input
                type="textarea"
                name="textarea"
                value={WhatsappParam.wht_VideoCallBooked_text}
                onChange={(e) =>
                  setWhatsappParam({
                    ...WhatsappParam,
                    wht_VideoCallBooked_text: e.target.value,
                  })
                }
              ></Input>
              <br></br>
              <Label>
                Upload File<br></br>
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  {WhatsappParam?.wht_VideoCallBooked_images ? (
                    <img
                      style={{ width: "100px", height: "100px" }}
                      src={WhatsappParam?.wht_VideoCallBooked_images}
                      alt="Image"
                    />
                  ) : null}
                </div>
                <Input
                  className="mt-2"
                  id="wht_VideoCallBooked_"
                  type="file"
                  name="Choose Image"
                  onChange={(e) =>
                    setPreviewImage(e, "wht_VideoCallBooked_images")
                  }
                  ref={register}
                ></Input>
              </Label>
              <button
                type="button"
                onClick={() =>
                  handleInsert(
                    "wht_VideoCallBooked_",
                    WhatsappParam.wht_VideoCallBooked_text
                  )
                }
                className="btn btn-primary "
              >
                Save
              </button>
            </CardContent>
          </Card>
          {success ? <Alert color="success">Saved Successfully</Alert> : null}
        </div>
        <div className="card-footer text-right">
            {loadInser ? (
              <button type="button" className="btn btn-primary ">
                Saving..
              </button>
            ) : (
              <button type="submit" className="btn btn-primary ">
                Save
              </button>
            )}
            {/* <br></br>
            <Label className="text-danger mt-2">Please Note By Clicking Save, It Will Remove All Images</Label> */}
          </div>
        </div>
      </Form>
    </>
  );
};

export default WhatsAppTemplate;
