import React, { useEffect, useState } from "react";
import CustomerHeader from "./components/header";
import CustomerFooter from "./components/footer";
import { useParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { postMethod } from "../../../_services/_postMethod";
import { API_BASE_URL } from "../../../_services/constant";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { findAllByDisplayValue } from "@testing-library/react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import NumberFormat from "react-number-format";
import { post } from "axios";
import CustomerVoucherComList from "./components/voucherList";
import { Paper } from "@material-ui/core";
import stepsInfo from "../../../_assets/media/home/4steps.jpg";
import stepsInfoMob from "../../../_assets/media/home/4steps-m.jpg";
import CustomertermsAndCondition from "./components/termsAndCondition";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#000",
    backgroundColor: "#fff",
  },
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Uploaddesignvoucher(props) {
  let queryParams = useQuery();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [checkedTerms, setcheckedTerms] = useState({
    check: false,
    comment: false,
  });
  const [voucherList, setVoucherList] = useState([]);

  const id = queryParams.get("id") == "0" ? null : queryParams.get("id");
  const { register, handleSubmit, errors } = useForm();
  const { buttonLabel, className } = props;

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [modalInfo, setModalInfo] = useState(false);
  const toggleInfo = () => setModalInfo(!modalInfo);

  const [modalUpload, setModalUpload] = useState(false);
  const toggleUpload = () => setModalUpload(!modalUpload);
  const [ImagePath, setImagePath] = useState("");
  const [paramImage, setParamImage] = useState({
    tran_id: "0",
    customer_id: "",
    remarks: "",
    image_path: "",
  });
  const [param, setParam] = useState({
    tran_id: "0",
    voucher_id: id,
  });
  const bull = <span className={classes.bullet}>•</span>;

  const handleToggle = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    setOpen(true);
    postMethod("api/customers/customer/customerVoucherDetails", param).then(
      (data) => {
        setParam({ ...param, ...data });
        setOpen(false);
      }
    );
    postMethod("api/customers/customer/voucherlist", { branch_id: "1" }).then(
      (data) => {
        setVoucherList(data);
      }
    );
  }, []);

  const insert = () => {
    var ParamValue = {
      tran_id: "0",
      voucher_id: id,
    };
    postMethod("api/customers/customer/InsertReferralVoucher", ParamValue).then(
      (data) => {
        toggle();
      }
    );
  };

  const insertData = (values) => {
    if (!checkedTerms.check) {
      setcheckedTerms({ ...checkedTerms, comment: true });
    } else {
      let file = document.getElementById("iduploaddesign");
      let files = file.files;
      let formData = new FormData();

      if (files.length > 0) {
        for (var j = 0; j < files.length; j++) {
          let file_name = "image-" + createGuid();
          // values.image_path = file_name+""+files[j].name.substring(files[j].name.lastIndexOf("."));
          param.image_path =
            file_name +
            "" +
            files[j].name.substring(files[j].name.lastIndexOf("."));
          formData.append("files", files[j], file_name);
        }
      }

      values.branch_id = localStorage.getItem("jwl_branch_id");
      postMethod("api/masters/customer/session/design/insert", param).then(
        (data) => {
          uploadImageFunc(formData);
        }
      );
    }
  };
  const uploadImageFunc = (formData) => {
    const url = API_BASE_URL + `api/masters/customer/session/upload/design`;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "auth-token": localStorage.getItem("jwl_token"),
      },
    };

    post(url, formData, config).then(
      (response) => {
        setImagePath("");
        document.getElementById("designForm").reset();
        insert();
        toggleUpload();
      },
      (error) => {
        // setSuccess(true);
      }
    );
  };
  function createGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  return (
    <>
      <div>
        <Modal
          isOpen={modal}
          backdrop="static"
          centered="true"
          className={className}
        >
          <ModalBody>Thank you for referral .</ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                window.location.pathname = "customers/home";
              }}
            >
              Ok
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={modalUpload}
          backdrop="static"
          centered="true"
          className={className}
        >
          <ModalBody>Thank you for upload design.</ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                window.location.reload();
              }}
            >
              Ok
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={modalInfo}
          backdrop="static"
          centered="true"
          className={className}
        >
          <ModalBody>
            <i
              className="fa fa-close protxt pull-right text-dark"
              toggle={toggle}
              aria-hidden="true"
              onClick={() => {
                toggleInfo();
              }}
            ></i>
            <img
              src={stepsInfo}
              className=" d-none d-lg-block d-md-block imgcurve custom-banner"
            />
            <img
              src={stepsInfoMob}
              className=" d-none d-lg-none d-md-none d-block imgcurve custom-banner"
            />
          </ModalBody>
        </Modal>
      </div>

      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CustomerHeader />
      <div className="clr"></div>
      <div className="container toppadhead">
        <div className="row toppad5 mtoppad2">
          <div className="col-sm-12">
            <div>
              <img
                src={
                  API_BASE_URL + "CustomerVoucherBanner/" + param.banner_image
                }
                className=" d-none d-lg-block d-md-block imgcurve custom-banner"
              />
              <img
                src={API_BASE_URL + "CustomerVoucher/" + param.banner_image}
                className=" d-none d-lg-none d-md-none d-block imgcurve custom-banner"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="clr"></div>
      <div className="container-fluid">
        <div className="container70">
          <div className="row toppad5 mtoppad2">
            <div className="col-12 col-sm-8 col-lg-8 toppad7">
              <div className="row">
                <div className="col-12 col-sm-8 col-lg-8">
                  <div className="voucher-box">
                    <div className="item clearfix">
                      <span className="circle-top"></span>
                      <span className="circle-bottom"></span>

                      <div className="item-left">
                        <div className="price-style">
                          <span className="price"> {param.voucher_value}</span>
                          <span className="price-unit"></span>
                          <span className="price-off"></span>
                        </div>
                      </div>
                      <div className="item-right">
                        <p className="p-one">{param.voucher_heading}</p>
                        <p className="p-two">
                          {" "}
                          <i className="iconfont icontime"></i>
                          {moment(param.start_date).format(
                            "MMM Do YYYY"
                          )} - {moment(param.end_date).format("MMM Do YYYY")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-lg-12 toppad10">
                  <div className="botmargn2">
                    <div className="upldbx toppad10">
                      <h2>
                        Upload your Designs
                        <button
                          type="button"
                          className="suggestbtn ml-3"
                          onClick={() => setModalInfo(true)}
                        >
                          <i
                            style={{ color: "black" }}
                            className="fa fa-info-circle"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </h2>
                    </div>
                    <div className="clr"></div>
                    <form id="designForm" onSubmit={handleSubmit(insertData)}>
                      <div className="row">
                        <div className="col-4 col-sm-4 col-lg-2 toppad5 btnpad">
                          <input
                            type="file"
                            hidden=""
                            onChange={() => {
                              var reader = new FileReader();
                              reader.onload = function () {
                                setImagePath(reader.result);
                              };
                              reader.readAsDataURL(
                                document.getElementById("iduploaddesign")
                                  .files[0]
                              );
                            }}
                            className="hide"
                            id="iduploaddesign"
                          />
                          {ImagePath !== "" ? (
                            <img src={ImagePath} className="browsebtn" />
                          ) : (
                            <label
                              className="browsebtn fileUpload btn-default"
                              onClick={() =>
                                document
                                  .getElementById("iduploaddesign")
                                  .click()
                              }
                            ></label>
                          )}
                        </div>
                        <div className="col-8 col-sm-8 col-lg-8 toppad5 btnpad">
                          <div className="form-group">
                            <label for="exampleFormControlTextarea1">
                              Suggestion
                            </label>
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              onChange={(e) => {
                                setParam({ ...param, remarks: e.target.value });
                              }}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="clr"></div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={checkedTerms.check}
                          checked={checkedTerms.check}
                          id="flexCheckDefault"
                          onClick={(e) => {
                            setcheckedTerms({
                              ...checkedTerms,
                              check: !checkedTerms.check,
                              comment: false,
                            });
                          }}
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckDefault"
                        >
                          Terms &amp; Conditions apply
                        </label>
                      </div>
                      {checkedTerms.comment ? (
                        <label
                          className="form-check-label color-red"
                          for="flexCheckDefault"
                        >
                          Please check Terms &amp; Conditions apply
                        </label>
                      ) : null}
                      <div className="row">
                        <div className="col-6 col-sm-6 col-lg-6 toppad5 mtoppad3">
                          <button type="submit" className="btn browsesubmit">
                            Submit
                          </button>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6 toppad5 mtoppad3">
                          <button
                            type="button"
                            onClick={() => {
                              setImagePath("");
                              document.getElementById("designForm").reset();
                            }}
                            className="btn browsesubmit"
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <CustomertermsAndCondition id={id} />
              </div>
            </div>
            <div className="col-sm-4 secbdr mtoppad3 m15 other-offer-mobile">
              <h1>Other Offers</h1>

              <CustomerVoucherComList />
            </div>
          </div>
        </div>
      </div>
      <div className="clr"></div>
      <div className="container-fluid"></div>
      <div className="clr"></div>
      <CustomerFooter />
    </>
  );
}
