import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "../../../_assets/css/custom.css";
import "react-owl-carousel2/lib/styles.css";
import { postMethod } from "../../../_services/_postMethod";
import "react-input-range/lib/css/index.css";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import CustomerHeader from "./components/header";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#000",
    backgroundColor: "#fff",
  },
}));

const CustomerProfile = (props) => {
  document.body.classList.add("profile_bg_image1");
  const { register, handleSubmit, errors } = useForm();
  const classes = useStyles();
  const [load, setLoad] = useState(false);
  const inputRef = React.createRef(null);

  useEffect(() => {
    setLoad(true);
    postMethod("api/customers/customer/getCustomer", { customer_id: "" }).then(
      (data) => {
        SetCustomerDetails(data);
        setLoad(false);
      }
    );
  }, []);

  const [CustomerDetails, SetCustomerDetails] = useState({
    customer_id: null,
    full_name: "",
    mobile: "",
    nick_name: "",
    profession: "",
    address: "",
  });

  const insertCustomer = () => {
    setLoad(true);
    postMethod("api/exhibition/insertCustomer", CustomerDetails).then(
      (data) => {
        window.location = "/exhibition-view/home";
      }
    );
  };

  return (
    <>
      <CustomerHeader count={0} />
      <Backdrop className={classes.backdrop} open={load}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="container mt-5">
        <div className="profile_bg shadow p-4 rounded">
          <h2>Information</h2>
          <hr />
          <form id="Login">
            <div className="row">
              <div className="form-group col-md-6">
                <label className="font-weight-bold">Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={CustomerDetails.full_name}
                  onChange={(e) =>
                    SetCustomerDetails({
                      ...CustomerDetails,
                      full_name: e.target.value,
                    })
                  }
                />
              </div>

              <div className="form-group col-md-6">
                <label className="font-weight-bold">Mobile</label>
                <input
                  type="text"
                  className="form-control"
                  value={CustomerDetails.mobile}
                  disabled="true"
                  onChange={(e) =>
                    SetCustomerDetails({
                      ...CustomerDetails,
                      mobile: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-group col-md-6">
                <label className="font-weight-bold">Profession</label>
                <input
                  type="text"
                  className="form-control"
                  value={CustomerDetails.profession}
                  onChange={(e) =>
                    SetCustomerDetails({
                      ...CustomerDetails,
                      profession: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-group col-md-6">
                <label className="font-weight-bold">Nick Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={CustomerDetails.nick_name}
                  onChange={(e) =>
                    SetCustomerDetails({
                      ...CustomerDetails,
                      nick_name: e.target.value,
                    })
                  }
                />
              </div>

              <div className="form-group col-md-12">
                <label className="font-weight-bold">Address</label>
                <textarea
                  className="form-control"
                  value={CustomerDetails.address}
                  onChange={(e) =>
                    SetCustomerDetails({
                      ...CustomerDetails,
                      address: e.target.value,
                    })
                  }
                ></textarea>
              </div>
              <div className="col-md-12 text-right">
                <button
                  type="button"
                  className="btn btn-theme px-5"
                  onClick={() => {
                    insertCustomer();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="clr"></div>

      {/* {window.outerWidth > 600 ? <CustomerFooter /> : null} */}
    </>
  );
};

export default CustomerProfile;
