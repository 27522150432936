import CompanyDashboard from "../app/screens/company/dashboard/dashboard";
import Branch from "../app/screens/company/branch/branch";
import AddBranch from "../app/screens/company/branch/addbranch";
import BranchUsers from "../app/screens/company/branchUsers/branchUsers";
import AddBranchUsers from "../app/screens/company/branchUsers/addbranchUsers";
export const CompanyRoutes = [
  {
    path: "/company/dashboard",
    name: "Dashboard",
    icon: "flaticon-web",
    child: false,
    component: CompanyDashboard,
  },
  {
    path: "/company/branch",
    name: "Branches",
    icon: "flaticon-web",
    child: false,
    component: Branch,
  },
  {
    path: "/company/branch/add",
    name: "Branches",
    icon: "flaticon-technology-1",
    child: true,
    component: AddBranch,
  },
  {
    path: "/company/Branch-users",
    name: "Branch Users",
    icon: "flaticon-user",
    child: false,
    component: BranchUsers,
  },
  {
    path: "/company/Branch-users/add",
    name: "Users",
    icon: "flaticon-user",
    child: true,
    component: AddBranchUsers,
  },
];
