import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useLocation } from "react-router-dom";

import { API_BASE_URL } from "../../../../_services/constant";
import { Button, Form, FormGroup, Label, Input, FormText, Row, Col, Alert } from "reactstrap";
import {
  postMethod,
  editUserGrid,
  ProductUploadImage,
} from "../../../../_services/_postMethod";
import Loader from "../../../modules/loader";
import { post } from "axios";
import { TextField } from "@material-ui/core";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AddCustomerReview = (props) => {
  let queryParams = useQuery();
  const id = queryParams.get("id") == "" ? null : queryParams.get("id");
  const { register, handleSubmit, errors } = useForm();
  const [load, setLoad] = useState(false);
  const [loadInser, setLoadInsert] = useState(false);
  const [loadDelete, setLoadDelete] = useState(false);  
  const [loadDeleteMsg, setLoadDeleteMsg] = useState("Deleted");
  const [param, setParam] = useState({
    tran_id: "0",
    review: "",  
    image_path:"",
    customer_name:"",
    branch_id: "" 
  });
  const [paramImageShow, setParamImageShow] = useState("");
   const [paramImageShowRequired, setParamImageShowRequired] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false); 
  function createGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) { 
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  const insertData = (values) => {
    let file = document.getElementById("updateImage");
    let files = file.files;
    let formData = new FormData();

    if (files.length > 0) {
      for (var j = 0; j < files.length; j++) {     

        let file_name = "image-" + createGuid();
        values.image_path = file_name+""+files[j].name.substring(files[j].name.lastIndexOf("."));
        param.image_path = file_name+""+files[j].name.substring(files[j].name.lastIndexOf("."));
        formData.append("files", files[j], file_name);
      }
    }
    if(param.image_path == "" && files.length == 0)
     {
     setParamImageShowRequired(true);
     }
     else
     {
          setLoadInsert(true);
      postMethod("api/masters/customer/customerreview/insert", param).then((data) => {     
      if (data.data[0].valid) {
        if (files.length > 0) {
          uploadImageFunc(formData);
        }      
        else {
          setSuccess(true);
          setTimeout(() => {
            props.history.push("/branch/customer-review");
          }, 2000);
        }

      }
      else{
           setLoadInsert(false);
      }
    });
     }
  };
  

  
  const uploadImageFunc = (formData) => {

    const url = API_BASE_URL + `api/masters/customer/UploadCustomerReviewImage`;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "auth-token": localStorage.getItem("jwl_token"),
      },
    };

    post(url, formData, config).then(
      (response) => {
   
          setSuccess(true);
          setTimeout(() => {
            props.history.push("/branch/customer-review");
          }, 2000);
      
      },
      (error) => {
           setLoadInsert(false);
        setSuccess(false);
      }
    );
  };

  

  const handleDelete = () => {
    var proceed = window.confirm("Do you Really Wish to Delete this Entry..?!");

    if (proceed) {
       setLoadDelete(true);
      postMethod("api/masters/customer/customerreview/delete", {
        tran_id: param.tran_id,
      }).then((data) => {      
        if (data.data[0].valid) {
         setSuccessDelete(true);
          setTimeout(() => {
            props.history.push("/branch/customer-review");
          }, 2000);
        }
        else{
            setSuccessDelete(true);
              setTimeout(() => {
               setSuccessDelete(false);
            }, 4000);
             setLoadDeleteMsg(data.error)
           setLoadDelete(false);
        }
      });
    }
  };

  useEffect(() => {
    if (id !== null) {
      setLoad(true);
      var temp = window.atob(id);
      postMethod("api/masters/customer/customerreview/preview", { tran_id: temp }).then((dataD) => {
      var data= dataD.data;
        setParam({
          ...param,
          tran_id: data.tran_id,
          review: data.review,
          image_path: data.image_path,
          customer_name:data.customer_name
        });
        setParamImageShow(data.image_path);
        setLoad(false);
      });
    }
  }, []);
  return (
    <>
      {load ? <Loader /> : null}
      <Form onSubmit={handleSubmit(insertData)}>
        <input
          type="hidden"
          name="tran_id"
          value={id == null ? "0" : param.tran_id}
          ref={register}
        />
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Add Customer Review</h3>
            </div>
          </div>
          <div className="card-body">
            <Row>
            <Col sm={4}>
                <FormGroup>
                  <Label>Customer Name</Label>
                  <input
                    type="text"
                    required
                    value={param.customer_name}
                    className="form-control h-auto py-2 px-6"
                    name="customer_name"
                    onChange={(e) => setParam({ ...param, customer_name: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>   
               <Col sm={4}>
                <FormGroup>
                  <Label>Review</Label>
                  <input
                    type="text"
                    required
                    value={param.review}
                    className="form-control h-auto py-2 px-6"
                    name="review"
                    onChange={(e) => setParam({ ...param, review: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>               
              <Col sm={4} className="d-flex">
              <FormGroup>
                  <Label>
                  Customer Image {paramImageShowRequired ? <label className="text-danger">*Required</label> : null}<br />
                  { (paramImageShow != "" && paramImageShow !=undefined) ? 
                    <img id="updateImageshow"
                    width="100"
                    src={API_BASE_URL + "customerreviewimage/" + paramImageShow}
                  ></img> : null    }
                    <input
                      id="updateImage"
                      type="file"
                      multiple={false}
                      accept="image/*"
                      name="Choose Image"
                      onChange={(e)=>  {
                          var reader = new FileReader();

                          reader.onload = function (e) {
                            setParamImageShow(e.target.result);
                            setParamImageShowRequired(false);
                            document.getElementById('updateImageshow').src=""+e.target.result+"";                          }
                          reader.readAsDataURL(e.target.files[0]);
                    }}
                    />
                  </Label>
                  </FormGroup>
              </Col>

            </Row>
          
            {success ? <Alert color="success">Saved Successfully</Alert> : null}
             {successDelete ? <Alert color="danger">{loadDeleteMsg}</Alert> : null}
          </div>
          <div className="card-footer text-right">
            <a href="/branch/customer-review" className="btn btn-secondary mr-2">
              Cancel
            </a>
             {id && (
              loadDelete ? <button type="button" className="btn btn-danger mr-2">
                Deleting..
              </button> : 
               <button type="button" onClick={handleDelete} className="btn btn-danger mr-2">
                Delete
              </button>
            )}
          
            {loadInser ? 
             <button type="button" className="btn btn-primary ">
              Saving..
            </button> :  <button type="submit" className="btn btn-primary ">
              Save
            </button>}
          </div>
        </div>
      </Form>
    </>
  );
};

export default AddCustomerReview;
