import React, { useEffect, useState } from "react";

const CustomerFooter = (props) => {
  const [year,setyear]=useState("")
 
  useEffect(() => { 
    setyear( new Date().getFullYear());
  }, []);
  
  return (
   
    <footer className="w-100">
    <div className="container mcntr">
      <div className="row">
      <div className="col-md-10">
            © {year} Quicktagg All Rights Reserved 
          </div>
        <div className="col-md-2">
          <div className="text-center center-block">
            <a href="https://www.facebook.com/bootsnipp">
              <i id="social-fb" className="fa fa-facebook fa-2x social"></i>
            </a>
            <a href="https://plus.google.com/+Bootsnipp-page">
              <i id="social-gp" className="fa fa-instagram fa-2x social"></i>
            </a>
            <a href="mailto:bootsnipp@gmail.com">
              <i id="social-em" className="fa fa-linkedin fa-2x social"></i>
            </a>
            <a href="https://twitter.com/bootsnipp">
              <i id="social-tw" className="fa fa-twitter fa-2x social"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
  );
};

export default CustomerFooter;
