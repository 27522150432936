import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../../../_services/constant";

export default function CustomBanner(props) {
  return (
    <>
      <div className="container toppadhead">
        <div className="row toppad5 mtoppad2">
          <div className="col-sm-12">
            <div>
              <img
                src={`${API_BASE_URL}BranchBanner/${localStorage.getItem(
                  "jwl_banner_path"
                )}`}
                className=" d-none d-lg-block d-md-block imgcurve custom-banner"
              />
              <img
                src={`${API_BASE_URL}BranchBanner/${localStorage.getItem(
                  "jwl_banner_path"
                )}`}
                className=" d-none d-lg-none d-md-none d-block imgcurve custom-banner"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="clr"></div>
    </>
  );
}
