import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "../../../_assets/css/custom.css";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/lib/styles.css";
import { postMethod } from "../../../_services/_postMethod";
import CustomerHeader from "./components/header";
import CustomerFooter from "./components/footer";
import CustomerBanner from "./components/banner";
import { API_BASE_URL } from "../../../_services/constant";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import CustomerVoucherCom from "./components/voucher";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#000",
    backgroundColor: "#fff",
  },
}));

const CustomerHome = (props) => {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const [isvalid, setValid] = useState(false);
  const [load, setLoad] = useState(false);
  const inputRef = React.createRef(null);
  const inputRef2 = React.createRef(null);
  const [productCategory, setProductCategory] = useState([]);
  const [countInnernal, setCountInnernal] = useState(0);
  const [reviewList, setreviewList] = useState([]);

  const options = {
    responsive: {
      0: {
        items: 2,
        margin: 10,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 4,
        margin: 40,
      },
    },
    dots: false,
    autoplay: false,
    // center:true,
    stagePadding: 10,

    loop: true,
    nav: true,
  };

  const optionsReview = {
    items: 1,
    nav: true,
    dots: true,
    autoplay: false,
    stagePadding: 10,
    margin: 10,
    loop: true,
  };

  const redirectToProduct = (prop) => {
    localStorage.setItem("category_id", prop.category_id);
    localStorage.setItem("jwl_category_banner_path", prop.banner_path);
    window.location.pathname = "trial-view/products";
  };
  useEffect(() => {
    setLoad(true);
    postMethod("api/session/category_list_trial", {}).then((data) => {
      setProductCategory(data.data);
      setLoad(false);
    });
    postMethod("api/session/customerReviewlist", {}).then((data) => {
      setreviewList(data.data);
    });
    postMethod("api/session/wishlist", {}).then((data) => {
      setCountInnernal(data.data.length);
    });
  }, []);

  return (
    <>
      <Backdrop className={classes.backdrop} open={load}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <CustomerHeader count={countInnernal} />
      <CustomerBanner />
      <div className="container-fluid">
        <div className="row toppad10 mtoppad3 catsection pt-5">
          <div className="container">
            <div className="catsectionbx">
              <div className="row">
                <div className="col-sm-12 text-center botpad8 uniq">
                  <h2>Shop By Category</h2>
                  
                </div>
              </div>
              <div className="clr"></div>
              <div className="row">
                <div className="col-6 col-sm-4 col-lg-4 botmargn3 d-none d-lg-block d-print-block">
                  <div className="cat1bx toppad5">
                    <h3>
                      Discover Our <br />
                      <span>Popular Designs</span>
                    </h3>
                    <p>
                      Our most loved designs deserve a worthy mention and here
                      they are!
                    </p>
                  </div>
                </div>
                {productCategory &&
                  productCategory.map((prop, i) => {
                    return (
                      <div
                        className="col-6 col-md-6 col-lg-4 botmargn3 probxpad cursor-pointer"
                        onClick={() => redirectToProduct(prop)}
                      >
                        <img
                          src={
                            API_BASE_URL + "BranchCategory/" + prop.image_path
                          }
                          className="img100 cat2bx home-category-box p-0"
                        />
                      </div>
                    );
                  })}
              </div>
              <div className="clr"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="clr"></div>
      <CustomerVoucherCom />

      <div className="clr"></div>

      {reviewList.length > 0 && (
        <section
          className="greybg testimonial-section2 toppad3 homeVoucher topborder"
          style={{ paddingBottom: 30 }}
        >
          <div className="row text-center">
            <div className="col-12 uniq">
              <h2>What Our Customer Says</h2>
            </div>
          </div>
          <div className=" pb-md-0">
            <OwlCarousel ref={inputRef2} options={optionsReview}>
              {reviewList.map((prop, i) => (
                <div className="item mt-4" key={i}>
                  <div className="d-flex justify-content-center mb-3">
                    <i className="fa fa-star text-warning"></i>
                    <i className="fa fa-star text-warning"></i>
                    <i className="fa fa-star text-warning"></i>
                    <i className="fa fa-star text-warning"></i>
                    <i className="fa fa-star text-warning"></i>
                  </div>
                  <div className="customerDetail">
                    <img
                      src={
                        API_BASE_URL + "customerreviewimage/" + prop.image_path
                      }
                      alt=""
                    />
                    <div className="h4">{prop.customer_name}</div>
                    <p>{prop.review}</p>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </section>
      )}

      {window.outerWidth > 600 ? <CustomerFooter /> : null}
    </>
  );
};

export default CustomerHome;
