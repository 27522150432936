import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "../../../_assets/css/custom.css";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/lib/styles.css";
import { postMethod } from "../../../_services/_postMethod";

import "react-input-range/lib/css/index.css";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { TabPanel } from "@material-ui/lab";
import CustomerHeader from "./components/header";
import { API_BASE_URL } from "../../../_services/constant";
import { post } from "axios";
import steps4_m from '../../../_assets/media/home/4steps-m.jpg';
import steps4 from '../../../_assets/media/home/4steps.jpg';
import CustomerBanner from "./components/banner";
import {  Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import CustomerFooter from "./components/footer";

const CustomerWishlist = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const [load, setLoad] = useState(false);
  const inputRef = React.createRef(null);
  const [products, setProducts] = useState([]);
  const [designs, setDesigns] = useState([]);
  const [voucherList, setVoucherList] = useState([]);
  const [ImagePath, setImagePath] = useState("");
  const [productCount, setProductCount] = React.useState(0);

  const [param, setParam] = useState({
    tran_id: "0",
    customer_id: "",
    remarks: "",
    image_path: "",
  });

  const options = {
    items: 5,
    nav: false,
    dots: true,
    autoplay: true,
    stagePadding: 10,
    loop: true,
  };

  const RedirectToProduct = (product_id) => {
    localStorage.setItem("product_id", product_id);
    window.location.pathname = "customers/product-view";
  };

  useEffect(() => {
    postMethod("api/session/wishlist", { }).then((data) => {
      setProducts(data.data);
    });
    postMethod("api/session/designList", {  }).then((data) => {
      setDesigns(data.data);
    });
    // postMethod("api/customers/customer/voucherlist", { branch_id: "1" }).then((data) => {
    //   setVoucherList(data);
    // });
  }, []);

  function createGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  const insertData = (values) => {
    let file = document.getElementById("iduploaddesign");
    let files = file.files;
    let formData = new FormData();

    if (files.length > 0) {
      for (var j = 0; j < files.length; j++) {
        let file_name = "image-" + createGuid();
        // values.image_path = file_name+""+files[j].name.substring(files[j].name.lastIndexOf("."));
        param.image_path = file_name + "" + files[j].name.substring(files[j].name.lastIndexOf("."));
        formData.append("files", files[j], file_name);
      }
    }   
    postMethod("api/session/design/insert", param).then((data) => {
      uploadImageFunc(formData);
    });
  };

  const uploadImageFunc = (formData) => {
    const url = API_BASE_URL + `api/session/upload/design`;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "auth-token": localStorage.getItem("jwl_token"),
      },
    };

    post(url, formData, config).then(
      (response) => {
        setImagePath("");
        document.getElementById("designForm").reset();
        postMethod("api/session/designList", { }).then((data) => {
          setDesigns(data.data);
        });
      },
      (error) => {     
      }
    );
  };

  const getImage = () => {
    if(window.outerWidth > 600){
      return <img src={steps4} className="img-fluid" />
    }
    else{
      return <img src={steps4_m} className="img-fluid" />
    }
  }

  const [valueTab, setValueTab] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const [modalInfo, setModalInfo] = useState(false);

  const togglInfo = () => setModalInfo(!modalInfo);

  const productDelete = (id) =>{
    postMethod("api/session/deleteWishListItem", { tran_id: id }).then((data) => {
      postMethod("api/session/wishlist", { }).then((data) => {
        setProductCount(data.data.length);
        setProducts(data.data);
      });   
    });
  }

  const productDeleteDesgin = (id) =>{
    postMethod("api/session/deleteDesign", { tran_id: id }).then((data) => {
      postMethod("api/session/designList", {  }).then((data) => {
        setDesigns(data.data);
      }); 
    });
  }
  
  return (
    <>
    <CustomerHeader count={productCount} />     
      <CustomerBanner />


      <div className="container-fluid">
        <Paper square></Paper>
        <div className="row mtoppad2">
          <div className="container">
            <div className="catsectionbx">
              <div className="row">
                <div className="col-sm-8 order-2 order-md-1">
                  <Tabs
                    value={valueTab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChangeTab}
                    aria-label="disabled tabs example"
                  >
                    <Tab label="Wishlist" />
                    <Tab label="My Design" />
                  </Tabs>

                  {valueTab === 0 && (
                    <div className="" id="my-design" role="tabpanel" aria-labelledby="my-wishlist">
                      <div className="whlist">
                        <div className="whlisthead">My Wishlist ({products.length} Item)</div>
                        {products &&
                          products.map((prop, i) => {
                            return (
                              <div className="whlistpro">
                                <div className="row">
                                  <div className="col-4 col-sm-4 col-lg-3">
                                    <div className="whlistproimg">
                                      <img
                                        src={API_BASE_URL + "Images/" + prop.image_path}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-8 col-sm-8 col-lg-9 toppad3">
                                    <h2>{prop.product_name}</h2>
                                    <p>{prop.remarks}</p>
                                    {(localStorage.getItem("jwl_hide_price") == 'false' || localStorage.getItem("jwl_hide_price") == false) ?
                                      <div className="price">
                                        <span>₹</span>
                                        {prop.price}
                                        <span className="greytxt lpadd1"><del>{prop.product_price}</del></span>
                                      </div>
                                      :
                                      <div className="price">
                                      </div>
                                    }
 
                                    <span className="closebtn">
                                      <a href="#">
                                        <i className="fa fa-times" aria-hidden="true" onClick={()=>{
                                          if(window.confirm("Are you sure want to remove ?")){
                                            productDelete(prop.tran_id);
                                        }
                                        }}></i>
                                      </a>
                                    </span>
                                  </div>
                                  {prop.type == "trial" ||  prop.type == "exhibition" ? <div className="ribbon-wrapper-green">
                                    <div className="ribbon-green text-capitalise">{prop.type}</div>
                                  </div> : null}

                                </div>
                              </div>
                            );
                          })}
                      </div>
                      <div className="clr"></div>
                      {/* <div className="botmargn2">
                        <div className="upldbx toppad10">
                          <h2>
                            Upload your Designs
                          <button
                              type="button"
                              className="suggestbtn ml-3"
                              onClick={() => setModal(true)}
                            >
                              <i
                                style={{ color: "black" }}
                                className="fa fa-info-circle"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </h2>
                        </div>
                        <div className="clr"></div>
                        <form id="designForm" onSubmit={handleSubmit(insertData)}>
                          <div className="row">
                            <div className="col-4 col-sm-4 col-lg-2 toppad5 btnpad">
                              <input
                                type="file"
                                hidden=""
                                onChange={() => {
                                  var reader = new FileReader();
                                  reader.onload = function () {
                                    setImagePath(reader.result);
                                  };
                                  reader.readAsDataURL(
                                    document.getElementById("iduploaddesign").files[0]
                                  );
                                }}
                                className="hide"
                                id="iduploaddesign"
                              />
                              {ImagePath !== "" ? (
                                <img src={ImagePath} className="browsebtn" />
                              ) : (
                                <label
                                  className="browsebtn fileUpload btn-default"
                                  onClick={() => document.getElementById("iduploaddesign").click()}
                                ></label>
                              )}
                            </div>
                            <div className="col-8 col-sm-8 col-lg-8 toppad5 btnpad">
                              <div className="form-group">
                                <label for="exampleFormControlTextarea1">Suggestion</label>
                                <textarea
                                  className="form-control"
                                  id="exampleFormControlTextarea1"
                                  rows="3"
                                  onChange={(e) => {
                                    setParam({ ...param, remarks: e.target.value });
                                  }}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <div className="clr"></div>
                          <div className="row">
                            <div className="col-6 col-sm-6 col-lg-6 toppad5 mtoppad3">
                              <button type="submit" className="btn browsesubmit">
                                Submit
                            </button>
                            </div>
                            <div className="col-6 col-sm-6 col-lg-6 toppad5 mtoppad3">
                              <button
                                type="button"
                                onClick={() => {
                                  setImagePath("");
                                  document.getElementById("designForm").reset();
                                }}
                                className="btn browsesubmit"
                              >
                                Reset
                            </button>
                            </div>
                          </div>
                        </form>
                      </div> */}
                    </div>
                  )}

                  {valueTab === 1 && (
                    <div className="" id="my-design" role="tabpanel" aria-labelledby="my-wishlist">
                      <div className="whlist">
                        <div className="whlisthead">My Designs ({designs.length} Item)</div>
                        {designs &&
                          designs.map((prop, i) => {
                            return (
                              <div className="whlistpro">
                                <div className="row">
                                  <div className="col-4 col-sm-4 col-lg-3">
                                    <div className="whlistproimg">
                                      <img                                       
                                        src={
                                          API_BASE_URL + "CustomerUploadedDesign/" + prop.image_path
                                        }                                        
                                      />
                                    </div>
                                  </div>
                                  <div className="col-8 col-sm-8 col-lg-9 toppad3">
                                    <p>{prop.remarks}</p>
                                    <span className="closebtn">
                                      <a href="#">
                                        <i className="fa fa-times" aria-hidden="true" onClick={()=>{
                                          if(window.confirm("Are you sure want to remove ?")){
                                            productDeleteDesgin(prop.tran_id);
                                        }
                                        }}></i>
                                      </a>
                                    </span>
                                  </div>
                                  <div className="ribbon-wrapper-green">
                                    {/* <div className="ribbon-green">{prop.remarks}</div> */}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      <div className="clr"></div>
                    </div>
                  )}
                </div>
                <div className={"col-sm-4 order-1 order-md-2 d-print-block " + (window.outerWidth > 600 ? "secbdr" : "")}>
                  <div className="secbdrin">
                    <div className="prcedbx">
                      <p>
                        Please <strong>Submit</strong>, so we can reach you with your selected
                      design..!
                    </p>
                      <a href="/customers/thank-you">
                        <button className="allbtn" type="button">
                          Procced
                      </button>
                      </a>

                    </div>
                    <img
                      src="http://rohininfotech.com/maliramjewellers/html/images/side-adv.jpg"
                      className="img-fluid topmargn3 d-none d-md-none"
                    />
                  </div>
                </div>

                  <div className="col-md-8 order-3">
                  <div className="botmargn2">
                        <div className="upldbx toppad10">
                          <h2>
                            Upload your Designs
                          <button
                              type="button"
                              className="suggestbtn ml-3"
                              onClick={togglInfo}>
                              <i style={{ color: "black" }}
                                className="fa fa-info-circle"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </h2>
                        </div>
                        <div className="clr"></div>
                        <form id="designForm" onSubmit={handleSubmit(insertData)}>
                          <div className="row">
                            <div className="col-4 col-sm-4 col-lg-2 toppad5 btnpad">
                              <input
                                type="file"
                                hidden=""
                                onChange={() => {
                                  var reader = new FileReader();
                                  reader.onload = function () {
                                    setImagePath(reader.result);
                                  };
                                  reader.readAsDataURL(
                                    document.getElementById("iduploaddesign").files[0]
                                  );
                                }}
                                className="hide"
                                id="iduploaddesign"
                              />
                              {ImagePath !== "" ? (
                                <img src={ImagePath} className="browsebtn" />
                              ) : (
                                <label
                                  className="browsebtn fileUpload btn-default"
                                  onClick={() => document.getElementById("iduploaddesign").click()}
                                ></label>
                              )}
                            </div>
                            <div className="col-8 col-sm-8 col-lg-8 toppad5 btnpad">
                              <div className="form-group">
                                <label for="exampleFormControlTextarea1">Suggestion</label>
                                <textarea
                                  className="form-control"
                                  id="exampleFormControlTextarea1"
                                  rows="3"
                                  onChange={(e) => {
                                    setParam({ ...param, remarks: e.target.value });
                                  }}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <div className="clr"></div>
                          <div className="row">
                            <div className="col-6 col-sm-6 col-lg-6 toppad5 mtoppad3">
                              <button type="submit" className="btn browsesubmit">
                                Submit
                            </button>
                            </div>
                            <div className="col-6 col-sm-6 col-lg-6 toppad5 mtoppad3">
                              <button
                                type="button"
                                onClick={() => {
                                  setImagePath("");
                                  document.getElementById("designForm").reset();
                                }}
                                className="btn browsesubmit"
                              >
                                Reset
                            </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    
                </div>
              
              </div>
              <div className="clr"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="clr"></div>
      <Modal isOpen={modalInfo} centered toggle={togglInfo}>
        <ModalBody>
              {getImage()}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={togglInfo}>Close</Button>
        </ModalFooter>
      </Modal>
     \
    </>
  );
};

export default CustomerWishlist;
