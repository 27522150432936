// export const API_BASE_URL = "https://jewellerapi.quickgst.in/";
// export const API_BASE_URL = "http://localhost:9000/";
//  export const API_BASE_URL = "http://192.168.1.7:9000/";

// export const WEB_BASE_URL = "https://jeweller.quickgst.in/";


// ------------------new server
// export const API_BASE_URL = "http://localhost:9019/";
// export const API_BASE_URL = "https://staging-api.quicktagg.com/";
export const API_BASE_URL = "https://api.quicktagg.com/";
export const WEB_BASE_URL = "https://admin.quicktagg.com/";
   
