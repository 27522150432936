import { MenuItem, OutlinedInput } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useLocation } from "react-router-dom";
import Select from "@material-ui/core/Select";
import { Button, Form, FormGroup, Label, Input, FormText, Row, Col, Alert } from "reactstrap";
import {
  postMethod,
  validateMobile,
  singleParam,
  editUserGrid,
} from "../../../../_services/_postMethod";
import Loader from "../../../modules/loader";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AddBranchUsers = (props) => {
  //const { id } = useParams();
  let queryParams = useQuery();
  const id = queryParams.get("id") == "" ? null : queryParams.get("id");
  const { register, handleSubmit, errors } = useForm();
  const [load, setLoad] = useState(false);  
  const [loadInser, setLoadInsert] = useState(false);
  const [loadDelete, setLoadDelete] = useState(false);  
  const [loadDeleteMsg, setLoadDeleteMsg] = useState("Deleted");
  const [branchList, setBranchList] = useState([]);
  const [userData, setUser] = useState({
    muser_id: "",
    full_name: "",
    mobile: "",    
    branch_id: "",
    email: "",
    password: "",
    disable: false,
    branch_list:[]
  });
  const [success, setSuccess] = useState(false);
  const [isvalid, setIsValid] = useState({
    mobile: true,
    email: true,
  });
  const [valid, setValid] = useState({
    mobile: true,
    email: true,
  });

  const addUser = () => {
    postMethod("api/masters/user/insert", userData).then((data) => {
      if (data.valid) {
        setSuccess(true);
        setTimeout(() => {
          props.history.push("/company/Branch-users");
        }, 2000);
      }
    });
  };
  const checkMobile = (val) => {
    setUser({ ...userData, mobile: val });
    if (val.length !== 10) {
      setValid({ ...valid, mobile: false });
    } else {
      setValid({ ...valid, mobile: true });
      validateMobile("api/branchUser/ValidateMobile", val).then((data) => {
        console.log(data);
        setIsValid({ ...isvalid, mobile: data });
      });
    }
  };
  const checkEmail = (val) => {
    setUser({ ...userData, email: val });
    var regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (regex.test(val)) {
      setValid({ ...valid, email: true });
    } else {
      setValid({ ...valid, email: false });
    }
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8; 
  const MenuProps = { 
    PaperProps: {
      style: {
        marginLeft: -10,
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 310,
      },
    },
  };
  useEffect(() => {
    postMethod("api/masters/user/branchList", { tran_id: temp }).then((data) => {
      setBranchList(data);     
  });
    if (id !== null) {
      setLoad(true);
      var temp = window.atob(id);
      postMethod("api/masters/user/preview", { tran_id: temp }).then((data) => {
        var branchs = [];
        data[1].forEach((element) => {
          branchs.push(element.branch_id);
        });
        setUser({
          ...userData,
          muser_id:temp,
          full_name: data[0][0].full_name,
          mobile: data[0][0].mobile,    
          branch_id: data[0][0].branch_id,
          email: data[0][0].email,
          disable: data[0][0].disable,
          password: data[0][0].password,
          branch_list:branchs
        });
        setLoad(false);
      });
    }
  }, []);
  return (
    <>
      {load ? <Loader /> : null}
      <Form onSubmit={handleSubmit(addUser)}>
        <input
          type="hidden"
          name="muser_id"
          value={id == null ? "0" : userData.user_id}
          ref={register}
        />
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Add User</h3>
            </div>
          </div>
          <div className="card-body">
            <Row>
            <Col sm={4}>
                <FormGroup>
                  <Label>Branch</Label>
                  <select
                    className="form-control h-auto py-2 px-6"
                    onChange={(e) => {
                      setUser({ ...userData, branch_id: e.target.value });
                    }}
                    name="branch_id"
                    value={userData.branch_id}
                    ref={register}
                  >
                    <option value="">--Select--</option>
                    {branchList.map((prop, i) => {
                      return (
                        <option key={i} value={prop.branch_id}>
                          {prop.company_name}
                        </option>
                      );
                    })}
                  </select>
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Full Name</Label>
                  <input
                    type="text"
                    required
                    value={userData.full_name}
                    className="form-control h-auto py-2 px-6"
                    name="full_name"
                    onChange={(e) => setUser({ ...userData, full_name: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Mobile</Label>
                  <input
                    type="number"
                    value={userData.mobile}
                    required
                    className="form-control h-auto py-2 px-6"
                    name="mobile"
                    ref={register}
                    onChange={(e) => checkMobile(e.target.value)}
                  />
                  {!valid.mobile && (
                    <span className="form-text text-danger">Please enter valid mobile</span>
                  )}
                  {!isvalid.mobile && (
                    <span className="form-text text-danger">This Mobile number already registered</span>
                  )}
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup> 
                  <Label>Email</Label>
                  <input
                    type="email"
                    value={userData.email}
                    required
                    className="form-control h-auto py-2 px-6"
                    onChange={(e) => checkEmail(e.target.value)}
                    onBlur={(e) => {
                      singleParam("api/branchUser/ValidateEmail", "email", e.target.value).then(
                        (data) => {
                          setIsValid({ ...isvalid, email: data });
                        }
                      );
                    }}
                    name="email"
                    ref={register}
                  />
                  {!valid.email && (
                    <span className="form-text text-danger">Please enter valid email</span>
                  )}
                  {!isvalid.email && (
                    <span className="form-text text-danger">This email already registered</span>
                  )}
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Password</Label>
                  <input
                    type="password"
                     value={userData.password}
                    required
                    className="form-control h-auto py-2 px-6"
                    name="password"
                      onChange={(e) => setUser({ ...userData, password: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>
              <Col sm={4} className="d-flex">
                <FormGroup className="my-auto" check>
                  <Label check>
                    <input
                      name="disable"
                      onChange={(e) => {
                        setUser({ ...userData, disable: e.target.checked });
                      }}
                      checked={userData.disable}
                      ref={register}
                      type="checkbox"
                    />{" "}
                    Disable
                  </Label>
                </FormGroup>
              </Col>

              <Col sm={4}>
                <FormGroup>
                  <Label>Branch Rights</Label>
                  <Select
                    style={{ width: 300, height: 35 }}
                    multiple
                    value={userData.branch_list}
                    //value={[]}
                    name="branch_id"
                    onChange={(e) => {
                      setUser({ ...userData, branch_list: e.target.value });                   
                    }}
                    ref={register}
                    input={<OutlinedInput id="select-multiple" />}
                    MenuProps={MenuProps}

                  >
                    {branchList.map((item) => (
                      <MenuItem key={item.branch_id} value={item.branch_id}>
                        {item.company_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormGroup>
              </Col>
            </Row>
            {success ? <Alert color="success">User Saved Successfully</Alert> : null}
          </div>
          <div className="card-footer text-right">
            <a href="/company/Branch-users" className="btn btn-secondary mr-2">
              Cancel
            </a>
            {loadInser ? 
             <button type="button" className="btn btn-primary ">
              Saving..
            </button> :  <button type="submit" className="btn btn-primary ">
              Save
            </button>}
          </div>
        </div>
      </Form>
    </>
  );
};

export default AddBranchUsers;
