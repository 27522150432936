// import SimpleReactValidator from "simple-react-validator";
// import useForceUpdate from "use-force-update";

import React, { useEffect, useState, Fragment, useRef } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "bootstrap/dist/css/bootstrap.min.css";
import arrowRight from "../../../../_assets/media/next.png";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";

// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";

import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Textarea,
  Divider,
  IconButton,
} from "@material-ui/core";
import {
  Row,
  Col,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  ListGroupItem,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  Form,
  FormGroup,
  FormText,
  FormFeedback,
} from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import useForceUpdate from "use-force-update";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import { postMethod } from "../../../../_services/_postMethod";
import { post } from "axios";
import { API_BASE_URL } from "../../../../_services/constant";
import Moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import telephoneIcon from "../../../../_assets/media/telephone.png";
import Chip from "@material-ui/core/Chip";
import { Alert } from "bootstrap";

import { Carousel, CarouselItem, Label } from "reactstrap";
import moment from "moment";
import axios from "axios";
import Dropzone from "react-dropzone";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RedeemVP(props) {
  //=================================
  const [openVisit, setOpenVisit] = React.useState({
    visit_count: "",
    full_name: "",
    showVisit: false,
  });

  const [registrationForm, setRegistration] = React.useState(true);
  const [areaValues, setAreaValues] = React.useState({
    area_name: "",
    area: false,
  });
  const [isPleaseWait, setIsPleaseWait] = React.useState(false);
  const [openUploadValues, setUploadValues] = React.useState({
    tran_id: 0,
    customer_id: "",
    mobile: "",
    full_name: "",
    branch_id: "",
    category_id: "",
    remarks: "",
    remarks1: "",
    remarks2: "",
    remarks3: "",
    sku: "",
    sku1: "",
    sku2: "",
    sku3: "",
    image_path: "",
    image_path1: "",
    image_path2: "",
    image_path3: "",
    payment1: "",
    payment2: "",
    payment3: "",
    appointment_date: moment().format("YYYY-MM-DD HH:mm:ss"),
    interest: "Yes",
    // category_name: "",
  });
  const [registrationFormIndex, setFormIndex] = useState(0);
  const [bannerList, setBannerList] = useState([]);
  const [formType, setFormType] = React.useState("");
  const [openCheckMobile, setOpenCheckMobile] = React.useState(false);
  const [validator, setValidate] = React.useState(new SimpleReactValidator());
  const [mobileNo, setMobileNo] = React.useState(null);
  const [areaList, setAreaList] = React.useState([]);
  const [staffList, setStaffList] = React.useState([]);
  const [referenceList, setReferenceList] = React.useState([]);
  const [categoryList, setCategoryList] = React.useState([]);
  const [recentCustomerList, setRecentCustomerList] = React.useState([]);
  const [notificationList, setNotificationList] = React.useState([]);
  const [lastCheckinList, setLastCheckinList] = React.useState([]);
  const [openForm, setOpenForm] = React.useState(false);
  const [voucherCount, setVoucherCount] = useState(0);
  const forceUpdate = useForceUpdate();
  const [registerValues, setRegisterValues] = React.useState({
    customer_id: 0,
    full_name: "",
    mobile: "",
    dob: null,
    doa: null,
    gender: "",
    address: "",
    category_id: "",
    reference_mobile: "",
    reference_name: "",
    ref_id: "",
    area_id: "",
    staff_id: "",
    profession: "",
  });

  const [customerHistory, setCustomerHistory] = React.useState({
    showRedeemList: false,
    showRedeemPoint: false,
    showVoucherPoint: false,
  });
  // --------function---------------

  const handleCustomerVoucherList = (customer_id) => {
    let temparam = {
      customer_id: customer_id,
    };
    postMethod("api/customervisit/getCustomerVoucherList", temparam).then(
      (data) => {
        setCustomerVoucherList(data.data);
        setVoucherCount(data.data[0].voucher_count);
      }
    );
  };

  const handleRedeem = (customer_id, voucher_id, voucher_name, details) => {
    var msg = voucher_name + " " + details + " confirm redeem ?";
    var r = window.confirm(msg);
    if (r == true) {
      let temparam = {
        tran_id: 0,
        customer_id: customer_id,

        voucher_id: voucher_id,
      };
      postMethod("api/customervisit/insertVoucherRedeem", temparam).then(
        (data) => {
          if (data.data[0].valid) {
            setCustomerHistory({
              ...customerHistory,
              showRedeemList: false,
            });
            alert("Voucher redeem succesfully");
            handleCustomerVoucherList(customer_id);
          }
        }
      );
    }
  };

  // -=-----------------------------------

  const handleCustomerPointList = (customer_id) => {
    let temparam = {
      customer_id: customer_id,
    };
    postMethod("api/customervisit/getCustomerPointList", temparam).then(
      (data) => {
        setCustomerPointList(data.data);
        setTotalPoint(data.data[0].total_points);
      }
    );
    postMethod("api/customervisit/getCustomerRedeemPointList", temparam).then(
      (data) => {
        SetCustomerRedeemPoint(data.data);
      }
    );
    postMethod("api/customervisit/getCustomerExpirePointList", temparam).then(
      (data) => {
        SetCustomerExpirePoint(data.data);
      }
    );
  };
  const handleRedeemPoint = (
    customer_id,
    voucher_id,
    voucher_name,
    details
  ) => {
    var msg = voucher_name + " " + details + " confirm redeem ?";
    var r = window.confirm(msg);
    if (r == true) {
      let temparam = {
        tran_id: 0,
        customer_id: customer_id,

        voucher_id: voucher_id,
      };
      postMethod("api/customervisit/insertPointRedeem", temparam).then(
        (data) => {
          if (data.data[0].valid) {
            setCustomerHistory({
              ...customerHistory,
              showRedeemPoint: false,
            });
            alert("Point redeem succesfully");
            handleCustomerPointList(customer_id);
          }
        }
      );
    }
  };

  const handleSubmit = () => {
    var btn = document.getElementById("cnbtn");
    btn.disabled = true;
    btn.innerText = "Please Wait...";
    if (validator.fieldValid("Mobile1")) {
      let temparam = {
        mobile: mobileNo,
      };
      postMethod("api/customervisit/getCustomerVisit", temparam).then(
        (dataD) => {
          btn.disabled = false;
          btn.innerText = "Continue";
          var data = dataD.data[0];
          if (data == undefined) {
            setRegisterValues({
              ...registerValues,
              mobile: mobileNo,
            });
            setOpenForm(true);
            setOpenCheckMobile(false);
            setMobileNo(null);
          } else if (formType == "voucher point") {
            setOpenCheckMobile(false);
            setCustomerHistory({
              ...customerHistory,
              showVoucherPoint: true,
            });
            handleCustomerVoucherList(data.customer_id);
            handleCustomerPointList(data.customer_id);
            getCustomerDetails(data.mobile);
          }
        }
      );
    } else {
      validator.showMessages();
      forceUpdate();
      btn.disabled = false;
      btn.innerText = "Continue";
    }
  };

  // --------end function

  const getBanners = () => {
    postMethod("api/masters/customer/tabtoscanBannerBrowse", {
      branch_id: "",
    }).then((data) => {
      setBannerList(data.data);
    });
  };
  const getAreaList = () => {
    postMethod("api/customervisit/AreaList").then((res) => {
      setAreaList(res.data);
    });
  };
  const getReferenceList = () => {
    postMethod("api/customervisit/ReferenceList").then((res) => {
      setReferenceList(res.data);
    });
  };
  const getStaffList = () => {
    postMethod("api/customervisit/StaffList").then((res) => {
      setStaffList(res.data);
      //   alert("staff :: " + JSON.stringify(res.data));
    });
  };
  const getCategoryList = () => {
    postMethod("api/customervisit/CategoryList").then((res) => {
      setCategoryList(res.data);
    });
  };
  const getRecentCustomerList = () => {
    postMethod("api/customervisit/getRecentvisiters").then((res) => {
      setRecentCustomerList(res.data);
    });
  };
  const getNotificationList = () => {
    postMethod("api/customervisit/getNotification").then((res) => {
      setNotificationList(res.data);
    });
  };

  const getLastCheckinCustomerList = () => {
    let temparam = {
      branch_id: localStorage.getItem("jwl_branch_id"),
    };
    postMethod("api/customervisit/LastCheckinCustomerList", temparam).then(
      (res) => {
        if (res.data) setLastCheckinList(res.data);
      }
    );
  };

  useEffect(() => {
    getBanners();
    getAreaList();
    getReferenceList();
    getNotificationList();
    getStaffList();
    getCategoryList();
    getLastCheckinCustomerList();
    getRecentCustomerList();
  }, []);

  const handleDobChange = (date) => {
    setRegisterValues({
      ...registerValues,
      dob: date,
    });
  };

  const handleDoaChange = (date) => {
    setRegisterValues({
      ...registerValues,
      doa: date,
    });
  };

  const CheckVisitFormValidation = () => {
    if (validator.fieldValid("Name")) {
      setRegistration(false);
    } else {
      validator.showMessages();
      forceUpdate();
    }
  };
  const handleRefMobile = (e) => {
    setRegisterValues({
      ...registerValues,
      reference_mobile: e.target.value,
    });
  };

  const handleRefOnBlur = (e) => {
    if (e.target.value.length === 10) {
      let temparam = {
        mobile: e.target.value,
      };
      postMethod("api/customervisit/getCustomerVisit", temparam).then(
        (dataD) => {
          var data = dataD.data[0];
          if (data == undefined) {
            alert("Reference Not Found");
            setRegisterValues({
              ...registerValues,
              reference_name: "",
              ref_id: "",
            });
          } else {
            setRegisterValues({
              ...registerValues,
              reference_name: data.full_name,
              ref_id: data.customer_id,
            });
          }
        }
      );
    }
  };

  const handleSubmitAreaForm = (customer_id) => {
    let temparam = {
      area_id: 0,
      area_name: areaValues.area_name,
    };
    postMethod("api/customervisit/insertArea", temparam).then((data) => {
      if (data.data[0].valid) {
        setAreaValues({
          ...areaList,
          area_name: "",
          area: false,
        });
        getAreaList();
      }
    });
  };

  const handleSubmitVisitForm = () => {
    let temparam = {
      customer_id: registerValues.customer_id,
      mobile: registerValues.mobile,
      full_name: registerValues.full_name,
      dob: registerValues.dob,
      category_id: registerValues.category_id,
      doa: registerValues.doa,
      area_id: registerValues.area_id,
      ref_id: registerValues.ref_id,
      staff_id: registerValues.staff_id,
      address: registerValues.address,
      profession: registerValues.profession,
      gender: registerValues.gender,
    };
    postMethod("api/customervisit/insertNewCustomerVisit", temparam).then(
      (data) => {
        if (data.data[0].valid) {
          setOpenForm(false);
          setOpenVisit({
            ...openVisit,
            full_name: registerValues.full_name,
            visit_count: "1",
            showVisit: true,
          });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
          setRegisterValues({
            ...registerValues,
            customer_id: 0,
            full_name: "",
            mobile: "",
            dob: null,
            doa: null,
            gender: "",
            address: "",
            category_id: "",
            ref_id: "",
            area_id: "",
            staff_id: "",
            profession: "",
          });
        } else {
        }
      }
    );
  };

  //Table Config
  const config = {
    page_size: 5,
    // show_pagination: false,
    show_filter: false,
    //show_info: false,
    show_length_menu: false,
  };

  const config_point = {
    page_size: 10,
    show_filter: false,
    show_length_menu: false,
  };

  //Table Config
  const config_AREPoint = {
    page_size: 10,
    length_menu: [10, 5, 20, 50],
  };

  const handleSubmitCustomerVisit = (customer_id) => {
    let temparam = {
      tran_id: 0,
      customer_id: customer_id,
    };
    postMethod("api/customervisit/insertCustomerVisit", temparam).then(
      (data) => {
        setOpenCheckMobile(false);
        setMobileNo(null);
        setOpenVisit({
          ...openVisit,
          full_name: data.data[0].customer_name,
          visit_count: data.data[0].total_visit,
          showVisit: true,
        });
        setTimeout(() => {
          setOpenVisit({
            ...openVisit,
            showVisit: false,
          });
        }, 4000);
        getLastCheckinCustomerList();
      }
    );
  };
  //   const handleSubmitAreaForm = (customer_id) => {
  //     let temparam = {
  //       area_id: 0,
  //       area_name: areaValues.area_name,
  //     };
  const handleRegisterForm = (e) => {
    setRegisterValues({
      ...registerValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleUploadForm = (e) => {
    setUploadValues({
      ...openUploadValues,
      [e.target.name]: e.target.value,
    });
    // console.log(e.target)
  };
  //   ------------
  const [CustomerVoucherList, setCustomerVoucherList] = React.useState([]);
  const voucher_columns = [
    {
      key: "voucher_name",
      text: "Voucher",
      align: "left",
      sortable: true,
    },
    {
      key: "details",
      text: "Details",
      sortable: true,
      align: "left",
    },
    {
      key: "amount",
      text: "Offer",
      sortable: true,
      align: "left",
    },
    {
      key: "start_date",
      text: "Issue Date",
      align: "left",
      sortable: true,
    },
    {
      key: "redeem_end_date",
      text: "Expiry Date",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <span>
              {/* {record.IsvoucherExpire === "true" ? (
                  <label className="text-success">{record.end_date}</label>
                ) : (
                  <label className="text-danger">{record.end_date}</label>
                )} */}

              {record.IsvoucherExpire === "true" ? (
                <label className="text-success">
                  {moment(record.redeem_end_date).format("DD/MM/YYYY")}
                </label>
              ) : (
                <label className="text-danger">
                  {moment(record.redeem_end_date).format("DD/MM/YYYY")}
                </label>
              )}
            </span>
          </Fragment>
        );
      },
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            {record.IsvoucherExpire === "true" ? (
              <label className="text-success">
                <button
                  className="btn btn-success"
                  color="primary"
                  onClick={() =>
                    handleRedeem(
                      record.customer_id,
                      record.voucher_id,
                      record.voucher_name,
                      record.details
                    )
                  }
                  style={{ marginRight: "5px" }}
                >
                  Redeem
                </button>
              </label>
            ) : null}
          </Fragment>
        );
      },
    },
  ];
  // -------------Active Point--------------------------------------
  const [pointActiveModal, SetPointActiveModal] = useState(false);

  const toggleActivePoint = () => {
    SetPointActiveModal(!pointActiveModal);
  };

  const [CustomerPointList, setCustomerPointList] = React.useState([]);
  const ActivePoint_Columns = [
    {
      key: "mobile",
      text: "Mobile",
      align: "left",
      sortable: true,
    },
    {
      key: "type",
      text: "Type",
      align: "left",
      sortable: true,
    },
    {
      key: "remark",
      text: "Remark",
      align: "left",
      sortable: true,
    },
    {
      key: "points",
      text: "Points",
      sortable: true,
      align: "left",
    },
    {
      key: "date_time",
      text: "Date",
      sortable: true,
      align: "left",
      cell: (record) => {
        return (
          <Fragment>
            {record.date_time != "" && record.date_time != null ? (
              <>
                <p style={{ padding: 0, margin: 0, color: "green" }}>
                  {
                    moment(
                      record.date_time.split("T")[0] +
                        "T" +
                        record.date_time.split("T")[1].split(".")[0]
                    )
                      .format("DD/MM/yyyy HH:mm")
                      .toString()
                      .split(" ")[0]
                  }
                </p>
              </>
            ) : null}{" "}
          </Fragment>
        );
      },
    },
    {
      key: "expire_date",
      text: "Expired Date",
      sortable: true,
      align: "left",
      cell: (record) => {
        return (
          <Fragment>
            {record.expire_date != "" && record.expire_date != null ? (
              <>
                <p style={{ padding: 0, margin: 0, color: "red" }}>
                  {
                    moment(
                      record.expire_date.split("T")[0] +
                        "T" +
                        record.expire_date.split("T")[1].split(".")[0]
                    )
                      .format("DD/MM/yyyy HH:mm")
                      .toString()
                      .split(" ")[0]
                  }
                </p>
              </>
            ) : null}{" "}
          </Fragment>
        );
      },
    },
  ];
  // ---------------------------

  // -------------Redeem Point--------------------------------------

  const [pointRedeemModal, SetPointRedeemModal] = useState(false);

  const toggleRedeemPoint = () => {
    SetPointRedeemModal(!pointRedeemModal);
  };

  const [CustomerRedeemPoint, SetCustomerRedeemPoint] = React.useState([]);
  const RedeemPoint_Columns = [
    {
      key: "mobile",
      text: "Mobile",
      align: "left",
      sortable: true,
    },
    {
      key: "type",
      text: "Type",
      align: "left",
      sortable: true,
    },
    {
      key: "remark",
      text: "Remark",
      align: "left",
      sortable: true,
    },
    {
      key: "staff_name",
      text: "Staff Name",
      align: "left",
      sortable: true,
    },
    {
      key: "points",
      text: "Points",
      sortable: true,
      align: "left",
    },
    {
      key: "date_time",
      text: "Date",
      sortable: true,
      align: "left",
      cell: (record) => {
        return (
          <Fragment>
            {record.date_time != "" && record.date_time != null ? (
              <>
                <p style={{ padding: 0, margin: 0, color: "green" }}>
                  {
                    moment(
                      record.date_time.split("T")[0] +
                        "T" +
                        record.date_time.split("T")[1].split(".")[0]
                    )
                      .format("DD/MM/yyyy HH:mm")
                      .toString()
                      .split(" ")[0]
                  }
                </p>

                {/* moment(record.changedatetime.split("T")[0]+'T'+(record.changedatetime.split('T')[1]).split('.')[0]).format("DD/MM/yyyy HH:mm")  */}
              </>
            ) : null}{" "}
          </Fragment>
        );
      },
    },
  ];
  // ---------------------------

  // -------------Expire Point--------------------------------------
  const [pointExpireModal, SetPointExpireModal] = useState(false);

  const toggleExpirePoint = () => {
    SetPointExpireModal(!pointExpireModal);
  };

  const [CustomerExpirePoint, SetCustomerExpirePoint] = React.useState([]);
  const ExpirePoint_Columns = [
    {
      key: "mobile",
      text: "Mobile",
      align: "left",
      sortable: true,
    },
    {
      key: "type",
      text: "Type",
      align: "left",
      sortable: true,
    },
    {
      key: "remark",
      text: "Remark",
      align: "left",
      sortable: true,
    },
    {
      key: "staff_name",
      text: "Staff Name",
      align: "left",
      sortable: true,
    },
    {
      key: "points",
      text: "Points",
      sortable: true,
      align: "left",
    },
    {
      key: "date_time",
      text: "Date",
      sortable: true,
      align: "left",
      cell: (record) => {
        return (
          <Fragment>
            {record.date_time != "" && record.date_time != null ? (
              <>
                <p style={{ padding: 0, margin: 0, color: "green" }}>
                  {
                    moment(
                      record.date_time.split("T")[0] +
                        "T" +
                        record.date_time.split("T")[1].split(".")[0]
                    )
                      .format("DD/MM/yyyy HH:mm")
                      .toString()
                      .split(" ")[0]
                  }
                </p>
              </>
            ) : null}{" "}
          </Fragment>
        );
      },
    },
    {
      key: "expire_date",
      text: "Expired Date",
      sortable: true,
      align: "left",
      cell: (record) => {
        return (
          <Fragment>
            {record.expire_date != "" && record.expire_date != null ? (
              <>
                <p style={{ padding: 0, margin: 0, color: "red" }}>
                  {
                    moment(
                      record.expire_date.split("T")[0] +
                        "T" +
                        record.expire_date.split("T")[1].split(".")[0]
                    )
                      .format("DD/MM/yyyy")
                      .toString()
                      .split(" ")[0]
                  }
                </p>
              </>
            ) : null}{" "}
          </Fragment>
        );
      },
    },
  ];
  // ---------------------------

  const [customerDetails, setCustomerDetails] = React.useState({
    customer_id: "",
    full_name: "",
    mobile: "",
    type: "",
    gender: "",
    dob: "",
    doa: "",
    category_name: "",
    staff_id: "",
    profession: "",
    address: "",
    email: "",
    total_visit: "",
    date: "",
  });

  // -----------------------------------------------point
  const [redeemPointData, setRedeemPointData] = useState({
    customer_id: "",
    mobile: "",
    full_name: "",
    redeemPoint: "",
    remark: "",
    staff_name: "",
  });

  const [extraPointData, setExtraPointData] = useState({
    customer_id: "",
    mobile: "",
    full_name: "",
    extra_point: 0,
    remark: "",
    staff_name: "",
  });

  // ------------------------------------------------------point
  const getCustomerDetails = (mobileNo) => {
    let temparam = {
      mobile: mobileNo,
    };
    postMethod("api/customervisit/getCustomerDetails", temparam).then(
      (dataD) => {
        var data = dataD.data;
        // alert(JSON.stringify(data));
        setCustomerDetails({
          customer_id: data.customer_id,
          full_name: data.full_name,
          mobile: data.mobile,
          total_visit: data.visit_count,
          last_visit: data.last_visit,
          gender: data.gender,
          dob: data.dob == null ? "N/A" : data.dob,
          doa: data.doa == null ? "N/A" : data.doa,
          ref_name: data.ref_name,
          ref_mobile: data.ref_mobile,
          category_name: data.category_name,
          area_name: data.area_name,
          staff_name: data.staff_name,
          profession: data.profession,
          address: data.address,
          branch_name: data.branch_name,
        });

        setRedeemPointData({
          ...redeemPointData,
          customer_id: data.customer_id,
          full_name: data.full_name,
          mobile: data.mobile,
        });
        setExtraPointData({
          ...extraPointData,
          customer_id: data.customer_id,
          full_name: data.full_name,
          mobile: data.mobile,
        });
      }
    );
  };

  //   -----------------

  const [totalPoint, setTotalPoint] = useState(0);
  const [text, setText] = useState("");
  const [maxLength, setMaxLength] = useState(200);
  const handleChange = (event) => {
    const newText = event.target.value;

    if (newText.length <= maxLength) {
      setText(newText);
      setRedeemPointData((prevState) => ({
        ...prevState,
        remark: newText,
      }));
    }
  };

  const [redeemPoint, setRedeemPoint] = useState("");

  function handlerPoint(e) {
    const redtemp = e.target.value;
    if (Number(redtemp) <= Number(totalPoint)) {
      setRedeemPoint(redtemp);
      setRedeemPointData((prevState) => ({
        ...prevState,
        redeemPoint: redtemp,
      }));
    }
  }

  const [redeemStaff, setRedeemStaff] = useState("");
  function handlerStaff(e) {
    const sttemp = e.target.value;
    setRedeemStaff(sttemp);
    setRedeemPointData((prevState) => ({
      ...prevState,
      staff_name: sttemp,
    }));
  }

  //   ---------------Redeem insert Api---------------------

  // ------------------------------------------------------

  function handlerRedeem() {
    // alert(JSON.stringify(redeemPointData));
    if (redeemPoint > totalPoint || redeemPoint == null) {
      alert("Invalid Points");
      setRedeemPoint();
      setCustomerHistory({
        ...customerHistory,
        showRedeemPoint: false,
        showRedeemList: false,
        showVoucherPoint: false,
      });
    } else {
      postMethod("api/customervisit/insertPointRedeem02", redeemPointData).then(
        (data) => {
          if (data.data[0].valid) {
            setCustomerHistory({
              ...customerHistory,
              showRedeemPoint: false,
              showRedeemList: false,
              showVoucherPoint: false,
            });
            setText("");
            setRedeemPoint("");
            setRedeemStaff("");
            alert("Points redeem succesfully");
          }
        }
      );
    }
  }
  //   ============EXTRA POINT=======================
  const [extraPointeModal, SetExtraPointeModal] = useState(false);
  const [extraPoint, setExtraPoint] = useState();
  const toggleExtraPoint = () => {
    SetExtraPointeModal(!extraPointeModal);
  };
  function handlerExtraPoint(e) {
    const redtemp = e.target.value;

    setExtraPoint(redtemp);
    setExtraPointData((prevState) => ({
      ...prevState,
      extra_point: redtemp,
    }));
  }
  const [extraPointStaff, setExtraPointStaff] = useState("");
  function handlerExtraPointStaff(e) {
    const sttemp = e.target.value;
    setExtraPointStaff(sttemp);
    setExtraPointData((prevState) => ({
      ...prevState,
      staff_name: sttemp,
    }));
  }

  const handleExtraChange = (event) => {
    const newText = event.target.value;

    if (newText.length <= maxLength) {
      setText(newText);
      setExtraPointData((prevState) => ({
        ...prevState,
        remark: newText,
      }));
    }
  };

  function addExtraPointHandler() {
    postMethod("api/customervisit/insert/extraPoint", extraPointData).then(
      (data) => {
        if (data.data[0].valid) {
          setCustomerHistory({
            ...customerHistory,
            showRedeemPoint: false,
            showRedeemList: false,
            showVoucherPoint: false,
          });
          setExtraPointStaff("");
          setExtraPoint();
          setText("");

          alert("Add Extra Points succesfully");
          toggleExtraPoint();
        }
      }
    );
  }

  //   ==============================================

  return (
    <>
      <div
        className="redeemOptions pointerShow shadow"
        style={{ borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }}
        onClick={() => {
          setOpenCheckMobile(true);
          setFormType("voucher point");
          setMobileNo("");
        }}
      >
        <h3>Redeem</h3>
      </div>

      {/* ------------------------- */}
      {/* Check Mobile Number Dialog and User Registration Form */}

      <Dialog
        open={openCheckMobile}
        className="custom_modal"
        disableBackdropClick
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="md"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="dialog-border">
          <DialogContent>
            <div className="checkMobileField">
              <h3 className="text-dark mb-3">{"Enter Mobile No."}</h3>
              <div className="d-flex align-items-center">
                <img src={telephoneIcon} />
                <TextField
                  autoComplete="new-off"
                  type="tel"
                  fullWidth
                  className="theme-input"
                  value={mobileNo}
                  placeholder="eg:1234567890"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+91 - </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setMobileNo(
                      (e.target.value.length <= 10 &&
                        !isNaN(Number(e.target.value)) &&
                        e.target.value) ||
                        (e.target.value == "" ? e.target.value : mobileNo)
                    );
                  }}
                  autoFocus
                  name="Mobile1"
                  error={validator.message(
                    "Mobile1",
                    mobileNo,
                    "required|min:10|max:10"
                  )}
                  helperText={validator.message(
                    "Mobile1",
                    mobileNo,
                    "required|min:10|max:10"
                  )}
                  variant="standard"
                />
              </div>
              <div className="mt-3 ml-5">
                {formType != "checkin" && lastCheckinList.length > 0
                  ? lastCheckinList.map((prop, i) => {
                      return (
                        <Chip
                          key={i}
                          label={prop.mobile}
                          className="mr-2 mobileChip"
                          onClick={(e) => {
                            setMobileNo(e.target.innerText);
                          }}
                        />
                      );
                    })
                  : null}
              </div>
            </div>
            {formType != "checkin" && recentCustomerList.length > 0 ? (
              <div className="row pl-5">
                {recentCustomerList.map((obj, i) => {
                  return (
                    <div
                      className="col-md-3 pointerShow"
                      onClick={() => {
                        setMobileNo(obj.mobile);
                      }}
                    >
                      {obj.mobile}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button
              className="btn btn-danger"
              onClick={() => {
                setOpenCheckMobile(false);
                setMobileNo("");
              }}
              color="secondary"
            >
              Close
            </Button>
            <Button
              id="cnbtn"
              className="btn btn-success"
              onClick={() => {
                handleSubmit();
                setIsPleaseWait(false);
              }}
              color="primary"
            >
              Continue
            </Button>
          </DialogActions>
        </div>
      </Dialog>

      {/* --------------------------- */}

      {/* Registration Form */}
      <Dialog
        open={openForm}
        className="custom_modal"
        disableBackdropClick
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="md"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="dialog-border">
          <DialogContent>
            {registrationForm && registrationFormIndex === 0 ? (
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-5">
                    <TextField
                      autoComplete="new-off"
                      fullWidth
                      className="theme-input mb-3"
                      value={registerValues.full_name || ""}
                      onChange={handleRegisterForm}
                      autoFocus
                      name="full_name"
                      id="filled-basic"
                      label="Name"
                      error={validator.message(
                        "Name",
                        registerValues.full_name,
                        "required"
                      )}
                      helperText={validator.message(
                        "Name",
                        registerValues.full_name,
                        "required"
                      )}
                      variant="filled"
                    />
                  </div>
                  <div className="col-md-5">
                    <TextField
                      autoComplete="new-off"
                      fullWidth
                      className="theme-input mb-3"
                      value={registerValues.mobile || ""}
                      onChange={handleRegisterForm}
                      autoFocus
                      id="filled-basic"
                      label="Mobile No."
                      variant="filled"
                      disabled
                    />
                  </div>
                  <div className="col-md-2">
                    <FormControl
                      fullWidth
                      variant="filled"
                      className="theme-input mb-3"
                    >
                      <InputLabel id="demo-simple-select-filled-label">
                        Gender
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={registerValues.gender || ""}
                        onChange={handleRegisterForm}
                        name="gender"
                      >
                        <MenuItem value={"Male"}>Male</MenuItem>
                        <MenuItem value={"Female"}>Female</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <KeyboardDatePicker
                      variant="inline"
                      inputVariant="filled"
                      fullWidth
                      label="Date of Birth"
                      className="mb-3 theme-input"
                      value={registerValues.dob}
                      autoOk
                      format="DD-MM-YYYY"
                      onChange={handleDobChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <KeyboardDatePicker
                      variant="inline"
                      inputVariant="filled"
                      fullWidth
                      className="mb-3  theme-input"
                      label="Date of Anniversary"
                      value={registerValues.doa}
                      autoOk
                      format="DD-MM-YYYY"
                      onChange={handleDoaChange}
                    />
                  </div>

                  <div className="col-md-12">
                    <TextField
                      autoComplete="new-off"
                      fullWidth
                      className="theme-input mb-3"
                      multiline
                      value={registerValues.address || ""}
                      onChange={handleRegisterForm}
                      autoFocus
                      id="filled-basic"
                      label="Address"
                      name="address"
                      variant="filled"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="row">
                  <div className="col-md-6">
                    <TextField
                      autoComplete="new-off"
                      fullWidth
                      className="theme-input mb-3"
                      value={registerValues.full_name || ""}
                      name="full_name"
                      id="filled-basic"
                      label="Name"
                      variant="filled"
                      disabled
                    />
                  </div>
                  <div className="col-md-6">
                    <TextField
                      autoComplete="new-off"
                      fullWidth
                      className="theme-input mb-3"
                      value={registerValues.mobile || ""}
                      onChange={handleRegisterForm}
                      autoFocus
                      id="filled-basic"
                      label="Mobile No."
                      variant="filled"
                      disabled
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <TextField
                      autoComplete="new-off"
                      fullWidth
                      type="number"
                      className="theme-input mb-3"
                      value={registerValues.reference_mobile || ""}
                      onChange={handleRefMobile}
                      onBlur={handleRefOnBlur}
                      name="reference_mobile"
                      id="filled-basic"
                      label="Reference Mobile No."
                      variant="filled"
                    />
                  </div>
                  <div className="col-md-3">
                    <TextField
                      autoComplete="new-off"
                      fullWidth
                      className="theme-input mb-3"
                      value={registerValues.reference_name || ""}
                      id="filled-basic"
                      label="Reference Name"
                      variant="filled"
                      disabled
                    />
                  </div>
                  <div className="col-md-3">
                    <FormControl
                      fullWidth
                      variant="filled"
                      className="theme-input mb-3"
                    >
                      <InputLabel id="demo-simple-select-filled-label">
                        Category
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={registerValues.category_id || ""}
                        name="category_id"
                        onChange={handleRegisterForm}
                      >
                        {categoryList.length > 0
                          ? categoryList.map((prop, i) => {
                              return (
                                <MenuItem key={i} value={prop.category_id}>
                                  {" "}
                                  {prop.category_name}
                                </MenuItem>
                              );
                            })
                          : null}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-3">
                    <FormControl
                      fullWidth
                      variant="filled"
                      className="theme-input mb-3"
                    >
                      <InputLabel id="demo-simple-select-filled-label">
                        Staff
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={registerValues.staff_id || ""}
                        name="staff_id"
                        onChange={handleRegisterForm}
                      >
                        {staffList.length > 0
                          ? staffList.map((prop, i) => {
                              return (
                                <MenuItem key={i} value={prop.staff_id}>
                                  {" "}
                                  {prop.name}
                                </MenuItem>
                              );
                            })
                          : null}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="row  no-gutters">
                  <div className="col-md-3">
                    <FormControl
                      fullWidth
                      variant="filled"
                      className="theme-input mb-3"
                    >
                      <InputLabel id="demo-simple-select-filled-label">
                        Area
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={registerValues.area_id || ""}
                        name="area_id"
                        onChange={handleRegisterForm}
                      >
                        {areaList.length > 0
                          ? areaList.map((prop, i) => {
                              return (
                                <MenuItem key={i} value={prop.area_id}>
                                  {" "}
                                  {prop.area_name}
                                </MenuItem>
                              );
                            })
                          : null}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-1 d-flex align-items-center">
                    <Button
                      className="btn btn-success areaBtn"
                      onClick={() => {
                        setAreaValues({
                          ...areaValues,
                          area: true,
                          area_name: "",
                        });
                      }}
                      color="secondary"
                    >
                      +
                    </Button>
                  </div>

                  <div className="col-md-8">
                    <TextField
                      autoComplete="new-off"
                      fullWidth
                      className="theme-input mb-3"
                      value={registerValues.profession || ""}
                      onChange={handleRegisterForm}
                      autoFocus
                      name="profession"
                      id="filled-basic"
                      label="Profession"
                      variant="filled"
                    />
                  </div>
                </div>
              </>
            )}
          </DialogContent>
          <DialogActions>
            {registrationForm ? (
              ""
            ) : (
              <Button
                className="btn btn-success"
                onClick={() => setRegistration(true)}
                color="secondary"
              >
                Back
              </Button>
            )}
            <Button
              className="btn btn-danger"
              onClick={() => {
                setOpenForm(false);
                setRegisterValues({
                  ...registerValues,
                  customer_id: 0,
                  full_name: "",
                  mobile: "",
                  dob: null,
                  doa: null,
                  gender: "",
                  address: "",
                  category_id: "",
                  ref_id: "",
                  area_id: "",
                  staff_id: "",
                  profession: "",
                });
                setRegistration(true);
              }}
              color="secondary"
            >
              Close
            </Button>

            {registrationForm ? (
              <Button
                className="btn btn-success"
                onClick={() => {
                  CheckVisitFormValidation();
                }}
                color="secondary"
              >
                Continue
              </Button>
            ) : (
              <Button
                className="btn btn-success"
                onClick={handleSubmitVisitForm}
                color="secondary"
              >
                Continue
              </Button>
            )}
          </DialogActions>
        </div>
      </Dialog>

      <Modal
        isOpen={customerHistory.showVoucherPoint}
        zIndex="99999"
        size="lg"
        backdrop="static"
        centered="true"
      >
        <ModalHeader>Customer Redeem Point System</ModalHeader>

        <ModalBody>
          <Row>
            <Card className="shadow ml-4" style={{ width: "58rem" }}>
              <CardHeader className="d-flex align-items-center justify-content-between font-weight-bold ">
                <Button
                  className="btn btn-info font-weight-bold"
                  color="primary"
                  onClick={() => {
                    setCustomerHistory({
                      ...customerHistory,
                      showRedeemList: true,
                      showRedeemPoint: false,
                    });
                  }}
                >
                  Vouchers : {voucherCount}
                </Button>
                <Button
                  className="btn btn-info font-weight-bold"
                  color="primary"
                  onClick={() => {
                    setCustomerHistory({
                      ...customerHistory,
                      showRedeemPoint: true,
                      showRedeemList: false,
                    });
                  }}
                >
                  Points : {totalPoint}
                </Button>
              </CardHeader>
            </Card>
          </Row>

          <Row>
            <Card
              className="my-2 ml-4"
              color="secondary"
              inverse
              style={{
                width: "58rem",
              }}
            >
              <CardHeader>
                <i>PROFILE</i>
              </CardHeader>
              <CardBody>
                <CardText>
                  <div className="dialog-border">
                    <div className="container-fluid customerDetails">
                      <div className="row">
                        <div className="col-md-4">
                          <p className="text-light mb-3">{"Name"}</p>
                          <h4 className="text-light">
                            {customerDetails.full_name || "N/A"}
                          </h4>
                        </div>
                        <div className="col-md-4">
                          <p className="text-light  mb-3">{"Date Of Birth"}</p>
                          <h4 className="text-light ">
                            {customerDetails.dob || "N/A"}
                          </h4>
                        </div>
                        <div className="col-md-4">
                          <p className="text-light mb-3">
                            {"Date Of Anniversary"}
                          </p>
                          <h4 className="text-light">
                            {customerDetails.doa || "N/A"}
                          </h4>
                        </div>
                      </div>

                      <Divider className="my-3" />

                      <div className="row">
                        <div className="col-md-4">
                          <p className="text-light mb-3">{"Category Name"}</p>
                          <h4 className="text-light">
                            {customerDetails.category_name || "N/A"}
                          </h4>
                        </div>
                        <div className="col-md-4">
                          <p className="text-light mb-3">{"Total Visit"}</p>
                          <h4 className="text-light">
                            {customerDetails.total_visit || "N/A"}
                          </h4>
                        </div>
                        <div className="col-md-4">
                          <p className="text-light mb-3">{"Last Visit"}</p>
                          <h4 className="text-light">
                            {moment(customerDetails.date).format(
                              "DD/MM/YYYY HH:mm"
                            ) || "N/A"}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardText>
              </CardBody>
            </Card>
          </Row>
        </ModalBody>

        <ModalFooter>
          <Button
            className="btn btn-primary m-2"
            color="secondary"
            onClick={() => toggleExtraPoint()}
          >
            Extra
          </Button>
          <Button
            className="btn btn-danger"
            onClick={() => {
              setCustomerHistory({
                ...customerHistory,
                showVoucherPoint: false,
              });
            }}
            color="secondary"
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>

      {/* Voucher */}

      <Modal
        isOpen={customerHistory.showRedeemList}
        zIndex="999999"
        size="lg"
        backdrop="static"
        centered="true"
      >
        <div className="dialog-border">
          {/* <div>
            <Button class="btn btn-success m-1">Active Voucher</Button>
            <Button class="btn btn-secondary m-1">Redeem Voucher</Button>
            <Button class="btn btn-danger m-1">Expire Voucher</Button>
          </div> */}
          <ModalBody
            className="table-responsive"
            style={{ maxHeight: "550px" }}
          >
            <Row>
              <Card className="shadow ml-4" style={{ width: "58rem" }}>
                <CardHeader className="d-flex align-items-center justify-content-between font-weight-bold">
                  <Col>
                    <i>{customerDetails.full_name}</i>
                  </Col>
                  <Col>
                    <i>{customerDetails.mobile}</i>
                  </Col>
                  <Col style={{ backgroundColor: "Orange", color: "white" }}>
                    <Button
                      style={{ backgroundColor: "Orange", color: "white" }}
                    >
                      Total Point:{totalPoint}
                    </Button>
                  </Col>
                </CardHeader>
              </Card>
            </Row>

            <ReactDatatable
              config={config}
              records={CustomerVoucherList}
              columns={voucher_columns}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              className="btn btn-primary m-2"
              onClick={() => {
                setCustomerHistory({
                  ...customerHistory,
                  showRedeemList: false,
                  showRedeemPoint: false,
                });
              }}
            >
              Back
            </Button>
            <Button
              className="btn btn-danger"
              onClick={() => {
                setCustomerHistory({
                  ...customerHistory,
                  showRedeemList: false,
                  showRedeemPoint: false,
                  showVoucherPoint: false,
                });
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>

      {/* Points */}

      <Modal
        isOpen={customerHistory.showRedeemPoint}
        zIndex="999999"
        size="lg"
        backdrop="static"
        centered="true"
      >
        <div className="dialog-border">
          <div>
            <Button class="btn btn-success m-1" onClick={toggleActivePoint}>
              Active Points
            </Button>
            <Button class="btn btn-secondary m-1" onClick={toggleRedeemPoint}>
              Redeem Points
            </Button>
            <Button class="btn btn-danger m-1" onClick={toggleExpirePoint}>
              Expire Points
            </Button>
          </div>
          <ModalBody>
            <Row>
              <Card className="shadow ml-4" style={{ width: "58rem" }}>
                <CardHeader className="d-flex align-items-center justify-content-between font-weight-bold">
                  <Col>
                    <i>{customerDetails.full_name || ""}</i>
                  </Col>
                  <Col>
                    <i>{customerDetails.mobile}</i>
                  </Col>
                  <Col>
                    <Button
                      style={{ backgroundColor: "orange", color: "white" }}
                    >
                      TotalPoint: {totalPoint}
                    </Button>
                  </Col>
                </CardHeader>
              </Card>
            </Row>
            <Row>
              <Col>
                <Form>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="point1">Redeem Point</Label>
                        <Input
                          type="number"
                          name="point1"
                          id="point1"
                          required
                          value={redeemPoint}
                          placeholder="Redeem Point"
                          onChange={handlerPoint}
                          valid={
                            redeemPoint !== "" &&
                            Number(redeemPoint) <= totalPoint
                          }
                          invalid={
                            redeemPoint !== "" &&
                            Number(redeemPoint) > totalPoint
                          }
                        />
                        <FormFeedback valid tooltip>
                          Sweet! The value is within the allowed range.
                        </FormFeedback>
                        <FormFeedback invalid tooltip>
                          Oh opps! The value exceeds the allowed Points.
                        </FormFeedback>
                        <FormText>
                          Enter a value that is smaller or equal to {totalPoint}
                          .
                        </FormText>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for="staff_id">Staff Name</Label>
                        <Input
                          id="saff_id"
                          name="saff_id"
                          type="select"
                          value={redeemStaff}
                          onChange={handlerStaff}
                        >
                          <option value="">Select Staff Name</option>
                          {staffList.map((i) => (
                            <option key={i.id} value={i.name}>
                              {i.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="ml-1">
                    <FormGroup>
                      <Label>Remark</Label>
                      <Input
                        value={text}
                        onChange={handleChange}
                        required={true}
                        placeholder="Type here..."
                        rows={5}
                        cols={100}
                        type="textarea"
                      />

                      <h5 style={{ color: "blue" }}>
                        {maxLength - text.length}/{maxLength}
                      </h5>
                    </FormGroup>
                  </Row>
                </Form>
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter>
          <Button className="btn btn-dark" onClick={() => handlerRedeem()}>
              Submit
            </Button>
            <Button
              className="btn btn-primary m-2"
              onClick={() => {
                setCustomerHistory({
                  ...customerHistory,
                  showRedeemList: false,
                  showRedeemPoint: false,
                });
              }}
              color="secondary"
            >
              Back
            </Button>
            <Button
              className="btn btn-danger"
              onClick={() => {
                setCustomerHistory({
                  ...customerHistory,
                  showRedeemList: false,
                  showRedeemPoint: false,
                  showVoucherPoint: false,
                });
              }}
              color="secondary"
            >
              Close
            </Button>
           
          </ModalFooter>
        </div>
      </Modal>

      {/* -----------------------ActivePointModal-------------------------- */}

      <Modal
        isOpen={pointActiveModal}
        zIndex="999999"
        size="lg"
        backdrop="static"
        centered="true"
      >
        <ModalHeader>
          <Row style={{ marginBottom: "10px" }}>
            <Col sm="6">Active Customer Point</Col>

            <Col sm="6">
              <i
                className="fa fa-close pull-right pointerShow"
                onClick={toggleActivePoint}
              ></i>
            </Col>
          </Row>
        </ModalHeader>

        <ModalBody style={{ maxHeight: "400px", overflowY: "auto" }}>
          <ReactDatatable
            config={config_AREPoint}
            records={CustomerPointList}
            columns={ActivePoint_Columns}
          />
        </ModalBody>
      </Modal>

      {/* ------------------------------------ */}

      {/* -----------------------RedeemPointModal-------------------------- */}

      <Modal
        isOpen={pointRedeemModal}
        zIndex="999999"
        size="lg"
        backdrop="static"
        centered="true"
      >
        <ModalHeader>
          <Row style={{ marginBottom: "10px" }}>
            <Col sm="6">Redeem Customer Point</Col>

            <Col sm="6">
              <i
                className="fa fa-close pull-right pointerShow"
                onClick={toggleRedeemPoint}
              ></i>
            </Col>
          </Row>
        </ModalHeader>

        <ModalBody style={{ maxHeight: "400px", overflowY: "auto" }}>
          <ReactDatatable
            config={config_AREPoint}
            records={CustomerRedeemPoint}
            columns={RedeemPoint_Columns}
          />
        </ModalBody>
      </Modal>

      {/* ------------------------------------ */}

      {/* -----------------------ExpirePointModal-------------------------- */}

      <Modal
        isOpen={pointExpireModal}
        zIndex="999999"
        size="lg"
        backdrop="static"
        centered="true"
      >
        <ModalHeader>
          <Row style={{ marginBottom: "10px" }}>
            <Col sm="6">Customer Expired Point</Col>

            <Col sm="6">
              <i
                className="fa fa-close pull-right pointerShow"
                onClick={toggleExpirePoint}
              ></i>
            </Col>
          </Row>
        </ModalHeader>

        <ModalBody style={{ maxHeight: "400px", overflowY: "auto" }}>
          <ReactDatatable
            config={config_AREPoint}
            records={CustomerExpirePoint}
            columns={ExpirePoint_Columns}
          />
        </ModalBody>
      </Modal>

      {/* ------------------------------------ */}

      <Modal
        isOpen={extraPointeModal}
        zIndex="999999"
        size="lg"
        backdrop="static"
        centered="true"
      >
        <div className="dialog-border">
          <ModalHeader>
            <Row style={{ marginBottom: "10px" }}>
              <Col sm="6">Extra Customer Point</Col>

              <Col sm="6">
                <i
                  className="fa fa-close pull-right pointerShow"
                  onClick={() => {
                    toggleExtraPoint();
                  }}
                ></i>
              </Col>
            </Row>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Card className="shadow ml-4" style={{ width: "58rem" }}>
                <CardHeader className="d-flex align-items-center justify-content-between font-weight-bold">
                  <Col>
                    <i>{customerDetails.full_name || ""}</i>
                  </Col>
                  <Col>
                    <i>{customerDetails.mobile}</i>
                  </Col>
                  <Col>
                    <Button
                      style={{ backgroundColor: "orange", color: "white" }}
                    >
                      TotalPoint: {totalPoint}
                    </Button>
                  </Col>
                </CardHeader>
              </Card>
            </Row>
            <Row>
              <Col>
                <Form>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label fo="point1">Extra Points</Label>

                        <Input
                          type="number"
                          name="point1"
                          id="point1"
                          required
                          value={extraPoint}
                          placeholder="Extra Point"
                          onChange={handlerExtraPoint}
                        />
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup>
                        <Label for="staff_id">Staff Name</Label>
                        <Input
                          id="saff_id"
                          name="saff_id"
                          type="select"
                          value={extraPointStaff}
                          onChange={handlerExtraPointStaff}
                        >
                          <option value="">Select Staff Name</option>
                          {staffList.map((i) => (
                            <option key={i.id} value={i.name}>
                              {i.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="ml-1">
                    <FormGroup>
                      <Label>Remark</Label>
                      <Input
                        value={text}
                        onChange={handleExtraChange}
                        required={true}
                        placeholder="Type here..."
                        rows={5}
                        cols={100}
                        type="textarea"
                      />

                      <h5 style={{ color: "blue" }}>
                        {maxLength - text.length}/{maxLength}
                      </h5>
                    </FormGroup>
                  </Row>
                </Form>
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter>
          <Button
              className="btn btn-dark"
              onClick={() => addExtraPointHandler()}
            >
              Add Point
            </Button>
            <Button
              className="btn btn-primary m-2"
              color="secondary"
              onClick={() => {
                toggleExtraPoint();
              }}
            >
              Back
            </Button>
            <Button
              className="btn btn-danger"
              onClick={() => {
                setCustomerHistory({
                  ...customerHistory,
                  showRedeemList: false,
                  showRedeemPoint: false,
                  showVoucherPoint: false,
                });
                toggleExtraPoint();
              }}
              color="secondary"
            >
              Close
            </Button>
           
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
}
