import React, { Fragment, useState, useEffect } from "react";
import { addDays } from "date-fns";
import moment from "moment";
import ReactDatatable from "@ashvin27/react-datatable";
import generatePDF, { Margin, Resolution } from "react-to-pdf";
// -------

// -------
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import Whatsapp from "./Whatsapp";
import { Divider } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { postMethod } from "../../../../_services/_postMethod";
import { API_BASE_URL } from "../../../../_services/constant";
import Design from "./Design";
import CustomRedeem from "./external_component/CustomRedeem";


function Extra(props) {
  const [DownloadReady, setDownloadReady] = React.useState(false);
  const [DownloadLink, setDownloadLink] = React.useState("");

  const { custInfo, className } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [dashboardShowDate, setDashboardShowDate] = useState([
    {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  // _________________Additional________________________
  // ---------------------------------start--------------------------------

  const resultArray = Object.values(
    custInfo.reduce((accumulator, obj) => {
      const key = `${obj.mobile}-${obj.date}`;

      if (!accumulator[key] || accumulator[key].count < obj.count) {
        accumulator[key] = { ...obj };
      }

      return accumulator;
    }, {})
  );

  // Create a map to store unique objects based on mobile number
  const uniqueObjectsMap = new Map();

  // Iterate through the original array
  resultArray.forEach((obj) => {
    // Check if mobile number already exists in the map
    if (uniqueObjectsMap.has(obj.mobile)) {
      // If yes, update count and date
      const existingObj = uniqueObjectsMap.get(obj.mobile);
      existingObj.customer_id = obj.customer_id;
      existingObj.full_name = obj.full_name;
      existingObj.mobile = obj.mobile;
      existingObj.type = obj.type;
      existingObj.gender = obj.gender;
      existingObj.dob = obj.dob;
      existingObj.doa = obj.doa;
      existingObj.category_name = obj.category_name;
      existingObj.visit_count += obj.visit_count;
      existingObj.date =
        obj.date > existingObj.date ? obj.date : existingObj.date;
    } else {
      // If no, add the object to the map
      uniqueObjectsMap.set(obj.mobile, { ...obj });
    }
  });

  // Convert the map values back to an array
  const newArray = Array.from(uniqueObjectsMap.values());

  // ---------------------------------end----------------------------------

  // ____________________________________________

  const getTargetElement = () => document.getElementById("data_list_table");

  const export_array = () => {
    let arr =
      "Name,Mobile,Type,Gender,D.O.B,D.O.A,Category,Visit Count,Last Visit\r\n";
    let csvContent = "data:text/csv;charset=utf-8," + arr;

    newArray.forEach(function (rowArray) {
      var row = `${rowArray.full_name},${rowArray.mobile},${rowArray.type},${rowArray.gender},${rowArray.dob},${rowArray.doa},${rowArray.category},${rowArray.visit_count}, ${rowArray.last_visit_date}`;
      csvContent += row + "\r\n";
    });

    var encodedUri = encodeURI(csvContent);
    setDownloadLink(encodedUri);
    setDownloadReady(true);
  };
  // ----------------show information---------------------------

  const [customerDetails, setCustomerDetails] = React.useState({
    customer_id: "",
    full_name: "",
    mobile: "",
    type: "",
    gender: "",
    dob: "",
    doa: "",
    category_name: "",
    staff_id: "",
    profession: "",
    address: "",
    email: "",
    visit_count: "",
    date: "",
  });
  const [showDetails, setShowDetails] = useState(false);
  const toggleDetail = function () {
    setShowDetails(!showDetails);
  };

  function findMobileByNumber(mobileArray, targetNumber) {
    const result = mobileArray.find((obj) => obj.mobile === targetNumber);
    return result || null;
  }

  function handlerInfo(num) {
    const resultObject = findMobileByNumber(newArray, num);
    setCustomerDetails({
      customer_id: resultObject.customer_id,
      full_name: resultObject.full_name,
      mobile: resultObject.mobile,
      type: resultObject.type,
      gender: resultObject.gender,
      dob: resultObject.dob == null ? "N/A" : resultObject.dob,
      doa: resultObject.doa == null ? "N/A" : resultObject.doa,
      category_name: resultObject.category_name,
      staff_id: resultObject.staff_id,
      profession: resultObject.profession,
      address: resultObject.address,
      email: resultObject.email,
      visit_count: resultObject.visit_count,
      date: resultObject.date,
    });
    toggleDetail();
  }

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  // ----------------end information----------------------------

  // -----------------------------------
  //Table Config
  const config = {
    page_size: 10,
    length_menu: [5, 10, 20, 50],
  };

  //Table Columns
  const columns_TotalCustomer = [
    {
      key: "full_name",
      text: "Name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <a href="#" onClick={() => handlerInfo(record.mobile)}>
              {record.full_name}
            </a>
          </Fragment>
        );
      },
    },
    {
      key: "mobile",
      text: "Mobile",
      align: "left",
      sortable: true,
    },
    {
      key: "type",
      text: "Type",
      sortable: true,
      align: "left",
      cell: (record) => {
        return <Fragment>{record.type.toLowerCase()}</Fragment>;
      },
    },
    {
      key: "gender",
      text: "Gender",
      sortable: true,
      align: "left",
    },
    {
      key: "dob",
      text: "D.O.B",
      sortable: true,
      align: "left",
      width: '100px'
    },
    {
      key: "doa",
      text: "D.O.A",
      sortable: true,
      align: "left",
      width: '100px'
    },
    {
      key: "category_name",
      text: "Category",
      sortable: true,
      align: "left",
    },
    {
      key: "visit_count",
      text: "Visit Count",
      sortable: true,
      align: "left",
      cell: (record) => {
        return <Fragment>{"" + record.visit_count}</Fragment>;
      },
    },
    {
      key: "total_points",
      text: "Points",
      sortable: true,
      align: "left",
      cell: (record) => {
        return <Fragment>{"" + record.total_points}</Fragment>;
      },
    },
    {
      key: "date",
      text: "Last Visit",
      sortable: true,
      align: "left",
      cell: (record) => {
        return (
          <Fragment>
            {moment(record.customer_check_out).format("DD/MM/YYYY HH:mm")}
          </Fragment>
        );
      },
    },
  ];
  

  return (
    <div>
      <Button color="primary" onClick={toggle}>
        All Customers
      </Button>
      <Modal
        isOpen={modal}
        zIndex="99999"
        size="lg"
        backdrop="static"
        centered="true"
      >
        <ModalHeader>
          <Row style={{ marginBottom: "10px" }}>
            <Col sm="2">Customers</Col>

            <Col sm="10">
              <i
                className="fa fa-close pull-right pointerShow"
                onClick={toggle}
              ></i>
            </Col>
          </Row>

          {/*  ----------------- */}

          <Row>
            {DownloadReady ? (
              <a
                href={DownloadLink}
                className="btn btn-sm btn-outline-theme font-weight-bold py-2 px-3 mx-3"
                download="customers_data.csv"
                target="_blank"
              >
                <i className="flaticon2-download"></i> Click Here to Download
              </a>
            ) : (
              <span
                onClick={() => {
                  export_array();
                }}
                className="btn btn-sm btn-outline-theme font-weight-bold py-2 px-3 mx-3"
              >
                <i className="flaticon2-download"></i> Export
              </span>
            )}
            <span
              onClick={() =>
                generatePDF(getTargetElement, {
                  filename: `Exported_data_${moment(
                    dashboardShowDate.startDate
                  ).format("DD-MM-YYYY")}-${moment(
                    dashboardShowDate.endDate
                  ).format("DD-MM-YYYY")}.pdf`,
                  page: {
                    margin: Margin.SMALL,
                    orientation: "landscape",
                    format: "A4",
                  },
                })
              }
              className="btn btn-sm btn-outline-theme font-weight-bold py-2 px-3 mx-3"
            >
              <i className="flaticon2-download"></i> Download PDF
            </span>
          </Row>

          <Row className="justify-content-end">
            <Col className="text-right">
              <Whatsapp custInfo={newArray} className={className} customerCategory={"TotalVisits"} />
            </Col>
          </Row>

          {/* ------------------ */}
        </ModalHeader>

        <ModalBody style={{ maxHeight: '450px', overflowY: 'auto' }}>
          <div className="table-responsive" id="data_list_table">
            <ModalBody>
              <ReactDatatable
                config={config}
                records={newArray}
                columns={columns_TotalCustomer}
              />
            </ModalBody>
          </div>
        </ModalBody>
        {/* <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Do Something
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter> */}
      </Modal>

      <Modal
        isOpen={showDetails}
        zIndex="999999"
        size="lg"
        backdrop="static"
        centered="true"
        className="custom_modal"
        disableBackdropClick
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="md"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <ModalHeader>
          <Row style={{ marginBottom: "10px" }}>
            <Col sm="6">Customer Details</Col>
            <Col sm="6">
              <i
                className="fa fa-close pull-right pointerShow"
                onClick={toggleDetail}
              ></i>
            </Col>
          </Row>
          <Row>
            <a
              className="btn btn-link btn-sm primary"
              href={
                "/branch/customer/add?id=" +
                window.btoa(customerDetails.customer_id)
              }
            >
              <i className="fa fa-edit"></i>Edit
            </a>
          </Row>
        </ModalHeader>

        <ModalBody>
          <div className="dialog-border">
            <div className="container-fluid customerDetails">
              <div className="row">
                <div className="col-md-3">
                  <p className="text-dark mb-3">{"Name"}</p>
                  <h4 className="text-dark">
                    {customerDetails.full_name || "N/A"}
                  </h4>
                </div>
                <div className="col-md-3">
                  <p className="text-dark mb-3">{"Mobile"}</p>
                  <h4 className="text-dark">
                    {customerDetails.mobile || "N/A"}
                  </h4>
                </div>
                <div className="col-md-3">
                  <p className="text-dark mb-3">{"Gender"}</p>
                  <h4 className="text-dark">
                    {customerDetails.gender || "N/A"}
                  </h4>
                </div>
                <div className="col-md-3">
                  <p className="text-dark mb-3">{"Date Of Birth"}</p>
                  <h4 className="text-dark">{customerDetails.dob || "N/A"}</h4>
                </div>
              </div>
              <Divider className="my-3" />
              <div className="row">
                <div className="col-md-3">
                  <p className="text-dark mb-3">{"Date Of Anniversary"}</p>
                  <h4 className="text-dark">{customerDetails.doa || "N/A"}</h4>
                </div>
                <div className="col-md-3">
                  <p className="text-dark mb-3">{"Profession"}</p>
                  <h4 className="text-dark">
                    {customerDetails.profession || "N/A"}
                  </h4>
                </div>
                <div className="col-md-3">
                  <p className="text-dark mb-3">{"Address"}</p>
                  <h4 className="text-dark">
                    {customerDetails.address || "N/A"}
                  </h4>
                </div>

                <div className="col-md-3">
                  <p className="text-dark mb-3">{"Category Name"}</p>
                  <h4 className="text-dark">
                    {customerDetails.category_name || "N/A"}
                  </h4>
                </div>
              </div>
            </div>

            <Divider className="my-3" />

            <div className="row ml-1">
              <div className="col-md-3 ">
                <p className="text-dark mb-3">{"Total Visit"}</p>
                <h4 className="text-dark">
                  {customerDetails.visit_count || "N/A"}
                </h4>
              </div>
              <div className="col-md-3">
                <p className="text-dark mb-3">{"Last Visit"}</p>
                <h4 className="text-dark ">
                  {moment(customerDetails.date).format("DD/MM/YYYY HH:mm") ||
                    "N/A"}
                </h4>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn btn-danger"
            onClick={toggleDetail}
            color="secondary"
          >
            Close
          </Button>
{/* 
          <Button
            className="btn btn-primary"
            // onClick={toggleWhatsappMessageDetail}
            color="secondary"
          >
            Whatsapp History
          </Button> */}
            
            
          <Design mob={customerDetails.mobile} />
          <div>
          <CustomRedeem mobno = {customerDetails.mobile}/>
          </div>
        </ModalFooter>
      </Modal>

     
    </div>
  );
}

export default Extra;
