import React, { useEffect, useState } from "react";
import "../../../_assets/css/custom.css";
import Paper from "@material-ui/core/Paper";
import CustomerHeader from "./components/header";
import { API_BASE_URL } from "../../../_services/constant";
import CustomerFooter from "./components/footer";

export default function CustomerThankyou(props) {
  return (
    <>
      <div className="container">
        <div className="row toppad3">
          <div className="col-sm-3 mcntr">
            <img src={`${API_BASE_URL}BranchLogo/${localStorage.getItem("jwl_logo")}`} />
          </div>
          <div className="col-sm-6 mtoppad3 text-center">
            <h1 className="clntlogotxt">{localStorage.getItem("jwl_brand_name")}</h1>
          </div>
          <div className="col-sm-3 mtoppad3 mcntr">
            <a href="#">
              <span className="bdgicon">
                <i className="fa fa-heart-o" aria-hidden="true"></i>
              </span>
              <span className="bdg">1</span>
            </a>
            <span className="tollno">Toll Free No. 1800 121 0899</span>
          </div>
        </div>
      </div>
      <div className="clr"></div>
      <div className="container-fluid">
        <div className="row toppad10">
          <div className="container">
            <div className="thnku">
              <div className="thnkulogo">
                <img src={`${API_BASE_URL}BranchLogo/${localStorage.getItem("jwl_logo")}`} />
              </div>
              <div className="thnkutext">
                <h2>Thank You</h2>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
              </div>
            </div>

            {/* <!----Mobile-----> */}
            {/* <div className="row">
              <div className="thnku">
                <div className="col-sm-12">
                  <div className="thnkulogo">
                    <img src="images/logo.png" />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="thnkutext">
                    <h2>Thank You</h2>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="clr"></div>
      <div className="container-fluid"></div>
      <div className="clr"></div>
      {/* <CustomerFooter /> */}
    </>
  );
}
