import React, { useEffect,  useState } from "react";
import { useForm } from "react-hook-form";
import {  useLocation } from "react-router-dom";
import {  Form, FormGroup, Label,  Row, Col, Alert,  Table  } from "reactstrap";
import {
  postMethod,
} from "../../../../_services/_postMethod";
import Loader from "../../../modules/loader";
import {showSuccessToast,showErrorToast} from "../../../../_services/notification";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AddCustomerSales = (props) => {
  let queryParams = useQuery();
  const id = queryParams.get("id") == "" ? null : queryParams.get("id");
  const websitelink = "https://admin.quicktagg.com/";
  const { register, handleSubmit, errors } = useForm();
  const [load, setLoad] = useState(false);
  const [loadInser, setLoadInsert] = useState(false);
  const [loadDelete, setLoadDelete] = useState(false);
  const [loadDeleteMsg, setLoadDeleteMsg] = useState("Deleted");
  const [loadSeachProduct, setLoadSeachProduct] = useState(false);
  const [customerRequired, setCustomerRequired] = useState(false);
  const [productsValidation, setProductsValidation] = useState(false);
  const [subcategory, setSubcategory] = useState([]);
  const [selectedSubCategory, setselectedSubCategory] = useState([]);
  const [productlist, setproductlist] = useState([]);
  const [seachProdutListLoading, setseachProdutListLoading] = useState(false);
  const [searchProduct, setSearchProduct] = useState("");
  const [selectedSubCategoryObject, setSelectedSubCategoryObject] = useState({
    mtran_id: "",
    category_id: "",
    subcategory_id: "",
    text: "",
    min_amount: "",
    max_amount: "",
  });
  const [param, setParam] = useState({
    tran_id: id == null ? "0" : id,
    entry_no: "",
    title: "",
    mobile: "",
    customer_id: "",
    customer_name: "",
    customer_sale_products: [],
    remarks: "",
  });
  const [success, setSuccess] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);
  const [isvalid, setIsValid] = useState({
    mobile: false,
    email: true,
  });
  const [valid, setValid] = useState({
    mobile: true,
    email: true,
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        marginLeft: -10,
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 310,
      },
    },
  };

  const addData = () => {
    setLoadInsert(true);
 if (selectedSubCategory.length == 0) {
      setProductsValidation(true);
      setLoadInsert(false);
    } else {
      setLoadInsert(true);
      param.customer_sale_products = selectedSubCategory;
      postMethod("api/transactions/stockSales/insert", param).then((dataD) => {
        var data = dataD.data[0];
        if (data.valid == true) {
         props.history.push("/branch/stock-sale");
        } else {
          setLoadInsert(false);
        }
      });
    }
  };

  const checkMobile = (val) => {
    setParam({ ...param, mobile: val });
    if (val.length !== 10) {
      setValid({ ...valid, mobile: false });
      setIsValid({ ...isvalid, mobile: false });
    } else {
      setValid({ ...valid, mobile: true });
      postMethod("api/transactions/customer/session/getCustomer", {
        mobile: val,
      }).then((dataD) => {
        var data = dataD.data[0];
        if (data) {
          setParam({
            ...param,
            customer_name: data.full_name,
            customer_id: data.customer_id,
            mobile: data.mobile,
          });
          setIsValid({ ...isvalid, mobile: false });
        } else {
          setParam({
            ...param,
            customer_name: "",
            customer_id: null,
            mobile: val,
          });

          setIsValid({ ...isvalid, mobile: true });
        }
      });
    }
  };

  const handleDelete = () => {
    var proceed = window.confirm("Do you Really Wish to Delete this Entry..?!");

    if (proceed) {
      setLoadDelete(true);
      postMethod("api/transactions/customer/session/delete", {
        tran_id: param.tran_id,
      }).then((data) => {
        if (data.data[0].valid) {
          setSuccessDelete(true);
          setTimeout(() => {
            props.history.push("/branch/customer/session");
          }, 2000);
        } else {
          setSuccessDelete(true);
          setTimeout(() => {
            setSuccessDelete(false);
          }, 4000);
          setLoadDeleteMsg(data.error);
          setLoadDelete(false);
        }
      });
    }
  };

  useEffect(() => {
    var selectCategory = [];
    postMethod("api/transactions/customer/session/getSubcategory", {
      branch_id: localStorage.getItem("jwl_branch_id"),
    }).then((data) => {
      setSubcategory(data.data);
      selectCategory = data.data;
    });
    setLoad(true);
    if (id !== null) {
      var temp = window.atob(id);
      postMethod("api/transactions/stockSales/preview", {
        tran_id: temp,
      }).then((dataD) => {
        var data = dataD.data[0];
        setParam({
          ...param,
          tran_id: temp,
          entry_no: data.entry_no,
          title: data.title,
          mobile: data.mobile,
          customer_id: data.customer_id, 
          customer_name: data.customer_name, 
          customer_sale_products: data.products, 
          remarks: data.remarks, 
        });
        setselectedSubCategory(data.products); 

        let newselectedCategoryArr = [...data.products]; 
        for ( 
          let Mainindex = 0;
          Mainindex < selectCategory.length;
          Mainindex++
        ) {
          const elementMain = selectCategory[Mainindex];

          for (
            let logoindex = 0;
            logoindex < newselectedCategoryArr.length;
            logoindex++
          ) {
            const element = newselectedCategoryArr[logoindex];
            if (element.subcategory_id === elementMain.subcategory_id) {
              selectCategory[Mainindex].checked = "true";
            }
          }
        }
        setSubcategory([...selectCategory]);
       
        setLoad(false);
      });
    } else {
      postMethod("api/transactions/stockSales/preview", {
        tran_id: 0,
      }).then((dataD) => {
        var data = dataD.data[0];
        setParam({ ...param, entry_no: data.entry_no });
        setLoad(false);
      });
    }
  }, []);
  // check object is null or undefined
  const checkObject = (obj) => {
    return obj === undefined
      ? {}
      : Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  // Add  Signle Product into Selected Array
  const AddProduct = (Obj) => {

    console.log( Obj.sale_qty);
    var tempIndex = selectedSubCategory.findIndex(
      (x) => x.product_id === Obj.product_id
    );
    var tempArry = [...selectedSubCategory];
    if (tempIndex > -1) {
      tempArry.splice(tempIndex, 1);
      setselectedSubCategory(tempArry);
    } else {
      var tempparam = {
        mtran_id: param.tran_id,
        category_id: Obj.category_id,
        subcategory_id: Obj.subcategory_id,
        product_id: Obj.product_id,
        product_code: Obj.product_code,
        product_name: Obj.product_name,
        gender: Obj.gender,
        price: Obj.price,
        qty: Obj.sale_qty == "on" ? 1 : Obj.sale_qty,
        url_image: Obj.url_image,
        image_path: Obj.image_path,
      };
      tempArry.push(tempparam);
      setselectedSubCategory(tempArry);
    }
  };

  // Add Or Remove ALL Product from arry
  const AddAllProduct = (checkAll) => {
    var tempArry = [...selectedSubCategory];
    if (checkAll) {
      productlist.forEach((Obj) => {
        var tempIndex = tempArry.findIndex(
          (x) => x.product_id === Obj.product_id
        );
        if (tempIndex == -1) {
          var tempparam = {
            mtran_id: param.tran_id,
            category_id: Obj.category_id,
            subcategory_id: Obj.subcategory_id,
            product_id: Obj.product_id,
            product_code: Obj.product_code,
            product_name: Obj.product_name,
            gender: Obj.gender,
            price: Obj.price,
            qty: Obj.qty,
            url_image: Obj.url_image,
            image_path: Obj.image_path,
          };
          tempArry.push(tempparam);
        }
      });
    } else {
      productlist.forEach((Obj) => {
        var tempIndex = tempArry.findIndex(
          (x) => x.product_id === Obj.product_id
        );
        if (tempIndex > -1) {
          tempArry.splice(tempIndex, 1);
        }
      });
    }

    setselectedSubCategory(tempArry);
  };

  const SearchProduct = () => {
    setLoadSeachProduct(true);
    if (
      selectedSubCategoryObject.subcategory_id !== "" &&
      selectedSubCategoryObject.max_amount !== "" &&
      selectedSubCategoryObject.min_amount !== ""
    ) {
      postMethod("api/transactions/customer/session/getProducts", {
        subcategory_id: selectedSubCategoryObject.subcategory_id,
        min_amount: selectedSubCategoryObject.min_amount,
        max_amount: selectedSubCategoryObject.max_amount,
      }).then((data) => {
        setproductlist(data.data);
      });
    }
    setLoadSeachProduct(false);
  };

  const removeProduct = (i) => {
    let tempArry = [...selectedSubCategory];
    tempArry.splice(i, 1);
    setselectedSubCategory(tempArry);
  };

    const searchProductFun = () => {
      setseachProdutListLoading(true);
      postMethod("api/transactions/customer/session/getProductsbyProductCode", {
        search: searchProduct,
      }).then((data) => {
        setproductlist(data.data);
        setseachProdutListLoading(false);
      });
    };

  return (
    <>
      {load ? <Loader /> : null}
      <Form onSubmit={handleSubmit(addData)}>
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Add Customer Sales</h3>
            </div>
          </div>
          <div className="card-body">
            <Row>
              <Col sm={4}>
                <FormGroup>
                  <Label>Entry No.</Label>
                  <input
                    type="text"
                    required
                    value={param.entry_no}
                    disabled="true"
                    className="form-control h-auto py-2 px-6"
                    name="entry_no"
                    onChange={(e) =>
                      setParam({
                        ...param,
                        entry_no: e.target.value,
                      })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Title</Label>
                  <input
                    type="text"
                    required
                    value={param.title}
                    className="form-control h-auto py-2 px-6"
                    name="title"
                    onChange={(e) =>
                      setParam({
                        ...param,
                        title: e.target.value,
                      })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>
                    Mobile{" "}
                    {customerRequired ? (
                      <label className="text-danger">*Required</label>
                    ) : null}
                  </Label>
                  <input
                    type="number"
                    value={param.mobile}
                    className="form-control h-auto py-2 px-6"
                    name="mobile"
                    onChange={(e) => checkMobile(e.target.value)}
                    ref={register}
                  />
                  {!valid.mobile && (
                    <span className="form-text text-danger">
                      Please enter valid mobile
                    </span>
                  )}
                  {isvalid.mobile && (
                    <span className="form-text text-danger">
                      This Mobile number not registered
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Customer Name</Label>
                  <input
                    type="text"
                    required
                    disabled
                    value={param.customer_name}
                    className="form-control h-auto py-2 px-6"
                    name="customer_name"
                    onChange={(e) =>
                      setParam({ ...param, customer_name: e.target.value })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Label>Remarks</Label>
                  <textarea
                    value={param.remarks}
                    className="form-control h-auto py-2 px-6"
                    name="remarks"
                    onChange={(e) =>
                      setParam({ ...param, remarks: e.target.value })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>
             
              <Col sm={6}>
                <h4>Searched Products</h4>
              </Col>
              <Col sm={6}>
                <FormGroup className="d-flex">
                  <input
                    type="text"
                    placeholder="Search by produuct code"
                    value={searchProduct}
                    className="form-control h-auto py-2 px-6"
                    name="search"
                    onChange={(e) => setSearchProduct(e.target.value)}
                  />
                  {seachProdutListLoading ? (
                    <button type="button" className="btn-info">
                      Searching..
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn-success"
                      onClick={() => {
                        searchProductFun();
                      }}
                    >
                      Search
                    </button>
                  )}
                </FormGroup>
              </Col>
              <Col sm={12}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th width="12%">Product Code </th>
                      <th>Product Name</th>
                      <th>Gender</th>
                      <th>Price</th>
                      <th width="10%">Available Qty</th>       
                      <th width="10%">Qty</th>                      
                      <th>Image</th>
                      <th className="d-flex w-100">
                        ALL{" "}
                        <input
                          type="checkbox"
                          className="mt-2 ml-2"
                          name="available"
                          onChange={(e) => {
                            AddAllProduct(e.target.checked);
                          }}
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {productlist.length > 0 ? (
                      productlist.map((obj, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              ({i + 1}) {obj.product_code}
                            </td>
                            <td>{obj.product_name}</td>
                            <td>{obj.gender}</td>
                            <td>{obj.price}</td>
                            <td>{obj.qty}</td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={obj.sale_qty ? obj.sale_qty : 1}
                                onChange={(e) => {
                                  productlist[i].sale_qty = e.target.value; 
                                   if(obj.qty <  e.target.value)
                                  {
                                    showErrorToast("Qty is not more then available qty.");
                                  }
                                }}
                              ></input>
                            </td>
                            <td>
                              <img
                                src={obj.url_image + "" + obj.image_path}
                                width="36px"
                                alt=""
                              />{" "}
                            </td>

                            <td>
                              <input
                                type="checkbox"
                                checked={
                                  selectedSubCategory.findIndex(
                                    (e) => e.product_id === obj.product_id
                                  ) > -1
                                    ? true
                                    : false
                                }
                                className="form-control h-auto py-2 px-6"
                                name="available"
                                onChange={(e) => { 

                                  if(e.target.checked == true){
                                    if(obj.sale_qty == undefined || obj.sale_qty == "" )
                                    {
                                    var temp = [...productlist];
                                    temp[temp.findIndex((x) => x.product_id == obj.product_id)].sale_qty =
                                      e.target.value;  
                                      AddProduct(obj);                                 
                                    }
                                    else if(obj.qty >=  obj.sale_qty){
                                      AddProduct(obj);      
                                    }
                                    else{
                                      showErrorToast("Qty is not more then available qty.");
                                    }
                                  }
                                 
                                
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="5" className="text-center">
                          No Data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>

              {selectedSubCategory.length > 0 ? (
                <Col sm={12}>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th width="12%">Product Code </th>
                        <th>Product Name</th>
                        <th>Gender</th>
                        <th>Price</th>
                        <th>qty</th>
                        <th>Image</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedSubCategory.length > 0
                        ? selectedSubCategory.map((obj, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  {i + 1}) {obj.product_code}
                                </td>
                                <td>{obj.product_name}</td>
                                <td>{obj.gender}</td>
                                <td>{obj.price}</td>
                                <td>{obj.qty}</td>
                                <td>
                                  <img
                                    src={obj.url_image + "" + obj.image_path}
                                    width="36px"
                                    alt=""
                                  />{" "}
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    onClick={() => removeProduct(i)}
                                  >
                                    x
                                  </button>
                                </td>
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  </Table>
                </Col>
              ) : null}
            </Row>

            {success ? <Alert color="success">Saved Successfully</Alert> : null}
            {successDelete ? (
              <Alert color="danger">{loadDeleteMsg}</Alert>
            ) : null}
          </div>
          <div className="card-footer text-right">
            <a
              href="/branch/customer/session"
              className="btn btn-secondary mr-2"
            >
              Cancel
            </a>
            {id &&
              (loadDelete ? (
                <button type="button" className="btn btn-danger mr-2">
                  Deleting..
                </button>
              ) : (
                <button
                  type="button"
                  onClick={handleDelete}
                  className="btn btn-danger mr-2"
                >
                  Delete
                </button>
              ))}

            {loadInser ? (
              <button type="button" className="btn btn-primary ">
                Saving..
              </button>
            ) : (
              <button type="submit" className="btn btn-primary ">
                Save
              </button>
            )}
          </div>
        </div>
      </Form>
    </>
  );
};

export default AddCustomerSales;
