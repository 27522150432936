import React, { useEffect, useState } from "react";

const CustomerFooter = (props) => {
  const [year, setyear] = useState("");
  const [branchName, setBranchName] = useState("");
  const [branchFacebook, setBranchFacebook] = useState("");
  const [branchInstagram, setBranchInstagram] = useState("");
  const [branchTwitter, setBranchTwitter] = useState("");
  const [branchLinkedin, setBranchLinkedin] = useState("");

  useEffect(() => {
    setyear(new Date().getFullYear());
    setBranchName(localStorage.getItem("jwl_brand_name"));
    setBranchFacebook(localStorage.getItem("jwl_facebook"));
    setBranchInstagram(localStorage.getItem("jwl_instagram"));
    setBranchTwitter(localStorage.getItem("jwl_twitter"));
  }, []);

  return (
    <footer>
      <div className="container mcntr">
        <div className="row">
          <div className="col-sm-10">
            © {year} {branchName} All Rights Reserved
          </div>
          <div className="col-sm-2">
            <div className="text-center center-block">
              {branchFacebook ? (
                <a href={branchFacebook}>
                  <i id="social-fb" className="fa fa-facebook fa-2x social"></i>
                </a>
              ) : null}
              {branchInstagram ? (
                <a href={branchInstagram}>
                  <i
                    id="social-gp"
                    className="fa fa-instagram fa-2x social"
                  ></i>
                </a>
              ) : null}
              {branchLinkedin ? (
                <a href={branchLinkedin}>
                  <i id="social-em" className="fa fa-linkedin fa-2x social"></i>
                </a>
              ) : null}
              {branchTwitter ? (
                <a href={branchTwitter}>
                  <i id="social-tw" className="fa fa-twitter fa-2x social"></i>
                </a>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default CustomerFooter;
