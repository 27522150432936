import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useLocation } from "react-router-dom";
import { Button, Form, FormGroup, Label, FormText, Row, Col, Alert, Table } from "reactstrap";
import { API_BASE_URL } from "../../../../_services/constant";
import { Card, TextField } from "@material-ui/core";
import { post } from "axios";

import {
  postMethod,
  validateMobile,
  singleParam,
  editUserGrid,
} from "../../../../_services/_postMethod";
import Loader from "../../../modules/loader";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AddStockin = (props) => {
  let queryParams = useQuery();
  const id = queryParams.get("id") == "" ? null : queryParams.get("id");
  const { register, handleSubmit, errors } = useForm();
  const [load, setLoad] = useState(false);
  const [loadInser, setLoadInsert] = useState(false);
  const [loadDelete, setLoadDelete] = useState(false);  
  const [loadDeleteMsg, setLoadDeleteMsg] = useState("Deleted");
  const [productlist, setProductlist] = useState([]);
  const [stockinproducts, setStockinproducts] = useState([]);
  const [stockinproductParam, setStockinproductParam] = useState({
    product_id: "",
    product: "",
    barcode: "",
    description: "",
    qty: "",
  });

  const [param, setParam] = useState({
    tran_id: id == null ? "0" : id,
    branch_id: 0,
    date: "",
    entry_no: "",
    remarks: "",
    stock_in_products: [],
  });
  const [success, setSuccess] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false); 

  useEffect(() => {
    if (id !== null) {
      setLoad(true);
      var temp = window.atob(id);
      postMethod("api/transactions/stockin/preview", { tran_id: temp }).then((data) => {
        setParam({
          ...param,
          branch_id: data[0][0].branch_id,
          date: data[0][0].date.split("T")[0],
          entry_no: data[0][0].entry_no,
          remarks: data[0][0].remarks,
          stock_in_products: data[1],
        });
        setStockinproducts(data[1]);
        setLoad(false);
      });
    }
    postMethod("api/transactions/stockin/getproductlist", {}).then((data) => {
      setProductlist(data.data);
    });
  }, []);

  // check object is null or undefined
  const checkObject = (obj) => {
    return obj === undefined ? {} : Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const AddProduct = () => {
    if (
      stockinproductParam.product_id !== "" &&
      stockinproductParam.barcode !== "" &&
      stockinproductParam.description !== "" &&
      stockinproductParam.qty !== ""
    ) {
      stockinproducts.push(stockinproductParam);
      setStockinproductParam({
        product_id: "",
        product: "",
        barcode: "",
        description: "",
        qty: "",
      });
    }
  };
  const removeProduct = (i) => {
    let tempArry = [...stockinproducts];
    tempArry.splice(i, 1);
    setStockinproducts(tempArry);
  };

  const _Save = () => {
    var temp = window.atob(id);
    let temparam = {
      tran_id: temp,
      branch_id: param.branch_id,
      date: param.date, 
      entry_no: param.entry_no,
      remarks: param.remarks,
      stock_in_products: stockinproducts
  
    };
    postMethod("api/transactions/stockin/insert", temparam).then((data) => {
      if (data.valid) {
        setSuccess(true);
        setTimeout(() => {
          props.history.push("/branch/stock-in");
        }, 2000);
      }
    });
  };
  const handleDelete = () => {
    var proceed = window.confirm("Do you Really Wish to Delete this Entry..?!");
    var temp = window.atob(id);
    if (proceed) {
      postMethod("api/transactions/stockin/delete", { tran_id: temp }).then((data) => {
        console.log(data);
        if (data.valid) {
          setSuccessDelete(true);
          setTimeout(() => {
            props.history.push("/branch/stock-in");
          }, 2000);
        }
      });
    }
  };

  return (
    <>
      {load ? <Loader /> : null}
      <Form onSubmit={handleSubmit(_Save)}>
        <input
          type="hidden"
          name="product_id"
          value={id == null ? "0" : param.product_id}
          ref={register}
        />
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Add Stock In</h3>
            </div>
          </div>
          <div className="card-body">
            <Row>
              <Col sm={3}>
                <FormGroup>
                  <Label>Date</Label>
                  <TextField
                  required
                    type="date"
                    value={param.date}
                    className="form-control"
                    onChange={(e) => {
                      setParam({
                        ...param,
                        date: e.target.value,
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <FormGroup>
                  <Label>Entry No</Label>
                  <input
                    type="text"
                    required
                    value={param.entry_no}
                    className="form-control h-auto py-2 px-6"
                    name="entry_no"
                    onChange={(e) => setParam({ ...param, entry_no: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label>Remarks</Label>
                  <input
                    type="text"
                    required
                    value={param.remarks}
                    className="form-control h-auto py-2 px-6"
                    name="remarks"
                    onChange={(e) => setParam({ ...param, remarks: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Barcode</th>
                      <th>Description</th>
                      <th>Qty</th>
                      <th>Action</th>
                    </tr>
                    <tr>
                      <th>
                        <select
                          className="form-control h-auto py-2 px-6"
                          name="product"
                          value={stockinproductParam.product_id}
                          onChange={(e) =>
                            setStockinproductParam({
                              ...stockinproductParam,
                              product_id: e.target.value,
                              product: e.target.options[e.target.selectedIndex].text,
                            })
                          }
                        >
                          <option value="">-select-</option>
                          {checkObject(productlist)
                            ? null
                            : productlist.map((obj, i) => {
                                return (
                                  <option key={i} value={obj.product_id}>
                                    {obj.product_code}
                                  </option>
                                );
                              })}
                        </select>
                      </th>
                      <th>
                        <input
                          type="text"
                          className="form-control h-auto py-2 px-6"
                          name="barcode"
                          onChange={(e) =>
                            setStockinproductParam({
                              ...stockinproductParam,
                              barcode: e.target.value,
                            })
                          }
                          value={stockinproductParam.barcode}
                        />
                      </th>
                      <th>
                        <input
                          type="text"
                          className="form-control h-auto py-2 px-6"
                          name="description"
                          onChange={(e) =>
                            setStockinproductParam({
                              ...stockinproductParam,
                              description: e.target.value,
                            })
                          }
                          value={stockinproductParam.description}
                        />
                      </th>
                      <th>
                        <input
                          type="number"
                          className="form-control h-auto py-2 px-6"
                          name="qty"
                          value={stockinproductParam.qty}
                          onChange={(e) =>
                            setStockinproductParam({ ...stockinproductParam, qty: e.target.value })
                          }
                        />
                      </th>
                      <th>
                        <button type="button" className="btn-success" onClick={() => AddProduct()}>
                          Add
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkObject(stockinproducts)
                      ? null
                      : stockinproducts.map((obj, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                {i + 1}) {obj.product}
                              </td>
                              <td>{obj.barcode}</td>
                              <td>{obj.description}</td>
                              <td>{obj.qty}</td>
                              <td>
                                <button type="button" onClick={() => removeProduct(i)}>
                                  x
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                  </tbody>
                </Table>
              </Col>
            </Row>
            {success ? <Alert color="success">Saved Successfully</Alert> : null}
            {successDelete ? <Alert color="danger">Entery deleted</Alert> : null}
          </div>
          <div className="card-footer text-right">
            <a href="/branch/stock-in" className="btn btn-secondary mr-2">
              Cancel
            </a>
            {id && (
              <button type="button" onClick={handleDelete} className="btn btn-danger mr-2">
                Delete
              </button>
            )}
            <button type="submit" className="btn btn-primary ">
              Submit
            </button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default AddStockin;
