import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../../../_services/constant";
import { postMethod } from "../../../../_services/_postMethod";
import videocall from '../../../../_assets/media/videocall.jpg';
import checked_icon from '../../../../_assets/media/checked.png';

const CustomerHeader = (props) => {
  const { count } = props;
  const [countInnernal , setCountInnernal] = useState(0);
  const [seeDesign , setSeeDesign] = useState(false);
   useEffect(() => { 
    setCountInnernal(count);  
  },[count]);




useEffect(() => {
  postMethod("api/session/wishlist", { }).then((data) => {
    setCountInnernal(data.data.length);
  });
},[]);




  return (
    <React.Fragment>      
      <header className="main_header_area">
      <div className="container">
        <div className="row toppad2 mtoppad2">
          <div className="col-6 col-sm-3 col-lg-3">
            <img onClick={()=> window.location.pathname="business-view/home"}
              src={`${API_BASE_URL}BranchLogo/${localStorage.getItem("jwl_logo")}`}
              className="img-fluid mlogo"
            />
          </div>
          <div className="col-6 col-sm-9 col-lg-9 rightnav">
            <ul>
              <li className="d-block d-md-none">
                <a href="tel:+9898989898">
                  <i className="fa fa-phone protxt" aria-hidden="true"></i>
                </a>
              </li>
              <li>
                <a href="#" onClick={() => {
                    window.location.pathname = "business-view/profile";
                  }}>
                  <i className="fa fa-user protxt" aria-hidden="true"></i>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  onClick={() => {
                    window.location.pathname = "business-view/wish-list";
                  }}
                >
                 <span className="bdgicon">
                    <i className={countInnernal > 0 ? "fa fa-heart text-danger" : "fa fa-heart-o"} style={{color: "#007bff"}} aria-hidden="true"></i>
                    <span className="bdg">{countInnernal}</span>
                  </span>
                </a>
              </li>              
            </ul>
          </div>
        </div>
      </div>
    </header>
    </React.Fragment>
    
  );
};

export default CustomerHeader;
