// import React, { Fragment, useEffect, useState } from "react";
// import ReactDatatable from "@ashvin27/react-datatable";
// import { postMethod } from "../../../../_services/_postMethod";
// import Loader from "../../../modules/loader";

// const Subcategory = (props) => {
//   const [load, setLoad] = React.useState(false);
  
//   //Edit Function
//   const editRecord = (val) => {
//     var temp = window.btoa(val);
//     props.history.push("/branch/product/subcategory/add?id=" + temp);
//   };



// //Table Objects
// const [tableState, setTableState] = useState({
//       loading: true,
//       total_pages: 0,
//       records: []
// });

// //Table Config
// const config= {
//       key_column: 'subcategory_id',
//       page_size: 10,
//       length_menu: [10, 20, 50],
//       show_filter: true,
//       show_pagination: true,
//       pagination: 'advance',
//       no_data_text: 'No data available!',
//       language: {
//           length_menu: "Show _MENU_ result per page",
//           filter: "Search...",
//           info: "Showing _START_ to _END_ of _TOTAL_ records",
//           loading_text: "Please be patient while data loads...",
//           pagination: {
//               first: "First",
//               previous: <span>&#9668;</span>,
//               next: <span>&#9658;</span>,
//               last: "Last"
//           }
//       },
//       button: {
//           excel: false,
//           print: false
//       }
//   };
//   // Handler for Table changes      
//   // const tableChangeHandler = data => {
//   //         let queryString = Object.keys(data).map((key) => {
//   //             if (key === "sort_order" && data[key]) {
//   //                 return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
//   //             } else {
//   //                 return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
//   //             }
//   //         }).join('&');
//   //         getData(queryString);
//   //     }

//   const tableChangeHandler = data => {
//     let queryString = Object.keys(data).map((key) => {
//       if (key === "sort_order" && data[key]) {
//         return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
//       } else if (key === "page_number") {
//         return encodeURIComponent("page_number") + '=' + encodeURIComponent(data[key])
//       } else if (key === "page_size") {
//         return encodeURIComponent("page_size") + '=' + encodeURIComponent(data[key])
//       } else {
//         return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
//       }
//     }).join('&');
//     getData(queryString);
//   }
  
//   // Get Table data
//   // const getData = (queryString = "") => {
//   //       let url = "api/masters/product/subcategory/web-browse/?" + queryString
//   //       postMethod(url, {}).then((res) => {
//   //       setTableState({...tableState,
//   //               loading: false,
//   //               total: res.recordsFiltered,
//   //               records: res.Data
//   //           })
//   //   });               
//   //   }
//   const getData = (queryString = "") => {
//     let url = "api/masters/product/subcategory/web-browse/?" + queryString
//     postMethod(url, {}).then((res) => {
//       setTableState({
//         ...tableState,
//         loading: false,
//         total_pages: Math.ceil(res.recordsFiltered / (queryString.page_size || 10)), // Calculate total pages
//         total_record: res.totalRecords,
//         records: res.Data
//       })
//     });
//   }
  
//    //Table Columns 
//   const columns = [
//     {
//       key: "category_name",
//       text: "Category Name",
//       align: "left",
//       sortable: true,
//     },
//     {
//       key: "subcategory_name",
//       text: "Subcategory Name",
//       align: "left",
//       sortable: true,
//     },

//     {
//       key: "action",
//       text: "Action",
//       className: "action",
//       width: 100,
//       align: "left",
//       sortable: false,
//       cell: (record) => {
//         return (
//           <Fragment>
//             <button
//               className="btn btn-link btn-sm"
//               onClick={() => editRecord(record.subcategory_id)}
//               style={{ marginRight: "5px" }}
//             >
//               <i className="fa fa-edit"></i>
//             </button>
//           </Fragment>
//         );
//       },
//     },
//   ];


//   useEffect(() => {
//     setLoad(true);
//     getData();   
//     setLoad(false);
//   }, []);

//   return (
//     <>
//       {load ? <Loader /> : null}
//       <div className="card card-custom gutter-b">
//         <div className="card-header">
//           <div className="card-title">
//             <h3 className="card-label">Product SubCategory</h3>
//           </div>
//           <div className="card-toolbar">
//             <a
//               href="/branch/product/subcategory/add"
//               className="btn btn-sm btn-outline-theme font-weight-bold py-2 px-3"
//             >
//               <i className="flaticon2-user"></i> Add SubCategory
//             </a>
//           </div>
//         </div>
//         <div className="card-body">
//            {/* <ReactDatatable
//                         config={config}
//                         records={tableState.records}
//                         columns={columns}
//                         dynamic={true}
//                         total_record={tableState.total_record}
//                         onChange={tableChangeHandler}
//                         loading={tableState.loading} /> */}

// <ReactDatatable
//   config={config}
//   records={tableState.records}
//   columns={columns}
//   dynamic={true}
//   total_record={tableState.total_record}
//   onChange={tableChangeHandler}
//   loading={tableState.loading}
// />

//         </div>
//       </div>
//     </>
//   );
// };

// export default Subcategory;



import React, { Fragment, useEffect, useState } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { postMethod } from "../../../../_services/_postMethod";
import Loader from "../../../modules/loader";

const Subcategory = (props) => {
  const [load, setLoad] = useState(false);
  const [tableState, setTableState] = useState({
    loading: true,
    total_record: 0,  // Change this to total_record
    records: []
  });

  // Edit Function
  const editRecord = (val) => {
    var temp = window.btoa(val);
    props.history.push("/branch/product/subcategory/add?id=" + temp);
  };

  // Table Config
  const config = {
    key_column: 'subcategory_id',
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    pagination: 'advance',
    no_data_text: 'No data available!',
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Search...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      loading_text: "Please be patient while data loads...",
      pagination: {
        first: "First",
        previous: <span>&#9668;</span>,
        next: <span>&#9658;</span>,
        last: "Last"
      }
    },
    button: {
      excel: false,
      print: false
    }
  };

  // Handler for Table changes      
  const tableChangeHandler = data => {
    let queryString = Object.keys(data).map((key) => {
      if (key === "sort_order" && data[key]) {
        return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
      } else if (key === "page_number") {
        return encodeURIComponent("page_number") + '=' + encodeURIComponent(data[key])
      } else if (key === "page_size") {
        return encodeURIComponent("page_size") + '=' + encodeURIComponent(data[key])
      } else {
        return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      }
    }).join('&');
    getData(queryString);
  };

  // Get Table data
  const getData = (queryString = "") => {
    let url = "api/masters/product/subcategory/web-browse/?" + queryString
    postMethod(url, {}).then((res) => {
      setTableState({
        ...tableState,
        loading: false,
        total_record: res.totalRecords, // Set total_record for pagination
        records: res.Data
      });
    }).catch((err) => {
      console.error("Error fetching data: ", err);
      setTableState({
        ...tableState,
        loading: false
      });
    });
  };

  
   //Table Columns 
  const columns = [
    {
      key: "category_name",
      text: "Category Name",
      align: "left",
      sortable: true,
    },
    {
      key: "subcategory_name",
      text: "Subcategory Name",
      align: "left",
      sortable: true,
    },

    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="btn btn-link btn-sm"
              onClick={() => editRecord(record.subcategory_id)}
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-edit"></i>
            </button>
          </Fragment>
        );
      },
    },
  ];

  useEffect(() => {
    setLoad(true);
    getData();   
    setLoad(false);
  }, []);

  return (
    <>
      {load ? <Loader /> : null}
      <div className="card card-custom gutter-b">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">Product SubCategory</h3>
          </div>
          <div className="card-toolbar">
            <a
              href="/branch/product/subcategory/add"
              className="btn btn-sm btn-outline-theme font-weight-bold py-2 px-3"
            >
              <i className="flaticon2-user"></i> Add SubCategory
            </a>
          </div>
        </div>
        <div className="card-body">
          <ReactDatatable
            config={config}
            records={tableState.records}
            columns={columns}
            dynamic={true}
            total_record={tableState.total_record}  // Ensure this is correctly set
            onChange={tableChangeHandler}
            loading={tableState.loading}
          />
        </div>
      </div>
    </>
  );
};

export default Subcategory;
