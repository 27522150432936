import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "../../../_assets/css/custom.css";
import "react-owl-carousel2/lib/styles.css";
import { postMethod } from "../../../_services/_postMethod";
import AddProduct from "../branch/products/addproduct";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import { API_BASE_URL } from "../../../_services/constant";
import OwlCarousel from "react-owl-carousel2";
import Slide from "@material-ui/core/Slide";
import CustomerHeader from "./components/header";
import CustomerFooter from "./components/footer";
import CustomerBanner from "./components/banner";
import CustomerVoucherCom from "./components/voucher";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#000',
    backgroundColor: '#fff'
  },
}));

const CustomerProductview = (props) => {
  const classes = useStyles(); 
  const { register, handleSubmit, errors } = useForm();
  const [isvalid, setValid] = useState(false);
  const [load, setLoad] = useState(false);
  const inputRef = React.createRef(null);
  const [voucherList , setVoucherList] = useState([]);
  const [snackbarMsg , setSnackbarMsg] = useState("");
  const [products, setProducts] = useState([]);

  const [productCount, setProductCount] = React.useState(0);
  const [product, setProduct] = useState([
    {
      product_id: "",
      product_code: "",
      Metal: "",
      discounted_price: "",
      price: "",
      product_name: "",
      remarks: "",
      weight: "",
      size_length: "",
      trial:false
    },
  ]);
  const [productImages, setProductImages] = useState([]);
  const [productImage, setProductImage] = useState("");
  const optionsSimilar = {
    responsive:{
      0:{
        items:2
         
      },
      600:{
        items:2,
         
      },
      1000:{
        items:4
        
      }
    },
    dots: false,
    margin:20,
    autoplay: true,
    // center:true,
    stagePadding: 10,
    loop: false,
    nav: false,
  };
  const optionsProduct = {     
    responsive:{
      0:{
        items:1,
        margin:10
      },
      600:{
        items:1,
        margin:10
      },
      1000:{
        items:1,
        margin:10
      }
    },
    // dots: false,    
    autoplay: false,
     center:true,
    stagePadding: 10,
    loop: true,
    nav: true,
  };

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const AddProductList = (type) => {
    postMethod("api/session/add_to_cart", {
      product_id: product.product_id,
      price: product.discounted_price,
      added_from: "general",
      type: type,
    }).then((data) => {
      if(type == "wish")  
      {
        setSnackbarMsg("Product added to wish list. Customer executive will contact you soon.");
      }
      else{
        setSnackbarMsg("Product added to trial. Customer executive will contact you soon.");
      }
      handleClick();
      postMethod("api/session/wishlist", { }).then((data) => {
        setProductCount(data.data.length);
      });  
    });
  };
 
  useEffect(() => {
    window.scrollTo(0, 0); 
    setLoad(true);
    postMethod("api/session/products_view", {
      product_id: localStorage.getItem("product_id"),
    }).then((dataD) => {
      var data = dataD.data;
      setProduct(data[0][0]);
      setProductImage(data[1][0].image_path);
      setProductImages(data[1]);
      postMethod("api/session/all_similar_product_list_general", {   
        category_id: localStorage.getItem("category_id"),
        min_price: 0,
        max_price: data[0][0].price,
        metal: data[0][0].Metal,
        material: data[0][0].material,
        subcategory_id:"0",
        skip: "0",
      }).then((data) => {
        var result = data.data.filter((item) => item.product_id != localStorage.getItem("product_id")); 
        setProducts(result);   
      });
       setLoad(false);
    });
    

  }, []);

  const RedirectToProduct = (product_id) => {  
    window.scrollTo(0, 0);  
    localStorage.setItem("product_id", product_id);
    window.location.reload(); 
  };


  return (
     <React.Fragment>
       <Backdrop className={classes.backdrop} open={load} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div>
      <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
          message={snackbarMsg}
        />
      </div>
      <CustomerHeader count={productCount} />
      <div className="clr"></div>
      <div className="container-fluid toppadhead">
        <div className="row toppad5 mtoppad2">
          <div className="container">
            <div className="catsectionbx">
              <div className="row no-gutters">
                <div className="col-md-2 d-none d-md-block col-lg-1 order-2 order-md-1">
                  <div className="thumbnail">
                    <ul className="list-unstyled p-0 d-flex d-md-block flex-wrap mt-3 mt-md-0">
                      {productImages.length > 0 ? productImages.map((pr,index) => {
                        if(index < 4){
                          return <li key={index}>
                          <div className="thumb shadow-sm">
                            <img src={`${API_BASE_URL}Images/` + pr.image_path} onClick={()=> {
                              if(window.outerWidth > 600){
                                  setProductImage(pr.image_path)
                              }
                              else{                               
                                inputRef.current.goTo(3)
                              }
                            }} className="w-100" />
                          </div>
                        </li>
                        }
                      }) : null}
                      
                    </ul>
                  </div>
                </div>
                <div className="col-md-10 col-lg-6 d-none d-lg-block d-md-block order-1 text-md-center text-lg-left order-md-2">
                  <img
                    style={{ height: 400, width: 423 }}
                    src={`${API_BASE_URL}Images/${productImage}`}
                  />
                </div>
                <div className=" col-sm-5 d-none d-lg-none d-md-none d-block productSlide">
                {productImages.length && (
                  <div className="productSlides">        
                    <OwlCarousel ref={inputRef} options={optionsProduct}> 
                      {productImages.map((prop, i) => (          
                          <img src={API_BASE_URL + "Images/" + prop.image_path}
                            className="img-fluid"
                            alt=""
                          />
                      ))}
                    </OwlCarousel>       
                  </div>
          )}
                </div>
                <div className="col-md-12 col-lg-5 prv mtoppad3 order-3 order-md-3">
                  <div className="mtoppad2">
                    <i className="fa fa-star text-warning"></i>
                    <i className="fa fa-star text-warning"></i>
                    <i className="fa fa-star text-warning"></i>
                    <i className="fa fa-star text-warning"></i>
                    <i className="fa fa-star text-warning"></i>
                  </div>
                  <h2 className="toppad1">
                    {product.product_name}
                    <span className="ml-2">
                      <a href="#section2">Product Details</a>
                    </span>
                  </h2>
                  <p>{product.remarks}</p>

                  {(localStorage.getItem("jwl_hide_price")=='false' || localStorage.getItem("jwl_hide_price")==false)?
                                     <div className="price">
                                     {/* <span>₹ </span> {product.price}{" "}
                                     <span className="greytxt">
                                       <del>{product.discounted_price}</del>
                                     </span> */}
                                     <span>₹ </span> {product.discounted_price}{" "}
                                     <span className="greytxt">
                                       <del>{product.price}</del>
                                     </span>
                 
                                   </div>
                                  :
                                     <div className="price">
                                     </div>
                                   }
                  
                  <div className="row toppad5">
                  {product.material !="" ?   
                   <div className="col-6 col-sm-6 col-lg-4 btnpad">
                      <div className="form-group">
                        <label for="inputState">Material</label>
                        <select id="inputState" className="form-control" disabled={true}>
                          <option selected>{product.material}</option>
                          
                        </select>
                      </div>
                    </div>
                    : null}
                     {product.material !="" ?   
                      <div className="col-6 col-sm-6 col-lg-4 btnpad">
                      <div className="form-group">
                        <label for="inputState">Metal</label>
                        <select id="inputState" className="form-control" disabled={true}>
                          <option selected> {product.Metal}</option>
                        </select>
                      </div>
                    </div>                 
                  : null}
                   </div>
                  <div className="row toppad5 mtoppad2 wishBtn">
                  {product.trial == true ? (
                        <>
                    <div className="col-6 col-sm-6 col-lg-4 btnpad">
                      <button
                        className="allbtn"
                        type="button"
                        onClick={() => AddProductList("wish")}
                      >
                        Add to Wish List
                      </button>
                    </div>
                      <div className="col-6 col-sm-6 col-lg-4 btnpad">
                        <button
                          className="trybtn"
                          type="button"
                          onClick={() => AddProductList("trial")}
                        >
                          Try Before you Buy
                        </button>
                      </div>
                      </>
                    ) : (
                      <div className="col-12 col-sm-12 col-lg-8 btnpad">
                      <button
                        className="allbtn"
                        type="button"
                        onClick={() => AddProductList("wish")}
                      >
                        Add to Wish List
                      </button>
                    </div>
                    )}
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="clr"></div>
      <div className="container-fluid py-5">
        <div className="container prvdtl">
          <div className="row" id="section2">
            <div className="col-12">
              <h3>Product Details</h3>
            </div>
            <div className="col-12 col-sm-12 col-lg-4">
              <ul className="p-0">
                <li><b>Product code:</b> {product.product_code}</li>
                <li><b>Name</b> - {product.product_name}</li>
                <li><b>Metal</b> - {product.Metal}</li>
                <li><b>Material</b> - {product.material}</li>
               </ul>
            </div>
             
          </div>
        </div>
      </div>
      {products &&
                    products.length > 0 ?
      <section className="similarProducts bg-light py-5">
        <div className="container">
        <div className="uniq">
            <h2 className=" text-left">Similar Products</h2>
          </div>
              

                <OwlCarousel ref={inputRef} options={optionsSimilar}>
                {products &&
                    products.map((prop, i) => {
                      return <div className="probx" key={"product" + i}>
                      <div className="ac-footer">
                        <div className="ac-footer-container ac-footer-brand">
                          {/* <span className="ac-icon ac-icon-love-dark" onClick={() => AddProductList("wish",prop.product_id,prop.price)}></span> */}
                        </div>
                      </div>
                      <a href="#" onClick={() => RedirectToProduct(prop.product_id)}>
                        <div className="probximg">
                          <img src={API_BASE_URL + "Images/" + prop.image_path} />
                        </div>
                        <h4>{prop.product_name}</h4>

                        {(localStorage.getItem("jwl_hide_price")=='false' || localStorage.getItem("jwl_hide_price")==false)?
                                     <div className="price">
                                     ₹ {prop.price}{" "}
                                     <span className="greytxt lpadd1">
                                       <del>{prop.discounted_price}</del>
                                     </span>
                                   </div>
                                  :
                                     <div className="price">
                                     </div>
                                   }
                        
                      </a>
                    </div>
                    })}
                </OwlCarousel>
        </div>
      </section>
      : null
       }
      <div className="clr"></div>
  
      <CustomerVoucherCom/>
      {window.outerWidth > 600 ? <CustomerFooter /> : null}
     </React.Fragment>
    
 );
};

export default CustomerProductview;
