import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { postMethod } from "../../../_services/_postMethod";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import  '../../../_assets/css/feedback.css'
import { API_BASE_URL } from "../../../_services/constant";
import ReactStars from "react-rating-stars-component";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#000',
    backgroundColor: '#fff'
  },
}));

const Feedback = (props) => {
   
  const classes = useStyles(); 
  const [load, setLoad] = useState(false);
  const [branchLogo, setBranchLogo] = useState("");
  const [branchid, setBranchid] = useState("");
  const [redirectLink, setRedirectLink] = useState("");
  const [service1, setService1] = useState(false);
  const [service2, setService2] = useState(false);
  const [service3, setService3] = useState(false);
  const [service4, setService4] = useState(false);
  const [service5, setService5] = useState(false);
  const [service6, setService6] = useState(false);
  const [rateUsText, setrateUsText] = useState("Rate Us !");
  const [contNum, setContNum] = useState("");
  const [param,setParam]= useState({
    customer_id:"",
    stars:"",
    branch_id:"",
    remarks:"",
   service1:"",
   service2:"",
   service3:"",
   service4:"",
   service5:"",
   service6:""
  })
  const [servicelist, setServicelist] = useState([]);

  const thirdExample = {
    size: 40,
    count: 5,
    isHalf: false,
    value: 0,
    color: "#9e9e9e",
    emptyIcon: <i className="fa fa-star fa-fw" />,
    activeColor: "#FFD700",
    onChange: newValue => {
      setParam({...param,stars:newValue});
      if(newValue == "1")
      {
     setrateUsText("We will improve ourselves. You rated this 1 stars.")
      }
      else if(newValue == "2")
      {
     setrateUsText("Thanks! You rated this 2 stars.")
      }
      else if(newValue == "3")
      {
     setrateUsText("Thanks! You rated this 3 stars.")
      }
      else if(newValue == "4")
      {
     setrateUsText("Thanks! You rated this 4 stars.")
      }
      else if(newValue == "5")
      {
     setrateUsText("Thanks! You rated this 5 stars.")
      }
      else{
        setrateUsText("Rate Us !")
      }
    }
  };


  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    var token = query.get('t');    
   setLoad(true);
    postMethod("public/branch_feedback", { customer_encrypt_id: token }).then((data) => {
      setBranchLogo(data.logo);
      setContNum(data.contact_number);
      
      if(data.instagram !=""){
        setRedirectLink(data.instagram);
      } else
      if(data.facebook !=""){
        setRedirectLink(data.facebook);
      }else 
      if(data.estore_link !=""){
        setRedirectLink(data.estore_link);
      }
      setBranchid(token);
      setParam({...param,branch_id:data.branch_id,customer_id: data.customer_id})  
      setLoad(false);
    });  
  }, []);

  const handlePhoneClick = () => {
    window.location.href = `tel:${contNum}`;
  };

  const insert = () =>{   
      postMethod("public/feedbackInsert", param).then((data) => {  
          alert("thank you for feedback");
          if(redirectLink == "" || redirectLink == null){
            window.location.reload();
          }
          else{            
          window.location.href=redirectLink;
          }
      });
    
  }
  return (
    <React.Fragment>
        <Backdrop className={classes.backdrop} open={load} > 
    <CircularProgress color="inherit" />
      </Backdrop>
      <nav>
        <div className="nav-wrapper grey darken-4">
            {/* <!--<a className="waves-effect waves-light btn modal-trigger" href="#modal1">Modal</a>--> */}
           
            <div className="container-fluid">
              <div className="row">
                  <div className="col-10">
                      <a href="#" className="brand-logo left" style={{color:"white"}}>Feedback</a>
                  </div>
                
                    <div className="col-2">
                    <a href="tel:<%=mobile %>" className="phone"><i className="fa fa-phone" aria-hidden="true" onClick={handlePhoneClick}></i></a>
                  </div>
              </div>
            </div>

        </div>
    </nav>
     {/* <!-- Modal Trigger --> */}
  <a id="feedback_model_btn" className="waves-effect waves-light btn modal-trigger hide" href="#feedbackmodal">Modal</a>

  {/* <!-- Modal Structure --> */}
  <div id="feedbackmodal" className="modal">
    <div className="modal-content">
      <h4>Thank You For Your Feedback !</h4>
    
    </div>
    
  </div>         

    <div className="uper-section box-card">      
  
    <img   src={API_BASE_URL + "BranchLogo/" + branchLogo} className="img-center img-responsive img_w" />
       
            {/* <img src="http://jeweller.quicktagg.com/Quick_img/branch_img/9222017115816AM67201743930PMquicktagg-logo.jpg" className="img-center img-responsive img_w" /> */}
       
      
    
          <div class='text-message d-flex justify-content-center mt-2' style={{fontSize:"14px"}}>{rateUsText}</div>
         
          <div class='text-message d-flex justify-content-center'>   <ReactStars {...thirdExample} /></div>
     
  
    </div>
    <div className="container">
      <p style={{fontSize:14,fontWeight:600,textAlign:'center'}}>  Please suggest us the areas for further improvement:</p>
                <div className="services">
                            <button onClick={() =>
                             {
                                setParam({...param,service1:service1 == false ? "Service" :""});                            
                                setService1(!service1);
                            }
                            } className={service1 ? "service_switch_toggle" :"service_switch"}>Service</button>
                            <button onClick={() =>
                              {
                              setParam({...param,service2:service2  == false ? "Quality" : ""});
                              setService2(!service2)
                            }
                            } className={service2 ? "service_switch_toggle" :"service_switch"}>Quality</button>
                            <button onClick={() =>
                             {
                              setParam({...param,service3:service3  == false ? "Showroom" : ""});
                               setService3(!service3);
                            }
                            } className={service3 ? "service_switch_toggle" :"service_switch"}>Showroom</button>
                            <button onClick={() =>
                              {
                                setService4(!service4);
                                setParam({...param,service4:service4  == false  ? "Presentation" : ""});
                              }
                            } className={service4 ? "service_switch_toggle" :"service_switch"}>Presentation</button>
                            <button onClick={() =>
                             {
                                setService5(!service5);
                                setParam({...param,service5:service5  == false  ? "Collection" : ""});
                              }
                            } className={service5 ? "service_switch_toggle" :"service_switch"}>Collection</button>
                            <button onClick={() =>
                           {
                                setService6(!service6);
                                setParam({...param,service6:service6  == false ? "Staff" : ""});
                              }
                            } className={service6 ? "service_switch_toggle px-4" :"service_switch px-4"}>Staff</button>
                </div>
                <div className="form-group">
                    <textarea id="txt_remarks" className="form-control" data-length="120" placeholder="Remarks" onChange={(e) =>
                      {
                    setParam({...param,remarks: e.target.value});
                    }} ></textarea> 
                </div>
            </div>
        <button className=" footer_button waves-effect waves-light btn-large grey darken-4" onClick={() => {insert()}}>Submit</button>
    </React.Fragment>
  );
};

export default Feedback;
