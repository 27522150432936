import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from 'prop-types';
import "../../../_assets/css/custom.css";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/lib/styles.css";
import { postMethod } from "../../../_services/_postMethod";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import CustomerHeader from "./components/header";
import CustomerFooter from "./components/footer";
import CustomerBanner from "./components/banner";
import { API_BASE_URL } from "../../../_services/constant";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Tab, Accordion, AccordionDetails, AccordionSummary, Dialog, DialogContent, DialogTitle, Divider, IconButton, Slide, Tabs, TextField, Typography, Box, makeStyles, Card, CardActionArea, CardMedia, CardContent, Button, CardActions, DialogActions, Snackbar } from '@material-ui/core'; 
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Input, Label, FormGroup } from "reactstrap";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width:175
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#000',
    backgroundColor: '#fff'
  },
}));


const CustomerProducts = (props) => {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { register, handleSubmit, errors } = useForm();
  const [isvalid, setValid] = useState(false);
  const [load, setLoad] = useState(false);
  const inputRef = React.createRef(null);
  const [sortOpen , setsortOpen] = useState(false);

  const [products, setProducts] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [categorys, setCategorys] = useState([]);
  const [category, setCategory] = useState("");
  const [material, setMaterial] = useState("");
  const [orderPrice, setOrderPrice] = useState("desc");
  const [genders, setGenders] = useState("");
  const [gender, setGender] = useState("");
  const [metals, setMetals] = useState("");
  const [metal, setMetal] = useState("");
  const [priceRange, setPriceRange] = useState({ min: 0, max: 0 });
  const [priceRangeSet, setPriceRangeSet] = useState({ min: 0, max: 0 });
  const [voucherList, setVoucherList] = useState([]);
  const [Tabvalue, setValue] = React.useState(0);
  const [productCount, setProductCount] = React.useState(0);
  const [show, setShow] = useState(false);
  const [sortObj , setSortObj] = useState({
    new:false,
    hightolow:false
  });

  const handleModal = () => {
    setOpen(!open);
  }; 

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSortModal = () => {
    setsortOpen(!sortOpen);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const options = {
    responsive:{
      0:{
        items:2
      },
      600:{
        items:2
      },
      1000:{
        items:4
      }
    },
    dots: false,    
    autoplay: true,
    // center:true,
    stagePadding: 10,
    margin:10,
    loop: true,
    nav: true,
  };

  const RedirectToProduct = (product_id) => {
    localStorage.setItem("product_id", product_id);
    window.location.pathname = "business-view/product-view";
  };

  useEffect(() => {
    setLoad(true);
     postMethod("api/session/general_product_filter", {     
      category_id: localStorage.getItem("category_id"),
    }).then((dataD) => {
      var data = dataD.data;
      if(data)
      {
      setPriceRangeSet({...priceRangeSet,
        max: data[0][0].max,
        min: data[0][0].min,
      });
      setPriceRange({
        ...priceRange,
        max: data[0][0].max,
        min: data[0][0].min,
      });
      setMetals(data[1]);
      setMaterials(data[2]);
      setGenders(data[3]);
      setCategorys(data[4])
      }
    });
    postMethod("api/session/general_products", {   
      category_id: localStorage.getItem("category_id"),
      min_price: priceRange.min,
      max_price: priceRange.max,
      metal: metal,
      material: material,
      subcategory_id:"0",
      skip: "0",
    }).then((data) => {
      setProducts(data.data);
      setLoad(false);
    });

 
  }, []);

  useEffect(() => {
    postMethod("api/session/general_products", {    
      category_id: localStorage.getItem("category_id"),
      subcategory_id:"0",
      price_order:orderPrice,
      min_price: priceRange.min,
      max_price: priceRange.max,
      metal: metal,
      material: material,
      skip: "0"
    }).then((data) => {
      setProducts(data.data);
    });
  }, [material, metal, priceRange,orderPrice]);
  
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  const changeRange = (e) => {
    setPriceRange({ ...priceRange, min: e.min, max: e.max });
  };

  const categorychange = (category_id,subcategory_id) =>
  {
    setLoad(true);
    setMaterial("");
    setMetal("");
    
    postMethod("api/session/general_products", {   
      category_id: category_id,
      subcategory_id:subcategory_id,
      price_order:"desc",
      min_price: 0,
      max_price: 0,
      metal: "",
      material: "",
      skip: "0",
    }).then((data) => {
      setProducts(data);
      setLoad(false);
    });

    postMethod("api/cust/customer/product_filter", {   
      category_id: category_id,
    }).then((data) => {
      setPriceRangeSet({...priceRangeSet,
        max: data[0][0].max,
        min: data[0][0].min,
      });
      setPriceRange({
        ...priceRange,
        max: data[0][0].max,
        min: data[0][0].min,
      });
      setMetals(data[1]);
      setMaterials(data[2]);
      setGenders(data[3]);
      setCategorys(data[4])
    });
  }
  
  const [openWish, setOpenWish] = React.useState(false);

  const handleClickWish = () => {
    setOpenWish(true);
  };

  const handleCloseWish = () => {
    setOpenWish(false);
  };


  const AddProductList = (type,product_id,price) => {
    postMethod("api/cust/customer/Add_product_wishlist", {
      product_id: product_id,
      price: price,
      type: type,
    }).then((data) => {   
      handleClickWish();
      postMethod("api/cust/customer/wishlist", { customer_id: "" }).then((data) => {
        setProductCount(data.length);
      });     
    });
  };

  return (
    <>
       <Backdrop className={classes.backdrop} open={load} >
      <CircularProgress color="inherit" />
        </Backdrop>
      <CustomerHeader count={productCount} />
      <CustomerBanner />
      <Snackbar
          open={openWish}
          autoHideDuration={5000}
          onClose={handleCloseWish}
          message="Product added to wish list. Customer executive will contact you soon."
        />
      <div className="container-fluid">
        <div className="row prosection  pt-md-5">
          <div className="container">
            <div className="row">
              <div className="col-sm-3 botpad8 d-none d-lg-block d-md-block">
                <div id="accordion" className="panel panel-primary behclick-panel">
                  <div className="panel-heading">
                    <h3 className="panel-title">Filters By </h3>
                  </div>
                  <div className="panel-body">
                  <div className="panel-heading ">   
                      <Accordion className="p-0">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography >Categorys</Typography>
        </AccordionSummary>
              <AccordionDetails className="p-0">
              <Typography>
              {categorys &&
                      categorys.map((prop, i) => {
                        return (
                          <React.Fragment>
                            {prop.subcategoryList.length > 0 ? 
                            <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          
        >
          <Typography >{prop.category_name}</Typography>
        </AccordionSummary>
              <AccordionDetails>
              <Typography>
              {prop.subcategoryList &&
                      prop.subcategoryList.map((propin, i) => {
                        return (
                          <React.Fragment>
                            <input
                              type="radio"
                              name="categoryname"
                              onClick={(e) => {                               
                                categorychange(prop.category_id,propin.subcategory_id);                               
                              }}
                            />{" "}
                            {propin.subcategory_name}
                            <br />
                          </React.Fragment>
                        );
                      })}
              </Typography>
              </AccordionDetails>
      </Accordion>
      : null}

        </React.Fragment>
      );
    })}
</Typography>
</AccordionDetails>
      </Accordion>

                    </div>

                    <div className="panel-heading ">
                      <div className="panel-title">
                        <a data-toggle="collapse" className="text-dark font-weight-bold" href="#collapse0">
                          Price
                          <br />
                          <br /> 
                          <InputRange
                            maxValue={priceRangeSet.max}
                            minValue={0}
                            value={priceRange}
                            onChange={changeRange}
                          />
                          <br />
                        </a>
                      </div>
                    </div>

                    <div className="panel-heading ">   
                      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography >Metal</Typography>
        </AccordionSummary>
              <AccordionDetails>
              <Typography>
              {metals &&
                      metals.map((prop, i) => {
                        return (
                          <React.Fragment>
                            <input
                              type="checkbox"
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setMetal(prop.Metal);
                                } else {
                                  setMetal("");
                                }
                              }}
                            />{" "}
                            {prop.Metal}
                            <br />
                          </React.Fragment>
                        );
                      })}
              </Typography>
              </AccordionDetails>
      </Accordion>

                    </div>
                    <div className="panel-heading">                                 
                          
                       
   <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography >Material</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <Typography>
          {materials && materials.map((prop, i) => {
                              return (
                                <React.Fragment>
                                  <input
                                    type="checkbox"
                                    onClick={(e) => {
                                      if (e.target.checked) {
                                        setMaterial(prop.material);
                                      } else {
                                        setMaterial("");
                                      }
                                    }}
                                  />{" "}
                                  {prop.material}
                                  <br />
                                </React.Fragment>
                              );
                            })}
          </Typography>
          </AccordionDetails>
          </Accordion>
      

                        
                     
                     
                    </div>
                    <div className="panel-heading">
            <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography >   Shop for</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
            {genders &&
                genders.map((prop, i) => {
                  return (
                    <React.Fragment>
                      <input
                        type="checkbox"
                        onClick={(e) => {
                          if (e.target.checked) {
                            setGender(prop.gender);
                          } else {
                            setGender("");
                          }
                        }}
                      />{" "}
                      {prop.gender}
                      <br />
                    </React.Fragment>
                  );
                })}
            </Typography>
            </AccordionDetails>
            </Accordion>
                    </div>
                  </div>
                </div>
              </div>

              <div id="sidebar" className="sidebar col-12 d-none d-lg-none d-md-none d-block">
                {open ? null : <div className="container-fluid p-0">
                <div className="row no-gutters">
                  <div className="col-6 lrpadd">
                    <button
                      type="button"
                      className="fltrbtn"
                      onClick={handleSortModal}
                    >
                      <i className="fa fa-sort text-white" aria-hidden="true"></i> Sort By
                    </button>
                  </div>
                  <div className="col-6 lrpadd leftbdr">
                    <button
                      type="button"
                      className="fltrbtn"
                      onClick={handleModal}

                    >
                      <i className="fa fa-filter text-white" aria-hidden="true"></i> Filter By
                    </button>
                  </div>
                </div>
                  </div>}
                <div
                  className="modal fade"
                  id="filterModal"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog fltrmodal-dialog" role="document">
                    <div className="modal-content fltrmodal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="fltrclose"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">
                            <i className="fa fa-chevron-left" aria-hidden="true"></i>
                          </span>
                        </button>
                        <span>
                          <button type="button" className="fltrclr">
                            Clear All
                          </button>
                        </span>
                      </div>
                      <div className="modal-body fltrmodal-body">
                        <div className="tab">
                          <button
                            className="tablinks"
                            onclick="openFilter(event, 'price')"
                            id="defaultOpen"
                          >
                            Price
                          </button>
                          <button className="tablinks" onclick="openFilter(event, 'weight')">
                            Weight
                          </button>
                          <button className="tablinks" onclick="openFilter(event, 'material')">
                            Material
                          </button>
                          <button className="tablinks" onclick="openFilter(event, 'metal')">
                            Metal
                          </button>
                          <button className="tablinks" onclick="openFilter(event, 'size')">
                            Size/Lenth
                          </button>
                          <button className="tablinks" onclick="openFilter(event, 'shop')">
                            Shop For
                          </button>
                        </div>
                        <div id="price" className="tabcontent"></div>
                        <div id="weight" className="tabcontent">
                          <h3>Weight</h3>
                          <p>Paris is the capital of France.</p>
                        </div>
                        <div id="material" className="tabcontent">
                          <h3>Material</h3>
                          <p>Tokyo is the capital of Japan.</p>
                        </div>
                        <div id="metal" className="tabcontent">
                          <h3>Metal</h3>
                          <p>Tokyo is the capital of Japan.</p>
                        </div>
                        <div id="size" className="tabcontent">
                          <h3>Size/Lenth</h3>
                          <p>Tokyo is the capital of Japan.</p>
                        </div>
                        <div id="shop" className="tabcontent">
                          <h3>Shop For</h3>
                          <p>Tokyo is the capital of Japan.</p>
                        </div>
                        <div className="aplybutnbx">
                          <button className="btn allbtn" type="button">
                            APPLY FILTERS
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="modal fade"
                  id="sortModal"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog fltrmodal-dialog" role="document">
                    <div className="modal-content fltrmodal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="fltrclose"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">
                            <i className="fa fa-chevron-left" aria-hidden="true"></i>
                          </span>
                        </button>

                        <span>
                          <button type="button" className="fltrclr">
                            Clear All
                          </button>
                        </span>
                      </div>
                      <div className="modal-body fltrmodal-body">
                        vmxcplvxz
                        <button className="aplybutn btn" type="button">
                          APPLY FILTERS
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-9 col-lg-9">
                <div className="row d-none d-lg-block d-md-block">
                  <div className="sortprobx">
                    <div className="form-group sortpro d-flex align-items-center">
                      <label className="w-50">Sort By</label>
      <select id="inputState" className="form-control" onChange={(e)=>{        
        setOrderPrice(e.target.value)}}>
                        <option selected value="desc">Choose option</option>
                        <option value="desc">Price: Low to High</option>
                        <option value="asc"> Price: High to Low</option>
                        <option value="new">New</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="clr"></div>
                <div className="row">
                  <div className="clr"></div>

                  {products && products.length > 0 && 
                    products.map((prop, i) => {
                      return (
                        <div className="col-6 col-sm-6 col-lg-4 topmargn3 probxpad">
                          <div className="probx">
                            <div className="ac-footer">
                              <div className="ac-footer-container ac-footer-brand">
                                <span className={"ac-icon ac-icon-love-dark " + (prop.wishlist == "true" ? "active" : "")} onClick={() => AddProductList("wish",prop.product_id,prop.price)}></span>
                              </div>
                            </div>
                            <a href="#" onClick={() => RedirectToProduct(prop.product_id)}>
                              <div className="probximg">
                                <img src={API_BASE_URL + "Images/" + prop.image_path} />
                              </div>
                              <h4>{prop.product_name}</h4>
                              {(localStorage.getItem("jwl_hide_price") == 'false' || localStorage.getItem("jwl_hide_price") == false) ?
                                <div className="price">
                                  ₹ {prop.price}{" "}
                                  <span className="greytxt lpadd1">
                                    <del>{prop.discounted_price}</del>
                                  </span>
                                </div>
                                :
                                <div className="price">
                                </div>
                              }
                             
                            </a>
                          </div>
                        </div>
                      );
                    }) }
                </div>
              </div>
              <div className="clr"></div>
               
            </div>
          </div>
         
        
          </div>
      </div>
  
      <div className="clr"></div>
      <CustomerBanner />

       {/* Sort Modal */}
       <Dialog fullScreen open={sortOpen} TransitionComponent={Transition}>
      <DialogTitle id="form-dialog-title" className="p-0">
          <IconButton onClick={handleSortModal} aria-label="upload picture" component="span">
            <i className="fas fa-arrow-left"></i>
            <span className="pl-3">Sort</span>
          </IconButton>
          </DialogTitle>
          <DialogContent className="p-3">
                <div className={"filterOption  mb-4 " + (sortObj.hightolow ? "" : "active")} onClick={() => setSortObj({...sortObj,hightolow:false})}>
                  <i className={"fas fa-long-arrow-alt-up"}></i>
                  <span>Lowest to Highest</span>
                </div>
                <div className={"filterOption  mb-4 " + (sortObj.hightolow ? "active" : "")} onClick={() => setSortObj({...sortObj,hightolow:true})}>
                  <i className={"fas fa-long-arrow-alt-down"}></i>
                  <span>Highest to Lowest</span>
                </div>
                <FormGroup check>
                <Label check>
                  <Input type="checkbox" />{' '}
                  New
                </Label>
              </FormGroup>
          </DialogContent>
      <DialogActions>
      <button className="fltrbtn btm w-100 py-3" onClick={() => setsortOpen(false)}>Apply Sort</button>
      </DialogActions>
      </Dialog>

      {/* Filter Modal */}
      <Dialog fullScreen open={open} TransitionComponent={Transition}>
      <DialogTitle id="form-dialog-title" className="p-0">
          <IconButton onClick={handleModal} aria-label="upload picture" component="span">
            <i className="fas fa-arrow-left"></i>
            <span className="pl-3">Filters</span>
          </IconButton>
          </DialogTitle>
          <DialogContent className="d-flex p-0">
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={Tabvalue}
                className={classes.tabs}
                onChange={handleChange}
                aria-label="Vertical tabs example">
                  <Tab label="Price" {...a11yProps(0)} />
                  <Tab label="Metal" {...a11yProps(1)} />
                  <Tab label="Material" {...a11yProps(2)} />
                  <Tab label="Shop For" {...a11yProps(3)} />
              </Tabs>
              <div className={( Tabvalue != "0" ? "d-none" : "") + " bg-light container pt-3"}>
                <div className="mt-5">
                <InputRange
                    maxValue={priceRangeSet.max}
                    minValue={0}
                    value={priceRange}
                    onChange={changeRange}
                  />
                </div>
              </div>
              <div className={(Tabvalue != "1" ? "d-none" : "") + " bg-light container pt-3"}>
              {metals &&
                      metals.map((prop, i) => {
                        return (
                          <div className={"filterOption mb-4 " + (prop.Metal === metal ? "active" : "")} onClick={() => setMetal(prop.Metal)}>
                              <i className="fas fa-check"></i>
                              <span>{prop.Metal}</span>
                            </div>
                        );
                      })}
              </div>
              <div className={ (Tabvalue != "2" ? "d-none" : "")+ " bg-light container pt-3"}>
              {materials && materials.map((prop, i) => {
                              return (
                                <div className={"filterOption  mb-4 " + (prop.material === material ? "active" : "")} onClick={() => setMaterial(prop.material)}>
                                    <i className="fas fa-check"></i>
                                    <span>{prop.material}</span>
                                  </div>
                              );
                            })}
              </div>
              <div className={ (Tabvalue != "3" ? "d-none" : "") + " bg-light container pt-3"}>
              {genders &&
                genders.map((prop, i) => {
                  return (
                    <div className={"filterOption mb-4 " + (prop.gender === gender ? "active" : "")} onClick={() => setGender(prop.gender)}>
                      <i className="fas fa-check"></i>
                      <span>{prop.gender}</span>
                    </div>
                  );
                })}
              </div>
          
         
          </DialogContent>
      <DialogActions>
      <button className="fltrbtn btm w-100 py-3" onClick={() => setOpen(false)}>Apply Filter</button>
      </DialogActions>
      </Dialog>


      {window.outerWidth > 600 ? <CustomerFooter /> : null}
    </>
  );
};

export default CustomerProducts;
