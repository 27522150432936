import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Row,
  Input,
} from "reactstrap";
import Backdrop from "@material-ui/core/Backdrop";
import { API_BASE_URL } from "../../../_services/constant";

import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import CustomerFooter from "../customerCRM/components/footer";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#000",
    backgroundColor: "#fff",
  },
}));

export default function RateUS(props) {
  const [currentRating, setCurrentRating] = useState(2);

  const branch_id = localStorage.getItem("jwl_customer_branch_id");
  const handleRatingChange = (newValue) => {
    setCurrentRating(newValue);
  };

  return (
    <>
     

      <div style={{ margin: "10px" }}>
        <Card
          className="my-2"
          style={{
            width: "100%",
          }}
        >
          <CardHeader>
    
        RATE US 
        <a
          href=""
          className="btn button"
          style={{ float: "right", fontSize: "15px", margin: "20px" }}
        >
          <PhoneIcon style={{ color: "blue", fontSize: "25px" }} />
        </a>
     
          </CardHeader>
          <CardBody>
            <CardTitle className="center" tag="h5">
              
            </CardTitle>
            <CardText>
                <Row>
                    <Col className="text-center"> 
                    <img
          src={API_BASE_URL + "BranchLogo/" + localStorage.getItem("jwl_logo")}
          className="logo"
          height="40%"
          width="40%"
          style={{ margin: "5px" }}
        />
                    </Col>
                </Row>
              <Row>
                <Col className="text-center">
                <Rating
                  initialValue={currentRating}
                  onChange={handleRatingChange}
                  
                />
                <h5 style={{color:"blue"}}>Current Rating: {currentRating}</h5>
               </Col>

              
              </Row>
              <Row style={{margin:"20px"}}>
                <Col>
                    <Button>Service</Button>
                </Col>
                <Col>
                    <Button>Store</Button>
                </Col>
                <Col>
                    <Button>Collection</Button>
                </Col>
                <Col>
                    <Button>Presentation</Button>
                </Col>
                <Col>
                    <Button>Staff</Button>
                </Col>
                
              </Row>

              <Row style={{margin:"20px"}}>
                <Col className="text-left">
                <textarea
                        type="text"
                        placeholder="Remark"
                        rows="5"
                        cols="50"
                        className="mt-1"
                       
                      ></textarea>
                </Col>
              </Row>
            </CardText>
          </CardBody>
        
        </Card>
      </div>
      <div style={{ marginTop: "10.5%"}}>
        <CustomerFooter />
      </div>
    </>
  );
}

const Rating = ({ initialValue, onChange }) => {
  const [rating, setRating] = useState(initialValue);

  const handleRatingChange = (newValue) => {
    setRating(newValue);
    onChange(newValue);
  };

  return (
    <div>
      {[...Array(5)].map((_, index) => {
        const ratingValue = index + 1;
        return (
          <span
            key={index}
            style={{ cursor: "pointer" }}
            onClick={() => handleRatingChange(ratingValue)}
          >
            {ratingValue <= rating ? (
              <h1 style={{ display: "inline-block", color: "orange" }}>
                {"★"}{" "}
              </h1>
            ) : (
              <h1 style={{ display: "inline-block", color: "orange" }}>
                {" "}
                {"☆"}
              </h1>
            )}
          </span>
        );
      })}
    </div>
  );
};
