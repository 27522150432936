import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import "../../../_assets/css/custom.css";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/lib/styles.css";
import { postMethod } from "../../../_services/_postMethod";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import CustomerHeader from "./components/header";
import CustomerFooter from "./components/footer";
import CustomerBanner from "./components/banner";
import CustomerCategoryBanner from "./components/category_banner";
import { API_BASE_URL } from "../../../_services/constant";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Tab,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Slide,
  Tabs,
  TextField,
  Typography,
  Box,
  makeStyles,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Button,
  CardActions,
  DialogActions,
  Snackbar,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Input, Label, FormGroup } from "reactstrap";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width: 175,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#000",
    backgroundColor: "#fff",
  },
}));

const CustomerProducts = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { register, handleSubmit, errors } = useForm();
  const [isvalid, setValid] = useState(false);
  const [load, setLoad] = useState(false);
  const inputRef = React.createRef(null);
  const [sortOpen, setsortOpen] = useState(false);

  const [products, setProducts] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [categorys, setCategorys] = useState([]);
  const [category, setCategory] = useState("");
  const [material, setMaterial] = useState("");
  const [orderPrice, setOrderPrice] = useState("desc");
  const [short_filters, setShortFilters] = useState({
    new: false,
    order: "desc",
    trial: false,
  });
  const [genders, setGenders] = useState("");
  const [gender, setGender] = useState("");
  const [metals, setMetals] = useState("");
  const [metal, setMetal] = useState("");
  const [priceRange, setPriceRange] = useState({ min: 0, max: 0 });
  const [priceRangeSet, setPriceRangeSet] = useState({ min: 0, max: 0 });
  const [voucherList, setVoucherList] = useState([]);
  const [Tabvalue, setValue] = React.useState(0);
  const [productCount, setProductCount] = React.useState(0);
  const [show, setShow] = useState(false);
  const [sortObj, setSortObj] = useState({
    new: false,
    hightolow: false,
    lowtohigh: false,
    new:false,
    tryathome:false,
    shortingApplied:false
  });

const applyShorting = () =>{
  let shortValue= "";
  if(sortObj.new){
    shortValue ="new";
  }
   if(sortObj.hightolow){
    shortValue ="desc";
  }
   if(sortObj.lowtohigh){
    shortValue ="asc";
  }
  if(sortObj.tryathome){
    shortValue ="trial";
  }
  setFilter(shortValue);
  setSortObj({ ...sortObj,  shortingApplied: true})
  setsortOpen(false);
}


  const handleModal = () => {
    setOpen(!open);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSortModal = () => {
    setsortOpen(!sortOpen);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const options = {
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
    dots: false,
    autoplay: true,
    // center:true,
    stagePadding: 10,
    margin: 10,
    loop: true,
    nav: true,
  };

  const RedirectToProduct = (product_id) => {
    localStorage.setItem("product_id", product_id);
    window.location.pathname = "trial-view/product-view";
  };

  useEffect(() => {
    setLoad(true);
    localStorage.setItem("subcategory_id", 0);
    postMethod("api/session/trial_product_filter", {
      category_id: localStorage.getItem("category_id"),
    }).then((dataD) => {
      var data = dataD.data;
      setPriceRangeSet({
        ...priceRangeSet,
        max: data[0][0].max,
        min: data[0][0].min,
      });
      setPriceRange({
        ...priceRange,
        max: data[0][0].max,
        min: data[0][0].min,
      });
      setMetals(data[1]);
      setMaterials(data[2]);
      setGenders(data[3]);
      setCategorys(data[4]);
    });
    postMethod("api/session/trial_products", {
      category_id: localStorage.getItem("category_id"),
      min_price: priceRange.min,
      max_price: priceRange.max,
      metal: metal,
      material: material,
      subcategory_id: localStorage.getItem("subcategory_id"),
      price_order: short_filters.order,
      trial: short_filters.trial,
      new: short_filters.new,
      shop_for: "",
      skip: "0",
    }).then((data) => {
    if(data.data!=""){
      setProducts(data.data);
    }
      setLoad(false);
    });
  }, []);

  const getCustomisedProduct = () => {
    postMethod("api/session/trial_products", {
      category_id: localStorage.getItem("category_id"),
      subcategory_id: localStorage.getItem("subcategory_id"),
      price_order: short_filters.order,
      trial: short_filters.trial,
      new: short_filters.new,
      min_price: priceRange.min,
      max_price: priceRange.max,
      metal: metal,
      material: material,
      shop_for: gender,
      skip: "0",
    }).then((data) => {
      if(data.data!=""){
        setProducts(data.data);
      }
    });
  };

  useEffect(() => {
    getCustomisedProduct();
  }, [material, metal, priceRange, short_filters, gender]);

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  const changeRange = (e) => {
    setPriceRange({ ...priceRange, min: e.min, max: e.max });
  };

  const categorychange = (category_id, subcategory_id) => {
    localStorage.setItem("category_id", category_id);
    localStorage.setItem("subcategory_id", subcategory_id);
    setLoad(true);
    setMaterial("");
    setMetal("");

    postMethod("api/session/trial_products", {
      category_id: category_id,
      subcategory_id: subcategory_id,
      price_order: short_filters.order,
      trial: short_filters.trial,
      new: short_filters.new,
      min_price: 0,
      max_price: 0,
      metal: "",
      material: "",
      skip: "0",
    }).then((data) => {
      if(data.data!=""){
        setProducts(data.data);
      }
    });

    postMethod("api/session/trial_product_filter", {
      category_id: category_id,
      subcategory_id: subcategory_id,
    }).then((dataD) => {
      var data = dataD.data;
      setPriceRangeSet({
        ...priceRangeSet,
        max: data[0][0].max,
        min: data[0][0].min,
      });
      setPriceRange({
        ...priceRange,
        max: data[0][0].max,
        min: data[0][0].min,
      });
      setMetals(data[1]);
      setMaterials(data[2]);
      setGenders(data[3]);
      setCategorys(data[4]);

      setTimeout(() => {
        setLoad(false);
      }, 2000);
    });
  };

  const [openWish, setOpenWish] = React.useState(false);

  const handleClickWish = () => {
    setOpenWish(true);
  };

  const handleCloseWish = () => {
    setOpenWish(false);
  };

  const AddProductList = (type, product_id, price,wishFlag) => {
    postMethod("api/session/add_to_cart", {
      product_id: product_id,
      price: price,
      added_from: "trial",
      type: type,
    }).then((data) => {
      if(wishFlag != "true"){
        handleClickWish();
      }
      postMethod("api/session/wishlist", {}).then((dataD) => {
        setProductCount(dataD.data.length);
        getCustomisedProduct();
      });
    });
  };

  const setFilter = (value) => {
    if (value == "desc") {
      setShortFilters({
        ...short_filters,
        new: false,
        order: "desc",
        trial: false,
      });
    }
    if (value == "asc") {
      setShortFilters({
        ...short_filters,
        new: false,
        order: "asc",
        trial: false,
      });
    }
    if (value == "new") {
      setShortFilters({
        ...short_filters,
        new: true,
        order: "desc",
        trial: false,
      });
    }
    if (value == "trial") {
      setShortFilters({
        ...short_filters,
        new: false,
        order: "desc",
        trial: true,
      });
    }
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={load}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CustomerHeader count={productCount} />
      <CustomerCategoryBanner />
      <Snackbar
        open={openWish}
        autoHideDuration={5000}
        onClose={handleCloseWish}
        message="Product added to wish list. Customer executive will contact you soon."
      />
      <div className="container-fluid my-0.5">
        <div className="row prosection  py-md-5">
        <div className="container">
            <div className="row">
              <div className="col-sm-3 botpad8 d-none d-lg-block d-md-block">
                <div
                  id="accordion"
                  className="panel panel-primary behclick-panel accordion_panel"
                >
                  <div className="panel-heading">
                    <h3 className="panel-title">Filters By </h3>
                  </div>
                  <div className="panel-body">
                    <div className="panel-heading ">
                      <Accordion
                        style={{
                          boxShadow: "none",
                          backgroundColor: "transparent",
                        }}
                        className="p-0"
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>Categories </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="p-0">
                          <Typography>
                            {categorys &&
                              categorys.map((prop, i) => {
                                return (
                                  <React.Fragment>
                                    {prop.subcategoryList.length > 0 ? (
                                      <Accordion elevation={0}>
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                        >
                                          <Typography>
                                            {prop.category_name}
                                          </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Typography>
                                            {prop.subcategoryList &&
                                            prop.subcategoryList.length > 0 ? (
                                              <>
                                                <input
                                                  type="radio"
                                                  name="categoryname"
                                                  checked={
                                                    localStorage.getItem(
                                                      "category_id"
                                                    ) == prop.category_id &&
                                                    localStorage.getItem(
                                                      "subcategory_id"
                                                    ) == 0
                                                      ? true
                                                      : false
                                                  }
                                                  onClick={(e) => {
                                                    localStorage.setItem(
                                                      "jwl_category_banner_path",
                                                      prop.banner_path
                                                    );
                                                    categorychange(
                                                      prop.category_id,
                                                      ""
                                                    );
                                                  }}
                                                />
                                                ALL <br />
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            {prop.subcategoryList &&
                                              prop.subcategoryList.map(
                                                (propin, i) => {
                                                  return (
                                                    <React.Fragment>
                                                      <input
                                                        type="radio"
                                                        name="categoryname"
                                                        checked={
                                                          localStorage.getItem(
                                                            "subcategory_id"
                                                          ) ==
                                                          propin.subcategory_id
                                                            ? true
                                                            : false
                                                        }
                                                        onClick={(e) => {
                                                          localStorage.setItem(
                                                            "jwl_category_banner_path",
                                                            prop.banner_path
                                                          );
                                                          categorychange(
                                                            prop.category_id,
                                                            propin.subcategory_id
                                                          );
                                                        }}
                                                      />{" "}
                                                      {propin.subcategory_name}
                                                      <br />
                                                    </React.Fragment>
                                                  );
                                                }
                                              )}
                                          </Typography>
                                        </AccordionDetails>
                                      </Accordion>
                                    ) : null}
                                  </React.Fragment>
                                );
                              })}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>

                    <div
                      className="panel-heading "
                      style={{ padding: "5px 16px 0 16px" }}
                    >
                      <div className="panel-title">
                        <a
                          data-toggle="collapse"
                          className="text-dark font-weight-bold"
                          href="#collapse0"
                        >
                          Price
                          <br />
                          <br />
                          <InputRange
                            maxValue={priceRangeSet.max}
                            minValue={0}
                            value={priceRange}
                            onChange={changeRange}
                          />
                          <br />
                        </a>
                      </div>
                    </div>

                    <div className="panel-heading ">
                      <Accordion
                        style={{
                          boxShadow: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>Metal</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            {metals && metals.length > 0 ? (
                              <>
                                <input
                                  type="radio"
                                  name="matal"
                                  checked={metal == "" ? true : false}
                                  onClick={(e) => {
                                    setMetal("");
                                  }}
                                />
                                ALL <br />
                              </>
                            ) : (
                              ""
                            )}
                            {metals &&
                              metals.map((prop, i) => {
                                return (
                                  <React.Fragment>
                                    <input
                                      type="radio"
                                      name="matal"
                                      onClick={(e) => {
                                        if (e.target.checked) {
                                          setMetal(prop.Metal);
                                        } else {
                                          setMetal("");
                                        }
                                      }}
                                    />{" "}
                                    {prop.Metal}
                                    <br />
                                  </React.Fragment>
                                );
                              })}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div className="panel-heading">
                      <Accordion
                        style={{
                          boxShadow: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>Material</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            {materials && materials.length > 0 ? (
                              <>
                                <input
                                  type="radio"
                                  name="material"
                                  checked={material == "" ? true : false}
                                  onClick={(e) => {
                                    setMaterial("");
                                  }}
                                />
                                ALL <br />
                              </>
                            ) : (
                              ""
                            )}
                            {materials &&
                              materials.map((prop, i) => {
                                return (
                                  <React.Fragment>
                                    <input
                                      type="radio"
                                      name="material"
                                      onClick={(e) => {
                                        if (e.target.checked) {
                                          setMaterial(prop.Material);
                                        } else {
                                          setMaterial("");
                                        }
                                      }}
                                    />{" "}
                                    {prop.Material}
                                    <br />
                                  </React.Fragment>
                                );
                              })}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div className="panel-heading">
                      <Accordion
                        style={{
                          boxShadow: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography> Shop for</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            {genders && genders.length > 0 ? (
                              <>
                                <input
                                  type="radio"
                                  name="gender"
                                  checked={gender == "" ? true : false}
                                  onClick={(e) => {
                                    setGender("");
                                  }}
                                />
                                ALL <br />
                              </>
                            ) : (
                              ""
                            )}
                            {genders &&
                              genders.map((prop, i) => {
                                return (
                                  <React.Fragment>
                                    <input
                                      type="radio"
                                      name="gender"
                                      onClick={(e) => {
                                        if (e.target.checked) {
                                          setGender(prop.gender);
                                        } else {
                                          setGender("");
                                        }
                                      }}
                                    />{" "}
                                    {prop.gender}
                                    <br />
                                  </React.Fragment>
                                );
                              })}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="sidebar"
                className="sidebar col-12 d-none d-lg-none d-md-none d-block"
              >
                {open ? null : (
                  <div className="container-fluid p-0">
                    <div className="row no-gutters">
                      <div className="col-6 lrpadd">
                        <button
                          type="button"
                          className="fltrbtn"
                          onClick={handleSortModal}
                        >
                          <i
                            className="fa fa-sort text-white"
                            aria-hidden="true"
                          ></i>{" "}
                          Sort By
                        </button>
                      </div>
                      <div className="col-6 lrpadd leftbdr">
                        <button
                          type="button"
                          className="fltrbtn"
                          onClick={handleModal}
                        >
                          <i
                            className="fa fa-filter text-white"
                            aria-hidden="true"
                          ></i>{" "}
                          Filter By
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className="modal fade"
                  id="filterModal"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog fltrmodal-dialog"
                    role="document"
                  >
                    <div className="modal-content fltrmodal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="fltrclose"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">
                            <i
                              className="fa fa-chevron-left"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </button>
                        <span>
                          <button type="button" className="fltrclr">
                            Clear All
                          </button>
                        </span>
                      </div>
                      <div className="modal-body fltrmodal-body">
                        <div className="tab">
                          <button
                            className="tablinks"
                            onclick="openFilter(event, 'price')"
                            id="defaultOpen"
                          >
                            Price
                          </button>
                          <button
                            className="tablinks"
                            onclick="openFilter(event, 'weight')"
                          >
                            Weight
                          </button>
                          <button
                            className="tablinks"
                            onclick="openFilter(event, 'material')"
                          >
                            Material
                          </button>
                          <button
                            className="tablinks"
                            onclick="openFilter(event, 'metal')"
                          >
                            Metal
                          </button>
                          <button
                            className="tablinks"
                            onclick="openFilter(event, 'size')"
                          >
                            Size/Lenth
                          </button>
                          <button
                            className="tablinks"
                            onclick="openFilter(event, 'shop')"
                          >
                            Shop For
                          </button>
                        </div>
                        <div id="price" className="tabcontent"></div>
                        <div id="weight" className="tabcontent">
                          <h3>Weight</h3>
                          <p>Paris is the capital of France.</p>
                        </div>
                        <div id="material" className="tabcontent">
                          <h3>Material</h3>
                          <p>Tokyo is the capital of Japan.</p>
                        </div>
                        <div id="metal" className="tabcontent">
                          <h3>Metal</h3>
                          <p>Tokyo is the capital of Japan.</p>
                        </div>
                        <div id="size" className="tabcontent">
                          <h3>Size/Lenth</h3>
                          <p>Tokyo is the capital of Japan.</p>
                        </div>
                        <div id="shop" className="tabcontent">
                          <h3>Shop For</h3>
                          <p>Tokyo is the capital of Japan.</p>
                        </div>
                        <div className="aplybutnbx">
                          <button className="btn allbtn" type="button">
                            APPLY FILTERS
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="modal fade"
                  id="sortModal"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog fltrmodal-dialog"
                    role="document"
                  >
                    <div className="modal-content fltrmodal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="fltrclose"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">
                            <i
                              className="fa fa-chevron-left"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </button>

                        <span>
                          <button type="button" className="fltrclr">
                            Clear All
                          </button>
                        </span>
                      </div>
                      <div className="modal-body fltrmodal-body">
                        vmxcplvxz
                        <button className="aplybutn btn" type="button">
                          APPLY FILTERS
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-9 col-lg-9">
                <div className="row d-none d-lg-block d-md-block">
                  <div className="sortprobx">
                    <div className="form-group sortpro d-flex align-items-center">
                      <label className="w-50">Sort By</label>
                      <select
                        id="inputState"
                        className="form-control"
                        onChange={(e) => {
                          setFilter(e.target.value);
                        }}
                      >
                        <option selected value="desc">
                          Choose option
                        </option>
                        <option value="new">New</option>
                        <option value="desc">Price: Low to High</option>
                        <option value="asc"> Price: High to Low</option>
                        <option value="trial">Try at Home</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="clr"></div>
                <div className="row">
                  <div className="clr"></div>

                  {products && products.length > 0 && products.map((prop, i) => {
                        return (
                          <div className="col-6 col-sm-6 col-lg-4 topmargn3 probxpad">
                            <div className="probx">
                              <div className="ac-footer">
                                <div className="ac-footer-container ac-footer-brand">
                                  <span
                                    className={
                                      "ac-icon ac-icon-love-dark " +
                                      (prop.wish == "true" ? "active" : "")
                                    }
                                    onClick={() =>
                                      AddProductList(
                                        "wish",
                                        prop.product_id,
                                        prop.price,
                                        prop.wish
                                      )
                                    }
                                  ></span>
                                </div>
                              </div>
                              <a
                                href="#"
                                onClick={() =>
                                  RedirectToProduct(prop.product_id)
                                }
                              >
                                <div className="probximg">
                                  {prop.new == true ? (
                                    <div className="product_badge_1">New</div>
                                  ) : null}
                                  {prop.new != true && prop.trial == true ? (
                                    <div className="product_badge">
                                      Try at Home
                                    </div>
                                  ) : null}
                                  <img
                                    src={
                                      API_BASE_URL + "Images/" + prop.image_path
                                    }
                                  />
                                </div>
                                <div className="px-2 py-3">
                                  <h4>{prop.product_name}</h4>
                                  {(localStorage.getItem("jwl_hide_price")=='false' || localStorage.getItem("jwl_hide_price")==false)? 
                                    <div className="price">
                                      ₹ {prop.discounted_price}{" "}
                                      <span className="greytxt lpadd1">
                                        <del>{prop.price}</del>
                                      </span>
                                    </div>
                                  : 
                                    <div className="price">
                                    </div>
                                  } 
                                  
                                </div>
                              </a>
                            </div>
                          </div>
                        );
                      })}
                </div>
              </div>
              <div className="clr"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="clr"></div>
      <CustomerBanner />
      <br />{/* Sort Modal */}
      <Dialog fullScreen open={sortOpen} TransitionComponent={Transition}>
        <DialogTitle id="form-dialog-title" className="p-0">
          <IconButton
            onClick={handleSortModal}
            aria-label="upload picture"
            component="span"
          >
            <i className="fas fa-arrow-left"></i>
            <span className="pl-3">Sort</span>
          </IconButton>
        </DialogTitle>
        <DialogContent className="p-3">
          <div
            className={
              "filterOption  mb-4 " + (sortObj.hightolow ? "" : "active")
            }
            onClick={() => setSortObj({ ...sortObj, hightolow: false })}
          >
            <i className={"fas fa-long-arrow-alt-up"}></i>
            <span>Lowest to Highest</span>
          </div>
          <div
            className={
              "filterOption  mb-4 " + (sortObj.hightolow ? "active" : "")
            }
            onClick={() => setSortObj({ ...sortObj, hightolow: true })}
          >
            <i className={"fas fa-long-arrow-alt-down"}></i>
            <span>Highest to Lowest</span>
          </div>
          <FormGroup check>
            
            
              <input type="checkbox"  /> New
          </FormGroup>
          <FormGroup check style={{marginTop:10}}>
            
              <input type="checkbox" /> Try at Home
            
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <button
            className="fltrbtn btm w-100 py-3"
            onClick={() => setsortOpen(false)}
          >
            Apply Sort
          </button>
        </DialogActions>
      </Dialog>

      {/* Filter Modal */}
      <Dialog fullScreen open={open} TransitionComponent={Transition}>
        <DialogTitle id="form-dialog-title" className="p-0">
          <IconButton
            onClick={handleModal}
            aria-label="upload picture"
            component="span"
          >
            <i className="fas fa-arrow-left"></i>
            <span className="pl-3">Filters</span>
          </IconButton>
        </DialogTitle>
        <DialogContent className="d-flex p-0">
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={Tabvalue}
            className={classes.tabs}
            onChange={handleChange}
            aria-label="Vertical tabs example"
          >
            <Tab label="Price" {...a11yProps(0)} />
            <Tab label="Metal" {...a11yProps(1)} />
            <Tab label="Material" {...a11yProps(2)} />
            <Tab label="Shop For" {...a11yProps(3)} />
          </Tabs>
          <div
            className={
              (Tabvalue != "0" ? "d-none" : "") + " bg-light container pt-3"
            }
          >
            <div className="mt-5">
              <InputRange
                maxValue={priceRangeSet.max}
                minValue={0}
                value={priceRange}
                onChange={changeRange}
              />
            </div>
          </div>
          <div
            className={
              (Tabvalue != "1" ? "d-none" : "") + " bg-light container pt-3"
            }
          >
            {metals &&
              metals.map((prop, i) => {
                return (
                  <div
                    className={
                      "filterOption mb-4 " +
                      (prop.Metal === metal ? "active" : "")
                    }
                    onClick={() => setMetal(prop.Metal)}
                  >
                    <i className="fas fa-check"></i>
                    <span>{prop.Metal}</span>
                  </div>
                );
              })}
              <div
                    className={
                      "filterOption  mb-4 " +
                      (metal === '' ? "active" : "")
                    }
                    onClick={() => setMetal('')}
                  >
                    <i className="fas fa-check"></i>
                    <span>All</span>
                  </div>
          </div>
          <div
            className={
              (Tabvalue != "2" ? "d-none" : "") + " bg-light container pt-3"
            }
          >
            {materials &&
              materials.map((prop, i) => {
                return (
                  <div
                    className={
                      "filterOption  mb-4 " +
                      (prop.material === material ? "active" : "")
                    }
                    onClick={() => setMaterial(prop.material)}
                  >
                    <i className="fas fa-check"></i>
                    <span>{prop.material}</span>
                  </div>
                );
              })}
               <div
                    className={
                      "filterOption  mb-4 " +
                      (material === '' ? "active" : "")
                    }
                    onClick={() => setMaterial('')}
                  >
                    <i className="fas fa-check"></i>
                    <span>All</span>
                  </div>
          </div>
          <div
            className={
              (Tabvalue != "3" ? "d-none" : "") + " bg-light container pt-3"
            }
          >
            {genders &&
              genders.map((prop, i) => {
                return (
                  <div
                    className={
                      "filterOption mb-4 " +
                      (prop.gender === gender ? "active" : "")
                    }
                    onClick={() => setGender(prop.gender)}
                  >
                    <i className="fas fa-check"></i>
                    <span>{prop.gender}</span>
                  </div>
                );
              })}
               <div
                    className={
                      "filterOption  mb-4 " +
                      (gender === '' ? "active" : "")
                    }
                    onClick={() => setGender('')}
                  >
                    <i className="fas fa-check"></i>
                    <span>All</span>
                  </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            className="fltrbtn btm w-100 py-3"
            onClick={() => setOpen(false)}
          >
            Apply Filter
          </button>
        </DialogActions>
      </Dialog>


      {window.outerWidth > 600 ? <CustomerFooter /> : null}
    </>
  );
};

export default CustomerProducts;
