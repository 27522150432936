import React, { Fragment, useEffect, useState } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { postMethod } from "../../../../_services/_postMethod";
import Loader from "../../../modules/loader";

const Area = (props) => {
  const [grid, setGrid] = React.useState([]);
  const [load, setLoad] = React.useState(false);
  const editRecord = (val) => {
    var temp = window.btoa(val);
    props.history.push("/branch/area/add?id=" + temp);
  };

  const [gridParam, setGridParam] = useState({});
  const columns = [
    {
      key: "area_name",
      text: "Area Name",
      align: "left",
      sortable: true,
    },

    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="btn btn-link btn-sm"
              onClick={() => editRecord(record.area_id)}
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-edit"></i>
            </button>
          </Fragment>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
  };

  useEffect(() => {
    setLoad(true);
    postMethod("api/masters/area/browse", gridParam).then((data) => {     
      setGrid(data.data);
      setLoad(false);
    });
  }, []);

  return (
    <>
      {load ? <Loader /> : null}
      <div className="card card-custom gutter-b">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">Area</h3>
          </div>
          <div className="card-toolbar">
            <a
              href="/branch/area/add"
              className="btn btn-sm btn-outline-theme font-weight-bold py-2 px-3"
            >
              <i className="flaticon2-user"></i> Add Area
            </a>
          </div>
        </div>
        <div className="card-body">
          <ReactDatatable config={config} records={grid} columns={columns} />
        </div>
      </div>
    </>
  );
};

export default Area;
