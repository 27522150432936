import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { BranchRoutes } from "../../routes/BranchRoutes";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Avatar from "@material-ui/core/Avatar";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";
import Sidenav from "./sidenav";
import ProtectedRoute from "../modules/auth/protectedroutes";
import { postMethod } from "../../_services/_postMethod";
import { Box, CircularProgress, FormGroup } from "@material-ui/core";
import { Col, Row } from "reactstrap";
import moment from "moment";
import ProtectedSubRoute from "../modules/auth/protectedSubRoutes";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      backgroundColor: "#d41c24",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        thickness={6}
        disableShrink
        size={30}
        variant="determinate"
        {...props}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="h6"
          component="div"
          className="time_text"
          color="white"
        >{`${Math.round(props.time)}`}</Typography>
      </Box>
    </Box>
  );
}

const Layout_Branch = (props) => {
  const { window } = props;
  const [time, setTime] = useState({
    hrs: moment().format("h"),
    minutes: moment().format("mm"),
    seconds: moment().format("ss"),
    hrsValue: Math.ceil((parseInt(moment().format("h")) * 8 + 1) / 10) * 10,
    minValue: Math.ceil((parseInt(moment().format("mm")) * 2 + 1) / 10) * 10,
    secValue: Math.ceil((parseInt(moment().format("ss")) * 1.6 + 1) / 10) * 10,
  });
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorElTemp, setAnchorEl] = React.useState(null);
  const [branchList, setBranchList] = useState([]);
  const [branch_id, setBranchID] = useState([]);
  const companyName = localStorage.getItem("jwl_company_name");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logOut = () => {
    localStorage.clear();
    props.history.push("/welcome");
  };

  const updateBranchID = (id) => {
    localStorage.setItem("jwl_branch_id", id);
    setBranchID(id);
    _SaveUserBranch(id);
  };

  const _SaveUserBranch = (id) => {
    let temparam = {
      branch_id: id,
    };
    postMethod("api/masters/user/updaterefBranch", temparam).then((data) => {});
  };

  const calculateTime = () => {
    setInterval(() => {
      setTime({
        ...time,
        hrsValue: Math.ceil((parseInt(moment().format("h")) * 8 + 1) / 10) * 10,
        hrs: moment().format("h"),
        secValue:
          Math.ceil((parseInt(moment().format("ss")) * 1.6 + 1) / 10) * 10,
        seconds: moment().format("ss"),
        minValue:
          Math.ceil((parseInt(moment().format("mm")) * 2 + 1) / 10) * 10,
        minutes: moment().format("mm"),
      });
    }, 1000);
  };
 
  
  // useEffect(() => {
  //   // calculateTime();
  //   postMethod("api/masters/branch/branchListUserWise", {}).then((data) => {
  //     setBranchList(data);
  //     setBranchID(localStorage.getItem("jwl_branch_id"));
  //   });
    
  // }, []);


const[branchAdr, setBranchAdr] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await postMethod("api/masters/branch/branchListUserWise", {});
        setBranchList(data);
        setBranchID(localStorage.getItem("jwl_branch_id"));
        setBranchAdr(data[0]["address"]);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors here (e.g., show a user-friendly error message)
      }
    };

    fetchData(); // Call the fetchData function when the component mounts

  }, []); // The empty dependency array ensures this effect runs only once on mount

  // The rest of your component logic...





  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          elevation={0}
          color="inherit"
          className={classes.appBar + " admin"}
        >
          <Toolbar>
            <Row className="w-100 align-items-center">
              <Col md={4}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h5" noWrap>
                  <span className="text-white">{companyName} - Branch</span>
                </Typography>
              </Col>
              <Col md={2}>
                <FormGroup>
                  {/* <select
                    id="user_branch_id"
                    className="form-control h-auto py-2 px-6"
                    name="user_branch_id"
                    value={branch_id}
                    onChange={(e) => updateBranchID(e.target.value)}
                  >
                    {branchList.map((prop, i) => {
                      return (
                        <option key={i} value={prop.branch_id}>
                          {prop.company_name}
                        </option>
                      );
                    })}
                  </select> */}
                  {
                    branchAdr ?  <input type="text" className="form-control h-auto py-2 px-6 font-weight-bold" value={branchAdr.toUpperCase()}/> : ""
                  }
                </FormGroup>
              </Col>
              <Col md={6} className="d-flex justify-content-around">
                <div className="version ml-auto">
                  <p className="font-weight-bold text-white mr-2">
                    Version 2.0
                  </p>
                </div>
                <div className="clock_container clock shadow ">
                  <p className="mb-2 text-center">
                    {moment().format("dddd DD MMM YYYY")}
                  </p>
                  <div className="clock_circle d-flex justify-content-around">
                    <div className="circle_box">
                      <CircularProgressWithLabel
                        className="hrs"
                        value={time.hrsValue}
                        time={time.hrs}
                      />
                    </div>
                    <div className="circle_box">
                      <CircularProgressWithLabel
                        color="secondary"
                        value={time.minValue}
                        time={time.minutes}
                      />
                    </div>
                    <div className="circle_box">
                      <CircularProgressWithLabel
                        className="seconds"
                        value={time.secValue}
                        time={time.seconds}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="clock_container w-25 ml-auto">
                  <p className="mb-0 ellipsis" >{localStorage.getItem("jwl_username")}</p>
                  <IconButton
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      className="pt-2 pb-0"
                      onClick={handleClick}
                      color="inherit">
                      <div className="user_header d-flex align-items-center">
                          <Avatar alt={localStorage.getItem("jwl_username")} className={classes.small} src="/static/images/avatar/1.jpg" />
                          <span className="ml-3">{userName}</span>
                      </div>
                  </IconButton>
                  <Menu
                      id="menu-appbar"
                      anchorEl={anchorElTemp}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                      }}
                      keepMounted
                      transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                      }}
                      open={Boolean(anchorElTemp)}
                      onClose={handleClose}
                  >
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={handleClose}>My account</MenuItem>
                  <MenuItem onClick={logOut}>Logout</MenuItem>
                </Menu>
              </div> */}
              </Col>
            </Row>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              className="admin"
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <Sidenav />
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              className="admin"
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              <Sidenav />
            </Drawer>
          </Hidden>
        </nav>
        <main className={"mt-5 pt-5 " + classes.content}>
          <BrowserRouter>
            <Switch>
              {BranchRoutes.map((route, index) => {
                return (
                  <ProtectedRoute
                    exact
                    key={index}
                    path={route.path}
                    component={route.component}
                  />
                );
              })}
              {/* <Redirect to="/admin" from="/" /> */}
            </Switch>
          </BrowserRouter>
        </main>
      </div>
    </>
  );
};

export default Layout_Branch;
