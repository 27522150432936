import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useLocation } from "react-router-dom";
import { Button, Form, FormGroup, Label, Input, FormText, Row, Col, Alert } from "reactstrap";
import {
  postMethod,
  validateMobile,
  singleParam,
  editUserGrid,
} from "../../../../_services/_postMethod";
import Loader from "../../../modules/loader";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField'; 

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AddArea = (props) => {
  let queryParams = useQuery();
  const id = queryParams.get("id") == "" ? null : queryParams.get("id");
  const { register, handleSubmit, errors } = useForm();
  const [load, setLoad] = useState(false);
  const [areaList, setAreaList] = useState([]);
  const [loadInser, setLoadInsert] = useState(false);
  const [loadDelete, setLoadDelete] = useState(false);
  const [loadDeleteMsg, setLoadDeleteMsg] = useState("Deleted");
  const [branchData, setBranch] = useState({
    area_name: "",
  });
  const [success, setSuccess] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false); 

  const insertData = (values) => {   
      setLoadInsert(true);
      values.area_name = branchData.area_name;
    postMethod("api/masters/area/insert", values).then((data) => {    
      if (data.data[0].valid) {
        setSuccess(true);
        setTimeout(() => {
          props.history.push("/branch/area");
        }, 2000);
      }
      else{
           setLoadInsert(false);
      }
    });
  };

  const handleDelete = () => {
    var proceed = window.confirm("Do you Really Wish to Delete this Entry..?!");

    if (proceed) {
      postMethod("api/masters/area/delete", {
        category_id: branchData.category_id,
      }).then((data) => {      
        if (data.data[0].valid) {
            setSuccessDelete(true);    
          setTimeout(() => {
            props.history.push("/branch/area");
          }, 2000);
        }
        else
        {
          setSuccessDelete(true);
              setTimeout(() => {
               setSuccessDelete(false);
            }, 4000);
             setLoadDeleteMsg(data.error)
           setLoadDelete(false);
        }
      });
     
    }
  };
   const getareaList = () =>{
      postMethod("api/customervisit/SearchAreaList", {search:branchData.area_name}).then((data) => setAreaList(data.data));
  }

   useEffect(() => {   
     getareaList();
  }, [branchData.area_name]);

  useEffect(() => {
    if (id !== null) {
      setLoad(true);
      var temp = window.atob(id);
      postMethod("api/masters/area/preview", { area_id: temp }).then((data) => {     
        setBranch(data.data[0]);
        setLoad(false);
      });
    }
     getareaList();
  }, []);
  return (
    <>
      {load ? <Loader /> : null}
      <Form onSubmit={handleSubmit(insertData)}>
        <input
          type="hidden"
          name="category_id"
          value={id == null ? "0" : branchData.category_id}
          ref={register}
        />
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Add Area</h3>
            </div>
          </div>
          <div className="card-body">
            <Row>
              <Col sm={4}>
                <FormGroup>
                  <Label className="mb-0">Area Name</Label>
                   <Autocomplete
                      id="name"
                      freeSolo
                      size="small"
                      value={branchData.area_name} 
                      options={areaList.map((option) => option.area_name)}  
                       onChange={(event, value) => {  
                      setBranch({ ...branchData, area_name: value });
                      }}   
                       renderInput={(params) => (
                        <TextField {...params}
                          onChange={(e) => setBranch({ ...branchData, area_name: e.target.value })}
                          margin="normal" variant="outlined" />
                      )}
                    />
                  
                </FormGroup>
              </Col>
            </Row>
            {success ? <Alert color="success">Saved Successfully</Alert> : null}
           {successDelete ? <Alert color="danger">{loadDeleteMsg}</Alert> : null}  
          </div>
          <div className="card-footer text-right">
            <a href="/branch/area" className="btn btn-secondary mr-2">
              Cancel
            </a>
            {id && (
              loadDelete ? <button type="button" className="btn btn-danger mr-2">
                Deleting..
              </button> : 
               <button type="button" onClick={handleDelete} className="btn btn-danger mr-2">
                Delete
              </button>
            )}
          
            {loadInser ? 
             <button type="button" className="btn btn-primary ">
              Saving..
            </button> :  <button type="submit" className="btn btn-primary ">
              Save
            </button>}
          </div>
        </div>
      </Form>
    </>
  );
};

export default AddArea;
