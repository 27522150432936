import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useLocation } from "react-router-dom";
import { Button, Form, FormGroup, Label, FormText, Row, Col, Alert, Table } from "reactstrap";
import { Card, TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {showSuccessToast,showErrorToast} from "../../../../_services/notification";
import {
  postMethod
} from "../../../../_services/_postMethod";
import Loader from "../../../modules/loader";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AddStocktransfer = (props) => {
  let queryParams = useQuery();
  const id = queryParams.get("id") == "" ? null : queryParams.get("id");
  const jwl_branch_id = localStorage.getItem("jwl_branch_id");
  const { register, handleSubmit, errors } = useForm();
  const [load, setLoad] = useState(false);
  const [loadInser, setLoadInsert] = useState(false);
  const [loadDelete, setLoadDelete] = useState(false);  
  const [loadDeleteMsg, setLoadDeleteMsg] = useState("Deleted");
  const [productsValidation, setProductsValidation] = useState(false);
  const [productlist, setProductlist] = useState([]);
  const [branchlist, setBranchlist] = useState([]);
  const [searchProduct, setSearchProduct] = useState("");
  const [stocktransferproducts, setstocktransferproducts] = useState([]);
  const [seachProdutList, setseachProdutList] = useState([]);
  const [seachProdutListLoading, setseachProdutListLoading] = useState(false);
  const [stockinproductParam, setStockinproductParam] = useState({
    product_id: "",
    product: "",
    product_code: "",
    qty: "",
    url_image:"",
    image_path:"",
    transfer_qty:"",
    status:"Pending"
  });

  const [param, setParam] = useState({
    tran_id: id == null ? "0" : id,
    to_branch_id: 0,
    date: "",
    entry_no: "",
    remarks: "",
    status: "",
    stock_in_products: [],
  });
  const [success, setSuccess] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);

  useEffect(() => {
      setLoad(true);
    if (id !== null) {    
      var temp = window.atob(id);
      postMethod("api/transactions/stockTransfer/preview", { tran_id: temp }).then((dataD) => {
           var data =dataD.data;
        setParam({
          ...param,
          date:   (data[0].date ? data[0].date.split("T")[0] : ""),
          entry_no: data[0].entry_no,
          to_branch_id: data[0].to_branch_id,        
          remarks: data[0].remarks,
          status: data[0].status             
        });     
        setstocktransferproducts(data[1]);
        setLoad(false);
      });
    }
    else{
         postMethod("api/transactions/stockTransfer/preview", { tran_id: 0 }).then((dataD) => { 
        var data =dataD.data;
               setParam({
          ...param,
          date: data[0].date,
          entry_no: data[0].entry_no ,
          status: data[0].status         
        });   
        setLoad(false);
      });
    }
    postMethod("api/transactions/stockin/getproductlist", {}).then((data) => {
      setProductlist(data.data);
    });

    postMethod("api/transactions/stockin/getbranchlist", {}).then((data) => {
      setBranchlist(data.data);
    });
  }, []);

  // Search Products 

  const searchProductFun = ()=>{
    setseachProdutListLoading(true);
    postMethod("api/transactions/stockTransfer/getProducts", { search:searchProduct}).then((data) => {
      setseachProdutList(data.data);
       setseachProdutListLoading(false);
    });
  }

  // check object is null or undefined
  const checkObject = (obj) => {
    return obj === undefined ? {} : Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  //Add product into Array
  const AddProduct = () => {

    if(stocktransferproducts.findIndex(x => x.product_id == stockinproductParam.product_id) == -1)
    {
   if (stockinproductParam.qty !== "") {
      stocktransferproducts.push(stockinproductParam);
      setstocktransferproducts(stocktransferproducts);  
      setStockinproductParam({
        product_id: "",
        product_code: "",     
        product: "",      
        qty: "",        
        url_image:"",
        image_path:"",
        transfer_qty:"",
        status:"Pending"
      });
    }
    } 
  };

    const AddProductSearch = (objD) => { 
      if(stocktransferproducts.findIndex(x => x.product_id == objD.product_id) == -1)
    {
    if (objD.qty !== "") {
       stocktransferproducts.push({product_id:objD.product_id,product_code:localStorage.getItem('jwl_branch_id')+'/'+objD.product_code,product:objD.product,qty:objD.qty,transfer_qty:objD.transfer_qty,image_path:objD.image_path,url_image:objD.url_image}); 
       let tempArry = [...stocktransferproducts]; 
    setstocktransferproducts(tempArry);
    }
  }
  };
  const removeProduct = (i) => {
    let tempArry = [...stocktransferproducts];
    tempArry.splice(i, 1);
    setstocktransferproducts(tempArry);
  };

  //Insert Data
  const _Save = () => {

    var qty_validation = false;
    stocktransferproducts.forEach(element => {
      console.log("loop in ");
      console.log(element.qty < element.transfer_qty);
      if(element.qty < element.transfer_qty)
      {
        qty_validation = true;
        console.log("break");
        return;      
      }

    });

     console.log(qty_validation);
    if(stocktransferproducts.length == 0)
    {
      setProductsValidation(true);
    }
    else if(qty_validation)
    {
      showErrorToast("Qty is not more then available qty.")
    }
    else
    {
    setLoadInsert(true);

    var temp = id == null ? "0" : window.atob(id);
    let temparam = {
      tran_id: temp,
      from_branch_id: param.from_branch_id,
      to_branch_id: param.to_branch_id,
      date: param.date,
      entry_no: param.entry_no,
      remarks: param.remarks,
      stock_transfer_products: stocktransferproducts
    };
    postMethod("api/transactions/stockTransfer/insert", temparam).then((data) => { 
     
      if (data.data[0].valid) {
        setSuccess(true);
        setTimeout(() => {
          props.history.push("/branch/stock-transfer");
        }, 2000);
      }
      else{
       setLoadInsert(false);
      }
    });
    }
  };

  const handleDelete = () => {
    var proceed = window.confirm("Do you Really Wish to Delete this Entry..?!");
    var temp = window.atob(id);
    if (proceed) {
         setLoadDelete(true);
      postMethod("api/transactions/stockTransfer/delete", { tran_id: temp }).then((data) => {      
        if (data.data[0].valid) {
          setSuccessDelete(true);
          setTimeout(() => {
            props.history.push("/branch/stock-transfer");
          }, 2000);
        }
        else
        {
          setSuccessDelete(true);
              setTimeout(() => {
               setSuccessDelete(false);
            }, 4000);
             setLoadDeleteMsg(data.error)
           setLoadDelete(false);
        }
      });
    }
  };

  return (
    <>
      {load ? <Loader /> : null}
      <Form onSubmit={handleSubmit(_Save)}>
        <input
          type="hidden"
          name="product_id"
          value={id == null ? "0" : param.product_id}
          ref={register}
        />
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Add Stock Fransfer</h3>
            </div>
          </div>
          <div className="card-body">
            <Row>
                  <Col sm={3}>
                <FormGroup>
                  <Label>Entry No</Label>
                  <input
                    type="text"
                    required
                    readOnly
                    value={param.entry_no}
                    className="form-control h-auto py-2 px-6"
                    name="entry_no"
                    onChange={(e) => setParam({ ...param, entry_no: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col> 

              <Col sm={3}>
                <FormGroup>
                  <Label>Date</Label>
                  <TextField
                    type="date"
                    required
                    value={param.date}
                    className="form-control"
                    onChange={(e) => {
                      setParam({
                        ...param,
                        date: e.target.value,
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormGroup>
              </Col>
                    
              <Col sm={3}>
                <FormGroup>
                  <Label>To Branch</Label>
                  <select
                    className="form-control h-auto py-2 px-6"
                    name="to_branch_id"
                    required
                    value={param.to_branch_id}
                    onChange={(e) => setParam({ ...param, to_branch_id: e.target.value })}
                  >
                    <option value="">-select-</option>
                    {checkObject(branchlist)
                      ? null
                      : branchlist.map((obj, i) => {
                        if(jwl_branch_id != obj.branch_id){
                          return (
                            <option key={i} value={obj.branch_id}>
                              {obj.company_name}
                            </option>
                          );
                        }
                         
                        })}
                  </select>
                </FormGroup>
              </Col>
              <Col sm={12}>
                <FormGroup>
                  <Label>Remarks</Label> 
                   <textarea                
                    required
                    value={param.remarks}
                    className="form-control h-auto py-2 px-6"
                    name="remarks"
                    onChange={(e) => setParam({ ...param, remarks: e.target.value })}
                    ref={register}
                  />          
                
                </FormGroup>
              </Col>
            </Row>
            {param.status.toUpperCase() == "PENDING" ?  <Row>
              <Col sm={6}>
                <h4>Searched Products</h4>
              </Col>                 
             <Col sm={6}>
                <FormGroup className="d-flex">                  
                  <input
                    type="text"
                    value={searchProduct}
                    className="form-control h-auto py-2 px-6"
                    name="search"
                    onChange={(e) => setSearchProduct(e.target.value)}                  
                  /> 
                  {seachProdutListLoading ?    <button type="button" className="btn-info">Searching..</button> :                  <button type="button" className="btn-success" onClick={()=>{searchProductFun()}}>Search</button> }

                </FormGroup>
              </Col>
              <Col sm={12}>
           
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Product Code </th>                  
                      <th>Product</th>    
                      <th>Image</th>  
                      <th>Available Qty</th>       
                      <th>Qty</th>
                      <th>Action</th>
                    </tr>                   
                  </thead>
                  <tbody>
                    {seachProdutList.length > 0
                      ? seachProdutList.map((obj, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                ({i + 1}) {obj.product_code}
                                {/* {obj.url_image +""+obj.image_path} */}
                              </td>   
                               <td>
                                {obj.product}
                              </td>                              
                              <td><img src={obj.url_image +""+obj.image_path} width="36px" alt="" />   </td>     
                              <td>
                                {obj.qty}
                              </td>                     
                              <td>
                                 <input
                          type="text"
                          className="form-control h-auto py-2 px-6"
                          name="qty"
                          defaultValue={obj.transfer_qty ? obj.transfer_qty : 1} 
                          onBlur={(e) =>
                            {
                              if(obj.qty >=  e.target.value)
                              {
                              var temp = [...seachProdutList];
                              temp[temp.findIndex((x) => x.product_id == obj.product_id)].transfer_qty =
                                e.target.value;
                              setProductlist(temp);
                              }
                              else{
                                showErrorToast("Qty is not more then available qty.")
                              }
                             
                            }
                          }
                        />
                                </td>
                              <td>
                                <button type="button" className="btn-success" onClick={() => {
                                  if(obj.transfer_qty == undefined)
                                  {
                                    obj.transfer_qty = 1;
                                    AddProductSearch(obj)
                                    showSuccessToast("Qty Added.");    
                                  }
                                  else if(obj.qty >=  obj.transfer_qty)
                                  {     
                                    showSuccessToast("Qty Added.");                             
                                  AddProductSearch(obj)
                                }
                                else{
                                  var temp = [...seachProdutList];
                                  temp[temp.findIndex((x) => x.product_id == obj.product_id)].transfer_qty =
                                    1;
                                  setProductlist(temp);
                                  showErrorToast("Qty can not be more then available qty.");
                                }
                                }}>
                                  Add
                                </button>
                              </td>
                            </tr>
                          );
                        }) : <tr>
                              <td colSpan="4" className="text-center">
                               <h3>No Data</h3>
                              </td> 
                        </tr>
                       }
                  </tbody>
                </Table>
              </Col>
            </Row> : null}
            <Row>
              <Col sm={12}>
                <Table bordered>
                  <thead>
                    <tr> 
                      <th>Product Code {productsValidation ? <label className="text-danger">*Required</label> : null}</th>
                      <th>Product</th>  
                      <th>Image</th>    
                      <th>Available Qty</th>              
                      <th>Qty</th>
                      <th>Status</th>
                      {param.status.toUpperCase() == "PENDING" ?<th>Action</th>: null}
                    </tr>
                    {param.status.toUpperCase() == "PENDING" ?   <tr>
                      <th>
                        <Autocomplete
                      id="combo-box-demo"
                      size="small"
                      options={productlist}                   
                      onChange={(event, value) => {  
                        if(value !=null)
                        setStockinproductParam({
                              ...stockinproductParam,
                              product_code: value.product_code,
                              product_id: value.product_id,
                              product: value.product_name,
                              qty: value.qty,
                              transfer_qty: 1,
                              image_path:value.image_path,
                              url_image:value.url_image
                            })
                      }}
                      getOptionLabel={(option) => option.product_code}                    
                      renderInput={(params) => <TextField {...params}  variant="outlined" />}
                    />
                      
                      </th>   
                          <th>
                        <input
                          type="text"
                          className="form-control h-auto py-2 px-6"
                          name="product"
                          disabled='false'
                          value={stockinproductParam.product}                        
                        />
                      </th>  
                      <td><img src={stockinproductParam.url_image +""+stockinproductParam.image_path} width="36px" alt="" />   </td>        
                      {/* <td>{stockinproductParam.url_image +""+stockinproductParam.image_path}</td> */}
                      <th>{stockinproductParam.qty }</th>              
                      <th>
                      <input
                          type="text"
                          className="form-control h-auto py-2 px-6"
                          name="transfer_qty"
                          value={stockinproductParam.transfer_qty} 
                          onChange={(e) =>
                            {
                              setStockinproductParam({ ...stockinproductParam, transfer_qty: e.target.value });
                              if(stockinproductParam.qty <  e.target.value)
                              {
                                showErrorToast("Qty can not be more then available qty.");
                              }
                             
                            }
                          }
                        />
                      </th>  
                      <th></th>                    
                      <th>
                        <button type="button" className="btn-success" onClick={() => {                          
                          if(stockinproductParam.qty >=  stockinproductParam.transfer_qty)
                          {
                            AddProduct();
                          }
                          else{                            
                            showErrorToast("Qty is not more then available qty.")
                          }
                          
                          }}>
                          Add
                        </button>
                      </th>
                    </tr>:null}
                  </thead>
                  <tbody>
                    {stocktransferproducts.length > 0
                      ? stocktransferproducts.map((obj, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                ({i + 1}) {obj.product_code}
                              </td>
                              <td>{obj.product}</td>
                              <td><img src={obj.url_image +""+obj.image_path} width="36px" alt="" />   </td> 
                              <td>{obj.qty}</td>  
                              <td>
                                <input
                          type="text"
                          className="form-control h-auto py-2 px-6"
                          name="qty"
                          value={obj.transfer_qty}
                          onChange={(e) =>
                            {
                              var temp = [...stocktransferproducts]
                              temp[temp.findIndex(x => x.product_id == obj.product_id)].transfer_qty = e.target.value;
                              setstocktransferproducts(temp);

                               if(obj.qty <  e.target.value)
                              {
                                showErrorToast("Qty can not be more then available qty.");
                              }
                              else{
                                var temp = [...stocktransferproducts]
                                temp[temp.findIndex(x => x.product_id == obj.product_id)].transfer_qty = e.target.value;
                                setstocktransferproducts(temp);
                              }
                           
                            }
                          }
                        />
                                </td>
                                 <td>
                              
                               {obj.status == 'Accepted' ? <span class="text-success">Accepted</span> : obj.status == 'Canceled'  ? <span class="text-danger">Cancel</span> : <span class="text-info">Pending</span>}
                              </td>
                                {param.status.toUpperCase() == "PENDING" ?  <td>
                              
                                <button type="button" onClick={() => removeProduct(i)}>
                                  x
                                </button> 
                              </td>: null}
                            </tr>
                          );
                        })
                      : <tr>
                              <td colSpan="4" className="text-center">
                               <h3>No Data</h3>
                              </td> 
                        </tr>}
                  </tbody>
                </Table>
              </Col>
            </Row>
            {success ? <Alert color="success">Saved Successfully</Alert> : null}
            {successDelete ? <Alert color="danger">{loadDeleteMsg}</Alert> : null}
          </div>
          <div className="card-footer text-right">
            <a href="/branch/stock-transfer" className="btn btn-secondary mr-2"> 
              Cancel
            </a>
              {id && (
              loadDelete ? <button type="button" className="btn btn-danger mr-2">
                Deleting..
              </button> :(param.status.toUpperCase() == "PENDING" ? 
               <button type="button" onClick={handleDelete} className="btn btn-danger mr-2">
                Delete
              </button> : null)
            )}
          
            {loadInser ? 
             <button type="button" className="btn btn-primary ">
              Saving..
            </button> :  param.status.toUpperCase() == "PENDING" ? <button type="submit" className="btn btn-primary ">
              Save
            </button> : null}
          </div>
        </div>
      </Form>
    </>
  );
};

export default AddStocktransfer;
