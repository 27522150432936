import Anniversary from "../app/screens/customerCRM/anniversary";
import Birthday from "../app/screens/customerCRM/birthday";
import CustDesign from "../app/screens/customerCRM/design";
import CustomerHome from "../app/screens/customerCRM/home";
import Instragram from "../app/screens/customerCRM/instagram";
import CustomerProducts from "../app/screens/customerCRM/products";
import CustomerProductview from "../app/screens/customerCRM/productview";
import CustomerProfile from "../app/screens/customerCRM/profile";
import Referral from "../app/screens/customerCRM/referral";
import Specialvoucher from "../app/screens/customerCRM/specialvoucher";
import CustomerThankyou from "../app/screens/customerCRM/thank-you";
import Uploaddesignvoucher from "../app/screens/customerCRM/uploaddesignvoucher";
import CustomerWishlist from "../app/screens/customerCRM/wishlist";
export const CustomerRoutes = [
  {
    path: "/general/home",
    name: "home",
    icon: "flaticon-web",
    child: false,
    component: CustomerHome,
  },
  {
    path: "/general/products",
    name: "Products",
    icon: "flaticon-web",
    child: false,
    component: CustomerProducts,
  },
  {
    path: "/general/product-view",
    name: "Product View",
    icon: "flaticon-web",
    child: false,
    component: CustomerProductview,
  },
  {
    path: "/general/wish-list",
    name: "wish list",
    icon: "flaticon-web",
    child: false,
    component: CustomerWishlist,
  },
  {
    path: "/general/thank-you",
    name: "thank you",
    icon: "flaticon-web",
    child: false,
    component: CustomerThankyou,
  },
  {
    path: "/general/referral",
    name: "referral",
    icon: "flaticon-web",
    child: false,
    component: Referral,
  },
  {
    path: "/general/birthday",
    name: "birthday",
    icon: "flaticon-web",
    child: false,
    component: Birthday,
  },
  {
    path: "/general/anniversary",
    name: "Anniversary",
    icon: "flaticon-web",
    child: false,
    component: Anniversary,
  },
  {
    path: "/general/instagram",
    name: "Instragram",
    icon: "flaticon-web",
    child: false,
    component: Instragram,
  },
  {
    path: "/general/special-voucher",
    name: "special voucher",
    icon: "flaticon-web",
    child: false,
    component: Specialvoucher,
  },
  {
    path: "/general/upload-design",
    name: "upload design",
    icon: "flaticon-web",
    child: false,
    component: Uploaddesignvoucher,
  },
  {
    path: "/general/profile",
    name: "profile",
    icon: "flaticon-web",
    child: false,
    component: CustomerProfile,
  },
  {
    path: "/customer/design",
    name: "design",
    icon: "flaticon-web",
    child: false,
    component: CustDesign,
  }
  
];
