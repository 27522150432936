import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useLocation } from "react-router-dom";

import { API_BASE_URL } from "../../../../_services/constant";
import { Button, Form, FormGroup, Label, Input, FormText, Row, Col, Alert } from "reactstrap";
import {
  postMethod,
  editUserGrid,
} from "../../../../_services/_postMethod";
import Loader from "../../../modules/loader";
import { post } from "axios";
import { TextField } from "@material-ui/core";
import { NaturePeopleOutlined } from "@material-ui/icons";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AddVoucher = (props) => {
  let queryParams = useQuery();
  const id = queryParams.get("id") == "" ? null : queryParams.get("id");
  const companyName = localStorage.getItem("jwl_brand_name");
  const { register, handleSubmit, errors } = useForm();
  const [load, setLoad] = useState(false);
  const [loadInser, setLoadInsert] = useState(false);
  const [loadDelete, setLoadDelete] = useState(false);  
  const [loadDeleteMsg, setLoadDeleteMsg] = useState("Deleted");
  const [imageValidation1, setImageValidation1] = useState(false);
  const [imageValidation2, setImageValidation2] = useState(false);
  const [voucher_sms_show, setVoucher_sms_show] = useState("");
  const [param, setParam] = useState({
    voucher_session_type: "",    
    voucher_name: "",
    voucher_type: "",
    voucher_heading: "",
    voucher_value: "",
    voucher_sms: "",
    start_date: "",
    end_date: "",
    redeem_start_date: "",
    redeem_end_date: "",
    duration:"",
    disable: false,
    image_path:"",
    banner_image:""
  });
    const [smsParam, setSmsParam] = useState({
    first_time_voucher_1: "",    
    fist_time_voucher_2: "",  
    fist_time_voucher_3: "",    
    birthday_voucher_1: "",   
    birthday_voucher_2: "",  
    birthday_voucher_3: "",    
    anniversary_voucher_1: "",   
    anniversary_voucher_2: "",  
    anniversary_voucher_3: "",    
    ref_voucher_1: "",   
    ref_voucher_2: "",  
    ref_voucher_3: "", 
    ref_voucher_4: "", 
    design_voucher_1: "",   
    design_voucher_2: "",  
    design_voucher_3: "", 
    other_voucher_1: "",   
    other_voucher_2: "",  
    other_voucher_3: ""
  });
  const [imageObj, setImageObj] = useState({
    image: "",
    banner: ""
  });
  const [success, setSuccess] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false); 
  function createGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) { 
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  const insertData = (values) => {
    let file = document.getElementById("updateImage");
    let files = file.files;
    let formData = new FormData();

    let fileBanner = document.getElementById("updateImageBanner"); 
    let filesBanner = fileBanner.files;
    let formDataBanner = new FormData();

    
      if(param.image_path == "" && files.length == 0)
     {
       setImageValidation1(true);
     }
     else if(param.banner_image == "" && filesBanner.length == 0)  
     {
        setImageValidation2(true);
     }
     else{
       setLoadInsert(true);
       if (filesBanner.length > 0) {
       for (var j = 0; j < filesBanner.length; j++) {   
        let file_nameBanner = "image-" + createGuid();
        values.banner_image = file_nameBanner+""+filesBanner[j].name.substring(filesBanner[j].name.lastIndexOf("."));
        param.banner_image = file_nameBanner+""+filesBanner[j].name.substring(filesBanner[j].name.lastIndexOf("."));
        formDataBanner.append("files", filesBanner[j], file_nameBanner);
      }
    }

    if (files.length > 0) {
      for (var j = 0; j < files.length; j++) {     
        let file_name = "image-" + createGuid();
        values.image_path = file_name+""+files[j].name.substring(files[j].name.lastIndexOf("."));
        param.image_path = file_name+""+files[j].name.substring(files[j].name.lastIndexOf("."));
        formData.append("files", files[j], file_name);
      }
    }
    postMethod("api/masters/customer/voucher/insert", param).then((data) => {   
      if (data.data[0].valid) {
        if (files.length > 0) {
          uploadImageFunc();
        } 
        else if (filesBanner.length > 0) {
          uploadImageBannerFunc();
        }       
        else {
          setSuccess(true);
          setTimeout(() => {
            props.history.push("/branch/voucher");
          }, 2000);
        }

      }
    });
     }

   
  };
  

  
  const uploadImageFunc = () => {

    let file = document.getElementById("updateImage");
    let files = file.files;
    let formData = new FormData();

    if (files.length > 0) {
      for (var j = 0; j < files.length; j++) {
        formData.append("files", files[j], param.image_path.split(".")[0]);
      }
    }

    const url = API_BASE_URL + `api/masters/customer/Uploadvoucher`;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "auth-token": localStorage.getItem("jwl_token"),
      },
    };

    post(url, formData, config).then(
      (response) => {
        let file = document.getElementById("updateImageBanner");
        let files = file.files;     
      
        if (files.length > 0) {
          uploadImageBannerFunc();
        }      
        else{
          setSuccess(true);
          setTimeout(() => {
            props.history.push("/branch/voucher");
          }, 2000);
        }
      },
      (error) => {
        setLoadInsert(false);
        setSuccess(true);
      }
    );
  };

  
  
  const uploadImageBannerFunc = () => {

     let fileBanner = document.getElementById("updateImageBanner"); 
    let filesBanner = fileBanner.files;
    let formDataBanner = new FormData();

    if (filesBanner.length > 0) {
      for (var j = 0; j < filesBanner.length; j++) {  
          formDataBanner.append("files", filesBanner[j], param.banner_image.split(".")[0]);
      }
    }

    const url = API_BASE_URL + `api/masters/customer/UploadvoucherBanner`;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "auth-token": localStorage.getItem("jwl_token"),
      },
    };

    post(url, formDataBanner, config).then(
      (response) => {
          setSuccess(true);
          setTimeout(() => {
            props.history.push("/branch/voucher");
          }, 2000);
      },
      (error) => {
       setLoadInsert(false);
        setSuccess(false);
      }
    );
  };

   //Delete Entry
  const handleDelete = () => {
    var proceed = window.confirm("Do you Really Wish to Delete this Entry..?!");

    if (proceed) {
        setLoadDelete(true);
      postMethod("api/masters/customer/voucher/delete", {
        voucher_id: param.voucher_id,
      }).then((data) => {      
        if (data.data[0].valid) {
           setSuccessDelete(true);
          setTimeout(() => {
            props.history.push("/branch/voucher");
          }, 2000);
        }
        else
        {
          setSuccessDelete(true);
              setTimeout(() => {
               setSuccessDelete(false);
            }, 4000);
             setLoadDeleteMsg(data.error)
           setLoadDelete(false);
        }
      });
    }
  };

  useEffect(() => {
    if (id !== null) {
      setLoad(true);
      var temp = window.atob(id);
      postMethod("api/masters/customer/voucher/preview", { voucher_id: temp }).then((data) => {
      var dataD = data.data;
        setParam({
          ...param,
          voucher_id: dataD.voucher_id,
          voucher_type: dataD.voucher_type,
          duration: dataD.duration,
          voucher_session_type: dataD.voucher_session_type,
          voucher_name: dataD.voucher_name,
          voucher_heading: dataD.voucher_heading,
          voucher_value: dataD.voucher_value,
          voucher_sms: dataD.voucher_sms,         
          start_date:   dataD.start_date?.split("T")[0],
          end_date: dataD.end_date?.split("T")[0], 
          redeem_start_date: dataD.redeem_start_date?.split("T")[0],
          redeem_end_date: dataD.redeem_end_date?.split("T")[0], 
          image_path: dataD.image_path,
          banner_image: dataD.banner_image,
          disable: dataD.disable
        });
        setVoucher_sms_show(dataD.voucher_sms);
         setImageObj({ ...imageObj, image: dataD.image_path,banner:dataD.banner_image})
        setLoad(false);
      });
    }
  }, []);

const setSMSTemplate = () =>{

  if(param.voucher_type == "first time")
  {
    var Template = "you have just got a "+smsParam.first_time_voucher_1+" gift "+smsParam.fist_time_voucher_2+" from "+companyName.toUpperCase()+". Validity ("+smsParam.fist_time_voucher_3+"). T and C apply."

    var TemplateShow = "you have just got a "+(smsParam.first_time_voucher_1 != "" ? "<div class='text-red ml-1 mr-1'>"+smsParam.first_time_voucher_1+"</div>" : "<div class='text-red ml-1 mr-1'>Variable Part</div>" )+" gift "+(smsParam.fist_time_voucher_2 != "" ? "<div class='text-red ml-1 mr-1'>"+smsParam.fist_time_voucher_2+"</div>" : "<div class='text-red ml-1 mr-1'>Variable Part</div>" )+" from "+companyName.toUpperCase()+". Validity ("+(smsParam.fist_time_voucher_3 != "" ? "<div class='text-red ml-1 mr-1'>"+smsParam.fist_time_voucher_3+"</div>" : "<div class='text-red ml-1 mr-1'>Variable Part</div>" )+"). T and C apply."

    setParam({...param,voucher_sms:Template});
    setVoucher_sms_show(TemplateShow);
  }

  if(param.voucher_type == "birthday") 
  {
    var Template = companyName.toUpperCase()+" wishes u Happy Birthday. Lets make it special by "+smsParam.birthday_voucher_1+". Validity ("+smsParam.birthday_voucher_2+"). T & C apply."

     var TemplateShow = " "+companyName.toUpperCase()+" wishes u Happy Birthday. Lets make it special by "+(smsParam.birthday_voucher_1 != "" ? "<div class='text-red ml-1 mr-1'>"+smsParam.birthday_voucher_1+"</div>" : "<div class='text-red ml-1 mr-1'>Variable Part</div>" )+". Validity ("+(smsParam.birthday_voucher_2 != "" ? "<div class='text-red ml-1 mr-1'>"+smsParam.birthday_voucher_2+"</div>" : "<div class='text-red ml-1 mr-1'>Variable Part</div>" )+"). T & C apply."

    setParam({...param,voucher_sms:Template});
    setVoucher_sms_show(TemplateShow);
  }
  if(param.voucher_type == "anniversary")
  {
    var Template = companyName.toUpperCase()+" wishes u Happy Anniversary. Lets make it special by "+smsParam.anniversary_voucher_1+". Validity ("+smsParam.anniversary_voucher_2+"). T & C apply."

    var TemplateShow = " "+companyName.toUpperCase()+" wishes u Happy Anniversary. Lets make it special by "+(smsParam.anniversary_voucher_1 != "" ? "<div class='text-red ml-1 mr-1'>"+smsParam.anniversary_voucher_1+"</div>" : "<div class='text-red ml-1 mr-1'>Variable Part</div>" )+". Validity ("+(smsParam.anniversary_voucher_2 != "" ? "<div class='text-red ml-1 mr-1'>"+smsParam.anniversary_voucher_2+"</div>" : "<div class='text-red ml-1 mr-1'>Variable Part</div>" )+"). T & C apply."

    setParam({...param,voucher_sms:Template});
    setVoucher_sms_show(TemplateShow);
  }
    if(param.voucher_type == "referral")
  {
    var Template = "thanks for referring $$MemberName$$ to "+companyName.toUpperCase()+". To honour, we offer "+smsParam.ref_voucher_2+" gift "+smsParam.ref_voucher_3+". Validity ("+smsParam.ref_voucher_4+"). T and C apply."

    var TemplateShow = "thanks for referring <div class='text-red ml-1 mr-1'>$$MemberName$$</div> to "+companyName.toUpperCase()+". To honour, we offer "+(smsParam.ref_voucher_2 != "" ? "<div class='text-red ml-1 mr-1'>"+smsParam.ref_voucher_2+"</div>" : "<div class='text-red ml-1 mr-1'>Variable Part</div>" )+" gift "+(smsParam.ref_voucher_3 != "" ? "<div class='text-red ml-1 mr-1'>"+smsParam.ref_voucher_3+"</div>" : "<div class='text-red ml-1 mr-1'>Variable Part</div>" )+". Validity ("+(smsParam.ref_voucher_4 != "" ? "<div class='text-red ml-1 mr-1'>"+smsParam.ref_voucher_4+"</div>" : "<div class='text-red ml-1 mr-1'>Variable Part</div>" )+"). T and C apply."

    setParam({...param,voucher_sms:Template});
    setVoucher_sms_show(TemplateShow);
  }
    if(param.voucher_type == "uploadDesign")
  {
     var Template = "Dear $$MemberName$$, thanks for sharing designs. We appreciate and offer "+smsParam.design_voucher_1+" gift "+smsParam.design_voucher_2+". Validity ("+smsParam.design_voucher_3+"). Team $$BrandName$$."

      var TemplateShow = "thanks for sharing designs. We appreciate and offer "+(smsParam.design_voucher_1 != "" ? "<div class='text-red ml-1 mr-1'>"+smsParam.design_voucher_1+"</div>" : "<div class='text-red ml-1 mr-1'>Variable Part</div>" )+" gift "+(smsParam.design_voucher_2 != "" ? "<div class='text-red ml-1 mr-1'>"+smsParam.design_voucher_2+"</div>" : "<div class='text-red ml-1 mr-1'>Variable Part</div>" )+". Validity ("+(smsParam.design_voucher_3 != "" ? "<div class='text-red ml-1 mr-1'>"+smsParam.design_voucher_3+"</div>" : "<div class='text-red ml-1 mr-1'>Variable Part</div>" )+"). Team "+companyName.toUpperCase()+"."

    setParam({...param,voucher_sms:Template});
    setVoucher_sms_show(TemplateShow);
  }  if(param.voucher_type == "other")
  {
    var Template = smsParam.other_voucher_1+" celebrate this special "+smsParam.other_voucher_2+" with "+smsParam.other_voucher_3+". Validity ("+smsParam.other_voucher_4+"). Team "+companyName.toUpperCase()+". T and C apply."

    var TemplateShow = " "+(smsParam.other_voucher_1 != "" ? "<div class='text-red ml-1 mr-1'>"+smsParam.other_voucher_1+"</div>" : "<div class='text-red ml-1 mr-1'>Variable Part</div>" )+" celebrate this special "+(smsParam.other_voucher_2 != "" ? "<div class='text-red ml-1 mr-1'>"+smsParam.other_voucher_2+"</div>" : "<div class='text-red ml-1 mr-1'>Variable Part</div>" )+" with "+(smsParam.other_voucher_3 != "" ? "<div class='text-red ml-1 mr-1'>"+smsParam.other_voucher_3+"</div>" : "<div class='text-red ml-1 mr-1'>Variable Part</div>" )+". Validity ("+(smsParam.other_voucher_4 != "" ? "<div class='text-red ml-1 mr-1'>"+smsParam.other_voucher_4+"</div>" : "<div class='text-red ml-1 mr-1'>Variable Part</div>" )+"). Team "+companyName.toUpperCase()+". T and C apply."

    setParam({...param,voucher_sms:Template});
    setVoucher_sms_show(TemplateShow);
  }  

}



  return (
    <>
      {load ? <Loader /> : null}
      <Form onSubmit={handleSubmit(insertData)}>
        <input
          type="hidden"
          name="voucher_id"
          value={id == null ? "0" : param.voucher_id}
          ref={register}
        />
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Add Customer Voucher</h3>
            </div>
          </div>
          <div className="card-body">
            <Row>
            <Col sm={4}>
                <FormGroup>
                  <Label>Voucher Session type</Label>
                  <select className="form-control" required value={param.voucher_session_type}
                   onChange={(e) => setParam({ ...param, voucher_session_type: e.target.value })}
                   ref={register}>
                    <option value="" disabled={true}>-Select Type-</option>
                    <option value="duration in days">Duration in days</option>
                    <option value="datetime">Date time</option>
                  </select>
                  
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Voucher Type</Label>
                  <select className="form-control" required  value={param.voucher_type}
                   onChange={(e) => {
                     setParam({ ...param, voucher_type: e.target.value,voucher_sms: "" });
                     setSmsParam({...smsParam, first_time_voucher_1: "", fist_time_voucher_2: "",  
                        fist_time_voucher_3: "",    
                        birthday_voucher_1: "",   
                        birthday_voucher_2: "",  
                        birthday_voucher_3: "",    
                        anniversary_voucher_1: "",   
                        anniversary_voucher_2: "",  
                        anniversary_voucher_3: "",    
                        ref_voucher_1: "",   
                        ref_voucher_2: "",   
                        ref_voucher_3: "", 
                        ref_voucher_4: "", 
                        design_voucher_1: "",   
                        design_voucher_2: "",  
                        design_voucher_3: "", 
                        other_voucher_1: "",   
                        other_voucher_2: "",  
                        other_voucher_3: ""
                    });
                     setVoucher_sms_show("");
                    }}
                   ref={register}>
                          <option value="" disabled={true}>-Select Type-</option>    
                          <option value="first time">First Time</option>  
                          <option value="birthday">Birthday</option>
                          <option value="anniversary">Anniversary</option>                 
                          <option value="referral">Referral</option>
                          <option value="uploadDesign">Upload Design</option>
                          <option value="other">Other</option>              
                                   
                  </select>
                  
                </FormGroup>
              </Col>
          
              <Col sm={4}>
                <FormGroup>
                  <Label>Voucher Name</Label>
                  <input
                    type="text"
                    required
                    value={param.voucher_name}
                    className="form-control h-auto py-2 px-6"
                    name="voucher_name"
                    onChange={(e) => setParam({ ...param, voucher_name: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Voucher Heading</Label>
                  <input
                    type="text"
                    required
                    value={param.voucher_heading}
                    className="form-control h-auto py-2 px-6"
                    name="voucher_heading"
                    onChange={(e) => setParam({ ...param, voucher_heading: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Voucher Value (Ex: ₹ 750 / 10%)</Label>
                  <input
                    type="text"
                    required
                    value={param.voucher_value}
                    className="form-control h-auto py-2 px-6"
                    name="voucher_value"
                    onChange={(e) => setParam({ ...param, voucher_value: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <FormGroup>
                  <Label>Start Date</Label>
                  <TextField
                    type="date"
                    required
                    value={param.start_date}
                    className="form-control"
                    onChange={(e) => {
                      setParam({
                        ...param,
                        start_date: e.target.value,
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <FormGroup>
                  <Label>End Date</Label>
                  <TextField
                    type="date"
                    required
                    value={param.end_date}
                    className="form-control"
                    onChange={(e) => {
                      setParam({
                        ...param,
                        end_date: e.target.value,
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormGroup>
              </Col>
              {param.voucher_session_type === "duration in days" ?    <Col sm={4}>
                <FormGroup>
                  <Label>Duration in days</Label>
                  <input
                    type="number"
                    required
                    value={param.duration}
                    className="form-control h-auto py-2 px-6"
                    name="voucher_value"
                    onChange={(e) => setParam({ ...param, duration: e.target.value })}
                    ref={register} />
                  
                </FormGroup>
              </Col> : null}
              {param.voucher_session_type === "datetime" ? 
          <>
              <Col sm={3}>
                <FormGroup>
                  <Label>Redeem Start Date</Label>
                  <TextField
                    type="date"
                    required
                    value={param.redeem_start_date}
                    className="form-control"
                    onChange={(e) => {
                      setParam({
                        ...param,
                        redeem_start_date: e.target.value,
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <FormGroup>
                  <Label>Redeem End Date</Label>
                  <TextField
                    type="date"
                    required
                    value={param.redeem_end_date}
                    className="form-control"
                    onChange={(e) => {
                      setParam({
                        ...param,
                        redeem_end_date: e.target.value,
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormGroup>
              </Col>
              </>
            
               : null }
                <Col sm={1}>
                <FormGroup>
                  <Label>Disable</Label>
                  <input
                    type="checkbox"
                    checked={param.disable}
                    className="form-control h-auto py-2 px-6"
                    name="disable"
                    onChange={(e) => setParam({ ...param, disable: !param.disable })}
                    ref={register}
                  />
                </FormGroup>
              </Col>
              <Col sm={4} className="d-flex">
              <FormGroup>
                  <Label>
                  Voucher Image (350X170) {imageValidation1 ? <label className="text-danger">*Required</label> : null}<br />
                  { (imageObj.image != "" && imageObj.image !=undefined) ? 
                    <img id="updateImageshow"
                    width="100"
                    src={API_BASE_URL + "CustomerVoucher/" + imageObj.image}
                  ></img> : null    }
                    <input
                      id="updateImage"
                      type="file"
                      multiple={false}
                      accept="image/*"
                      name="Choose Image"
                      onChange={(e)=>  {
                          var reader = new FileReader();

                          reader.onload = function (e) {
                             setImageObj({ ...imageObj, image: e.target.result}) 
                            document.getElementById('updateImageshow').src=""+e.target.result+"";
                          }
                          reader.readAsDataURL(e.target.files[0]);
                           setImageValidation1(false);
                    }}
                    />
                  </Label>
                  </FormGroup>
              </Col>

              <Col sm={4} className="d-flex">
              <FormGroup>
                  <Label>
                  Voucher Banner (1110X320) {imageValidation2 ? <label className="text-danger">*Required</label> : null} <br />
                  { (imageObj.banner != "" && imageObj.banner !=undefined) ? 
                    <img id="updateImageshowBanner"
                    width="100"
                    src={API_BASE_URL + "CustomerVoucherBanner/" + imageObj.banner}
                  ></img> : null    }
                    <input
                      id="updateImageBanner"
                      type="file"
                      multiple={false}
                      accept="image/*"
                      name="Choose Image"
                      onChange={(e)=>  {
                          var reader = new FileReader();
                          reader.onload = function (e) {
                              setImageObj({ ...imageObj, banner: e.target.result})
                            document.getElementById('updateImageshowBanner').src=""+e.target.result+"";
                          }
                          reader.readAsDataURL(e.target.files[0]);
                           setImageValidation2(false);
                    }}
                    />
                  </Label>
                  </FormGroup>
              </Col>
              

             
              {param.voucher_type != "" ? 
              <Col sm={12} className="d-flex w-100">
                <FormGroup>
                  <br/>
                  <br/>
                  <Label>SMS Template <label className="text-danger">*</label></Label>   

                    {param.voucher_type == "first time" ?                            

                  <div className="d-flex flex-wrap align-items-center">Dear Customer, you have just got a <input
                    type="text"
                    value={smsParam.first_time_voucher_1}
                    style={{ width:'24%' }}
                    maxLength="30"
                    className="form-control h-auto py-2 px-4 mb-3 mx-2"
                    name="voucher_sms"
                    onChange={(e) => setSmsParam({ ...smsParam, first_time_voucher_1: e.target.value })}
                    onBlur={(e) => {setSMSTemplate()}}
                    ref={register}
                  /> gift 
                  <input
                    type="text"
                     value={smsParam.fist_time_voucher_2}
                       maxLength="30"
                    style={{ width:'24%' }}
                    className="form-control h-auto py-2 px-4 mb-3 mx-2"
                    name="voucher_sms"
                    onChange={(e) => setSmsParam({ ...smsParam, fist_time_voucher_2: e.target.value })}
                    onBlur={(e) => {setSMSTemplate()}}
                    ref={register}
                  /> from {companyName.toUpperCase()}. Validity (<input
                    type="text"
                    value={smsParam.fist_time_voucher_3}
                    style={{ width:'24%' }}
                      maxLength="30"
                    className="form-control h-auto py-2 px-4 mb-3 mx-2"
                    name="voucher_sms"
                    onChange={(e) => setSmsParam({ ...smsParam, fist_time_voucher_3: e.target.value })}
                     onBlur={(e) => {setSMSTemplate()}}
                    ref={register}
                  />). T and C apply.</div> : ""
                  }


                  {param.voucher_type == "birthday" ?                            

                  <div className="d-flex flex-wrap align-items-center">Dear Customer, {companyName.toUpperCase()} wishes u Happy Birthday. Lets make it special by  <input
                    type="text"
                    value={smsParam.birthday_voucher_1}
                    style={{ width:'24%' }}
                    maxLength="30"
                    className="form-control h-auto py-2 px-4 mb-3 mx-2"
                    name="voucher_sms"
                    onChange={(e) => setSmsParam({ ...smsParam, birthday_voucher_1: e.target.value })}
                    onBlur={(e) => {setSMSTemplate()}}
                    ref={register}
                  /> . Validity (<input
                    type="text"
                    value={smsParam.birthday_voucher_2}
                    style={{ width:'24%' }}
                      maxLength="30"
                    className="form-control h-auto py-2 px-4 mb-3 mx-2"
                    name="voucher_sms"
                    onChange={(e) => setSmsParam({ ...smsParam, birthday_voucher_2: e.target.value })}
                     onBlur={(e) => {setSMSTemplate()}}
                    ref={register}
                  />). T and C apply.</div> : ""
                  }

                  {param.voucher_type == "anniversary" ?                            

                  <div className="d-flex flex-wrap align-items-center">Dear Customer, {companyName.toUpperCase()} wishes u Happy Anniversary. Lets make it special by  <input
                    type="text"
                    value={smsParam.anniversary_voucher_1}
                    style={{ width:'24%' }}
                    maxLength="30"
                    className="form-control h-auto py-2 px-4 mb-3 mx-2"
                    name="voucher_sms"
                    onChange={(e) => setSmsParam({ ...smsParam, anniversary_voucher_1: e.target.value })}
                    onBlur={(e) => {setSMSTemplate()}}
                    ref={register}
                  /> . Validity (<input
                    type="text"
                    value={smsParam.anniversary_voucher_2}
                    style={{ width:'24%' }}
                      maxLength="30"
                    className="form-control h-auto py-2 px-4 mb-3 mx-2"
                    name="voucher_sms"
                    onChange={(e) => setSmsParam({ ...smsParam, anniversary_voucher_2: e.target.value })}
                     onBlur={(e) => {setSMSTemplate()}}
                    ref={register}
                  />). T and C apply.</div> : ""
                  }


                   {param.voucher_type == "referral" ?                            

                  <div className="d-flex flex-wrap align-items-center">Dear Customer, thanks for referring
                   $$MemberName$$ to {companyName.toUpperCase()}. To honour, we offer <input
                    type="text"
                    value={smsParam.ref_voucher_2}
                    style={{ width:'24%' }}
                      maxLength="30"
                    className="form-control h-auto py-2 px-4 mb-3 mx-2"
                    name="voucher_sms"
                    onChange={(e) => setSmsParam({ ...smsParam, ref_voucher_2: e.target.value })}
                     onBlur={(e) => {setSMSTemplate()}}
                    ref={register}
                  /> gift
                    <input
                    type="text"
                    value={smsParam.ref_voucher_3}
                    style={{ width:'24%' }}
                      maxLength="30"
                    className="form-control h-auto py-2 px-4 mb-3 mx-2"
                    name="voucher_sms"
                    onChange={(e) => setSmsParam({ ...smsParam, ref_voucher_3: e.target.value })}
                     onBlur={(e) => {setSMSTemplate()}}
                    ref={register}
                  />. Validity (<input
                    type="text"
                    value={smsParam.ref_voucher_4}
                    style={{ width:'24%' }}
                      maxLength="30"
                    className="form-control h-auto py-2 px-4 mb-3 mx-2"
                    name="voucher_sms"
                    onChange={(e) => setSmsParam({ ...smsParam, ref_voucher_4: e.target.value })}
                     onBlur={(e) => {setSMSTemplate()}}
                    ref={register}
                  />). T and C apply.</div>
                  : ""
                  }

                     {param.voucher_type == "other" ?                            

                  <div className="d-flex flex-wrap align-items-center">Dear Customer,  
                  <input
                    type="text"
                    value={smsParam.other_voucher_1}
                    style={{ width:'24%' }}
                      maxLength="30"
                    className="form-control h-auto py-2 px-4 mb-3 mx-2"
                    name="voucher_sms"
                    onChange={(e) => setSmsParam({ ...smsParam, other_voucher_1: e.target.value })}
                     onBlur={(e) => {setSMSTemplate()}}
                    ref={register}
                  />
                   celebrate this special 
                   <input
                    type="text"
                    value={smsParam.other_voucher_2}
                    style={{ width:'24%' }}
                      maxLength="30"
                    className="form-control h-auto py-2 px-4 mb-3 mx-2"
                    name="voucher_sms"
                    onChange={(e) => setSmsParam({ ...smsParam, other_voucher_2: e.target.value })}
                     onBlur={(e) => {setSMSTemplate()}}
                    ref={register}
                  /> 
                   with 
                   <input
                    type="text"
                    value={smsParam.other_voucher_3}
                    style={{ width:'24%' }}
                      maxLength="30"
                    className="form-control h-auto py-2 px-4 mb-3 mx-2"
                    name="voucher_sms"
                    onChange={(e) => setSmsParam({ ...smsParam, other_voucher_3: e.target.value })}
                     onBlur={(e) => {setSMSTemplate()}}
                    ref={register}
                  />. Validity (
                     <input
                    type="text"
                    value={smsParam.other_voucher_4}
                    style={{ width:'24%' }}
                      maxLength="30"
                    className="form-control h-auto py-2 px-4 mb-3 mx-2"
                    name="voucher_sms"
                    onChange={(e) => setSmsParam({ ...smsParam, other_voucher_4: e.target.value })}
                     onBlur={(e) => {setSMSTemplate()}}
                    ref={register}
                  />).
                      Team {companyName.toUpperCase()}. T and C apply.
                   </div>
                     : ""
                     }

                     
                   {param.voucher_type == "uploadDesign" ?                            

                  <div className="d-flex flex-wrap align-items-center">Dear Customer,  thanks for sharing designs. We appreciate and offer 
                  <input
                    type="text"
                    value={smsParam.design_voucher_1}
                    style={{ width:'24%' }}
                      maxLength="30"
                    className="form-control h-auto py-2 px-4 mb-3 mx-2"
                    name="voucher_sms"
                    onChange={(e) => setSmsParam({ ...smsParam, design_voucher_1: e.target.value })}
                     onBlur={(e) => {setSMSTemplate()}}
                    ref={register}
                  />
                   gift 
                 <input
                    type="text"
                    value={smsParam.design_voucher_2}
                    style={{ width:'24%' }}
                      maxLength="30"
                    className="form-control h-auto py-2 px-4 mb-3 mx-2"
                    name="voucher_sms"
                    onChange={(e) => setSmsParam({ ...smsParam, design_voucher_2: e.target.value })}
                     onBlur={(e) => {setSMSTemplate()}}
                    ref={register}
                  />
                   . Validity (
                     <input
                    type="text"
                    value={smsParam.design_voucher_3}
                    style={{ width:'24%' }}
                      maxLength="30"
                    className="form-control h-auto py-2 px-4 mb-3 mx-2"
                    name="voucher_sms"
                    onChange={(e) => setSmsParam({ ...smsParam, design_voucher_3: e.target.value })}
                     onBlur={(e) => {setSMSTemplate()}}
                    ref={register}
                  />
                     ).
                  
                  </div> : ""
                  }
                </FormGroup>
              </Col>
              : ""}
            </Row>
            {param.voucher_sms != "" ? 
            <div>
             <FormGroup className="d-flex w-100">
               <h3>SMS Template Preview</h3>
            </FormGroup>
           <FormGroup className="d-flex w-100">
              <h5 className="d-flex" style={{fontSize:"11px"}}>Dear Customer,   <p className="d-flex" dangerouslySetInnerHTML={{ __html:voucher_sms_show }}
          /></h5>
            </FormGroup>
            </div> : ""}
            {success ? <Alert color="success">Saved Successfully</Alert> : null}
             {successDelete ? <Alert color="danger">{loadDeleteMsg}</Alert> : null}
          </div>
          <div className="card-footer text-right">
            <a href="/branch/voucher" className="btn btn-secondary mr-2">
              Cancel
            </a>
           {id && (
              loadDelete ? <button type="button" className="btn btn-danger mr-2">
                Deleting..
              </button> : 
               <button type="button" onClick={handleDelete} className="btn btn-danger mr-2">
                Delete
              </button>
            )}
          
            {loadInser ? 
             <button type="button" className="btn btn-primary ">
              Saving..
            </button> :  <button type="submit" className="btn btn-primary ">
              Save
            </button>}
          </div>
        </div>
      </Form>
    </>
  );
};

export default AddVoucher;
