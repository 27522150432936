import React, { Fragment, useEffect, useState } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { postMethod } from "../../../../_services/_postMethod";
import Loader from "../../../modules/loader";
import { API_BASE_URL } from "../../../../_services/constant";
import { post } from "axios";
import { Button, Form, FormGroup, Label, FormText, Row, Col, Alert,Table  } from "reactstrap";
import { Card } from "@material-ui/core";
import { useForm } from "react-hook-form";
const Tab_to_scan_banner = (props) => {
  
  const [load, setLoad] = React.useState(false);
  const [success, setSuccess] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const [bannerList, setBannerList] = useState([]);
  const [loadInser, setLoadInsert] = useState(false);
  const [param, setParam] = useState({  
    image_path: "",
  });
  
  const addUser = (values) => {
    let file = document.getElementById("uploadImage");
    let files = file.files;
    let formData = new FormData();

    if (files.length > 0) {
      for (var j = 0; j < files.length; j++) {
        let file_name = "image-" + createGuid();
        
        // Get the file extension
        let extension = files[j].name.substring(files[j].name.lastIndexOf("."));
        
        // Change .jpeg to .jpg
        if (extension.toLowerCase() === ".jpeg") {
          extension = ".jpg";
        }
        
        // Construct the image path with the modified extension
        values.image_path = file_name + extension;
        param.image_path = file_name + extension;
        
        // Append the file with the new file name
        formData.append("files", files[j], file_name + extension);
      }









      // for (var j = 0; j < files.length; j++) {     

      //   let file_name = "image-" + createGuid();
      //   param.image_path = file_name+""+files[j].name.substring(files[j].name.lastIndexOf("."));
      //   formData.append("files", files[j], file_name);
      // }
    }
     setLoadInsert(true);
    postMethod("api/masters/customer/tabtoscanBannerinsert", {image_path :param.image_path}).then((data) => {   
      
        if (files.length > 0) {
         uploadImageFunc(formData);
        } else {
          setSuccess(false);
           setLoadInsert(false);
          setTimeout(() => {
          window.location.reload();
          }, 2000);
        }
      
    });
  };

  const uploadImageFunc = (formData) => {
    const url = API_BASE_URL + `api/masters/customer/UploadTabToScanBanner`;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "auth-token": localStorage.getItem("jwl_token"),
      },
    };

    post(url, formData, config).then(
      (response) => {
        setSuccess(true);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      (error) => {
         setLoadInsert(false);
        setSuccess(false);
      }
    );
  };

  function createGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  const handleDelete = (tran_id) => {
    var proceed = window.confirm("Do you Really Wish to Delete this Banner..?!");

    if (proceed) {
      postMethod("api/masters/customer/tabtoscanBannerDelete", { tran_id: tran_id }).then((data) => {       
          setSuccessDelete(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);     
      });
    }
  };

  useEffect(() => {
    setLoad(true);
    postMethod("api/masters/customer/tabtoscanBannerBrowse", {}).then((data) => {    
      setBannerList(data.data);
      setLoad(false);
    });
  }, []);

  return (
    <>
      {load ? <Loader /> : null}
      <div className="card card-custom gutter-b">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">Tab To Scan Banner</h3>
          </div>
         
        </div>
        <div className="card-body">
        <Form onSubmit={handleSubmit(addUser)}> 
          <div className="card-body">
            <Row>
             
             

              <Col sm={4} className="d-flex">
                <Card body className="col-md-12">
                  <Label>
                  Banners <br />
                  { (param.logo != "" && param.logo !=undefined) ? 
                    <img id="banner"
                    width="100"
                    src={API_BASE_URL + "taptoscanbanner/" + param.logo}
                  ></img> : null    }
                    <input
                      id="uploadImage"
                      type="file"
                      multiple={true}
                      accept="image/*"
                      name="Choose Image"
                      onChange={(e)=>  {
                        var reader = new FileReader();
                        reader.onload = function (e) {
                          setParam({ ...param, logo: e.target.result})
                          document.getElementById('banner').src=""+e.target.result+"";  
                        }
                        reader.readAsDataURL(e.target.files[0]);
                      }}
                    />
                  </Label>
                </Card>
              </Col>
             
            </Row>
            {success ? <Alert color="success">Saved Successfully</Alert> : null}
            {successDelete ? <Alert color="danger">Deleted</Alert> : null}
          </div>
          <div className="card-footer text-right">
            <a href="/admin/company" className="btn btn-secondary mr-2">
              Cancel
            </a>           
            <button type="submit" className="btn btn-primary ">
              Submit
            </button>
          </div>
       
      </Form>
      <Table>
      <thead>
        <tr>
          <th>#</th> 
          <th>Image</th>
          <th>Action</th> 
          </tr>
      </thead> 
      <tbody>
        {bannerList.length > 0 ?  
        bannerList.map((obj, i) => {
          return (
            <tr key={i+1}>
              <th scope="row">{i}</th>
              <td><img src={API_BASE_URL + "tabBanner/" + obj.image_path} width="100px"></img> </td>
              <td> <a href="#" onClick={()=>handleDelete(obj.tran_id)}>X</a></td>        
           </tr>
            
          );
        })
        
      :
      <tr className="d-flex justify-content-center w-100">
        <td colSpan="3">No Data</td>
      </tr>
        }
        
      </tbody>
    </Table>
        </div>
      </div>
    </>
  );
};

export default Tab_to_scan_banner;
