import withAutoplay from "react-awesome-slider/dist/autoplay";
import React, { useState, useEffect } from "react";
import AwesomeSlider from "react-awesome-slider";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";


import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { postMethod } from "../../../../_services/_postMethod";
import { API_BASE_URL } from "../../../../_services/constant";
import { Label } from "@mui/icons-material";
import { Input } from "@material-ui/icons";
const AutoplaySlider = withAutoplay(AwesomeSlider);
export default function Design(props) {
  {
    /* ----------------------Open Design------------------------------------- */
  }

  const [customerInformation, setCustomerInformation] = useState({
    customer_id: "",
    full_name: "",
    mobile: "",
    total_visit: "",
    last_visit: "",
    gender: "",
    dob: "",
    doa: "",
    ref_name: "",
    category_name: "",
    area_name: "",
    ref_mobile: "",
    staff_name: "",
    profession: "",
    address: "",
    branch_name: "",
  });
  const [tabIndex, setTabIndex] = useState(0);
  const [slideIndex, setSlideIndex] = useState(0);
  const [slideIndexExhibition, setSlideIndexExhibition] = useState(0);
  const [slideIndexWishlist, setSlideIndexWishlist] = useState(0);
  const [HistoryList, setHistoryList] = React.useState([]);
  const [UploadHistoryList, setUploadHistoryList] = React.useState([]);
  const [WishlistHistoryList, setWishlistHistoryList] = React.useState([]);
  const [ExhibitionHistoryList, setExhibitionHistoryList] = React.useState([]);

  const [showDesign, setShowDesign] = useState(false);

  function getCustomerDetails(mob) {
    let temparam = {
      mobile: mob,
    };
    postMethod("api/customervisit/getCustomerDetails", temparam).then(
      (dataD) => {
        var data = dataD.data;

        setCustomerInformation({
          ...customerInformation,

          full_name: data.full_name,
          mobile: data.mobile,
          total_visit: data.total_visit,
          last_visit: data.last_visit,
          gender: data.gender,
          dob: data.dob == null ? "N/A" : data.dob,
          doa: data.doa == null ? "N/A" : data.doa,
          ref_name: data.ref_name,
          ref_mobile: data.ref_mobile,
          category_name: data.category_name,
          area_name: data.area_name,
          staff_name: data.staff_name,
          profession: data.profession,
          address: data.address,
          branch_name: data.branch_name,
        });
        getCustomerHistory(data.customer_id);
        getCustomerUploadHistory(data.customer_id);
        getCustomerExhibitionHistory(data.customer_id);
        getCustomerWishlistHistory(data.customer_id);
      }
    );
  }

  const getCustomerHistory = (customer_id) => {
    let temparam = {
      customer_id: customer_id,
    };
    postMethod("api/customervisit/getCustomerHistory", temparam).then(
      (data) => {
        setHistoryList(data.data);
      }
    );
  };
  const getCustomerUploadHistory = (customer_id) => {
    let temparam = {
      customer_id: customer_id,
    };
    postMethod("api/customervisit/getCustomerUploadHistory", temparam).then(
      (data) => {
        setUploadHistoryList(data.data);
      }
    );
  };
  const getCustomerExhibitionHistory = (customer_id) => {
    let temparam = {
      customer_id: customer_id,
    };
    postMethod("api/customervisit/getCustomerExhibitionHistory", temparam).then(
      (data) => {
        setExhibitionHistoryList(data.data);
        alert("shivam :: " + JSON.stringify(data.data));
      }
    );
  };
  const getCustomerWishlistHistory = (customer_id) => {
    let temparam = {
      customer_id: customer_id,
    };
    postMethod("api/customervisit/getCustomerWishlistHistory", temparam).then(
      (data) => {
        setWishlistHistoryList(data.data);
        alert("tyagi :: " + JSON.stringify(data.data));
      }
    );
  };

  function openDesignhandler(num) {
    setShowDesign(true);
    getCustomerDetails(num);
    // console.log("shivam :: " + num);
  }

  return (
    <>
      <Button
        className="btn btn-success"
        onClick={() => openDesignhandler(props.mob)}
      >
        DESIGN
      </Button>

      {/* ----------------------Open Design------------------------------------- */}

      <Modal
        isOpen={showDesign}
        zIndex="9999999"
        size="lg"
        backdrop="static"
        centered="true"
        keepMounted
        fullWidth
        maxWidth="md"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="dialog-border">
          <ModalBody>
            <div>
              <Tabs
                selectedIndex={tabIndex}
                onSelect={(index) => setTabIndex(index)}
              >
                <TabList>
                  <Tab>My Design</Tab>
                  <Tab>Wishlist</Tab>
                  <Tab>Exhibition</Tab>
                </TabList>

                <TabPanel>
                  <div className="container-fluid">
                    {UploadHistoryList && UploadHistoryList.length !== 0 ? (
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <AutoplaySlider
                            onTransitionEnd={(slide) =>
                              setSlideIndex(slide.currentIndex)
                            }
                            mobileTouch={true}
                            bullets={false}
                          >
                            {UploadHistoryList &&
                              UploadHistoryList.map((prop, i) => {
                                return (
                                  <div
                                    data-src={
                                      `${API_BASE_URL}CustomerUploads/` +
                                      prop.image_path
                                    }
                                  />
                                );
                              })}
                          </AutoplaySlider>
                        </div>
                        <div
                          className="col-md-6 mt-2"
                          style={{ overflowY: "auto" }}
                        >
                         
                          <Card
                            sx={{ minWidth: "290px", maxHeight: "290px" }}
                            style={{ overflowY: "auto" }}
                          >
                            <CardContent>
                              <Row>
                                
                                <Col>
                                  <label style={{ fontSize: "13px" }}>
                                    <b>Product Category</b>
                                  </label>
                                  <p>
                                    {" "}
                                    {UploadHistoryList[slideIndex].category ||
                                      "N/A"}
                                  </p>
                                </Col>
                                {/* <Col>
                                  <label style={{ fontSize: "13px" }}>
                                    <b>Date</b>
                                  </label>
                                  <p>
                                    {UploadHistoryList[slideIndex].date ||
                                      "N/A"}
                                  </p>
                                </Col> */}
                              </Row>
                              <Row>
                                <Col>
                                  <label style={{ fontSize: "13px" }}>
                                    <b>Interest</b>
                                  </label>
                                  <p>
                                    {UploadHistoryList[slideIndex].interest ||
                                      "N/A"}
                                  </p>
                                </Col>
                                <Col>
                                  <label style={{ fontSize: "13px" }}>
                                    <b>Staff-Name</b>
                                  </label>
                                  <p>
                                    {UploadHistoryList[slideIndex].staff_name ||
                                      "N/A"}
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <label style={{ fontSize: "13px" }}>
                                    <b>Product Code</b>
                                  </label>
                                  <p>
                                    {UploadHistoryList[
                                      slideIndex
                                    ].hasOwnProperty("sku")
                                      ? UploadHistoryList[slideIndex].sku
                                      : "N/A"}
                                  </p>
                                </Col>
                                <Col>
                                  <label style={{ fontSize: "13px" }}>
                                    <b>Product Name</b>
                                  </label>
                                  <p>N/A</p>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <label style={{ fontSize: "13px" }}>
                                    <b>Remark</b>
                                  </label>
                                </Col>
                                <Col>
                                  <textarea
                                    style={{ overflow: "auto" }}
                                    cols="30"
                                    rows="3"
                                    value={
                                      UploadHistoryList[
                                        slideIndex
                                      ].hasOwnProperty("remarks")
                                        ? UploadHistoryList[slideIndex].remarks
                                        : "N/A"
                                    }
                                  ></textarea>
                                </Col>
                              </Row>
                            </CardContent>
                          </Card>
                        </div>
                      </div>
                    ) : (
                      <>
                        <h3>No Image</h3>
                      </>
                    )}
                  </div>
                </TabPanel>

                <TabPanel>
                  <div className="container-fluid">
                    {WishlistHistoryList && WishlistHistoryList.length !== 0 ? (
                      <div className="row">
                        <div className="col-md-6">
                          <AutoplaySlider
                            onTransitionEnd={(slide) =>
                              setSlideIndexWishlist(slide.currentIndex)
                            }
                            mobileTouch={true}
                            bullets={false}
                          >
                            {WishlistHistoryList &&
                              WishlistHistoryList.map((prop, i) => {
                                return (
                                  <div
                                    data-src={
                                      `${API_BASE_URL}Images/` + prop.image_path
                                    }
                                  />
                                );
                              })}
                          </AutoplaySlider>
                        </div>
                        <div
                          className="col-md-6 mt-2"
                          style={{ overflowY: "auto" }}
                        >
                         
                          <Card
                            sx={{ minWidth: "290px", maxHeight: "290px" }}
                            style={{ overflowY: "auto" }}
                          >
                            <CardContent>
                              <Row>
                               
                                <Col>
                                  <label style={{ fontSize: "13px" }}>
                                    <b>Product Category</b>
                                  </label>
                                  <p>
                                    {" "}
                                    {WishlistHistoryList[slideIndexWishlist]
                                      .category || "N/A"}
                                  </p>
                                </Col>

                                <Col>
                                  <label style={{ fontSize: "13px" }}>
                                    <b>Date</b>
                                  </label>
                                  <p>
                                    {WishlistHistoryList[slideIndexWishlist]
                                      .date || "N/A"}
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <label style={{ fontSize: "13px" }}>
                                    <b>Interest</b>
                                  </label>
                                  <p>
                                    {WishlistHistoryList[slideIndexWishlist]
                                      .interest || "N/A"}
                                  </p>
                                </Col>
                                <Col>
                                  <label style={{ fontSize: "13px" }}>
                                    <b>Staff-Name</b>
                                  </label>
                                  <p>
                                    {WishlistHistoryList[slideIndexWishlist] ||
                                      "N/A"}
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <label style={{ fontSize: "13px" }}>
                                    <b>Product Code</b>
                                  </label>
                                  <p>
                                    {UploadHistoryList[
                                      slideIndex
                                    ].hasOwnProperty("sku")
                                      ? WishlistHistoryList[slideIndexWishlist]
                                          .sku
                                      : "N/A"}
                                  </p>
                                </Col>
                                <Col>
                                  <label style={{ fontSize: "13px" }}>
                                    <b>Product Name</b>
                                  </label>
                                  <p>N/A</p>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <label style={{ fontSize: "13px" }}>
                                    <b>Remark</b>
                                  </label>
                                </Col>
                                <Col>
                                  <textarea
                                    style={{ overflow: "auto" }}
                                    cols="30"
                                    rows="3"
                                    value={
                                      WishlistHistoryList[
                                        slideIndexWishlist
                                      ].hasOwnProperty("remarks")
                                        ? WishlistHistoryList[
                                            slideIndexWishlist
                                          ].remarks
                                        : "N/A"
                                    }
                                  ></textarea>
                                </Col>
                              </Row>
                            </CardContent>
                          </Card>
                        </div>
                      </div>
                    ) : (
                      <>
                        <h3>No Image</h3>
                      </>
                    )}
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="container-fluid">
                    {ExhibitionHistoryList &&
                    ExhibitionHistoryList.length !== 0 ? (
                      <div className="row">
                        <div className="col-md-6">
                          <AutoplaySlider
                            onTransitionEnd={(slide) =>
                              setSlideIndexExhibition(slide.currentIndex)
                            }
                            mobileTouch={true}
                            bullets={false}
                          >
                            {ExhibitionHistoryList &&
                              ExhibitionHistoryList.map((prop, i) => {
                                return (
                                  <div
                                    data-src={
                                      `${API_BASE_URL}Images/` + prop.image_path
                                    }
                                  />
                                );
                              })}
                          </AutoplaySlider>
                        </div>
                        <div className="col-md-3" style={{ overflowY: "auto" }}>
                         

                          <Card
                            sx={{ minWidth: "290px", maxHeight: "290px" }}
                            style={{ overflowY: "auto" }}
                          >
                            <CardContent>
                              <Row>
                                <Col>
                                  <label style={{ fontSize: "13px" }}>
                                    <b>Date</b>
                                  </label>
                                  <p>
                                    {ExhibitionHistoryList[slideIndexExhibition]
                                      .date || "N/A"}
                                  </p>
                                </Col>
                                <Col>
                                  <label style={{ fontSize: "13px" }}>
                                    <b>Product Category</b>
                                  </label>
                                  <p>
                                    {" "}
                                    {ExhibitionHistoryList[slideIndexExhibition]
                                      .category || "N/A"}
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <label style={{ fontSize: "13px" }}>
                                    <b>Interest</b>
                                  </label>
                                  <p>
                                    {ExhibitionHistoryList[slideIndexExhibition]
                                      .interest || "N/A"}
                                  </p>
                                </Col>
                                <Col>
                                  <label style={{ fontSize: "13px" }}>
                                    <b>Staff-Name</b>
                                  </label>
                                  <p>
                                    {ExhibitionHistoryList[
                                      slideIndexExhibition
                                    ] || "N/A"}
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <label style={{ fontSize: "13px" }}>
                                    <b>Product Code</b>
                                  </label>
                                  <p>
                                    {ExhibitionHistoryList[
                                      slideIndexExhibition
                                    ].hasOwnProperty("sku")
                                      ? ExhibitionHistoryList[
                                          slideIndexExhibition
                                        ].product_code
                                      : "N/A"}
                                  </p>
                                </Col>
                                <Col>
                                  <label style={{ fontSize: "13px" }}>
                                    <b>Product Name</b>
                                  </label>
                                  <p>N/A</p>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <label style={{ fontSize: "13px" }}>
                                    <b>Remark</b>
                                  </label>
                                </Col>
                                <Col>
                                  <textarea
                                    style={{ overflow: "auto" }}
                                    cols="30"
                                    rows="3"
                                    value={
                                      ExhibitionHistoryList[
                                        slideIndexExhibition
                                      ].hasOwnProperty("remarks")
                                        ? ExhibitionHistoryList[
                                            slideIndexExhibition
                                          ].remarks
                                        : "N/A"
                                    }
                                  ></textarea>
                                </Col>
                              </Row>
                            </CardContent>
                          </Card>
                        </div>
                      </div>
                    ) : (
                      <>
                        <h3>No Image</h3>
                      </>
                    )}
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn btn-danger"
              onClick={() => {
                setShowDesign(false);
              }}
              color="secondary"
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
}
