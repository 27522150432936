import React, { useEffect, useState } from "react";
import background from "../../../_assets/media/login_back.jpg";
import Logo from "../../../_assets/media/favicon.png";
import { useForm } from "react-hook-form";
import { Alert } from "reactstrap";

import logo from "../../../_assets/media/home/logo.png";
import header1 from "../../../_assets/media/home/header1.jpg";
import header2 from "../../../_assets/media/home/header2.jpg";
import header3 from "../../../_assets/media/home/header3.jpg";
import { postMethod } from "../../../_services/_postMethod";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import NumberFormat from 'react-number-format';
import { API_BASE_URL } from "../../../_services/constant";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  //  backgroundColor: '#fff'
  },
}));
const BusinessWelcome = (props) => {
  const classes = useStyles(); 
  const [otp, setOtp] = useState(false);
  const { register, handleSubmit, errors } = useForm(); 
  const [isvalid, setValid] = useState(false);
  const [load, setLoad] = useState(false);
  const [otpVal, setotpVal] = useState(false);
  const [mobileVal, setmobileVal] = useState(false);
  const [mobile, setmobile] = useState("");
  const [branchLogo, setBranchLogo] = useState("");
  const [branchid, setBranchid] = useState("");
  const loginCustomer = (values) => {
    var mobile = values.mobile.trim().replace('+91','').replace(/ /g,'').replace(/_/g,''); 
    values.mobile = mobile;
   values.encrypt_id = branchid;
    if(mobile.length == 10){
    setLoad(true);
   
    if (otp) {
    
      postMethod(`auth/exhibition/token`, values).then((dataD) => { 
        var data = dataD.data[0];
        if (data) {         
          localStorage.setItem("jwl_login", true);          
          localStorage.setItem("type", "businessSession");
          localStorage.setItem("jwl_token", data.access_token);
          localStorage.setItem("jwl_username", data.full_name);
          localStorage.setItem("jwl_logo", data.logo);
          localStorage.setItem("jwl_brand_name", data.brand_name);
          localStorage.setItem("jwl_banner_path", data.banner_path);
          localStorage.setItem("jwl_customer_id", data.customer_id);
          localStorage.setItem("jwl_customer_branch_id", data.branch_id);
          localStorage.setItem("jwl_hide_price", data.hide_price);
          localStorage.setItem("cust_mobile", mobile);
         window.location = `/business-view/home`;
        } else {
          setotpVal(!otpVal);
          setValid(true);
        }
        setLoad(false);
      });
    } else {  
   
      values.mobile = mobile.trim().replace('+91','').replace(' ','').replace(' ','').replace(' ',''); 
      postMethod(`auth/exhibition/login`, values).then((dataD) => {
        var data = dataD.data[0];
        if (data.valid === true) {
          setOtp(true);
          setValid(false);
        } else {
          setOtp(false);
          setValid(true);
        }
        setLoad(false);
      });
    }
  }
  else{
    setmobileVal(true);
  }
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    var token = query.get('t');
    setBranchid(token)  
  
   setLoad(true);
    postMethod("public/customer_branch_details", { branch_id: token }).then((data) => {
      setBranchLogo(data.data[0].logo);
      setLoad(false);
    });  
  }, []);

  return (
    <React.Fragment>
        <Backdrop className={classes.backdrop} open={load} > 
    <CircularProgress color="inherit" />
      </Backdrop>
      <div className="customcontainer">
        <div className="row toppad3">
          <div className="col-sm-8 mcntr">
            <img src={API_BASE_URL + "BranchLogo/" + branchLogo} className="logo" />
          </div>
          <div className="col-sm-4"></div>
        </div>
        <div className="clr"></div>
        <div className="row pt-md-5 pt-3 mtoppad0">
          <div className="lgnleft p-0 pr-md-3">
            <img src={header1} className="img-fluid mainIMage" />
          </div>
          <div className="lgnright mtoppad3">
            <div className="tophead">
              <h4 className="d-none d-lg-block d-print-block">UNIQUE ACCESSORIES</h4>
              <h1 className="d-none d-lg-block d-print-block">
                Your Jewelry Collection
                <br />
                Forever
              </h1>
              <h4 className="mb-3">Crafting Exquisite Jewellery for you..</h4>
              <form onSubmit={handleSubmit(loginCustomer)} className="mt-4">
                <div className={"group subs " + (!otp ? "" : "hide")}>
                 
                  <NumberFormat  getInputRef ={register}
                  onValueChange={(e) =>{
                    setmobileVal(false);
                  }}                 
                  name="mobile" format="+91 ### ### ####" allowEmptyFormatting mask="_"/>
                  <span className="highlight"></span>
                  {mobileVal ? <p style={{color:"red"}}>Incorrect mobile no.</p> : null} 
                  <span className="bar"></span>
                </div>
                
                <div className={"group subs " + (!otp ? "hide" : "")}>
                  <input
                    className="form-control form-control-solid h-auto py-3 px-6"
                    ref={register}
                    type="number"
                    placeholder="Enter 6-digit OTP"
                    name="otp"
                    autoComplete="off"
                    style={{fontWeight: "600"}}
                    onClick={()=>{
                      setotpVal(false)}}
                  />
                  {otpVal ? <p style={{color:"red"}}>Incorrect OTP.</p> : null}
                </div>
                <div className="sbmt">
                  <input type="submit" value="Go" className="sbmtbtn" />
                </div>
              </form>
            </div>
            <div className="tophead d-none d-lg-block d-print-block">
              <div className="topheadl">
                <img src={header2} className="img-fluid" />
              </div>
              <div className="topheadr">
                <img src={header3} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {window.outerWidth > 600 ? <footer>
        <div className="container mcntr">
          <div className="row">
            <div className="col-sm-10">© 2020 maliramjewellers.com All Rights Reserved</div>
            <div className="col-sm-2">
              <div className="text-center center-block">
                <a href="https://www.facebook.com/bootsnipp">
                  <i id="social-fb" className="fa fa-facebook fa-2x social"></i>
                </a>
                <a href="https://plus.google.com/+Bootsnipp-page">
                  <i id="social-gp" className="fa fa-instagram fa-2x social"></i>
                </a>
                <a href="mailto:bootsnipp@gmail.com">
                  <i id="social-em" className="fa fa-linkedin fa-2x social"></i>
                </a>
                <a href="https://twitter.com/bootsnipp">
                  <i id="social-tw" className="fa fa-twitter fa-2x social"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer> : null}
    </React.Fragment>
  );
};

export default BusinessWelcome;
