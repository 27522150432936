import React, { Fragment, useEffect, useState } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { postMethod } from "../../../../_services/_postMethod";
import Loader from "../../../modules/loader";
import * as XLSX from "xlsx";
import moment from "moment";
import axios from "axios";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
} from "reactstrap";
import Whatsapp from "../dashboard/Whatsapp";




// --------------------------------------




// const whts = (props) => {
//   const { custInfo, className } = props;








//   const [selectedRows, setSelectedRows] = useState([]);
//   const [selectAll, setSelectAll] = useState(false);

//   const columns = [
//     {
//       key: "checkbox",
//       text: (
//         <input
//           type="checkbox"
//           checked={selectAll}
//           onChange={handleSelectAll}
//         />
//       ),
//       cell: (record) => (
//         <input
//           type="checkbox"
//           checked={selectedRows.includes(record.mobile)}
//           onChange={() => handleSelectRow(record.mobile)}
//         />
//       ),
//       sortable: false
//     },
//     {
//       key: "full_name",
//       text: "Name",
//       sortable: true
//     },
//     {
//       key: "email",
//       text: "Email",
//       sortable: true
//     },
//     {
//       key: "mobile",
//       text: "Mobile",
//       sortable: true
//     }
//   ];

//   const config = {
//     page_size: 10,
//     length_menu: [10, 20, 50],
//     button: {
//       excel: false,
//       print: false,
//       extra: false
//     }
//   };

//   function handleSelectAll() {
//     setSelectAll(!selectAll);
//     if (!selectAll) {
//       setSelectedRows(data.map((item) => item.id));
//     } else {
//       setSelectedRows([]);
//     }
//   }

//   function handleSelectRow(id) {
//     if (selectedRows.includes(id)) {
//       setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
//     } else {
//       setSelectedRows([...selectedRows, id]);
//     }
//   }

//   const [modal, setModal] = useState(false);
//   const toggle = () => setModal(!modal);

  




// return(
//   <>
//   <Button color="primary" onClick={toggle}>
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           viewBox="0 0 48 48"
//           width="28px"
//           height="28px"
//         >
//           <path
//             fill="#fff"
//             d="M4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98c-0.001,0,0,0,0,0h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303z"
//           />
//           <path
//             fill="#fff"
//             d="M4.868,43.803c-0.132,0-0.26-0.052-0.355-0.148c-0.125-0.127-0.174-0.312-0.127-0.483l2.639-9.636c-1.636-2.906-2.499-6.206-2.497-9.556C4.532,13.238,13.273,4.5,24.014,4.5c5.21,0.002,10.105,2.031,13.784,5.713c3.679,3.683,5.704,8.577,5.702,13.781c-0.004,10.741-8.746,19.48-19.486,19.48c-3.189-0.001-6.344-0.788-9.144-2.277l-9.875,2.589C4.953,43.798,4.911,43.803,4.868,43.803z"
//           />
//           <path
//             fill="#cfd8dc"
//             d="M24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,4C24.014,4,24.014,4,24.014,4C12.998,4,4.032,12.962,4.027,23.979c-0.001,3.367,0.849,6.685,2.461,9.622l-2.585,9.439c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98c0.002-5.339-2.075-10.359-5.848-14.135C34.378,6.083,29.357,4.002,24.014,4L24.014,4z"
//           />
//           <path
//             fill="#40c351"
//             d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z"
//           />
//           <path
//             fill="#fff"
//             fill-rule="evenodd"
//             d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z"
//             clip-rule="evenodd"
//           />
//         </svg>
//       </Button>

//       <Modal
//         isOpen={modal}
//         zIndex="99999"
//         size="lg"
//         backdrop="static"
//         centered="true"
//       >
//         <ModalHeader>
//           <Row style={{ marginBottom: "10px" }}>
//             <Col sm="6">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 viewBox="0 0 48 48"
//                 width="28px"
//                 height="28px"
//               >
//                 <path
//                   fill="#fff"
//                   d="M4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98c-0.001,0,0,0,0,0h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303z"
//                 />
//                 <path
//                   fill="#fff"
//                   d="M4.868,43.803c-0.132,0-0.26-0.052-0.355-0.148c-0.125-0.127-0.174-0.312-0.127-0.483l2.639-9.636c-1.636-2.906-2.499-6.206-2.497-9.556C4.532,13.238,13.273,4.5,24.014,4.5c5.21,0.002,10.105,2.031,13.784,5.713c3.679,3.683,5.704,8.577,5.702,13.781c-0.004,10.741-8.746,19.48-19.486,19.48c-3.189-0.001-6.344-0.788-9.144-2.277l-9.875,2.589C4.953,43.798,4.911,43.803,4.868,43.803z"
//                 />
//                 <path
//                   fill="#cfd8dc"
//                   d="M24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,4C24.014,4,24.014,4,24.014,4C12.998,4,4.032,12.962,4.027,23.979c-0.001,3.367,0.849,6.685,2.461,9.622l-2.585,9.439c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98c0.002-5.339-2.075-10.359-5.848-14.135C34.378,6.083,29.357,4.002,24.014,4L24.014,4z"
//                 />
//                 <path
//                   fill="#40c351"
//                   d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z"
//                 />
//                 <path
//                   fill="#fff"
//                   fill-rule="evenodd"
//                   d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z"
//                   clip-rule="evenodd"
//                 />
//               </svg>
//               Whats-App Messenger
//             </Col>

//             <Col sm="6">
//               <i
//                 className="fa fa-close pull-right pointerShow"
//                 onClick={toggle}
//               ></i>
//             </Col>
//           </Row>
//         </ModalHeader>


//         <ModalBody>
//           <Row>
//           <ReactDatatable
//       config={config}
//       records={custInfo}
//       columns={columns}
//     />
//           </Row>


//           <Row>

//           </Row>
//         </ModalBody>

//       </Modal>
  
//   </>
// );


// }




// ----------------------------------------

const Customer = (props) => {
  const [grid, setGrid] = React.useState([]);
  const [load, setLoad] = React.useState(false);
  const [DownloadReady, setDownloadReady] = React.useState(false);
  const [DownloadLink, setDownloadLink] = React.useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(grid);
  const editRecord = (val) => {
    var temp = window.btoa(val);
    console.log(temp);
    props.history.push("/branch/customer/add?id=" + temp);
  };

  const [gridParam, setGridParam] = useState({});
  const columns = [
    {
      key: "full_name",
      text: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "mobile",
      text: "Mobile",
      align: "left",
      sortable: true,
    },
    {
      key: "type",
      text: "Type",
      sortable: true,
      align: "left",
    },
    {
      key: "gender",
      text: "Gender",
      sortable: true,
      align: "left",
    },
    {
      key: "dob",
      text: "D.O.B",
      sortable: true,
      align: "left",
    },
    {
      key: "doa",
      text: "D.O.A",
      sortable: true,
      align: "left",
    },
    {
      key: "category_name",
      text: "Category",
      sortable: true,
      align: "left",
    },
    {
      key: "staff_name",
      text: "Staff",
      sortable: true,
      align: "left",
    },
    {
      key: "created_on",
      text: "Join Date",
      sortable: true,
      align: "left",
      cell: (record) => {
        return dateConvert(record.created_on);
      },
    },

    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="btn btn-link btn-sm"
              onClick={() => editRecord(record.customer_id)}
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-edit"></i>
            </button>
          </Fragment>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: false,
  };
  const dateConvert = (date) => {
    const originalDate = new Date(date);

    const year = originalDate.getFullYear();
    const month = String(originalDate.getMonth() + 1).padStart(2, "0");
    const day = String(originalDate.getDate()).padStart(2, "0");
    const hours = String(originalDate.getHours()).padStart(2, "0");
    const minutes = String(originalDate.getMinutes()).padStart(2, "0");
    const seconds = String(originalDate.getSeconds()).padStart(2, "0");
    const ampm = originalDate.getHours() >= 12 ? "PM" : "AM";

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  };
  // const export_array =()=>{
  //   let arr='Name,Mobile,Type,Gender,D.O.B,D.O.A,Category,Staff,Join\r\n'
  //   let csvContent = "data:text/csv;charset=utf-8," +arr;
  //   // +arr+ grid.map(e => e.join(",")).join("\n");
  //   // let csvContent = "data:text/csv;charset=utf-8,";

  //   grid.forEach(function(rowArray) {
  //       var row=`${rowArray.full_name},${rowArray.mobile},${rowArray.type},${rowArray.gender},${rowArray.dob},${rowArray.doa},${rowArray.category_name},${rowArray.created_by},${dateConvert(rowArray.created_on)}`;
  //       csvContent += row + "\r\n";
  //   });
  //   var encodedUri = encodeURI(csvContent);
  //   setDownloadLink(encodedUri)
  //   setDownloadReady(true);
  //   // link.setAttribute("href", encodedUri);
  //   // link.setAttribute("download", "customers_data.csv");
  //   // Required for FF
  // }

  useEffect(() => {
    setLoad(true);
    postMethod("api/masters/customer/browse", gridParam).then((data) => {
      setGrid(data.data);
      setFilteredData(data.data);
      setLoad(false);
    });
  }, []);

  // -----------------

  // Function to handle search
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchQuery(value);
    const filtered = grid.filter((item) =>
      Object.values(item).some(
        (val) => typeof val === "string" && val.toLowerCase().includes(value)
      )
    );
    setFilteredData(filtered);
  };

  // Modify downloadExcel function to set DownloadLink
  const downloadExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Filtered Data");
    const excelFile = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "buffer",
    });
    const blob = new Blob([excelFile], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    setDownloadLink(url); // Set DownloadLink
    setDownloadReady(true);
  };

  
  // -----------------

  return (
    <>
      {load ? <Loader /> : null}
      <div className="card card-custom gutter-b">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">Customer</h3>
          </div>
          <div className="card-toolbar">
            {DownloadReady ? (
              <a
                href={DownloadLink}
                className="btn btn-sm btn-outline-theme font-weight-bold py-2 px-3 mx-3"
                download="customers_data.csv"
                target="_blank"
              >
                <i className="flaticon2-download"></i> Click Here to Download
              </a>
            ) : (
              <span
                onClick={() => {
                  downloadExcel();
                }}
                className="btn btn-sm btn-outline-theme font-weight-bold py-2 px-3 mx-3"
              >
                <i className="flaticon2-download"></i> Export
              </span>
            )}

            <a
              href="/branch/customer/add"
              className="btn btn-sm btn-outline-theme font-weight-bold py-2 px-3"
            >
              <i className="flaticon2-user"></i> Add Customer
            </a>
           <div className="mx-3">
           <Whatsapp custInfo={filteredData} />
           </div>
          </div>
        </div>
        <div className="card-body">
          <Row className="justify-content-end">
            <Col xs="auto" className="text-right">
              <Input
                type="text"
                placeholder="Search in records..."
                value={searchQuery}
                onChange={handleSearch}
                style={{ width: "200px" }}
              />
            </Col>
          </Row>

          <ReactDatatable
            config={config}
            records={filteredData}
            columns={columns}
          />
        </div>
      </div>
    </>
  );
};

export default Customer;








