// import React, { useEffect, useState } from "react";
// import { useForm } from "react-hook-form";
// import { useParams, useLocation } from "react-router-dom";
// import { Button, Form, FormGroup, Label, FormText, Row, Col, Alert, Table } from "reactstrap";
// import { API_BASE_URL } from "../../../../_services/constant";
// import { Card, TextField } from "@material-ui/core";
// import { post } from "axios";

// import {
//   postMethod,
//   validateMobile,
//   singleParam,
//   editUserGrid,
// } from "../../../../_services/_postMethod";
// import Loader from "../../../modules/loader";
// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

// const AddStockacceptance = (props) => {
//   let queryParams = useQuery();
//   const id = queryParams.get("id") == "" ? null : queryParams.get("id");
//   const { register, handleSubmit, errors } = useForm();
//   const [load, setLoad] = useState(false);
//   const [productlist, setProductlist] = useState([]);
//   const [branchlist, setBranchlist] = useState([]);
//   const [stockAcceptanceproducts, setstockAcceptanceproducts] = useState([]);
//   const [stockinproductParam, setStockinproductParam] = useState({
//     product_id: "",
//     product: "",
//     barcode: "",
//     description: "",
//     qty: "",
//     branch_id:""
//   });

//   const [param, setParam] = useState({
//     tran_id: id == null ? "0" : id,   
//     to_branch_id: 0,
//     from_branch_id:0,
//     date: "",
//     entry_no: "",
//     remarks: "",
//     status: "",
//     stock_in_products: [],
//   });
//   const [success, setSuccess] = useState(false);
//   const [successDelete, setSuccessDelete] = useState(false);

//   useEffect(() => {
//     if (id !== null) {
//       setLoad(true);
//       var temp = window.atob(id);
//       postMethod("api/transactions/stockAcceptance/preview", { tran_id: temp }).then((data) => {
//         setParam({
//           ...param,       
//           from_branch_id: data[0][0].from_branch_id,
//           status: data[0][0].status,
//           to_branch_id: data[0][0].to_branch_id,          
//           date: data[0][0].date.split("T")[0],
//           entry_no: data[0][0].entry_no,
//           remarks: data[0][0].remarks,
//           stock_in_products: data[1],
//         });
//         setstockAcceptanceproducts(data[1]);
//         setLoad(false);
//       });
//     }
//     postMethod("api/transactions/stockin/getproductlist", {}).then((data) => {
//       setProductlist(data.data);
//     });

//     postMethod("api/transactions/stockin/getbranchlist", {}).then((data) => {
//       setBranchlist(data.data);
//     });
//   }, []);

//   // check object is null or undefined
//   const checkObject = (obj) => {
//     return obj === undefined ? {} : Object.keys(obj).length === 0 && obj.constructor === Object;
//   };

//   const AddProduct = () => {
//     if (
//       stockinproductParam.product_id !== "" &&
//       (stockinproductParam.barcode !== "") !== "" &&
//       stockinproductParam.qty !== ""
//     ) {
//       stockAcceptanceproducts.push(stockinproductParam);
//       setStockinproductParam({
//         product_id: "",
//         product: "",
//         barcode: "",
//         qty: "",
//       });
//     }
//   };
//   const removeProduct = (i) => {
//     let tempArry = [...stockAcceptanceproducts];
//     tempArry.splice(i, 1);
//     setstockAcceptanceproducts(tempArry);
//   };

//   const _Save = () => {
//      let tempArrySave = [];
//     let tempArry = [...stockAcceptanceproducts];
//       tempArry.forEach(Obj => { 
//       if(Obj.accept == true)
//       {
//         tempArrySave.push({product_id:Obj.product_id,stp_id:Obj.tran_id,qty:Obj.rec_qty});
//       }
//       }); 

//     var temp = id == null ? "0" : window.atob(id);
//     let temparam = {
//       tran_id:0,
//       from_branch_id: param.from_branch_id, 
//       st_id: temp, 
//       date: param.date,
//       entry_no: param.entry_no,
//       remarks: param.remarks,
//       stock_acceptance_products: tempArrySave  
//     };
//     postMethod("api/transactions/stockAcceptance/insert", temparam).then((data) => {
//       if (data.valid) {
//         setSuccess(true);
//         setTimeout(() => {
//           props.history.push("/branch/stock-acceptance");
//         }, 2000);
//       }
//     });
//   };
//   const handleDelete = () => {
//     var proceed = window.confirm("Do you Really Wish to Delete this Entry..?!");
//     var temp = window.atob(id);
//     if (proceed) {
//       postMethod("api/transactions/stockAcceptance/delete", { tran_id: temp }).then((data) => {
//         console.log(data);
//         if (data.valid) {
//           setSuccessDelete(true);
//           setTimeout(() => {
//             props.history.push("/branch/stock-acceptance");
//           }, 2000);
//         }
//       });
//     }
//   };

//   return (
//     <>
//       {load ? <Loader /> : null}
//       <Form onSubmit={handleSubmit(_Save)}>
//         <input
//           type="hidden"
//           name="product_id"
//           value={id == null ? "0" : param.product_id}
//           ref={register}
//         />
//         <div className="card card-custom gutter-b">
//           <div className="card-header">
//             <div className="card-title">
//               <h3 className="card-label">Add Stock Acceptance</h3>
//             </div>
//           </div>
//           <div className="card-body">
//             <Row>
//               <Col sm={3}>
//                 <FormGroup>
//                   <Label>Date</Label>
//                   <TextField
//                     type="date"
//                     value={param.date}
//                     className="form-control"
//                     onChange={(e) => {
//                       setParam({
//                         ...param,
//                         date: e.target.value,
//                       });
//                     }}
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                   />
//                 </FormGroup>
//               </Col>
//               <Col sm={3}>
//                 <FormGroup>
//                   <Label>Entry No</Label>
//                   <input
//                     type="text"
//                     required
//                     value={param.entry_no}
//                     className="form-control h-auto py-2 px-6"
//                     name="entry_no"
//                     onChange={(e) => setParam({ ...param, entry_no: e.target.value })}
//                     ref={register}
//                   />
//                 </FormGroup>
//               </Col> 
//               <Col sm={12}>
//                 <FormGroup>
//                   <Label>Remarks</Label>
//                   <input
//                     type="text"
//                     value={param.remarks}
//                     className="form-control h-auto py-2 px-6"
//                     name="remarks"
//                     onChange={(e) => setParam({ ...param, remarks: e.target.value })}
//                     ref={register}
//                   />
//                 </FormGroup>
//               </Col>
//             </Row>

//             <Row>
//               <Col sm={12}>
//                 <Table bordered>
//                   <thead>
//                     <tr>
//                       <th>Product Code</th>
//                       <th>Product</th>
//                       <th>Image</th>
//                       <th>Qty</th>
//                       {param.status.toUpperCase() == "DONE" || param.status.toUpperCase() == "CANCEL" ? null :  <th>Action</th>}
//                     </tr>                  
//                   </thead>
//                   <tbody>
//                     {checkObject(stockAcceptanceproducts)
//                       ? null
//                       : stockAcceptanceproducts.map((obj, i) => {
//                           return (
//                             <tr key={i}>
//                               <td>
//                                 {i + 1}) {obj.product_code}
//                               </td>
//                               <td>{obj.product}</td>                              
//                               <td><img src={obj.url_image +""+obj.image_path} width="36px" alt="" />   </td>  
//                               <td>{obj.qty}</td>
//                               {param.status.toUpperCase() == "DONE" || param.status.toUpperCase() == "CANCEL" ? null: <td>
//                                  <input
//                                 type="checkbox"                  
//                                 defaultChecked={ obj.accept}
//                                  name="available"
//                                  onChange={(e) => {                               
//                                      stockAcceptanceproducts[i].accept = e.target.checked; 
//                                  }} 
//                                  />
//                               </td>}
//                             </tr>
//                           );
//                         })}
//                   </tbody>
//                 </Table>
//               </Col>
//             </Row>
//             {success ? <Alert color="success">Saved Successfully</Alert> : null}
//             {successDelete ? <Alert color="danger">Entery deleted</Alert> : null}
//           </div>
//           <div className="card-footer text-right">
//             <a href="/branch/stock-acceptance" className="btn btn-secondary mr-2">
//               Cancel
//             </a>
//            {param.status.toUpperCase() == "DONE" || param.status.toUpperCase() == "CANCEL" ? null:  <button type="submit" className="btn btn-primary ">
//               Submit
//             </button>}
          
//           </div>
//         </div>
//       </Form>
//     </>
//   );
// };

// export default AddStockacceptance;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useLocation } from "react-router-dom";
import { Button, Form, FormGroup, Label, FormText, Row, Col, Alert, Table } from "reactstrap";
import { API_BASE_URL } from "../../../../_services/constant";
import { Card, TextField } from "@material-ui/core";
import { post } from "axios";

import {
  postMethod,
  validateMobile,
  singleParam,
  editUserGrid,
} from "../../../../_services/_postMethod";
import Loader from "../../../modules/loader";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AddStockacceptance = (props) => {
  let queryParams = useQuery();
  const id = queryParams.get("id") == "" ? null : queryParams.get("id");
  const { register, handleSubmit, errors } = useForm();
  const [load, setLoad] = useState(false);
  const [productlist, setProductlist] = useState([]);
  const [branchlist, setBranchlist] = useState([]);
  const [stockAcceptanceproducts, setstockAcceptanceproducts] = useState([]);
  const [stockinproductParam, setStockinproductParam] = useState({
    product_id: "",
    product: "",
    barcode: "",
    description: "",
    qty: "",
    branch_id: ""
  });

  const [param, setParam] = useState({
    tran_id: id == null ? "0" : id,
    to_branch_id: 0,
    from_branch_id: 0,
    date: "",
    entry_no: "",
    remarks: "",
    status: "",
    stock_in_products: [],
  });

  const [success, setSuccess] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (id !== null) {
      setLoad(true);
      var temp = window.atob(id);
      postMethod("api/transactions/stockAcceptance/preview", { tran_id: temp }).then((data) => {
        setParam({
          ...param,
          from_branch_id: data[0][0].from_branch_id,
          status: data[0][0].status,
          to_branch_id: data[0][0].to_branch_id,
          date: data[0][0].date.split("T")[0],
          entry_no: data[0][0].entry_no,
          remarks: data[0][0].remarks,
          stock_in_products: data[1],
        });
        setstockAcceptanceproducts(data[1]);
        setLoad(false);
      });
    }
    postMethod("api/transactions/stockin/getproductlist", {}).then((data) => {
      setProductlist(data.data);
    });

    postMethod("api/transactions/stockin/getbranchlist", {}).then((data) => {
      setBranchlist(data.data);
    });
  }, []);

  const checkObject = (obj) => {
    return obj === undefined ? {} : Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const AddProduct = () => {
    if (
      stockinproductParam.product_id !== "" &&
      (stockinproductParam.barcode !== "") !== "" &&
      stockinproductParam.qty !== ""
    ) {
      stockAcceptanceproducts.push(stockinproductParam);
      setStockinproductParam({
        product_id: "",
        product: "",
        barcode: "",
        qty: "",
      });
    }
  };

  const removeProduct = (i) => {
    let tempArry = [...stockAcceptanceproducts];
    tempArry.splice(i, 1);
    setstockAcceptanceproducts(tempArry);
  };

  const _Save = () => {
    let tempArrySave = [];
    let tempArry = [...stockAcceptanceproducts];
    tempArry.forEach((Obj) => {
      if (Obj.accept === true) {
        tempArrySave.push({ product_id: Obj.product_id, stp_id: Obj.tran_id, qty: Obj.rec_qty });
      }
    });

    var temp = id == null ? "0" : window.atob(id);
    let temparam = {
      tran_id: 0,
      from_branch_id: param.from_branch_id,
      st_id: temp,
      date: param.date,
      entry_no: param.entry_no,
      remarks: param.remarks,
      stock_acceptance_products: tempArrySave,
    };
    postMethod("api/transactions/stockAcceptance/insert", temparam).then((data) => {
      if (data.valid) {
        setSuccess(true);
        setTimeout(() => {
          props.history.push("/branch/stock-acceptance");
        }, 2000);
      }
    });
  };

  const handleDelete = () => {
    var proceed = window.confirm("Do you Really Wish to Delete this Entry..?!");
    var temp = window.atob(id);
    if (proceed) {
      postMethod("api/transactions/stockAcceptance/delete", { tran_id: temp }).then((data) => {
        console.log(data);
        if (data.valid) {
          setSuccessDelete(true);
          setTimeout(() => {
            props.history.push("/branch/stock-acceptance");
          }, 2000);
        }
      });
    }
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    let tempArry = [...stockAcceptanceproducts];
    tempArry.forEach((obj) => (obj.accept = !selectAll));
    setstockAcceptanceproducts(tempArry);
  };

  return (
    <>
      {load ? <Loader /> : null}
      <Form onSubmit={handleSubmit(_Save)}>
        <input
          type="hidden"
          name="product_id"
          value={id == null ? "0" : param.product_id}
          ref={register}
        />
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Add Stock Acceptance</h3>
            </div>
          </div>
          <div className="card-body">
            <Row>
              <Col sm={3}>
                <FormGroup>
                  <Label>Date</Label>
                  <TextField
                    type="date"
                    value={param.date}
                    className="form-control"
                    onChange={(e) => {
                      setParam({
                        ...param,
                        date: e.target.value,
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <FormGroup>
                  <Label>Entry No</Label>
                  <input
                    type="text"
                    required
                    value={param.entry_no}
                    className="form-control h-auto py-2 px-6"
                    name="entry_no"
                    onChange={(e) => setParam({ ...param, entry_no: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>
              <Col sm={12}>
                <FormGroup>
                  <Label>Remarks</Label>
                  <input
                    type="text"
                    value={param.remarks}
                    className="form-control h-auto py-2 px-6"
                    name="remarks"
                    onChange={(e) => setParam({ ...param, remarks: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Product Code</th>
                      <th>Product</th>
                      <th>Image</th>
                      <th>Qty</th>
                      {param.status.toUpperCase() == "DONE" || param.status.toUpperCase() == "CANCEL" ? null : (
                        <th>
                          Action &nbsp;
                          <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                          /> 
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {checkObject(stockAcceptanceproducts)
                      ? null
                      : stockAcceptanceproducts.map((obj, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                ({i + 1}) {obj.product_code}
                              </td>
                              <td>{obj.product}</td>
                              <td><img src={obj.url_image + obj.image_path} width="36px" alt="" /></td>
                              <td>{obj.qty}</td>
                              {param.status.toUpperCase() == "DONE" || param.status.toUpperCase() == "CANCEL" ? null : (
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={obj.accept || false}
                                    onChange={(e) => {
                                      let tempArry = [...stockAcceptanceproducts];
                                      tempArry[i].accept = e.target.checked;
                                      setstockAcceptanceproducts(tempArry);
                                    }}
                                  />
                                </td>
                              )}
                            </tr>
                          );
                        })}
                  </tbody>
                </Table>
              </Col>
            </Row>
            {success ? <Alert color="success">Saved Successfully</Alert> : null}
            {successDelete ? <Alert color="danger">Entry deleted</Alert> : null}
          </div>
          <div className="card-footer text-right">
            <a href="/branch/stock-acceptance" className="btn btn-secondary mr-2">
              Cancel
            </a>
            {param.status.toUpperCase() == "DONE" || param.status.toUpperCase() == "CANCEL" ? null : (
              <button type="submit" className="btn btn-primary ">
                Submit
              </button>
            )}
          </div>
        </div>
      </Form>
    </>
  );
};

export default AddStockacceptance;

