import React, { useEffect, useState } from "react";
import Logo from "../../../_assets/media/Maliramlogo.png";
import { authenUser, postMethod } from "../../../_services/_postMethod";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from "react-hook-form";
import { Alert, AlertTitle } from "@material-ui/lab";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  //  backgroundColor: '#fff'
  },
}));
const MaliramCheckCustomer = (props) => {
  const classes = useStyles(); 
  const [type, setType] = useState(localStorage.getItem("type"));
  const [otp, setOtp] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const [isvalid, setValid] = useState(false);
  const [load, setLoad] = useState(false);
    const [otpvalue, setOtpvalue] = useState("");
  const login = (values) => {
  
    setLoad(true);
    if (otp) {
      postMethod(`auth/maliram/checkupdate`, values).then((data) => {
       window.location.reload();
         setValid(false);
      });
    } else {
      postMethod(`auth/maliram/check`, values).then((data) => {
        if (data.status === 200) {
          setOtpvalue(data.otp)
          setOtp(true);
          setValid(false);
        } else {
          setOtp(false);
          setValid(true);
        }

        setLoad(false);
      });
    }
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={load}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="exhibitionbg">
        <div className="container">
          <div className="text-center">
            <img src={Logo} width="40%" />
          </div>
          <div className="login-form" style={{ marginTop: "25px" }}>
            <div className="main-div">
              <form onSubmit={handleSubmit(login)}>
                <div className="exgroup exsubs">
                  <div className="form-group">
                    <label className="font-weight-bold">Mobile</label>
                    <input
                      className="form-control custom"
                      readOnly={otp}
                      ref={register}
                      type="text"
                      name="mobile"
                      autoComplete="off"
                    />
                  </div>

                  <div className={"form-group " + (!otp ? "hide" : "")}>
                    <label className="font-weight-bold">
                      Enter 6-digit OTP
                    </label>
                    <input
                      className="form-control custom"
                      ref={register}
                      readOnly={otp}
                      defaultValue={otpvalue}
                      type="number"
                      name="otp"
                      autoComplete="off"
                    />
                  </div>
                  <span className="highlight"></span>
                </div>

                <div className={otp ? "hide" : ""}>
                  <button type="submit" className="btn btn-theme w-100 py-2">
                    Login
                  </button>
                </div>
                <div className={(!otp ? "hide" : "")}>
                  <div className="d-flex">
                    <button
                      type="button"
                      onClick={() => {
                         setLoad(true);
                       window.location.reload();
                      }}
                      className="btn btn-theme2 w-100 py-2"
                    >
                      Back
                    </button>{" "}
                    <button type="submit" className="btn btn-theme w-100 py-2">
                      Varified
                    </button>
                  </div>
                </div>
                <br />
                <div>
                  {isvalid && (
                    <Alert severity="info">
                      <AlertTitle>Invitation Not Fount</AlertTitle>
                    </Alert>
                  )}
                </div>
              </form>
            </div>

            <div className="clr"></div>
          </div>
        </div>
        <div className="clr"></div>
        <div className="custom-footer">
          <div className="container mcntr">
            <div className="row">
              <div className="col-sm-10">
                © 2020 QuickTagg All Rights Reserved
              </div>
              <div className="col-sm-2">
                <div className="text-center center-block">
                  <a href="https://www.facebook.com/bootsnipp">
                    <i
                      id="social-fb"
                      className="fa fa-facebook fa-2x social"
                    ></i>
                  </a>
                  <a href="https://plus.google.com/+Bootsnipp-page">
                    <i
                      id="social-gp"
                      className="fa fa-instagram fa-2x social"
                    ></i>
                  </a>
                  <a href="mailto:bootsnipp@gmail.com">
                    <i
                      id="social-em"
                      className="fa fa-linkedin fa-2x social"
                    ></i>
                  </a>
                  <a href="https://twitter.com/bootsnipp">
                    <i
                      id="social-tw"
                      className="fa fa-twitter fa-2x social"
                    ></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MaliramCheckCustomer;

