import React from 'react';
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Collapse,
  ListSubheader,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { BranchRoutes } from '../../routes/BranchRoutes';
import { AdminRoutes } from '../../routes/AdminRoutes';
import { CompanyRoutes } from '../../routes/CompanyRoutes';
import { API_BASE_URL } from '../../_services/constant';
import logo from '../../_assets/media/logo-white.png';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { WhatsApp } from '@material-ui/icons';
import PhoneIcon from '@material-ui/icons/Phone';

const Sidenav = (props) => {
  const [routes, setRoutes] = React.useState([]);
  const [openCollapse, setOpenCollapse] = React.useState("");
  const useStyles = makeStyles((theme) => ({
    toolbar: theme.mixins.toolbar,
  }));

  React.useEffect(() => {
    if (routes.length === 0) {
      var typeRoute = localStorage.getItem("type");
      switch (typeRoute) {
        case "admin":
          setRoutes(AdminRoutes);
          break;
        case "company":
          setRoutes(CompanyRoutes);
          break;
        case "branch":
          setRoutes(BranchRoutes);
          break;
        default:
          setRoutes([]);
          break;
      }
    }
  }, []);

  const classes = useStyles();

  const handleClick = (category) => {
    setOpenCollapse(openCollapse === category ? "" : category);
  };

  const renderMenuItems = (categoryRoutes) => {
    return categoryRoutes.map((route, index) => (
      <ListItem key={index} button component="a" href={route.path}>
        <ListItemIcon style={{ minWidth: 30 }}>
          <i className={route.icon} />{" "}
        </ListItemIcon>
        <ListItemText primary={route.name} />
      </ListItem>
    ));
  };

  // Group the routes by category
  const groupedRoutes = {
    dashboard: routes.filter((route) => route.name === "Dashboard" && route.child===false),
    customer: routes.filter((route) =>
      ["Customer", "Customer Categories", "Customer Review"].includes(route.name) && route.child===false
    ),
    product: routes.filter((route) =>
      ["Products", "Product Categories", "Product SubCategories"].includes(route.name) && route.child===false
    ),
    voucher: routes.filter((route) =>
      ["Voucher", "Voucher Terms"].includes(route.name) && route.child===false
    ),
    catalog: routes.filter((route) =>
      ["Customer Session", "Trial Session", "General Session", "Exhibition Session", "Seesion Images"].includes(route.name) && route.child===false
    ),
    template: routes.filter((route) =>
      ["Sms Templete Setting", "Whatsapp Templete Setting"].includes(route.name) && route.child===false
    ),
    stock: routes.filter((route) =>
      ["Stock Acceptance", "Stock Transfer", "Stock Sale"].includes(route.name) && route.child===false
    ),
    tapToScan: routes.filter((route) =>
      ["Tab To Scan", "Tab to scan banner"].includes(route.name) && route.child===false
    ),
    other: routes.filter((route) =>
      ![
        "Dashboard",
        "Customer",
        "Customer Categories",
        "Customer Review",
        "Products",
        "Product Categories",
        "Product SubCategories",
        "Voucher",
        "Voucher Terms",
        "Customer Session",
        "Trial Session",
        "General Session",
        "Exhibition Session",
        "Seesion Images",
        "Sms Templete Setting",
        "Whatsapp Templete Setting",
        "Stock Acceptance",
        "Stock Transfer",
        "Stock Sale",
        "Tab To Scan",
        "Tab to scan banner",
      ].includes(route.name) && route.child===false
    ),
  };

  return (
    <div>
      <div className={classes.toolbar + " logo"}>
        {localStorage.getItem("type") === "admin" ? <img src={logo} /> : null}
        {localStorage.getItem("type") === "company" ? (
          <img
            src={
              API_BASE_URL +
              "companylogo/" +
              localStorage.getItem("jwl_company_logo")
            }
          />
        ) : null}
        {localStorage.getItem("type") === "branch" ? (
          <img
            src={
              API_BASE_URL +
              "BranchLogo/" +
              localStorage.getItem("jwl_company_logo")
            }
          />
        ) : null}
      </div>
      <Divider />
      <List>
        {Object.keys(groupedRoutes).map((category) => (
          groupedRoutes[category].length > 0 && (
            <div key={category}>
              <ListItem button onClick={() => handleClick(category)}>
                <ListItemText primary={category.charAt(0).toUpperCase() + category.slice(1)} />
                {openCollapse === category ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openCollapse === category} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {renderMenuItems(groupedRoutes[category])}
                </List>
              </Collapse>
            </div>
          )
        ))}
        <Divider />
      </List>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Contact Us
          </ListSubheader>
        }
      >
        <ListItem button component="a">
          <ListItemIcon style={{ minWidth: 30 }}>
            <WhatsApp />
          </ListItemIcon>
          <ListItemText primary={"Whatsapp"} />
        </ListItem>
        <ListItem button component="a">
          <ListItemIcon style={{ minWidth: 30 }}>
            <PhoneIcon />
          </ListItemIcon>
          <ListItemText primary={"Contact Us"} />
        </ListItem>
      </List>
    </div>
  );
};

export default Sidenav;
