import React, {useState, useEffect} from "react";
import "../../../_assets/css/custom.css";

import { API_BASE_URL } from "../../../_services/constant";

export default function CustomerThankyou(props) {
  const [year, setyear] = useState("");
  const [branchName, setBranchName] = useState("");
  const [branchFacebook, setBranchFacebook] = useState("");
  const [branchInstagram, setBranchInstagram] = useState("");
  const [branchTwitter, setBranchTwitter] = useState("");
  const [branchLinkedin, setBranchLinkedin] = useState(""); 
  useEffect(() => {
    setyear(new Date().getFullYear());
    setBranchName(localStorage.getItem("jwl_brand_name"));
    setBranchFacebook(localStorage.getItem("jwl_facebook"));
    setBranchInstagram(localStorage.getItem("jwl_instagram"));
    setBranchTwitter(localStorage.getItem("jwl_twitter"));
  }, []);


  const phrases = ["Jewellery has the power to be the one little thing that’s make you feel unique.", "Jewellery is like ice cream. There’s always room for more", "You are pretty darn awesome. We look forward to serving you again"]
  var item = phrases[Math.floor(Math.random()*phrases.length)];
   useEffect(() => {
     setTimeout(() => {
       window.location.pathname = "/generalsession/home";
     }, 10000);
   }, []);
  return (
    <>
      <div className="container">
        <div className="row toppad3" style={{display:'flex', justifyContent:"space-between"}}>
        <div className="col-6 col-sm-3 col-lg-3 logo_session">
              <img
                onClick={() => (window.location.pathname = "generalsession/home")}
                src={`${API_BASE_URL}BranchLogo/${localStorage.getItem(
                  "jwl_logo"
                )}`}
                className="img-fluid mlogo"
              />
            </div>
          <div className="col-6 col-sm-9 col-lg-9 rightnav" >
            {localStorage.getItem("jwl_branch_mobile") ? (
              <div style={{textAlign:"right"}}> Toll Free No. {localStorage.getItem("jwl_branch_mobile")} </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="clr"></div>
      <div className="container">
        <div className="row " style={{textAlign:"center"}}>
        
          
            
              {/* <div className="col-12 logo_session" style={{textAlign:"center"}}>
                
                </div> */}
            
                
                  <div className="col-12 thnkutext" >
                  <h2 className="fs-5" style={{marginTop:"100px"}}>Thank You</h2>
              
            <p className="thnkuTagline">"{item}"</p>

            {/* <!----Mobile-----> */}
            {/* <div className="row">
              <div className="thnku">
                <div className="col-sm-12">
                  <div className="thnkulogo">
                    <img src="images/logo.png" />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="thnkutext">
                    <h2>Thank You</h2>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="clr"></div>
      <div className="container-fluid"></div>
      <div className="clr"></div>
      <footer style={{position:'absolute', bottom:0, right:0, width:'100%'}}>
      <div className="container mcntr">
        <div className="row">
          <div className="col-sm-10">
            © {year} {branchName} All Rights Reserved
          </div>
          <div className="col-sm-2">
            <div className="text-center center-block">
              {branchFacebook ? (
                <a href={branchFacebook}>
                  <i id="social-fb" className="fa fa-facebook fa-2x social"></i>
                </a>
              ) : null}
              {branchInstagram ? (
                <a href={branchInstagram}>
                  <i
                    id="social-gp"
                    className="fa fa-instagram fa-2x social"
                  ></i>
                </a>
              ) : null}
              {branchLinkedin ? (
                <a href={branchLinkedin}>
                  <i id="social-em" className="fa fa-linkedin fa-2x social"></i>
                </a>
              ) : null}
              {branchTwitter ? (
                <a href={branchTwitter}>
                  <i id="social-tw" className="fa fa-twitter fa-2x social"></i>
                </a>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </footer>
      {/* <CustomerFooter /> */}
    </>
  );
}
