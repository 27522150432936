import { React, Fragment, useState, useEffect, useRef } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import UploadIcon from '@mui/icons-material/Upload';
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
} from "reactstrap";
import { postMethod } from "../../../../_services/_postMethod";
import { API_BASE_URL } from "../../../../_services/constant";
import moment from "moment";

export default function Notification(props) {
  const [modal, setModal] = useState(false);

  const [count, setCount] = useState(0);
  const [noti, setNoti] = useState([]);

  function check_notification() {
    postMethod("api/customervisit/check_notification", {})
      .then((data) => {})
      .catch((err) => {
        console.log(err);
      });
  }

  const toggle = () => {
    setModal(!modal);
    check_notification();
  };

  const countStyle = {
    position: "absolute",
    top: "-15px",
    right: "-10px",
    backgroundColor: "red",
    color: "white",
    borderRadius: "50%",
    padding: "5px",
    minWidth: "20px",
    minHeight: "15px",
    textAlign: "center",
  };

  const buttonStyle = {
    position: "relative",
    borderRadius: "10px",
    right: "18px",
    backgroundColor: "none",
    color: "blue",
    border: "none",
    cursor: "pointer",
  };

  const ref = useRef();

  //Table Config
  const config = {
    page_size: 5,
    length_menu: [10, 5, 20, 50],
  };

  useEffect(() => {
    postMethod("api/customervisit/notification", {
      from_date: props.fromDate,
      to_date: props.toDate,
    })
      .then((data) => {
        setNoti(data.data);
        setCount(data.data[0].count_check);
        // console.log(JSON.stringify(noti));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //Table Cart
  const columns_noti = [
    {
      key: "full_name",
      text: "Customer Name",
      align: "left",
      sortable: true,
    },
    {
      key: "mobile",
      text: "Mobile",
      align: "left",
      sortable: true,
    },
    {
      key: "category_name",
      text: "Customer Category",
      align: "left",
      sortable: true,
    },

    {
      key: "remarks",
      text: "Remarks",
      sortable: true,
      align: "left",
      className: "text-wrap w-20",
      cell: (record) => {
        return (
          <Fragment>
            {record.remarks != "" && record.remarks != null
              ? record.remarks.length > 10
                ? record.remarks.substring(0, 10) + "..."
                : record.remarks
              : null}
          </Fragment>
        );
      },
    },

    {
      key: "category",
      text: "Product Category",
      align: "left",
      sortable: true,
    },

    {
      key: "image_path",
      text: "Design",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            {
              record.image_path ?  <img
              src={API_BASE_URL + "CustomerUploadedDesign/" + record.image_path}
              width="auto"
              height="95px"
              alt=""
            />
            :
            <UploadIcon style={{color:"green"}}/>
            }
           
          </Fragment>
        );
      },
    },

    {
      key: "datetime",
      text: "Datetime",
      sortable: true,
      align: "left",
      className: "text-nowrap",
      cell: (record) => {
        return (
          <Fragment>
            {record.edatetime != "" && record.edatetime != null ? (
              <>
                <p style={{ padding: 0, margin: 0 }}>
                  {
                    moment(
                      record.edatetime.split("T")[0] +
                        "T" +
                        record.edatetime.split("T")[1].split(".")[0]
                    )
                      .format("DD/MM/yyyy HH:mm")
                      .toString()
                      .split(" ")[0]
                  }
                </p>
                <p style={{ padding: 0, margin: 0 }}>
                  {
                    moment(
                      record.edatetime.split("T")[0] +
                        "T" +
                        record.edatetime.split("T")[1].split(".")[0]
                    )
                      .format("DD/MM/yyyy HH:mm")
                      .toString()
                      .split(" ")[1]
                  }
                </p>
                {/* moment(record.changedatetime.split("T")[0]+'T'+(record.changedatetime.split('T')[1]).split('.')[0]).format("DD/MM/yyyy HH:mm")  */}
              </>
            ) : null}{" "}
          </Fragment>
        );
      },
    },
  ];

  return (
    <>
      <Button style={buttonStyle} onClick={toggle}>
        <CircleNotificationsIcon style={{ fontSize: 28 }} />
        <span style={countStyle}>{count}</span>
      </Button>

      <Modal
        isOpen={modal}
        zIndex="999999"
        size="lg"
        backdrop="static"
        centered="true"
      >
        <ModalHeader>
          <Row style={{ marginBottom: "10px" }}>
            <Col sm="6">Notification</Col>

            <Col sm="6">
              <i
                className="fa fa-close pull-right pointerShow"
                onClick={toggle}
              ></i>
            </Col>
          </Row>
        </ModalHeader>

        <ModalBody style={{ maxHeight: "400px", overflowY: "auto" }}>
          <ReactDatatable
            ref={ref}
            config={config}
            records={noti}
            columns={columns_noti}
          />
        </ModalBody>
      </Modal>
    </>
  );
}
