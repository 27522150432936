import React, { useState, useEffect } from "react";
import moment from "moment";
import { postMethod } from "../../../../../_services/_postMethod";
import { API_BASE_URL } from "../../../../../_services/constant";
import {
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Button,
} from "reactstrap";

export default function SubService(props) {
  const { nxt, customerData } = props;

  const [staffList, setStaffList] = useState([]);
  const getStaffList = () => {
    postMethod("api/customervisit/StaffList").then((res) => {
      setStaffList(res.data);
    });
  };

  useEffect(() => {
    getStaffList();
  }, []);

  const todayDate = moment().format("YYYY-MM-DD");

  const [uploadService, setUploadService] = useState({
    tran_id: customerData.tran_id || "",
    customer_id: customerData.customer_id || "",
    full_name: customerData.name || "",
    category: customerData.category || "",
    mobile: customerData.mobile || "",
    color: customerData.color,
    modal: customerData.modal || "",
    staff: customerData.staff || "",
    image: customerData.image || "",
    next_service: "",
    service_date:todayDate,
    payment: "",
    vichle: customerData.vichle || "",
    remark: customerData.remark || "",
    kms: "",
  });

  function clearForm() {
    setUploadService({
      full_name: customerData.name || "",
      mobile: customerData.mobile || "",
      staff: customerData.staff || "",
      next_service: "",
      service_date:"",
      payment: "",
      vichle: "",
      remark: "",
      kms: "",
      color: customerData.color || "",
    });
  }

  const handleUploadService = (e) => {
    setUploadService({
      ...uploadService,
      [e.target.name]: e.target.value,
    });
  };

  const handleIntegerForm = (e) => {
    const value = e.target.value;

    if (!Number.isInteger(Number(value))) {
      alert("It should be a whole number.");
      setUploadService({
        ...uploadService,
        [e.target.name]: "",
      });
      return;
    }
    setUploadService({
      ...uploadService,
      [e.target.name]: value,
    });
  };

  function insertService() {
    postMethod("api/customervisit/insert_service", {
      ...uploadService,
      tran_id: customerData.tran_id,
      customer_id: customerData.customer_id,
      full_name: customerData.name,
      category: customerData.category,
      mobile: customerData.mobile,
      color: customerData.color,
      modal: customerData.modal,
      image: customerData.image,
    }).then((data) => {
      if (data.data[0].valid) {
        alert("Success");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        alert("Something Went Wrong");
      }
    });
  }

  function handleFormSubmit() {
    insertService();
    alert("Done");
    clearForm();
  }

  return (
    <>
      <Row>
        <Col sm="9">
          <Row>
            <Col>
              <FormGroup>
                <Label for="service_due">Service Due</Label>
                <Input
                  type="date"
                  placeholder="Service Due"
                  name="service_due"
                  id="service_due"
                  value={nxt}
                  readOnly
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="next_service">Next Service</Label>
                <Input
                  type="date"
                  placeholder="Next Service Date"
                  name="next_service"
                  id="next_service"
                  value={uploadService.next_service}
                  onChange={handleUploadService}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="service_date">Service Date</Label>
                <Input
                  type="date"
                  placeholder="Service Date"
                  name="service_date"
                  id="service_date"
                  value={uploadService.service_date}
                  onChange={handleUploadService}
                />
              </FormGroup>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <FormGroup>
                <Label for="vichle">Vehicle Number</Label>
                <Input
                  type="text"
                  placeholder="Vehicle Number"
                  name="vichle"
                  id="vichle"
                  value={uploadService.vichle}
                  onChange={handleUploadService}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="kms">KMS</Label>
                <Input
                  type="text"
                  placeholder="Kilometers"
                  name="kms"
                  id="kms"
                  value={uploadService.kms}
                  onChange={handleIntegerForm}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="payment">Payment</Label>
                <Input
                  type="text"
                  placeholder="Payment"
                  name="payment"
                  id="payment"
                  value={uploadService.payment}
                  onChange={handleUploadService}
                />
              </FormGroup>
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm="4">
              <FormGroup>
                <Label for="staff">Staff Name</Label>
                <Input
                  type="select"
                  name="staff"
                  id="staff"
                  value={uploadService.staff || ""}
                  onChange={handleUploadService}
                >
                  {staffList.map((item, i) => (
                    <option value={item.name} key={i}>
                      {item.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col sm="8">
              <FormGroup>
                <Label for="remark">Remark</Label>
                <Input
                  type="textarea"
                  placeholder="Remark"
                  name="remark"
                  id="remark"
                  value={uploadService.remark}
                  onChange={handleUploadService}
                />
              </FormGroup>
            </Col>
          </Row>
          <br />
        </Col>
        <Col sm="3">
          <img
            style={{
              width: "230px",
              height: "230px",
              backgroundColor: "black",
            }}
            src={`${API_BASE_URL}CustomerUploads/${customerData.image}`}
          />
        </Col>
      </Row>
      <Row className="justify-content-end">
        <Col md="auto">
          <Button size="xl" className="btn btn-danger" onClick={clearForm}>
            Clear
          </Button>
        </Col>
        <Col md="auto">
          <Button size="xl" className="btn btn-success" onClick={handleFormSubmit}>
            Save
          </Button>
        </Col>
      </Row>
      <hr style={{ border: "2px solid blue" }} />
    </>
  );
}
