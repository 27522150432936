import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "../../../_assets/css/custom.css";
import "react-owl-carousel2/lib/styles.css";
import { postMethod } from "../../../_services/_postMethod";
import "react-input-range/lib/css/index.css";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import CustomerHeader from "./components/header";
import bg from '../../../_assets/media/home/background1.jpg'
import CustomerFooter from "./components/footer";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#000',
    backgroundColor: '#fff'
  },
}));

const CustomerProfile = (props) => {
  document.body.style.backgroundImage = `url(${bg})!important`
  const { register, handleSubmit, errors } = useForm();
  const classes = useStyles(); 
  const [load, setLoad] = useState(false);
  const inputRef = React.createRef(null);

  useEffect(() => {
    setLoad(true);
    postMethod("api/customers/customer/getCustomer", { customer_id: "" }).then((data) => {
      SetCustomerDetails(data);
      setLoad(false);
    });
  }, []);

  const [CustomerDetails, SetCustomerDetails] = useState({
    customer_id: null,
    full_name: "",
    mobile: "",
    nick_name: "",
    profession: "",
    address: ""
  });


  const insertCustomer = () => {
    setLoad(true);
    postMethod("api/exhibition/insertCustomer", CustomerDetails).then((data) => {
      setLoad(false);
      window.location = "/view/home"
    });
  };

  return (
    <>
      <CustomerHeader count={0} /> 
      <Backdrop className={classes.backdrop} open={load} >
      <CircularProgress color="inherit" />
        </Backdrop>
      <div className="container-fluid">
      <div className="main-div1">
                    <form id="Login">
                      <div className="exgroup1">
                        <div className="exgroup exsubs">
                          <input
                            type="text"
                            placeholder="Name"
                            value={CustomerDetails.full_name}
                            onChange={(e) =>
                              SetCustomerDetails({
                                ...CustomerDetails,
                                full_name: e.target.value,
                              })
                            }
                          />
                          <span className="highlight"></span>
                          <span className="exbar"></span>
                        </div>
                      </div>

                      <div className="exgroup2">
                        <div className="exgroup exsubs">
                          <input
                            type="text"
                            placeholder="Mobile"
                            value={CustomerDetails.mobile}
                            onChange={(e) =>
                              SetCustomerDetails({ ...CustomerDetails, mobile: e.target.value })
                            }
                          />
                          <span className="highlight"></span>
                          <span className="exbar"></span>
                        </div>
                      </div>
                      <div className="exgroup1">
                        <div className="exgroup exsubs">
                          <input
                            type="text"
                            placeholder="Profession"
                            value={CustomerDetails.profession}
                            onChange={(e) =>
                              SetCustomerDetails({
                                ...CustomerDetails,
                                profession: e.target.value,
                              })
                            }
                          />
                          <span className="highlight"></span>
                          <span className="exbar"></span>
                        </div>
                      </div>
                      <div className="exgroup2">
                        <div className="exgroup exsubs">
                          <input
                            type="text"
                            placeholder="Nick Name"
                            value={CustomerDetails.nick_name}
                            onChange={(e) =>
                              SetCustomerDetails({
                                ...CustomerDetails,
                                nick_name: e.target.value,
                              })
                            }
                          />
                          <span className="highlight"></span>
                          <span className="exbar"></span>
                        </div>
                      </div>

                      <div className="exgroup exsubs">
                        <input
                          type="text"
                          placeholder="Address"
                          value={CustomerDetails.address}
                          onChange={(e) =>
                            SetCustomerDetails({ ...CustomerDetails, address: e.target.value })
                          }
                        />
                        <span className="highlight"></span>
                        <span className="exbar"></span>
                      </div>

                      <div className="row">
                      
                        <div className="col-6">
                          <input
                            type="button"
                            value="submit"
                            onClick={() => {
                              insertCustomer();
                            }}
                            className="exsbmtbtn"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
      </div>
      <div className="clr"></div>

      {window.outerWidth > 600 ? <CustomerFooter /> : null}
    </>
  );
};

export default CustomerProfile;
