import React, { useEffect, useState } from "react";
import CustomerHeader from "./components/header";
import CustomerFooter from "./components/footer";
import { useParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { postMethod } from "../../../_services/_postMethod";
import { API_BASE_URL } from "../../../_services/constant";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { findAllByDisplayValue } from "@testing-library/react";
import CustomerVoucherComList from "./components/voucherList";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import CustomertermsAndCondition from "./components/termsAndCondition";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#000",
    backgroundColor: "#fff",
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Instragram(props) {
  let queryParams = useQuery();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const id = queryParams.get("id") == "0" ? null : queryParams.get("id");
  const { register, handleSubmit, errors } = useForm();
  const [checkedTerms, setcheckedTerms] = useState({
    check: false,
    comment: false,
  });
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [param, setParam] = useState({
    tran_id: "0",
    voucher_id: id,
    disable: false,
    redeem_start_date:"",
customer_redeemed_date:""
  });
  const handleToggle = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    setOpen(true);
    postMethod("api/customers/customer/customerVoucherDetails", param).then(
      (data) => {
        setParam({ ...param, ...data });
        setOpen(false);
      }
    );
  }, []);

  const insert = () => {
    if (!checkedTerms.check) {
      setcheckedTerms({ ...checkedTerms, comment: true });
    } else {
      postMethod("api/customers/customer/InsertinstagramVoucher", param).then(
        (data) => {
          toggle();
        }
      );
    }
  };

  return (
    <>
      <div>
        <Modal isOpen={modal} backdrop="static" centered="true">
          <ModalBody>Thank you .</ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                window.location.pathname = "customers/home";
              }}
            >
              Ok
            </Button>
          </ModalFooter>
        </Modal>
      </div>

      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CustomerHeader />
      <div className="clr"></div>
      <div className="container toppadhead">
        <div className="row toppad5 mtoppad2">
          <div className="col-sm-12">
            <div>
              <img
                src={
                  API_BASE_URL + "CustomerVoucherBanner/" + param.banner_image
                }
                className=" d-none d-lg-block d-md-block imgcurve custom-banner"
              />
              <img
                src={API_BASE_URL + "CustomerVoucher/" + param.banner_image}
                className=" d-none d-lg-none d-md-none d-block imgcurve custom-banner"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="clr"></div>
      <div className="container-fluid">
        <div className="container70">
          <div className="row toppad5 mtoppad2">
            <div className="col-12 col-sm-8 col-lg-8 toppad7">
              <div className="row">
                <div className="col-12 col-sm-8 col-lg-8">
                  <div className="voucher-box">
                    <div className="item clearfix">
                      <span className="circle-top"></span>
                      <span className="circle-bottom"></span>

                      <div className="item-left">
                        <div className="price-style">
                          {/* <span className="price-desc"> ₹ </span> */}
                          <span className="price"> {param.voucher_value}</span>
                          <span className="price-unit"></span>
                          <span className="price-off"></span>
                        </div>
                      </div>
                      <div className="item-right">
                        <p className="p-one">{param.voucher_heading}</p>
                        <p className="p-two">
                          {" "}
                          <i className="iconfont icontime"></i>
                          {moment(param.start_date).format(
                            "MMM Do YYYY"
                          )} - {moment(param.end_date).format("MMM Do YYYY")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                {param.status == "true" ? null : (
                  <form onSubmit={handleSubmit(insert)}>
                    <div className="col-12 col-sm-12 col-lg-12 toppad11"></div>
                    <div className="col-12 col-sm-12 col-lg-12 mtoppad5">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={checkedTerms.check}
                          checked={checkedTerms.check}
                          id="flexCheckDefault"
                          onClick={(e) => {
                            setcheckedTerms({
                              ...checkedTerms,
                              check: !checkedTerms.check,
                              comment: false,
                            });
                          }}
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckDefault"
                        >
                          Terms &amp; Conditions apply
                        </label>
                      </div>
                      {checkedTerms.comment ? (
                        <label
                          className="form-check-label color-red"
                          for="flexCheckDefault"
                        >
                          Please check Terms &amp; Conditions apply
                        </label>
                      ) : null}
                    </div>
                    <div className="col-12 col-sm-3 col-lg-12 toppad4">
                      <div className="sbmt sbmt1">
                        {param.disable ? null : (
                          <input
                            type="submit"
                            value="Get"
                            className="sbmtbtn1"
                          />
                        )}
                      </div>
                    </div>
                  </form>
                )}
               {param.status == "Get voucher" ? (
                  <label
                    className="form-check-label color-red mt-5"
                    for="flexCheckDefault"
                  >
                    Note: Last date to { moment(param.redeem_end_date).format("DD/MM/yyyy")} . Plz visit or contact showroom.
                  </label>
                ) : null}
                 {param.status == "Redeem voucher" ? (
                  <label
                    className="form-check-label color-red mt-5"
                    for="flexCheckDefault"
                  >
                    Note: Already redeemed voucher on { moment(param.customer_redeemed_date).format("DD/MM/yyyy")}.
                  </label>
                ) : null}
                <CustomertermsAndCondition id={id} />
              </div>
            </div>
            <div className="col-sm-4 secbdr mtoppad3 m15">
              <h1>Other Offers</h1>
              <CustomerVoucherComList voucher_id={id} />
            </div>
          </div>
        </div>
      </div>
      <div className="clr"></div>
      <div className="container-fluid"></div>
      <div className="clr"></div>
      <CustomerFooter />
    </>
  );
}
