// import React, { Fragment, useEffect, useState } from "react";
// import { useForm } from "react-hook-form";
// import ReactDatatable from "@ashvin27/react-datatable";
// import { postMethod } from "../../../../_services/_postMethod";
// import Loader from "../../../modules/loader";
// import { API_BASE_URL } from "../../../../_services/constant";
// import { post } from "axios";
// import {
//   Button,
//   Form,
//   FormGroup,
//   Label,
//   FormText,
//   Row,
//   Col,
//   Alert,
//   Table,
//   Modal,
//   ModalHeader,
//   ModalBody,
//   ModalFooter,
//   Input,
// } from "reactstrap";

// const SessionImage = (props) => {
//   const [file, setFile] = useState({});
//   const [file1, setFile1] = useState({});
//   const [file2, setFile2] = useState({});
//   const [branchEncryptID, setBranchEncryptID] = React.useState(localStorage.getItem("jwl_branchEncrypt_id"));

//   function handleChange(e) {
//     console.log(e.target.files);
//     setFile(URL.createObjectURL(e.target.files[0]));
//   }
//   function handleChange1(e) {
//     console.log(e.target.files);
//     setFile1(URL.createObjectURL(e.target.files[0]));
//   }
//   function handleChange2(e) {
//     console.log(e.target.files);
//     setFile2(URL.createObjectURL(e.target.files[0]));
//   }
//   console.log(file);

//   const { register, handleSubmit, errors } = useForm();

//   const [param, setParam] = useState({
//     image1: [],
//     image2: [],
//     image3: [],
//     branch_id: "",
//   });

//   console.log("image", param.image1);
//   useEffect(() => {
//     fetch_data()
//   }, [])
  

//   function createGuid() {
//     return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
//       /[xy]/g,
//       function (c) {
//         var r = (Math.random() * 16) | 0,
//           v = c === "x" ? r : (r & 0x3) | 0x8;
//         return v.toString(16);
//       }
//     );
//   }
//   const uploadImageFunc = (formData) => {
//     const url = API_BASE_URL + `api/masters/product/uploadImage`;
//     const config = {
//       headers: {
//         "content-type": "multipart/form-data",
//         "auth-token": localStorage.getItem("jwl_token"),
//       },
//     };

//     post(url, formData, config).then(
//       (response) => {
//         // alert("Image Saved Successfully");
//       },
//       (error) => {
//         alert("something went wrong!");
//       }
//     );
//   };

//   const _Save = () => {
//     // alert("test passed");
//     // debugger;
//     for (var i = 1; i <= 3; i++) {
//       // debugger;
//       let file = document.getElementById("image" + i);
//       let files = file.files;
//       let formData = new FormData();
//       console.log("-----image----", files);



//       if (files.length > 0) {
//         for (var j = 0; j < files.length; j++) {
//           let file_name = "image-" + createGuid();
//           // Get the file extension
//           let extension = files[j].name.substring(files[j].name.lastIndexOf("."));
//           // Change .jpeg to .jpg
//           if (extension.toLowerCase() === ".jpeg") {
//             extension = ".jpg";
//           }
//           // Construct the image path with the modified extension
      
//           param.image1.push(file_name + extension)
//           // Append the file with the new file name
//           formData.append("files", files[j], file_name + extension);
//         }



//       // if (files.length > 0) {
//       //   for (var j = 0; j < files.length; j++) {
//       //     let file_name = "image-" + createGuid();
//       //     param.image1.push(
//       //       file_name +
//       //         "" +
//       //         files[j].name.substring(files[j].name.lastIndexOf("."))
//       //     );
//       //     formData.append("files", files[j], file_name);
//       //   }
//       }
//       uploadImageFunc(formData);
//       console.log("------", formData);
//     }
//     postMethod("api/sms/images/sessionimages", param).then((data) => {
//       // debugger
//       if (data.data) {
//         alert("Image Saved Successfully");
//         window.location.reload();
//       } else {
//         alert("Something Went Wrong");
//       }
//     });
//   };

//   const fetch_data=()=>{
//     postMethod("public/Trialimages", { branch_id: branchEncryptID }).then((data) => {
//       // debugger
//       if (data.data.length > 0) {
//         let images=data.data[0];
//         setFile(`${API_BASE_URL}/images/${images.image1}`)
//         setFile1(`${API_BASE_URL}/images/${images.image2}`)
//         setFile2(`${API_BASE_URL}/images/${images.image3}`)
//         console.log(data.data[0]);
//       }
//     });
//   }

//   return (
//     <>
//       <Form onSubmit={handleSubmit(_Save)}>
//         <div className="card card-custom gutter-b">
//           <div className="card-header">
//             <div className="card-title">
//               <h3 className="card-label">Image Upload</h3>
//             </div>
//           </div>
//           <div className="card-body">
//             <Row>
//               <Col sm={12}>
//                 <Label className="text-danger pull-right">
//                   Please save all images
//                 </Label>
//               </Col>
//             </Row>
//             <Row>
//               <Col sm={4} className="d-flex flex-column">
//                 <Label>
//                   Main Image{" "}
//                   <span className="text-danger">(width should be 375px)</span>
//                   <Input
//                     type="file"
//                     onChange={handleChange}
//                     id="image1"
//                     className="mt-2"
//                     name="image1"
//                     required
//                   ></Input>
//                 </Label>
//                 <img
//                   src={file}
//                   style={{
//                     width: "100px",
//                     height: "100px",
//                     objectFit: "contain",
//                   }}
//                 />
//                 <button className="btn btn-danger mt-4 w-25" onClick={setFile}>
//                   clear
//                 </button>
//               </Col>
//               <Col sm={4} className="d-flex flex-column">
//                 <Label>
//                   Image 2{" "}
//                   <span className="text-danger">(width should be 315px)</span>
//                   <Input
//                     type="file"
//                     id="image2"
//                     className="mt-2 image1"
//                     onChange={handleChange1}
//                     required
//                   ></Input>
//                 </Label>
//                 <img
//                   src={file1}
//                   style={{
//                     width: "100px",
//                     height: "100px",
//                     objectFit: "contain",
//                   }}
//                 />
//                 <button className="btn btn-danger mt-4 w-25" onClick={setFile1}>
//                   clear
//                 </button>
//               </Col>
//               <Col sm={4} className="d-flex flex-column">
//                 <Label>
//                   Image 3{" "}
//                   <span className="text-danger">(width should be 315px)</span>
//                   <Input
//                     type="file"
//                     id="image3"
//                     className="mt-2 image1"
//                     onChange={handleChange2}
//                     required
//                   ></Input>
//                 </Label>
//                 <img
//                   src={file2}
//                   style={{
//                     width: "100px",
//                     height: "100px",
//                     objectFit: "contain",
//                   }}
//                 />
//                 <button className="btn btn-danger mt-4 w-25" onClick={setFile2}>
//                   clear
//                 </button>
//               </Col>
//               <Col sm={12}>
//                 <div className="border-top border-2 mt-5">
//                   <button type="submit" className="btn btn-primary mt-4 w-30">
//                     Save
//                   </button>
//                 </div>
//               </Col>
//             </Row>
//           </div>
//         </div>
//       </Form>
//     </>
//   );
// };

// export default SessionImage;



import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReactDatatable from "@ashvin27/react-datatable";
import { postMethod } from "../../../../_services/_postMethod";
import Loader from "../../../modules/loader";
import { API_BASE_URL } from "../../../../_services/constant";
import { post } from "axios";
import {
  Button,
  Form,
  FormGroup,
  Label,
  FormText,
  Row,
  Col,
  Alert,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";

const SessionImage = (props) => {
  const [file, setFile] = useState({});
  const [file1, setFile1] = useState({});
  const [file2, setFile2] = useState({});
  const [branchEncryptID, setBranchEncryptID] = React.useState(localStorage.getItem("jwl_branchEncrypt_id"));

  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  function handleChange1(e) {
    console.log(e.target.files);
    setFile1(URL.createObjectURL(e.target.files[0]));
  }
  function handleChange2(e) {
    console.log(e.target.files);
    setFile2(URL.createObjectURL(e.target.files[0]));
  }
  console.log(file);

  const { register, handleSubmit, errors } = useForm();

  const [param, setParam] = useState({
    image1: [],
    image2: [],
    image3: [],
    branch_id: "",
  });

  console.log("image", param.image1);
  useEffect(() => {
    fetch_data()
  }, [])
  

  function createGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
  const uploadImageFunc = (formData) => {
    const url = API_BASE_URL + `api/masters/product/uploadImage`;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "auth-token": localStorage.getItem("jwl_token"),
      },
    };

    post(url, formData, config).then(
      (response) => {
        // alert("Image Saved Successfully");
      },
      (error) => {
        alert("something went wrong!");
      }
    );
  };

  const _Save = () => {
    // alert("test passed");
    // debugger;
    for (var i = 1; i <= 3; i++) {
      // debugger;
      let file = document.getElementById("image" + i);
      let files = file.files;
      let formData = new FormData();
      console.log("-----image----", files);
      if (files.length > 0) {
        for (var j = 0; j < files.length; j++) {
          let file_name = "image-" + createGuid();
          // param.image1.push(
          //   file_name +
          //     "" +
          //     files[j].name.substring(files[j].name.lastIndexOf("."))
          // );

          param.image1.push(
            file_name + 
            files[j].name.substring(files[j].name.lastIndexOf(".")).replace(".jpeg", ".jpg")
          );
          
          formData.append("files", files[j], file_name);
        }
      }
      uploadImageFunc(formData);
      console.log("------", formData);
    }
    postMethod("api/sms/images/sessionimages", param).then((data) => {
      // debugger
      if (data.data) {
        alert("Image Saved Successfully");
        window.location.reload();
      } else {
        alert("Something Went Wrong");
      }
    });
  };

  const fetch_data=()=>{
    postMethod("public/Trialimages", { branch_id: branchEncryptID }).then((data) => {
      // debugger
      if (data.data.length > 0) {
        let images=data.data[0];
        setFile(`${API_BASE_URL}/Images/${images.image1}`)
        setFile1(`${API_BASE_URL}/Images/${images.image2}`)
        setFile2(`${API_BASE_URL}/Images/${images.image3}`)
        console.log(`${API_BASE_URL}/Images/${images.image1}`);
      }
    });
  }

  return (
    <>
      <Form onSubmit={handleSubmit(_Save)}>
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Image Upload</h3>
            </div>
          </div>
          <div className="card-body">
            <Row>
              <Col sm={12}>
                <Label className="text-danger pull-right">
                  Please save all images
                </Label>
              </Col>
            </Row>
            <Row>
              <Col sm={4} className="d-flex flex-column">
                <Label>
                  Main Image{" "}
                  <span className="text-danger">(width should be 375px)</span>
                  <Input
                    type="file"
                    onChange={handleChange}
                    id="image1"
                    className="mt-2"
                    name="image1"
                    required
                  ></Input>
                </Label>
                <img
                  src={file}
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "contain",
                  }}
                />
                <button className="btn btn-danger mt-4 w-25" onClick={setFile}>
                  clear
                </button>
              </Col>
              <Col sm={4} className="d-flex flex-column">
                <Label>
                  Image 2{" "}
                  <span className="text-danger">(width should be 315px)</span>
                  <Input
                    type="file"
                    id="image2"
                    className="mt-2 image1"
                    onChange={handleChange1}
                    required
                  ></Input>
                </Label>
                <img
                  src={file1}
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "contain",
                  }}
                />
                <button className="btn btn-danger mt-4 w-25" onClick={setFile1}>
                  clear
                </button>
              </Col>
              <Col sm={4} className="d-flex flex-column">
                <Label>
                  Image 3{" "}
                  <span className="text-danger">(width should be 315px)</span>
                  <Input
                    type="file"
                    id="image3"
                    className="mt-2 image1"
                    onChange={handleChange2}
                    required
                  ></Input>
                </Label>
                <img
                  src={file2}
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "contain",
                  }}
                />
                <button className="btn btn-danger mt-4 w-25" onClick={setFile2}>
                  clear
                </button>
              </Col>
              <Col sm={12}>
                <div className="border-top border-2 mt-5">
                  <button type="submit" className="btn btn-primary mt-4 w-30">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </>
  );
};

export default SessionImage;
