import Anniversary from "../app/screens/customerCRMCustomized/anniversary";
import Birthday from "../app/screens/customerCRMCustomized/birthday";
import CustomerHome from "../app/screens/customerCRMCustomized/home";
import Instragram from "../app/screens/customerCRMCustomized/instagram";
import CustomerProducts from "../app/screens/customerCRMCustomized/products";
import CustomerProductview from "../app/screens/customerCRMCustomized/productview";
import CustomerProfile from "../app/screens/customerCRMCustomized/profile";
import Referral from "../app/screens/customerCRMCustomized/referral";
import Specialvoucher from "../app/screens/customerCRMCustomized/specialvoucher";
import CustomerThankyou from "../app/screens/customerCRMCustomized/thank-you";
import Uploaddesignvoucher from "../app/screens/customerCRMCustomized/uploaddesignvoucher";
import CustomerWishlist from "../app/screens/customerCRMCustomized/wishlist";
export const CustomizedCustomerRoutes = [
  {
    path: "/customersession/home",
    name: "home",
    icon: "flaticon-web",
    child: false,
    component: CustomerHome,
  },
  {
    path: "/customersession/products",
    name: "Products",
    icon: "flaticon-web",
    child: false,
    component: CustomerProducts,
  },
  {
    path: "/customersession/product-view",
    name: "Product View",
    icon: "flaticon-web",
    child: false,
    component: CustomerProductview,
  },
  {
    path: "/customersession/wish-list",
    name: "wish list",
    icon: "flaticon-web",
    child: false,
    component: CustomerWishlist,
  },
  {
    path: "/customersession/thank-you",
    name: "thank you",
    icon: "flaticon-web",
    child: false,
    component: CustomerThankyou,
  },
  {
    path: "/customersession/referral",
    name: "referral",
    icon: "flaticon-web",
    child: false,
    component: Referral,
  },
  {
    path: "/customersession/birthday",
    name: "birthday",
    icon: "flaticon-web",
    child: false,
    component: Birthday,
  },
  {
    path: "/customersession/anniversary",
    name: "Anniversary",
    icon: "flaticon-web",
    child: false,
    component: Anniversary,
  },
  {
    path: "/customersession/instagram",
    name: "Instragram",
    icon: "flaticon-web",
    child: false,
    component: Instragram,
  },
  {
    path: "/customersession/special-voucher",
    name: "special voucher",
    icon: "flaticon-web",
    child: false,
    component: Specialvoucher,
  },
  {
    path: "/customersession/upload-design",
    name: "upload design",
    icon: "flaticon-web",
    child: false,
    component: Uploaddesignvoucher,
  },
  {
    path: "/customersession/profile",
    name: "profile",
    icon: "flaticon-web",
    child: false,
    component: CustomerProfile,
  }
];
