import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../../../_services/constant";
import OwlCarousel from "react-owl-carousel2";
import { postMethod } from "../../../../_services/_postMethod";

export default function CustomerVoucherCom(props) {
  const [voucherList, setVoucherList] = useState([]);
  const options = {
    responsive: {
      0: {
        items: 2,
        margin: 10,
      },
      600: {
        items: 2,
        margin: 10,
      },
      1000: {
        items: 3,
        margin: 30,
      },
    },
    nav: true,
    dots: true,
    autoplay: true,
    stagePadding: 10,
    loop: false,
    rewind: true,
  };
  useEffect(() => {
    postMethod("api/session/voucherlist", {}).then((data) => {
      setVoucherList(data.data);
    });
  }, []);

  return (
    <>
      {(voucherList.length>0)? (
        <>
        <div className="container">
          <div className="uniq">
            <h2 className=" text-left">Offers/Voucher</h2>
          </div>
        </div>
        <div className="container my-4 px-0">
          <OwlCarousel options={options}>
            {voucherList.map((prop, i) => (
              <img
              style={{ width: 350, height: window.screen.width>480?170:100, border: "1px solid #ddd", objectFit:'cover' }}
                onClick={() => {
                  // console.log(prop.voucher_id);
                  var tran = prop.voucher_id;

                  if (prop.voucher_type == "birthday") {
                    window.location = `/general/birthday?id=${tran}`;
                  } else if (prop.voucher_type == "anniversary") {
                    window.location = `/general/anniversary?id=${tran}`;
                  } else if (prop.voucher_type == "instagram") {
                    window.location = `/general/instagram?id=${tran}`;
                  } else if (prop.voucher_type == "referral") {
                    window.location = `/general/referral?id=${tran}`;
                  } else if (prop.voucher_type == "uploadDesign") {
                    window.location = `/general/upload-design?id=${tran}`;
                  } else {
                    window.location = `/general/special-voucher?id=${tran}`;
                  }
                }}
                src={API_BASE_URL + "CustomerVoucher/" + prop.image_path}
                className="img-fluid"
                alt=""
              />
            ))}
          </OwlCarousel>
        </div>
        </>
      ): null}
      <div className="clr"></div>
    </>
  );
}
