import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useLocation } from "react-router-dom";
import { Button, Form, FormGroup, Label, Input, FormText, Row, Col, Alert } from "reactstrap";
import {
  postMethod,
  validateMobile,
  singleParam,
  editUserGrid,
} from "../../../../_services/_postMethod";
import Loader from "../../../modules/loader";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField'; 

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AddSubcategory = (props) => {
  let queryParams = useQuery();
  const id = queryParams.get("id") == "" ? null : queryParams.get("id");
  const { register, handleSubmit, errors } = useForm();
  const [load, setLoad] = useState(false);
  const [loadInser, setLoadInsert] = useState(false);
  const [loadDelete, setLoadDelete] = useState(false);
  const [category, setCategory] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [param, setParam] = useState({
    category_id: "",
    subcategory_name: "",
    description: "",
  });
  const [success, setSuccess] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);
  const insertData = (values) => {   
      setLoadInsert(true); 
    values.subcategory_name =param.subcategory_name;
    values.description  = param.description;
    // console.log(values);
    postMethod("api/masters/product/subcategory/insert", values).then((data) => { 
      if (data.data[0].valid) {
        setSuccess(true);
        setTimeout(() => {
          props.history.push("/branch/product/subcategory");
        }, 2000);
      }
      else
      {
          setLoadInsert(false);
      }
    });
  };

  const handleDelete = () => {
    var proceed = window.confirm("Do you Really Wish to Delete this Entry..?!");

    if (proceed) {
        setLoadDelete(true);
      postMethod("api/masters/product/subcategory/delete", {
        subcategory_id: param.subcategory_id,
      }).then((data) => {
        if (data.data[0].valid) {
          setSuccessDelete(true);
          setTimeout(() => {
            props.history.push("/branch/product/subcategory");
          }, 2000);
        }
         else{
           setLoadDelete(true);
        }
      });
    }
  };

   useEffect(() => { 
     subcategory();
  }, [param.category_id]);

     useEffect(() => { 
     subcategory();
  }, [param.subcategory_name]);

  const subcategory = () =>{
      postMethod("api/masters/product/subcategory/getsubCategory", {category_id:param.category_id,search: param.subcategory_name}).then((data) => setSubCategoryList(data.data));
  }

  useEffect(() => { 

    // document.getElementById("user_branch_id").disabled = true;

    if (id !== null) {
      setLoad(true);
      var temp = window.atob(id);    
      postMethod("api/masters/product/subcategory/preview", { subcategory_id: temp }).then(
        (data) => {       
          setParam(data.data);
           subcategory(data.data.category_id);
          setLoad(false);
        }
      );
    }
    postMethod("api/masters/product/subcategory/getCategory", {}).then((data) => setCategory(data.data));
  }, []);
  return (
    <>
      {load ? <Loader /> : null}
      <Form onSubmit={handleSubmit(insertData)}>
        <input
          type="hidden"
          name="subcategory_id"
          value={id == null ? "0" : param.subcategory_id}
          ref={register}
        />
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Add Product SubCategory</h3>
            </div>
          </div>
          <div className="card-body">
            <Row>
              <Col sm={4}>
                <FormGroup>
                  <Label>Category</Label>
                  <select
                    className="form-control h-auto py-2 px-6"
                    onChange={(e) => {
                      setParam({ ...param, category_id: e.target.value });                    
                    }}
                    name="category_id"
                    value={param.category_id}
                    ref={register}
                    required
                  >
                    <option value="">--Select--</option>
                    {category.map((prop, i) => {
                      return (
                        <option key={i} value={prop.category_id}>
                          {prop.category_name}
                        </option>
                      );
                    })}
                  </select>
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label className="mb-0">SubCategory Name</Label>
                   <Autocomplete
                      id="CategoryName"
                      freeSolo
                      size="small"                     
                      value={param.subcategory_name} 
                      options={subCategoryList.map((option) => option.subcategory_name)}  
                       onChange={(event, value) => {  
                      setParam({ ...param, subcategory_name: value });
                      }}   
                       renderInput={(params) => (
                        <TextField {...params}
                        required
                          onChange={(e) => {                            
                            setParam({ ...param, subcategory_name: e.target.value })
                        }}
                          margin="normal" variant="outlined" />
                      )}
                    />
                
                </FormGroup>
              </Col>
              <Col sm={4}>
                  <FormGroup>
                    <Label className="mb-0">Description</Label>
                    <Input type="textarea" className="mt-2" id="sub_description"
                     value={param.description} 
                    onChange={(e) => setParam({...param,description: e.target.value})}
                     ></Input>
                  </FormGroup>
              </Col>
            </Row>
            {success ? <Alert color="success">Saved Successfully</Alert> : null}
            {successDelete ? <Alert color="danger">Deleted</Alert> : null}
          </div>
          <div className="card-footer text-right">
            <a href="/branch/product/subcategory" className="btn btn-secondary mr-2">
              Cancel
            </a>
               {id && (
              loadDelete ? <button type="button" className="btn btn-danger mr-2">
                Deleting..
              </button> : 
               <button type="button" onClick={handleDelete} className="btn btn-danger mr-2">
                Delete
              </button>
            )}
          {loadInser ? 
             <button type="button" className="btn btn-primary ">
              Saving..
            </button> :  <button type="submit" className="btn btn-primary ">
              Save
            </button>}
          </div>
        </div>
      </Form>
    </>
  );
};

export default AddSubcategory;
