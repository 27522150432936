import React, { useEffect, useState } from "react";
import CustomerHeader from "./components/header";
import CustomerFooter from "./components/footer";
import { useParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { postMethod } from "../../../_services/_postMethod";
import { API_BASE_URL } from "../../../_services/constant";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { findAllByDisplayValue } from "@testing-library/react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import NumberFormat from "react-number-format";

import CustomerVoucherComList from "./components/voucherList";
import CustomertermsAndCondition from "./components/termsAndCondition";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#000",
    backgroundColor: "#fff",
  },
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Referral(props) {
  let queryParams = useQuery();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [checkedTerms, setcheckedTerms] = useState({
    check: false,
    comment: false,
  });
  const [voucherList, setVoucherList] = useState([]);
  const [mobileVal, setmobileVal] = useState(false);
  const id = queryParams.get("id") == "0" ? null : queryParams.get("id");
  const { register, handleSubmit, errors } = useForm();
  const { buttonLabel, className } = props;

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [param, setParam] = useState({
    tran_id: "0",
    mobile: "",
    voucher_id: id,
    disable: false,
  });
  const bull = <span className={classes.bullet}>•</span>;

  const handleToggle = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    setOpen(true);
    postMethod("api/customers/customer/customerVoucherDetails", param).then(
      (data) => {
        setParam({ ...param, ...data });
        setOpen(false);
      }
    );
    postMethod("api/customers/customer/voucherlist", { branch_id: "1" }).then(
      (data) => {
        setVoucherList(data);
      }
    );
  }, []);

  const insert = (values) => {
    var mobile = values.mobile
      .trim()
      .replace("+91", "")
      .replace(/ /g, "")
      .replace(/_/g, "");
    values.mobile = mobile;

    if (mobile.length != 10) {
      setmobileVal(true);
    } else if (!checkedTerms.check) {
      setcheckedTerms({ ...checkedTerms, comment: true });
    } else {
      var ParamValue = {
        tran_id: "0",
        voucher_id: id,
        mobile: mobile,
      };
      postMethod(
        "api/customers/customer/InsertReferralVoucher",
        ParamValue
      ).then((data) => {
        toggle();
      });
    }
  };

  return (
    <>
      <div>
        <Modal
          isOpen={modal}
          backdrop="static"
          centered="true"
          className={className}
        >
          <ModalBody>Thank you for referral .</ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                window.location.pathname = "customers/home";
              }}
            >
              Ok
            </Button>
          </ModalFooter>
        </Modal>
      </div>

      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CustomerHeader />
      <div className="clr"></div>
      <div className="container toppadhead">
        <div className="row toppad5 mtoppad2">
          <div className="col-sm-12">
            <div>
              <img
                src={
                  API_BASE_URL + "CustomerVoucherBanner/" + param.banner_image
                }
                className=" d-none d-lg-block d-md-block imgcurve custom-banner custom-banner"
              />
              <img
                src={
                  API_BASE_URL + "CustomerVoucherBanner/" + param.banner_image
                }
                className=" d-none d-lg-none d-md-none d-block imgcurve custom-banner custom-banner"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="clr"></div>
      <div className="container-fluid">
        <div className="container70">
          <div className="row toppad5 mtoppad2">
            <div className="col-12 col-sm-8 col-lg-8 toppad7">
              <div className="row">
                <div className="col-12 col-sm-8 col-lg-8">
                  <div className="voucher-box">
                    <div className="item clearfix">
                      <span className="circle-top"></span>
                      <span className="circle-bottom"></span>

                      <div className="item-left">
                        <div className="price-style">
                          <span className="price"> {param.voucher_value}</span>
                          <span className="price-unit"></span>
                          <span className="price-off"></span>
                        </div>
                      </div>
                      <div className="item-right">
                        <p className="p-one">{param.voucher_heading}</p>
                        <p className="p-two">
                          {" "}
                          <i className="iconfont icontime"></i>
                          {moment(param.start_date).format(
                            "MMM Do YYYY"
                          )} - {moment(param.end_date).format("MMM Do YYYY")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <form onSubmit={handleSubmit(insert)}>
                  <div className="col-12 col-sm-12 col-lg-12 toppad11">
                    <div className="subs subs1 mb-3">
                      <NumberFormat
                        getInputRef={register}
                        name="mobile"
                        onValueChange={(e) => {
                          setmobileVal(false);
                        }}
                        format="+91 ### ### ####"
                        allowEmptyFormatting
                        mask="_"
                      />

                      {mobileVal ? (
                        <p style={{ color: "red" }}>Incorrect mobile no.</p>
                      ) : null}

                      <span className="highlight"></span>
                      <span className="bar bar1"></span>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-lg-12 mtoppad5">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={checkedTerms.check}
                        checked={checkedTerms.check}
                        id="flexCheckDefault"
                        onClick={(e) => {
                          setcheckedTerms({
                            ...checkedTerms,
                            check: !checkedTerms.check,
                            comment: false,
                          });
                        }}
                      />
                      <label
                        className="form-check-label"
                        for="flexCheckDefault"
                      >
                        Terms &amp; Conditions apply
                      </label>
                    </div>
                    {checkedTerms.comment ? (
                      <label
                        className="form-check-label color-red"
                        for="flexCheckDefault"
                      >
                        Please check Terms &amp; Conditions apply
                      </label>
                    ) : null}
                  </div>
                  <div className="col-12 col-sm-3 col-lg-12 toppad4">
                    {param.disable ? (
                      <label
                        className="form-check-label color-red"
                        for="flexCheckDefault"
                      >
                        Note: Go to showroom to voucher redeem.
                      </label>
                    ) : null}
                    <div className="sbmt sbmt1">
                      {param.disable ? null : (
                        <input type="submit" value="Get" className="sbmtbtn1" />
                      )}
                    </div>
                  </div>
                </form>
                <CustomertermsAndCondition id={id} />
              </div>
            </div>
            <div className="col-sm-4 secbdr mtoppad3 m15 other-offer-mobile d-none d-lg-block d-print-block secbdr">
              <h1>Other Offers</h1>

              <CustomerVoucherComList />
            </div>
          </div>
        </div>
      </div>
      <div className="clr"></div>
      <div className="container-fluid"></div>
      <div className="d-none d-lg-block d-print-block secbdr">
        <CustomerFooter />
      </div>
    </>
  );
}
