import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { MDBCol, MDBIcon } from "mdbreact";
import { Label, Input, Alert } from "reactstrap";

import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
} from "reactstrap";
import { postMethod } from "../../../../_services/_postMethod";
import { API_BASE_URL } from "../../../../_services/constant";

export default function Whatsapp2(props) {
  const { custInfo, className } = props;
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const uniqueMobilesArray = custInfo.reduce((accumulator, currentObject) => {
    const mobileExists = accumulator.some(
      (obj) => obj.mobile === currentObject.mobile
    );

    if (!mobileExists) {
      accumulator.push(currentObject);
    }

    return accumulator;
  }, []);

  // --------logo-----------
  const[logo,setLogo] = useState(null);

  const handlerLogo = () => {
   
    postMethod("api/customervisit/get_branch_logo").then(
      (data) => {
        setLogo(data.data[0].logo);
      }
    );
  }

  useEffect(() => {
    handlerLogo();
  }, []);

  //  --------------------forTextarea start----------------------------------------------

  const [text, setText] = useState("");
  const maxLength = 400;
  const handleChange = (event) => {
    const newText = event.target.value;

    if (newText.length <= maxLength) {
      setText(newText);
    }
  };

  //  --------------------forTextarea end----------------------------------------------

  // ----------------------forImage start----------------------------------------------

  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Set the selected image
      setSelectedImage(file);

      // Generate a preview URL for the selected image
      const previewURL = URL.createObjectURL(file);
      setImagePreview(previewURL);
    }
  };

  // ----------------------forImage end----------------------------------------------

 // --------------Pagination start--------------------------------------

const [currentPage, setCurrentPage] = useState(1);
const [inputValue, setInputValue] = useState(currentPage); // Add state to handle input value
const recordsPerPage = 20;
const lastIndex = currentPage * recordsPerPage;
const firstIndex = lastIndex - recordsPerPage;
const records = uniqueMobilesArray.slice(firstIndex, lastIndex);
const npage = Math.ceil(uniqueMobilesArray.length / recordsPerPage);

// Navigate to First Page
function firstPage() {
  setCurrentPage(1);
  setInputValue(1); // Keep input value in sync
  setSelectAll(!selectAll);
}

// Navigate to Previous Page
function prevPage() {
  if (currentPage !== 1) {
    const newPage = currentPage - 1;
    setCurrentPage(newPage);
    setInputValue(newPage); // Keep input value in sync
    setSelectAll(!selectAll);
  }
}

// Navigate to Next Page
function nextPage() {
  if (currentPage !== npage) {
    const newPage = currentPage + 1;
    setCurrentPage(newPage);
    setInputValue(newPage); // Keep input value in sync
    setSelectAll(!selectAll);
  }
}

// Navigate to Last Page
function lastPage() {
  setCurrentPage(npage);
  setInputValue(npage); // Keep input value in sync
  setSelectAll(!selectAll);
}

// Handle input change and temporary value update
function handleInputChange1(e) {
  setInputValue(e.target.value);
}

// Handle input submit (either on blur or enter key press)
function handlePageJump(e) {
  if (e.key === "Enter" || e.type === "blur") {
    const newPage = parseInt(inputValue, 10);
    if (!isNaN(newPage) && newPage >= 1 && newPage <= npage) {
      setCurrentPage(newPage);
      setSelectAll(!selectAll);
    } else {
      setInputValue(currentPage); // Reset if invalid
    }
  }
}

  //  ----------------------forselectId start----------------------------------------------
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(records.map((row) => row.mobile));
    }
    setSelectAll(!selectAll);
  };

  const toggleRow = (rowId) => {
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
  };

  // -------------selectedId end--------------------------------------------------------
  // ----------------------------Bluck message on whatsapp------------

  // To Send Message on whatsapp------------------------------------

  const saveWhatsappMessage = async () => {
    const formData = new FormData();
    formData.append("file", selectedImage);
    formData.append("mobiles", selectedRows);
    formData.append("message", text);
    formData.append("bb_id", localStorage.getItem("jwl_branch_id"));

    try {
      const response = await axios.post(
        API_BASE_URL + "api/customervisit/whatsapp/bulk/message",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            "auth-token": localStorage.getItem("jwl_token"),
          },
        }
      );
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  function onUpload() {
    if (selectedRows.length > 0) {
      saveWhatsappMessage();
      setSelectAll(false);
      toggle();
      setSelectedImage(null);
      setImagePreview(null);
      setSelectedRows([]);
      setText("");
      alert("send");
    } else {
      alert("please choose mobile number.");
    }
  }

  // ----------------------for Seach Parameter

  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = records.filter((item) =>
    Object.values(item).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );
  // ------------------------------------------------------------------
  return (
    <>
      <Button color="primary" onClick={toggle}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 48 48"
          width="28px"
          height="28px"
        >
          <path
            fill="#fff"
            d="M4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98c-0.001,0,0,0,0,0h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303z"
          />
          <path
            fill="#fff"
            d="M4.868,43.803c-0.132,0-0.26-0.052-0.355-0.148c-0.125-0.127-0.174-0.312-0.127-0.483l2.639-9.636c-1.636-2.906-2.499-6.206-2.497-9.556C4.532,13.238,13.273,4.5,24.014,4.5c5.21,0.002,10.105,2.031,13.784,5.713c3.679,3.683,5.704,8.577,5.702,13.781c-0.004,10.741-8.746,19.48-19.486,19.48c-3.189-0.001-6.344-0.788-9.144-2.277l-9.875,2.589C4.953,43.798,4.911,43.803,4.868,43.803z"
          />
          <path
            fill="#cfd8dc"
            d="M24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,4C24.014,4,24.014,4,24.014,4C12.998,4,4.032,12.962,4.027,23.979c-0.001,3.367,0.849,6.685,2.461,9.622l-2.585,9.439c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98c0.002-5.339-2.075-10.359-5.848-14.135C34.378,6.083,29.357,4.002,24.014,4L24.014,4z"
          />
          <path
            fill="#40c351"
            d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z"
          />
          <path
            fill="#fff"
            fill-rule="evenodd"
            d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z"
            clip-rule="evenodd"
          />
        </svg>
      </Button>

      <Modal
        isOpen={modal}
        zIndex="99999"
        size="lg"
        backdrop="static"
        centered="true"
      >
        <ModalHeader>
          <Row style={{ marginBottom: "10px" }}>
            <Col sm="6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                width="28px"
                height="28px"
              >
                <path
                  fill="#fff"
                  d="M4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98c-0.001,0,0,0,0,0h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303z"
                />
                <path
                  fill="#fff"
                  d="M4.868,43.803c-0.132,0-0.26-0.052-0.355-0.148c-0.125-0.127-0.174-0.312-0.127-0.483l2.639-9.636c-1.636-2.906-2.499-6.206-2.497-9.556C4.532,13.238,13.273,4.5,24.014,4.5c5.21,0.002,10.105,2.031,13.784,5.713c3.679,3.683,5.704,8.577,5.702,13.781c-0.004,10.741-8.746,19.48-19.486,19.48c-3.189-0.001-6.344-0.788-9.144-2.277l-9.875,2.589C4.953,43.798,4.911,43.803,4.868,43.803z"
                />
                <path
                  fill="#cfd8dc"
                  d="M24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,4C24.014,4,24.014,4,24.014,4C12.998,4,4.032,12.962,4.027,23.979c-0.001,3.367,0.849,6.685,2.461,9.622l-2.585,9.439c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98c0.002-5.339-2.075-10.359-5.848-14.135C34.378,6.083,29.357,4.002,24.014,4L24.014,4z"
                />
                <path
                  fill="#40c351"
                  d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z"
                />
                <path
                  fill="#fff"
                  fill-rule="evenodd"
                  d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z"
                  clip-rule="evenodd"
                />
              </svg>
              Whats-App Messenger
            </Col>

            <Col sm="6">
              <i
                className="fa fa-close pull-right pointerShow"
                onClick={toggle}
              ></i>
            </Col>
          </Row>
        </ModalHeader>

        <ModalBody className="table-responsive"
            style={{ maxHeight: "450px" }}>
          <MDBCol md="6">
            <form className="form-inline mt-4 mb-4">
              <MDBIcon icon="search" />
              <input
                className="form-control form-control-sm ml-3 w-75"
                type="text"
                placeholder="Search..."
                aria-label="Search"
                value={searchTerm}
                onChange={handleInputChange}
              />
            </form>
          </MDBCol>

          <Row>
            <Col>
              <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                <Table dark>
                  <thead>
                    <tr>
                      <th>
                        <button onClick={toggleSelectAll}>
                          {selectAll ? "None" : "All"}
                        </button>
                      </th>
                      <th>Name</th>
                      <th>Mobile</th>
                      <th>Category</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((item, i) => (
                      <tr key={i}>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedRows.includes(item.mobile)}
                            onChange={() => toggleRow(item.mobile)}
                          />
                        </td>
                        <td>{item.full_name || item.customer_name}</td>
                        <td>{item.mobile}</td>
                        <td>{item.category_name || item.customer_category}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>

            <Col>
              <Row>
                <textarea
                  value={text}
                  onChange={handleChange}
                  required={true}
                  placeholder="Type here..."
                  rows={10}
                  cols={50}
                />

                <h5 style={{ color: "blue" }}>
                  {maxLength - text.length}/{maxLength}
                </h5>
              </Row>
              <Row>
                <Label>
                  <Table>
                    <tbody>
                      <tr>
                        <td>
                          <Input
                            className="mt-15 image-cart"
                            type="file"
                            name="Choose Image"
                            onChange={handleImageChange}
                          ></Input>
                        </td>

                        {
                        selectedImage ? (
                          <td>
                          <img
                            style={{
                              width: "150px",
                              height: "150px",
                              borderRadius: "4px",
                            }}
                            src={imagePreview}
                            alt="Image"
                            className="mr-10"
                          />
                        </td>
                        ) : (
                          <td>
                             <img
                            style={{
                              width: "150px",
                              height: "150px",
                              borderRadius: "4px",
                            }}
                            src={API_BASE_URL +
                              "BranchLogo/" +logo}
                            alt="Image"
                            className="mr-10"
                            />
                          </td>
                        )
                      }

                       
                      </tr>
                    </tbody>
                  </Table>
                  <hr />
                </Label>
              </Row>
              <Row>
                <Button
                  color="primary"
                  style={{ marginTop: "2px" }}
                  onClick={() => onUpload()}
                >
                  Send
                </Button>
              </Row>
            </Col>
          </Row>
          <Row>
  {/* Pagination Controls */}
  <div style={{ margin: "20px" }}>
    <nav>
      <ul className="pagination">
        {/* First Button */}
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <a href="#" className="page-link" onClick={firstPage}>
            First
          </a>
        </li>

        {/* Previous Button */}
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <a href="#" className="page-link" onClick={prevPage}>
            Previous
          </a>
        </li>

        {/* Current Page Input */}
        <li className="page-item">
          <input
            type="number"
            value={inputValue} // Controlled input tied to inputValue state
            onChange={handleInputChange1} // Update inputValue on change
            onKeyPress={handlePageJump}  // Handle "Enter" press
            onBlur={handlePageJump} // Handle blur event to trigger page jump
            className="page-input"
            style={{ width: "30px",height:"30px", textAlign: "center", margin:"1px" }}
          />
        </li>

        {/* Next Button */}
        <li className={`page-item ${currentPage === npage ? "disabled" : ""}`}>
          <a href="#" className="page-link" onClick={nextPage}>
            Next
          </a>
        </li>

        {/* Last Button */}
        <li className={`page-item ${currentPage === npage ? "disabled" : ""}`}>
          <a href="#" className="page-link" onClick={lastPage}>
            Last
          </a>
        </li>
      </ul>
    </nav>
    {/* Showing current records info */}
    <p>Showing {firstIndex + 1} to {Math.min(lastIndex, uniqueMobilesArray.length)} of {uniqueMobilesArray.length} entries</p>
  </div>
</Row>
        </ModalBody>
      </Modal>
    </>
  );
}
