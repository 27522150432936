import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../../../_services/constant";
import { postMethod } from "../../../../_services/_postMethod";

export default function CustomertermsAndCondition(props) {
  const { id } = props;
    const [ListObject, setListObject] = useState([]);    
    useEffect(() => { 
      postMethod("api/transactions/term/voucherList", {"voucher_id": id}).then((data) => {
        setListObject(data);
      });
     
    }, []);
    
  return (
    <>
     <div className="col-12 col-sm-12 col-lg-12 toppad10">
                  <h2></h2>
                  <div className="card">
                    <h5 className="card-header">Terms &amp; Conditions</h5>
                    <div className="card-body">
                     
                      <ul>
                      {ListObject.length > 0 ? ListObject.map((prop, i) => (          
                          <li>
                        {prop.text}
                          </li>

                          )) : null}
                      </ul>
                    </div>
                  </div>
                </div>
 
      <div className="clr"></div>
    </>
  );
}
