import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useLocation } from "react-router-dom";
import { Button, Form, FormGroup, Label, Input, FormText, Row, Col, Alert } from "reactstrap";
import {
  postMethod,
  validateMobile,
  singleParam,
  editUserGrid,
} from "../../../../_services/_postMethod";
import Loader from "../../../modules/loader";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField'; 

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AddCustomerCategory = (props) => {
  let queryParams = useQuery();
  const id = queryParams.get("id") == "" ? null : queryParams.get("id");
  const { register, handleSubmit, errors } = useForm();
  const [load, setLoad] = useState(false);
  const [loadInser, setLoadInsert] = useState(false);
  const [loadDelete, setLoadDelete] = useState(false); 
  const [categoryList, setCategoryList] = useState([]);
  const [branchData, setBranch] = useState({
    category_name: "",
  });
  const [success, setSuccess] = useState(false);
  const [deleteentry, setseleteentry] = useState(false);
  const [deleteentryMsg, setseleteentryMsg] = useState(false);

  const insertData = (values) => { 
     setLoadInsert(true);
     values.category_name = branchData.category_name;
    postMethod("api/masters/customer/category/insert", values).then((data) => {    
      if (data.data[0].valid) {
        setSuccess(true);
        setTimeout(() => {
          props.history.push("/branch/customer/category");
        }, 2000);
      }
      else{
          setLoadInsert(false);
      }
    });
  };

  const handleDelete = () => {
    var proceed = window.confirm("Do you Really Wish to Delete this Entry..?!");

    if (proceed) {
      setLoadDelete(true);
      postMethod("api/masters/customer/category/delete", {
        category_id: branchData.category_id,
      }).then((data) => {      
        if (data.data[0].valid) {
          setseleteentry(true);
          setseleteentryMsg(data.data[0].msg);
          setTimeout(() => {
            props.history.push("/branch/customer/category");
          }, 2000);
        }
        else{
          setseleteentry(true);
          setseleteentryMsg(data.error);
          setLoadDelete(false);
          setTimeout(() => {
            setseleteentry(false);
          }, 4000);
        }
      });
    }
  };
  const getcategoryList = () =>{
      postMethod("api/masters/customer/category/getCategory", {search:branchData.category_name}).then((data) => setCategoryList(data));
  }
  useEffect(() => {
   getcategoryList();
  }, [branchData.category_name]);
  
  useEffect(() => {
    if (id !== null) {
      setLoad(true);
      var temp = window.atob(id);
      postMethod("api/masters/customer/category/preview", { category_id: temp }).then((data) => {     
        setBranch(data.data[0]);
        setLoad(false);
      });
    }
    getcategoryList();
  }, []);
  return (
    <>
      {load ? <Loader /> : null}
      <Form onSubmit={handleSubmit(insertData)}>
        <input
          type="hidden"
          name="category_id"
          value={id == null ? "0" : branchData.category_id}
          ref={register}
        />
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Add Customer Category</h3>
            </div>
          </div>
          <div className="card-body">
            <Row>
              <Col sm={4}>
                <FormGroup>
                  <Label>Category Name</Label>
                  <Autocomplete
                      id="CategoryName"
                      freeSolo
                      size="small"
                      value={branchData.category_name} 
                      options={categoryList.map((option) => option.category_name)}  
                       onChange={(event, value) => {  
                      setBranch({ ...branchData, category_name: value });
                      }}   
                       renderInput={(params) => (
                        <TextField {...params}
                          onChange={(e) => setBranch({ ...branchData, category_name: e.target.value })}
                          margin="normal" variant="outlined" />
                      )}
                    />

                 
                </FormGroup>
              </Col>
            </Row>
            {success ? <Alert color="success">Saved Successfully</Alert> : null}
            {deleteentry ? <Alert color="danger">{deleteentryMsg}</Alert> : null}
          </div>
          <div className="card-footer text-right">
            <a href="/branch/customer/category" className="btn btn-secondary mr-2">
              Cancel
            </a>
              {id && (
              loadDelete ? <button type="button" className="btn btn-danger mr-2">
                Deleting..
              </button> : 
               <button type="button" onClick={handleDelete} className="btn btn-danger mr-2">
                Delete
              </button>
            )}
          
            {loadInser ? 
             <button type="button" className="btn btn-primary ">
              Saving..
            </button> :  <button type="submit" className="btn btn-primary ">
              Save
            </button>}
          </div>
        </div>
      </Form>
    </>
  );
};

export default AddCustomerCategory;
