import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useLocation } from "react-router-dom";
import { Button, Form, FormGroup, Label, FormText, Row, Col, Alert } from "reactstrap";
import { API_BASE_URL } from "../../../../_services/constant";
import { Card } from "@material-ui/core";
import { post } from "axios";

import {postMethod} from "../../../../_services/_postMethod";
import Loader from "../../../modules/loader";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AddCompanyUser = (props) => {
  let queryParams = useQuery();
  const id = queryParams.get("id") == "" ? null : queryParams.get("id");
  const { register, handleSubmit, errors } = useForm();
  const [companyList, setCompanyList] = useState([]);
  const [load, setLoad] = useState(false);
  const [loadInser, setLoadInsert] = useState(false);
  const [loadDelete, setLoadDelete] = useState(false);
  const [param, setParam] = useState({
    tran_id: id == null ? "0" : id,
    company_id: "",
    full_name: "",
    mobile: "",
    email: "",
    password: "",
    disable: false
  });
  const [success, setSuccess] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);

  const insertData = (values) => {
     setLoadInsert(true);
    postMethod("api/companys/companyUser/insert", values).then((dataD) => {    
       var data = dataD.data[0];
      if (data) {   
          setSuccess(true); 
       
          setTimeout(() => {
            props.history.push("/admin/company-users"); 
          }, 2000);
        
      }
      else{
           setLoadInsert(false);
      
      }
    });
  };

  const handleDelete = () => {
    var proceed = window.confirm("Do you Really Wish to Delete this Entry..?!");

    if (proceed) {
        setLoadDelete(true);
      postMethod("api/companys/companyUser/delete", { tran_id: param.tran_id }).then((dataD) => {
        var data = dataD.data[0];
        if (data.valid) {
          setSuccessDelete(true);
          setTimeout(() => {
            props.history.push("/admin/company-users");
          }, 2000);
        }
        else{
            setLoadDelete(false);
        }
      });
    }
  };



  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        marginLeft: -10,
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 310,
      },
    },
  };

  useEffect(() => {
    postMethod("api/companys/companyUser/companyList", { tran_id: temp }).then((data) => {
        setCompanyList(data.data);     
    });
    
    if (id !== null) {
      setLoad(true);
      var temp = window.atob(id);
      //console.log(temp);
      postMethod("api/companys/companyUser/preview", { tran_id: temp }).then((dataD) => {
         var data = dataD.data[0];
        setParam({
          ...param,
          tran_id: temp,
          company_id: data.company_id,
          full_name: data.full_name,
          mobile: data.mobile,
          email: data.email,
          password: data.password,
          disable: data.disable,
        });
        setLoad(false);
      });
    }
 
  }, []);
  return (
    <>
      {load ? <Loader /> : null}
      <Form onSubmit={handleSubmit(insertData)}>
        <input
          type="hidden"
          name="tran_id"
          value={id == null ? "0" : param.tran_id}
          ref={register}
        />
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Add Company User</h3>
            </div>
          </div>
          <div className="card-body">
            <Row>
            <Col sm={4}>
                <FormGroup>
                  <Label>Company</Label>
                  <select
                    className="form-control h-auto py-2 px-6"
                    onChange={(e) => {
                      setParam({ ...param, company_id: e.target.value });
                    }}
                    name="company_id"
                    value={param.company_id}
                    ref={register}
                    required
                  >
                    <option value="">--Select--</option>
                    {companyList.map((prop, i) => {
                      return (
                        <option key={i} value={prop.company_id}>
                          {prop.company_name}
                        </option>
                      );
                    })}
                  </select>
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Contact person</Label>
                  <input
                    type="text"
                    required
                    value={param.full_name}
                    className="form-control h-auto py-2 px-6"
                    name="full_name"
                    onChange={(e) => setParam({ ...param, full_name: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>mobile</Label>
                  <input
                    type="number"
                    required
                    value={param.mobile}
                    className="form-control h-auto py-2 px-6"
                    name="mobile"
                    onChange={(e) => setParam({ ...param, mobile: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>
              

              <Col sm={4}>
                <FormGroup>
                  <Label>email</Label>
                  <input
                    type="email"
                    required
                    value={param.email}
                    className="form-control h-auto py-2 px-6"
                    name="email"
                    onChange={(e) => setParam({ ...param, email: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>


              <Col sm={4}>
                <FormGroup>
                  <Label>password</Label>
                  <input
                    type="password"
                    required
                    value={param.password}
                    className="form-control h-auto py-2 px-6"
                    name="password"
                    onChange={(e) => setParam({ ...param, password: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>
              <Col sm={1}>
                <FormGroup>
                  <Label>Disable</Label>
                  <input
                    type="checkbox"                 
                    checked={param.disable}
                    className="form-control h-auto py-2 px-6"
                    name="disable"
                    onChange={(e) => setParam({ ...param, disable: !param.disable })}
                    ref={register}
                  />
                </FormGroup>
              </Col>


            </Row>
            {success ? <Alert color="success">Saved Successfully</Alert> : null}
            {successDelete ? <Alert color="danger">Deleted</Alert> : null}
          </div>
          <div className="card-footer text-right">
            <a href="/admin/company-users" className="btn btn-secondary mr-2">
              Cancel
            </a>
         
             {id && (
              loadDelete ? <button type="button" className="btn btn-danger mr-2">
                Deleting..
              </button> : 
               <button type="button" onClick={handleDelete} className="btn btn-danger mr-2">
                Delete
              </button>
            )}

             {loadInser ? 
             <button type="button" className="btn btn-primary ">
              Saving..
            </button> :  <button type="submit" className="btn btn-primary ">
              Save
            </button>}
          </div>
        </div>
      </Form>
    </>
  );
};

export default AddCompanyUser;
