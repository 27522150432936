import React, { Fragment, useEffect, useState } from "react";
import { postMethod } from "../../../_services/_postMethod";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Login from "./login";

const ProtectedRoute = ({ component: Component, roles, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!localStorage.getItem("jwl_login")) {
          // not logged in so redirect to login page with the return url
          return <Redirect to="/login" from="/" />;
        }

        // // check if route is restricted by role
        // if(roles && roles.indexOf(localStorage.getItem('role')) === -1){
        // // role not authorised so redirect to home page
        //    return <Redirect to={{ pathname: '/'}} />
        // }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;
