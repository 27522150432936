import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useLocation } from "react-router-dom";
import { Button, Form, FormGroup, Label, FormText, Row, Col, Alert, Table, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { API_BASE_URL } from "../../../../_services/constant";
import { Card } from "@material-ui/core";
import { post } from "axios";

import {
  postMethod,
  validateMobile,
  singleParam,
  editUserGrid,
} from "../../../../_services/_postMethod";
import Loader from "../../../modules/loader";
//import Select from "react-select";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import OutlinedInput from "@material-ui/core/OutlinedInput";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AddProduct = (props) => {
  let queryParams = useQuery();
  const id = queryParams.get("id") == "" ? null : queryParams.get("id");
  const { register, handleSubmit, errors } = useForm();
  const [imageValidation, setImageValidation] = useState(false);
  const [imageValidationMsg, setImageValidationMsg] = useState("");
  const [load, setLoad] = useState(false);
  const [loadInser, setLoadInsert] = useState(false);
  const [loadDelete, setLoadDelete] = useState(false);
  const [loadDeleteMsg, setLoadDeleteMsg] = useState("Deleted");
  const [categoryList, setCategoryList] = useState([]);
  const [stockHistoryList, setstockHistoryList] = useState([]);
  const [subcategory, setSubcategory] = useState([]);
  const [isempty, setIsempty] = useState(true);
  const [showProductImages, setShowProductImages] = useState([]);
  const [divStockHistory, setdivStockHistory] = useState(false);
  const toggledivStockHistory = () => setdivStockHistory(!divStockHistory);
  const [param, setParam] = useState({
    product_id: id == null ? "0" : id,
    product_subcategory_list: [],
    product_code: "", 
    product_name: "",
    branch_id: "",
    gender: "",
    price: null,
    qty: "1",
    addedQty: "0",
    discounted_price: null,
    remarks: "",
    weight: null,
    size_length: null,
    Metal: "",
    material: "",
    on_demand: "",
    category_id: "",
    available: false,
    exhibition: false,
    businesses: false,
    disable: false,
    trial: false,
    product_images: [],
  }); 
  
  const [success, setSuccess] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);
  const insertData = (values) => {
    let file = document.getElementById("uploadImage");
    console.log(file.files);
    let files = file.files;
    let formData = new FormData();
    let product_imagesCount = parseInt(param.product_images.length) + parseInt(files.length);
    if (product_imagesCount == 0) {
      setImageValidation(true);
      setImageValidationMsg("*Required");
    }
    else if (product_imagesCount > 4) {
      setImageValidation(true);
      setImageValidationMsg("*Max 4 product image allow.");
      document.getElementById("uploadImage").value = null;
    }
    else {
      setLoadInsert(true);

      if (files.length > 0) {
        for (var j = 0; j < files.length; j++) {
          let file_name = "image-" + createGuid();
          console.log(files[j].type.split('/')[1])
          param.product_images.push(file_name+"."+files[j].type.split('/')[1]);
          formData.append("files", files[j], file_name);
        }
      }

      postMethod("api/masters/product/insert", param).then((data) => {
        if (data.data[0].valid) {
          if (files.length > 0) {
            uploadImageFunc(formData);
          } else {
            setSuccess(true);
            setTimeout(() => {
              props.history.push("/branch/product");
            }, 2000);
          }
        }
        else {
          setLoadInsert(false);
        }
      });
    }

  };
console.log(param);
  const handleDelete = () => {
    var proceed = window.confirm("Do you Really Wish to Delete this Entry..?!");

    if (proceed) {
      setLoadDelete(true);
      postMethod("api/masters/product/delete", { product_id: param.product_id }).then((data) => {
        if (data.data[0].valid) {
          setSuccessDelete(true);
          setTimeout(() => {
            props.history.push("/branch/product");
          }, 2000);
        }
        else {
          setLoadDeleteMsg(data.error)
          setSuccessDelete(true);
          setTimeout(() => {
            setSuccessDelete(false);
          }, 4000);
          setLoadDelete(false);
        }
      });
    }
  };


  const handleDeleteImage = (image_id) => {
    var proceed = window.confirm("Do you Really Wish to Delete this Image..?!");

    if (proceed) {
      postMethod("api/masters/product/delete_image", { product_id: param.product_id, image_id: image_id }).then((data) => {
        var images = [];
        setShowProductImages(data.data);
        data.data.forEach((element) => {
          images.push(element.image_path);
          showProductImages.push({ "image_id": element.image_id, "image_path": element.image_path });
        });

        setParam({
          ...param,
          product_images: images
        });

      });
    }
  };

  function createGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  const uploadImageFunc = (formData) => {
    const url = API_BASE_URL + `api/masters/product/uploadImage`;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "auth-token": localStorage.getItem("jwl_token"),
      },
    };

    post(url, formData, config).then(
      (response) => {
        setSuccess(true);
        setTimeout(() => {
          props.history.push("/branch/product");
        }, 2000);
      },
      (error) => {
        setSuccess(true);
      }
    );
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        marginLeft: -10,
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 310,
      },
    },
  };

  useEffect(() => {
    if (id !== null) {
      setLoad(true);
      var temp = window.atob(id);
      postMethod("api/masters/product/preview", { product_id: temp }).then((data) => {
        var subcategory = [];
        var images = [];
        setShowProductImages(data.data[0].images);
        setstockHistoryList(data.data[0].stockHistory)
        data.data[0].images.forEach((element) => {
          images.push(element.image_path);
        });
        data.data[0].subcategorylist.forEach((element) => {
          subcategory.push(element.subcategory_id);
        });
        setParam({
          ...param,
          product_id: data.data[0].product_id,
          product_name: data.data[0].product_name,
          product_code: data.data[0].product_code,
          price: data.data[0].price,
          qty: data.data[0].qty,
          addedQty: data.data[0].qty,
          remarks: data.data[0].remarks,
          gender: data.data[0].gender,
          discounted_price: data.data[0].discounted_price,
          exhibition: data.data[0].exhibition,
          available: data.data[0].available,
          businesses: data.data[0].businesses,
          trial: data.data[0].trial,
          disable: data.data[0].disable,
          weight: data.data[0].weight,
          size_length: data.data[0].size_length,
          metal: data.data[0].Metal,
          material: data.data[0].material,
          on_demand: data.data[0].on_demand,
          product_images: images,
          category_id: data.data[0].category_id,
          balance_qty: data.data[0].balance_qty,
          product_subcategory_list: subcategory,
        });
        postMethod("api/masters/product/subcategory/getSubcategoryForm", { category_id: data.data[0].category_id }).then((data) => {
          setSubcategory(data.data);
        });
        setLoad(false);
      });
    }
    postMethod("api/masters/product/subcategory/getCategory", {}).then((data) => setCategoryList(data.data));

  }, []);

  const getSubcategory = (category_id) => {
    postMethod("api/masters/product/subcategory/getSubcategoryForm", { category_id: category_id }).then((data) => {
      setSubcategory(data.data);
    });
  }
  const getDiscription = (sub_cat) => {
    // alert(description);
    if (sub_cat.length == 1) {
      postMethod("api/masters/product/subcategory/getSubcategorydescription", { subcategory_id: sub_cat[0] }).then((data) => {
        setIsempty(false);
        setParam({ ...param, remarks: data.data[0].description, product_subcategory_list: sub_cat })
        // document.getElementById("tdescription").value = data.data[0].description;
        // setRemarks(data.data[0].description);
      });
    } else {
      setParam({ ...param, product_subcategory_list: sub_cat })
    }
  }

  const [selectedImages, setSelectedImages] = useState([]);
 

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    if (files.length > 4) {
      setImageValidationMsg("You can only upload a maximum of 4 images");
      setSelectedImages([]);
      return;
    }

    setSelectedImages(files);
    setImageValidationMsg("");
  };


  return (
    <>


      {load ? <Loader /> : null}
      <Form onSubmit={handleSubmit(insertData)}>
        <input autocomplete="new-off"
          type="hidden"
          name="product_id"
          value={id == null ? "0" : param.product_id}
          ref={register}
        />
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Add Product</h3>
            </div>
          </div>
          <div className="card-body">
            <Row>
              <Col sm={4}>
                <FormGroup>
                  <Label>Product Name</Label><span className="text-danger ml-1">*</span>
                  <input autocomplete="new-off"
                    type="text"
                    required
                    value={param.product_name}
                    className="form-control h-auto py-2 px-6"
                    name="product_name"
                    onChange={(e) => setParam({ ...param, product_name: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Branch code /Product Code</Label>
                  <input autocomplete="new-off"
                    type="text"
                    value={param.product_code}
                    className="form-control h-auto py-2 px-6"
                    name="product_code"
                    onChange={(e) => setParam({ ...param, product_code: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>

              <Col sm={4}>
                <FormGroup>
                  <Label>Shop for</Label>
                  <select className="form-control h-auto py-2 px-6" value={param.gender} onChange={(e) => {
                    setParam({ ...param, gender: e.target.value });
                  }}>
                    <option value="" selected>--select--</option>
                    <option value="Women">Women</option>
                    <option value="Men">men</option>
                    <option value="Kid">kid</option>
                    <option value="Unisex">Unisex</option>
                  </select>
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Category</Label>
                  <select
                    className="form-control h-auto py-2 px-6"
                    onChange={(e) => {
                      {
                        setParam({ ...param, category_id: e.target.value })
                        getSubcategory(e.target.value)
                      }
                    }}
                    name="category_id"
                    value={param.category_id}
                    ref={register}
                  >
                    <option value="">--Select--</option>
                    {categoryList.map((prop, i) => {
                      return (
                        <option key={i} value={prop.category_id}>
                          {prop.category_name}
                        </option>
                      );
                    })}
                  </select>
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>SubCategory</Label>
                  <Select
                    style={{ width: 300, height: 35 }}
                    multiple
                    value={param.product_subcategory_list}
                    //value={[]}
                    name="subcategory_id"
                    onChange={(e) => {
                      getDiscription(e.target.value)
                    }}
                    ref={register}
                    input={<OutlinedInput id="select-multiple" />}
                    MenuProps={MenuProps}
                  >
                    {subcategory.length > 0 ? subcategory.map((item) => (
                      <MenuItem key={item.subcategory_id} value={item.subcategory_id}>
                        {item.subcategory_name + "(" + item.category_name + ")"}
                      </MenuItem>
                    )) : null}
                  </Select>
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Price</Label>
                  <input autocomplete="new-off"
                    type="text"
                    value={param.price}
                    className="form-control h-auto py-2 px-6"
                    name="price"
                    onChange={(e) => setParam({ ...param, price: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Discounted Price</Label>
                  <input autocomplete="new-off"
                    type="text"
                    value={param.discounted_price}
                    className="form-control h-auto py-2 px-6"
                    name="price" discounted_price
                    onChange={(e) => setParam({ ...param, discounted_price: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>
              <Modal isOpen={divStockHistory} toggle={toggledivStockHistory} style={{ marginTop: "100px" }}>
                <ModalHeader toggle={toggledivStockHistory}>Stock History</ModalHeader>
                <ModalBody style={{ maxHeight: "238px", overflow: 'auto' }} >
                  <table className="table">
                    <thead>
                      <tr>
                        <th>sno</th>
                        <th>Transaction Detail</th>
                        <th>Qty</th>
                        <th>Date Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {stockHistoryList.map((item, index) => {
                        return (
                          <tr className={index == stockHistoryList.length - 1 ? "text-danger" : ""} >
                            <td>{index + 1}</td>
                            <td>{item.Text}</td>
                            <td>{item.Qty}</td>
                            <td >{item.datetime}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={toggledivStockHistory}>Update</Button>{' '}
                  <Button color="secondary" onClick={toggledivStockHistory}>Cancel</Button>
                </ModalFooter>
              </Modal>
              <Col sm={4}>
                <FormGroup>
                  <Label className="text-info">Add Quantity
                    <i className="fa fa-info-circle text-dark ml-2" onClick={toggledivStockHistory}> </i>
                    <a  ></a> </Label>
                  <input autocomplete="new-off"
                    style={{ borderColor: "var(--info)" }}
                    type="text"
                    value={param.addedQty}
                    className="form-control h-auto py-2 px-6"
                    name="addedQty"
                    onChange={(e) => setParam({ ...param, addedQty: e.target.value ,qty:e.target.value })}
                    ref={register}
                  />
                  <b className="text-info text-bold" >(Balance Qty {param.balance_qty})</b> {" "}
                  <b className="text-danger text-bold" >(Last Qty Added {param.qty}) </b>
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Weight</Label>
                  <input autocomplete="new-off"
                    type="text"
                    value={param.weight}
                    className="form-control h-auto py-2 px-6"
                    name="price" weight
                    onChange={(e) => setParam({ ...param, weight: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Size/Length</Label>
                  <input autocomplete="new-off"
                    type="text"
                    value={param.size_length}
                    className="form-control h-auto py-2 px-6"
                    name="price" size_length
                    onChange={(e) => setParam({ ...param, size_length: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Metal</Label>
                  <input autocomplete="new-off"
                    type="text"
                    value={param.metal}
                    className="form-control h-auto py-2 px-6"
                    name="price" metal
                    onChange={(e) => setParam({ ...param, metal: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>

              <Col sm={4}>
                <FormGroup>
                  <Label>Material</Label>
                  <input autocomplete="new-off"
                    type="text"
                    value={param.material}
                    className="form-control h-auto py-2 px-6"
                    name="price" material
                    onChange={(e) => setParam({ ...param, material: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Availability</Label>
                  <select
                    className="form-control h-auto py-2 px-6"
                    onChange={(e) => {
                      {
                        setParam({ ...param, on_demand: e.target.value })
                      }
                    }}
                    name="on_demand"
                    value={param.on_demand}
                    ref={register}
                  >
                    <option value="">--Select--</option>
                    <option value="In stock">In stock</option>
                    <option value="Make to order">Make to order</option>
                  </select>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label>Description</Label>
                  <textarea
                    // type="textarea"
                    value={param.remarks}
                    className="form-control h-auto py-2 px-6"
                    name="remarks"
                    id="tdescription"
                    onChange={(e) => setParam({ ...param, remarks: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>
              <Col sm={1}>
                <FormGroup>

                  <Button type="button" title="Clear" className="form-control h-auto mt-8" onClick={() => { setParam({ ...param, remarks: "" }) }}>Clear</Button>
                </FormGroup>
              </Col>
              <Col cm={1}>

              </Col>
              <Col sm={1}>
                <FormGroup>
                  <Label>Exhibition</Label>
                  <input autocomplete="new-off"
                    type="checkbox"
                    checked={param.exhibition}
                    className="form-control h-auto py-2 px-6"
                    name="exhibition"
                    onChange={(e) => setParam({ ...param, exhibition: !param.exhibition })}
                    ref={register}
                  />
                </FormGroup>
              </Col>
              {/*  <Col sm={1}>
                <FormGroup>
                  <Label>Available</Label>
                    <input autocomplete="new-off"
                    type="checkbox"                 
                    checked={param.available}
                    className="form-control h-auto py-2 px-6"
                    name="available"
                    onChange={(e) => setParam({ ...param, available: !param.available })}
                    ref={register}
                  />
                </FormGroup>
              </Col>*/}
              <Col sm={1}>
                <FormGroup>
                  <Label>Businesses</Label>
                  <input autocomplete="new-off"
                    type="checkbox"
                    checked={param.businesses}
                    className="form-control h-auto py-2 px-6"
                    name="businesses"
                    onChange={(e) => setParam({ ...param, businesses: !param.businesses })}
                    ref={register}
                  />
                </FormGroup>
              </Col>
              <Col sm={1}>
                <FormGroup>
                  <Label>Disable</Label>
                  <input autocomplete="new-off"
                    type="checkbox"
                    checked={param.disable}
                    className="form-control h-auto py-2 px-6"
                    name="disable"
                    onChange={(e) => setParam({ ...param, disable: !param.disable })}
                    ref={register}
                  />
                </FormGroup>
              </Col>
              <Col sm={2}>
                <FormGroup>
                  <Label>Trial at home</Label>
                  <input autocomplete="new-off"
                    type="checkbox"
                    checked={param.trial}
                    className="form-control h-auto py-2 px-6"
                    name="trial"
                    onChange={(e) => setParam({ ...param, trial: !param.trial })}
                    ref={register}
                  />
                </FormGroup>
              </Col>
              {/* <Col sm={4} className="d-flex">
                <Card body className="col-md-12">
                  <Label>
                    Product Image 4 (251X230) (<label className="text-danger">select multiple images</label>) {imageValidation ? <label className="text-danger">{imageValidationMsg}</label> : null}<br />

                    <input autocomplete="new-off"
                      id="uploadImage"
                      type="file"
                      multiple={true}
                      accept="image/*"
                      name="Choose Image"
                    />
                  </Label>
                </Card>
              </Col> */}


<Col sm={4} className="d-flex">
      <Card body className="col-md-12">
        <Label>
          Product Image 4 (251X230){" "}
          <label className="text-danger">select multiple images</label>{" "}
          {imageValidationMsg && (
            <label className="text-danger">{imageValidationMsg}</label>
          )}
          <br />
          <input
            autoComplete="new-off"
            id="uploadImage"
            type="file"
            multiple={true}
            accept="image/*"
            name="Choose Image"
            onChange={handleImageChange}
          />
        </Label>

        <div className="image-preview">
          {selectedImages.length > 0 &&
            selectedImages.map((image, index) => (
              <img
                key={index}
                src={URL.createObjectURL(image)}
                alt={`preview ${index}`}
                width="100"
                height="100"
                style={{ margin: "10px" }}
              />
            ))}
        </div>
      </Card>
    </Col>




            </Row>

            <Table>
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {showProductImages.length > 0 ? showProductImages.map((item, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    <td><img src={API_BASE_URL + "Images/" + item.image_path} style={{ maxHeight: "40px" }} alt="product name"></img></td>
                    <td><i className="fa fa-trash" style={{ cursor: "pointer", color: "red" }} aria-hidden="true" onClick={() => handleDeleteImage(item.image_id)}></i></td>
                  </tr>
                )) : <tr>
                  <td colSpan="3" className="text-center"> <b>NO image</b> </td>
                </tr>}
              </tbody>
            </Table>
            {success ? <Alert color="success">Saved Successfully</Alert> : null}
            {successDelete ? <Alert color="danger">{loadDeleteMsg}</Alert> : null}
          </div>
          <div className="card-footer text-right">
            <a href="/branch/product" className="btn btn-secondary mr-2">
              Cancel
            </a>
            {id && (
              loadDelete ? <button type="button" className="btn btn-danger mr-2">
                Deleting..
              </button> :
                <button type="button" onClick={handleDelete} className="btn btn-danger mr-2">
                  Delete
                </button>
            )}
            {loadInser ?
              <button type="button" className="btn btn-primary ">
                Saving..
              </button> : <button type="submit" className="btn btn-primary ">
                Save
              </button>}
          </div>
        </div>
      </Form>
    </>
  );
};

export default AddProduct;
