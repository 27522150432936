import React, { Fragment, useEffect, useState } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { postMethod } from "../../../../_services/_postMethod";
import Loader from "../../../modules/loader";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const Product = (props) => {
  const [grid, setGrid] = React.useState([]);
  const [load, setLoad] = React.useState(false);
  const [divShowImportModal, setdivShowImportModal] = useState(false);
  const toggledivShowImportModal = () => setdivShowImportModal(!divShowImportModal);
  const [importData, setimportData] = useState();
  const [importDataPreviewList, setimportDataPreviewList] = useState([]);
  const saveBulkData = () =>{
    var params = {
      product_list:importDataPreviewList
    }
    postMethod("api/masters/product/bulk_insert", params).then((data) => {
      if (data.data[0].valid) {
        debugger;
        setdivShowImportModal(false)
        setimportDataPreviewList([])
        getData()
      }
     else
     {
      // setLoadInsert(false);
     }
    });
  }  
  const [param, setParam] = useState({
    product_id: "0",
    product_subcategory_list: [],
    product_code: "",
    product_name: "",
    branch_id:"",
    gender:"",
    price:null,
    qty:"1",
    discounted_price:null,
    remarks:"",
    weight:null,
    size_length:null,
    Metal:"",
    material:"",
    on_demand:"",
    category_id:"",
    available:false,
    exhibition: false,
    businesses: false,
    disable: false,
    trial: false,
    product_images: [],
  });
  const importExcelData = (e)=>{
    
    postMethod("api/masters/product/PreviewImportFile", { importData: importData }).then((data) => {
      setimportDataPreviewList(data.data)
      debugger;
    })
  }
  const DonwloadSample = (e)=>{
    postMethod("api/masters/product/download_sample", { }).then((data) => {
      var linkSource = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + data.data[0].data
      var link = document.createElement('a');
      link.href = linkSource;
      var fileName = "Import_Products_Template.xlsx";
      link.target = '_self';
      link.download = fileName;
      link.click();
      // setimportDataPreviewList(data.data)
      debugger;
    })
    
  }
  const editRecord = (val) => {
    var temp = window.btoa(val);
    console.log(temp);
    props.history.push("/branch/product/add?id=" + temp);
  };
//Table Objects
const [tableState, setTableState] = useState({
      loading: true,
      total_pages: 0,
      records: []
});

console.log("-----------table data ",tableState.records)

//Table Config
const config= {
      key_column: 'state_id',
      page_size: 10,
      length_menu: [10, 20, 50],
      show_filter: true,
      show_pagination: true,
      pagination: 'advance',
      no_data_text: 'No data available!',
      language: {
          length_menu: "Show _MENU_ result per page",
          filter: "Search...",
          info: "Showing _START_ to _END_ of _TOTAL_ records",
          loading_text: "Please be patient while data loads...",
          pagination: {
              first: "First",
              previous: <span>&#9668;</span>,
              next: <span>&#9658;</span>,
              last: "Last"
          }
      },
      button: {
          excel: false,
          print: false
      }
  };
  // Handler for Table changes      
  const tableChangeHandler = data => {
          let queryString = Object.keys(data).map((key) => {
              if (key === "sort_order" && data[key]) {
                  return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
              } else {
                  return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
              }
          }).join('&');
          getData(queryString);
      }

  // Get Table data
  const getData = (queryString = "") => {
        let url = "api/masters/product/web-browse/?" + queryString
        postMethod(url, {}).then((res) => {
        setTableState({...tableState,
                loading: false,
                total_pages: res.totalRecords,
                records: res.Data
            })

          
    });               
    }
   //Table Columns 
  const columns = [
    {
      key: "product_code",
      text: "Product Code",
      align: "left",
      sortable: true,
    },
     {
      key: "product_name",
      text: "Product Name",
      align: "left",
      sortable: true,
    },
    {
      key: "category_name",
      text: "Category Name",
      align: "left",
      sortable: true,
    },
    {
      key: "image",
      text: "Image",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.image_path === "" ? "NO Image" : (<img src={record.url_image +""+record.image_path} width="40px" alt="" />)}
          </Fragment>

          
        );
      },
    },
    {
      key: "disable",
      text: "Disable",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
             <span>{record.disable === true ? <label className="text-danger">Disabled</label> : <label className="text-success">Active</label>}</span>
          </Fragment>
        );
      },
    },
    {
      key: "balance_qty",
      text: "Balance Qty",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
             <span>{record.balance_qty == 0 ? <label className="text-danger">0</label> : <label className="text-success">{record.balance_qty}</label>}</span>
          </Fragment>
        );
      },
    },

    {
      key: "action",
      text: "Action",
      className: "action",
      width: 130,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="btn btn-link btn-sm"
              onClick={() => editRecord(record.product_id)}
              style={{ marginRight: "5px" }} 
            >
              <i className="fa fa-edit"></i>
            </button>
          </Fragment>
        );
      },
    },
  ];

  useEffect(() => {
    setLoad(true);
    getData();   
    setLoad(false);
  }, []);

  return (
    <>
      {load ? <Loader /> : null}
      <Modal size="xl"
      zIndex="99999"
      backdrop="static"
       isOpen={divShowImportModal} toggle={toggledivShowImportModal} style={{marginTop:"100px"}}>
          <ModalHeader className="text-center" toggle={toggledivShowImportModal}>Import Product</ModalHeader>
          <ModalBody style={{maxHeight:"400px",overflow:'auto'}} >
            <div className="row">
              <div className="col-md-4">
              <input
              className="form-control p-1"
                      type="file"
                      multiple={false}
                      name="Choose Excel File"
                      onChange={(e)=>  {
                          var reader = new FileReader();
                          reader.onload = function (e) {
                            console.log(e.target.result)
                            setimportData(e.target.result)
                          }
                          reader.readAsDataURL(e.target.files[0]);
                    }}
                    />
              </div>
              <div className="col-md-6">
                <button class="btn btn-info" onClick={importExcelData} >Preview</button>
                <a
              onClick={DonwloadSample}
              className="btn btn-sm btn-outline-success ml-2 font-weight-bold py-2 px-3 mr-3"
            >
              <i className="flaticon2-download"></i> Download Sample
            </a>
              </div>

            </div>
            <table className="table">
              <thead>
                <tr>
                  <th className="p-1" >sno</th>
                  <th className="p-1">Product Code</th>
                  <th className="p-1">Shop for</th>
                  <th className="p-1">Category</th>
                  <th className="p-1">SubCategory</th>
                  <th className="p-1">Price</th>
                  <th className="p-1">Discounted Price</th>
                  <th className="p-1">Add Quantity</th>
                  <th className="p-1">Weight</th>
                  <th className="p-1">Size/Length</th>
                  <th className="p-1">Metal</th>
                  <th className="p-1">Material</th>
                  <th className="p-1">Availability</th>
                  <th className="p-1">Description</th>
                  <th className="p-1">Exhibition</th>
                  <th className="p-1">Disable</th>
                  <th className="p-1">Trial at home</th>
                </tr>
              </thead>
              <tbody>
                  {importDataPreviewList.map((item,index)=>{
                    return (
                      <tr >
                        <td className="p-1">{index + 1}</td>
                        <td className="p-1">{item["Product Code"]}</td>
                        <td className="p-1">{item["Shop for"]}</td>
                        <td className="p-1">{item["Category"]}</td>
                        <td className="p-1">{item["SubCategory"]}</td>
                        <td className="p-1">{item["Price"]}</td>
                        <td className="p-1">{item["Discounted Price"]}</td>
                        <td className="p-1">{item["Add Quantity"]}</td>
                        <td className="p-1">{item["Weight"]}</td>
                        <td className="p-1">{item["Size/Length"]}</td>
                        <td className="p-1">{item["Metal"]}</td>
                        <td className="p-1">{item["Material"]}</td>
                        <td className="p-1">{item["Availability"]}</td>
                        <td className="p-1">{item["Description"]}</td>
                        <td className="p-1">{item["Exhibition"]}</td>
                        <td className="p-1">{item["Disable"]}</td>
                        <td className="p-1">{item["Trial at home"]}</td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </ModalBody>
          <ModalFooter>
            {importDataPreviewList.length > 0 && <Button color="primary" onClick={saveBulkData}>Save</Button>}{' '}
            <Button color="secondary" onClick={toggledivShowImportModal}>Cancel</Button>
          </ModalFooter>
        </Modal>
      <div className="card card-custom gutter-b">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">Products</h3>
          </div>
          <div className="card-toolbar">
            <a
              onClick={toggledivShowImportModal}
              className="btn btn-sm btn-outline-info font-weight-bold py-2 px-3 mr-3"
            >
              <i className="flaticon2-download"></i> Import
            </a>
            <a
              href="/branch/product/add"
              className="btn btn-sm btn-outline-theme font-weight-bold py-2 px-3"
            >
              <i className="flaticon2-user"></i> Add Product
            </a>
          </div>
        </div>
        <div className="card-body">
           <ReactDatatable
                        config={config}
                        records={tableState.records}
                        columns={columns}
                        dynamic={true}
                        total_record={tableState.total_pages}
                        onChange={tableChangeHandler}
                        loading={tableState.loading} />
        </div>
      </div>
    </>
  );
};

export default Product;
