import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SendIcon from "@material-ui/icons/Send";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Modal, ModalHeader, ModalBody, ModalFooter,
  CardHeader,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Row,
  Alert,
  Input,
} from "reactstrap";
import Backdrop from "@material-ui/core/Backdrop";
import { API_BASE_URL } from "../../../_services/constant";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import { Autocomplete, TextField } from "@mui/material";
import { postMethod } from "../../../_services/_postMethod";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#000",
    backgroundColor: "#fff",
  },
}));
const CustDesign = (props) => {
  const classes = useStyles();
  const [load, setLoad] = useState(false);
  const [category, setCategory] = useState("");
  const [image, setImage] = useState(null);
  const [remark, setRemark] = useState("");
  const [carts, setCarts] = useState([]);
  const [subCat, setSubCat] = useState([]);

  const handleAddCart = () => {
    if (carts.length < 5) {
      !image
        ? alert("oops| Add your Design first")
        : setCarts([
            ...carts,
            {
              name: localStorage.getItem("jwl_username"),
              logo: localStorage.getItem("jwl_logo"),
              brand_name: localStorage.getItem("jwl_brand_name"),
              customer_id: localStorage.getItem("jwl_customer_id"),
              branch_id: localStorage.getItem("jwl_customer_branch_id"),
              mobile: localStorage.getItem("cust_mobile"),
              category,
              image,
              remark,
            },
          ]);
      setCategory("");
      setImage("");
      setRemark("");
    } else {
      alert("Maximum 5 carts allowed!");
    }
  };

  const handleDeleteCart = (index) => {
    const newCarts = carts.filter((_, i) => i !== index);
    setCarts(newCarts);
  };

  //  -----------------------------------

  //   --apicall--
  const designUploader = async (data) => {
    try {
      const response = await axios.post(
        API_BASE_URL + "auth/upload/design",
        data,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } catch (error) {
      alert(error);
      alert("Failed to upload image and save data");
    }
  };

  const savedAllDesign = () => {
    carts.forEach((item) => {
      const formData = new FormData();
      formData.append("tran_id", "0");
      formData.append("voucher_id", 52);
      formData.append("category", item.category);
      formData.append("image", item.image);
      formData.append("remark", item.remark);
      formData.append("name", item.name);
      formData.append("logo", item.logo);
      formData.append("brand_name", item.brand_name);
      formData.append("customer_id", item.customer_id);
      formData.append("branch_id", item.branch_id);
      formData.append("mobile", item.mobile);
      formData.append(
        "entry_date",
        moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ")
      );

      designUploader(formData);
    });
    redirect_after_submission();
  };

  function redirect_after_submission() {
    const insta = localStorage.getItem("jwl_instagram");
    const facebook = localStorage.getItem("jwl_facebook");
    const twitter =  localStorage.getItem("jwl_twitter");

    return insta ? window.open('https://www.instagram.com/' + insta, '_blank') : facebook ? window.open('https://www.facebook.com/' + facebook, '_blank') : twitter ? window.open('https://www.twitter.com/' + twitter, '_blank') : "";
  }
  // ---------------------------------------------------------


  useEffect(() => {
    axios
      .post(API_BASE_URL + "auth/get_product_setCategory", {
        branch_id: localStorage.getItem("jwl_customer_branch_id"),
      })
      .then((response) => {
        setSubCat(response.data);
       
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  }, []);

  const [visible, setVisible] = useState(true);

  const onDismiss = () => setVisible(false);

  const handlePhoneClick = () => {
    window.location.href = `tel:${localStorage.getItem("branch_contact")}`;
  };

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // =============
  return (
    <>
      <Backdrop className={classes.backdrop} open={load}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div>
        <div className="container">
          <img
            className="img-fluid"
            src={
              API_BASE_URL + "BranchLogo/" + localStorage.getItem("jwl_logo")
            }
            height="23%"
            width="23%"
            style={{ margin: "5px" }}
          />
          <span
            className="btn button"
            style={{ float: "right", margin: "20px" }}
            onClick={handlePhoneClick}
          >
            <PhoneIcon style={{ color: "blue", fontSize: "2rem" }} />
          </span>
        </div>
        <div style={{ margin: "10px" }}>
          <Card
            className="my-2"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <CardHeader tag="h5">Add Your Design </CardHeader>
            <CardBody>
              <CardTitle className="center" tag="h5"></CardTitle>
              <CardText>
                <Row className="justify-content-center">
                  {image ? (
                    <div
                      style={{
                        width: "200px",
                        height: "200px",
                        border: "4px solid black",
                      }}
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          padding: 0,
                        }}
                        className="m-auto"
                        src={URL.createObjectURL(image)}
                        alt="Preview"
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "200px",
                        height: "200px",
                        backgroundColor: "#f0f0f0",
                        border: "1px solid #ccc",
                        padding: "20px",
                      }}
                    >
                      <img alt="Choose Design" />
                    </div>
                  )}
                  <div className="my-auto ml-10">
                    <input
                      type="file"
                      placeholder="Image URL"
                      size="30"
                      required
                      onChange={(e) => setImage(e.target.files[0])}
                    />
                  </div>
                </Row>

                <Row className="mt-5 justify-content-center">
                  {/* <label htmlFor="selectField">Select an option:</label> */}
                  <Input
                    style={{ width: "80%" }}
                    id="selectField"
                    type="select"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    <option value="">Select Category...</option>
                    {subCat.map((option) => (
                      <option key={option.id} value={option.category_name}>
                        {option.category_name}
                      </option>
                    ))}
                  </Input>
                </Row>

                <Row className=" mt-3 justify-content-center">
                  <Input
                    type="textarea"
                    placeholder="Remark"
                    rows="5"
                    cols="50"
                    style={{ width: "80%" }}
                    value={remark}
                    className="mt-1"
                    onChange={(e) => setRemark(e.target.value)}
                  />
                </Row>

                <Row className="ml-8" style={{ position: "relative" }}>
                  <p
                    onClick={handleAddCart}
                    style={{ position: "absolute", top: 0, right: 0 }}
                    className="mr-15"
                  >
                    <AddCircleIcon
                      style={{ fontSize: "40px", color: "orange" }}
                    />
                  </p>
                </Row>
              </CardText>
            </CardBody>
          </Card>
        </div>

        {carts.length > 0 ? (
          <div class="outer_modal">
            <div
              style={{ display: "flex", flexWrap: "wrap" }}
              class="shadow-lg p-3 mb-5 bg-white rounded"
            >
              {carts.map((cart, index) => (
                <Card
                  style={{
                    width: "10rem",
                    height: "20rem",
                    margin: "3rem",
                  }}
                >
                  <img
                    className="img-fluid"
                    style={{
                      width: "100%",
                      height: "50%",
                      borderRadius: "4px",
                    }}
                    src={URL.createObjectURL(cart.image)}
                    alt="Preview"
                  />
                  <CardBody className="text-left">
                    <CardSubtitle className="mb-2 text-muted" tag="h6">
                      {cart.category}
                    </CardSubtitle>

                    <Row>
                      <Input
                        type="textarea"
                        rows="2"
                        cols="20"
                        value={cart.remark}
                      ></Input>
                    </Row>
                    <Row className="mt-2">
                      <Button
                        color="danger"
                        onClick={() => handleDeleteCart(index)}
                      >
                        Delete
                      </Button>
                    </Row>
                  </CardBody>
                </Card>
              ))}
            </div>

            <div className="footer">
              <div
                className="fixed-bottom "
                style={{ height: "6%", backgroundColor: "orange" }}
              >
                <Row className="justify-content-center">
                  <h5
                    onClick={() => {
                      toggle();
                      setVisible(true);
                    }}
                    className="mt-3"
                  >
                    <b style={{ color: "black" }}>Submit</b>{" "}
                  </h5>
                </Row>
              </div>
            </div>
          </div>
        ) : (
          " "
        )}
      </div>

      <Modal isOpen={modal} fade={false} toggle={toggle}>
        <ModalHeader toggle={toggle}>Upload Design</ModalHeader>
        <ModalBody>Are you sure you want to submit</ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={()=> {
              savedAllDesign();
              toggle();
            }}
          >
            submit
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default CustDesign;
