import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { CustomizedCustomerRoutes } from "../../routes/CustomizedCustomerRoutes";
import { BrowserRouter, Route, Switch, Redirect, useLocation } from "react-router-dom";
import ProtectedRoute from "../modules/auth/protectedroutes";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));
const Layout_Customized_Customer = (props) => {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorElTemp, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  const logOut = () => {
    localStorage.clear();
    props.history.push("/welcome");
  };

  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <>       
          <BrowserRouter>
            <Switch>
              {CustomizedCustomerRoutes.map((route, index) => {
                return (
                  <ProtectedRoute exact key={index} path={route.path} component={route.component} />
                );
              })}
            </Switch>
          </BrowserRouter>    
    </>
  );
};

export default Layout_Customized_Customer;
