import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../../../_services/constant";
import OwlCarousel from "react-owl-carousel2";
import { postMethod } from "../../../../_services/_postMethod";
import Card from "@material-ui/core/Card";
import { CardMedia } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#000",
    backgroundColor: "#fff",
  },
  root: {
    minWidth: 275,
    marginBottom: 15,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

export default function CustomerVoucherComList(props) {
  const { voucher_id } = props;
  const classes = useStyles();
  const [voucherList, setVoucherList] = useState([]);
  const options = {
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
    nav: true,
    dots: true,
    autoplay: true,
    stagePadding: 10,
    loop: true,
  };
  useEffect(() => {   
    postMethod("api/session/voucherlist", {}).then((data) => {     
      var result = data.data.filter((item) => item.voucher_id != voucher_id); 
      setVoucherList(result);
    });
  }, []);

  return (
    <>
     {voucherList.length > 0 && voucherList.length == 1 ? "No Other Voucher" : ''}
      {voucherList.length > 0
        ? voucherList.map((prop, i) => (
            <Card className={classes.root} variant="outlined">
              <CardMedia
                component="img"
                alt="Contemplative Reptile"
                style={{ height: 150 }}
                onClick={() => {
                  var tran = prop.voucher_id;
                  if (prop.voucher_type == "birthday") {
                    window.location = `/generalsession/birthday?id=${tran}`;
                  } else if (prop.voucher_type == "anniversary") {
                    window.location = `/generalsession/anniversary?id=${tran}`;
                  } else if (prop.voucher_type == "instagram") {
                    window.location = `/generalsession/instagram?id=${tran}`;
                  } else if (prop.voucher_type == "referral") {
                    window.location = `/generalsession/referral?id=${tran}`;
                  }  else if (prop.voucher_type == "uploadDesign") {
                    window.location = `/generalsession/upload-design?id=${tran}`;
                  } else {
                    window.location = `/generalsession/special-voucher?id=${tran}`;
                  }
                }}
                image={API_BASE_URL + "CustomerVoucher/" + prop.image_path}
                className="img-flui d img-pointer"
                title="Contemplative Reptile"
              />
            </Card>
          ))
        : null}
      <div className="clr"></div>
    </>
  );
}
