import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useLocation } from "react-router-dom";
import { Button, Form, FormGroup, Label, Input, FormText, Row, Col, Alert } from "reactstrap";
import {
  postMethod,
  validateMobile,
  singleParam,
  editUserGrid,
} from "../../../../_services/_postMethod";
import {showSuccessToast,showErrorToast} from "../../../../_services/notification";
import Loader from "../../../modules/loader";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { MenuItem } from '@mui/material';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AddCustomer = (props) => {
  let queryParams = useQuery();
  const id = queryParams.get("id") == "" ? null : queryParams.get("id");
  const { register, handleSubmit, errors } = useForm();
  const [load, setLoad] = useState(false);
  const [loadInser, setLoadInsert] = useState(false);
  const [loadDelete, setLoadDelete] = useState(false);  
  const [loadDeleteMsg, setLoadDeleteMsg] = useState("Deleted");
  const [category, setCategory] = useState([]);
  const [areaList, setAreaList] = React.useState([]);
  const [staffList, setStaffList] = React.useState([]);
  const [refList, setRefList] = React.useState([]);
  const [refDefaultvalue, setRefDefaultvalue] = useState({
    customer_id:"",
    full_name:"",
    mobile:""
  });
  const [param, setParam] = useState({
    customer_id: id == null ? 0 : id,
    mobile: "",
    full_name: "",
    email: "",
    dob: null,
    category_id: null,
    doa: null,
    area_id: null,
    ref_id: null,
    staff_id: null,
    address: "",
    profession: "",
    gender: "",
    sp_bdy:null
  });
  const [success, setSuccess] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false); 
  const [isvalid, setIsValid] = useState({
    mobile: true,
    email: true,
  });
  const [valid, setValid] = useState({
    mobile: true,
    email: true,
  });

  // Customer Insert
  const insertData = (values) => {
    setLoadInsert(true);
    if(param.mobile == "")
    {
      showErrorToast("Please fill mobile.")
      setLoadInsert(false);
    } 
    else if (param.mobile.length !== 10) {
      setValid({ ...valid, mobile: false });
      setLoadInsert(false);
    } 
    else{
      postMethod("api/masters/customer_check_mobile", {customer_id:param.customer_id ,mobile : param.mobile}).then((dataD) => {
        setIsValid({ ...isvalid, mobile: dataD.data });
        if(dataD.data == true){
          param.ref_id = param.ref_id == 0 ? null : param.ref_id; 
          postMethod("api/masters/customer/insert", param).then((data) => {
            if (data.data[0].valid) {
              setSuccess(true);
              setTimeout(() => {
                props.history.push("/branch/customer");
              }, 2000);
            }
            else{
               setLoadInsert(false);
            }
           });
        }else{
          setLoadInsert(false);
        }
       
     
      });
  
    }
  };

  //Mobile check
  const checkMobile = (val) => {
    setIsValid({ ...isvalid, mobile: true });
    setParam({ ...param, mobile: val });
    if (val.length !== 10) {
      setValid({ ...valid, mobile: false });
    } else {
      setValid({ ...valid, mobile: true });    
      postMethod("api/masters/customer_check_mobile", {customer_id:param.customer_id ,mobile : val}).then((dataD) => {
        setIsValid({ ...isvalid, mobile: dataD.data });
      });
    }
  };
  const checkEmail = (val) => {
    setParam({ ...param, email: val });
    var regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (regex.test(val)) {
      setValid({ ...valid, email: true });
    } else {
      setValid({ ...valid, email: false });
    }
  };

  const handleDelete = () => {
    var proceed = window.confirm("Do you Really Wish to Delete this Entry..?!");

    if (proceed) {
        setLoadDelete(true);
      postMethod("api/masters/customer/delete", { customer_id: param.customer_id }).then((data) => {     
        if (data.valid) {
          setSuccess(true);
             setSuccessDelete(true);
          setTimeout(() => {
            props.history.push("/branch/customer");
          }, 2000);
        }
         else
        {
          setSuccessDelete(true);
              setTimeout(() => {
               setSuccessDelete(false);
            }, 4000);
             setLoadDeleteMsg(data.error)
           setLoadDelete(false);
        }
      });
    }
  };

  useEffect(() => {
    if (id !== null) {
      setLoad(true);
      var temp = window.atob(id);
      postMethod("api/masters/customer/preview", { customer_id: temp }).then((data) => {
       var data = data.data;
        setParam(data);        
        setParam({...param,
          customer_id: temp,
          date: (data.date ? data.date.split('T')[0] : null),
          mobile: data.mobile,
          email: data.email,
          dob: (data.dob ? data.dob.split('T')[0] : null),
          doa: (data.doa ? data.doa.split('T')[0] : null),
          full_name: data.full_name, 
          category_id: data.category_id,
          area_id: data.area_id,
          ref_id:  data.ref_id,
          staff_id:  data.staff_id,
          address:  data.address,
          gender:  data.gender,
          profession: data.profession,
          sp_bdy:(data.sp_bdy ? data.sp_bdy.split('T')[0] : null),
          });
          setRefDefaultvalue({...refDefaultvalue,customer_id:data.ref_id,mobile:data.mobile,full_name:data.ref_full_name});
        setLoad(false);
      });
    }
    getCategoryList();
    getAreaList();
    getStaffList();
    getRefList();
  }, []);

  const getCategoryList = () => {
    postMethod("api/masters/customer/category/Categorys", {}).then((data) => setCategory(data));
}
  
  const getAreaList = () => {
    postMethod("api/customervisit/AreaList").then(res => {
        setAreaList(res.data);
    });
}
const getStaffList = () => {
  postMethod("api/customervisit/StaffList").then(res => {
      setStaffList(res.data);
  })
}
const getRefList = () => {
  postMethod("api/customervisit/ReferenceList").then(res => {
    setRefList(res.data);
  })
}
  return (
    <>
      {load ? <Loader /> : null}
      <Form onSubmit={handleSubmit(insertData)}>
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Add Customer</h3>
            </div>
          </div>
          <div className="card-body">
            <Row>
              <Col sm={4}>
                <FormGroup>
                  <Label>Full Name</Label>
                  <input
                    type="text"                  
                    value={param.full_name}
                    className="form-control h-auto py-2 px-6"
                    name="full_name"
                    onChange={(e) =>
                      setParam({ ...param, full_name: e.target.value })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Mobile</Label>
                  <input
                    type="number"
                    required
                    value={param.mobile}
                    className="form-control h-auto py-2 px-6"
                    name="mobile"
                    onChange={(e) => checkMobile(e.target.value)}
                    ref={register}
                  />
                  {!valid.mobile && (
                    <span className="form-text text-danger">
                      Please enter valid mobile
                    </span>
                  )}
                  {!isvalid.mobile && (
                    <span className="form-text text-danger">
                      This Mobile number already registered
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Email</Label>
                  <input
                    type="text"
                 
                    value={param.email}
                    className="form-control h-auto py-2 px-6"
                    name="email"
                    onChange={(e) => checkEmail(e.target.value)}
                    ref={register}
                  />
                  {!valid.email && (
                    <span className="form-text text-danger">
                      Please enter valid email
                    </span>
                  )}
                  {!isvalid.email && (
                    <span className="form-text text-danger">
                      This email already registered
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Date of birth</Label>
                  <TextField
                    type="date"
                    name="dob"
                    value={(param.dob)?param.dob:null}
                    className="form-control"
                    ref={register}
                    onChange={(e) => {
                      setParam({
                        ...param,
                        dob: e.target.value,
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Date of anniversary</Label>
                  <TextField
                    type="date"
                    name="doa"
                    value={(param.doa)?param.doa:null}
                    className="form-control"
                    ref={register}
                    onChange={(e) => {
                      setParam({
                        ...param,
                        doa: e.target.value,
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormGroup>
              </Col>




              <Col sm={4}>
                <FormGroup>
                  <Label>Spous's Birthday</Label>
                  <TextField
                    type="date"
                    name="sp_bdy"
                    value={(param.sp_bdy)?param.sp_bdy:null}
                    className="form-control"
                    ref={register}
                    onChange={(e) => {
                      setParam({
                        ...param,
                        sp_bdy: e.target.value,
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormGroup>
              </Col>







              <Col sm={4} style={{marginTop:"10px"}}>
                <FormGroup>
                  <Label>Gender</Label>
                  <select
                    className="form-control h-auto py-2 px-6"                   
                    onChange={(e) => {
                      setParam({ ...param, gender: e.target.value });
                    }}
                    name="gender"
                    value={param.gender}
                    ref={register}
                  >
                    <option value="">--Select--</option>
                    <option value="male">Male</option>
                    <option value="female">female</option>
                  </select>
                </FormGroup>
              </Col>
              <Col sm={4} style={{marginTop:"10px"}}>
                <FormGroup>
                  <Label>Address</Label>
                  <input
                    type="text"                  
                    value={param.address}
                    className="form-control h-auto py-2 px-6"
                    name="address"
                    onChange={(e) => {
                      setParam({ ...param, address: e.target.value });
                    }}
                    ref={register}
                  />
                </FormGroup>
              </Col>
              <Col sm={4} style={{marginTop:"10px"}}>
                <FormGroup>
                  <Label>Category</Label>
                  <select
                    className="form-control h-auto py-2 px-6"
                    onChange={(e) => {
                      setParam({ ...param, category_id: e.target.value });
                    }}
                    name="category_id"
                    value={param.category_id}
                    ref={register}
                  >
                    <option value="">--Select--</option>
                    {category.map((prop, i) => {
                      return (
                        <option key={i} value={prop.category_id}>
                          {prop.category_name}
                        </option>
                      );
                    })}
                  </select>
                </FormGroup>
              </Col>

              <Col sm={4} >
                <FormGroup>
                  <Label>Area</Label>
                  <select
                    className="form-control h-auto py-2 px-6"
                    onChange={(e) => {
                      setParam({ ...param, area_id: e.target.value });
                    }}
                    name="area_id"
                    value={param.area_id}
                    ref={register}
                  >
                    <option value="">--Select--</option>
                    {areaList.map((prop, i) => {
                      return (
                        <option key={i} value={prop.area_id}>
                          {prop.area_name}
                        </option>
                      );
                    })}
                  </select>
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Staff</Label>
                  <select
                    className="form-control h-auto py-2 px-6"
                    onChange={(e) => {
                      setParam({ ...param, staff_id: e.target.value });
                    }}
                    name="staff_id"
                    value={param.staff_id}
                    ref={register}
                  >
                    <option value="">--Select--</option>
                    {staffList.map((prop, i) => {
                      return (
                        <option key={i} value={prop.staff_id}>
                          {prop.name}
                        </option>
                      );
                    })}
                  </select>
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Reference</Label>
                  <Autocomplete
                    id="combo-box-demo"
                    size="small"
                    options={refList}
                    value={refDefaultvalue}
                    onChange={(event, value) => {
                      setParam({
                        ...param,
                        ref_id: value ? value.customer_id : "",
                        
                      });
                     
                      setRefDefaultvalue(value);
                    }}
                    getOptionLabel={(option) =>
                      option.full_name
                    }
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Reference" />
                    )}
                  />
                </FormGroup>
              </Col>

              <Col sm={4}>
                <FormGroup>
                  <Label>Profession</Label>
                  <input
                    type="text"                 
                    value={param.profession}
                    className="form-control h-auto py-2 px-6"
                    name="profession"
                    onChange={(e) => {
                      setParam({ ...param, profession: e.target.value });
                    }}
                    ref={register}
                  />
                </FormGroup>
              </Col>
            </Row>
            {success ? <Alert color="success">Saved Successfully</Alert> : null}
            {successDelete ? (
              <Alert color="danger">{loadDeleteMsg}</Alert>
            ) : null}
          </div>
          <div className="card-footer text-right">
            <a href="/branch/customer" className="btn btn-secondary mr-2">
              Cancel
            </a>
            {id &&
              (loadDelete ? (
                <button type="button" className="btn btn-danger mr-2">
                  Deleting..
                </button>
              ) : (
                <button
                  type="button"
                  onClick={handleDelete}
                  className="btn btn-danger mr-2"
                >
                  Delete
                </button>
              ))}

            {loadInser ? (
              <button type="button" className="btn btn-primary ">
                Saving..
              </button>
            ) : (
              <button type="submit" className="btn btn-primary ">
                Save
              </button>
            )}
          </div>
        </div>
      </Form>
    </>
  );
};

export default AddCustomer;
