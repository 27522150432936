import Anniversary from "../app/screens/customerCRMExhibition/anniversary";
import Birthday from "../app/screens/customerCRMExhibition/birthday";
import CustomerHome from "../app/screens/customerCRMExhibition/home";
import Instragram from "../app/screens/customerCRMExhibition/instagram";
import CustomerProducts from "../app/screens/customerCRMExhibition/products";
import CustomerProductview from "../app/screens/customerCRMExhibition/productview";
import CustomerProfile from "../app/screens/customerCRMExhibition/profile";
import Referral from "../app/screens/customerCRMExhibition/referral";
import Specialvoucher from "../app/screens/customerCRMExhibition/specialvoucher";
import CustomerThankyou from "../app/screens/customerCRMExhibition/thank-you";
import Uploaddesignvoucher from "../app/screens/customerCRMExhibition/uploaddesignvoucher";
import CustomerWishlist from "../app/screens/customerCRMExhibition/wishlist";
export const ExhibitionRoutes = [
  {
    path: "/exhibition-view/home",
    name: "home",
    icon: "flaticon-web",
    child: false,
    component: CustomerHome,
  },
  {
    path: "/exhibition-view/products",
    name: "Products",
    icon: "flaticon-web",
    child: false,
    component: CustomerProducts,
  },
  {
    path: "/exhibition-view/product-view",
    name: "Product View",
    icon: "flaticon-web",
    child: false,
    component: CustomerProductview,
  },
  {
    path: "/exhibition-view/wish-list",
    name: "wish list",
    icon: "flaticon-web",
    child: false,
    component: CustomerWishlist,
  },
  {
    path: "/exhibition-view/thank-you",
    name: "thank you",
    icon: "flaticon-web",
    child: false,
    component: CustomerThankyou,
  },
  {
    path: "/exhibition-view/referral",
    name: "referral",
    icon: "flaticon-web",
    child: false,
    component: Referral,
  },
  {
    path: "/exhibition-view/birthday",
    name: "birthday",
    icon: "flaticon-web",
    child: false,
    component: Birthday,
  },
  {
    path: "/exhibition-view/anniversary",
    name: "Anniversary",
    icon: "flaticon-web",
    child: false,
    component: Anniversary,
  },
  {
    path: "/exhibition-view/instagram",
    name: "Instragram",
    icon: "flaticon-web",
    child: false,
    component: Instragram,
  },
  {
    path: "/exhibition-view/special-voucher",
    name: "special voucher",
    icon: "flaticon-web",
    child: false,
    component: Specialvoucher,
  },
  {
    path: "/exhibition-view/upload-design",
    name: "upload design",
    icon: "flaticon-web",
    child: false,
    component: Uploaddesignvoucher,
  },
  {
    path: "/exhibition-view/profile",
    name: "profile",
    icon: "flaticon-web",
    child: false,
    component: CustomerProfile,
  }
];
