import React, { Fragment, useEffect, useState } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { postMethod } from "../../../../_services/_postMethod";
import Loader from "../../../modules/loader";

const CompanyUser = (props) => {
  const [grid, setGrid] = React.useState([]);
  const [load, setLoad] = React.useState(false);
  const editRecord = (val) => {
    var temp = window.btoa(val);
    console.log(temp);
    props.history.push("/admin/company-users/add?id=" + temp);
  };

  const [gridParam, setGridParam] = useState({});
  const columns = [
    {
      key: "company_name",
      text: "Company Name",
      align: "left",
      sortable: true,
    },
    {
      key: "full_name",
      text: "Contact person",
      align: "left",
      sortable: true,
    },
    {
      key: "mobile",
      text: "Mobile",
      align: "left",
      sortable: true,
    },
    {
      key: "email",
      text: "Email",
      align: "left",
      sortable: true,
    },
 {
      key: "disable",
      text: "Status",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            {record.disable == "0" ? <h6 className="text-success">active</h6> :  <h6 className="text-danger">Deactive</h6> }
          </Fragment>
        );
      },
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="btn btn-link btn-sm"
              onClick={() => editRecord(record.tran_id)}
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-edit"></i>
            </button>
          </Fragment>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
  };

  useEffect(() => {
    setLoad(true);
    postMethod("api/companys/companyUser/browse", gridParam).then((dataD) => {    
      var data= dataD.data;
      setGrid(data);
      setLoad(false);
    });
  }, []);

  return (
    <>
      {load ? <Loader /> : null}
      <div className="card card-custom gutter-b">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">Company Users</h3>
          </div>
          <div className="card-toolbar"> 
            <a
              href="/admin/company-users/add"
              className="btn btn-sm btn-outline-theme font-weight-bold py-2 px-3"
            >
              <i className="flaticon2-user"></i> Add Company User
            </a>
          </div>
        </div>
        <div className="card-body">
          <ReactDatatable config={config} records={grid} columns={columns} />
        </div>
      </div>
    </>
  );
};

export default CompanyUser;
