import React, { useEffect, useState } from "react";
import Logo from "../../../_assets/media/favicon.png";
import { authenUser, postMethod } from "../../../_services/_postMethod";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from "react-hook-form";
import { Alert } from "reactstrap";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  //  backgroundColor: '#fff'
  },
}));
const Login = (props) => {
  const classes = useStyles(); 
  const [type, setType] = useState(localStorage.getItem("type"));
  const [otp, setOtp] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const [isvalid, setValid] = useState(false);
  const [load, setLoad] = useState(false);
  const login = (values) => {
  
    setLoad(true);
    if (otp) {
      postMethod(`auth/${type}/token`, values).then((data) => {
        if (data.status === 200) {
          localStorage.setItem("jwl_login", true);
          localStorage.setItem("jwl_token", data.data.access_token);
          localStorage.setItem("jwl_username", data.data.full_name);
           if (type === "company") {
             localStorage.setItem("jwl_company_name", data.data.company_name);
             localStorage.setItem("jwl_company_logo", data.data.logo);
           }
          if (type === "branch" || "exhibition") {
            localStorage.setItem("jwl_branch_id", data.data.branch_id);
            localStorage.setItem("jwl_company_name", data.data.company_name);
            localStorage.setItem("jwl_company_logo", data.data.logo);
            localStorage.setItem("jwl_company_name", data.data.company_name);
            localStorage.setItem("jwl_branchEncrypt_id", data.data.branch_encrypt_id);
            localStorage.setItem("branch_type", data.data.branch_type);
          }
          if (type === "branch") {
          localStorage.setItem("jwl_brand_name", data.data.sms_brand_name);
          localStorage.setItem("jwl_estore_link", data.data.estore_link); 
          localStorage.setItem("branch_type", data.data.branch_type);
          }
          window.location = `/${type}/dashboard`;
        } else {     
     
          setValid(true);
        }
        
        setLoad(false);
      });
    } else {
      postMethod(`auth/${type}/login`, values).then((data) => {
        if (data.status === 200) {
          setOtp(true);
          setValid(false);
        
        } else {        
          setOtp(false);
          setValid(true);
        }
       
        setLoad(false);
      });
    }
  };

  useEffect(() => {
  if(type === "" || type === null){
    props.history.push("/Welcome");
  }
  }, []);


const[cust, setCust] = useState([]);
  useEffect(() => {

  })

  return (
    <>
          <Backdrop className={classes.backdrop} open={load} >
    <CircularProgress color="inherit" />
      </Backdrop>
      <div className="exhibitionbg">
      <div className="container">
              <div className="text-center">
                <img src={Logo} />
              </div>
              <div className="login-form" style={{ marginTop: "25px" }}>
                <div className="main-div">
                  <form onSubmit={handleSubmit(login)}>
                    <div className="exgroup exsubs">
                      <div className="form-group">
                      <label className="font-weight-bold">Mobile</label>
                        <input
                          className="form-control custom"
                          readOnly={otp}
                          ref={register}
                          type="text"
                          name="user_name"
                          autoComplete="off"
                        />
                      </div>

                      <div className={"form-group " + (otp ? "hide" : "")}>
                        <label className="font-weight-bold">Password</label>
                        <input
                          className="form-control custom"
                          ref={register}
                          type="password"
                          placeholder=""
                          name="password"
                          autoComplete="off"
                        />
                      </div>

                      <div className={"form-group " + (!otp ? "hide" : "")}>
                        <label className="font-weight-bold">Enter 6-digit OTP</label>
                        <input
                          className="form-control custom"
                          ref={register}
                          type="number"
                           
                          name="otp"
                          autoComplete="off"
                        />
                      </div>
                      <span className="highlight"></span>
                      <span style={{ color: "red" }} className="exbar">
                        {isvalid && "* Invalid UserName Or Password"}
                      </span>
                    </div>

                    <div className="">
                      <button type="submit" className="btn btn-theme w-100 py-2">Login</button>
                    </div>
                  </form>
                </div>

                <div className="clr"></div>
              </div>
            </div>
        <div className="clr"></div>
        <div className="custom-footer">
          <div className="container mcntr">
            <div className="row">
              <div className="col-sm-10">© 2020 QuickTagg All Rights Reserved</div>
              <div className="col-sm-2">
                <div className="text-center center-block">
                  <a href="https://www.facebook.com/bootsnipp">
                    <i id="social-fb" className="fa fa-facebook fa-2x social"></i>
                  </a>
                  <a href="https://plus.google.com/+Bootsnipp-page">
                    <i id="social-gp" className="fa fa-instagram fa-2x social"></i>
                  </a>
                  <a href="mailto:bootsnipp@gmail.com">
                    <i id="social-em" className="fa fa-linkedin fa-2x social"></i>
                  </a>
                  <a href="https://twitter.com/bootsnipp">
                    <i id="social-tw" className="fa fa-twitter fa-2x social"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
