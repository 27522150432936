import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useLocation } from "react-router-dom";
import { Button, Form, FormGroup, Label, Input, FormText, Row, Col, Alert } from "reactstrap";
import {
  postMethod,
  validateMobile,
  singleParam,
  editUserGrid,
} from "../../../../_services/_postMethod";
import Loader from "../../../modules/loader";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField'; 

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AddStaff = (props) => { 
  let queryParams = useQuery();
  const id = queryParams.get("id") == "" ? null : queryParams.get("id");
  const { register, handleSubmit, errors } = useForm();
  const [load, setLoad] = useState(false);
  const [loadInser, setLoadInsert] = useState(false);
  const [loadDelete, setLoadDelete] = useState(false);  
  const [loadDeleteMsg, setLoadDeleteMsg] = useState("Deleted");
  const [branchData, setBranch] = useState({
    name: "",
    mobile: ""
  });
  const [staffList, setStaffList] = useState([]);
  const [success, setSuccess] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false); 

  const insertData = (values) => {    
    setLoadInsert(true);
    values.name=branchData.name;
    postMethod("api/masters/staff/insert", values).then((data) => {    
      if (data.data[0].valid) {
        setSuccess(true);
        setTimeout(() => {
          props.history.push("/branch/staff");
        }, 2000);
      }
      else{
          setLoadInsert(true);
      }
    });
  };

  const handleDelete = () => {
    var proceed = window.confirm("Do you Really Wish to Delete this Entry..?!");

    if (proceed) {
       setLoadDelete(true);
      postMethod("api/masters/staff/delete", {
        staff_id: branchData.staff_id,
      }).then((data) => {      
        if (data.data[0].valid) {
          setSuccessDelete(true);         
          setTimeout(() => {
            props.history.push("/branch/staff");
          }, 2000);
        }
      else
        {
          setSuccessDelete(true);
              setTimeout(() => {
               setSuccessDelete(false);
            }, 4000);
             setLoadDeleteMsg(data.error)
           setLoadDelete(false);
        }
      });
    }
  };

    const getstaffList = () =>{
      postMethod("api/customervisit/SearchStaffList", {search:branchData.name}).then((data) => setStaffList(data.data));
  }

  useEffect(() => { 
    getstaffList();
  }, [branchData.name]);

  useEffect(() => {
    if (id !== null) {
      setLoad(true);
      var temp = window.atob(id);
      postMethod("api/masters/staff/preview", { staff_id: temp }).then((data) => {     
        setBranch(data.data[0]);
        setLoad(false);
      });
    }
    getstaffList();
  }, []);
  return (
    <>
      {load ? <Loader /> : null}
      <Form onSubmit={handleSubmit(insertData)}>
        <input
          type="hidden"
          name="staff_id"
          value={id == null ? "0" : branchData.staff_id}
          ref={register}
        />
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Add Staff</h3>
            </div>
          </div>
          <div className="card-body">
            <Row>
              <Col sm={4}>
                <FormGroup>
                  <Label className="mb-0">Staff Name</Label>
                  <Autocomplete
                      id="name"
                      freeSolo
                      size="small"
                      value={branchData.name} 
                      options={staffList.map((option) => option.name)}  
                       onChange={(event, value) => {  
                      setBranch({ ...branchData, name: value });
                      }}   
                       renderInput={(params) => (
                        <TextField {...params}
                          onChange={(e) => setBranch({ ...branchData, name: e.target.value })}
                          margin="normal" variant="outlined" />
                      )}
                    />

                
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Mobile</Label>
                  <input
                    type="number"
                    required
                    value={branchData.mobile}
                    className="form-control h-auto py-2 px-6"
                    name="mobile"
                    onChange={(e) => setBranch({ ...branchData, mobile: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>
            </Row>
            {success ? <Alert color="success">Saved Successfully</Alert> : null}
            {successDelete ? <Alert color="danger">{loadDeleteMsg}</Alert> : null}
          </div>
          <div className="card-footer text-right">
            <a href="/branch/staff" className="btn btn-secondary mr-2">
              Cancel
            </a>
            {id && (
              loadDelete ? <button type="button" className="btn btn-danger mr-2">
                Deleting..
              </button> : 
               <button type="button" onClick={handleDelete} className="btn btn-danger mr-2">
                Delete
              </button>
            )}
          
            {loadInser ? 
             <button type="button" className="btn btn-primary ">
              Saving..
            </button> :  <button type="submit" className="btn btn-primary ">
              Save
            </button>}
          </div>
        </div>
      </Form>
    </>
  );
};

export default AddStaff;
