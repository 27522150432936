import Anniversary from "../app/screens/customerCRMGeneralCustomized/anniversary";
import Birthday from "../app/screens/customerCRMGeneralCustomized/birthday";
import CustomerHome from "../app/screens/customerCRMGeneralCustomized/home";
import Instragram from "../app/screens/customerCRMGeneralCustomized/instagram";
import CustomerProducts from "../app/screens/customerCRMGeneralCustomized/products";
import CustomerProductview from "../app/screens/customerCRMGeneralCustomized/productview";
import CustomerProfile from "../app/screens/customerCRMGeneralCustomized/profile";
import Referral from "../app/screens/customerCRMGeneralCustomized/referral";
import Specialvoucher from "../app/screens/customerCRMGeneralCustomized/specialvoucher";
import CustomerThankyou from "../app/screens/customerCRMGeneralCustomized/thank-you";
import Uploaddesignvoucher from "../app/screens/customerCRMGeneralCustomized/uploaddesignvoucher";
import CustomerWishlist from "../app/screens/customerCRMGeneralCustomized/wishlist";
export const GeneralSessionRoutes = [
  {
    path: "/generalsession/home",
    name: "home",
    icon: "flaticon-web",
    child: false,
    component: CustomerHome,
  },
  {
    path: "/generalsession/products",
    name: "Products",
    icon: "flaticon-web",
    child: false,
    component: CustomerProducts,
  },
  {
    path: "/generalsession/product-view",
    name: "Product View",
    icon: "flaticon-web",
    child: false,
    component: CustomerProductview,
  },
  {
    path: "/generalsession/wish-list",
    name: "wish list",
    icon: "flaticon-web",
    child: false,
    component: CustomerWishlist,
  },
  {
    path: "/generalsession/thank-you",
    name: "thank you",
    icon: "flaticon-web",
    child: false,
    component: CustomerThankyou,
  },
  {
    path: "/generalsession/referral",
    name: "referral",
    icon: "flaticon-web",
    child: false,
    component: Referral,
  },
  {
    path: "/generalsession/birthday",
    name: "birthday",
    icon: "flaticon-web",
    child: false,
    component: Birthday,
  },
  {
    path: "/generalsession/anniversary",
    name: "Anniversary",
    icon: "flaticon-web",
    child: false,
    component: Anniversary,
  },
  {
    path: "/generalsession/instagram",
    name: "Instragram",
    icon: "flaticon-web",
    child: false,
    component: Instragram,
  },
  {
    path: "/generalsession/special-voucher",
    name: "special voucher",
    icon: "flaticon-web",
    child: false,
    component: Specialvoucher,
  },
  {
    path: "/generalsession/upload-design",
    name: "upload design",
    icon: "flaticon-web",
    child: false,
    component: Uploaddesignvoucher,
  },
  {
    path: "/generalsession/profile",
    name: "profile",
    icon: "flaticon-web",
    child: false,
    component: CustomerProfile,
  },
];
