import React, { Fragment, useState, useEffect } from "react";
import { addDays } from "date-fns";
import moment from "moment";
import ReactDatatable from "@ashvin27/react-datatable";
import generatePDF, { Margin, Resolution } from "react-to-pdf";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CancelIcon from "@mui/icons-material/Cancel";
import * as XLSX from "xlsx";
import Whatsapp from "../Whatsapp";
import { postMethod } from "../../../../../_services/_postMethod";
import { API_BASE_URL } from "../../../../../_services/constant";
import "react-awesome-slider/dist/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import axios from "axios";

import {
  Row,
  Col,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardHeader,
  FormGroup,
  Label,
} from "reactstrap";
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Textarea,
  Divider,
  IconButton,
  FilledInput,
} from "@material-ui/core";
import PostAddIcon from "@mui/icons-material/PostAdd";
import CloseIcon from "@mui/icons-material/Close";
import {
  DatePicker,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import "react-tabs/style/react-tabs.css";
import telephoneIcon from "../../../../../_assets/media/telephone.png";

import { Alert } from "bootstrap";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddService(props) {
  const { transId } = props;

  const [modalAddService, setModalAddService] = useState(false);
  const toggleAddService = () => setModalAddService(!modalAddService);

  const [customerData, setCustomerData] = useState({
    tran_id: transId,
    customer_id: "",
    name: "",
    mobile: "",
    category: "",
    modal: "",
    staff: "",
    color: "",
    remark: "",
    payment: "",
    datetime: "",
    type:"",
  });

  const get_service_product = () => {
    postMethod("api/customervisit/get_customer_upload_details", { transId })
      .then((data) => {
        setCustomerData({
          ...customerData,
          name: data.data[0].customer_name,
          customer_id: data.data[0].customer_id,
          mobile: data.data[0].mobile,
          category: data.data[0].product_category,
          modal: data.data[0].subcategory_name,
          staff: data.data[0].staff_name,
          remark: data.data[0].remarks,
          payment: data.data[0].payment,
          color: data.data[0].color,
          datetime: data.data[0].datetime,
          image: data.data[0].image_path,
          type:data.data[0].type,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    get_service_product();
  }, []);

  const [nxtservice, setNxtService] = useState("");
  const getServiceDateFormat = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

    return formattedDate;
  };

  return (
    <>
      <Button className="btn btn-info" onClick={toggleAddService}>
        <PostAddIcon />
      </Button>
      <Modal
  isOpen={modalAddService}
  zIndex="999999"
  size="xl"
  backdrop="static"
  centered={true}
>
  <ModalHeader>
    <Row style={{ marginBottom: "10px" }}>
      <Col sm="6">
        <h4>Add Service</h4>
      </Col>
      <Col sm="6">
        <CloseIcon
          style={{ float: "right" }}
          onClick={toggleAddService}
        />
      </Col>
    </Row>
    <Row>
      <Card
        className="shadow ml-4"
        style={{ width: "59rem", marginBottom: "-1%" }}
      >
        <CardHeader className="d-flex align-items-center justify-content-between font-weight-bold">
          <Col>
            <i>{customerData.name || ""}</i>
          </Col>
          <Col>
            <i>{customerData.mobile || ""}</i>
          </Col>
          <Col>
            <h5 color="red">Date : </h5>
          </Col>
          <Col>
            <Input
              type="text"
              value={moment(customerData.datetime).format("DD/MM/YYYY")}
              readOnly
            ></Input>
          </Col>
        </CardHeader>
      </Card>
    </Row>
  </ModalHeader>
  <ModalBody style={{ maxHeight: "480px", overflowY: "auto" }}>
    <div className="table-responsive" id="data_list_table">
      <Row>
        <Col sm="9">
          <Row>
            <Col>
              <FormGroup>
                <Label for="category">Category</Label>
                <Input
                  type="text"
                  placeholder="Category"
                  value={customerData.category || ""}
                  readOnly
                  id="category"
                ></Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="modal">Model</Label>
                <Input
                  type="text"
                  id="modal"
                  placeholder="Modal"
                  value={customerData.modal || ""}
                  readOnly
                ></Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="color">Color</Label>
                <Input
                  type="text"
                  placeholder="Color"
                  value={customerData.color || ""}
                  readOnly
                  id="color"
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <FormGroup>
                <Label for="staff">Staff</Label>
                <Input
                  type="text"
                  id="staff"
                  placeholder="Staff"
                  value={customerData.staff || ""}
                  readOnly
                ></Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="payment">Payment</Label>
                <Input
                  type="text"
                  placeholder="Payment"
                  value={customerData.payment || ""}
                  readOnly
                  id="payment"
                ></Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="next_service">Next Service</Label>
                <Input
                  type="date"
                  placeholder="Next Service"
                  value={getServiceDateFormat(nxtservice) || ""}
                  onChange={(e) => setNxtService(e.target.value)}
                  id="next_service"
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <FormGroup>
                <Label for="remark">Remark</Label>
                <Input
                  type="textarea"
                  placeholder="Remark"
                  id="remark"
                  value={customerData.remark || ""}
                  readOnly
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <br />
        </Col>

        <Col sm="3" className="d-flex align-items-center justify-content-center">
          <img
            style={{
              width: "230px",
              height: "230px",
              backgroundColor: "black",
            }}
            src={API_BASE_URL + "CustomerUploads/" + customerData.image}
          />
        </Col>
      </Row>

      <hr style={{ border: "2px solid blue" }} />
      {/* ------------------part 2  */}
      <SubCatService nxt={nxtservice} customerData={customerData} />
    </div>
  </ModalBody>
</Modal>
    </>
  );
}
// -----------------SUB CATEGORY SERVICE-----------------
function SubCatService(props) {
  const { nxt, customerData } = props;
  const [staffList, setStaffList] = useState([]);

  const getStaffList = () => {
    postMethod("api/customervisit/StaffList").then((res) => {
      setStaffList(res.data);
    });
  };

  useEffect(() => {
    getStaffList();
  }, []);

  const [uploadService, setUploadService] = React.useState({
    tran_id: customerData.tran_id || "",
    customer_id: customerData.customer_id || "",
    full_name: customerData.name || "",
    category: customerData.category || "",
    mobile: customerData.mobile || "",
    staff: customerData.staff || "",
    modal: customerData.modal || "",
    image: customerData.image || "",
    next_service: nxt,
    service_date: "",
    payment: "",
    vichle: "",
    remark: "",
    kms: "",
    color: customerData.color || "",
    type: customerData.type || "",
  });

  function clearForm() {
    setUploadService({
      full_name: customerData.name || "",
      mobile: customerData.mobile || "",
      staff: customerData.staff || "",
      next_service: "",
      service_date: "",
      payment: "",
      vichle: "",
      remark: "",
      kms: "",
      color: customerData.color || "",
      type: customerData.type || "",
    });
  }

  const handleUploadService = (e) => {
    setUploadService({
      ...uploadService,
      [e.target.name]: e.target.value,
    });
  };

  const handleIntegerForm = (e) => {
    const value = e.target.value;

    // Check if the value is a valid integer
    if (!Number.isInteger(Number(value))) {
      alert("it should be a whole number.");
      setUploadService({
        ...uploadService,
        [e.target.name]: "",
      });
      return;
    }
    // Proceed with setting the state if the value is a valid number
    setUploadService({
      ...uploadService,
      [e.target.name]: value,
    });
    
  };

  function insertService() {
    postMethod("api/customervisit/insert_service", {
      ...uploadService,
      tran_id: customerData.tran_id,
      next_service: nxt,
    }).then((data) => {
      if (data.data[0].valid) {
        alert("Success");
      } else {
        alert("Something Went Wrong");
      }
    });
  }

  function handleFormSubmit() {
    insertService();
    alert("Done");
    clearForm();
  }

  return (
    <>
      <Row>
        <Col sm="9">
          <Row>
            <Col>
              <FormGroup>
                <Label for="service_due">Service Due</Label>

                <Input
                  type="date"
                  placeholder="Service Due"
                  value={nxt}
                  readOnly
                />
              </FormGroup>
            </Col>
            {/* <Col>
              <FormGroup>
                <Label for="next_service">Next Service</Label>

                <Input
                  type="date"
                  placeholder="Next Service Date"
                  name="next_service"
                  id="next_service"
                  value={uploadService.next_service}
                  onChange={handleUploadService}
                />
              </FormGroup>
            </Col> */}
            {/* <Col>
              <FormGroup>
                <Label for="service_date">Service Date</Label>
                <Input
                  type="date"
                  placeholder="Service Date"
                  name="service_date"
                  id="service_date"
                  value={uploadService.service_date}
                  onChange={handleUploadService}
                />
              </FormGroup>
            </Col> */}

<Col>
              <FormGroup>
                <Label for="vichle">Vehicle Number</Label>
                <Input
                  type="text"
                  placeholder="Vehicle Number"
                  name="vichle"
                  id="vichle"
                  value={uploadService.vichle}
                  onChange={handleUploadService}
                />
              </FormGroup>
            </Col>
            <Col sm="4">
              <FormGroup>
                <Label for="staff">Staff Name</Label>
                <Input
                  type="select"
                  name="staff"
                  id="staff"
                  value={uploadService.staff || ""}
                  onChange={handleUploadService}
                >
                  {staffList.map((item, i) => (
                    <option value={item.name} key={i}>
                      {item.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <br />
          <Row>
            
            <Col>
              <FormGroup>
                <Label for="kms">KMS</Label>
                <Input
                  type="text"
                  placeholder="Kilometers"
                  name="kms"
                  id="kms"
                  value={uploadService.kms}
                  onChange={handleIntegerForm}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="payment">Payment</Label>
                <Input
                  type="text"
                  placeholder="Payment"
                  name="payment"
                  id="payment"
                  value={uploadService.payment}
                  onChange={handleUploadService}
                />
              </FormGroup>
            </Col>
          </Row>
          <br />
         
          <Row>
            <Col>
              <FormGroup>
                <Label for="remark">Remark</Label>
                <Input
                  type="textarea"
                  placeholder="Remark"
                  name="remark"
                  id="remark"
                  value={uploadService.remark}
                  onChange={handleUploadService}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col sm="3" >
          <img
            style={{
              width: "230px",
              height: "230px",
              backgroundColor: "black",
             
            }}
            src={`${API_BASE_URL}CustomerUploads/${customerData.image}`}
          />
        </Col>
       
      </Row>
      <Row className="justify-content-end">
  <Col md="auto">
    <Button size="xl" className="btn btn-danger" onClick={clearForm}>
      Clear
    </Button>
  </Col>
  <Col md="auto">
    <Button size="xl" className="btn btn-success" onClick={handleFormSubmit}>
      Save
    </Button>
  </Col>
</Row>

    
      <hr style={{ border: "2px solid blue" }} />
    </>
  );
}
