import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useLocation } from "react-router-dom";
import FilterResults from 'react-filter-search'; 
import { Button, Form, FormGroup, Label, FormText, Row, Col, Alert, table, Table,Modal, ModalHeader, ModalBody, ModalFooter  } from "reactstrap";
import {
  postMethod,
  validateMobile,
  singleParam,
  editUserGrid,
} from "../../../../_services/_postMethod";
import Loader from "../../../modules/loader";
import TextField from "@material-ui/core/TextField";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {WEB_BASE_URL } from "../../../../_services/constant";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AddExhibitionSession = (props) => {
  let queryParams = useQuery();
  const id = queryParams.get("id") == "" ? null : queryParams.get("id");
const [branchEncryptID, setBranchEncryptID] = React.useState(localStorage.getItem("jwl_branchEncrypt_id"));
  const [copylink, setCopylink] = useState("");
  const [sharedLink, setSharedLink] = useState("");
  const { register, handleSubmit, errors } = useForm();
  const [load, setLoad] = useState(false);
  const [loadInser, setLoadInsert] = useState(false);
  const [loadDelete, setLoadDelete] = useState(false);  
  const [loadDeleteMsg, setLoadDeleteMsg] = useState("Deleted");
  const [productsValidation, setProductsValidation] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [selectedCustomers, setselectedCustomers] = useState([]);
  const [copyed, setCopyed] = useState(false);
  const [subcategory, setSubcategory] = useState([]);
  const [productlist, setproductlist] = useState([]);
  const [selectedSubCategory, setselectedSubCategory] = useState([]);
  const [selectedSubCategoryObject, setSelectedSubCategoryObject] = useState({
    mtran_id: "",
    category_id: "",  
    subcategory_id: "",
    text: "",
    min_amount:null,
    max_amount:null
  });
  const [param, setParam] = useState({
    tran_id: id == null ? "0" : id,  
    entry_no: "",   
    title: "",   
    customer_session_products: [],
    remarks: "",
  });
  const [success, setSuccess] = useState(false);
  
  const [entryDelete, setEntryDelete] = useState(false);
  const [isvalid, setIsValid] = useState({
    mobile: true,
    email: true,
  });
  const [valid, setValid] = useState({
    mobile: true,
    email: true,
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        marginLeft: -10,
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 310,
      },
    },
  };

  const addData = () => {
    if(selectedSubCategory.length == 0)
    {
     setProductsValidation(true);
    }
    else{
      setLoadInsert(true);
    param.product_ids =selectedSubCategory;  
    param.customers = []; 
    postMethod("api/transactions/customer/exhibition/insert", param).then((data) => {
      if (data.status === 200) {  
        setParam({...param,tran_id:data.ID});    
           toggle();    
           setLoadInsert(false);   
      }
      else{
         setLoadInsert(false);
      }
    });
    }
 
  };

  const SearchProduct = () => {
    if (selectedSubCategoryObject.subcategory_id !== "") {
      postMethod("api/transactions/customer/session/getProducts", 
      {
        subcategory_id: selectedSubCategoryObject.subcategory_id,      
        min_amount: selectedSubCategoryObject.min_amount,
        max_amount: selectedSubCategoryObject.max_amount
      }).then((data) => {
        setproductlist(data.data);
      });
      
    }
  };

  useEffect(() => {
    setCopylink(WEB_BASE_URL+"e?t="+branchEncryptID) 
    setSharedLink(WEB_BASE_URL+"e?t="+branchEncryptID); 
    var selectCategory=[];
    postMethod("api/transactions/customer/session/getSubcategory", {}).then((data) => {
      setSubcategory(data.data);
      selectCategory =data.data; 
    });
    setLoad(true);
    if (id !== null) {
    
      var temp = window.atob(id);
      postMethod("api/transactions/customer/exhibitionsession/preview-session", { tran_id: temp }).then((dataD) => {
     var data = dataD.data[0];
        setParam({...param,
          tran_id: temp,       
          entry_no: data.entry_no,      
          title: data.title,    
          customer_session_products: data.products,
          remarks: data.remarks,
          }
          );
          setselectedSubCategory(data.products);

          let newselectedCategoryArr = [...data.products] 
          for (let Mainindex = 0; Mainindex < selectCategory.length; Mainindex++) {
             const elementMain = selectCategory[Mainindex];
            
             for (let logoindex = 0; logoindex < newselectedCategoryArr.length; logoindex++) {
                const element = newselectedCategoryArr[logoindex];                 
                if(element.subcategory_id === elementMain.subcategory_id)
                {
                  selectCategory[Mainindex].checked = 'true';
                }  
             }               
          }  
          setSubcategory([...selectCategory]); 
          setselectedCustomers(data.customers);
         
        setLoad(false);
      });
    }
    else{
      postMethod("api/transactions/customer/exhibitionsession/preview-session", { tran_id: 0 }).then((dataD) => {
        var data =  dataD.data[0];
           setParam({...param,         
             entry_no: data.entry_no
             });   
                 setselectedSubCategory(data.products);

          let newselectedCategoryArr = [...data.products] 
          for (let Mainindex = 0; Mainindex < selectCategory.length; Mainindex++) {
             const elementMain = selectCategory[Mainindex];
            
             for (let logoindex = 0; logoindex < newselectedCategoryArr.length; logoindex++) {
                const element = newselectedCategoryArr[logoindex];                 
                if(element.subcategory_id === elementMain.subcategory_id)
                {
                  selectCategory[Mainindex].checked = 'true';
                }  
             }               
          }  
          setSubcategory([...selectCategory]); 
           setLoad(false);
         });
    }

      postMethod("api/transactions/customer/customerListMob", {}).then((data) => {
      setCustomerList(data.data);    
      Setsearchable({...searchable,data:data.data});
    });
   
  }, []);
 
  // check object is null or undefined
    const checkObject = (obj) => {
      return obj === undefined ? {} : Object.keys(obj).length === 0 && obj.constructor === Object;
    };
   // Add single product
    const AddProduct = (Obj) => {    
      var tempIndex = selectedSubCategory.findIndex(x => x.product_id === Obj.product_id);
      var tempArry = [...selectedSubCategory];
      if(tempIndex > -1){
         tempArry.splice(tempIndex, 1);
         setselectedSubCategory(tempArry);
      }
      else{
           var tempparam = { 
             mtran_id: param.tran_id,
             category_id: Obj.category_id,  
             subcategory_id: Obj.subcategory_id,
             product_id: Obj.product_id,
             product_code: Obj.product_code,
             product_name: Obj.product_name,
             gender: Obj.gender,
             price: Obj.price
         }
         tempArry.push(tempparam);
         setselectedSubCategory(tempArry);
      }
   };

   // Add Or Remove ALL Product from arry
  const AddAllProduct = (checkAll) => {   
      var tempArry = [...selectedSubCategory];
      if(checkAll)
      {
      productlist.forEach(Obj => { 
      var tempIndex = tempArry.findIndex(x => x.product_id === Obj.product_id);       
        if(tempIndex == -1){ 
               var tempparam = { 
               mtran_id: param.tran_id,
             category_id: Obj.category_id,  
             subcategory_id: Obj.subcategory_id,
             product_id: Obj.product_id,
             product_code: Obj.product_code,
             product_name: Obj.product_name,
             gender: Obj.gender,
             price: Obj.price
          }
          tempArry.push(tempparam);  
        }  
      }); 
      }
      else{
         productlist.forEach(Obj => { 
         var tempIndex = tempArry.findIndex(x => x.customer_id === Obj.customer_id);       
         if(tempIndex > -1){
          tempArry.splice(tempIndex, 1);    
         }      
      }); 
      }
      
      setselectedSubCategory(tempArry);
    };


    const removeProduct = (i) => {
      let tempArry = [...selectedSubCategory];
      tempArry.splice(i, 1);
      setselectedSubCategory(tempArry);
    };

    const removeProductAll = () =>{
      if (window.confirm("Are you sure want to remove all products ?")) {
        setselectedSubCategory([]);
    }
    }
    const {
      buttonLabel,
      className
    } = props;
  
    
    // Link Modal Toggle
    const [modal, setModal] = useState(false);  
    const toggle = () => setModal(!modal); 

    // Customer Modal Toggle
    const [customerModal, setCustomerModal] = useState(false);  
    const toggleCustomerModal = () => setCustomerModal(!customerModal); 


  // Add Or Remove single Customer from arry
  const AddCustomer = (Obj) => {    
      var tempIndex = selectedCustomers.findIndex(x => x.customer_id === Obj.customer_id);
      var tempArry = [...selectedCustomers];
      if(tempIndex > -1){
         tempArry.splice(tempIndex, 1);
         setselectedCustomers(tempArry);
      }
      else{
           var tempparam = { 
             mtran_id: param.tran_id,
             customer_id: Obj.customer_id,  
             full_name: Obj.full_name,
             mobile: Obj.mobile
         }
         tempArry.push(tempparam);
         setselectedCustomers(tempArry);
      }
   };


 // Add Or Remove ALL Customer from arry
  const AddAllCustomer = (checkAll) => {   
      var tempArry = [...selectedCustomers];
      if(checkAll)
      {
      customerList.forEach(Obj => { 
      var tempIndex = tempArry.findIndex(x => x.customer_id === Obj.customer_id);       
        if(tempIndex == -1){ 
               var tempparam = { 
              mtran_id: param.tran_id,
              customer_id: Obj.customer_id,  
              full_name: Obj.full_name,
              mobile: Obj.mobile
          }
          tempArry.push(tempparam);  
        }  
      }); 
      }
      else{
         customerList.forEach(Obj => { 
         var tempIndex = tempArry.findIndex(x => x.customer_id === Obj.customer_id);       
         if(tempIndex > -1){
          tempArry.splice(tempIndex, 1);    
         }      
      }); 
      }
      
      setselectedCustomers(tempArry);
    };
 
  // Send SMS To Customers
   const sendSMS = () =>{

    var paramD = {
      tran_id: 0,
      mtran_id: param.tran_id,
      sms: "exhibition session sms",
      type: "exhibition session",
      sms_details: selectedCustomers,
      shared_url: "e?t=" + branchEncryptID,
    };
    postMethod("api/transactions/customer/exhibitionsession/SendSms", paramD).then((dataD) => {
         props.history.push("/branch/customer/exhibition-session");
    });
   }


   const [searchable,Setsearchable]= useState({
    data: customerList,
    value: ''
  })
 const handleSeachChange = event => {
    Setsearchable({...searchable,value:event.target.value});
  };

  const dateConvert = (date) => {
    const originalDate = new Date(date);

    const year = originalDate.getFullYear();
    const month = String(originalDate.getMonth() + 1).padStart(2, '0');
    const day = String(originalDate.getDate()).padStart(2, '0');
    const hours = String(originalDate.getHours()).padStart(2, '0');
    const minutes = String(originalDate.getMinutes()).padStart(2, '0');
    const seconds = String(originalDate.getSeconds()).padStart(2, '0');
    const ampm = originalDate.getHours() >= 12 ? 'PM' : 'AM';

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate

  }
  return (
    <>
    
    <div>

      <Modal isOpen={customerModal} toggle={toggleCustomerModal} className={className}  zIndex="999999">
        <ModalHeader toggle={toggleCustomerModal}>Customer List</ModalHeader>
        <ModalBody>
        <Row>
            <Col sm={12}>      
            <input type="text" placeholder="Search" className="ml-auto pull-right" value={searchable.value} onChange={(e) => handleSeachChange(e)} />   
                <Table bordered>
                  <thead>
                    <tr>                   
                      <th>Customer Name </th>
                      <th>Mobile</th>         
                      <th>Join Date</th>          
                      <th>
                        <div className="d-flex w-100"> 
                        ALL  {searchable.value ? "": <input
                                type="checkbox"                  
                                 className=""
                                name="available"
                                 onChange={(e) => {   
                                     AddAllCustomer(e.target.checked);
                                 }}                             
                              /> }
                              </div>
                              </th>
                    </tr>
                  </thead>
                  <tbody>
                    <FilterResults
                        value={searchable.value}
                        data={searchable.data}
                        renderResults={results => ( results.length > 0 ? results.map((obj, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                {i + 1}) {obj.full_name}
                              </td>
                              <td>{obj.mobile}</td>
                              <td>{dateConvert(obj.datetime)}</td>
                              <td>
                              <input
                                type="checkbox"                  
                                checked={ selectedCustomers.findIndex(e => e.customer_id === obj.customer_id) > -1 ? true : false}
                                className="form-control h-auto py-2 px-6"
                                name="available"
                                 onChange={(e) => {                               
                                     AddCustomer(obj); 
                                 }}                             
                              />
                                {/* <button type="button" onClick={() => removeProduct(i)}>
                                  x
                                </button> */}
                              </td>
                            </tr>
                          );
                        }) : null  )}
                        />
                  </tbody>
                </Table>
              </Col>
        </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={()=> {sendSMS();}}>Send</Button>{' '}
          <Button color="primary" onClick={()=> {props.history.push("/branch/customer/exhibition-session")}}>Back to Browse</Button>{' '}
          <Button color="secondary" onClick={toggleCustomerModal}>Cancel</Button>
        </ModalFooter>
      </Modal>


      
      <Modal isOpen={modal} toggle={toggle} backdrop="static"  className={className}  zIndex="999999">
        <ModalHeader toggle={toggle}>Customer Link</ModalHeader>
        <ModalBody>
         <div className="d-flex w-100"> <div style={{background:"rgb(190 196 231)",padding:"16px"}} > 
          {copylink}
          </div> 
          
        <CopyToClipboard text={copylink}
          onCopy={() => {}}>
           {copyed ? <i className="fas fa-copy" style={{color:'#4dd74d',cursor:"pointer",padding:"16px"}}></i> : <i className="fas fa-copy" style={{cursor:"pointer",padding:"16px"}} onClick={()=>{
            setCopyed(true);
         }}></i>} 
        </CopyToClipboard>
       </div>
        </ModalBody>
        <ModalFooter>
           <Button color="primary" onClick={()=> {toggle(); toggleCustomerModal();}}>Share with Customer</Button>{' '}
          <Button color="primary" onClick={()=> {props.history.push("/branch/customer/exhibition-session")}}>Back to Browse</Button>{' '}
         </ModalFooter>
      </Modal>
      
    </div>


      {load ? <Loader /> : null}
      <Form onSubmit={handleSubmit(addData)}>
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Add Customer Trial Session</h3>
            </div>
          </div>
          <div className="card-body">
            <Row>
               <Col sm={4}>
                <FormGroup>
                  <Label>Entry No.</Label>
                  <input
                    type="text"
                    required
                    value={param.entry_no}
                    disabled="true"
                    className="form-control h-auto py-2 px-6"
                    name="entry_no"
                    onChange={(e) =>
                      setParam({
                        ...param,
                        entry_no: e.target.value,
                      })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Title</Label>
                  <input
                    type="text"
                    required
                    value={param.title}                  
                    className="form-control h-auto py-2 px-6"
                    name="title"
                    onChange={(e) =>
                      setParam({
                        ...param,
                        title: e.target.value,
                      })
                    }
                    ref={register}
                  />
                </FormGroup>
              </Col>
              
              
              <Col sm={6}>
                <FormGroup>
                  <Label>Remarks</Label>
                  <textarea                
                    required
                    value={param.remarks}
                    className="form-control h-auto py-2 px-6"
                    name="remarks"
                    onChange={(e) => setParam({ ...param, remarks: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>
           
              <Col sm={12}>
            
                <Table bordered>
                  <thead>
                    <tr>                   
                      <th>Name</th>
                      <th>Min Amount</th>
                      <th>Max Amount</th>
                      <th>Action</th>
                    </tr>
                    <tr>
                      <th>
                        <select
                          className="form-control h-auto py-2 px-6"
                          name="product"
                          value={selectedSubCategoryObject.product_id}
                          onChange={(e) =>{
                         
                            setSelectedSubCategoryObject({
                              ...selectedSubCategoryObject,
                              category_id: e.target.value.split('-')[0],
                              subcategory_id: e.target.value.split('-')[1],
                              text: e.target.options[e.target.selectedIndex].text,
                            })                           
                          }
                          }
                        >
                          <option value="">-select-</option>
                         {subcategory.length < 0
                            ? null
                            : subcategory.map((obj, i) => {
                                return (
                                  <option key={i} value={obj.category_id+"-"+obj.subcategory_id}>
                                  {obj.subcategory_name + "(" + obj.category_name + ")"}
                                  </option>
                                );
                              })} 
                        </select>
                      </th>
                      <th>
                        <input
                          type="number"
                          className="form-control h-auto py-2 px-6"
                          name="min_amount"
                          onChange={(e) =>
                            setSelectedSubCategoryObject({
                              ...selectedSubCategoryObject,
                              min_amount: e.target.value,
                            })
                          }
                          value={selectedSubCategoryObject.min_amount}
                        />
                      </th>
                      <th>
                        <input
                          type="number"
                          className="form-control h-auto py-2 px-6"
                          name="max_amount"
                          onChange={(e) =>
                            setSelectedSubCategoryObject({
                              ...selectedSubCategoryObject,
                              max_amount: e.target.value,
                            })
                          }
                          value={selectedSubCategoryObject.max_amount}
                        />
                      </th>
                      <th>
                        <button type="button" className="btn-success" onClick={() => { SearchProduct()}}>
                          Search
                        </button>
                      </th>
                    </tr>
                  </thead>                 
                </Table>
              </Col>
              
{productlist.length > 0 ?

              <Col sm={12}>
                <h3>Select Products</h3>
                <Table bordered>
                  <thead>
                    <tr>                   
                      <th>Product Code </th>
                      <th>Product Name</th>
                      <th>Gender</th>
                      <th>Price</th>
                        <th>Image</th>
                      <th>Exhibtion</th>
                       <th>Trial</th>
                      <th  className="d-flex w-100">
                        ALL  <input
                                type="checkbox"                  
                                className="mt-2 ml-2"
                                name="available"
                                 onChange={(e) => {                               
                                     AddAllProduct(e.target.checked)
                                 }}                             
                              />
                                                            </th>
                    </tr>
                  </thead>
                  <tbody>
                    {productlist.length > 0 ? productlist.map((obj, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                {i + 1}) {obj.product_code}
                              </td>
                              <td>{obj.product_name}</td>
                              <td>{obj.gender}</td>
                              <td>{obj.price}</td>
                                <td><img src={obj.url_image +""+obj.image_path} width="36px" alt="" />   </td>  
                               <td>{obj.exhibition ? <label className="text-success">Active</label>:<label className="text-danger">Deactive</label>}</td>
                              <td>{obj.trial ? <label className="text-success">Active</label>:<label className="text-danger">Deactive</label>}</td>
                             
                              <td>
                              <input
                                type="checkbox"                  
                                checked={ selectedSubCategory.findIndex(e => e.product_id === obj.product_id) > -1 ? true : false}
                                 name="available"
                                 onChange={(e) => {                               
                                     AddProduct(obj); 
                                 }}                             
                              />
                              </td>
                            </tr>
                          );
                        }) : null } 
                  </tbody>
                </Table>
              </Col>
: null}


              <Col sm={12}>
                  <h3>Selected Products {productsValidation ?  <label className="text-danger">*Required</label> : null}</h3> 
                <Table bordered>
                  <thead>
                    <tr>                   
                      <th>Product Code </th>
                      <th>Product Name</th>
                      <th>Gender</th>
                      <th>Price</th>
                      <th>Image</th>
                      <th>Action <button type="button" onClick={() => removeProductAll()}>
                                  x
                                </button></th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedSubCategory.length > 0
                      ? selectedSubCategory.map((obj, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                {i + 1}) {obj.product_code}
                              </td>
                              <td>{obj.product_name}</td>
                              <td>{obj.gender}</td>
                              <td>{obj.price}</td>
                              <td><img src={obj.url_image +""+obj.image_path} width="36px" alt="" />   </td>  
                              <td>
                             
                                <button type="button" onClick={() => removeProduct(i)}>
                                  x
                                </button>
                              </td>
                            </tr>
                          );
                        }) :   <tr>                   
                      <th colSpan="5" className="text-center"> No record </th>
                    </tr> } 
                  </tbody>
                </Table>
              </Col>
            </Row>




            {success ? <Alert color="success">Saved Successfully</Alert> : null}
            {entryDelete ? <Alert color="danger">Entry Deleted</Alert> : null}
          </div>
          <div className="card-footer text-right">
            {id && (   
               <button type="button" onClick={toggle} className="btn btn-success mr-2">
                Session Link
              </button>          
             
            )}
            <a href="/branch/customer/exhibition-session" className="btn btn-secondary mr-2">
              Cancel
            </a>
           
          
            {!id && ( loadInser ? 
             <button type="button" className="btn btn-primary ">
              Saving..
            </button> :  <button type="submit" className="btn btn-primary ">
              Save
            </button>)}
          </div>
        </div>
      </Form>
    </>
  );
};

export default AddExhibitionSession;
