import AddCompany from "../app/screens/admin/company/addcompany";
import Company from "../app/screens/admin/company/company";
import AddCompanyUser from "../app/screens/admin/companyUser/addcompanyUser";
import CompanyUser from "../app/screens/admin/companyUser/companyUser";
import AdminDashboard from "../app/screens/admin/dashboard/dashboard";

export const AdminRoutes = [
  {
    path: "/admin/dashboard",
    name: "Dashboard",
    icon: "flaticon-web",
    child: false,
    component: AdminDashboard,
  },
  {
    path: "/admin/company",
    name: "Company",
    icon: "flaticon-user",
    child: false,
    component: Company,
  },
  {
    path: "/admin/company/add",
    name: "Company",
    icon: "flaticon-user",
    child: true,
    component: AddCompany,
  }, {
    path: "/admin/company-users",
    name: "Company Users",
    icon: "flaticon-user",
    child: false,
    component: CompanyUser,
  },
  {
    path: "/admin/company-users/add",
    name: "Company user",
    icon: "flaticon-user",
    child: true,
    component: AddCompanyUser,
  },
];
