import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useLocation } from "react-router-dom";
import { Button, Form, FormGroup, Label, FormText, Row, Col, Alert, table, Table,Modal, ModalHeader, ModalBody, ModalFooter  } from "reactstrap";
import {
  postMethod,
  validateMobile,
  singleParam,
  editUserGrid,
} from "../../../../_services/_postMethod";
import Loader from "../../../modules/loader";
import TextField from "@material-ui/core/TextField";
import {CopyToClipboard} from 'react-copy-to-clipboard';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Trials = (props) => {
  let queryParams = useQuery();
  const id = queryParams.get("id") == "" ? null : queryParams.get("id");
  const websitelink = "http://jeweller.quickgst.in/";
  const [copylink, setCopylink] = useState("");
  const { register, handleSubmit, errors } = useForm();
  const [load, setLoad] = useState(false);
  const [loadInser, setLoadInsert] = useState(false);
  const [copyed, setCopyed] = useState(false);
  const [subcategory, setSubcategory] = useState([]);
  const [productlist, setproductlist] = useState([]);
  const [selectedSubCategory, setselectedSubCategory] = useState([]);
  const [selectedSubCategoryObject, setSelectedSubCategoryObject] = useState({
    mtran_id: "",
    category_id: "",  
    subcategory_id: "",
    text: "",
    min_amount:"",
    max_amount:""
  });
  const [param, setParam] = useState({
    tran_id: id == null ? "0" : id,  
    entry_no: "",   
    customer_session_products: [],
    remarks: "",
  });
  const [success, setSuccess] = useState(false);
  const [entryDelete, setEntryDelete] = useState(false);
  const [isvalid, setIsValid] = useState({
    mobile: true,
    email: true,
  });
  const [valid, setValid] = useState({
    mobile: true,
    email: true,
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        marginLeft: -10,
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 310,
      },
    },
  };

  const addData = (values) => {
     setLoadInsert(true);
    postMethod("api/transactions/customer/trial/insert", {product_ids:selectedSubCategory}).then((data) => {
      if (data.status === 200) {        
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
           setLoadInsert(false);
          props.history.push("/branch/trials");
        }, 2000);
      }
    });
  };
  const SearchProduct = () => {
    if (
      selectedSubCategoryObject.category_id !== "" &&
      selectedSubCategoryObject.max_amount !== "" &&
      selectedSubCategoryObject.min_amount !== ""
    ) {
      postMethod("api/transactions/customer/session/getProducts", 
      {
        subcategory_id: selectedSubCategoryObject.category_id,
        min_amount: selectedSubCategoryObject.min_amount,
        max_amount: selectedSubCategoryObject.max_amount
      }).then((data) => {
        setproductlist(data.data);
      });
      
    }
  };
  const checkMobile = (val) => {
    setParam({ ...param, mobile: val });
    if (val.length !== 10) {
      setValid({ ...valid, mobile: false });
    } else {
      setValid({ ...valid, mobile: true });
      postMethod("api/transactions/customer/generalsession/getCustomer", { mobile: val }).then((data) => {
        if (data.full_name !== 'undefined' && data.full_name) {
          setParam({
            ...param,
            customer_name: data.full_name,
            customer_id: data.customer_id,
            mobile: data.mobile,
          });
      };

      
      });
    }
  };

  const handleDelete = () => {
    var proceed = window.confirm("Do you Really Wish to Delete this Entry..?!");

    if (proceed) {
      postMethod("api/transactions/customer/generalsession/delete", { tran_id: param.tran_id }).then(
        (data) => {
          if (data.data[0].valid) {
            setEntryDelete(true);
            setTimeout(() => {
              props.history.push("/branch/customer/general-session");
            }, 2000);
          }
        }
      );
    }
  };

  useEffect(() => {
    var selectCategory=[];
    postMethod("api/transactions/customer/session/getSubcategory", {}).then((data) => {
      setSubcategory(data.data);
      selectCategory =data.data; 
    });
    setLoad(true); 
      postMethod("api/transactions/customer/trial/preview",{}).then((dataD) => {
     var data = dataD.data;
          setselectedSubCategory(data);

          let newselectedCategoryArr = [...data]
          for (let Mainindex = 0; Mainindex < selectCategory.length; Mainindex++) {
             const elementMain = selectCategory[Mainindex];
            
             for (let logoindex = 0; logoindex < newselectedCategoryArr.length; logoindex++) {
                const element = newselectedCategoryArr[logoindex];                 
                if(element.subcategory_id === elementMain.subcategory_id)
                {
                  selectCategory[Mainindex].checked = 'true';
                }  
             }               
          }  
          setSubcategory([...selectCategory]); 

        setLoad(false);
      });
   
   
  }, []);
    // check object is null or undefined
    const checkObject = (obj) => {
      return obj === undefined ? {} : Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    const AddProduct = (Obj) => {    
      var tempIndex = selectedSubCategory.findIndex(x => x.product_id === Obj.product_id);
      var tempArry = [...selectedSubCategory];
      if(tempIndex > -1){
         tempArry.splice(tempIndex, 1);
         setselectedSubCategory(tempArry);
      }
      else{
           var tempparam = { 
             mtran_id: param.tran_id,
             category_id: Obj.category_id,  
             subcategory_id: Obj.subcategory_id,
             product_id: Obj.product_id,
             product_code: Obj.product_code,
             product_name: Obj.product_name,
             gender: Obj.gender,
             price: Obj.price
         }
         tempArry.push(tempparam);
         setselectedSubCategory(tempArry);
      }
   
   };

   //Remove product
    const removeProduct = (i) => {
      let tempArry = [...selectedSubCategory];
      tempArry.splice(i, 1);
      setselectedSubCategory(tempArry);
    };

    // Add Or Remove ALL Product from arry
     const AddAllProduct = (checkAll) => {   
      var tempArry = [...selectedSubCategory];
      if(checkAll)
      {
      productlist.forEach(Obj => { 
      var tempIndex = tempArry.findIndex(x => x.product_id === Obj.product_id);       
        if(tempIndex == -1){ 
               var tempparam = { 
               mtran_id: param.tran_id,
             category_id: Obj.category_id,  
             subcategory_id: Obj.subcategory_id,
             product_id: Obj.product_id,
             product_code: Obj.product_code,
             product_name: Obj.product_name,
             gender: Obj.gender,
             price: Obj.price
          }
          tempArry.push(tempparam);  
        }  
      }); 
      }
      else{
         productlist.forEach(Obj => { 
         var tempIndex = tempArry.findIndex(x => x.product_id === Obj.product_id);       
         if(tempIndex > -1){
          tempArry.splice(tempIndex, 1);    
         }      
      }); 
      }
      
      setselectedSubCategory(tempArry);
    };
    const {
      buttonLabel,
      className
    } = props;
  
    const [modal, setModal] = useState(false);
  
    const toggle = () => setModal(!modal); 


  return (
    <>
    
<div>

      <Modal isOpen={modal} toggle={toggle} className={className}  zIndex="999999">
        <ModalHeader toggle={toggle}>Customer Link</ModalHeader>
        <ModalBody>
         <div className="d-flex w-100"> <div style={{background:"rgb(190 196 231)",padding:"16px"}} > 
          {copylink}
          </div> 
          
        <CopyToClipboard text={copylink}
          onCopy={() => {}}>
           {copyed ? <i className="fas fa-copy" style={{color:'#4dd74d',cursor:"pointer",padding:"16px"}}></i> : <i className="fas fa-copy" style={{cursor:"pointer",padding:"16px"}} onClick={()=>{
 setCopyed(true);
         }}></i>} 
        </CopyToClipboard>
       </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={()=> {props.history.push("/branch/customer/general-session")}}>Back to Browse</Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>


      {load ? <Loader /> : null}
      <Form onSubmit={handleSubmit(addData)}>
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Trial Products</h3>
            </div>
          </div>
          <div className="card-body">
            <Row>               
           
              <Col sm={12}>
            
                <Table bordered>
                  <thead>
                    <tr>                   
                      <th>Name</th>
                      <th>Min Amount</th>
                      <th>Max Amount</th>
                      <th>Action</th>
                    </tr>
                    <tr>
                      <th>
                        <select
                          className="form-control h-auto py-2 px-6"
                          name="product"
                          value={selectedSubCategoryObject.product_id}
                          onChange={(e) =>{
                         
                            setSelectedSubCategoryObject({
                              ...selectedSubCategoryObject,
                              category_id: e.target.value.split('-')[0],
                              subcategory_id: e.target.value.split('-')[1],
                              text: e.target.options[e.target.selectedIndex].text,
                            })                           
                          }
                          }
                        >
                          <option value="">-select-</option>
                         {subcategory.length < 0
                            ? null
                            : subcategory.map((obj, i) => {
                                return (
                                  <option key={i} value={obj.category_id+"-"+obj.subcategory_id}>
                                  {obj.subcategory_name + "(" + obj.category_name + ")"}
                                  </option>
                                );
                              })} 
                        </select>
                      </th>
                      <th>
                        <input
                          type="number"
                          className="form-control h-auto py-2 px-6"
                          name="min_amount"
                          onChange={(e) =>
                            setSelectedSubCategoryObject({
                              ...selectedSubCategoryObject,
                              min_amount: e.target.value,
                            })
                          }
                          value={selectedSubCategoryObject.min_amount}
                        />
                      </th>
                      <th>
                        <input
                          type="number"
                          className="form-control h-auto py-2 px-6"
                          name="max_amount"
                          onChange={(e) =>
                            setSelectedSubCategoryObject({
                              ...selectedSubCategoryObject,
                              max_amount: e.target.value,
                            })
                          }
                          value={selectedSubCategoryObject.max_amount}
                        />
                      </th>
                      <th>
                        <button type="button" className="btn-success" onClick={() => { SearchProduct()}}>
                          Search
                        </button>
                      </th>
                    </tr>
                  </thead>                 
                </Table>
              </Col>
              
{productlist.length > 0 ?

              <Col sm={12}>
                <h3>Selected Products</h3>
                <Table bordered>
                  <thead>
                    <tr>                   
                      <th>Product Code </th>
                      <th>Product Name</th>
                      <th>Gender</th>
                      <th>Price</th>
                       <th  className="d-flex w-100">
                        ALL  <input
                                type="checkbox"                  
                                className="mt-2 ml-2"
                                name="available"
                                 onChange={(e) => {                               
                                     AddAllProduct(e.target.checked)
                                 }}                             
                              />
                                                            </th>
                    </tr>
                  </thead>
                  <tbody>
                    {productlist.length > 0
                      ? productlist.map((obj, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                {i + 1}) {obj.product_code}
                              </td>
                              <td>{obj.product_name}</td>
                              <td>{obj.gender}</td>
                              <td>{obj.price}</td>
                              <td>
                              <input
                                type="checkbox"                  
                                checked={ selectedSubCategory.findIndex(e => e.product_id === obj.product_id) > -1 ? true : false}
                                className="form-control h-auto py-2 px-6"
                                name="available"
                                 onChange={(e) => {                               
                                     AddProduct(obj); 
                                 }}                             
                              />
                                {/* <button type="button" onClick={() => removeProduct(i)}>
                                  x
                                </button> */}
                              </td>
                            </tr>
                          );
                        }) : null } 
                  </tbody>
                </Table>
              </Col>
: null}

{selectedSubCategory.length > 0 ? 
              <Col sm={12}>
                  <h3>Selected Products</h3> 
                <Table bordered>
                  <thead>
                    <tr>                   
                      <th>Product Code </th>
                      <th>Product Name</th>
                      <th>Gender</th>
                      <th>Price</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedSubCategory.length > 0
                      ? selectedSubCategory.map((obj, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                {i + 1}) {obj.product_code}
                              </td>
                              <td>{obj.product_name}</td>
                              <td>{obj.gender}</td>
                              <td>{obj.price}</td>
                              <td>
                             
                                <button type="button" onClick={() => removeProduct(i)}>
                                  x
                                </button>
                              </td>
                            </tr>
                          );
                        }) : null } 
                  </tbody>
                </Table>
              </Col>
: null}

            </Row>




            {success ? <Alert color="success">Saved Successfully</Alert> : null}
            {entryDelete ? <Alert color="danger">Entry Deleted</Alert> : null}
          </div>
          <div className="card-footer text-right">           
            
               {loadInser ? 
             <button type="button" className="btn btn-primary ">
              Saving..
            </button> :  <button type="submit" className="btn btn-primary ">
              Save
            </button>}
          </div>
        </div>
      </Form>
    </>
  );
};

export default Trials;
