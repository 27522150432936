// import React, { Fragment, useEffect, useState } from "react";
// import ReactDatatable from "@ashvin27/react-datatable";
// import { postMethod } from "../../../../_services/_postMethod";
// import Loader from "../../../modules/loader";

// const Category = (props) => {
//   const [grid, setGrid] = React.useState([]);
//   const [load, setLoad] = React.useState(false);
//   const editRecord = (val) => {
//     var temp = window.btoa(val);
//     console.log(temp);
//     props.history.push("/branch/product/category/add?id=" + temp);
//   };

// //Table Objects
// const [tableState, setTableState] = useState({
//       loading: true,
//       total_pages: 0,
//       records: []
// });

// //Table Config
// const config= {
//       key_column: 'category_id',
//       page_size: 10,
//       length_menu: [10, 20, 50],
//       show_filter: true,
//       show_pagination: true,
//       pagination: 'advance',
//       no_data_text: 'No data available!',
//       language: {
//           length_menu: "Show _MENU_ result per page",
//           filter: "Search...",
//           info: "Showing _START_ to _END_ of _TOTAL_ records",
//           loading_text: "Please be patient while data loads...",
//           pagination: {
//               first: "First",
//               previous: <span>&#9668;</span>,
//               next: <span>&#9658;</span>,
//               last: "Last"
//           }
//       },
//       button: {
//           excel: false,
//           print: false
//       }
//   };
//   // Handler for Table changes      
//   const tableChangeHandler = data => {
//           let queryString = Object.keys(data).map((key) => {
//               if (key === "sort_order" && data[key]) {
//                   return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
//               } else {
//                   return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
//               }
//           }).join('&');
//           getData(queryString);
//       }

//   // Get Table data
//   const getData = (queryString = "") => {
//         let url = "api/masters/product/category/web-browse/?" + queryString
//         postMethod(url, {}).then((res) => {
//           alert(JSON.stringify(res.Data));
//         setTableState({...tableState,
//                 loading: false,
//                 total: res.recordsFiltered,
//                 records: res.Data
//             })
//     });               
//     }



//   const columns = [
//     {
//       key: "category_name",
//       text: "Category Name",
//       align: "left",
//       sortable: true,
//     },

//     {
//       key: "action",
//       text: "Action",
//       className: "action",
//       width: 100,
//       align: "left",
//       sortable: false,
//       cell: (record) => {
//         return (
//           <Fragment>
//             <button
//               className="btn btn-link btn-sm"
//               onClick={() => editRecord(record.category_id)}
//               style={{ marginRight: "5px" }}
//             >
//               <i className="fa fa-edit"></i>
//             </button>
//           </Fragment>
//         );
//       },
//     },
//   ];


//   useEffect(() => {
//     setLoad(true);
//     getData();   
//     setLoad(false);
//   }, []);


    

//   return (
//     <>
//       {load ? <Loader /> : null}
//       <div className="card card-custom gutter-b">
//         <div className="card-header">
//           <div className="card-title">
//             <h3 className="card-label">Product Category</h3>
//           </div>
//           <div className="card-toolbar">
//             <a
//               href="/branch/product/category/add"
//               className="btn btn-sm btn-outline-theme font-weight-bold py-2 px-3"
//             >
//               <i className="flaticon2-user"></i> Add Category
//             </a>
//           </div>
//         </div>
//         <div className="card-body">               
//                     <ReactDatatable
//                         config={config}
//                         records={tableState.records}
//                         columns={columns}
//                         dynamic={true}
//                         total_record={tableState.total_record}
//                         onChange={tableChangeHandler}
//                         loading={tableState.loading} />              
          
//         </div>
//       </div>
//     </>
//   );
// };

// export default Category;



import React, { Fragment, useEffect, useState } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { postMethod } from "../../../../_services/_postMethod";
import Loader from "../../../modules/loader";

const Category = (props) => {
  const [load, setLoad] = useState(false);

  const [tableState, setTableState] = useState({
    loading: true,
    total_pages: 0,
    records: [],
    total_records: 0,
    page_number: 1,
    page_size: 10
  });

  const editRecord = (val) => {
    var temp = window.btoa(val);
    props.history.push("/branch/product/category/add?id=" + temp);
  };

  const config = {
    key_column: 'category_id',
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    pagination: 'advance',
    no_data_text: 'No data available!',
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Search...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      loading_text: "Please be patient while data loads...",
      pagination: {
        first: "First",
        previous: <span>&#9668;</span>,
        next: <span>&#9658;</span>,
        last: "Last"
      }
    },
    button: {
      excel: false,
      print: false
    }
  };

  const tableChangeHandler = data => {
    let queryString = Object.keys(data).map((key) => {
      if (key === "sort_order" && data[key]) {
        return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
      } else {
        return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      }
    }).join('&');
    getData(queryString);
  }

  const getData = (queryString = "") => {
    let url = "api/masters/product/category/web-browse/?" + queryString;
    setLoad(true);
    postMethod(url, {}).then((res) => {
      setTableState({
        ...tableState,
        loading: false,
        total_records: res.recordsFiltered,
        records: res.Data,
        total_pages: Math.ceil(res.recordsFiltered / tableState.page_size),
      });
      setLoad(false);
    }).catch(() => setLoad(false));
  }

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      key: "category_name",
      text: "Category Name",
      align: "left",
      sortable: true,
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="btn btn-link btn-sm"
              onClick={() => editRecord(record.category_id)}
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-edit"></i>
            </button>
          </Fragment>
        );
      },
    },
  ];

  return (
    <>
      {load && <Loader />}
      <div className="card card-custom gutter-b">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">Product Category</h3>
          </div>
          <div className="card-toolbar">
            <a
              href="/branch/product/category/add"
              className="btn btn-sm btn-outline-theme font-weight-bold py-2 px-3"
            >
              <i className="flaticon2-user"></i> Add Category
            </a>
          </div>
        </div>
        <div className="card-body">
          <ReactDatatable
            config={config}
            records={tableState.records}
            columns={columns}
            dynamic={true}
            total_record={tableState.total_records}
            onChange={tableChangeHandler}
            loading={tableState.loading}
          />
        </div>
      </div>
    </>
  );
};

export default Category;

