import React, { useEffect, useState, Fragment, useRef } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "bootstrap/dist/css/bootstrap.min.css";
import arrowRight from "../../../../_assets/media/next.png";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Textarea,
  Divider,
  IconButton,
  FilledInput,
} from "@material-ui/core";
import {
  Row,
  Col,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ListGroupItem,
} from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import useForceUpdate from "use-force-update";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import { postMethod } from "../../../../_services/_postMethod";
import { post } from "axios";
import { API_BASE_URL } from "../../../../_services/constant";
import Moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import telephoneIcon from "../../../../_assets/media/telephone.png";
import Chip from "@material-ui/core/Chip";
import { Alert } from "bootstrap";
import Thankyou from "./thankyou";
import { Carousel, CarouselItem, Label } from "reactstrap";
import moment from "moment";
import axios from "axios";
import Dropzone from "react-dropzone";
import RedeemVP from "../dashboard/Redeem";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AutoplaySlider = withAutoplay(AwesomeSlider);

const TabToScan = () => {
  const imageRef = useRef(null);
  const [formType, setFormType] = React.useState("");
  const [openCheckMobile, setOpenCheckMobile] = React.useState(false);
  const [openForm, setOpenForm] = React.useState(false);
  const [bannerList, setBannerList] = useState([]);
  const [fetchImage, setFetchImage] = useState(false);
  const [fetchImage1, setFetchImage1] = useState(false);
  const [fetchImage2, setFetchImage2] = useState(false);
  const [fetchImage3, setFetchImage3] = useState(false);
  const [fetch, setfetch] = useState(false);
  const [fetch1, setfetch1] = useState(false);
  const [fetch2, setfetch2] = useState(false);
  const [fetch3, setfetch3] = useState(false);
  const [openVisit, setOpenVisit] = React.useState({
    visit_count: "",
    full_name: "",
    showVisit: false,
  });
  const moment = require("moment");

  const [visitDate, setVisitDate] = React.useState(
    moment().format("YYYY-MM-DD HH:mm:ssZZ")
  );
  const [openUpload, setOpenUpload] = React.useState(false);
  const [openUploadStep2, setOpenUploadStep2] = React.useState(false);
  const [isPleaseWait, setIsPleaseWait] = React.useState(false);
  const [openUploadValues, setUploadValues] = React.useState({
    tran_id: 0,
    customer_id: "",
    mobile: "",
    full_name: "",
    branch_id: "",
    category_id: "",
    sub_category1: "",
    sub_category2: "",
    sub_category3: "",
    remarks: "",
    remarks1: "",
    remarks2: "",
    remarks3: "",
    sku: "",
    sku1: "",
    sku2: "",
    sku3: "",
    image_path: "",
    image_path1: "",
    image_path2: "",
    image_path3: "",
    payment1: "",
    payment2: "",
    payment3: "",
    appointment_date: moment().format("YYYY-MM-DD HH:mm:ss"),
    interest: "Yes",

    // category_name: "",
  });
  const [customerDetails, setCustomerDetails] = React.useState({
    customer_id: "",
    full_name: "",
    mobile: "",
    total_visit: "",
    last_visit: "",
    gender: "",
    dob: "",
    doa: "",
    ref_name: "",
    category_name: "",
    area_name: "",
    ref_mobile: "",
    staff_name: "",
    profession: "",
    address: "",
    branch_name: "",
    showDetails: false,
  });
  const [slideIndex, setSlideIndex] = useState(0);
  const [slideIndexExhibition, setSlideIndexExhibition] = useState(0);
  const [slideIndexWishlist, setSlideIndexWishlist] = useState(0);
  const [customerHistory, setCustomerHistory] = React.useState({
    showHistory: false,
    showDesign: false,
    showNotify: false,
  });

  const [validator, setValidate] = React.useState(new SimpleReactValidator());
  const [mobileNo, setMobileNo] = React.useState(null);
  const [uploadedFile, setUploadedFile] = useState({});

  // Files
  const [uploadedFile1, setUploadedFile1] = useState({});
  const [uploadedFile2, setUploadedFile2] = useState({});
  const [uploadedFile3, setUploadedFile3] = useState({});

  const forceUpdate = useForceUpdate();
  const [areaList, setAreaList] = React.useState([]);
  const [staffList, setStaffList] = React.useState([]);
  const [referenceList, setReferenceList] = React.useState([]);
  const [categoryList, setCategoryList] = React.useState([]);
  const [recentCustomerList, setRecentCustomerList] = React.useState([]);
  const [notificationList, setNotificationList] = React.useState([]);
  const [lastCheckinList, setLastCheckinList] = React.useState([]);
  const [registrationForm, setRegistration] = React.useState(true);

  const [animating, setAnimating] = useState(false);

  const [registerValues, setRegisterValues] = React.useState({
    customer_id: 0,
    full_name: "",
    mobile: "",
    dob: null,
    doa: null,
    gender: "",
    address: "",
    category_id: "",
    reference_mobile: "",
    reference_name: "",
    ref_id: "",
    area_id: "",
    staff_id: "",
    profession: "",
  });
  const [areaValues, setAreaValues] = React.useState({
    area_name: "",
    area: false,
  });

  const [registrationFormIndex, setFormIndex] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const [HistoryList, setHistoryList] = React.useState([]);
  const [UploadHistoryList, setUploadHistoryList] = React.useState([]);
  const [WishlistHistoryList, setWishlistHistoryList] = React.useState([]);
  const [ExhibitionHistoryList, setExhibitionHistoryList] = React.useState([]);
  const [selectedCheckbox, setSelectedCheckbox] = React.useState("Yes");
  const [checkboxes, setCheckboxes] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  const [FormState, setFormState] = useState("step1");
  const [CardCounter, setCardCounter] = useState(0);

  const [branchEncryptID, setBranchEncryptID] = React.useState(
    localStorage.getItem("jwl_branchEncrypt_id")
  );

  const [im1, setIm1] = useState("");
  const [im2, setIm2] = useState("");
  const [im3, setIm3] = useState("");

  const ChangeStep = (step) => {
    initCounter = 0;
    // alert(JSON.stringify(checkboxes));
    const len_counter = checkboxes.filter((item) => item.checked === true);
    setIm1(len_counter[0] ? len_counter[0].image : "");
    setIm2(len_counter[1] ? len_counter[1].image : "");
    setIm3(len_counter[2] ? len_counter[2].image : "");
    if (step === "step2") {
      if (len_counter.length > 0) {
        // alert("JSON :: "+ JSON.stringify(len_counter));
        setFilteredData(len_counter);
        setCardCounter(len_counter.length);
        setFormState(step);
      } else {
        alert("please select category!");
      }
    } else {
      setFormState(step);
    }
  };

  let initCounter = 0;

  const handleCheckboxChange = (id) => {
    let data = checkboxes.map((checkbox) => {
      if (checkbox.id === id) {
        checkbox.checked = !checkbox.checked;
      }
      return checkbox;
    });
    let validate_data = checkboxes.filter((checkbox) => {
      return checkbox.checked === true;
    }).length;

    if (validate_data > 3) {
      alert("You can only select 3 Categories");
    } else {
      setCheckboxes(data);
    }
  };

  const getSelectedCheckboxes = () => {
    const selectedCheckboxes = checkboxes
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.label);

    setSelectedCheckboxes(selectedCheckboxes);
  };

  const columns = [
    {
      key: "date",
      text: "Datetime",
      align: "left",
      sortable: true,
    },
    {
      key: "type",
      text: "Type",
      align: "left",
      sortable: true,
    },
    {
      key: "details",
      text: "Details",
      sortable: true,
      align: "left",
    },
    {
      key: "action",
      text: "Status",
      sortable: true,
      align: "left",
      className: "",
    },
  ];
  const config = {
    page_size: 5,
    // show_pagination: false,
    show_filter: false,
    //show_info: false,
    show_length_menu: false,
  };
  const config_point = {
    page_size: 10,
    show_filter: false,
    show_length_menu: false,
  };

  const handleDobChange = (date) => {
    setRegisterValues({
      ...registerValues,
      dob: date,
    });
  };

  const handleDoaChange = (date) => {
    setRegisterValues({
      ...registerValues,
      doa: date,
    });
  };
  useEffect(() => {
    getBanners();
    getAreaList();
    getReferenceList();
    getStaffList();
    getCategoryList();
    getLastCheckinCustomerList();
    getRecentCustomerList();
    getNotificationList();
    getAllSubCategory();
    getData();
  }, []);

  const getBanners = () => {
    postMethod("api/masters/customer/tabtoscanBannerBrowse", {
      branch_id: "",
    }).then((data) => {
      setBannerList(data.data);
    });
  };
  const getAreaList = () => {
    postMethod("api/customervisit/AreaList").then((res) => {
      setAreaList(res.data);
    });
  };
  const getReferenceList = () => {
    postMethod("api/customervisit/ReferenceList").then((res) => {
      setReferenceList(res.data);
    });
  };
  const getStaffList = () => {
    postMethod("api/customervisit/StaffList").then((res) => {
      setStaffList(res.data);
    });
  };
  const getCategoryList = () => {
    postMethod("api/customervisit/CategoryList").then((res) => {
      setCategoryList(res.data);
    });
  };
  const getRecentCustomerList = () => {
    postMethod("api/customervisit/getRecentvisiters").then((res) => {
      setRecentCustomerList(res.data);
    });
  };
  const getNotificationList = () => {
    postMethod("api/customervisit/getNotification").then((res) => {
      setNotificationList(res.data);
    });
  };
  const getLastCheckinCustomerList = () => {
    let temparam = {
      branch_id: localStorage.getItem("jwl_branch_id"),
    };
    postMethod("api/customervisit/LastCheckinCustomerList", temparam).then(
      (res) => {
        if (res.data) setLastCheckinList(res.data);
      }
    );
  };
  // ----------------------------subCategoryList----------------
  const [SubCategoryList, setSubCategoryList] = React.useState([]);
  const getAllSubCategory = () => {
    postMethod("api/masters/category/all_sub_category").then((data) => {
      setSubCategoryList(data.data);
    });
  };
  //-------------------------End--------------------------------
  const handleSubmit = () => {
    var btn = document.getElementById("cnbtn");
    btn.disabled = true;
    btn.innerText = "Please Wait...";
    if (validator.fieldValid("Mobile1")) {
      let temparam = {
        mobile: mobileNo,
      };
      postMethod("api/customervisit/getCustomerVisit", temparam).then(
        (dataD) => {
          btn.disabled = false;
          btn.innerText = "Continue";
          var data = dataD.data[0];
          if (data == undefined) {
            setRegisterValues({
              ...registerValues,
              mobile: mobileNo,
            });
            setOpenForm(true);
            setOpenCheckMobile(false);
            setMobileNo(null);
          } else {
            if (formType == "checkin") {
              handleSubmitCustomerVisit(data.customer_id);
            } else if (formType == "upload") {
              setMobileNo(null);
              setUploadValues({
                ...openUploadValues,
                customer_id: data.customer_id,
                full_name: data.full_name,
                mobile: data.mobile,
                category_id: data.category_id,
                branch_id: data.branch_id,
                staff_id: data.staff_id,
              });
              setOpenCheckMobile(false);
              setOpenUpload(true);
            } else if (formType == "history") {
              getCustomerDetails();
            }
          }
        }
      );
    } else {
      validator.showMessages();
      forceUpdate();
      btn.disabled = false;
      btn.innerText = "Continue";
    }
  };

  const handleSubmitVisitForm = () => {
    let temparam = {
      customer_id: registerValues.customer_id,
      mobile: registerValues.mobile,
      full_name: registerValues.full_name,
      dob: registerValues.dob,
      category_id: registerValues.category_id,
      doa: registerValues.doa,
      area_id: registerValues.area_id,
      ref_id: registerValues.ref_id,
      staff_id: registerValues.staff_id,
      address: registerValues.address,
      profession: registerValues.profession,
      gender: registerValues.gender,
    };
    postMethod("api/customervisit/insertNewCustomerVisit", temparam).then(
      (data) => {
        if (data.data[0].valid) {
          setOpenForm(false);
          setOpenVisit({
            ...openVisit,
            full_name: registerValues.full_name,
            visit_count: "1",
            showVisit: true,
          });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
          setRegisterValues({
            ...registerValues,
            customer_id: 0,
            full_name: "",
            mobile: "",
            dob: null,
            doa: null,
            gender: "",
            address: "",
            category_id: "",
            ref_id: "",
            area_id: "",
            staff_id: "",
            profession: "",
          });
        } else {
        }
      }
    );
  };
  const handleSubmitCustomerVisit = (customer_id) => {
    let temparam = {
      tran_id: 0,
      customer_id: customer_id,
    };
    postMethod("api/customervisit/insertCustomerVisit", temparam).then(
      (data) => {
        setOpenCheckMobile(false);
        setMobileNo(null);
        setOpenVisit({
          ...openVisit,
          full_name: data.data[0].customer_name,
          visit_count: data.data[0].total_visit,
          showVisit: true,
        });
        setTimeout(() => {
          setOpenVisit({
            ...openVisit,
            showVisit: false,
          });
        }, 4000);
        getLastCheckinCustomerList();
      }
    );
  };

  function getFileExtensionFromBase64(base64String) {
    const matches = base64String.match(/^data:image\/([a-zA-Z]+);base64,/);
    if (matches && matches.length > 1) {
      return matches[1];
    }
    return null;
  }
  const handleSubmitUploadForm = () => {
    let file = document.getElementById("updateImage");
    let files = file.files;
    let formData = new FormData();
    // console.log("On Handle")
    // console.log(file.files[0])
    if (fetchImage) {
      let new_img = uploadedFile.split("/Images/");
      openUploadValues.image_path = new_img[1];
    } else {
      if (files.length > 0) {
        for (var j = 0; j < files.length; j++) {
          let file_name = "image-" + createGuid();
          let ext = getFileExtensionFromBase64(uploadedFile);
          // openUploadValues.image_path =  file_name + "" + files[j].name.substring(files[j].name.lastIndexOf("."));
          openUploadValues.image_path = file_name + "." + ext;

          formData.append("files", files[j], file_name);
        }
      }
    }

    postMethod("api/customervisit/insertCustomerUpload", openUploadValues).then(
      (data) => {
        if (files.length > 0) {
          uploadImageFunc(formData);
        }
        setOpenUpload(false);
        setUploadedFile({});
        setUploadValues({
          ...openUploadValues,
          customer_id: "",
          full_name: "",
          mobile: "",
          branch_id: "",
          staff_id: "",
          category_id: "",
          remarks: "",
          sku: "",
          image_path: "",
        });
      }
    );
  };
  const uploadImageFunc = (formData) => {
    const url = API_BASE_URL + `api/customervisit/UploadCustomerImage`;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "auth-token": localStorage.getItem("jwl_token"),
      },
    };

    post(url, formData, config).then(
      (response) => {
        setOpenUpload(false);
      },
      (error) => {
        // setSuccess(true);
      }
    );
  };

  const handleRegisterForm = (e) => {
    setRegisterValues({
      ...registerValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleUploadForm = (e) => {
    setUploadValues({
      ...openUploadValues,
      [e.target.name]: e.target.value,
    });
  };

  const handlePaymentForm = (e) => {
    const value = e.target.value;

    // Check if the value is a valid integer
    if (!Number.isInteger(Number(value))) {
      alert("Pay amount should be a whole number.");
      setUploadValues({
        ...openUploadValues,
        [e.target.name]: "",
      });
      return;
    }

    // Proceed with setting the state if the value is a valid number
    setUploadValues({
      ...openUploadValues,
      [e.target.name]: value,
    });
  };

  function createGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  const getCustomerDetails = () => {
    let temparam = {
      mobile: mobileNo,
    };
    postMethod("api/customervisit/getCustomerDetails", temparam).then(
      (dataD) => {
        var data = dataD.data;
        setMobileNo(null);
        setOpenCheckMobile(false);
        setCustomerDetails({
          ...customerDetails,
          showDetails: true,
          full_name: data.full_name,
          mobile: data.mobile,
          total_visit: data.visit_count,
          last_visit: data.last_visit,
          gender: data.gender,
          dob: data.dob == null ? "N/A" : data.dob,
          doa: data.doa == null ? "N/A" : data.doa,
          ref_name: data.ref_name,
          ref_mobile: data.ref_mobile,
          category_name: data.category_name,
          area_name: data.area_name,
          staff_name: data.staff_name,
          profession: data.profession,
          address: data.address,
          branch_name: data.branch_name,
        });
        getCustomerHistory(data.customer_id);
        getCustomerUploadHistory(data.customer_id);
        getCustomerExhibitionHistory(data.customer_id);
        getCustomerWishlistHistory(data.customer_id);
      }
    );
  };

  const getCustomerHistory = (customer_id) => {
    let temparam = {
      customer_id: customer_id,
    };
    postMethod("api/customervisit/getCustomerHistory", temparam).then(
      (data) => {
        setHistoryList(data.data);
      }
    );
  };
  const getCustomerUploadHistory = (customer_id) => {
    let temparam = {
      customer_id: customer_id,
    };
    postMethod("api/customervisit/getCustomerUploadHistory", temparam).then(
      (data) => {
        setUploadHistoryList(data.data);
      }
    );
  };
  const getCustomerExhibitionHistory = (customer_id) => {
    let temparam = {
      customer_id: customer_id,
    };
    postMethod("api/customervisit/getCustomerExhibitionHistory", temparam).then(
      (data) => {
        setExhibitionHistoryList(data.data);
      }
    );
  };
  const getCustomerWishlistHistory = (customer_id) => {
    let temparam = {
      customer_id: customer_id,
    };
    postMethod("api/customervisit/getCustomerWishlistHistory", temparam).then(
      (data) => {
        setWishlistHistoryList(data.data);
      }
    );
  };

  const CheckVisitFormValidation = () => {
    if (validator.fieldValid("Name")) {
      setRegistration(false);
    } else {
      validator.showMessages();
      forceUpdate();
    }
  };
  const handleRefMobile = (e) => {
    setRegisterValues({
      ...registerValues,
      reference_mobile: e.target.value,
    });
  };

  const handleRefOnBlur = (e) => {
    if (e.target.value.length === 10) {
      let temparam = {
        mobile: e.target.value,
      };
      postMethod("api/customervisit/getCustomerVisit", temparam).then(
        (dataD) => {
          var data = dataD.data[0];
          if (data == undefined) {
            alert("Reference Not Found");
            setRegisterValues({
              ...registerValues,
              reference_name: "",
              ref_id: "",
            });
          } else {
            setRegisterValues({
              ...registerValues,
              reference_name: data.full_name,
              ref_id: data.customer_id,
            });
          }
        }
      );
    }
  };

  const handleSubmitAreaForm = (customer_id) => {
    let temparam = {
      area_id: 0,
      area_name: areaValues.area_name,
    };
    postMethod("api/customervisit/insertArea", temparam).then((data) => {
      if (data.data[0].valid) {
        setAreaValues({
          ...areaList,
          area_name: "",
          area: false,
        });
        getAreaList();
      }
    });
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async function maintobase64(file) {
    return await toBase64(file);
  }

  const clearUploadModal = () => {
    setMobileNo("");
    setOpenUpload(false);
    setUploadedFile({});
    setUploadValues({
      ...openUploadValues,
      customer_id: "",
      full_name: "",
      mobile: "",
      branch_id: "",
      staff_id: "",
      remarks: "",
      sku: "",
      image_path: "",
    });
  };

  const slides = bannerList.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.image_path}
      >
        <img src={API_BASE_URL + "tabBanner/" + item.image_path} alt={""} />
      </CarouselItem>
    );
  });

  const FetchImage = (index = 0) => {
    if (index === 0) {
      if (openUploadValues.sku1 === "") {
        alert("Please Enter SKU");
      } else {
        postMethod("api/customervisit/SkuImage", {
          sku: openUploadValues.sku1,
          branch_id: localStorage.getItem("jwl_branch_id"),
        }).then((data) => {
          if (data.valid) {
            setUploadedFile1(data.data.image_path);
            setFetchImage1(true);
            setfetch1(true);
          } else {
            alert("No Image Found");
          }
        });
        // alert(openUploadValues.sku+" path"+openUploadValues.image_path);
      }
    } else if (index === 1) {
      if (openUploadValues.sku2 === "") {
        alert("Please Enter SKU");
      } else {
        postMethod("api/customervisit/SkuImage", {
          sku: openUploadValues.sku2,
          branch_id: localStorage.getItem("jwl_branch_id"),
        }).then((data) => {
          if (data.valid) {
            setUploadedFile2(data.data.image_path);
            setFetchImage2(true);
            setfetch2(true);
          } else {
            alert("No Image Found");
          }
        });
        // alert(openUploadValues.sku+" path"+openUploadValues.image_path);
      }
    } else if (index === 2) {
      if (openUploadValues.sku3 === "") {
        alert("Please Enter SKU");
      } else {
        postMethod("api/customervisit/SkuImage", {
          sku: openUploadValues.sku3,
          branch_id: localStorage.getItem("jwl_branch_id"),
        }).then((data) => {
          if (data.valid) {
            setUploadedFile3(data.data.image_path);
            setFetchImage3(true);
            setfetch3(true);
          } else {
            alert("No Image Found");
          }
        });
        // alert(openUploadValues.sku+" path"+openUploadValues.image_path);
      }
    }
  };

  // Get Table data
  const getData = (queryString = "") => {
    let url = "api/masters/product/category/web-browse/?" + queryString;
    postMethod(url, {}).then((res) => {
      // alert(JSON.stringify(res.Data));
      var check_data = [];
      let filtered_checkboxes = res.Data.map((item, index) => {
        check_data.push({
          id: item.category_id,
          label: item.category_name,
          image: item.image_path,
          checked: false,
        });
        return {
          id: item.category_id,
          label: item.category_name,
          image: item.image_path,
          checked: false,
        };
      });

      // Create a Set to store unique id values
      const uniqueIds = new Set();

      // Filter the data array based on unique id values
      const filteredData = check_data.filter((item) => {
        // If the id is not in the Set, add it and return true to keep the item
        if (!uniqueIds.has(item.id)) {
          uniqueIds.add(item.id);
          return true;
        }
        // If the id is already in the Set, return false to filter out the item
        return false;
      });

      // alert(JSON.stringify(filteredData))
      setCheckboxes(filteredData);
      let url2 = "api/masters/product/sub_category/web-browse/?" + queryString;
    postMethod(url2, {}).then((res) => {
      setSubCategoryList(res.Data);
    })
    });
  };

  const onValueChange = (val) => {
    setSelectedCheckbox(val);
    setUploadValues({
      ...openUploadValues,
      interest: val,
    });
  };

  const handleSubmitUploadFormMultiple = () => {
    filteredData.map((item, index) => {
      let file = document.getElementById(`updateImage${index}`);
      let files = file?.files || [];
      let formData = new FormData();

      if (fetchImage) {
        if (index === 0) {
          let new_img = uploadedFile1.split("/Images/");
          openUploadValues.image_path1 = new_img[1];
        } else if (index === 1) {
          let new_img = uploadedFile2.split("/Images/");
          openUploadValues.image_path2 = new_img[1];
        } else if (index === 2) {
          let new_img = uploadedFile3.split("/Images/");
          openUploadValues.image_path3 = new_img[1];
        }
      } else {
        if (files != undefined && files.length > 0) {
          for (var j = 0; j < files.length; j++) {
            let file_name = "image-" + createGuid();

            // openUploadValues.image_path =  file_name + "" + files[j].name.substring(files[j].name.lastIndexOf("."));
            if (index === 0) {
              let ext = getFileExtensionFromBase64(uploadedFile1);
              openUploadValues.image_path1 = file_name + "." + ext;
            } else if (index === 1) {
              let ext = getFileExtensionFromBase64(uploadedFile2);
              openUploadValues.image_path2 = file_name + "." + ext;
            } else if (index === 2) {
              let ext = getFileExtensionFromBase64(uploadedFile3);
              openUploadValues.image_path3 = file_name + "." + ext;
            }
            formData.append("files", files[j], file_name);
          }
        } else {
          // formData.append("files", [], []);
        }
      }
      let payload_req = {
        tran_id: openUploadValues.tran_id,
        customer_id: openUploadValues.customer_id,
        mobile: openUploadValues.mobile,
        full_name: openUploadValues.full_name,
        remarks:
          index === 0
            ? openUploadValues.remarks1
            : index === 1
            ? openUploadValues.remarks2
            : openUploadValues.remarks3,
        sku:
          index === 0
            ? openUploadValues.sku1
            : index === 1
            ? openUploadValues.sku2
            : openUploadValues.sku3,
        image_path:
          index === 0
            ? openUploadValues.image_path1
              ? openUploadValues.image_path1
              : im1
            : index === 1
            ? openUploadValues.image_path2
              ? openUploadValues.image_path2
              : im2
            : openUploadValues.image_path3
            ? openUploadValues.image_path3
            : im3,
        appointment_date:
          selectedCheckbox === "Follow Up"
            ? openUploadValues.appointment_date
            : "",
        payment:
          index === 0
            ? openUploadValues.payment1
            : index === 1
            ? openUploadValues.payment2
            : openUploadValues.payment3,

        color:
          index === 0
            ? openUploadValues.color1
            : index === 1
            ? openUploadValues.color2
            : openUploadValues.color3,

        payment_mode:
          index === 0
            ? openUploadValues.payment_mode1
            : index === 1
            ? openUploadValues.payment_mode2
            : openUploadValues.payment_mode3,
        sub_category:
          index === 0
            ? openUploadValues.sub_category1
            : index === 1
            ? openUploadValues.sub_category2
            : openUploadValues.sub_category3,
        interest: selectedCheckbox,
        staff_id: openUploadValues.staff_id,
        category_id: item.id,
      };

      if (payload_req.remarks != "" || payload_req.image_path != "") {
        postMethod("api/customervisit/insertCustomerUpload", payload_req).then(
          (data) => {
            if (files != undefined && files.length > 0) {
              uploadImageFunc(formData);
            }

            let new_img_length1 = uploadedFiles1.length;
            let new_img_length2 = uploadedFiles2.length;
            let new_img_length3 = uploadedFiles3.length;
            if (
              new_img_length1 === 0 &&
              new_img_length2 === 0 &&
              new_img_length3 === 0
            ) {
              setOpenUpload(false);
              setUploadedFile({});
              setUploadedFile1({});
              setUploadedFile2({});
              setUploadedFile3({});
              setUploadValues({
                ...openUploadValues,
                customer_id: "",
                full_name: "",
                mobile: "",
                branch_id: "",
                staff_id: "",
                category_id: "",
                category_id1: "",
                category_id2: "",
                category_id3: "",
                sub_category: "",
                sub_category1: "",
                sub_category2: "",
                sub_category3: "",
                remarks: "",
                remarks1: "",
                remarks2: "",
                remarks3: "",
                sku: "",
                sku1: "",
                sku2: "",
                sku3: "",
                image_path: "",
                image_path1: "",
                image_path2: "",
                image_path3: "",
                payment1: "",
                payment2: "",
                payment3: "",
                color1: "",
                color2: "",
                color3: "",
                payment_mode1: "",
                payment_mode2: "",
                payment_mode3: "",
              });
              setIsPleaseWait(false);
            }
          }
        );
      }

      if (index === 0) {
        if (uploadedFiles1.length > 0) {
          uploadedFiles1.map((items, index) => {
            let custom_payload_req = {
              tran_id: openUploadValues.tran_id,
              customer_id: openUploadValues.customer_id,
              mobile: openUploadValues.mobile,
              full_name: openUploadValues.full_name,
              remarks: openUploadValues.remarks1,
              sku: openUploadValues.sku1,
              image_path: items,
              appointment_date:
                selectedCheckbox === "Follow Up"
                  ? openUploadValues.appointment_date
                  : "",
              interest: selectedCheckbox,
              staff_id: openUploadValues.staff_id,
              category_id: item.id,
            };
            postMethod(
              "api/customervisit/insertCustomerUpload",
              custom_payload_req
            ).then((data) => {
              console.log("Done");
            });
          });
        }
      } else if (index === 1) {
        if (uploadedFiles2.length > 0) {
          uploadedFiles2.map((items, index) => {
            let custom_payload_req = {
              tran_id: openUploadValues.tran_id,
              customer_id: openUploadValues.customer_id,
              mobile: openUploadValues.mobile,
              full_name: openUploadValues.full_name,
              remarks: openUploadValues.remarks2,
              sku: openUploadValues.sku2,
              image_path: items,
              appointment_date:
                selectedCheckbox === "Follow Up"
                  ? openUploadValues.appointment_date
                  : "",
              interest: selectedCheckbox,
              staff_id: openUploadValues.staff_id,
              category_id: item.id,
            };
            postMethod(
              "api/customervisit/insertCustomerUpload",
              custom_payload_req
            ).then((data) => {
              console.log("Done");
            });
          });
        }
      } else if (index === 2) {
        if (uploadedFiles3.length > 0) {
          uploadedFiles3.map((items, index) => {
            let custom_payload_req = {
              tran_id: openUploadValues.tran_id,
              customer_id: openUploadValues.customer_id,
              mobile: openUploadValues.mobile,
              full_name: openUploadValues.full_name,
              remarks: openUploadValues.remarks3,
              sku: openUploadValues.sku3,
              image_path: items,
              appointment_date:
                selectedCheckbox === "Follow Up"
                  ? openUploadValues.appointment_date
                  : "",
              interest: selectedCheckbox,
              staff_id: openUploadValues.staff_id,
              category_id: item.id,
            };
            postMethod(
              "api/customervisit/insertCustomerUpload",
              custom_payload_req
            ).then((data) => {
              console.log("Done");
              if (index === filteredData.length - 1) {
                setOpenUpload(false);
                setUploadedFile({});
                setUploadedFile1({});
                setUploadedFile2({});
                setUploadedFile3({});
                setUploadValues({
                  ...openUploadValues,
                  customer_id: "",
                  full_name: "",
                  mobile: "",
                  branch_id: "",
                  staff_id: "",
                  category_id: "",
                  category_id1: "",
                  category_id2: "",
                  category_id3: "",
                  sub_category: "",
                  sub_category1: "",
                  sub_category2: "",
                  sub_category3: "",
                  remarks: "",
                  remarks1: "",
                  remarks2: "",
                  remarks3: "",
                  sku: "",
                  sku1: "",
                  sku2: "",
                  sku3: "",
                  image_path: "",
                  image_path1: "",
                  image_path2: "",
                  image_path3: "",
                  payment1: "",
                  payment2: "",
                  payment3: "",
                  color1: "",
                  color2: "",
                  color3: "",
                  payment_mode1: "",
                  payment_mode2: "",
                  payment_mode3: "",
                });
                setSelectedFiles1([]);
                setSelectedFiles2([]);
                setSelectedFiles3([]);
                setUploadedFiles1([]);
                setUploadedFiles2([]);
                setUploadedFiles3([]);
                setIsPleaseWait(false);
              }
            });
          });
        }
      }
      if (index === filteredData.length - 1) {
        setOpenUpload(false);
        setUploadedFile({});
        setUploadedFile1({});
        setUploadedFile2({});
        setUploadedFile3({});
        setUploadValues({
          ...openUploadValues,
          customer_id: "",
          full_name: "",
          mobile: "",
          branch_id: "",
          staff_id: "",
          category_id: "",
          category_id1: "",
          category_id2: "",
          category_id3: "",
          remarks: "",
          remarks1: "",
          remarks2: "",
          remarks3: "",
          sku: "",
          sku1: "",
          sku2: "",
          sku3: "",
          image_path: "",
          image_path1: "",
          image_path2: "",
          image_path3: "",
          payment1: "",
          payment2: "",
          payment3: "",
          color1: "",
          color2: "",
          color3: "",
          payment_mode1: "",
          payment_mode2: "",
          payment_mode3: "",
        });
        setSelectedFiles1([]);
        setSelectedFiles2([]);
        setSelectedFiles3([]);
        setUploadedFiles1([]);
        setUploadedFiles2([]);
        setUploadedFiles3([]);
        setIsPleaseWait(false);
      }
    });
    setIsPleaseWait(false);
  };

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [selectedFiles1, setSelectedFiles1] = useState([]);
  const [uploadedFiles1, setUploadedFiles1] = useState([]);
  const [selectedFiles2, setSelectedFiles2] = useState([]);
  const [uploadedFiles2, setUploadedFiles2] = useState([]);
  const [selectedFiles3, setSelectedFiles3] = useState([]);
  const [uploadedFiles3, setUploadedFiles3] = useState([]);

  const onDrop = (acceptedFiles) => {
    setSelectedFiles(acceptedFiles);
  };
  const onDrop1 = (acceptedFiles) => {
    let full_arr = acceptedFiles.concat(selectedFiles1);
    setSelectedFiles1(full_arr);
  };
  const onDrop2 = (acceptedFiles) => {
    setSelectedFiles2([...acceptedFiles]);
  };
  const onDrop3 = (acceptedFiles) => {
    setSelectedFiles3([...acceptedFiles]);
  };

  const uploadFiles = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await axios.post(API_BASE_URL + "api/upload", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          "auth-token": localStorage.getItem("jwl_token"),
        },
      });

      setUploadedFiles(response.data.fileNames);
      setSelectedFiles([]); // Clear the selected files
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  const uploadFiles1 = async () => {
    const formData = new FormData();
    selectedFiles1.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await axios.post(API_BASE_URL + "api/upload", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          "auth-token": localStorage.getItem("jwl_token"),
        },
      });

      setUploadedFiles1(response.data.fileNames);
      setSelectedFiles1([]); // Clear the selected files
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  const uploadFiles2 = async () => {
    const formData = new FormData();
    selectedFiles2.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await axios.post(API_BASE_URL + "api/upload", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          "auth-token": localStorage.getItem("jwl_token"),
        },
      });

      setUploadedFiles2(response.data.fileNames);
      setSelectedFiles2([]); // Clear the selected files
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  const uploadFiles3 = async () => {
    const formData = new FormData();
    selectedFiles3.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await axios.post(API_BASE_URL + "upload", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          "auth-token": localStorage.getItem("jwl_token"),
        },
      });

      setUploadedFiles3(response.data.fileNames);
      setSelectedFiles3([]); // Clear the selected files
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  const removeFile = (file) => {
    const updatedFiles = selectedFiles.filter((f) => f !== file);
    setSelectedFiles(updatedFiles);
  };

  const removeFile1 = (file) => {
    const updatedFiles = selectedFiles1.filter((f) => f !== file);
    setSelectedFiles1(updatedFiles);
  };

  const removeFile2 = (file) => {
    const updatedFiles = selectedFiles2.filter((f) => f !== file);
    setSelectedFiles2(updatedFiles);
  };

  const removeFile3 = (file) => {
    const updatedFiles = selectedFiles3.filter((f) => f !== file);
    setSelectedFiles3(updatedFiles);
  };

  const removeFileUploaded1 = (file) => {
    const updatedFiles = uploadedFiles1.filter((f) => f !== file);
    setUploadedFiles1(updatedFiles);
  };

  const removeFileUploaded2 = (file) => {
    const updatedFiles = uploadedFiles2.filter((f) => f !== file);
    setUploadedFiles2(updatedFiles);
  };

  const removeFileUploaded3 = (file) => {
    const updatedFiles = uploadedFiles3.filter((f) => f !== file);
    setUploadedFiles3(updatedFiles);
  };

  return (
    <React.Fragment>
      {/* <div className="main position-relative">*/}
      {openVisit.showVisit ? <Thankyou val={openVisit} /> : null}
      <div className="main">
        {bannerList.length > 0 ? (
          <Carousel
            ride="carousel"
            interval="5000"
            slide={false}
            className="carousel-fade"
          >
            {slides}
          </Carousel>
        ) : null}
        {/* <AutoplaySlider
                    play={true}
                    cancelOnInteraction={false} // should stop playing on user interaction
                    interval={3000}
                    bullets={false}
                    organicArrows={false}
                    fillParent={true}>
                 {bannerList.length > 0 ?  
                    bannerList.map((obj, i) => {
                    return (
                        <div key={i+1} data-src={} />       
                        
                    );
                    }) : null }
                  
                     </AutoplaySlider> */}
        <div className="customerLogo">
          <img
            src={`${API_BASE_URL}BranchLogo/${localStorage.getItem(
              "jwl_company_logo"
            )}`}
          />
        </div>
        <div className="notificationBtn h-100">
          <IconButton
            color="primary"
            aria-label="upload picture"
            onClick={() =>
              setCustomerHistory({
                ...customerHistory,
                showNotify: !customerHistory.showNotify,
              })
            }
            component="span"
          >
            <i className="text-dark fas fa-bell"></i>
          </IconButton>
          <div
            className={
              "notification_modal shadow " +
              (customerHistory.showNotify ? "active" : "")
            }
          >
            <p>Notifications</p>
            {notificationList.map((obj, i) => {
              return (
                <div className="notification_box" key={i}>
                  <div
                    className="notification_icon"
                    style={{ backgroundColor: obj.color }}
                  >
                    {obj.notification_type.trim().charAt(0)}
                  </div>
                  <div className="notification">
                    <p>
                      <b>{obj.full_name} </b> &nbsp; &nbsp; &nbsp;
                      {obj.mobile}
                    </p>
                    <p>
                      {obj.hasOwnProperty("f_stars") &&
                        obj.f_stars > 0 &&
                        Array.apply(null, Array(obj.f_count)).map((x, i) => {
                          return (
                            <i key={i} class="fas fa-star text-warning"></i>
                          );
                        })}
                    </p>
                    <span>
                      {obj.msg === ""
                        ? obj.notification_type === "Feedback"
                          ? obj.f_service.split("/").map((item, i) => {
                              if (i + 1 == obj.f_service.split("/").length) {
                                return (
                                  <>
                                    <label>{item}</label>
                                    <br />
                                  </>
                                );
                              } else {
                                return (
                                  <>
                                    <strong>{item}</strong>
                                    <br />
                                  </>
                                );
                              }
                            })
                          : obj.notification_type
                        : obj.msg}
                    </span>
                  </div>
                  <div className="notification_tym ml-auto">
                    <p style={{ fontStyle: "italic" }}>{obj.time}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="redeemPanel">
          <div>
            <div
              className="redeemOptions pointerShow shadow"
              style={{
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
              }}
              onClick={() => {
                window.open(localStorage.getItem("jwl_estore_link"), "_blank");
              }}
            >
              <h3>E-Store</h3>
            </div>
            <div
              className="redeemOptions pointerShow shadow"
              style={{
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
              }}
              onClick={() => {
                setOpenCheckMobile(true);
                setFormType("upload");
                setMobileNo("");
              }}
            >
              <h3>Upload </h3>
            </div>
          </div>
          <div>
            <RedeemVP />
            <div
              className="redeemOptions pointerShow shadow"
              style={{ borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }}
              onClick={() => {
                setOpenCheckMobile(true);
                setFormType("history");
                setMobileNo("");
              }}
            >
              <h3>Customer Details</h3>
            </div>
          </div>
        </div>

        <div className="checkinOptions">
          <div
            className="checkIN shadow pointerShow "
            onClick={() => {
              setMobileNo("");
              setOpenCheckMobile(true);
              setFormType("checkin");
            }}
          >
            <div className="w-100 text-center">
              <h3>Join {`${localStorage.getItem("jwl_company_name")}`} Now</h3>
              <p className="mb-0">Accounts are free</p>
            </div>
            <img src={arrowRight} />
          </div>
          <div
            className="checkIN white shadow pointerShow"
            onClick={() => {
              setMobileNo("");
              setOpenCheckMobile(true);
              setFormType("checkin");
            }}
          >
            <div className="w-100 text-center">
              <h3>Check In </h3>
              <p className="mb-0">for rewards</p>
            </div>
            <img src={arrowRight} />
          </div>
        </div>
      </div>

      {/* Check Mobile Number Dialog and User Registration Form */}

      <Dialog
        open={openCheckMobile}
        className="custom_modal"
        disableBackdropClick
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="md"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="dialog-border">
          <DialogContent>
            <div className="checkMobileField">
              <h3 className="text-dark mb-3">{"Enter Mobile No."}</h3>
              <div className="d-flex align-items-center">
                <img src={telephoneIcon} />
                <TextField
                  autoComplete="new-off"
                  type="tel"
                  fullWidth
                  className="theme-input"
                  value={mobileNo}
                  placeholder="eg:1234567890"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+91 - </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setMobileNo(
                      (e.target.value.length <= 10 &&
                        !isNaN(Number(e.target.value)) &&
                        e.target.value) ||
                        (e.target.value == "" ? e.target.value : mobileNo)
                    );
                  }}
                  autoFocus
                  name="Mobile1"
                  error={validator.message(
                    "Mobile1",
                    mobileNo,
                    "required|min:10|max:10"
                  )}
                  helperText={validator.message(
                    "Mobile1",
                    mobileNo,
                    "required|min:10|max:10"
                  )}
                  variant="standard"
                />
              </div>
              <div className="mt-3 ml-5">
                {formType != "checkin" && lastCheckinList.length > 0
                  ? lastCheckinList.map((prop, i) => {
                      return (
                        <Chip
                          key={i}
                          label={prop.mobile}
                          className="mr-2 mobileChip"
                          onClick={(e) => {
                            setMobileNo(e.target.innerText);
                          }}
                        />
                      );
                    })
                  : null}
              </div>
            </div>
            {formType != "checkin" && recentCustomerList.length > 0 ? (
              <div className="row pl-5">
                {recentCustomerList.map((obj, i) => {
                  return (
                    <div
                      className="col-md-3 pointerShow"
                      onClick={() => {
                        setMobileNo(obj.mobile);
                      }}
                    >
                      {obj.mobile}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button
              className="btn btn-danger"
              onClick={() => {
                setOpenCheckMobile(false);
                setMobileNo("");
              }}
              color="secondary"
            >
              Close
            </Button>
            <Button
              id="cnbtn"
              className="btn btn-success"
              onClick={() => {
                handleSubmit();
                setIsPleaseWait(false);
              }}
              color="primary"
            >
              Continue
            </Button>
          </DialogActions>
        </div>
      </Dialog>

      {/* Registration Form */}
      <Dialog
        open={openForm}
        className="custom_modal"
        disableBackdropClick
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="md"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="dialog-border">
          <DialogContent>
            {registrationForm && registrationFormIndex === 0 ? (
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-5">
                    <TextField
                      autoComplete="new-off"
                      fullWidth
                      className="theme-input mb-3"
                      value={registerValues.full_name || ""}
                      onChange={handleRegisterForm}
                      autoFocus
                      name="full_name"
                      id="filled-basic"
                      label="Name"
                      error={validator.message(
                        "Name",
                        registerValues.full_name,
                        "required"
                      )}
                      helperText={validator.message(
                        "Name",
                        registerValues.full_name,
                        "required"
                      )}
                      variant="filled"
                    />
                  </div>
                  <div className="col-md-5">
                    <TextField
                      autoComplete="new-off"
                      fullWidth
                      className="theme-input mb-3"
                      value={registerValues.mobile || ""}
                      onChange={handleRegisterForm}
                      autoFocus
                      id="filled-basic"
                      label="Mobile No."
                      variant="filled"
                      disabled
                    />
                  </div>
                  <div className="col-md-2">
                    <FormControl
                      fullWidth
                      variant="filled"
                      className="theme-input mb-3"
                    >
                      <InputLabel id="demo-simple-select-filled-label">
                        Gender
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={registerValues.gender || ""}
                        onChange={handleRegisterForm}
                        name="gender"
                      >
                        <MenuItem value={"Male"}>Male</MenuItem>
                        <MenuItem value={"Female"}>Female</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <KeyboardDatePicker
                      variant="inline"
                      inputVariant="filled"
                      fullWidth
                      label="Date of Birth"
                      className="mb-3 theme-input"
                      value={registerValues.dob}
                      autoOk
                      format="DD-MM-YYYY"
                      onChange={handleDobChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <KeyboardDatePicker
                      variant="inline"
                      inputVariant="filled"
                      fullWidth
                      className="mb-3  theme-input"
                      label="Date of Anniversary"
                      value={registerValues.doa}
                      autoOk
                      format="DD-MM-YYYY"
                      onChange={handleDoaChange}
                    />
                  </div>

                  <div className="col-md-12">
                    <TextField
                      autoComplete="new-off"
                      fullWidth
                      className="theme-input mb-3"
                      multiline
                      value={registerValues.address || ""}
                      onChange={handleRegisterForm}
                      autoFocus
                      id="filled-basic"
                      label="Address"
                      name="address"
                      variant="filled"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="row">
                  <div className="col-md-6">
                    <TextField
                      autoComplete="new-off"
                      fullWidth
                      className="theme-input mb-3"
                      value={registerValues.full_name || ""}
                      name="full_name"
                      id="filled-basic"
                      label="Name"
                      variant="filled"
                      disabled
                    />
                  </div>
                  <div className="col-md-6">
                    <TextField
                      autoComplete="new-off"
                      fullWidth
                      className="theme-input mb-3"
                      value={registerValues.mobile || ""}
                      onChange={handleRegisterForm}
                      autoFocus
                      id="filled-basic"
                      label="Mobile No."
                      variant="filled"
                      disabled
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <TextField
                      autoComplete="new-off"
                      fullWidth
                      type="number"
                      className="theme-input mb-3"
                      value={registerValues.reference_mobile || ""}
                      onChange={handleRefMobile}
                      onBlur={handleRefOnBlur}
                      name="reference_mobile"
                      id="filled-basic"
                      label="Reference Mobile No."
                      variant="filled"
                    />
                  </div>
                  <div className="col-md-3">
                    <TextField
                      autoComplete="new-off"
                      fullWidth
                      className="theme-input mb-3"
                      value={registerValues.reference_name || ""}
                      id="filled-basic"
                      label="Reference Name"
                      variant="filled"
                      disabled
                    />
                  </div>
                  <div className="col-md-3">
                    <FormControl
                      fullWidth
                      variant="filled"
                      className="theme-input mb-3"
                    >
                      <InputLabel id="demo-simple-select-filled-label">
                        Category
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={registerValues.category_id || ""}
                        name="category_id"
                        onChange={handleRegisterForm}
                      >
                        {categoryList.length > 0
                          ? categoryList.map((prop, i) => {
                              return (
                                <MenuItem key={i} value={prop.category_id}>
                                  {" "}
                                  {prop.category_name}
                                </MenuItem>
                              );
                            })
                          : null}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-3">
                    <FormControl
                      fullWidth
                      variant="filled"
                      className="theme-input mb-3"
                    >
                      <InputLabel id="demo-simple-select-filled-label">
                        Staff
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={registerValues.staff_id || ""}
                        name="staff_id"
                        onChange={handleRegisterForm}
                      >
                        {staffList.length > 0
                          ? staffList.map((prop, i) => {
                              return (
                                <MenuItem key={i} value={prop.staff_id}>
                                  {" "}
                                  {prop.name}
                                </MenuItem>
                              );
                            })
                          : null}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="row  no-gutters">
                  <div className="col-md-3">
                    <FormControl
                      fullWidth
                      variant="filled"
                      className="theme-input mb-3"
                    >
                      <InputLabel id="demo-simple-select-filled-label">
                        Area
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={registerValues.area_id || ""}
                        name="area_id"
                        onChange={handleRegisterForm}
                      >
                        {areaList.length > 0
                          ? areaList.map((prop, i) => {
                              return (
                                <MenuItem key={i} value={prop.area_id}>
                                  {" "}
                                  {prop.area_name}
                                </MenuItem>
                              );
                            })
                          : null}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-1 d-flex align-items-center">
                    <Button
                      className="btn btn-success areaBtn"
                      onClick={() => {
                        setAreaValues({
                          ...areaValues,
                          area: true,
                          area_name: "",
                        });
                      }}
                      color="secondary"
                    >
                      +
                    </Button>
                  </div>

                  <div className="col-md-8">
                    <TextField
                      autoComplete="new-off"
                      fullWidth
                      className="theme-input mb-3"
                      value={registerValues.profession || ""}
                      onChange={handleRegisterForm}
                      autoFocus
                      name="profession"
                      id="filled-basic"
                      label="Profession"
                      variant="filled"
                    />
                  </div>
                </div>
              </>
            )}
          </DialogContent>
          <DialogActions>
            {registrationForm ? (
              ""
            ) : (
              <Button
                className="btn btn-success"
                onClick={() => setRegistration(true)}
                color="secondary"
              >
                Back
              </Button>
            )}
            <Button
              className="btn btn-danger"
              onClick={() => {
                setOpenForm(false);
                setRegisterValues({
                  ...registerValues,
                  customer_id: 0,
                  full_name: "",
                  mobile: "",
                  dob: null,
                  doa: null,
                  gender: "",
                  address: "",
                  category_id: "",
                  ref_id: "",
                  area_id: "",
                  staff_id: "",
                  profession: "",
                });
                setRegistration(true);
              }}
              color="secondary"
            >
              Close
            </Button>

            {registrationForm ? (
              <Button
                className="btn btn-success"
                onClick={() => {
                  CheckVisitFormValidation();
                }}
                color="secondary"
              >
                Continue
              </Button>
            ) : (
              <Button
                className="btn btn-success"
                onClick={handleSubmitVisitForm}
                color="secondary"
              >
                Continue
              </Button>
            )}
          </DialogActions>
        </div>
      </Dialog>

      {/* Msg Total Count */}
      <Dialog
        open={false}
        className="custom_modal"
        disableBackdropClick
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="md"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="dialog-border">
          <DialogContent>
            <h3 className="text-dark mb-3">{"Thank You"}</h3>
            <h3 className="text-dark mb-3">{openVisit.full_name}</h3>
            <h3 className="text-dark mb-3">{openVisit.visit_count}</h3>
          </DialogContent>
        </div>
      </Dialog>

      {/*Customer Uploads */}
      <Dialog
        open={openUpload}
        className="custom_modal"
        disableBackdropClick
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="md"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="dialog-border">
          <DialogContent>
            <div className="container-fluid">
              {FormState === "step1" ? (
                <>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="row">
                        <div className="col-md-12">
                          <TextField
                            autoComplete="new-off"
                            fullWidth
                            className="theme-input mb-3 "
                            value={openUploadValues.full_name || ""}
                            onChange={handleUploadForm}
                            autoFocus
                            name="full_name"
                            id="filled-basic"
                            label="Name"
                            variant="filled"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="row">
                        <div className="col-md-12">
                          <TextField
                            autoComplete="new-off"
                            fullWidth
                            className="theme-input mb-3"
                            value={openUploadValues.mobile || ""}
                            onChange={handleUploadForm}
                            autoFocus
                            id="filled-basic"
                            label="Mobile"
                            variant="filled"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="row">
                        <div className="col-md-12">
                          <FormControl
                            fullWidth
                            variant="filled"
                            className="theme-input mb-3"
                          >
                            <InputLabel id="demo-simple-select-filled-label">
                              Staff
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select-filled"
                              value={openUploadValues.staff_id || ""}
                              name="staff_id"
                              onChange={handleUploadForm}
                            >
                              {staffList.length > 0
                                ? staffList.map((prop, i) => {
                                    return (
                                      <MenuItem key={i} value={prop.staff_id}>
                                        {" "}
                                        {prop.name}
                                      </MenuItem>
                                    );
                                  })
                                : null}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <h5>Category</h5>
                    </div>

                    <div className="col-md-12 pb-4">
                      <div
                        className="row"
                        style={{
                          maxHeight: "100px",
                          overflowY: "scroll",
                        }}
                      >
                        {checkboxes.map((checkbox) => (
                          <>
                            <div className="col-md-3" key={checkbox.id}>
                              <label className="d-flex  align-items-center">
                                <input
                                  type="checkbox"
                                  checked={checkbox.checked}
                                  onChange={() =>
                                    handleCheckboxChange(checkbox.id)
                                  }
                                  className="mr-2"
                                  style={{
                                    height: "16px",
                                    width: "16px",
                                  }}
                                />
                                {checkbox.label}
                              </label>
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <h5 className="py-3">Interest</h5>
                    </div>
                    <div className="col-md-12 pb-4 pt-2">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-4 ">
                              <div className="radio d-flex justify-content-center">
                                <label className="d-flex">
                                  <input
                                    style={{ height: "20px", width: "20px" }}
                                    type="radio"
                                    value="Male"
                                    checked={selectedCheckbox === "Yes"}
                                    onChange={() => {
                                      onValueChange("Yes");
                                    }}
                                  />
                                  &nbsp; Yes
                                </label>
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="radio d-flex justify-content-center">
                                <label className="d-flex">
                                  <input
                                    style={{ height: "20px", width: "20px" }}
                                    type="radio"
                                    value="Female"
                                    checked={selectedCheckbox === "Follow Up"}
                                    onChange={() => {
                                      onValueChange("Follow Up");
                                    }}
                                  />
                                  &nbsp; Follow Up
                                </label>
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="radio d-flex justify-content-center">
                                <label className="d-flex">
                                  <input
                                    style={{ height: "20px", width: "20px" }}
                                    type="radio"
                                    value="Other"
                                    checked={selectedCheckbox === "Requirement"}
                                    onChange={() => {
                                      onValueChange("Requirement");
                                    }}
                                  />
                                  &nbsp; Requirement
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="row">
                  <div className="col-sm-12">
                    {selectedCheckbox === "Follow Up" && (
                      <div className="row">
                        <div className="col-sm-2 d-flex">
                          <h4 className="d-flex justify-content-center align-items-center">
                            Follow Up:{" "}
                          </h4>
                        </div>
                        <div className="col-sm-10">
                          <FormControl
                            fullWidth
                            variant="filled"
                            className="theme-input mb-3"
                          >
                            <DateTimePicker
                              size="small"
                              labelId="label-datepicker"
                              inputVariant="outlined"
                              ampm={false}
                              disablePast
                              format="DD/MM/yyyy hh:mm a"
                              value={visitDate}
                              onChange={(e) => {
                                let date = moment(e).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                );
                                console.log(
                                  moment(e).format("YYYY-MM-DD HH:mm:ss")
                                );
                                setUploadValues({
                                  ...openUploadValues,
                                  appointment_date: date,
                                });
                                setVisitDate(
                                  moment(e).format().split("T")[0] +
                                    " " +
                                    moment(e).format().split("T")[1]
                                );
                              }}
                            />
                          </FormControl>
                        </div>
                      </div>
                    )}
                  </div>
                  {filteredData.map((item, index) => {
                    return (
                      <div className="col-sm-4" key={index}>
                        <div className="row">
                          <div className="col-sm-12">
                            <TextField
                              fullWidth
                              className="theme-input mb-3 "
                              value={item.label || ""}
                              onChange={handleUploadForm}
                              name="cat"
                              id="filled-basic"
                              label="Category"
                              variant="filled"
                              disabled
                              InputProps={{
                                style: {
                                  height: "40px", // Adjust this value as needed for height
                                  display: "flex",
                                  alignItems: "center", // Vertically center the text
                                },
                                classes: {
                                  input: "text-center-input", // Custom class for text alignment
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  top: "-8px", // Adjust this value as needed to position the label
                                },
                              }}
                            />
                          </div>
                        </div>

                        {selectedCheckbox !== "Requirement" && (
                          <>
                            <div className="row">
                              <div className="col-sm-12">
                                <input
                                  id={`updateImage${index}`}
                                  innerRef={imageRef}
                                  type="file"
                                  multiple={true}
                                  accept="image/*"
                                  onChange={(e) => {
                                    if (e.target.files.length > 0) {
                                      maintobase64(e.target.files[0]).then(
                                        (data) => {
                                          if (index === 0) {
                                            setUploadedFile1(data);
                                            setFetchImage1(false);
                                          } else if (index === 1) {
                                            setUploadedFile2(data);
                                            setFetchImage2(false);
                                          } else if (index === 2) {
                                            setFetchImage3(false);
                                            setUploadedFile3(data);
                                          }
                                        }
                                      );
                                      // }
                                    } else {
                                      e.target.value = null;
                                    }
                                  }}
                                  name="Choose Image"
                                />
                              </div>
                              <div className="col-sm-12">
                                <div className="previewImage mt-3">
                                  {index == 0 && (
                                    <img
                                      src={
                                        Object.keys(uploadedFile1).length > 0
                                          ? fetch1
                                            ? API_BASE_URL +
                                              "CustomerUploads/" +
                                              uploadedFile1
                                            : uploadedFile1
                                          : // : "https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?format=jpg&quality=90&v=1530129081"
                                            API_BASE_URL +
                                            "CustomerUploads/" +
                                            im1
                                      }
                                    />
                                  )}
                                  {index == 1 && (
                                    <img
                                      src={
                                        Object.keys(uploadedFile2).length > 0
                                          ? fetch2
                                            ? API_BASE_URL +
                                              "CustomerUploads/" +
                                              uploadedFile2
                                            : uploadedFile2
                                          : // : "https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?format=jpg&quality=90&v=1530129081"
                                            API_BASE_URL +
                                            "CustomerUploads/" +
                                            im2
                                      }
                                    />
                                  )}
                                  {index == 2 && (
                                    <img
                                      src={
                                        Object.keys(uploadedFile3).length > 0
                                          ? fetch3
                                            ? API_BASE_URL +
                                              "CustomerUploads/" +
                                              uploadedFile3
                                            : uploadedFile3
                                          : // : "https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?format=jpg&quality=90&v=1530129081"
                                            API_BASE_URL +
                                            "CustomerUploads/" +
                                            im3
                                      }
                                    />
                                  )}
                                  {index === 0 && (
                                    <div>
                                      <div>
                                        <div className="row mt-2">
                                          <div className="col-12 d-flex justify-content-between">
                                            <Dropzone
                                              onDrop={onDrop1}
                                              accept="image/*"
                                              style={{
                                                height: "30px",
                                                width: "100%",
                                              }}
                                              multiple
                                            >
                                              {({
                                                getRootProps,
                                                getInputProps,
                                              }) => (
                                                <div
                                                  {...getRootProps()}
                                                  className="dropzone btn btn-primary d-flex justify-content-center align-items-center"
                                                  style={{
                                                    height: "30px",
                                                    width: "120px",
                                                    padding: "10px",
                                                  }}
                                                >
                                                  <input {...getInputProps()} />
                                                  <p
                                                    className="d-flex justify-content-center align-items-center pt-2"
                                                    style={{
                                                      width: "100%",
                                                      height: "100%",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    Add Images
                                                  </p>
                                                </div>
                                              )}
                                            </Dropzone>

                                            <Button
                                              className="btn btn-success p-2 m-0"
                                              color="secondary"
                                              onClick={uploadFiles1}
                                            >
                                              <img
                                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAg0lEQVR4nO3RMQrCQBBA0b2GlYWFjY2Fabx9AlaeQAiCRcBC8AZPBEWNiLq7guj+aqr/mZkQ/gYMsMTiOOeWD7FyYY1RLvkYG/d0mKTKp9h6zA5VrHx2Ejxjj3lMoH1BfqaN2qIXvCFZWAJvo/zgG07UXPnr7IFC6N04lebTgfp3fnYAEn/y2m24AyIAAAAASUVORK5CYII="
                                                style={{
                                                  height: "18px",
                                                  width: "20px",
                                                }}
                                              />
                                            </Button>
                                          </div>
                                        </div>
                                        {selectedFiles1.length > 0 && (
                                          <div>
                                            <p>Selected Files:</p>
                                            <div className="row">
                                              {selectedFiles1.map((file) => (
                                                <div
                                                  className="col-3"
                                                  key={file.name}
                                                >
                                                  <div className="row">
                                                    <div className="col-12 d-flex justify-content-center">
                                                      <img
                                                        src={URL.createObjectURL(
                                                          file
                                                        )}
                                                        alt={file.name}
                                                        height={30}
                                                        style={{
                                                          height: "38px",
                                                          width: "38px",
                                                        }}
                                                      />
                                                    </div>
                                                    <div className="col-12 d-flex justify-content-center p-2">
                                                      <img
                                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADYklEQVR4nO2aTUgVURTHf6WkBqVJpi0KCqJdixatql1Q2YdtyxZFrdM+oFWbdilCEBER0QcUtDREy1WbiihcRGlpEpUGJVIbnXufdePAnRh847z3Zu44T/HC2cxczjn/e//33HPODCyNRToM1HvQoqBTQY+GIQWTCpSVSQ2D8k5Bh8w1sIZyGAaqFRxX0K/gjwZTiiiYUfBEQavoIgMANRrOKxgv1fkIUKLrnOieFxA5OKBh1BWAEPmUg+a0aXQ1RQCz5Z6Bla5BrNcwMI8gjJXXBpqcgJiGzbLdGYAwVkbEh6Q7sU7DxwxBGP/cxN4ZG5myoJOZQ97ECtEabpaB8yYoCq7HCbGmHCUH+0qhVJaH2xSQkaIopuFCGThrCkh7od2o0jBWBo6aKFHwPTKVkQQwpvJ3/nZrOFPE/Ad24SoVvIwJ5lgUkP4Eq9RhnVuu4FnEvB8GGizoSwns9c1Fq3pJqxMoljR+p+jyYIuCqbB5Hhy2i7ZdgU5gb8ZAXR4QD4444O+ogVV2tc+GvL8bOItvk9rz4FAYrTpdXloGKhQ8D7wb8ytCBV2ObF0JA9LjSPlf/9LyYKtPsRzstzu1K041qcOlOw+I4+Twi89fey/dtrtUq+GzQztDYTsy4dBA8DxUBELzHZc2FPwMA6IcA/kfoRwGEzMLiJc6EHsOdgf0n5gvIBOOjXRZalUaWGap1Z06tbTbw/7ez4WkrlFw2oJqEuNpH/YeR6uU07BDdOZgj4RjBb+nYKM9Ky0OgXSneSFetqtfp+Fb4HlfgGIPU7sQPTcrNWBghXX2fojhUwGQX5Pa8+BgHhBJHxImjZ6CbVGLouDXFGywtGtOSmEDtXlALL2eJlB+0S7IWil8IhzoD1DsVgIgvaEgLJDWmEpfyA1unXtUxPyTFvTquCmLgqOLodQdL9iAkNZ+1o7qwtIWCWKBtIOGi+44Jo0oaUoO9lLKUHAja6f1LFFwrSQQlmLV8n2ijEC8kmBUMhALpkHDhzJpkzbi4EPPSJYgpmFTIhCBnWmS7xMZ0anRCYgAmKoMPobWkNaQVk/KVBsuOcTGHTaitTtOZ0RXW1Z/QAjdWqVoilMC2F84eqWzHju0uh5SLEkvVqo2BY/tDzQTgZ9qpLkxKOWpzJGiaM56Ymmw8Mc/6vKer4zrZQAAAAAASUVORK5CYII="
                                                        onClick={() =>
                                                          removeFile1(file)
                                                        }
                                                        style={{
                                                          height: "20px",
                                                          width: "20px",
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        )}
                                        {uploadedFiles1.length > 0 && (
                                          <div>
                                            <p
                                              style={{
                                                fontWeight: "900",
                                                color: "green",
                                              }}
                                            >
                                              Uploaded Files:
                                            </p>
                                            <div className="row">
                                              {uploadedFiles1.map(
                                                (fileName) => (
                                                  <div
                                                    className="col-3"
                                                    key={fileName}
                                                  >
                                                    <div className="row">
                                                      <div className="col-12 d-flex justify-content-center">
                                                        <img
                                                          src={
                                                            API_BASE_URL +
                                                            "CustomerUploads/" +
                                                            fileName
                                                          }
                                                          alt={fileName}
                                                          height={30}
                                                          style={{
                                                            height: "38px",
                                                            width: "38px",
                                                          }}
                                                        />
                                                      </div>
                                                      <div className="col-12 d-flex justify-content-center p-2">
                                                        <img
                                                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADYklEQVR4nO2aTUgVURTHf6WkBqVJpi0KCqJdixatql1Q2YdtyxZFrdM+oFWbdilCEBER0QcUtDREy1WbiihcRGlpEpUGJVIbnXufdePAnRh847z3Zu44T/HC2cxczjn/e//33HPODCyNRToM1HvQoqBTQY+GIQWTCpSVSQ2D8k5Bh8w1sIZyGAaqFRxX0K/gjwZTiiiYUfBEQavoIgMANRrOKxgv1fkIUKLrnOieFxA5OKBh1BWAEPmUg+a0aXQ1RQCz5Z6Bla5BrNcwMI8gjJXXBpqcgJiGzbLdGYAwVkbEh6Q7sU7DxwxBGP/cxN4ZG5myoJOZQ97ECtEabpaB8yYoCq7HCbGmHCUH+0qhVJaH2xSQkaIopuFCGThrCkh7od2o0jBWBo6aKFHwPTKVkQQwpvJ3/nZrOFPE/Ad24SoVvIwJ5lgUkP4Eq9RhnVuu4FnEvB8GGizoSwns9c1Fq3pJqxMoljR+p+jyYIuCqbB5Hhy2i7ZdgU5gb8ZAXR4QD4444O+ogVV2tc+GvL8bOItvk9rz4FAYrTpdXloGKhQ8D7wb8ytCBV2ObF0JA9LjSPlf/9LyYKtPsRzstzu1K041qcOlOw+I4+Twi89fey/dtrtUq+GzQztDYTsy4dBA8DxUBELzHZc2FPwMA6IcA/kfoRwGEzMLiJc6EHsOdgf0n5gvIBOOjXRZalUaWGap1Z06tbTbw/7ez4WkrlFw2oJqEuNpH/YeR6uU07BDdOZgj4RjBb+nYKM9Ky0OgXSneSFetqtfp+Fb4HlfgGIPU7sQPTcrNWBghXX2fojhUwGQX5Pa8+BgHhBJHxImjZ6CbVGLouDXFGywtGtOSmEDtXlALL2eJlB+0S7IWil8IhzoD1DsVgIgvaEgLJDWmEpfyA1unXtUxPyTFvTquCmLgqOLodQdL9iAkNZ+1o7qwtIWCWKBtIOGi+44Jo0oaUoO9lLKUHAja6f1LFFwrSQQlmLV8n2ijEC8kmBUMhALpkHDhzJpkzbi4EPPSJYgpmFTIhCBnWmS7xMZ0anRCYgAmKoMPobWkNaQVk/KVBsuOcTGHTaitTtOZ0RXW1Z/QAjdWqVoilMC2F84eqWzHju0uh5SLEkvVqo2BY/tDzQTgZ9qpLkxKOWpzJGiaM56Ymmw8Mc/6vKer4zrZQAAAAAASUVORK5CYII="
                                                          onClick={() =>
                                                            removeFileUploaded1(
                                                              fileName
                                                            )
                                                          }
                                                          style={{
                                                            height: "20px",
                                                            width: "20px",
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  {index === 1 && (
                                    <div>
                                      <div>
                                        <div className="row mt-2">
                                          <div className="col-12 d-flex justify-content-between">
                                            <Dropzone
                                              onDrop={onDrop2}
                                              accept="image/*"
                                              style={{
                                                height: "30px",
                                                width: "100%",
                                              }}
                                              multiple
                                            >
                                              {({
                                                getRootProps,
                                                getInputProps,
                                              }) => (
                                                <div
                                                  {...getRootProps()}
                                                  className="dropzone btn btn-primary d-flex justify-content-center align-items-center"
                                                  style={{
                                                    height: "30px",
                                                    width: "120px",
                                                    padding: "10px",
                                                  }}
                                                >
                                                  <input {...getInputProps()} />
                                                  <p
                                                    className="d-flex justify-content-center align-items-center pt-2"
                                                    style={{
                                                      width: "100%",
                                                      height: "100%",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    Add Images
                                                  </p>
                                                </div>
                                              )}
                                            </Dropzone>

                                            <Button
                                              className="btn btn-success p-2 m-0"
                                              color="secondary"
                                              onClick={uploadFiles2}
                                            >
                                              <img
                                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAg0lEQVR4nO3RMQrCQBBA0b2GlYWFjY2Fabx9AlaeQAiCRcBC8AZPBEWNiLq7guj+aqr/mZkQ/gYMsMTiOOeWD7FyYY1RLvkYG/d0mKTKp9h6zA5VrHx2Ejxjj3lMoH1BfqaN2qIXvCFZWAJvo/zgG07UXPnr7IFC6N04lebTgfp3fnYAEn/y2m24AyIAAAAASUVORK5CYII="
                                                style={{
                                                  height: "18px",
                                                  width: "20px",
                                                }}
                                              />
                                            </Button>
                                          </div>
                                        </div>
                                        {selectedFiles2.length > 0 && (
                                          <div>
                                            <p>Selected Files:</p>
                                            <div className="row">
                                              {selectedFiles2.map((file) => (
                                                <div
                                                  className="col-3"
                                                  key={file.name}
                                                >
                                                  <div className="row">
                                                    <div className="col-12 d-flex justify-content-center">
                                                      <img
                                                        src={URL.createObjectURL(
                                                          file
                                                        )}
                                                        alt={file.name}
                                                        height={30}
                                                        style={{
                                                          height: "38px",
                                                          width: "38px",
                                                        }}
                                                      />
                                                    </div>
                                                    <div className="col-12 d-flex justify-content-center p-2">
                                                      <img
                                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADYklEQVR4nO2aTUgVURTHf6WkBqVJpi0KCqJdixatql1Q2YdtyxZFrdM+oFWbdilCEBER0QcUtDREy1WbiihcRGlpEpUGJVIbnXufdePAnRh847z3Zu44T/HC2cxczjn/e//33HPODCyNRToM1HvQoqBTQY+GIQWTCpSVSQ2D8k5Bh8w1sIZyGAaqFRxX0K/gjwZTiiiYUfBEQavoIgMANRrOKxgv1fkIUKLrnOieFxA5OKBh1BWAEPmUg+a0aXQ1RQCz5Z6Bla5BrNcwMI8gjJXXBpqcgJiGzbLdGYAwVkbEh6Q7sU7DxwxBGP/cxN4ZG5myoJOZQ97ECtEabpaB8yYoCq7HCbGmHCUH+0qhVJaH2xSQkaIopuFCGThrCkh7od2o0jBWBo6aKFHwPTKVkQQwpvJ3/nZrOFPE/Ad24SoVvIwJ5lgUkP4Eq9RhnVuu4FnEvB8GGizoSwns9c1Fq3pJqxMoljR+p+jyYIuCqbB5Hhy2i7ZdgU5gb8ZAXR4QD4444O+ogVV2tc+GvL8bOItvk9rz4FAYrTpdXloGKhQ8D7wb8ytCBV2ObF0JA9LjSPlf/9LyYKtPsRzstzu1K041qcOlOw+I4+Twi89fey/dtrtUq+GzQztDYTsy4dBA8DxUBELzHZc2FPwMA6IcA/kfoRwGEzMLiJc6EHsOdgf0n5gvIBOOjXRZalUaWGap1Z06tbTbw/7ez4WkrlFw2oJqEuNpH/YeR6uU07BDdOZgj4RjBb+nYKM9Ky0OgXSneSFetqtfp+Fb4HlfgGIPU7sQPTcrNWBghXX2fojhUwGQX5Pa8+BgHhBJHxImjZ6CbVGLouDXFGywtGtOSmEDtXlALL2eJlB+0S7IWil8IhzoD1DsVgIgvaEgLJDWmEpfyA1unXtUxPyTFvTquCmLgqOLodQdL9iAkNZ+1o7qwtIWCWKBtIOGi+44Jo0oaUoO9lLKUHAja6f1LFFwrSQQlmLV8n2ijEC8kmBUMhALpkHDhzJpkzbi4EPPSJYgpmFTIhCBnWmS7xMZ0anRCYgAmKoMPobWkNaQVk/KVBsuOcTGHTaitTtOZ0RXW1Z/QAjdWqVoilMC2F84eqWzHju0uh5SLEkvVqo2BY/tDzQTgZ9qpLkxKOWpzJGiaM56Ymmw8Mc/6vKer4zrZQAAAAAASUVORK5CYII="
                                                        onClick={() =>
                                                          removeFile1(file)
                                                        }
                                                        style={{
                                                          height: "20px",
                                                          width: "20px",
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        )}
                                        {uploadedFiles2.length > 0 && (
                                          <div>
                                            <p
                                              style={{
                                                fontWeight: "900",
                                                color: "green",
                                              }}
                                            >
                                              Uploaded Files:
                                            </p>
                                            <div className="row">
                                              {uploadedFiles2.map(
                                                (fileName) => (
                                                  <div
                                                    className="col-3"
                                                    key={fileName}
                                                  >
                                                    <div className="row">
                                                      <div className="col-12 d-flex justify-content-center">
                                                        <img
                                                          src={
                                                            API_BASE_URL +
                                                            "CustomerUploads/" +
                                                            fileName
                                                          }
                                                          alt={fileName}
                                                          height={30}
                                                          style={{
                                                            height: "38px",
                                                            width: "38px",
                                                          }}
                                                        />
                                                      </div>
                                                      <div className="col-12 d-flex justify-content-center p-2">
                                                        <img
                                                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADYklEQVR4nO2aTUgVURTHf6WkBqVJpi0KCqJdixatql1Q2YdtyxZFrdM+oFWbdilCEBER0QcUtDREy1WbiihcRGlpEpUGJVIbnXufdePAnRh847z3Zu44T/HC2cxczjn/e//33HPODCyNRToM1HvQoqBTQY+GIQWTCpSVSQ2D8k5Bh8w1sIZyGAaqFRxX0K/gjwZTiiiYUfBEQavoIgMANRrOKxgv1fkIUKLrnOieFxA5OKBh1BWAEPmUg+a0aXQ1RQCz5Z6Bla5BrNcwMI8gjJXXBpqcgJiGzbLdGYAwVkbEh6Q7sU7DxwxBGP/cxN4ZG5myoJOZQ97ECtEabpaB8yYoCq7HCbGmHCUH+0qhVJaH2xSQkaIopuFCGThrCkh7od2o0jBWBo6aKFHwPTKVkQQwpvJ3/nZrOFPE/Ad24SoVvIwJ5lgUkP4Eq9RhnVuu4FnEvB8GGizoSwns9c1Fq3pJqxMoljR+p+jyYIuCqbB5Hhy2i7ZdgU5gb8ZAXR4QD4444O+ogVV2tc+GvL8bOItvk9rz4FAYrTpdXloGKhQ8D7wb8ytCBV2ObF0JA9LjSPlf/9LyYKtPsRzstzu1K041qcOlOw+I4+Twi89fey/dtrtUq+GzQztDYTsy4dBA8DxUBELzHZc2FPwMA6IcA/kfoRwGEzMLiJc6EHsOdgf0n5gvIBOOjXRZalUaWGap1Z06tbTbw/7ez4WkrlFw2oJqEuNpH/YeR6uU07BDdOZgj4RjBb+nYKM9Ky0OgXSneSFetqtfp+Fb4HlfgGIPU7sQPTcrNWBghXX2fojhUwGQX5Pa8+BgHhBJHxImjZ6CbVGLouDXFGywtGtOSmEDtXlALL2eJlB+0S7IWil8IhzoD1DsVgIgvaEgLJDWmEpfyA1unXtUxPyTFvTquCmLgqOLodQdL9iAkNZ+1o7qwtIWCWKBtIOGi+44Jo0oaUoO9lLKUHAja6f1LFFwrSQQlmLV8n2ijEC8kmBUMhALpkHDhzJpkzbi4EPPSJYgpmFTIhCBnWmS7xMZ0anRCYgAmKoMPobWkNaQVk/KVBsuOcTGHTaitTtOZ0RXW1Z/QAjdWqVoilMC2F84eqWzHju0uh5SLEkvVqo2BY/tDzQTgZ9qpLkxKOWpzJGiaM56Ymmw8Mc/6vKer4zrZQAAAAAASUVORK5CYII="
                                                          onClick={() =>
                                                            removeFileUploaded2(
                                                              fileName
                                                            )
                                                          }
                                                          style={{
                                                            height: "20px",
                                                            width: "20px",
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  {index === 2 && (
                                    <div>
                                      <div>
                                        <div className="row mt-2">
                                          <div className="col-12 d-flex justify-content-between">
                                            <Dropzone
                                              onDrop={onDrop3}
                                              accept="image/*"
                                              style={{
                                                height: "30px",
                                                width: "100%",
                                              }}
                                              multiple
                                            >
                                              {({
                                                getRootProps,
                                                getInputProps,
                                              }) => (
                                                <div
                                                  {...getRootProps()}
                                                  className="dropzone btn btn-primary d-flex justify-content-center align-items-center"
                                                  style={{
                                                    height: "30px",
                                                    width: "120px",
                                                    padding: "10px",
                                                  }}
                                                >
                                                  <input {...getInputProps()} />
                                                  <p
                                                    className="d-flex justify-content-center align-items-center pt-2"
                                                    style={{
                                                      width: "100%",
                                                      height: "100%",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    Add Images
                                                  </p>
                                                </div>
                                              )}
                                            </Dropzone>

                                            <Button
                                              className="btn btn-success p-2 m-0"
                                              color="secondary"
                                              onClick={uploadFiles3}
                                            >
                                              <img
                                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAg0lEQVR4nO3RMQrCQBBA0b2GlYWFjY2Fabx9AlaeQAiCRcBC8AZPBEWNiLq7guj+aqr/mZkQ/gYMsMTiOOeWD7FyYY1RLvkYG/d0mKTKp9h6zA5VrHx2Ejxjj3lMoH1BfqaN2qIXvCFZWAJvo/zgG07UXPnr7IFC6N04lebTgfp3fnYAEn/y2m24AyIAAAAASUVORK5CYII="
                                                style={{
                                                  height: "18px",
                                                  width: "20px",
                                                }}
                                              />
                                            </Button>
                                          </div>
                                        </div>
                                        {selectedFiles3.length > 0 && (
                                          <div>
                                            <p>Selected Files:</p>
                                            <div className="row">
                                              {selectedFiles3.map((file) => (
                                                <div
                                                  className="col-3"
                                                  key={file.name}
                                                >
                                                  <div className="row">
                                                    <div className="col-12 d-flex justify-content-center">
                                                      <img
                                                        src={URL.createObjectURL(
                                                          file
                                                        )}
                                                        alt={file.name}
                                                        height={30}
                                                        style={{
                                                          height: "38px",
                                                          width: "38px",
                                                        }}
                                                      />
                                                    </div>
                                                    <div className="col-12 d-flex justify-content-center p-2">
                                                      <img
                                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADYklEQVR4nO2aTUgVURTHf6WkBqVJpi0KCqJdixatql1Q2YdtyxZFrdM+oFWbdilCEBER0QcUtDREy1WbiihcRGlpEpUGJVIbnXufdePAnRh847z3Zu44T/HC2cxczjn/e//33HPODCyNRToM1HvQoqBTQY+GIQWTCpSVSQ2D8k5Bh8w1sIZyGAaqFRxX0K/gjwZTiiiYUfBEQavoIgMANRrOKxgv1fkIUKLrnOieFxA5OKBh1BWAEPmUg+a0aXQ1RQCz5Z6Bla5BrNcwMI8gjJXXBpqcgJiGzbLdGYAwVkbEh6Q7sU7DxwxBGP/cxN4ZG5myoJOZQ97ECtEabpaB8yYoCq7HCbGmHCUH+0qhVJaH2xSQkaIopuFCGThrCkh7od2o0jBWBo6aKFHwPTKVkQQwpvJ3/nZrOFPE/Ad24SoVvIwJ5lgUkP4Eq9RhnVuu4FnEvB8GGizoSwns9c1Fq3pJqxMoljR+p+jyYIuCqbB5Hhy2i7ZdgU5gb8ZAXR4QD4444O+ogVV2tc+GvL8bOItvk9rz4FAYrTpdXloGKhQ8D7wb8ytCBV2ObF0JA9LjSPlf/9LyYKtPsRzstzu1K041qcOlOw+I4+Twi89fey/dtrtUq+GzQztDYTsy4dBA8DxUBELzHZc2FPwMA6IcA/kfoRwGEzMLiJc6EHsOdgf0n5gvIBOOjXRZalUaWGap1Z06tbTbw/7ez4WkrlFw2oJqEuNpH/YeR6uU07BDdOZgj4RjBb+nYKM9Ky0OgXSneSFetqtfp+Fb4HlfgGIPU7sQPTcrNWBghXX2fojhUwGQX5Pa8+BgHhBJHxImjZ6CbVGLouDXFGywtGtOSmEDtXlALL2eJlB+0S7IWil8IhzoD1DsVgIgvaEgLJDWmEpfyA1unXtUxPyTFvTquCmLgqOLodQdL9iAkNZ+1o7qwtIWCWKBtIOGi+44Jo0oaUoO9lLKUHAja6f1LFFwrSQQlmLV8n2ijEC8kmBUMhALpkHDhzJpkzbi4EPPSJYgpmFTIhCBnWmS7xMZ0anRCYgAmKoMPobWkNaQVk/KVBsuOcTGHTaitTtOZ0RXW1Z/QAjdWqVoilMC2F84eqWzHju0uh5SLEkvVqo2BY/tDzQTgZ9qpLkxKOWpzJGiaM56Ymmw8Mc/6vKer4zrZQAAAAAASUVORK5CYII="
                                                        onClick={() =>
                                                          removeFile3(file)
                                                        }
                                                        style={{
                                                          height: "20px",
                                                          width: "20px",
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        )}
                                        {uploadedFiles3.length > 0 && (
                                          <div>
                                            <p
                                              style={{
                                                fontWeight: "900",
                                                color: "green",
                                              }}
                                            >
                                              Uploaded Files:
                                            </p>
                                            <div className="row">
                                              {uploadedFiles3.map(
                                                (fileName) => (
                                                  <div
                                                    className="col-3"
                                                    key={fileName}
                                                  >
                                                    <div className="row">
                                                      <div className="col-12 d-flex justify-content-center">
                                                        <img
                                                          src={
                                                            API_BASE_URL +
                                                            "CustomerUploads/" +
                                                            fileName
                                                          }
                                                          alt={fileName}
                                                          height={30}
                                                          style={{
                                                            height: "38px",
                                                            width: "38px",
                                                          }}
                                                        />
                                                      </div>
                                                      <div className="col-12 d-flex justify-content-center p-2">
                                                        <img
                                                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADYklEQVR4nO2aTUgVURTHf6WkBqVJpi0KCqJdixatql1Q2YdtyxZFrdM+oFWbdilCEBER0QcUtDREy1WbiihcRGlpEpUGJVIbnXufdePAnRh847z3Zu44T/HC2cxczjn/e//33HPODCyNRToM1HvQoqBTQY+GIQWTCpSVSQ2D8k5Bh8w1sIZyGAaqFRxX0K/gjwZTiiiYUfBEQavoIgMANRrOKxgv1fkIUKLrnOieFxA5OKBh1BWAEPmUg+a0aXQ1RQCz5Z6Bla5BrNcwMI8gjJXXBpqcgJiGzbLdGYAwVkbEh6Q7sU7DxwxBGP/cxN4ZG5myoJOZQ97ECtEabpaB8yYoCq7HCbGmHCUH+0qhVJaH2xSQkaIopuFCGThrCkh7od2o0jBWBo6aKFHwPTKVkQQwpvJ3/nZrOFPE/Ad24SoVvIwJ5lgUkP4Eq9RhnVuu4FnEvB8GGizoSwns9c1Fq3pJqxMoljR+p+jyYIuCqbB5Hhy2i7ZdgU5gb8ZAXR4QD4444O+ogVV2tc+GvL8bOItvk9rz4FAYrTpdXloGKhQ8D7wb8ytCBV2ObF0JA9LjSPlf/9LyYKtPsRzstzu1K041qcOlOw+I4+Twi89fey/dtrtUq+GzQztDYTsy4dBA8DxUBELzHZc2FPwMA6IcA/kfoRwGEzMLiJc6EHsOdgf0n5gvIBOOjXRZalUaWGap1Z06tbTbw/7ez4WkrlFw2oJqEuNpH/YeR6uU07BDdOZgj4RjBb+nYKM9Ky0OgXSneSFetqtfp+Fb4HlfgGIPU7sQPTcrNWBghXX2fojhUwGQX5Pa8+BgHhBJHxImjZ6CbVGLouDXFGywtGtOSmEDtXlALL2eJlB+0S7IWil8IhzoD1DsVgIgvaEgLJDWmEpfyA1unXtUxPyTFvTquCmLgqOLodQdL9iAkNZ+1o7qwtIWCWKBtIOGi+44Jo0oaUoO9lLKUHAja6f1LFFwrSQQlmLV8n2ijEC8kmBUMhALpkHDhzJpkzbi4EPPSJYgpmFTIhCBnWmS7xMZ0anRCYgAmKoMPobWkNaQVk/KVBsuOcTGHTaitTtOZ0RXW1Z/QAjdWqVoilMC2F84eqWzHju0uh5SLEkvVqo2BY/tDzQTgZ9qpLkxKOWpzJGiaM56Ymmw8Mc/6vKer4zrZQAAAAAASUVORK5CYII="
                                                          onClick={() =>
                                                            removeFileUploaded3(
                                                              fileName
                                                            )
                                                          }
                                                          style={{
                                                            height: "20px",
                                                            width: "20px",
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            {branchEncryptID !== "21FC375" && (
                              <div className="row">
                                <div className="col-sm-12 pt-2">
                                  <TextField
                                    fullWidth
                                    autoComplete="new-off"
                                    className="theme-input mb-1"
                                    value={
                                      index == 0
                                        ? openUploadValues.sku1
                                        : index == 1
                                        ? openUploadValues.sku2
                                        : index == 2
                                        ? openUploadValues.sku3
                                        : ""
                                    }
                                    onChange={handleUploadForm}
                                    id="filled-basic"
                                    name={`sku${index + 1}`}
                                    label="SKU"
                                    variant="filled"
                                    InputProps={{
                                      style: {
                                        height: "40px", // Adjust this value as needed for height
                                        display: "flex",
                                        alignItems: "center", // Vertically center the text
                                      },
                                      classes: {
                                        input: "text-center-input", // Custom class for text alignment
                                      },
                                    }}
                                    InputLabelProps={{
                                      style: {
                                        top: "-8px", // Adjust this value as needed to position the label
                                      },
                                    }}
                                  />
                                </div>
                                <div className="col-sm-12">
                                  <Button
                                    className="btn btn-success"
                                    onClick={() => {
                                      FetchImage(index);
                                    }}
                                    color="secondary"
                                  >
                                    Fetch Image
                                  </Button>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                        <div className="row">
                          <div className="col-sm-12 pt-2">
                            {index === 0 && (
                              <>
                                {/* -------------- */}

                                {/* --------------- */}

                                {/* start */}
                                {/* <FormControl
                                  fullWidth
                                  variant="filled"
                                  className="theme-input mb-2"
                                  key={index}
                                >
                                  <InputLabel
                                    id={`demo-simple-select-filled-label-${index}`}
                                  >
                                    {branchEncryptID === "21FC375"
                                      ? "Modal"
                                      : "Sub Category"}
                                  </InputLabel>
                                  <Select
                                    labelId={`demo-simple-select-filled-label-${index}`}
                                    id={`demo-simple-select-filled-${index}`}
                                    value={openUploadValues.sub_category1 || ""}
                                    name="sub_category1"
                                    onChange={handleUploadForm}
                                    style={{ height: "60px" }}
                                  >
                                    {SubCategoryList.length > 0
                                      ? SubCategoryList.filter(
                                          (prop) => prop.category_id === item.id
                                        ).map((sc, k) => {
                                          return (
                                            <MenuItem
                                              key={k}
                                              value={sc.subcategory_name}
                                            >
                                              {sc.subcategory_name}
                                            </MenuItem>
                                          );
                                        })
                                      : null}
                                  </Select>
                                </FormControl> */}

                                <FormControl
                                  fullWidth
                                  variant="filled"
                                  className="theme-input mb-2"
                                  key={index}
                                >
                                  <InputLabel
                                    id={`demo-simple-select-filled-label-${index}`}
                                    style={{
                                      top: "-8px", // Adjust this value to position the label as needed
                                    }}
                                  >
                                    {branchEncryptID === "21FC375"
                                      ? "Modal"
                                      : "Sub Category"}
                                  </InputLabel>
                                  <Select
                                    labelId={`demo-simple-select-filled-label-${index}`}
                                    id={`demo-simple-select-filled-${index}`}
                                    value={openUploadValues.sub_category1 || ""}
                                    name="sub_category1"
                                    onChange={handleUploadForm}
                                    style={{
                                      height: "40px", // Adjust this value as needed for height
                                      display: "flex",
                                      alignItems: "center", // Vertically center the text
                                      textAlign: "center", // Center align the text horizontally
                                    }}
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight: 200, // Optional: limit the height of the dropdown menu
                                        },
                                      },
                                    }}
                                  >
                                    {SubCategoryList.length > 0
                                      ? SubCategoryList.filter(
                                          (prop) => prop.category_id === item.id
                                        ).map((sc, k) => {
                                          return (
                                            <MenuItem
                                              key={k}
                                              value={sc.subcategory_name}
                                            >
                                              {sc.subcategory_name}
                                            </MenuItem>
                                          );
                                        })
                                      : null}
                                  </Select>
                                </FormControl>

                                {/* end */}

                                <TextField
                                  fullWidth
                                  autoComplete="new-off"
                                  multiline
                                  className="theme-input mb-2"
                                  value={openUploadValues.remarks1 || ""}
                                  id="filled-basic"
                                  onChange={handleUploadForm}
                                  name="remarks1"
                                  label="Remarks"
                                  variant="filled"
                                  InputProps={{
                                    style: {
                                      height: "40px", // Adjust this value as needed for height
                                      display: "flex",
                                      alignItems: "center", // Vertically center the text
                                    },
                                    classes: {
                                      input: "text-center-input", // Custom class for text alignment
                                    },
                                  }}
                                  InputLabelProps={{
                                    style: {
                                      top: "-8px", // Adjust this value as needed to position the label
                                    },
                                  }}
                                />

                                {branchEncryptID === "21FC375" && (
                                  <>
                                    <TextField
                                      fullWidth
                                      autoComplete="new-off"
                                      multiline
                                      className="theme-input mb-2"
                                      value={openUploadValues.color1 || ""}
                                      id="filled-basic-color1"
                                      onChange={handleUploadForm}
                                      name="color1"
                                      label="Color"
                                      variant="filled"
                                      InputProps={{
                                        style: {
                                          height: "40px", // Adjust this value as needed for height
                                          display: "flex",
                                          alignItems: "center", // Vertically center the text
                                        },
                                        classes: {
                                          input: "text-center-input", // Custom class for text alignment
                                        },
                                      }}
                                      InputLabelProps={{
                                        style: {
                                          top: "-8px", // Adjust this value as needed to position the label
                                        },
                                      }}
                                    />

                                    <TextField
                                      fullWidth
                                      autoComplete="new-off"
                                      multiline
                                      className="theme-input mb-2"
                                      value={
                                        openUploadValues.payment_mode1 || ""
                                      }
                                      id="filled-basic-payment_mode1"
                                      onChange={handleUploadForm}
                                      name="payment_mode1"
                                      label="Payment Mode"
                                      variant="filled"
                                      InputProps={{
                                        style: {
                                          height: "40px", // Adjust this value as needed for height
                                          display: "flex",
                                          alignItems: "center", // Vertically center the text
                                        },
                                        classes: {
                                          input: "text-center-input", // Custom class for text alignment
                                        },
                                      }}
                                      InputLabelProps={{
                                        style: {
                                          top: "-8px", // Adjust this value as needed to position the label
                                        },
                                      }}
                                    />
                                  </>
                                )}

                                {openUploadValues.interest === "Yes" ? (
                                  <TextField
                                    fullWidth
                                    autoComplete="new-off"
                                    multiline
                                    className="theme-input mb-2"
                                    value={openUploadValues.payment1 || ""}
                                    id="filled-basic"
                                    onChange={handlePaymentForm}
                                    name="payment1"
                                    label="Payment"
                                    variant="filled"
                                    InputProps={{
                                      style: {
                                        height: "40px", // Adjust this value as needed for height
                                        display: "flex",
                                        alignItems: "center", // Vertically center the text
                                      },
                                      classes: {
                                        input: "text-center-input", // Custom class for text alignment
                                      },
                                    }}
                                    InputLabelProps={{
                                      style: {
                                        top: "-8px", // Adjust this value as needed to position the label
                                      },
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </>
                            )}
                            {index === 1 && (
                              <>
                                {/* <FormControl
                                  fullWidth
                                  variant="filled"
                                  className="theme-input mb-2"
                                  key={index}
                                >
                                  <InputLabel
                                    id={`demo-simple-select-filled-label-${index}`}
                                  >
                                    {branchEncryptID === "21FC375"
                                      ? "Modal"
                                      : "Sub Category"}
                                  </InputLabel>
                                  <Select
                                    labelId={`demo-simple-select-filled-label-${index}`}
                                    id={`demo-simple-select-filled-${index}`}
                                    value={openUploadValues.sub_category2 || ""}
                                    name="sub_category2"
                                    onChange={handleUploadForm}
                                    style={{ height: "60px" }}
                                  >
                                    {SubCategoryList.length > 0
                                      ? SubCategoryList.filter(
                                          (prop) => prop.category_id === item.id
                                        ).map((sc, k) => {
                                          return (
                                            <MenuItem
                                              key={k}
                                              value={sc.subcategory_name}
                                            >
                                              {sc.subcategory_name}
                                            </MenuItem>
                                          );
                                        })
                                      : null}
                                  </Select>
                                </FormControl> */}

                                <FormControl
                                  fullWidth
                                  variant="filled"
                                  className="theme-input mb-2"
                                  key={index}
                                >
                                  <InputLabel
                                    id={`demo-simple-select-filled-label-${index}`}
                                    style={{
                                      top: "-8px", // Adjust this value to position the label as needed
                                    }}
                                  >
                                    {branchEncryptID === "21FC375"
                                      ? "Modal"
                                      : "Sub Category"}
                                  </InputLabel>
                                  <Select
                                    labelId={`demo-simple-select-filled-label-${index}`}
                                    id={`demo-simple-select-filled-${index}`}
                                    value={openUploadValues.sub_category2 || ""}
                                    name="sub_category2"
                                    onChange={handleUploadForm}
                                    style={{
                                      height: "40px", // Adjust this value as needed for height
                                      display: "flex",
                                      alignItems: "center", // Vertically center the text
                                      textAlign: "center", // Center align the text horizontally
                                    }}
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight: 200, // Optional: limit the height of the dropdown menu
                                        },
                                      },
                                    }}
                                  >
                                    {SubCategoryList.length > 0
                                      ? SubCategoryList.filter(
                                          (prop) => prop.category_id === item.id
                                        ).map((sc, k) => {
                                          return (
                                            <MenuItem
                                              key={k}
                                              value={sc.subcategory_name}
                                            >
                                              {sc.subcategory_name}
                                            </MenuItem>
                                          );
                                        })
                                      : null}
                                  </Select>
                                </FormControl>

                                <TextField
                                  fullWidth
                                  autoComplete="new-off"
                                  multiline
                                  className="theme-input mb-2"
                                  value={openUploadValues.remarks2 || ""}
                                  id="filled-basic"
                                  name="remarks2"
                                  onChange={handleUploadForm}
                                  label="Remarks"
                                  variant="filled"
                                  InputProps={{
                                    style: {
                                      height: "40px", // Adjust this value as needed for height
                                      display: "flex",
                                      alignItems: "center", // Vertically center the text
                                    },
                                    classes: {
                                      input: "text-center-input", // Custom class for text alignment
                                    },
                                  }}
                                  InputLabelProps={{
                                    style: {
                                      top: "-8px", // Adjust this value as needed to position the label
                                    },
                                  }}
                                />

                                {branchEncryptID === "21FC375" ? (
                                  <>
                                    <TextField
                                      fullWidth
                                      autoComplete="new-off"
                                      multiline
                                      className="theme-input mb-2"
                                      value={openUploadValues.color2 || ""}
                                      id="filled-basic-color2"
                                      onChange={handleUploadForm}
                                      name="color2"
                                      label="Color"
                                      variant="filled"
                                      InputProps={{
                                        style: {
                                          height: "40px", // Adjust this value as needed for height
                                          display: "flex",
                                          alignItems: "center", // Vertically center the text
                                        },
                                        classes: {
                                          input: "text-center-input", // Custom class for text alignment
                                        },
                                      }}
                                      InputLabelProps={{
                                        style: {
                                          top: "-8px", // Adjust this value as needed to position the label
                                        },
                                      }}
                                    />
                                    <TextField
                                      fullWidth
                                      autoComplete="new-off"
                                      multiline
                                      className="theme-input mb-2"
                                      value={
                                        openUploadValues.payment_mode2 || ""
                                      }
                                      id="filled-basic-payment-mode2"
                                      onChange={handleUploadForm}
                                      name="payment_mode2"
                                      label="Payment Mode"
                                      variant="filled"
                                      InputProps={{
                                        style: {
                                          height: "40px", // Adjust this value as needed for height
                                          display: "flex",
                                          alignItems: "center", // Vertically center the text
                                        },
                                        classes: {
                                          input: "text-center-input", // Custom class for text alignment
                                        },
                                      }}
                                      InputLabelProps={{
                                        style: {
                                          top: "-8px", // Adjust this value as needed to position the label
                                        },
                                      }}
                                    />
                                  </>
                                ) : null}

                                {openUploadValues.interest === "Yes" ? (
                                  <TextField
                                    fullWidth
                                    autoComplete="new-off"
                                    multiline
                                    className="theme-input mb-2"
                                    value={openUploadValues.payment2 || ""}
                                    id="filled-basic"
                                    onChange={handlePaymentForm}
                                    name="payment2"
                                    label="Payment"
                                    variant="filled"
                                    InputProps={{
                                      style: {
                                        height: "40px", // Adjust this value as needed for height
                                        display: "flex",
                                        alignItems: "center", // Vertically center the text
                                      },
                                      classes: {
                                        input: "text-center-input", // Custom class for text alignment
                                      },
                                    }}
                                    InputLabelProps={{
                                      style: {
                                        top: "-8px", // Adjust this value as needed to position the label
                                      },
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </>
                            )}
                            {index === 2 && (
                              <>
                                {/* <FormControl
                                  fullWidth
                                  variant="filled"
                                  className="theme-input mb-2"
                                  key={index}
                                >
                                  <InputLabel
                                    id={`demo-simple-select-filled-label-${index}`}
                                  >
                                    {branchEncryptID === "21FC375"
                                      ? "Modal"
                                      : "Sub Category"}
                                  </InputLabel>
                                  <Select
                                    labelId={`demo-simple-select-filled-label-${index}`}
                                    id={`demo-simple-select-filled-${index}`}
                                    value={openUploadValues.sub_category3 || ""}
                                    name="sub_category3"
                                    onChange={handleUploadForm}
                                    style={{ height: "60px" }}
                                  >
                                    {SubCategoryList.length > 0
                                      ? SubCategoryList.filter(
                                          (prop) => prop.category_id === item.id
                                        ).map((sc, k) => {
                                          return (
                                            <MenuItem
                                              key={k}
                                              value={sc.subcategory_name}
                                            >
                                              {sc.subcategory_name}
                                            </MenuItem>
                                          );
                                        })
                                      : null}
                                  </Select>
                                </FormControl> */}
                                <FormControl
                                  fullWidth
                                  variant="filled"
                                  className="theme-input mb-2"
                                  key={index}
                                >
                                  <InputLabel
                                    id={`demo-simple-select-filled-label-${index}`}
                                    style={{
                                      top: "-8px", // Adjust this value to position the label as needed
                                    }}
                                  >
                                    {branchEncryptID === "21FC375"
                                      ? "Modal"
                                      : "Sub Category"}
                                  </InputLabel>
                                  <Select
                                    labelId={`demo-simple-select-filled-label-${index}`}
                                    id={`demo-simple-select-filled-${index}`}
                                    value={openUploadValues.sub_category3 || ""}
                                    name="sub_category3"
                                    onChange={handleUploadForm}
                                    style={{
                                      height: "40px", // Adjust this value as needed for height
                                      display: "flex",
                                      alignItems: "center", // Vertically center the text
                                      textAlign: "center", // Center align the text horizontally
                                    }}
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight: 200, // Optional: limit the height of the dropdown menu
                                        },
                                      },
                                    }}
                                  >
                                    {SubCategoryList.length > 0
                                      ? SubCategoryList.filter(
                                          (prop) => prop.category_id === item.id
                                        ).map((sc, k) => {
                                          return (
                                            <MenuItem
                                              key={k}
                                              value={sc.subcategory_name}
                                            >
                                              {sc.subcategory_name}
                                            </MenuItem>
                                          );
                                        })
                                      : null}
                                  </Select>
                                </FormControl>

                                <TextField
                                  fullWidth
                                  multiline
                                  autoComplete="new-off"
                                  className="theme-input mb-2"
                                  value={openUploadValues.remarks3 || ""}
                                  id="filled-basic"
                                  name="remarks3"
                                  onChange={handleUploadForm}
                                  label="Remarks"
                                  variant="filled"
                                  InputProps={{
                                    style: {
                                      height: "40px", // Adjust this value as needed for height
                                      display: "flex",
                                      alignItems: "center", // Vertically center the text
                                    },
                                    classes: {
                                      input: "text-center-input", // Custom class for text alignment
                                    },
                                  }}
                                  InputLabelProps={{
                                    style: {
                                      top: "-8px", // Adjust this value as needed to position the label
                                    },
                                  }}
                                />

                                {branchEncryptID === "21FC375" ? (
                                  <>
                                    <TextField
                                      fullWidth
                                      autoComplete="new-off"
                                      multiline
                                      className="theme-input mb-2"
                                      value={openUploadValues.color3 || ""}
                                      id="filled-basic"
                                      onChange={handleUploadForm}
                                      name="color3"
                                      label="Color"
                                      variant="filled"
                                      InputProps={{
                                        style: {
                                          height: "40px", // Adjust this value as needed for height
                                          display: "flex",
                                          alignItems: "center", // Vertically center the text
                                        },
                                        classes: {
                                          input: "text-center-input", // Custom class for text alignment
                                        },
                                      }}
                                      InputLabelProps={{
                                        style: {
                                          top: "-8px", // Adjust this value as needed to position the label
                                        },
                                      }}
                                    />
                                    <TextField
                                      fullWidth
                                      autoComplete="new-off"
                                      multiline
                                      className="theme-input mb-2"
                                      value={
                                        openUploadValues.payment_mode3 || ""
                                      }
                                      id="filled-basic"
                                      onChange={handleUploadForm}
                                      name="payment_mode3"
                                      label="Payment Mode"
                                      variant="filled"
                                      InputProps={{
                                        style: {
                                          height: "40px", // Adjust this value as needed for height
                                          display: "flex",
                                          alignItems: "center", // Vertically center the text
                                        },
                                        classes: {
                                          input: "text-center-input", // Custom class for text alignment
                                        },
                                      }}
                                      InputLabelProps={{
                                        style: {
                                          top: "-8px", // Adjust this value as needed to position the label
                                        },
                                      }}
                                    />
                                  </>
                                ) : null}

                                {openUploadValues.interest === "Yes" ? (
                                  <TextField
                                    fullWidth
                                    autoComplete="new-off"
                                    multiline
                                    className="theme-input mb-2"
                                    value={openUploadValues.payment3 || ""}
                                    id="filled-basic"
                                    onChange={handlePaymentForm}
                                    name="payment3"
                                    label="Payment"
                                    variant="filled"
                                    InputProps={{
                                      style: {
                                        height: "40px", // Adjust this value as needed for height
                                        display: "flex",
                                        alignItems: "center", // Vertically center the text
                                      },
                                      classes: {
                                        input: "text-center-input", // Custom class for text alignment
                                      },
                                    }}
                                    InputLabelProps={{
                                      style: {
                                        top: "-8px", // Adjust this value as needed to position the label
                                      },
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              className="btn btn-danger"
              onClick={() => {
                setUploadedFile({});
                clearUploadModal();
              }}
              color="secondary"
            >
              Cancel
            </Button>
            {FormState === "step1" ? (
              <Button
                className="btn btn-success"
                onClick={() => ChangeStep("step2")}
                color="secondary"
              >
                Next
              </Button>
            ) : (
              <>
                <Button
                  className="btn btn-success"
                  onClick={() => {
                    ChangeStep("step1");
                    setUploadedFile1({});
                    setFetchImage1(false);

                    setUploadedFile2({});
                    setFetchImage2(false);

                    setFetchImage3(false);
                    setUploadedFile3({});
                  }}
                  color="secondary"
                >
                  Back
                </Button>
                {isPleaseWait === true ? (
                  <Button
                    className="btn btn-success"
                    disabled={true}
                    color="secondary"
                  >
                    Please Wait
                  </Button>
                ) : (
                  <Button
                    className="btn btn-success"
                    onClick={() => {
                      handleSubmitUploadFormMultiple();
                      setIsPleaseWait(true);
                    }}
                    color="secondary"
                  >
                    Continue
                  </Button>
                )}
              </>
            )}
          </DialogActions>
        </div>
      </Dialog>

      {/*Customer History and Design Option */}
      <Dialog
        open={customerDetails.showDetails}
        className="custom_modal"
        disableBackdropClick
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="md"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="dialog-border">
          <DialogContent>
            <div className="container-fluid customerDetails">
              <div className="row">
                <div className="col-md-3">
                  <p className="text-dark mb-3">{"Name"}</p>
                  <h4 className="text-dark">
                    {customerDetails.full_name || "N/A"}
                  </h4>
                </div>
                <div className="col-md-3">
                  <p className="text-dark mb-3">{"Mobile"}</p>
                  <h4 className="text-dark">
                    {customerDetails.mobile || "N/A"}
                  </h4>
                </div>
                <div className="col-md-3">
                  <p className="text-dark mb-3">{"Gender"}</p>
                  <h4 className="text-dark">
                    {customerDetails.gender || "N/A"}
                  </h4>
                </div>
                <div className="col-md-3">
                  <p className="text-dark mb-3">{"Date Of Birth"}</p>
                  <h4 className="text-dark">
                    {customerDetails.dob ||
                      Moment(customerDetails.dob).format("DD/MM/YYYY")}
                  </h4>
                </div>
              </div>
              <Divider className="my-3" />
              <div className="row">
                <div className="col-md-3">
                  <p className="text-dark mb-3">{"Date Of Anniversary"}</p>
                  <h4 className="text-dark">{customerDetails.doa || "N/A"}</h4>
                </div>
                <div className="col-md-3">
                  <p className="text-dark mb-3">{"Profession"}</p>
                  <h4 className="text-dark">
                    {customerDetails.profession || "N/A"}
                  </h4>
                </div>
                <div className="col-md-3">
                  <p className="text-dark mb-3">{"Address"}</p>
                  <h4 className="text-dark">
                    {customerDetails.address || "N/A"}
                  </h4>
                </div>
                <div className="col-md-3">
                  <p className="text-dark mb-3">{"Branch Name"}</p>
                  <h4 className="text-dark">
                    {customerDetails.branch_name || "N/A"}
                  </h4>
                </div>
              </div>
              <Divider className="my-3" />
              <div className="row">
                <div className="col-md-3">
                  <p className="text-dark mb-3">{"Staff Name"}</p>
                  <h4 className="text-dark">
                    {customerDetails.staff_name || "N/A"}
                  </h4>
                </div>
                <div className="col-md-3">
                  <p className="text-dark mb-3">{"Ref Name"}</p>
                  <h4 className="text-dark">
                    {customerDetails.ref_name || "N/A"}
                  </h4>
                </div>
                <div className="col-md-3">
                  <p className="text-dark mb-3">{"Category Name"}</p>
                  <h4 className="text-dark">
                    {customerDetails.category_name || "N/A"}
                  </h4>
                </div>
                <div className="col-md-3">
                  <p className="text-dark mb-3">{"Total Visit"}</p>
                  <h4 className="text-dark">
                    {customerDetails.total_visit || "N/A"}
                  </h4>
                </div>
              </div>
              <Divider className="my-3" />
              <div className="row">
                <div className="col-md-6">
                  <p className="text-dark mb-3">{"Last Visit"}</p>
                  <h4 className="text-dark ">
                    {customerDetails.last_visit || "N/A"}
                  </h4>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              className="btn btn-danger"
              onClick={() => {
                setCustomerDetails({
                  ...customerDetails,
                  full_name: "",
                  mobile: "",
                  total_visit: "",
                  last_visit: "",
                  gender: "",
                  dob: "",
                  doa: "",
                  ref_name: "",
                  category_name: "",
                  area_name: "",
                  staff_name: "",
                  profession: "",
                  address: "",
                  branch_name: "",
                  showDetails: false,
                });
              }}
              color="secondary"
            >
              Close
            </Button>
            <Button
              color="primary"
              onClick={() => {
                setCustomerHistory({ ...customerHistory, showHistory: true });
                setCustomerDetails({
                  ...customerDetails,
                  showDetails: false,
                });
              }}
              variant="contained"
              className="text-white"
              disableElevation
            >
              History
            </Button>
            <Button
              className="btn btn-success"
              onClick={() => {
                setCustomerHistory({ ...customerHistory, showDesign: true });
                setCustomerDetails({
                  ...customerDetails,
                  showDetails: false,
                });
              }}
              color="primary"
            >
              Design
            </Button>
          </DialogActions>
        </div>
      </Dialog>

      {/*Customer History Table */}
      <Dialog
        open={customerHistory.showHistory}
        className="custom_modal"
        disableBackdropClick
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="md"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="dialog-border">
          <DialogContent>
            <ReactDatatable
              config={config}
              records={HistoryList}
              columns={columns}
            />
          </DialogContent>
          <DialogActions>
            <Button
              className="btn btn-success"
              onClick={() => {
                setCustomerDetails({ ...customerDetails, showDetails: true });
                setCustomerHistory({
                  ...customerHistory,
                  showHistory: false,
                });
              }}
              color="secondary"
            >
              Back
            </Button>
            <Button
              className="btn btn-danger"
              onClick={() => {
                setCustomerHistory({
                  ...customerHistory,
                  showHistory: false,
                });
              }}
              color="secondary"
            >
              Close
            </Button>
          </DialogActions>
        </div>
      </Dialog>

      {/*Customer History Design */}
      <Dialog
        open={customerHistory.showDesign}
        className="custom_modal"
        disableBackdropClick
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="md"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="dialog-border">
          <DialogContent>
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(index) => setTabIndex(index)}
            >
              <TabList>
                <Tab>My Design</Tab>
                <Tab>Wishlist</Tab>
                <Tab>Exhibition</Tab>
              </TabList>
              <TabPanel>
                <div className="container-fluid">
                  {UploadHistoryList && UploadHistoryList.length !== 0 ? (
                    <div className="row">
                      <div className="col-md-6">
                        <AutoplaySlider
                          onTransitionEnd={(slide) =>
                            setSlideIndex(slide.currentIndex)
                          }
                          mobileTouch={true}
                          bullets={false}
                        >
                          {UploadHistoryList &&
                            UploadHistoryList.map((prop, i) => {
                              return (
                                <div
                                  data-src={
                                    `${API_BASE_URL}CustomerUploads/` +
                                    prop.image_path
                                  }
                                />
                              );
                            })}
                        </AutoplaySlider>
                      </div>
                      <div
                        className="col-md-6 mt-2"
                        style={{ overflowY: "auto" }}
                      >
                        <Card
                          sx={{ minWidth: "290px", maxHeight: "290px" }}
                          style={{ overflowY: "auto" }}
                        >
                          <CardContent>
                            <Row>
                              <Col>
                                <label style={{ fontSize: "13px" }}>
                                  <b>Product Category</b>
                                </label>
                                <p>
                                  {" "}
                                  {UploadHistoryList[slideIndex].category ||
                                    "N/A"}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <label style={{ fontSize: "13px" }}>
                                  <b>Interest</b>
                                </label>
                                <p>
                                  {UploadHistoryList[slideIndex].interest ||
                                    "N/A"}
                                </p>
                              </Col>
                              <Col>
                                <label style={{ fontSize: "13px" }}>
                                  <b>Staff-Name</b>
                                </label>
                                <p>
                                  {UploadHistoryList[slideIndex].staff_name ||
                                    "N/A"}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <label style={{ fontSize: "13px" }}>
                                  <b>Product Code</b>
                                </label>
                                <p>
                                  {UploadHistoryList[slideIndex].hasOwnProperty(
                                    "sku"
                                  )
                                    ? UploadHistoryList[slideIndex].sku
                                    : "N/A"}
                                </p>
                              </Col>
                              <Col>
                                <label style={{ fontSize: "13px" }}>
                                  <b>Product Name</b>
                                </label>
                                <p>N/A</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <label style={{ fontSize: "13px" }}>
                                  <b>Remark</b>
                                </label>
                              </Col>
                              <Col>
                                <textarea
                                  style={{ overflow: "auto" }}
                                  cols="30"
                                  rows="3"
                                  value={
                                    UploadHistoryList[
                                      slideIndex
                                    ].hasOwnProperty("remarks")
                                      ? UploadHistoryList[slideIndex].remarks
                                      : "N/A"
                                  }
                                ></textarea>
                              </Col>
                            </Row>
                          </CardContent>
                        </Card>
                      </div>
                    </div>
                  ) : (
                    <>
                      <h3>No Image</h3>
                    </>
                  )}
                </div>
              </TabPanel>
              <TabPanel>
                <div className="container-fluid">
                  {WishlistHistoryList && WishlistHistoryList.length !== 0 ? (
                    <div className="row">
                      <div className="col-md-6">
                        <AutoplaySlider
                          onTransitionEnd={(slide) =>
                            setSlideIndexWishlist(slide.currentIndex)
                          }
                          mobileTouch={true}
                          bullets={false}
                        >
                          {WishlistHistoryList &&
                            WishlistHistoryList.map((prop, i) => {
                              return (
                                <div
                                  data-src={
                                    `${API_BASE_URL}Images/` + prop.image_path
                                  }
                                />
                              );
                            })}
                        </AutoplaySlider>
                      </div>
                      <div
                        className="col-md-6 mt-2"
                        style={{ overflowY: "auto" }}
                      >
                        <Card
                          sx={{ minWidth: "290px", maxHeight: "290px" }}
                          style={{ overflowY: "auto" }}
                        >
                          <CardContent>
                            <Row>
                              <Col>
                                <label style={{ fontSize: "13px" }}>
                                  <b>Product Category</b>
                                </label>
                                <p>
                                  {" "}
                                  {WishlistHistoryList[slideIndexWishlist]
                                    .category || "N/A"}
                                </p>
                              </Col>

                              <Col>
                                <label style={{ fontSize: "13px" }}>
                                  <b>Date</b>
                                </label>
                                <p>
                                  {WishlistHistoryList[slideIndexWishlist]
                                    .date || "N/A"}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <label style={{ fontSize: "13px" }}>
                                  <b>Interest</b>
                                </label>
                                <p>
                                  {WishlistHistoryList[slideIndexWishlist]
                                    .interest || "N/A"}
                                </p>
                              </Col>
                              <Col>
                                <label style={{ fontSize: "13px" }}>
                                  <b>Staff-Name</b>
                                </label>
                                <p>
                                  {WishlistHistoryList[slideIndexWishlist] ||
                                    "N/A"}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <label style={{ fontSize: "13px" }}>
                                  <b>Product Code</b>
                                </label>
                                <p>
                                  {UploadHistoryList[slideIndex].hasOwnProperty(
                                    "sku"
                                  )
                                    ? WishlistHistoryList[slideIndexWishlist]
                                        .sku
                                    : "N/A"}
                                </p>
                              </Col>
                              <Col>
                                <label style={{ fontSize: "13px" }}>
                                  <b>Product Name</b>
                                </label>
                                <p>N/A</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <label style={{ fontSize: "13px" }}>
                                  <b>Remark</b>
                                </label>
                              </Col>
                              <Col>
                                <textarea
                                  style={{ overflow: "auto" }}
                                  cols="30"
                                  rows="3"
                                  value={
                                    WishlistHistoryList[
                                      slideIndexWishlist
                                    ].hasOwnProperty("remarks")
                                      ? WishlistHistoryList[slideIndexWishlist]
                                          .remarks
                                      : "N/A"
                                  }
                                ></textarea>
                              </Col>
                            </Row>
                          </CardContent>
                        </Card>
                      </div>
                    </div>
                  ) : (
                    <>
                      <h3>No Image</h3>
                    </>
                  )}
                </div>
              </TabPanel>
              <TabPanel>
                <div className="container-fluid">
                  {ExhibitionHistoryList &&
                  ExhibitionHistoryList.length !== 0 ? (
                    <div className="row">
                      <div className="col-md-6">
                        <AutoplaySlider
                          onTransitionEnd={(slide) =>
                            setSlideIndexExhibition(slide.currentIndex)
                          }
                          mobileTouch={true}
                          bullets={false}
                        >
                          {ExhibitionHistoryList &&
                            ExhibitionHistoryList.map((prop, i) => {
                              return (
                                <div
                                  data-src={
                                    `${API_BASE_URL}Images/` + prop.image_path
                                  }
                                />
                              );
                            })}
                        </AutoplaySlider>
                      </div>
                      <div className="col-md-3" style={{ overflowY: "auto" }}>
                        <Card
                          sx={{ minWidth: "290px", maxHeight: "290px" }}
                          style={{ overflowY: "auto" }}
                        >
                          <CardContent>
                            <Row>
                              <Col>
                                <label style={{ fontSize: "13px" }}>
                                  <b>Date</b>
                                </label>
                                <p>
                                  {ExhibitionHistoryList[slideIndexExhibition]
                                    .date || "N/A"}
                                </p>
                              </Col>
                              <Col>
                                <label style={{ fontSize: "13px" }}>
                                  <b>Product Category</b>
                                </label>
                                <p>
                                  {" "}
                                  {ExhibitionHistoryList[slideIndexExhibition]
                                    .category || "N/A"}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <label style={{ fontSize: "13px" }}>
                                  <b>Interest</b>
                                </label>
                                <p>
                                  {ExhibitionHistoryList[slideIndexExhibition]
                                    .interest || "N/A"}
                                </p>
                              </Col>
                              <Col>
                                <label style={{ fontSize: "13px" }}>
                                  <b>Staff-Name</b>
                                </label>
                                <p>
                                  {ExhibitionHistoryList[
                                    slideIndexExhibition
                                  ] || "N/A"}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <label style={{ fontSize: "13px" }}>
                                  <b>Product Code</b>
                                </label>
                                <p>
                                  {ExhibitionHistoryList[
                                    slideIndexExhibition
                                  ].hasOwnProperty("sku")
                                    ? ExhibitionHistoryList[
                                        slideIndexExhibition
                                      ].product_code
                                    : "N/A"}
                                </p>
                              </Col>
                              <Col>
                                <label style={{ fontSize: "13px" }}>
                                  <b>Product Name</b>
                                </label>
                                <p>N/A</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <label style={{ fontSize: "13px" }}>
                                  <b>Remark</b>
                                </label>
                              </Col>
                              <Col>
                                <textarea
                                  style={{ overflow: "auto" }}
                                  cols="30"
                                  rows="3"
                                  value={
                                    ExhibitionHistoryList[
                                      slideIndexExhibition
                                    ].hasOwnProperty("remarks")
                                      ? ExhibitionHistoryList[
                                          slideIndexExhibition
                                        ].remarks
                                      : "N/A"
                                  }
                                ></textarea>
                              </Col>
                            </Row>
                          </CardContent>
                        </Card>
                      </div>
                    </div>
                  ) : (
                    <>
                      <h3>No Image</h3>
                    </>
                  )}
                </div>
              </TabPanel>
            </Tabs>
          </DialogContent>
          <DialogActions>
            <Button
              className="btn btn-success"
              onClick={() => {
                setCustomerDetails({ ...customerDetails, showDetails: true });
                setCustomerHistory({ ...customerHistory, showDesign: false });
              }}
              color="secondary"
            >
              Back
            </Button>
            <Button
              className="btn btn-danger"
              onClick={() => {
                setCustomerHistory({ ...customerHistory, showDesign: false });
              }}
              color="secondary"
            >
              Close
            </Button>
          </DialogActions>
        </div>
      </Dialog>

      {/* --------------------------- */}

      {/*Customer New Area Add  */}
      <Dialog
        open={areaValues.area}
        className="custom_modal"
        disableBackdropClick
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="md"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="dialog-border">
          <DialogContent>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    autoComplete="new-off"
                    fullWidth
                    className="theme-input mb-3"
                    value={areaValues.area_name || ""}
                    onChange={(e) =>
                      setAreaValues({
                        ...areaValues,
                        area_name: e.target.value,
                      })
                    }
                    autoFocus
                    name="area_name"
                    id="filled-basic"
                    label="Area Name"
                    variant="filled"
                    InputProps={{
                      style: {
                        height: "40px", // Adjust this value as needed
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              className="btn btn-danger"
              onClick={() => {
                setAreaValues({ ...areaValues, area: false, area_name: "" });
              }}
              color="secondary"
            >
              Close
            </Button>
            <Button
              className="btn-btn-sucess"
              onClick={handleSubmitAreaForm}
              color="secondary"
            >
              Save
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default TabToScan;
