import { API_BASE_URL } from "./constant";
import axios from "axios";

export function authenUser(url, data) {
  var login = "grant_type=password&username=" + data.user_name + "&password=" + data.otp;
  console.log(login);
  return new Promise((resolve, reject) => {
    fetch(API_BASE_URL + url, {
      method: "post",
      // mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: "same-origin", // include, *same-origin, omit
      headers: {
        Accept: "application/text",
        "Content-Type": "application/x-www-form-urlencoded",

        // "Authorization": "Bearer " + authToken
        // "Access-Control-Allow-Origin": '*'
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      body: login,
    })
      .then((response) => response.json())
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function postMethod(url, data) {
   return await fetch(API_BASE_URL + url, {
    method: "post",
    headers: {
      Accept: "application/json",
           "Content-type": "application/json",
      "auth-token": localStorage.getItem("jwl_token"),
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (response.status === 401 || response.status === 400) {
        localStorage.clear();
        window.location = "/login";
        return null;
      } else {
        return response.json();
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function validateMobile(url, data) {
  return new Promise((resolve, reject) => {
    fetch(API_BASE_URL + url, {
      method: "post",
      headers: {
        Accept: "application/json",
             "Content-type": "application/json",
      "auth-token": localStorage.getItem("jwl_token"),
      },
      body: JSON.stringify({
        mobile: data,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function singleParam(url, _param, data) {
  return new Promise((resolve, reject) => {
    fetch(API_BASE_URL + url, {
      method: "post",
      headers: {
        Accept: "application/json",
             "Content-type": "application/json",
      "auth-token": localStorage.getItem("jwl_token"),
      },
      body: JSON.stringify({
        _param: data,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function editUserGrid(url, data) {
  return new Promise((resolve, reject) => {
    fetch(API_BASE_URL + url, {
      method: "post",
      headers: {
        Accept: "application/json",
             "Content-type": "application/json",
      "auth-token": localStorage.getItem("jwl_token"),
      },
      body: JSON.stringify({
        tran_id: data,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        console.log("err");
      });
  });
}
export function editProductGrid(url, data) {
  return new Promise((resolve, reject) => {
    fetch(API_BASE_URL + url, {
      method: "post",
      headers: {
        Accept: "application/json",
             "Content-type": "application/json",
      "auth-token": localStorage.getItem("jwl_token"),
      },
      body: JSON.stringify({
        product_id: data,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function ProductUploadImage(url, data) {
  return new Promise((resolve, reject) => {
    fetch(API_BASE_URL + url, {
      method: "post",
      headers: {
        "Content-type": "application/json",
        "auth-token": localStorage.getItem("jwl_token")
      },
      body: JSON.stringify({
        product_id: data,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export async function postDirectMethod(url) {
  return await fetch(url, {
   method: "GET",
   headers: {
    "content-type": "application/json",
  },
  crossDomain: true,
 })
   .then((response) => {     
       return response.json();
   })
   .catch((error) => {
     console.log(error);
   });
}




export function testingaxios(apiEndpoint, payload) { 
  return axios
    .get(apiEndpoint, payload, getOptions())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      if (err.response != undefined && err.response.status == 401) {
        // window.open('/','_self');
      } else {
        //alert('operation not able to perform');
      }
      return err;
    });
}

function getOptions() {
  var options = {};
  options.headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };
}