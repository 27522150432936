import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useLocation } from "react-router-dom";
import { Button, Form, FormGroup, Label, FormText, Row, Col, Alert } from "reactstrap";
import { API_BASE_URL } from "../../../../_services/constant";
import { Card } from "@material-ui/core";
import { post } from "axios";

import {
  postMethod,
  validateMobile,
  singleParam,
  editUserGrid,
} from "../../../../_services/_postMethod";
import Loader from "../../../modules/loader";
import { CompassCalibrationOutlined } from "@material-ui/icons";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AddCompany = (props) => {
  let queryParams = useQuery();
  const id = queryParams.get("id") == "" ? null : queryParams.get("id");
  const { register, handleSubmit, errors } = useForm();
  const [load, setLoad] = useState(false);
  const [loadInser, setLoadInsert] = useState(false);
  const [loadDelete, setLoadDelete] = useState(false);
  const [logoValidation, setLogoValidation] = useState(false);
  const [param, setParam] = useState({
    company_id: id == null ? "0" : id,
    company_name: "",
    address: "",
    city: "",
    pin: "",
    state: "",
    country: "",
    no_of_branch: "",
    disable: false,
    logo: "",
  });
  const [success, setSuccess] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);



  const insertData = (values) => {
    let file = document.getElementById("uploadImage");
    let files = file.files;
    let formData = new FormData();
     if(param.logo !="" || files.length > 0)
     {
    setLoadInsert(true);
    if (files.length > 0) {
      for (var j = 0; j < files.length; j++) {       
        let file_name = "image-" + createGuid();
        param.logo = file_name+""+files[j].name.substring(files[j].name.lastIndexOf("."));     
        formData.append("files", files[j], file_name);           
      }
    }
    postMethod("api/companys/company/insert", param).then((dataD) => {  
       var data = dataD.data[0];  
      if (data.valid) {
        if (files.length > 0) {
          uploadImageFunc();
        } else {
          setSuccess(true);
          setTimeout(() => {
            props.history.push("/admin/company"); 
          }, 2000);
        }
      }
    });
     }
     else{
     setLogoValidation(true);
     }
  };

  const handleDelete = () => {
    var proceed = window.confirm("Do you Really Wish to Delete this Entry..?!");

    if (proceed) {
        setLoadDelete(true);
      postMethod("api/companys/company/delete", { company_id: param.company_id }).then((dataD) => {
         var data = dataD.data[0];
        if (data.valid) {
          setSuccessDelete(true);
          setTimeout(() => {
            props.history.push("/admin/company");
          }, 2000);
        }
        else{
           setLoadDelete(false);
        }
      });
    }
  };

  function createGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  const uploadImageFunc = () => {
        let file = document.getElementById("uploadImage");
    let files = file.files;
    let formData = new FormData();

    if (files.length > 0) {
      for (var j = 0; j < files.length; j++) {     
      formData.append("files", files[j], param.logo.split(".")[0]);
      }
    }
    const url = API_BASE_URL + `api/companys/company/uploadImage`;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "auth-token": localStorage.getItem("jwl_token"),
      },
    };

    post(url, formData, config).then(
      (response) => {
        setSuccess(true);
        setTimeout(() => {
          props.history.push("/admin/company");
        }, 2000);
      },
      (error) => {
        setSuccess(true);
         setLoadInsert(false);
      }
    );
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        marginLeft: -10,
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 310,
      },
    },
  };

  useEffect(() => {
    if (id !== null) {
      setLoad(true);     
      var temp = window.atob(id);
      //console.log(temp);
      postMethod("api/companys/company/preview", { company_id: temp }).then((dataD) => {
        var data = dataD.data[0];
        setParam({
          ...param,
          company_id: data.company_id,
          company_name: data.company_name,
          address: data.address,
          city: data.city,
          pin: data.pin,
          state: data.state,
          country: data.country,
          no_of_branch: data.no_of_branch,
          logo: data.logo,
          disable: data.disable
        });
        setLoad(false);
      });
    }
 
  }, []);
  return (
    <>
      {load ? <Loader/> : null}
      <Form onSubmit={handleSubmit(insertData)}>
        <input
          type="hidden"
          name="company_id"
          value={id == null ? "0" : param.company_id}
          ref={register}
        />
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Add Company</h3>
            </div>
          </div>
          <div className="card-body">
            <Row>
              <Col sm={4}>
                <FormGroup>
                  <Label>Company Name</Label>
                  <input
                    type="text"
                    required
                    value={param.company_name}
                    className="form-control h-auto py-2 px-6"
                    name="company_name"
                    onChange={(e) => setParam({ ...param, company_name: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label>Address</Label>
                  <input
                    type="text"
                    required
                    value={param.address}
                    className="form-control h-auto py-2 px-6"
                    name="address"
                    onChange={(e) => setParam({ ...param, address: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>
              

              <Col sm={4}>
                <FormGroup>
                  <Label>City</Label>
                  <input
                    type="text"
                    required
                    value={param.city}
                    className="form-control h-auto py-2 px-6"
                    name="city"
                    onChange={(e) => setParam({ ...param, city: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>


              <Col sm={4}>
                <FormGroup>
                  <Label>Pin</Label> 
                  <input
                    type="number"
                    required
                    value={param.pin}
                    className="form-control h-auto py-2 px-6"
                    name="pin"
                    onChange={(e) => setParam({ ...param, pin: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>



              <Col sm={4}>
                <FormGroup>
                  <Label>State</Label>
                  <input
                    type="text"
                    required
                    value={param.state}
                    className="form-control h-auto py-2 px-6"
                    name="state"
                    onChange={(e) => setParam({ ...param, state: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>



              <Col sm={4}>
                <FormGroup>
                  <Label>Country</Label>
                  <input
                    type="text"
                    required
                    value={param.country}
                    className="form-control h-auto py-2 px-6"
                    name="country"
                    onChange={(e) => setParam({ ...param, country: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>



              <Col sm={4}>
                <FormGroup>
                  <Label>No of branch</Label>
                  <input
                    type="number"
                    required
                    value={param.no_of_branch}
                    className="form-control h-auto py-2 px-6"
                    name="no_of_branch"
                    onChange={(e) => setParam({ ...param, no_of_branch: e.target.value })}
                    ref={register}
                  />
                </FormGroup>
              </Col>

             

              <Col sm={4} className="d-flex">
                <Card body className="col-md-12">
                  <Label>
                  Logo <br />
                  { (param.logo != "" && param.logo !=undefined) ? 
                    <img id="companylogo"
                    width="100"
                    src={API_BASE_URL + "companylogo/" + param.logo}
                  ></img> : null    }
                    <input
                      id="uploadImage"
                      type="file"
                      multiple={true}
                      accept="image/*"
                      name="Choose Image"
                      onChange={(e)=>  {
                        var reader = new FileReader();
                        reader.onload = function (e) {
                          setParam({ ...param, logo: e.target.result})
                          document.getElementById('companylogo').src=""+e.target.result+""; 
                        
                        }
                        reader.readAsDataURL(e.target.files[0]);
                        setLogoValidation(false);
                      }}
                    />
                  </Label>
                
                </Card>
              </Col>
              <Col sm={1}>
                <FormGroup>
                  <Label>Disable</Label>
                  <input
                    type="checkbox"
                    checked={param.disable}
                    className="form-control h-auto py-2 px-6"
                    name="disable"
                    onChange={(e) => setParam({ ...param, disable: !param.disable })}
                    ref={register}
                  />
                </FormGroup>
              </Col>
            </Row>
            {success ? <Alert color="success">Saved Successfully</Alert> : null}
            {successDelete ? <Alert color="danger">Deleted</Alert> : null}
          </div>
          <div className="card-footer text-right">
            <a href="/admin/company" className="btn btn-secondary mr-2">
              Cancel
            </a>
         
             {id && (
              loadDelete ? <button type="button" className="btn btn-danger mr-2">
                Deleting..
              </button> : 
               <button type="button" onClick={handleDelete} className="btn btn-danger mr-2">
                Delete
              </button>
            )}
          
            
            {loadInser ? 
             <button type="button" className="btn btn-primary ">
              Saving..
            </button> :  <button type="submit" className="btn btn-primary ">
              Save
            </button>}
           
          </div>
        </div>
      </Form>
    </>
  );
};

export default AddCompany;
