import React, { useEffect, useState } from "react";
import CustomerHeader from "./components/header";
import CustomerFooter from "./components/footer";
import { useParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { postMethod } from "../../../_services/_postMethod";
import { API_BASE_URL } from "../../../_services/constant";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { findAllByDisplayValue } from "@testing-library/react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import NumberFormat from "react-number-format";

import CustomerVoucherComList from "./components/voucherList";
import CustomertermsAndCondition from "./components/termsAndCondition";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#000",
    backgroundColor: "#fff",
  },
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Referral(props) {
  let queryParams = useQuery();
  const classes = useStyles();
  const [mobileVerification, setMobileVerification] = useState(false); 
  const [mobileVerificationMsg, setMobileVerificationMsg] = useState(false); 
  const [open, setOpen] = useState(false);
  const [checkedTerms, setcheckedTerms] = useState({
    check: false,
    comment: false,
  });
  const [mobileVal, setmobileVal] = useState(false);
  const id = queryParams.get("id") == "0" ? null : queryParams.get("id");
  const { register, handleSubmit, errors } = useForm();
  const { buttonLabel, className } = props;

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [param, setParam] = useState({
    tran_id: "0",
    dob: "",
    voucher_id: id,
    disable: false,
    banner_image: "",
    voucher_value: "",
    voucher_heading: "",
    start_date: "",
    end_date: "",
    status: "",
    redeem_end_date:"",
    customer_redeemed_date:""
  });
  const bull = <span className={classes.bullet}>•</span>;

  const handleToggle = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    setOpen(true);
    postMethod("api/session/VoucherDetails", { voucher_id: id }).then(
      (dataD) => {
        var data = dataD.data[0];
        setParam({
          ...param,
          tran_id: "0",
          banner_image: data.banner_image,
          voucher_value: data.voucher_value,
          voucher_heading: data.voucher_heading,
          start_date:
            data.start_date == null
              ? ""
              : data.start_date == ""
              ? ""
              : data.start_date.split("T")[0],
          end_date:
            data.end_date == null
              ? ""
              : data.end_date == ""
              ? ""
              : data.end_date.split("T")[0],
          status: data.status,
          redeem_end_date: data.redeem_end_date,
          customer_redeemed_date: data.customer_redeemed_date
        });
        setOpen(false);
      }
    );
  }, []);

  const mobileVarificationFun = (e) =>{
    setMobileVerificationMsg(false);
    setMobileVerification(false);
    var refMobile = e.value
    if (refMobile.length == 10) {
      var ParamValue = {
        mobile: refMobile,
      };
      postMethod("api/session/refVoucherMobileVerification", ParamValue).then(
        (data) => {
         if(data.status == "true"){
          setMobileVerification(true);
         }
         else{
              setMobileVerificationMsg(true);
         }
        }
      );
    }    
  }

  const insert = (values) => {
    var mobile = values.mobile
      .trim()
      .replace("+91", "")
      .replace(/ /g, "")
      .replace(/_/g, "");
    values.mobile = mobile;

    if (mobile.length != 10) {
      setmobileVal(true);
    } else if (!checkedTerms.check) {
      setcheckedTerms({ ...checkedTerms, comment: true });
    } else {
      var ParamValue = {
        tran_id: "0",
        voucher_id: id,
        mobile: mobile,
      };
      postMethod("api/session/InsertReferralVoucher", ParamValue).then(
        (data) => {
          toggle();
        }
      );
    }
  };

  return (
    <>
      <div>
        <Modal isOpen={modal} backdrop="static" centered="true">
          <ModalBody>
            <div className="text-center">
              <img
                onClick={() => (window.location.pathname = "customersession/home")}
                src={`${API_BASE_URL}BranchLogo/${localStorage.getItem(
                  "jwl_logo"
                )}`}
                className="img-fluid mlogo"
              />
              <h3 className="m-4">Thank you for Referral.</h3>
              <div className="sbmt sbmt1 w-100">
                <input
                  type="button"
                  value="Home"
                  onClick={() => {
                    window.location.pathname = "customersession/home";
                  }}
                  className="sbmtbtn1"
                />
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>

      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CustomerHeader />
      <div className="clr"></div>
      <div className="container toppadhead">
        <div className="row toppad5 mtoppad2">
          <div className="col-sm-12">
            <div>
              <img
                src={
                  API_BASE_URL + "CustomerVoucherBanner/" + param.banner_image
                }
                className="d-none d-lg-block d-md-block imgcurve custom-banner"
              />
              <img
                src={
                  API_BASE_URL + "CustomerVoucherBanner/" + param.banner_image
                }
                className="d-none d-lg-none d-md-none d-block imgcurve custom-banner"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="clr"></div>
      <div className="container-fluid">
      <div className="container" style={{padding:window.screen.width>480?30:15, paddingTop:window.screen.width>480?30:0}}>
          <div className="row ">
            <div className="col-12 col-sm-8 col-lg-8 p-0 ">
              <div className="row">
                <div className="col-12 col-sm-11 col-lg-11">
                  <div className="voucher-box">
                    <div className="item clearfix">
                      <span className="circle-top"></span>
                      <span className="circle-bottom" style={{left:window.screen.width>480?131:116}}></span>

                      <div className="item-left" style={{width:window.screen.width>480?138:124}}>
                        <div className="price-style">
                          {/* <span className="price-desc"> ₹ </span> */}
                          <span className="price" style={{fontSize:window.screen.width>480?15:12, marginLeft:'-10px'}}> {param.voucher_value}</span>
                          <span className="price-unit"></span>
                          <span className="price-off"></span>
                        </div>
                      </div>
                      <div className="item-right" style={{width:window.screen.width>480?400:150}}>
                        <p className="p-one" style={{fontSize:window.screen.width>480?16:13}}>{param.voucher_heading}</p>
                        <p className="p-two">
                          {" "}
                          <i className="iconfont icontime"></i>
                          {moment(param.start_date).format(
                            "MMM Do YYYY"
                          )} - {moment(param.end_date).format("MMM Do YYYY")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <form onSubmit={handleSubmit(insert)}>
                  <div className="col-12 col-sm-12 col-lg-12 toppad11">
                    <div className="subs subs1 mb-3">
                      <NumberFormat
                        getInputRef={register}
                        name="mobile"
                        onValueChange={(e) => {
                          setmobileVal(false);
                          mobileVarificationFun(e);
                        }}
                        format="+91 ### ### ####"
                        allowEmptyFormatting
                        mask="_"
                      />

                      {mobileVal ? (
                        <p style={{ color: "red" }}>Incorrect mobile no.</p>
                      ) : null}
                       {mobileVerificationMsg ? (
                        <p style={{ color: "red" }}>This Mobile Number is already register.</p>
                      ) : null}

                      <span className="highlight"></span>
                      <span className="bar bar1"></span>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-lg-12 mtoppad2">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={checkedTerms.check}
                        checked={checkedTerms.check}
                        id="flexCheckDefault"
                        onClick={(e) => {
                          setcheckedTerms({
                            ...checkedTerms,
                            check: !checkedTerms.check,
                            comment: false,
                          });
                        }}
                      />
                      <label
                      style={{paddingLeft:5}}
                        className="form-check-label"
                        for="flexCheckDefault"
                      >
                        Terms &amp; Conditions apply
                      </label>
                    </div>
                    {checkedTerms.comment ? (
                      <label
                        className="form-check-label color-red"
                        for="flexCheckDefault"
                      >
                        Please check Terms &amp; Conditions apply
                      </label>
                    ) : null}
                  </div>
                  <div className="col-12 col-sm-3 col-lg-12 toppad4">                 
                    <div className="sbmt sbmt1">        
                    {mobileVerification ? <input type="submit" value="Get" className="sbmtbtn1" /> : <input type="button" value="Get" disabled={true} className="sbmtbtn1" />}             
                        
                      
                    </div>
                  </div>
                </form>
                <CustomertermsAndCondition id={id} />
              </div>
            </div>
            <div className="d-none d-md-block col-sm-12 col-md-4 secbdr "style={{marginTop:10}}>
              <h1>Other Offers</h1>
              <CustomerVoucherComList voucher_id={id} />
            </div>
            
          </div>
        </div>
        <div className="d-block d-md-none col-sm-12 col-md-4 secbdr " style={{marginTop:10}}>
              <h1>Other Offers</h1>
              <CustomerVoucherComList voucher_id={id} />
            </div>
      </div>
      <div className="clr"></div>
      <div className="container-fluid"></div>
      <div className="d-none d-lg-block d-print-block secbdr">
        <CustomerFooter />
      </div>
    </>
  );
}
