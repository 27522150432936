import React, { useEffect, useState } from "react";
import CustomerHeader from "./components/header";
import CustomerFooter from "./components/footer";
import { useParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { postMethod } from "../../../_services/_postMethod";
import { API_BASE_URL } from "../../../_services/constant";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { findAllByDisplayValue } from "@testing-library/react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import NumberFormat from "react-number-format";
import { post } from "axios";
import CustomerVoucherComList from "./components/voucherList";
import { Paper, Snackbar } from "@material-ui/core";
import stepsInfo from "../../../_assets/media/home/4steps.jpg";
import stepsInfoMob from "../../../_assets/media/home/4steps-m.jpg";
import CustomertermsAndCondition from "./components/termsAndCondition";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#000",
    backgroundColor: "#fff",
  },
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Uploaddesignvoucher(props) {
  let queryParams = useQuery();
  const classes = useStyles();
  const [load, setLoad] = useState("false");
  const [open, setOpen] = useState(false);
  const [checkedTerms, setcheckedTerms] = useState({
    check: false,
    comment: false,
  });

  const id = queryParams.get("id") == "0" ? null : queryParams.get("id");
  const { register, handleSubmit, errors } = useForm();
  const { buttonLabel, className } = props;

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [modalInfo, setModalInfo] = useState(false);
  const toggleInfo = () => setModalInfo(!modalInfo);

  const [ImagePath, setImagePath] = useState("");
  const [paramImage, setParamImage] = useState({
    tran_id: "0",
    customer_id: "",
    remarks: "",
    image_path: "",
  });
  const [param, setParam] = useState({
    tran_id: "0",
    voucher_id: id,
    disable: false,
    banner_image: "",
    voucher_value: "",
    voucher_heading: "",
    start_date: "",
    end_date: "",
    status: "",
    redeem_end_date:"",
    customer_redeemed_date:"",
    remarks:""
  });
  const bull = <span className={classes.bullet}>•</span>;

  useEffect(() => {
    setOpen(true);
    postMethod("api/session/VoucherDetails", {
      voucher_id: param.voucher_id,
    }).then((dataD) => {
      var data = dataD.data[0];
      setParam({
        ...param,
        tran_id: "0",
        banner_image: data.banner_image,
        voucher_value: data.voucher_value,
        voucher_heading: data.voucher_heading,
        start_date:
          data.start_date == null
            ? ""
            : data.start_date == ""
            ? ""
            : data.start_date.split("T")[0],
        end_date:
          data.end_date == null
            ? ""
            : data.end_date == ""
            ? ""
            : data.end_date.split("T")[0],
        status: data.status,
        redeem_end_date: data.redeem_end_date,
        customer_redeemed_date: data.customer_redeemed_date
      });
      setOpen(false);
    });
  }, []);

  const [fileUploadValidation, setFileUploadValidation] = React.useState(false);
  const handleClickWish = () => {
    setFileUploadValidation(true);
  };

  const handleCloseWish = () => {
    setFileUploadValidation(false);
  };

  const insertData = (values) => {
    let file = document.getElementById("iduploaddesign");
    let files = file.files;
    let formData = new FormData();

    if (!checkedTerms.check) {
      setcheckedTerms({ ...checkedTerms, comment: true });
      setLoad("false");
    }
     else if(files.length == 0){
      handleClickWish();
      setLoad("false");
    }
    else if(param.remarks ==""){
      handleClickWish();
      setLoad("false");
    }
    else{    

      if (files.length > 0) {
        for (var j = 0; j < files.length; j++) {
          let file_name = "image-" + createGuid();
          // values.image_path = file_name+""+files[j].name.substring(files[j].name.lastIndexOf("."));
          param.image_path =
            file_name +
            "" +
            files[j].name.substring(files[j].name.lastIndexOf("."));
          formData.append("files", files[j], file_name);
        }
      }
      postMethod("api/session/voucherDesign/insert", param).then((data) => {
        uploadImageFunc(formData);
      });
    }
  };
  const uploadImageFunc = (formData) => {
    const url = API_BASE_URL + `api/masters/customer/session/upload/design`;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "auth-token": localStorage.getItem("jwl_token"),
      },
    };

    post(url, formData, config).then(
      (response) => {
        setImagePath("");
        document.getElementById("designForm").reset();
        //insert();
        toggle();
        setLoad("false"); 
      },
      (error) => {
        setLoad("false"); 
      }
    );
  };
  function createGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  return (
    <>
      <div>
        <Modal isOpen={modal} backdrop="static" centered="true">
          <ModalBody>
            <div className="text-center">
              <img
                onClick={() => (window.location.pathname = "general/home")}
                src={`${API_BASE_URL}BranchLogo/${localStorage.getItem(
                  "jwl_logo"
                )}`}
                className="img-fluid mlogo"
              />
              <h3 className="m-4">Thank you for uploading your design .</h3>
              <div className="sbmt sbmt1 w-100">
                <input
                  type="button"
                  value="Home"
                  onClick={() => {
                    window.location.pathname = "general/home";
                  }}
                  className="sbmtbtn1"
                />
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={modalInfo}
          backdrop="static"
          centered="true"
          size="lg"
          className={className}
        >
          <ModalBody>
            <i
              className="fa fa-close protxt pull-right text-dark"
              toggle={toggle}
              aria-hidden="true"
              onClick={() => {
                toggleInfo();
              }}
            ></i>
            <img
              src={stepsInfo}
              style={{objectFit:"contain"}}
              className=" d-none d-lg-block d-md-block imgcurve custom-banner"
            />
            <img
              src={stepsInfoMob}
              style={{objectFit:"contain", height:255, width:"100%"}}
              className=" d-none d-lg-none d-md-none d-block imgcurve "
            />
          </ModalBody>
        </Modal>
      </div>

      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CustomerHeader />
      <div className="clr"></div>
      <div className="container toppadhead">
        <div className="row toppad5 mtoppad2">
          <div className="col-sm-12">
            <div>
              <img
                src={
                  API_BASE_URL + "CustomerVoucherBanner/" + param.banner_image
                }
                className=" d-none d-lg-block d-md-block imgcurve custom-banner"
              />
              <img
                src={API_BASE_URL + "CustomerVoucherBanner/" + param.banner_image}
                className=" d-none d-lg-none d-md-none d-block imgcurve custom-banner"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="clr"></div>
      <div className="container-fluid">
      <div className="container" style={{padding:window.screen.width>480?30:15, paddingTop:window.screen.width>480?30:0}}>
          <div className="row ">
            <div className="col-12 col-sm-8 col-lg-8 p-0 ">
              <div className="row">
                <div className="col-12 col-sm-11 col-lg-11">
                  <div className="voucher-box">
                    <div className="item clearfix">
                      <span className="circle-top"></span>
                      <span className="circle-bottom" style={{left:window.screen.width>480?131:116}}></span>

                      <div className="item-left" style={{width:window.screen.width>480?138:124}}>
                        <div className="price-style">
                          {/* <span className="price-desc"> ₹ </span> */}
                          <span className="price" style={{fontSize:window.screen.width>480?15:12, marginLeft:'-10px'}}> {param.voucher_value}</span>
                          <span className="price-unit"></span>
                          <span className="price-off"></span>
                        </div>
                      </div>
                      <div className="item-right" style={{width:window.screen.width>480?400:150}}>
                        <p className="p-one" style={{fontSize:window.screen.width>480?16:13}}>{param.voucher_heading}</p>
                        <p className="p-two">
                          {" "}
                          <i className="iconfont icontime"></i>
                          {moment(param.start_date).format(
                            "MMM Do YYYY"
                          )} - {moment(param.end_date).format("MMM Do YYYY")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
              {(param.status == "Available" || param.status == "Redeem voucher") ?  (
                <div className="col-11 col-sm-11 col-lg-11 ">
                  <div className="botmargn2">
                    <div className="upldbx toppad10">
                      <h2>
                        Upload your Designs
                        <button
                          type="button"
                          className="suggestbtn ml-3"
                          onClick={() => setModalInfo(true)}
                        >
                          <i
                            style={{ color: "black" }}
                            className="fa fa-info-circle"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </h2>
                    </div>
                    <div className="clr"></div>
                    <form id="designForm" onSubmit={handleSubmit(insertData)}>
                      <div className="row">
                        <div className="col-4 col-sm-4 col-lg-2 toppad5 btnpad" style={{marginRight:2}}>
                          <input
                            type="file"
                            hidden=""
                            onChange={() => {
                              var reader = new FileReader();
                              reader.onload = function () {
                                setImagePath(reader.result);
                              };
                              reader.readAsDataURL(
                                document.getElementById("iduploaddesign")
                                  .files[0]
                              );
                            }}
                            className="hide"
                            id="iduploaddesign"
                          />
                          {ImagePath !== "" ? (
                            <img src={ImagePath} className="browsebtn" />
                          ) : (
                            <label
                              className="browsebtn fileUpload btn-default"
                              onClick={() =>
                                document
                                  .getElementById("iduploaddesign")
                                  .click()
                              }
                            ></label>
                          )}
                        </div>
                        <div className="col-7 col-sm-7 col-lg-7 toppad5 btnpad" style={{marginLeft:5, padding:0, paddingLeft:10, paddingTop:30}}>
                          <div className="form-group">
                            <label for="exampleFormControlTextarea1">
                              Suggestion
                            </label>
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              onChange={(e) => {
                                setParam({ ...param, remarks: e.target.value });
                              }}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="clr"></div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={checkedTerms.check}
                          checked={checkedTerms.check}
                          id="flexCheckDefault"
                          onClick={(e) => {
                            setcheckedTerms({
                              ...checkedTerms,
                              check: !checkedTerms.check,
                              comment: false,
                            });
                          }}
                        />
                        <label
                        style={{paddingLeft:5}}
                          className="form-check-label"
                          for="flexCheckDefault"
                        >
                          Terms &amp; Conditions apply
                        </label>
                      </div>
                      {checkedTerms.comment ? (
                        <label
                          className="form-check-label color-red"
                          for="flexCheckDefault"
                        >
                          Please check Terms &amp; Conditions apply
                        </label>
                      ) : null}
                      <div className="row">
                       
                        {load == "false" ?  <div className="col-6 col-sm-6 col-lg-6 toppad5 mtoppad3" >
                              <button type="submit" className="btn browsesubmit" onClick={()=>{setLoad("true"); insertData()}}>
                                Submit
                            </button>
                            </div> :
                             <div className="col-6 col-sm-6 col-lg-6 toppad5 mtoppad3">
                             <button type="button" className="btn browsesubmit">
                               Submiting..
                           </button>
                           </div>}
                       
                        <div className="col-6 col-sm-6 col-lg-6 toppad5 mtoppad3">
                          <button
                            type="button"
                            onClick={() => {
                              setImagePath("");
                              document.getElementById("designForm").reset();
                            }}
                            className="btn browsesubmit"
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>): null}
                <Snackbar
                open={fileUploadValidation}
                autoHideDuration={5000}
                onClose={handleCloseWish}
                message="Please upload image and fill suggestion."
              />
                {param.status == "Get voucher" ? (
                  <label
                    className="form-check-label color-red mt-5"
                    for="flexCheckDefault"
                    style={{marginLeft:15}}
                  >
                    Note: Last date to { moment(param.redeem_end_date).format("DD/MM/yyyy")} . Plz visit or contact showroom.
                  </label>
                ) : null}
                 {/* {param.status == "Redeem voucher" ? (
                  <label
                    className="form-check-label color-red mt-5"
                    for="flexCheckDefault"
                    style={{marginLeft:15}}
                  >
                    Note: Already redeemed voucher on { moment(param.customer_redeemed_date).format("DD/MM/yyyy")}.
                  </label>
                ) : null} */}
                <CustomertermsAndCondition id={id} />
              </div>
            </div>
            <div className="d-none d-md-block col-sm-12 col-md-4 secbdr " style={{marginTop:10}}>
              <h1>Other Offers</h1>
              <CustomerVoucherComList voucher_id={id} />
            </div>
          </div>
        </div>
        <div className="d-block d-md-none col-sm-12 col-md-4 secbdr " style={{marginTop:10}}>
              <h1>Other Offers</h1>
              <CustomerVoucherComList voucher_id={id} />
            </div>
      </div>
      <div className="clr"></div>
      <div className="container-fluid"></div>
      <div className="clr"></div>
      <CustomerFooter />
    </>
  );
}
