import React from 'react';

const Thankyou = (props) => {
    console.log(props)
    return(
        <div className="thank_bg">
            <h1>Thank You</h1>
            <div className="checkinOptions">
                    <div className="checkIN shadow" >
                        <div className="w-100 text-center">
                            <h3>{props.val.full_name}</h3>
                        </div>
                    </div>
                    <div className="checkIN white shadow">
                        <div className="w-100 text-center">
                            <h3>{props.val.visit_count}</h3>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default Thankyou;