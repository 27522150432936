import React, { Fragment, memo, useEffect, useState } from "react";
import {
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  CardBody,
} from "reactstrap";
import { postMethod } from "../../../../_services/_postMethod";
import Loader from "../../../modules/loader";
import { API_BASE_URL, WEB_BASE_URL } from "../../../../_services/constant";
import { CardHeader, Divider } from "@material-ui/core";
import ReactDatatable from "@ashvin27/react-datatable";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { addDays } from "date-fns";
import { DateRangePicker } from "react-date-range";
import { DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Chart from "react-google-charts";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

export default function BranchDashboard(props) {
  document.body.classList.add("custom_dashboard");
  const classes = useStyles();
  const [load, setLoad] = React.useState(false);
  const [branchEncryptID, setBranchEncryptID] = React.useState(
    localStorage.getItem("jwl_branchEncrypt_id")
  );
  const [figures, setFigures] = React.useState({
    available: "",
    check_in: "",
    e_store: "",
    exhibition: "",
    new_customers: "",
    new_check_in: "",
    new_e_store: "",
    new_exhibition: "",
    redeem: "",
    t_exhibition: "",
    total_cart: "",
    total_customers: "",
    total_voucher: "",
    today_sms_count: "",
    total_sms_count: "",
    upload: "",
    wishlist: "",
    VideoCall: "",
  });
  const { buttonLabel, className } = props;
  const [recentActivitys, setRecentActivitys] = React.useState([]);
  const [customerCart, setCustomerCart] = React.useState([]);
  const [vCalls, setVCalls] = React.useState([]);
  const [dobANDdoa, setDobANDdoa] = React.useState([]);
  const [vcallRemarks, setVcallRemarks] = React.useState([]);
  const [missCallRemarks, setMissCallRemarks] = React.useState([]);
  const [modalTotalCustomer, setModalTotalCustomer] = useState(false);
  const toggleTotalCustomer = () => setModalTotalCustomer(!modalTotalCustomer);
  const [modalTotalCustomerNew, setModalTotalCustomerNew] = useState(false);
  const toggleTotalCustomerNew = () =>
    setModalTotalCustomerNew(!modalTotalCustomerNew);
  const [modalCartDetails, setModalCartDetails] = useState(false);
  const toggleCartDetails = () => setModalCartDetails(!modalCartDetails);
  const [modalVoucherDetails, setModalVoucherDetails] = useState(false);
  const toggleModalVoucherDetails = () =>
    setModalVoucherDetails(!modalVoucherDetails);
  const [modalNotVisitingCustomer, setModalNotVisitingCustomer] =
    useState(false);
  const toggleNotVisitingCustomer = () =>
    setModalNotVisitingCustomer(!modalNotVisitingCustomer);
  const [
    modalNotVisitingCustomerDataRange,
    setModalNotVisitingCustomerDataRange,
  ] = useState(false);
  const toggleNotVisitingCustomerDataRange = () =>
    setModalNotVisitingCustomerDataRange(!modalNotVisitingCustomerDataRange);
  const [modalVideoCallRequest, setModalVideoCallRequest] = useState(false);
  const toggleVideoCallRequest = () =>
    setModalVideoCallRequest(!modalVideoCallRequest);
  const [modalVideoCallRequestDetails, setModalVideoCallRequestDetails] =
    useState(false);
  const toggleVideoCallRequestDetails = () =>
    setModalVideoCallRequestDetails(!modalVideoCallRequestDetails);
  const [modalMissCallRequest, setModalMissCallRequest] = useState(false);
  const toggleMissCallRequest = () =>
    setModalMissCallRequest(!modalMissCallRequest);
  const [modalMissCallRequestDetails, setModalMissCallRequestDetails] =
    useState(false);
  const toggleMissCallRequestDetails = () =>
    setModalMissCallRequestDetails(!modalMissCallRequestDetails);
  const [modalStock, setModalStock] = useState(false);
  const toggleStock = () => setModalStock(!modalStock);
  const [appointmentDate, appointmenthandleDateChange] = useState(
    moment(new Date())
  );
  const [remarksLoader, setRemarksLoader] = useState(false);
  const [remarksMissLoader, setRemarksMissLoader] = useState(false);
  const [productcountList, setProductcountList] = React.useState([]);
  const [divBirthdayAndAnniversary, setDivBirthdayAndAnniversary] =
    useState(false);
  const toggleDivBirthdayAndAnniversary = () =>
    setDivBirthdayAndAnniversary(!divBirthdayAndAnniversary);

  const [modalDashboardDataRange, setModalDashboardDataRange] = useState(false);
  const toggleDashboardDataRange = () =>
    setModalDashboardDataRange(!modalDashboardDataRange);

  //Table Objects
  const [totalCustomers, setTotalCustomers] = React.useState([]);
  const [filter_type, setFiltertype] = React.useState("");
  const [newCustomers, setNewCustomers] = React.useState([]);
  const [filter_type_new, setFilterTypeNew] = React.useState("");
  const [notVisitingCustomers, setNotVisitingCustomers] = React.useState([]);
  const [filter_type_no_visit, setFilterTypeNotVisit] = React.useState("");
  const [videoCallRequest, setVideoCallRequest] = React.useState([]);
  const [filter_type_vcall, setFilterTypeVCall] = React.useState("");
  const [missCallRequest, setMissCallRequest] = React.useState([]);
  const [filter_type_miss, setFilterTypeMiss] = React.useState("");
  const [productsInCart, setProductsInCart] = React.useState([]);
  const [activeVouchers, setActiveVouchers] = React.useState([]);
  const [filter_type_voucher, setFilterTypeVoucher] = React.useState("");
  const [browseStock, setBrowseStock] = React.useState([]);
  //Date Range
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const [showDate, setshowDate] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  const [dashboardShowDate, setDashboardShowDate] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  // Update Video call Remarks

  const [remarksValidation, setRemarksValidation] = React.useState(false);
  const [updateVcall, setUpdateVcall] = React.useState({
    tran_id: "",
    status: "",
    accept_date: "",
    accept_time: "",
    remarks: "",
  });

  // Update Miss Call Remarks
  const [remarksMissValidation, setRemarksMissValidation] =
    React.useState(false);
  const [updateMiss, setUpdateMiss] = React.useState({
    tran_id: "",
    status: "",
    accept_date: "",
    accept_time: "",
    remarks: "",
  });

  // Update Cart Remarks
  const [remarksCartLoader, setRemarksCartLoader] = useState(false);
  const [modalCartRequestDetails, setModalCartRequestDetails] = useState(false);
  const toggleCartRequestDetails = () =>
    setModalCartRequestDetails(!modalCartRequestDetails);
  const [cartRemarks, setCartRemarks] = React.useState([]);
  const [remarksCartValidation, setRemarksCartValidation] =
    React.useState(false);
  const [updateCart, setUpdateCart] = React.useState({
    tran_id: "",
    status: "",
    accept_date: "",
    accept_time: "",
    remarks: "",
  });



  useEffect(() => {
    setLoad(true);
    var startDateVar = new Date();
    var endDateVar = addDays(new Date(), 7);
    setDashboardShowDate({
      ...dashboardShowDate,
      startDate: startDateVar.toLocaleDateString("en-US"),
      endDate: endDateVar.toLocaleDateString("en-US"),
    });
    setshowDate({
      ...showDate,
      startDate: startDateVar.toLocaleDateString("en-US"),
      endDate: endDateVar.toLocaleDateString("en-US"),
    });

     dashboard_figures();
     recentActivityFun();
    birthdayANDanniversary();
     browse_customers_chart();
   // getFigureDetials();
   // getupdates();
    setLoad(false);
  }, []);

    useEffect(() => {   
      dashboard_figures();       
    }, [dashboardShowDate]);

  const getFigureDetials = () => {
    browse_customers_not_visit();
    browse_vouchers();
    birthdayANDanniversary();
    browse_stocks();
    browse_ProductCategoryCountList();
    browse_customers_chart();
  };

  const getupdates = () => {
    //  getFigureDetials();
    // browse_customers();
    // browse_customers_new();
    // browse_vCall();
    // browse_missCall();   
    // dashboard_cartFun();
    // dashboard_cartFun();
    // setTimeout(() => {
    //         getupdates(); 
    // }, 80000);
  };

  const dashboard_figures = () => {
    postMethod("api/masters/dashboard/figures", {
      from_date: moment(dashboardShowDate.startDate).format("yyyy-MM-DD"),
      to_date: moment(dashboardShowDate.endDate).format("yyyy-MM-DD"),
    }).then((dataD) => {
      var data = dataD.data[0];
      setFigures({
        ...figures,
        new_customers: data.new_customers,
        total_cart: data.total_cart,
        total_customers: data.total_customers,
        total_not_visit_customers: data.total_notVisitCustomer,
        total_voucher: data.total_voucher,
        total_sms_count: data.total_sms_count,
        today_sms_count: data.today_sms_count,
        VideoCall: data.vCall,
        missedCall: data.missedCall,
        stock: data.stock,
      });
    });
  };

  const recentActivityFun = () => {
    postMethod("api/masters/dashboard/recentActivity", {
      from_date: dashboardShowDate.startDate,
      to_date: dashboardShowDate.endDate
    }).then((data) => {
      setRecentActivitys(data.data);
    });
  };

  const dashboard_cartFun = () => {
    postMethod("api/masters/dashboard/browse_cart", {
      from_date: dashboardShowDate.startDate,
      to_date: dashboardShowDate.endDate
    }).then((data) => {
      setProductsInCart(data.data);
    });
  };

  useEffect(() => {
    browse_customers();
  }, [filter_type]);
  useEffect(() => {
    browse_customers_new();
  }, [filter_type_new]);
  useEffect(() => {
    browse_customers_not_visit();
  }, [filter_type_no_visit]);
  useEffect(() => {
    browse_vouchers();
  }, [filter_type_voucher]);

  useEffect(() => {
    browse_vCall();
  }, [filter_type_vcall]);

  useEffect(() => {
    browse_missCall();
  }, [filter_type_miss]);

  const browse_customers = () => {
    postMethod("api/masters/dashboard/browse_customer", {
      type: filter_type,
      from_date: dashboardShowDate.startDate,
      to_date: dashboardShowDate.endDate
    }).then((data) => {
      setTotalCustomers(data.data);
    });
  };

  const browse_ProductCategoryCountList = () => {
    postMethod("api/masters/dashboard/productCategorysCountList", {}).then(
      (data) => {
        setProductcountList(data.data);
      }
    );
  };

  const browse_customers_new = () => {
    postMethod("api/masters/dashboard/newCutomers", {
      type: filter_type_new,
    }).then((data) => {
      setNewCustomers(data.data);
    });
  };
  const browse_customers_not_visit = () => {
    postMethod("api/masters/dashboard/notVisitingCustomers", {
      from_date: showDate.startDate,
      to_date: showDate.endDate,
      type: filter_type_no_visit,
    }).then((data) => {
      setNotVisitingCustomers(data.data);
    });
  };

  const notVisitngCustomerDateChange = (startDate, endDate) => {
    postMethod("api/masters/dashboard/notVisitingCustomers", {
      from_date: startDate,
      to_date: endDate,
    }).then((data) => {
      setNotVisitingCustomers(data.data);
    });
  };

  const browse_vouchers = () => {
    postMethod("api/masters/dashboard/activeVouchers", {
      type: filter_type_voucher,
      from_date: moment(dashboardShowDate.startDate).format("yyyy-MM-DD"),
      to_date: moment(dashboardShowDate.endDate).format("yyyy-MM-DD"),
    }).then((data) => {
      setActiveVouchers(data.data);
    });
  };

  const browse_vCall = () => {
    postMethod("api/masters/dashboard/videoCallRequest", {
      from_date: dashboardShowDate.startDate,
      to_date: dashboardShowDate.endDate,
      status: filter_type_vcall,
    }).then((data) => {
      setVideoCallRequest(data.data);
    });
  };

  const browse_missCall = () => {
    postMethod("api/masters/dashboard/missCallRequest", {
      from_date: dashboardShowDate.startDate,
      to_date: dashboardShowDate.endDate,
      status: filter_type_miss,
    }).then((data) => {
      setMissCallRequest(data.data);
    });
  };

  const birthdayANDanniversary = () => {
    postMethod("api/masters/dashboard/dobAndDoa", {}).then((data) => {
      setDobANDdoa(data.data);
    });
  };

  const browse_stocks = () => {
    postMethod("api/masters/dashboard/stock", {}).then((data) => {
      setBrowseStock(data.data);
    });
  };

  const browse_customers_chart = () => {
    var param = {
      from_date: showDate.startDate,
      to_date: showDate.endDate,
    };
    postMethod("api/masters/dashboard/customer_graph_new", param).then((data) => {
      var dataD = data.data;

      var temparr = [["x", "visits", "estore", "exhibitions"]];

      if (dataD.length > 0) {
        for (let index = 0; index < dataD.length; index++) {
          const element = dataD[index];

          temparr.push([
            element.date,
            element.visits,
            element.estore,
            element.exhibitions,
          ]);
        }
      }
      setCustomerCart(temparr);
    });
  };

  //Table Config
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
  };

  //Table Columns
  const columns_TotalCustomer = [
    {
      key: "full_name",
      text: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "mobile",
      text: "Mobile",
      align: "left",
      sortable: true,
    },
    {
      key: "type",
      text: "Type",
      sortable: true,
      align: "left",
    },
    {
      key: "gender",
      text: "Gender",
      sortable: true,
      align: "left",
    },
    {
      key: "dob",
      text: "D.O.B",
      sortable: true,
      align: "left",
    },
    {
      key: "doa",
      text: "D.O.A",
      sortable: true,
      align: "left",
    },
    {
      key: "category_name",
      text: "Category",
      sortable: true,
      align: "left",
    },
  ];

  //Table Cart
  const columns_Cart = [
    {
      key: "customer_name",
      text: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "mobile",
      text: "Mobile",
      align: "left",
      sortable: true,
    },
    {
      key: "product_name",
      text: "Product",
      sortable: true,
      align: "left",
    },
    {
      key: "product_code",
      text: "Product code",
      sortable: true,
      align: "left",
    },
    {
      key: "image",
      text: "image",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <img
              src={record.url_image + "" + record.image_path}
              width="36px"
              alt=""
            />
          </Fragment>
        );
      },
    },

    {
      key: "datetime",
      text: "Datetime",
      sortable: true,
      align: "left",
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="btn btn-link btn-sm"
              onClick={() => getCartRemarks(record)}
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-indent"></i>
            </button>
            <a
              href={"http://api.whatsapp.com/send?phone=+91" + record.mobile}
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                width="28px"
                height="28px"
              >
                <path
                  fill="#fff"
                  d="M4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98c-0.001,0,0,0,0,0h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303z"
                />
                <path
                  fill="#fff"
                  d="M4.868,43.803c-0.132,0-0.26-0.052-0.355-0.148c-0.125-0.127-0.174-0.312-0.127-0.483l2.639-9.636c-1.636-2.906-2.499-6.206-2.497-9.556C4.532,13.238,13.273,4.5,24.014,4.5c5.21,0.002,10.105,2.031,13.784,5.713c3.679,3.683,5.704,8.577,5.702,13.781c-0.004,10.741-8.746,19.48-19.486,19.48c-3.189-0.001-6.344-0.788-9.144-2.277l-9.875,2.589C4.953,43.798,4.911,43.803,4.868,43.803z"
                />
                <path
                  fill="#cfd8dc"
                  d="M24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,4C24.014,4,24.014,4,24.014,4C12.998,4,4.032,12.962,4.027,23.979c-0.001,3.367,0.849,6.685,2.461,9.622l-2.585,9.439c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98c0.002-5.339-2.075-10.359-5.848-14.135C34.378,6.083,29.357,4.002,24.014,4L24.014,4z"
                />
                <path
                  fill="#40c351"
                  d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z"
                />
                <path
                  fill="#fff"
                  fill-rule="evenodd"
                  d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
          </Fragment>
        );
      },
    },
  ];

  //VCall
  const columns_VCall = [
    {
      key: "name",
      text: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "mobile",
      text: "Mobile",
      align: "left",
      sortable: true,
    },
    {
      key: "Remarks",
      text: "remarks",
      sortable: true,
      align: "left",
    },
    {
      key: "status",
      text: "Status",
      sortable: true,
      align: "left",
    },
    {
      key: "datetime",
      text: "Datetime",
      sortable: true,
      align: "left",
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="btn btn-link btn-sm"
              onClick={() => getRemarks(record)}
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-indent"></i>
            </button>
            <a
              href={"http://api.whatsapp.com/send?phone=+91" + record.mobile}
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                width="28px"
                height="28px"
              >
                <path
                  fill="#fff"
                  d="M4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98c-0.001,0,0,0,0,0h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303z"
                />
                <path
                  fill="#fff"
                  d="M4.868,43.803c-0.132,0-0.26-0.052-0.355-0.148c-0.125-0.127-0.174-0.312-0.127-0.483l2.639-9.636c-1.636-2.906-2.499-6.206-2.497-9.556C4.532,13.238,13.273,4.5,24.014,4.5c5.21,0.002,10.105,2.031,13.784,5.713c3.679,3.683,5.704,8.577,5.702,13.781c-0.004,10.741-8.746,19.48-19.486,19.48c-3.189-0.001-6.344-0.788-9.144-2.277l-9.875,2.589C4.953,43.798,4.911,43.803,4.868,43.803z"
                />
                <path
                  fill="#cfd8dc"
                  d="M24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,4C24.014,4,24.014,4,24.014,4C12.998,4,4.032,12.962,4.027,23.979c-0.001,3.367,0.849,6.685,2.461,9.622l-2.585,9.439c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98c0.002-5.339-2.075-10.359-5.848-14.135C34.378,6.083,29.357,4.002,24.014,4L24.014,4z"
                />
                <path
                  fill="#40c351"
                  d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z"
                />
                <path
                  fill="#fff"
                  fill-rule="evenodd"
                  d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
          </Fragment>
        );
      },
    },
  ];

  //Miss Call
  const columns_MissCall = [
    {
      key: "full_name",
      text: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "mobile",
      text: "Mobile",
      align: "left",
      sortable: true,
    },
    {
      key: "status",
      text: "Status",
      align: "left",
      sortable: true,
    },
    {
      key: "datetime",
      text: "Datetime",
      sortable: true,
      align: "left",
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="btn btn-link btn-sm"
              onClick={() => getMissRemarks(record)}
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-indent"></i>
            </button>
            <a
              href={"http://api.whatsapp.com/send?phone=+91" + record.mobile}
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                width="28px"
                height="28px"
              >
                <path
                  fill="#fff"
                  d="M4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98c-0.001,0,0,0,0,0h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303z"
                />
                <path
                  fill="#fff"
                  d="M4.868,43.803c-0.132,0-0.26-0.052-0.355-0.148c-0.125-0.127-0.174-0.312-0.127-0.483l2.639-9.636c-1.636-2.906-2.499-6.206-2.497-9.556C4.532,13.238,13.273,4.5,24.014,4.5c5.21,0.002,10.105,2.031,13.784,5.713c3.679,3.683,5.704,8.577,5.702,13.781c-0.004,10.741-8.746,19.48-19.486,19.48c-3.189-0.001-6.344-0.788-9.144-2.277l-9.875,2.589C4.953,43.798,4.911,43.803,4.868,43.803z"
                />
                <path
                  fill="#cfd8dc"
                  d="M24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,4C24.014,4,24.014,4,24.014,4C12.998,4,4.032,12.962,4.027,23.979c-0.001,3.367,0.849,6.685,2.461,9.622l-2.585,9.439c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98c0.002-5.339-2.075-10.359-5.848-14.135C34.378,6.083,29.357,4.002,24.014,4L24.014,4z"
                />
                <path
                  fill="#40c351"
                  d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z"
                />
                <path
                  fill="#fff"
                  fill-rule="evenodd"
                  d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
          </Fragment>
        );
      },
    },
  ];

  //voucher
  const columns_Voucher = [
    {
      key: "full_name",
      text: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "mobile",
      text: "Mobile",
      align: "left",
      sortable: true,
    },
    {
      key: "voucher_name",
      text: "Voucher Name",
      align: "left",
      sortable: true,
    },
    {
      key: "voucher_heading",
      text: "Voucher Heading",
      align: "left",
      sortable: true,
    },
    {
      key: "start_date",
      text: "Start Date",
      align: "left",
      sortable: true,
    },
    {
      key: "end_date",
      text: "End Date",
      align: "left",
      sortable: true,
    },
    {
      key: "disable",
      text: "Disable",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <span>{record.disable === true ? "Disabled" : "Active"}</span>
          </Fragment>
        );
      },
    },
  ];

  //Stock
  const columns_Stocks = [
    {
      key: "entry_no",
      text: "ERntry no",
      align: "left",
      sortable: true,
    },
    {
      key: "date",
      text: "Date",
      align: "left",
      sortable: true,
    },
    {
      key: "to_branch",
      text: "To Branch",
      align: "left",
      sortable: true,
    },
    {
      key: "status",
      text: "Status",
      align: "left",
      sortable: true,
    },
    {
      key: "remarks",
      text: "Remarks",
      align: "left",
      sortable: true,
    },
  ];

  const getRemarks = (record) => {
    setUpdateVcall({
      ...updateVcall,
      tran_id: record.tran_id,
      status: record.status,
      accept_date: moment(new Date()).format().split("T")[0],
      accept_time: moment(new Date()).format().split("T")[1],
      remarks: "",
    });
    postMethod("api/transactions/customer/vcall/remarks", {
      tran_id: record.tran_id,
    }).then((data) => {
      setVcallRemarks(data.data);
      toggleVideoCallRequestDetails();
    });
  };
  const updateVcallEntry = () => {
    if (updateVcall.remarks == "") {
      setRemarksValidation(true);
    } else {
      setRemarksLoader(true);
      postMethod("api/transactions/customer/vcall/update", updateVcall).then(
        (data) => {
          setVcallRemarks(data.data);
          setUpdateVcall({ ...updateVcall, remarks: "" });
          setRemarksLoader(false);
        }
      );
    }
  };

  const getMissRemarks = (record) => {
    setUpdateMiss({
      ...updateMiss,
      tran_id: record.tran_id,
      status: record.status,
      accept_date: moment(new Date()).format().split("T")[0],
      accept_time: moment(new Date()).format().split("T")[1],
    });
    postMethod("api/transactions/customer/missCall/remarks", {
      tran_id: record.tran_id,
    }).then((data) => {
      setMissCallRemarks(data.data);
      toggleMissCallRequestDetails();
    });
  };
  const updateMissEntry = () => {
    if (updateMiss.remarks == "") {
      setRemarksMissValidation(true);
    } else {
      setRemarksMissLoader(true);
      postMethod("api/transactions/customer/missCall/update", updateMiss).then(
        (data) => {
          setMissCallRemarks(data.data);
          setUpdateMiss({ ...updateMiss, remarks: "" });
          setRemarksMissLoader(false);
        }
      );
    }
  };

  const getCartRemarks = (record) => {
    setUpdateCart({
      ...updateCart,
      tran_id: record.tran_id,
      status: record.status,
      accept_date: moment(new Date()).format().split("T")[0],
      accept_time: moment(new Date()).format().split("T")[1],
      remarks: "",
    });
    postMethod("api/transactions/customer/cart/remarks", {
      tran_id: record.tran_id,
    }).then((data) => {
      setCartRemarks(data.data);
      toggleCartRequestDetails();
    });
  };
  const updateCartEntry = () => {
    if (updateCart.remarks == "") {
      setRemarksCartValidation(true);
    } else {
      setRemarksCartLoader(true);
      postMethod("api/transactions/customer/cart/update", updateCart).then(
        (data) => {
          setCartRemarks(data.data);
          setUpdateCart({ ...updateCart, remarks: "" });
          setRemarksCartLoader(false);
        }
      );
    }
  };

  const trialSVG = () => {
    return (
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="20pt"
        height="20pt"
        viewBox="0 0 820.000000 819.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <metadata>
          Created by potrace 1.16, written by Peter Selinger 2001-2019
        </metadata>
        <g
          transform="translate(0.000000,819.000000) scale(0.100000,-0.100000)"
          fill="#239e27"
          stroke="none"
        >
          <path
            d="M534 7250 c-269 -39 -479 -248 -523 -521 -7 -42 -11 -640 -11 -1781
          l0 -1718 4035 0 4035 0 0 1743 c0 1704 0 1746 -20 1821 -28 112 -73 189 -160
          277 -58 58 -96 86 -160 118 -149 73 -74 70 -1657 71 l-1413 0 0 -775 0 -775
          570 0 c314 0 570 -3 570 -6 0 -10 -1750 -1749 -1764 -1752 -6 -1 -407 394
          -891 878 l-880 880 573 0 572 0 0 775 0 775 -1412 -1 c-777 -1 -1436 -5 -1464
          -9z"
          />
          <path
            d="M100 2515 l0 -185 260 0 260 0 0 -760 0 -759 183 -3 c100 -2 192 -2
          205 0 l22 3 0 760 0 759 260 0 260 0 0 185 0 185 -725 0 -725 0 0 -185z"
          />
          <path
            d="M2022 2649 c-34 -4 -72 -11 -82 -14 -20 -6 -20 -20 -20 -931 l0 -924
          205 0 205 0 0 370 0 370 119 0 c166 0 219 -21 276 -111 30 -47 45 -92 110
          -337 30 -114 62 -226 71 -249 l16 -43 214 0 c118 0 214 2 214 4 0 3 -5 17 -11
          33 -6 15 -44 150 -85 298 -41 149 -86 293 -99 321 -32 65 -94 136 -157 178
          l-48 33 47 20 c209 88 321 301 284 543 -17 110 -57 186 -141 263 -111 102
          -231 153 -420 176 -97 13 -604 12 -698 0z m638 -314 c76 -23 142 -92 160 -164
          31 -130 -32 -259 -154 -312 -43 -19 -73 -24 -193 -27 l-143 -4 0 267 0 268
          138 -6 c87 -3 157 -11 192 -22z"
          />
          <path d="M3730 1720 l0 -940 200 0 200 0 0 940 0 940 -200 0 -200 0 0 -940z" />
          <path
            d="M5095 2648 c-5 -14 -98 -325 -258 -863 -53 -176 -141 -472 -196 -657
          -56 -186 -101 -340 -101 -343 0 -3 96 -5 213 -5 l214 0 53 203 c29 111 57 221
          63 245 l11 42 266 -2 265 -3 75 -242 75 -243 228 0 c125 0 227 4 227 8 0 12
          -28 102 -210 682 -213 679 -350 1117 -362 1158 l-9 32 -275 0 c-211 0 -276 -3
          -279 -12z m378 -683 c48 -203 87 -374 87 -380 0 -6 -73 -9 -202 -8 -112 1
          -202 6 -202 10 1 4 33 112 72 238 38 127 86 297 107 378 21 81 41 144 45 140
          4 -4 46 -174 93 -378z"
          />
          <path
            d="M6620 1720 l0 -940 585 0 585 0 0 185 0 185 -372 2 -373 3 -2 750 -2
          750 -211 3 -210 2 0 -940z"
          />
        </g>
      </svg>
    );
  };

  const misscallSVG = () => {
    return (
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="20pt"
        height="20pt"
        viewBox="0 0 452.000000 452.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <metadata>
          Created by potrace 1.16, written by Peter Selinger 2001-2019
        </metadata>
        <g
          transform="translate(0.000000,452.000000) scale(0.100000,-0.100000)"
          fill="#ff0000"
          stroke="none"
        >
          <path
            d="M2636 3864 c-38 -38 -36 -95 3 -135 l29 -29 354 0 353 0 -557 -557
              -558 -558 -608 608 -608 607 -45 0 c-62 0 -99 -35 -99 -94 0 -41 1 -42 660
              -701 655 -656 660 -660 700 -660 40 0 45 5 650 610 l610 610 0 -352 0 -351 26
              -31 c22 -26 33 -31 70 -31 36 0 50 5 69 26 l25 27 0 488 0 487 -26 31 -26 31
              -498 0 -497 0 -27 -26z"
          />
          <path
            d="M1770 2159 c-681 -50 -1174 -202 -1462 -451 -109 -94 -135 -196 -124
              -488 7 -210 28 -374 54 -433 42 -94 127 -157 214 -157 25 0 141 20 259 45 456
              97 633 136 649 145 26 14 77 76 100 120 26 51 60 181 100 385 24 121 37 164
              52 177 27 24 183 72 298 93 123 22 433 31 568 16 192 -21 393 -74 437 -115 18
              -17 30 -62 63 -222 51 -243 72 -315 112 -377 54 -81 78 -88 705 -217 255 -53
              306 -58 359 -36 47 20 114 89 138 143 29 65 49 260 51 488 3 294 -13 342 -148
              450 -304 244 -781 385 -1470 435 -164 12 -789 11 -955 -1z m935 -189 c642 -46
              1091 -174 1356 -386 55 -44 68 -60 80 -101 25 -87 10 -510 -22 -611 -13 -41
              -39 -55 -87 -46 -128 22 -767 166 -777 174 -7 6 -18 23 -24 38 -11 28 -63 263
              -102 457 -25 128 -28 131 -144 186 -100 47 -218 82 -360 106 -170 28 -584 26
              -753 -5 -146 -26 -321 -82 -394 -126 l-53 -31 -56 -276 c-58 -282 -73 -328
              -112 -353 -28 -18 -784 -178 -806 -171 -49 15 -74 164 -75 450 -1 211 6 237
              78 302 223 203 712 346 1341 392 162 12 748 12 910 1z"
          />
        </g>
      </svg>
    );
  };

  return (
    <div>
      {load ? <Loader /> : null}
      <div className="dashboard_top_bar d-flex align-items-center justify-content-between shadow">
        <div className="filter_date_range d-flex align-items-center">
          {/* <p className="mb-0 font-weight-bold">Filter Data</p> */}
          <div className="f_data_range" onClick={toggleDashboardDataRange}>
            <i className="far fa-calendar-alt mr-2"></i> From :{" "}
            {moment(dashboardShowDate.startDate).format("DD/MM/yyyy")} || To :{" "}
            {moment(dashboardShowDate.endDate).format("DD/MM/yyyy")}
          </div>
        </div>
        <button
          className="btn btn-danger pointerShow"
          onClick={toggleDivBirthdayAndAnniversary}
          type="button"
        >
          Birthday and Anniversary
        </button>
      </div>
      
        {/* All DateRange */}
        <Modal
          isOpen={modalDashboardDataRange}
          zIndex="99999"
          size={"xl"}
          backdrop="static"
          centered="true"
          className={className + " text-center"}
        >
          <ModalHeader>
            Data Range{" "}
            <i
              className="fa fa-close pull-right pointerShow"
              onClick={() => {
                toggleDashboardDataRange();
              }}
            ></i>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col sm="12">
                <DateRangePicker
                  onChange={(item) => {
                    var startDate =
                      item.selection.startDate.toLocaleDateString("en-US");
                    var endDate =
                      item.selection.endDate.toLocaleDateString("en-US");
                    setState([item.selection]);
                    setDashboardShowDate({
                      ...dashboardShowDate,
                      startDate: startDate,
                      endDate: endDate,
                    });
                    setshowDate({
                      ...showDate,
                      startDate: startDate,
                      endDate: endDate,
                    });
                    // notVisitngCustomerDateChange(startDate,endDate)
                  }}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={state}
                  direction="horizontal"
                />
              </Col>
              <Col sm="12" className="text-right">
                {" "}
                <span
                  className="badge-success pointerShow"
                  onClick={() => {
                    toggleDashboardDataRange();
                  }}
                >
                  Continue
                </span>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      
        <Card>
         <CardHeader>Visit Figures</CardHeader>
         <CardBody>
         <Col
          sm="3"
          className="pointerShow"
          onClick={() => {
            toggleTotalCustomer();
            browse_customers();
          }}
        >
          <Card body>
            <Row>
              <Col sm="12" className="">
                <h4>Total Customer</h4> <b>{figures.total_customers}</b>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col
          sm="3"
          className="pointerShow"
          onClick={() => {
            toggleTotalCustomerNew();
            browse_customers_new();
          }}
        >
          <Card body>
            <Row>
              <Col sm="12" className="">
                <h4>Total New Customer</h4> <b>{figures.new_customers}</b>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col
          sm="3"
          className="pointerShow"
          onClick={() => {
            toggleCartDetails();
            dashboard_cartFun();
          }}
        >
          <Card body>
            <Row>
              <Col sm="12" className="">
                <h4>Total Cart</h4> <b>{figures.total_cart}</b>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col
          sm="3"
          className="pointerShow"
          onClick={() => {
            toggleModalVoucherDetails();
            browse_vouchers();
          }}
        >
          <Card body>
            <Row>
              <Col sm="12" className="">
                <h4>Vouchers</h4> <b>{figures.total_voucher}</b>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col
          sm="3"
          className="pointerShow"
          onClick={() => {
            toggleNotVisitingCustomer();
            browse_customers_not_visit();
          }}
        >
          <Card body>
            <Row>
              <Col sm="12" className="">
                <h4>Not visiting Customer</h4>{" "}
                <b>{figures.total_not_visit_customers}</b>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col
          sm="3"
          className="pointerShow"
          onClick={() => {toggleVideoCallRequest(); browse_vCall();}}
        >
          <Card body>
            <Row>
              <Col sm="12" className="">
                <h4>Video call requests</h4> <b>{figures.VideoCall}</b>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col
          sm="3"
          className="pointerShow"
          onClick={() => toggleMissCallRequest()}
        >
          <Card body>
            <Row>
              <Col sm="12" className="">
                <h4>Missed call </h4> <b>{figures.missedCall}</b>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col sm="3" className="pointerShow" onClick={() => toggleStock()}>
          <Card body>
            <Row>
              <Col sm="12" className="">
                <h4>Stock</h4> <b>{figures.stock}</b>
              </Col>
            </Row>
          </Card>
        </Col>
     
         </CardBody>
       </Card>
     

      <Row>
        <Col sm="6">
          <Chart
            width={"500px"}
            height={"300px"}
            chartType="LineChart"
            loader={<div>Loading Chart</div>}
            data={customerCart}
            options={{
              hAxis: {
                title: "Date",
              },
              vAxis: {
                title: "Customers",
              },
              series: {
                1: { curveType: "function" },
              },
            }}
            rootProps={{ "data-testid": "2" }}
          />
        </Col>
        <Col sm="6">
          <h4>Recent Activity </h4>
          <div className="recentTableHeight table-responsive">
            <Table>
              <tbody>
                {recentActivitys.length > 0 ? (
                  recentActivitys.map((row, i) => (
                    <tr>
                      <th scope="row">
                        {row.full_name == null ? "Member" : row.full_name}( 
                        {row.type == "Get Voucher" ? "Get "+ row.details :(row.type == "Get Voucher" ? "Redeem "+ row.details: row.type )})
                        <span
                          className={
                            row.type == "New Customer"
                              ? "badge-green"
                              : "badge-warning"
                          }
                        >
                          {row.mobile}
                        </span>
                        {row.dob == "ture" ? (
                          <i className="fa fa-birthday-cake fa-lg text-red m-2"></i>
                        ) : (
                          ""
                        )}
                        {row.doa == "ture" ? (
                          <i className="fa fa-birthday-cake fa-lg text-red m-2"></i>
                        ) : (
                          ""
                        )}
                        {row.trial == "ture" ? (
                          <i className=" m-2">{trialSVG()}</i>
                        ) : (
                          ""
                        )}
                        {row.vcall == "ture" ? (
                          <i className="fa fa-video fa-lg  m-2"></i>
                        ) : (
                          ""
                        )}
                        {row.wish == "ture" ? (
                          <i className="fa fa-heart fa-lg text-red  m-2"></i>
                        ) : (
                          ""
                        )}
                        {row.missCall == "ture" ? (
                          <i className=" m-2">{misscallSVG()}</i>
                        ) : (
                          ""
                        )}
                      </th>
                    </tr>
                  ))
                ) : (
                  <td className="text-center color-red">No Data</td>
                )}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
      <Row></Row>

      <Col
        sm="6"
        className={
          "shadow bday_table " +
          (divBirthdayAndAnniversary
            ? "BA-Notification-Active"
            : "BA-Notification-Deactive")
        }
      >
        <h4>
          Birthday and Anniversary{" "}
          <i
            onClick={toggleDivBirthdayAndAnniversary}
            className="fa fa-close pull-right pointerShow"
          ></i>
        </h4>

        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Mobile</th>
              <th>Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {dobANDdoa.length > 0 ? (
              dobANDdoa.map((row, i) => (
                <tr>
                  <th scope="row">{i + 1}</th>
                  <td>{row.full_name}</td>
                  <td>{row.mobile}</td>
                  <td>
                    {row.dob == "true" ? "Birthday" : ""}{" "}
                    {row.doa == "true" ? "Anniversary" : ""}
                  </td>
                  <td>
                    <a
                      href={
                        "http://api.whatsapp.com/send?phone=+91" + row.mobile
                      }
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        width="28px"
                        height="28px"
                      >
                        <path
                          fill="#fff"
                          d="M4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98c-0.001,0,0,0,0,0h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303z"
                        />
                        <path
                          fill="#fff"
                          d="M4.868,43.803c-0.132,0-0.26-0.052-0.355-0.148c-0.125-0.127-0.174-0.312-0.127-0.483l2.639-9.636c-1.636-2.906-2.499-6.206-2.497-9.556C4.532,13.238,13.273,4.5,24.014,4.5c5.21,0.002,10.105,2.031,13.784,5.713c3.679,3.683,5.704,8.577,5.702,13.781c-0.004,10.741-8.746,19.48-19.486,19.48c-3.189-0.001-6.344-0.788-9.144-2.277l-9.875,2.589C4.953,43.798,4.911,43.803,4.868,43.803z"
                        />
                        <path
                          fill="#cfd8dc"
                          d="M24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,4C24.014,4,24.014,4,24.014,4C12.998,4,4.032,12.962,4.027,23.979c-0.001,3.367,0.849,6.685,2.461,9.622l-2.585,9.439c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98c0.002-5.339-2.075-10.359-5.848-14.135C34.378,6.083,29.357,4.002,24.014,4L24.014,4z"
                        />
                        <path
                          fill="#40c351"
                          d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z"
                        />
                        <path
                          fill="#fff"
                          fill-rule="evenodd"
                          d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </a>
                  </td>
                </tr>
              ))
            ) : (
              <td colSpan="4" className="text-center">
                No Data
              </td>
            )}
          </tbody>
        </Table>
      </Col>
      <Row>
        <Col sm-12>
          <h4>Category</h4>
          <div className={classes.root}>
            {productcountList.length > 0 ? (
              productcountList.map((row, i) => (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-label="Expand"
                    aria-controls="additional-actions2-content"
                    id="additional-actions2-header"
                  >
                    <table className="table table-borderless">
                      <thead>
                        <tr>
                          <th>{row.category_name}</th>
                          <th>
                            SubCategory Count
                            <span className={"badge-success"}>
                              {row.subcategory}
                            </span>
                          </th>
                          <th>
                            Proudct Count
                            <span className={"badge-warning"}>
                              {row.product}
                            </span>
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography color="textSecondary">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Category Name</th>
                            <th>SubCategory Count</th>
                            <th>Proudct Count</th>
                          </tr>
                        </thead>
                        <tbody>
                          {row.innerTable.length > 0 ? (
                            row.innerTable.map((row1, i) => (
                              <tr>
                                <th scope="row">{i + 1}</th>
                                <td>{row.category_name}</td>
                                <td>
                                  <span className={"badge-success"}>
                                    {row1.subcategory_name}
                                  </span>
                                </td>
                                <td>
                                  <span className={"badge-warning"}>
                                    {row1.product}
                                  </span>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <td colSpan="4" className="text-center">
                              No Data
                            </td>
                          )}
                        </tbody>
                      </table>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <td colSpan="4" className="text-center">
                No Data
              </td>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <Row>
            <Col>
              <Card body>
                <Row>
                  <Col sm="12" className="text-center">
                    <h5
                      className="pointerShow"
                      onClick={() =>
                        window.open(
                          WEB_BASE_URL + "r?t=" + branchEncryptID,
                          "_blank"
                        )
                      }
                    >
                      {" "}
                      Exhibition Link : {WEB_BASE_URL}e?t={branchEncryptID}
                    </h5>
                  </Col>
                  <Col sm="12">
                    <Divider light />
                  </Col>
                  <Col sm="12" className="text-center">
                    <h5
                      className="pointerShow"
                      onClick={() =>
                        window.open(
                          WEB_BASE_URL + "t?t=" + branchEncryptID,
                          "_blank"
                        )
                      }
                    >
                      {" "}
                      Trial Link : {WEB_BASE_URL}t?t={branchEncryptID}{" "}
                    </h5>
                  </Col>
                  <Col sm="12" className="text-center">
                    <h5
                      className="pointerShow"
                      onClick={() =>
                        window.open(
                          WEB_BASE_URL + "g?t=" + branchEncryptID,
                          "_blank"
                        )
                      }
                    >
                      {" "}
                      General Link : {WEB_BASE_URL}g?t={branchEncryptID}{" "}
                    </h5>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col sm="6">
          <Card body>
            <Row>
              <Col sm="12" className="text-center">
                <h4>SMS [Today / Total]</h4> <br />{" "}
                <b className="font-size32">
                  {figures.today_sms_count} / {figures.total_sms_count}
                </b>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      {/* Total Customer Modal */}
      <Modal
        isOpen={modalTotalCustomer}
        zIndex="99999"
        size="lg"
        backdrop="static"
        centered="true"
        className={className}
      >
        <ModalHeader>
          <Row>
            <Col sm="2">Customers</Col>

            <Col sm="4">
              <FormGroup>
                <select
                  className="form-control h-auto py-2 px-6"
                  onChange={(e) => {
                    setFiltertype(e.target.value);
                  }}
                  name="customer_type"
                  value={filter_type}
                  required
                >
                  <option value="">All</option>
                  <option value="general session">E-store</option>
                  <option value="exhibition">Exhibition</option>
                  <option value="visit">Visit</option>
                </select>
              </FormGroup>
            </Col>
            <Col sm="6">
              <i
                className="fa fa-close pull-right pointerShow"
                onClick={() => {
                  toggleTotalCustomer();
                }}
              ></i>
            </Col>
          </Row>
        </ModalHeader>
        <ModalBody>
          <ReactDatatable
            config={config}
            records={totalCustomers}
            columns={columns_TotalCustomer}
          />
        </ModalBody>
      </Modal>

      {/* Total Customer Modal */}
      <Modal
        isOpen={modalTotalCustomerNew}
        zIndex="99999"
        size="lg"
        backdrop="static"
        centered="true"
        className={className}
      >
        <ModalHeader>
          <Row>
            <Col sm="4">New Customers</Col>

            <Col sm="4">
              <FormGroup>
                <select
                  className="form-control h-auto py-2 px-6"
                  onChange={(e) => {
                    setFilterTypeNew(e.target.value);
                  }}
                  name="customer_type_new"
                  value={filter_type_new}
                  required
                >
                  <option value="">All</option>
                  <option value="general session">E-store</option>
                  <option value="exhibition">Exhibition</option>
                  <option value="visit">Visit</option>
                </select>
              </FormGroup>
            </Col>
            <Col sm="4">
              <i
                className="fa fa-close pull-right pointerShow"
                onClick={() => {
                  toggleTotalCustomerNew();
                }}
              ></i>
            </Col>
          </Row>
        </ModalHeader>
        <ModalBody>
          <ReactDatatable
            config={config}
            records={newCustomers}
            columns={columns_TotalCustomer}
          />
        </ModalBody>
      </Modal>

      {/* Total Cart */}
      <Modal
        isOpen={modalCartDetails}
        zIndex="99999"
        size="lg"
        backdrop="static"
        centered="true"
        className={className}
      >
        <ModalHeader>
          Cart Details
          <i
            className="fa fa-close pull-right pointerShow"
            onClick={() => {
              toggleCartDetails();
            }}
          ></i>
        </ModalHeader>
        <ModalBody>
          <ReactDatatable
            config={config}
            records={productsInCart}
            columns={columns_Cart}
          />
        </ModalBody>
      </Modal>

      {/* Stocks*/}
      <Modal
        isOpen={modalStock}
        zIndex="99999"
        size="lg"
        backdrop="static"
        centered="true"
        className={className}
      >
        <ModalHeader>
          Stocks Details
          <i
            className="fa fa-close pull-right pointerShow"
            onClick={() => {
              toggleStock();
            }}
          ></i>
        </ModalHeader>
        <ModalBody>
          <ReactDatatable
            config={config}
            records={browseStock}
            columns={columns_Stocks}
          />
        </ModalBody>
      </Modal>

      {/* Voucher Details */}
      <Modal
        isOpen={modalVoucherDetails}
        zIndex="99999"
        size="lg"
        backdrop="static"
        centered="true"
        className={className}
      >
        <ModalHeader>
          <Row>
            <Col sm="4">Voucher Details</Col>

            <Col sm="4">
              <FormGroup>
                <select
                  className="form-control h-auto py-2 px-6"
                  onChange={(e) => {
                    setFilterTypeVoucher(e.target.value);
                  }}
                  name="customer_type_voucher"
                  value={filter_type_voucher}
                  required
                >
                  <option value="">All</option>
                  <option value="Active">Active</option>
                  <option value="Expire">Expire</option>
                </select>
              </FormGroup>
            </Col>
            <Col sm="4">
              <i
                className="fa fa-close pull-right pointerShow"
                onClick={() => {
                  toggleModalVoucherDetails();
                }}
              ></i>
            </Col>
          </Row>
        </ModalHeader>
        <ModalBody>
          <ReactDatatable
            config={config}
            records={activeVouchers}
            columns={columns_Voucher}
          />
        </ModalBody>
      </Modal>

      {/* Not Visiting Customers */}
      <Modal
        isOpen={modalNotVisitingCustomer}
        zIndex="99999"
        size="lg"
        backdrop="static"
        centered="true"
        className={className}
      >
        <ModalHeader>
          <Row>
            <Col sm="3">Not Visiting Customer</Col>

            <Col sm="3">
              <FormGroup>
                <select
                  className="form-control h-auto py-2 px-6"
                  onChange={(e) => {
                    setFilterTypeNotVisit(e.target.value);
                  }}
                  name="customer_type_not_visiting"
                  value={filter_type_no_visit}
                  required
                >
                  <option value="">All</option>
                  <option value="general session">E-store</option>
                  <option value="exhibition">Exhibition</option>
                  <option value="visit">Visit</option>
                </select>
              </FormGroup>
            </Col>
            <Col sm="6">
              <i
                className="fa fa-close pull-right pointerShow"
                onClick={() => {
                  toggleNotVisitingCustomer();
                }}
              ></i>
           
              <div className="filter_date_range d-flex align-items-center">
                <div
                  className="f_data_range"
                  onClick={toggleNotVisitingCustomerDataRange}
                >
                  <i className="far fa-calendar-alt mr-2"></i> From :{" "}
                  {moment(showDate.startDate).format("DD/MM/yyyy")} || To :{" "}
                  {moment(showDate.endDate).format("DD/MM/yyyy")}
                </div>
              </div>
            </Col>
          </Row>
        </ModalHeader>
        <ModalBody>
          <ReactDatatable
            config={config}
            records={notVisitingCustomers}
            columns={columns_TotalCustomer}
          />
        </ModalBody>
      </Modal>
      {/* Not Visiting Customers DateRange */}
      <Modal
        isOpen={modalNotVisitingCustomerDataRange}
        zIndex="99999"
        size={"xl"}
        backdrop="static"
        centered="true"
        className={className + " text-center"}
      >
        <ModalHeader>
          Data Range{" "}
          <i
            className="fa fa-close pull-right pointerShow"
            onClick={() => {
              toggleNotVisitingCustomerDataRange();
            }}
          ></i>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <DateRangePicker
                onChange={(item) => {
                  var startDate =
                    item.selection.startDate.toLocaleDateString("en-US");
                  var endDate =
                    item.selection.endDate.toLocaleDateString("en-US");
                  setState([item.selection]);
                  setshowDate({
                    ...showDate,
                    startDate: startDate,
                    endDate: endDate,
                  });
                  notVisitngCustomerDateChange(startDate, endDate);
                }}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={state}
                direction="horizontal"
              />
            </Col>
            <Col sm="12" className="text-right">
              {" "}
              <span
                className="badge-success pointerShow"
                onClick={() => {
                  toggleNotVisitingCustomerDataRange();
                }}
              >
                Continue
              </span>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      {/* Miss Call */}
      <Modal
        isOpen={modalMissCallRequest}
        zIndex="99999"
        size="lg"
        backdrop="static"
        centered="true"
        className={className}
      >
        <ModalHeader>
          <Row>
            <Col sm="4">Miss Call Requrest</Col>

            <Col sm="4">
              <FormGroup>
                <select
                  className="form-control h-auto py-2 px-6"
                  onChange={(e) => {
                    setFilterTypeMiss(e.target.value);
                  }}
                  name="customer_type_missCall"
                  value={filter_type_miss}
                  required
                >
                  <option value="">All</option>
                  <option value="request">Request</option>
                  <option value="Pending">Pending</option>
                  <option value="accept">Accept</option>
                  <option value="done">Done</option>
                </select>
              </FormGroup>
            </Col>
            <Col sm="4">
              <i
                className="fa fa-close pull-right pointerShow"
                onClick={() => {
                  toggleMissCallRequest();
                }}
              ></i>
            </Col>
          </Row>
        </ModalHeader>
        <ModalBody>
          <ReactDatatable
            config={config}
            records={missCallRequest}
            columns={columns_MissCall}
          />
        </ModalBody>
      </Modal>

      {/* Video Call */}
      <Modal
        isOpen={modalVideoCallRequest}
        zIndex="99999"
        size="lg"
        backdrop="static"
        centered="true"
        className={className}
      >
        <ModalHeader>
          <Row>
            <Col sm="4">Video Call Requrest</Col>

            <Col sm="4">
              <FormGroup>
                <select
                  className="form-control h-auto py-2 px-6"
                  onChange={(e) => {
                    setFilterTypeVCall(e.target.value);
                  }}
                  name="customer_type_vCall"
                  value={filter_type_vcall}
                  required
                >
                  <option value="">All</option>
                  <option value="request">Request</option>
                  <option value="Pending">Pending</option>
                  <option value="accept">Accept</option>
                  <option value="done">Done</option>
                </select>
              </FormGroup>
            </Col>
            <Col sm="4">
              <i
                className="fa fa-close pull-right pointerShow"
                onClick={() => {
                  toggleVideoCallRequest();
                }}
              ></i>
            </Col>
          </Row>
        </ModalHeader>
        <ModalBody>
          <ReactDatatable
            config={config}
            records={videoCallRequest}
            columns={columns_VCall}
          />
        </ModalBody>
      </Modal>

      {/* Video Call */}
      <Modal
        isOpen={modalVideoCallRequestDetails}
        zIndex="99999"
        size="lg"
        backdrop="static"
        centered="true"
        className={className}
      >
        <ModalHeader>
          Video Call Details
          <i
            className="fa fa-close pull-right pointerShow"
            onClick={() => {
              toggleVideoCallRequestDetails();
            }}
          ></i>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="4">
              <FormGroup>
                <Label>Video Call Datetime</Label>
                <DateTimePicker
                  size="small"
                  inputVariant="outlined"
                  ampm={false}
                  disableFuture
                  format="DD/MM/yyyy hh:mm a"
                  value={appointmentDate}
                  onChange={(e) => {
                    setUpdateVcall({
                      ...updateVcall,
                      accept_date: moment(e).format().split("T")[0],
                      accept_time: moment(e).format().split("T")[1],
                    });
                    appointmenthandleDateChange(e);
                  }}
                />
              </FormGroup>
            </Col>
            <Col sm="4">
              <FormGroup>
                <Label>Status</Label>
                <select
                  className="form-control h-auto py-2 px-6"
                  onChange={(e) => {
                    setUpdateVcall({ ...updateVcall, status: e.target.value });
                  }}
                  name="vCallStatus"
                  value={updateVcall.status}
                  required
                >
                  <option value="">--Select--</option>
                  <option value="accept">Accept</option>
                  <option value="done">Done</option>
                  <option value="request">Request</option>
                </select>
              </FormGroup>
            </Col>
            <Col sm="4"></Col>
          </Row>

          <div className="chat-text-body">
            {vcallRemarks.length > 0 ? (
              vcallRemarks.map((row, i) => (
                <div className="chat-container chat-darker">
                  <p>{row.remarks}</p>
                  <span className="time-right">{row.date}</span>
                </div>
              ))
            ) : (
              <div className="container text-center">
                <h4>No Remarks</h4>
              </div>
            )}
          </div>
          <Row className="mt-4">
            <Col sm="10">
              <textarea
                required
                value={updateVcall.remarks}
                className="form-control h-auto py-2 px-6"
                name="remarks"
                onClick={() => {
                  setRemarksValidation(false);
                }}
                onChange={(e) =>
                  setUpdateVcall({ ...updateVcall, remarks: e.target.value })
                }
              />
              {remarksValidation ? (
                <Label className="text-danger">Remark can not be blank.</Label>
              ) : null}
            </Col>
            <Col sm="2">
              {remarksLoader ? (
                <Button className="btn btn-info mt-3" type="button">
                  Updating..
                </Button>
              ) : (
                <Button
                  className="btn btn-success mt-3"
                  type="button"
                  onClick={() => {
                    updateVcallEntry();
                  }}
                >
                  Enter
                </Button>
              )}
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      {/* Miss Call */}
      <Modal
        isOpen={modalMissCallRequestDetails}
        zIndex="99999"
        size="lg"
        backdrop="static"
        centered="true"
        className={className}
      >
        <ModalHeader>
          Miss Call Details
          <i
            className="fa fa-close pull-right pointerShow"
            onClick={() => {
              toggleMissCallRequestDetails();
            }}
          ></i>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="4">
              <FormGroup>
                <Label>Miss Call Datetime</Label>
                <DateTimePicker
                  size="small"
                  inputVariant="outlined"
                  ampm={false}
                  disableFuture
                  format="DD/MM/yyyy hh:mm a"
                  value={appointmentDate}
                  onChange={(e) => {
                    setUpdateMiss({
                      ...updateMiss,
                      accept_date: moment(e).format().split("T")[0],
                      accept_time: moment(e).format().split("T")[1],
                    });
                    appointmenthandleDateChange(e);
                  }}
                />
              </FormGroup>
            </Col>
            <Col sm="4">
              <FormGroup>
                <Label>Status</Label>
                <select
                  className="form-control h-auto py-2 px-6"
                  onChange={(e) => {
                    setUpdateMiss({ ...updateMiss, status: e.target.value });
                  }}
                  name="vCallStatus"
                  value={updateMiss.status}
                  required
                >
                  <option value="">--Select--</option>
                  <option value="accept">Accept</option>
                  <option value="done">Done</option>
                  <option value="request">Request</option>
                </select>
              </FormGroup>
            </Col>
            <Col sm="4"></Col>
          </Row>

          <div className="chat-text-body">
            {missCallRemarks.length > 0 ? (
              missCallRemarks.map((row, i) => (
                <div className="chat-container chat-darker">
                  <p>{row.remarks}</p>
                  <span className="time-right">{row.date}</span>
                </div>
              ))
            ) : (
              <div className="container text-center">
                <h4>No Remarks</h4>
              </div>
            )}
          </div>
          <Row className="mt-4">
            <Col sm="10">
              <textarea
                required
                value={updateMiss.remarks}
                className="form-control h-auto py-2 px-6"
                name="remarks"
                onClick={() => {
                  setRemarksMissValidation(false);
                }}
                onChange={(e) =>
                  setUpdateMiss({ ...updateMiss, remarks: e.target.value })
                }
              />
              {remarksValidation ? (
                <Label className="text-danger">Remark can not be blank.</Label>
              ) : null}
            </Col>
            <Col sm="2">
              {remarksMissLoader ? (
                <Button className="btn btn-info mt-3" type="button">
                  Updating..
                </Button>
              ) : (
                <Button
                  className="btn btn-success mt-3"
                  type="button"
                  onClick={() => {
                    updateMissEntry();
                  }}
                >
                  Enter
                </Button>
              )}
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      {/* Video Call */}
      <Modal
        isOpen={modalCartRequestDetails}
        zIndex="99999"
        size="lg"
        backdrop="static"
        centered="true"
        className={className}
      >
        <ModalHeader>
          Cart Details
          <i
            className="fa fa-close pull-right pointerShow"
            onClick={() => {
              toggleCartRequestDetails();
            }}
          ></i>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="4">
              <FormGroup>
                <Label>Cart Datetime</Label>
                <DateTimePicker
                  size="small"
                  inputVariant="outlined"
                  ampm={false}
                  disableFuture
                  format="DD/MM/yyyy hh:mm a"
                  value={appointmentDate}
                  onChange={(e) => {
                    setUpdateCart({
                      ...updateCart,
                      accept_date: moment(e).format().split("T")[0],
                      accept_time: moment(e).format().split("T")[1],
                    });
                    appointmenthandleDateChange(e);
                  }}
                />
              </FormGroup>
            </Col>
            <Col sm="4">
              <FormGroup>
                <Label>Status</Label>
                <select
                  className="form-control h-auto py-2 px-6"
                  onChange={(e) => {
                    setUpdateCart({ ...updateCart, status: e.target.value });
                  }}
                  name="cartStatus"
                  value={updateCart.status}
                  required
                >
                  <option value="">--Select--</option>
                  <option value="accept">Accept</option>
                  <option value="done">Done</option>
                  <option value="request">Request</option>
                </select>
              </FormGroup>
            </Col>
            <Col sm="4"></Col>
          </Row>

          <div className="chat-text-body">
            {cartRemarks.length > 0 ? (
              cartRemarks.map((row, i) => (
                <div className="chat-container chat-darker">
                  <p>{row.remarks}</p>
                  <span className="time-right">{row.date}</span>
                </div>
              ))
            ) : (
              <div className="container text-center">
                <h4>No Remarks</h4>
              </div>
            )}
          </div>
          <Row className="mt-4">
            <Col sm="10">
              <textarea
                required
                value={updateCart.remarks}
                className="form-control h-auto py-2 px-6"
                name="remarks"
                onClick={() => {
                  setRemarksValidation(false);
                }}
                onChange={(e) =>
                  setUpdateCart({ ...updateCart, remarks: e.target.value })
                }
              />
              {remarksValidation ? (
                <Label className="text-danger">Remark can not be blank.</Label>
              ) : null}
            </Col>
            <Col sm="2">
              {remarksLoader ? (
                <Button className="btn btn-info mt-3" type="button">
                  Updating..
                </Button>
              ) : (
                <Button
                  className="btn btn-success mt-3"
                  type="button"
                  onClick={() => {
                    updateCartEntry();
                  }}
                >
                  Enter
                </Button>
              )}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
}
